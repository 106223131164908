// source: protos/report.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.AssignMessage', null, global);
goog.exportSymbol('proto.AssignMessage.AssignType', null, global);
goog.exportSymbol('proto.CreateReportMessage', null, global);
goog.exportSymbol('proto.CreateReportResult', null, global);
goog.exportSymbol('proto.FetchNearReportsMessage', null, global);
goog.exportSymbol('proto.FetchReportStats', null, global);
goog.exportSymbol('proto.FetchReportStats.FetchType', null, global);
goog.exportSymbol('proto.FetchReportsMessage', null, global);
goog.exportSymbol('proto.FetchReportsMessageCron', null, global);
goog.exportSymbol('proto.FetchReportsResult', null, global);
goog.exportSymbol('proto.FinishReportWorkMessage', null, global);
goog.exportSymbol('proto.GeoPoint', null, global);
goog.exportSymbol('proto.IdMessage', null, global);
goog.exportSymbol('proto.LikeUnlikeMessage', null, global);
goog.exportSymbol('proto.ListenReportsMessage', null, global);
goog.exportSymbol('proto.ListenReportsMessage.ListenReportType', null, global);
goog.exportSymbol('proto.Report', null, global);
goog.exportSymbol('proto.ReportAssignedWorkers', null, global);
goog.exportSymbol('proto.ReportStatsResult', null, global);
goog.exportSymbol('proto.ReportStatus', null, global);
goog.exportSymbol('proto.ReportWorkMessage', null, global);
goog.exportSymbol('proto.ReportWorkStatus', null, global);
goog.exportSymbol('proto.SetReportsVisibilityMessage', null, global);
goog.exportSymbol('proto.StartReportWorkMessage', null, global);
goog.exportSymbol('proto.Success', null, global);
goog.exportSymbol('proto.UpdateReportMessage', null, global);
goog.exportSymbol('proto.VisibilityType', null, global);
goog.exportSymbol('proto.WorkerLog', null, global);
goog.exportSymbol('proto.WorkerLog.WorkerLogStatus', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Report = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Report.repeatedFields_, null);
};
goog.inherits(proto.Report, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Report.displayName = 'proto.Report';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SetReportsVisibilityMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.SetReportsVisibilityMessage.repeatedFields_, null);
};
goog.inherits(proto.SetReportsVisibilityMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SetReportsVisibilityMessage.displayName = 'proto.SetReportsVisibilityMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.FetchReportsMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.FetchReportsMessage.repeatedFields_, null);
};
goog.inherits(proto.FetchReportsMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.FetchReportsMessage.displayName = 'proto.FetchReportsMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.FetchReportsMessageCron = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.FetchReportsMessageCron, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.FetchReportsMessageCron.displayName = 'proto.FetchReportsMessageCron';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AssignMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AssignMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AssignMessage.displayName = 'proto.AssignMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ReportAssignedWorkers = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ReportAssignedWorkers.repeatedFields_, null);
};
goog.inherits(proto.ReportAssignedWorkers, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ReportAssignedWorkers.displayName = 'proto.ReportAssignedWorkers';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateReportMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateReportMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateReportMessage.displayName = 'proto.UpdateReportMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.FetchReportStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.FetchReportStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.FetchReportStats.displayName = 'proto.FetchReportStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ReportStatsResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ReportStatsResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ReportStatsResult.displayName = 'proto.ReportStatsResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LikeUnlikeMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LikeUnlikeMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.LikeUnlikeMessage.displayName = 'proto.LikeUnlikeMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ReportWorkMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ReportWorkMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ReportWorkMessage.displayName = 'proto.ReportWorkMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.FetchReportsResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.FetchReportsResult.repeatedFields_, null);
};
goog.inherits(proto.FetchReportsResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.FetchReportsResult.displayName = 'proto.FetchReportsResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.FetchNearReportsMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.FetchNearReportsMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.FetchNearReportsMessage.displayName = 'proto.FetchNearReportsMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.WorkerLog = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.WorkerLog, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.WorkerLog.displayName = 'proto.WorkerLog';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.IdMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.IdMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.IdMessage.displayName = 'proto.IdMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ListenReportsMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ListenReportsMessage.repeatedFields_, null);
};
goog.inherits(proto.ListenReportsMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ListenReportsMessage.displayName = 'proto.ListenReportsMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateReportMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateReportMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateReportMessage.displayName = 'proto.CreateReportMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateReportResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateReportResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateReportResult.displayName = 'proto.CreateReportResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.StartReportWorkMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.StartReportWorkMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.StartReportWorkMessage.displayName = 'proto.StartReportWorkMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.FinishReportWorkMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.FinishReportWorkMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.FinishReportWorkMessage.displayName = 'proto.FinishReportWorkMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Success = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Success, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Success.displayName = 'proto.Success';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GeoPoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GeoPoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GeoPoint.displayName = 'proto.GeoPoint';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Report.repeatedFields_ = [12,17,18];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Report.prototype.toObject = function(opt_includeInstance) {
  return proto.Report.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Report} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Report.toObject = function(includeInstance, msg) {
  var f, obj = {
    reportid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reportstatus: jspb.Message.getFieldWithDefault(msg, 2, 0),
    reporttypeid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    geopoint: (f = msg.getGeopoint()) && proto.GeoPoint.toObject(includeInstance, f),
    address: jspb.Message.getFieldWithDefault(msg, 5, ""),
    description: jspb.Message.getFieldWithDefault(msg, 6, ""),
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    reportedby: jspb.Message.getFieldWithDefault(msg, 8, ""),
    finishedcomment: jspb.Message.getFieldWithDefault(msg, 9, ""),
    estimatedtime: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    workstatus: jspb.Message.getFieldWithDefault(msg, 11, 0),
    workerlogsList: jspb.Message.toObjectList(msg.getWorkerlogsList(),
    proto.WorkerLog.toObject, includeInstance),
    imageurl: jspb.Message.getFieldWithDefault(msg, 13, ""),
    doneimageurl: jspb.Message.getFieldWithDefault(msg, 14, ""),
    ignored: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    cityid: jspb.Message.getFieldWithDefault(msg, 16, ""),
    likeusersList: (f = jspb.Message.getRepeatedField(msg, 17)) == null ? undefined : f,
    assignedWorkersList: (f = jspb.Message.getRepeatedField(msg, 18)) == null ? undefined : f,
    arrivalImageUrl: jspb.Message.getFieldWithDefault(msg, 19, ""),
    workProgressDate: (f = msg.getWorkProgressDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    workFinishedDate: (f = msg.getWorkFinishedDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    problem: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
    assignDate: (f = msg.getAssignDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Report}
 */
proto.Report.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Report;
  return proto.Report.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Report} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Report}
 */
proto.Report.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReportid(value);
      break;
    case 2:
      var value = /** @type {!proto.ReportStatus} */ (reader.readEnum());
      msg.setReportstatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setReporttypeid(value);
      break;
    case 4:
      var value = new proto.GeoPoint;
      reader.readMessage(value,proto.GeoPoint.deserializeBinaryFromReader);
      msg.setGeopoint(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setReportedby(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinishedcomment(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setEstimatedtime(value);
      break;
    case 11:
      var value = /** @type {!proto.ReportWorkStatus} */ (reader.readEnum());
      msg.setWorkstatus(value);
      break;
    case 12:
      var value = new proto.WorkerLog;
      reader.readMessage(value,proto.WorkerLog.deserializeBinaryFromReader);
      msg.addWorkerlogs(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageurl(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setDoneimageurl(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIgnored(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setCityid(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.addLikeusers(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.addAssignedWorkers(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setArrivalImageUrl(value);
      break;
    case 20:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setWorkProgressDate(value);
      break;
    case 21:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setWorkFinishedDate(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setProblem(value);
      break;
    case 23:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAssignDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Report.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Report.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Report} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Report.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReportid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReportstatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getReporttypeid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getGeopoint();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.GeoPoint.serializeBinaryToWriter
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getReportedby();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getFinishedcomment();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getEstimatedtime();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
  f = message.getWorkstatus();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getWorkerlogsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.WorkerLog.serializeBinaryToWriter
    );
  }
  f = message.getImageurl();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getDoneimageurl();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getIgnored();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getCityid();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getLikeusersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      17,
      f
    );
  }
  f = message.getAssignedWorkersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      18,
      f
    );
  }
  f = message.getArrivalImageUrl();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getWorkProgressDate();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getWorkFinishedDate();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getProblem();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
  f = message.getAssignDate();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string reportId = 1;
 * @return {string}
 */
proto.Report.prototype.getReportid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Report} returns this
 */
proto.Report.prototype.setReportid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ReportStatus reportStatus = 2;
 * @return {!proto.ReportStatus}
 */
proto.Report.prototype.getReportstatus = function() {
  return /** @type {!proto.ReportStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.ReportStatus} value
 * @return {!proto.Report} returns this
 */
proto.Report.prototype.setReportstatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string reportTypeId = 3;
 * @return {string}
 */
proto.Report.prototype.getReporttypeid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Report} returns this
 */
proto.Report.prototype.setReporttypeid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional GeoPoint geoPoint = 4;
 * @return {?proto.GeoPoint}
 */
proto.Report.prototype.getGeopoint = function() {
  return /** @type{?proto.GeoPoint} */ (
    jspb.Message.getWrapperField(this, proto.GeoPoint, 4));
};


/**
 * @param {?proto.GeoPoint|undefined} value
 * @return {!proto.Report} returns this
*/
proto.Report.prototype.setGeopoint = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Report} returns this
 */
proto.Report.prototype.clearGeopoint = function() {
  return this.setGeopoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Report.prototype.hasGeopoint = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string address = 5;
 * @return {string}
 */
proto.Report.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.Report} returns this
 */
proto.Report.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string description = 6;
 * @return {string}
 */
proto.Report.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.Report} returns this
 */
proto.Report.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp createdAt = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.Report.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.Report} returns this
*/
proto.Report.prototype.setCreatedat = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Report} returns this
 */
proto.Report.prototype.clearCreatedat = function() {
  return this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Report.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string reportedBy = 8;
 * @return {string}
 */
proto.Report.prototype.getReportedby = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.Report} returns this
 */
proto.Report.prototype.setReportedby = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string finishedComment = 9;
 * @return {string}
 */
proto.Report.prototype.getFinishedcomment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.Report} returns this
 */
proto.Report.prototype.setFinishedcomment = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional float estimatedTime = 10;
 * @return {number}
 */
proto.Report.prototype.getEstimatedtime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Report} returns this
 */
proto.Report.prototype.setEstimatedtime = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional ReportWorkStatus workStatus = 11;
 * @return {!proto.ReportWorkStatus}
 */
proto.Report.prototype.getWorkstatus = function() {
  return /** @type {!proto.ReportWorkStatus} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.ReportWorkStatus} value
 * @return {!proto.Report} returns this
 */
proto.Report.prototype.setWorkstatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * repeated WorkerLog workerLogs = 12;
 * @return {!Array<!proto.WorkerLog>}
 */
proto.Report.prototype.getWorkerlogsList = function() {
  return /** @type{!Array<!proto.WorkerLog>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.WorkerLog, 12));
};


/**
 * @param {!Array<!proto.WorkerLog>} value
 * @return {!proto.Report} returns this
*/
proto.Report.prototype.setWorkerlogsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.WorkerLog=} opt_value
 * @param {number=} opt_index
 * @return {!proto.WorkerLog}
 */
proto.Report.prototype.addWorkerlogs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.WorkerLog, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Report} returns this
 */
proto.Report.prototype.clearWorkerlogsList = function() {
  return this.setWorkerlogsList([]);
};


/**
 * optional string imageUrl = 13;
 * @return {string}
 */
proto.Report.prototype.getImageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.Report} returns this
 */
proto.Report.prototype.setImageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string doneImageUrl = 14;
 * @return {string}
 */
proto.Report.prototype.getDoneimageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.Report} returns this
 */
proto.Report.prototype.setDoneimageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional bool ignored = 15;
 * @return {boolean}
 */
proto.Report.prototype.getIgnored = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Report} returns this
 */
proto.Report.prototype.setIgnored = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional string cityId = 16;
 * @return {string}
 */
proto.Report.prototype.getCityid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.Report} returns this
 */
proto.Report.prototype.setCityid = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * repeated string likeUsers = 17;
 * @return {!Array<string>}
 */
proto.Report.prototype.getLikeusersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 17));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.Report} returns this
 */
proto.Report.prototype.setLikeusersList = function(value) {
  return jspb.Message.setField(this, 17, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.Report} returns this
 */
proto.Report.prototype.addLikeusers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 17, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Report} returns this
 */
proto.Report.prototype.clearLikeusersList = function() {
  return this.setLikeusersList([]);
};


/**
 * repeated string assigned_workers = 18;
 * @return {!Array<string>}
 */
proto.Report.prototype.getAssignedWorkersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 18));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.Report} returns this
 */
proto.Report.prototype.setAssignedWorkersList = function(value) {
  return jspb.Message.setField(this, 18, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.Report} returns this
 */
proto.Report.prototype.addAssignedWorkers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 18, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Report} returns this
 */
proto.Report.prototype.clearAssignedWorkersList = function() {
  return this.setAssignedWorkersList([]);
};


/**
 * optional string arrival_image_url = 19;
 * @return {string}
 */
proto.Report.prototype.getArrivalImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.Report} returns this
 */
proto.Report.prototype.setArrivalImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional google.protobuf.Timestamp work_progress_date = 20;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.Report.prototype.getWorkProgressDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 20));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.Report} returns this
*/
proto.Report.prototype.setWorkProgressDate = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Report} returns this
 */
proto.Report.prototype.clearWorkProgressDate = function() {
  return this.setWorkProgressDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Report.prototype.hasWorkProgressDate = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional google.protobuf.Timestamp work_finished_date = 21;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.Report.prototype.getWorkFinishedDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 21));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.Report} returns this
*/
proto.Report.prototype.setWorkFinishedDate = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Report} returns this
 */
proto.Report.prototype.clearWorkFinishedDate = function() {
  return this.setWorkFinishedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Report.prototype.hasWorkFinishedDate = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional bool problem = 22;
 * @return {boolean}
 */
proto.Report.prototype.getProblem = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Report} returns this
 */
proto.Report.prototype.setProblem = function(value) {
  return jspb.Message.setProto3BooleanField(this, 22, value);
};


/**
 * optional google.protobuf.Timestamp assign_date = 23;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.Report.prototype.getAssignDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 23));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.Report} returns this
*/
proto.Report.prototype.setAssignDate = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Report} returns this
 */
proto.Report.prototype.clearAssignDate = function() {
  return this.setAssignDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Report.prototype.hasAssignDate = function() {
  return jspb.Message.getField(this, 23) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.SetReportsVisibilityMessage.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SetReportsVisibilityMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.SetReportsVisibilityMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SetReportsVisibilityMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SetReportsVisibilityMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    reportIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    visibilityType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SetReportsVisibilityMessage}
 */
proto.SetReportsVisibilityMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SetReportsVisibilityMessage;
  return proto.SetReportsVisibilityMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SetReportsVisibilityMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SetReportsVisibilityMessage}
 */
proto.SetReportsVisibilityMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addReportIds(value);
      break;
    case 2:
      var value = /** @type {!proto.VisibilityType} */ (reader.readEnum());
      msg.setVisibilityType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SetReportsVisibilityMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SetReportsVisibilityMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SetReportsVisibilityMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SetReportsVisibilityMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReportIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getVisibilityType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * repeated string report_ids = 1;
 * @return {!Array<string>}
 */
proto.SetReportsVisibilityMessage.prototype.getReportIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.SetReportsVisibilityMessage} returns this
 */
proto.SetReportsVisibilityMessage.prototype.setReportIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.SetReportsVisibilityMessage} returns this
 */
proto.SetReportsVisibilityMessage.prototype.addReportIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SetReportsVisibilityMessage} returns this
 */
proto.SetReportsVisibilityMessage.prototype.clearReportIdsList = function() {
  return this.setReportIdsList([]);
};


/**
 * optional VisibilityType visibility_type = 2;
 * @return {!proto.VisibilityType}
 */
proto.SetReportsVisibilityMessage.prototype.getVisibilityType = function() {
  return /** @type {!proto.VisibilityType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.VisibilityType} value
 * @return {!proto.SetReportsVisibilityMessage} returns this
 */
proto.SetReportsVisibilityMessage.prototype.setVisibilityType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.FetchReportsMessage.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.FetchReportsMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.FetchReportsMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.FetchReportsMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.FetchReportsMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    reportIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    licenseId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.FetchReportsMessage}
 */
proto.FetchReportsMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.FetchReportsMessage;
  return proto.FetchReportsMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.FetchReportsMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.FetchReportsMessage}
 */
proto.FetchReportsMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addReportIds(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLicenseId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.FetchReportsMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.FetchReportsMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.FetchReportsMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.FetchReportsMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReportIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getLicenseId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated string report_ids = 1;
 * @return {!Array<string>}
 */
proto.FetchReportsMessage.prototype.getReportIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.FetchReportsMessage} returns this
 */
proto.FetchReportsMessage.prototype.setReportIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.FetchReportsMessage} returns this
 */
proto.FetchReportsMessage.prototype.addReportIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.FetchReportsMessage} returns this
 */
proto.FetchReportsMessage.prototype.clearReportIdsList = function() {
  return this.setReportIdsList([]);
};


/**
 * optional string license_id = 2;
 * @return {string}
 */
proto.FetchReportsMessage.prototype.getLicenseId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.FetchReportsMessage} returns this
 */
proto.FetchReportsMessage.prototype.setLicenseId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.FetchReportsMessageCron.prototype.toObject = function(opt_includeInstance) {
  return proto.FetchReportsMessageCron.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.FetchReportsMessageCron} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.FetchReportsMessageCron.toObject = function(includeInstance, msg) {
  var f, obj = {
    licenseId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    days: jspb.Message.getFieldWithDefault(msg, 2, 0),
    visibilityType: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.FetchReportsMessageCron}
 */
proto.FetchReportsMessageCron.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.FetchReportsMessageCron;
  return proto.FetchReportsMessageCron.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.FetchReportsMessageCron} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.FetchReportsMessageCron}
 */
proto.FetchReportsMessageCron.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLicenseId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDays(value);
      break;
    case 3:
      var value = /** @type {!proto.VisibilityType} */ (reader.readEnum());
      msg.setVisibilityType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.FetchReportsMessageCron.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.FetchReportsMessageCron.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.FetchReportsMessageCron} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.FetchReportsMessageCron.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLicenseId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDays();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getVisibilityType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string license_id = 1;
 * @return {string}
 */
proto.FetchReportsMessageCron.prototype.getLicenseId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.FetchReportsMessageCron} returns this
 */
proto.FetchReportsMessageCron.prototype.setLicenseId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 days = 2;
 * @return {number}
 */
proto.FetchReportsMessageCron.prototype.getDays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.FetchReportsMessageCron} returns this
 */
proto.FetchReportsMessageCron.prototype.setDays = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional VisibilityType visibility_type = 3;
 * @return {!proto.VisibilityType}
 */
proto.FetchReportsMessageCron.prototype.getVisibilityType = function() {
  return /** @type {!proto.VisibilityType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.VisibilityType} value
 * @return {!proto.FetchReportsMessageCron} returns this
 */
proto.FetchReportsMessageCron.prototype.setVisibilityType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AssignMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.AssignMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AssignMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AssignMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    reportid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AssignMessage}
 */
proto.AssignMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AssignMessage;
  return proto.AssignMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AssignMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AssignMessage}
 */
proto.AssignMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReportid(value);
      break;
    case 2:
      var value = /** @type {!proto.AssignMessage.AssignType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AssignMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AssignMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AssignMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AssignMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReportid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.AssignMessage.AssignType = {
  ASSIGN: 0,
  UNASSIGN: 1
};

/**
 * optional string reportId = 1;
 * @return {string}
 */
proto.AssignMessage.prototype.getReportid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AssignMessage} returns this
 */
proto.AssignMessage.prototype.setReportid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional AssignType type = 2;
 * @return {!proto.AssignMessage.AssignType}
 */
proto.AssignMessage.prototype.getType = function() {
  return /** @type {!proto.AssignMessage.AssignType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.AssignMessage.AssignType} value
 * @return {!proto.AssignMessage} returns this
 */
proto.AssignMessage.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ReportAssignedWorkers.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ReportAssignedWorkers.prototype.toObject = function(opt_includeInstance) {
  return proto.ReportAssignedWorkers.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ReportAssignedWorkers} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportAssignedWorkers.toObject = function(includeInstance, msg) {
  var f, obj = {
    reportIdList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    assignedWorkersList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ReportAssignedWorkers}
 */
proto.ReportAssignedWorkers.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ReportAssignedWorkers;
  return proto.ReportAssignedWorkers.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ReportAssignedWorkers} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ReportAssignedWorkers}
 */
proto.ReportAssignedWorkers.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addReportId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addAssignedWorkers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ReportAssignedWorkers.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ReportAssignedWorkers.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ReportAssignedWorkers} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportAssignedWorkers.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReportIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getAssignedWorkersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * repeated string report_id = 1;
 * @return {!Array<string>}
 */
proto.ReportAssignedWorkers.prototype.getReportIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.ReportAssignedWorkers} returns this
 */
proto.ReportAssignedWorkers.prototype.setReportIdList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.ReportAssignedWorkers} returns this
 */
proto.ReportAssignedWorkers.prototype.addReportId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ReportAssignedWorkers} returns this
 */
proto.ReportAssignedWorkers.prototype.clearReportIdList = function() {
  return this.setReportIdList([]);
};


/**
 * repeated string assigned_workers = 2;
 * @return {!Array<string>}
 */
proto.ReportAssignedWorkers.prototype.getAssignedWorkersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.ReportAssignedWorkers} returns this
 */
proto.ReportAssignedWorkers.prototype.setAssignedWorkersList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.ReportAssignedWorkers} returns this
 */
proto.ReportAssignedWorkers.prototype.addAssignedWorkers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ReportAssignedWorkers} returns this
 */
proto.ReportAssignedWorkers.prototype.clearAssignedWorkersList = function() {
  return this.setAssignedWorkersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateReportMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateReportMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateReportMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateReportMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    reportid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    imageurl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    problem: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    doneImageUrl: jspb.Message.getFieldWithDefault(msg, 5, ""),
    arrivalImageUrl: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateReportMessage}
 */
proto.UpdateReportMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateReportMessage;
  return proto.UpdateReportMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateReportMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateReportMessage}
 */
proto.UpdateReportMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReportid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageurl(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setProblem(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDoneImageUrl(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setArrivalImageUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateReportMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateReportMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateReportMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateReportMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReportid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getImageurl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProblem();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getDoneImageUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getArrivalImageUrl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string reportId = 1;
 * @return {string}
 */
proto.UpdateReportMessage.prototype.getReportid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateReportMessage} returns this
 */
proto.UpdateReportMessage.prototype.setReportid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.UpdateReportMessage.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateReportMessage} returns this
 */
proto.UpdateReportMessage.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string imageUrl = 3;
 * @return {string}
 */
proto.UpdateReportMessage.prototype.getImageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateReportMessage} returns this
 */
proto.UpdateReportMessage.prototype.setImageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool problem = 4;
 * @return {boolean}
 */
proto.UpdateReportMessage.prototype.getProblem = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UpdateReportMessage} returns this
 */
proto.UpdateReportMessage.prototype.setProblem = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string done_image_url = 5;
 * @return {string}
 */
proto.UpdateReportMessage.prototype.getDoneImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateReportMessage} returns this
 */
proto.UpdateReportMessage.prototype.setDoneImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string arrival_image_url = 6;
 * @return {string}
 */
proto.UpdateReportMessage.prototype.getArrivalImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateReportMessage} returns this
 */
proto.UpdateReportMessage.prototype.setArrivalImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.FetchReportStats.prototype.toObject = function(opt_includeInstance) {
  return proto.FetchReportStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.FetchReportStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.FetchReportStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.FetchReportStats}
 */
proto.FetchReportStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.FetchReportStats;
  return proto.FetchReportStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.FetchReportStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.FetchReportStats}
 */
proto.FetchReportStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.FetchReportStats.FetchType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.FetchReportStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.FetchReportStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.FetchReportStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.FetchReportStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.FetchReportStats.FetchType = {
  BY_USERID: 0
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.FetchReportStats.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.FetchReportStats} returns this
 */
proto.FetchReportStats.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional FetchType type = 2;
 * @return {!proto.FetchReportStats.FetchType}
 */
proto.FetchReportStats.prototype.getType = function() {
  return /** @type {!proto.FetchReportStats.FetchType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.FetchReportStats.FetchType} value
 * @return {!proto.FetchReportStats} returns this
 */
proto.FetchReportStats.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ReportStatsResult.prototype.toObject = function(opt_includeInstance) {
  return proto.ReportStatsResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ReportStatsResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportStatsResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    reportsDone: jspb.Message.getFieldWithDefault(msg, 1, 0),
    reportsInProgress: jspb.Message.getFieldWithDefault(msg, 2, 0),
    reportsCompleted: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ReportStatsResult}
 */
proto.ReportStatsResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ReportStatsResult;
  return proto.ReportStatsResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ReportStatsResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ReportStatsResult}
 */
proto.ReportStatsResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReportsDone(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReportsInProgress(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReportsCompleted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ReportStatsResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ReportStatsResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ReportStatsResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportStatsResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReportsDone();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getReportsInProgress();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getReportsCompleted();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 reports_done = 1;
 * @return {number}
 */
proto.ReportStatsResult.prototype.getReportsDone = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ReportStatsResult} returns this
 */
proto.ReportStatsResult.prototype.setReportsDone = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 reports_in_progress = 2;
 * @return {number}
 */
proto.ReportStatsResult.prototype.getReportsInProgress = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.ReportStatsResult} returns this
 */
proto.ReportStatsResult.prototype.setReportsInProgress = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 reports_completed = 3;
 * @return {number}
 */
proto.ReportStatsResult.prototype.getReportsCompleted = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.ReportStatsResult} returns this
 */
proto.ReportStatsResult.prototype.setReportsCompleted = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.LikeUnlikeMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.LikeUnlikeMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.LikeUnlikeMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LikeUnlikeMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    reportid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    islike: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LikeUnlikeMessage}
 */
proto.LikeUnlikeMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.LikeUnlikeMessage;
  return proto.LikeUnlikeMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LikeUnlikeMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LikeUnlikeMessage}
 */
proto.LikeUnlikeMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReportid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIslike(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LikeUnlikeMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.LikeUnlikeMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LikeUnlikeMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LikeUnlikeMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReportid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIslike();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string reportId = 1;
 * @return {string}
 */
proto.LikeUnlikeMessage.prototype.getReportid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.LikeUnlikeMessage} returns this
 */
proto.LikeUnlikeMessage.prototype.setReportid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string userId = 2;
 * @return {string}
 */
proto.LikeUnlikeMessage.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.LikeUnlikeMessage} returns this
 */
proto.LikeUnlikeMessage.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool isLike = 3;
 * @return {boolean}
 */
proto.LikeUnlikeMessage.prototype.getIslike = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.LikeUnlikeMessage} returns this
 */
proto.LikeUnlikeMessage.prototype.setIslike = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ReportWorkMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.ReportWorkMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ReportWorkMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportWorkMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    reportid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    workerid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    startwork: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ReportWorkMessage}
 */
proto.ReportWorkMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ReportWorkMessage;
  return proto.ReportWorkMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ReportWorkMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ReportWorkMessage}
 */
proto.ReportWorkMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReportid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkerid(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStartwork(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ReportWorkMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ReportWorkMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ReportWorkMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportWorkMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReportid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWorkerid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStartwork();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string reportId = 1;
 * @return {string}
 */
proto.ReportWorkMessage.prototype.getReportid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ReportWorkMessage} returns this
 */
proto.ReportWorkMessage.prototype.setReportid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string workerId = 2;
 * @return {string}
 */
proto.ReportWorkMessage.prototype.getWorkerid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ReportWorkMessage} returns this
 */
proto.ReportWorkMessage.prototype.setWorkerid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool startWork = 3;
 * @return {boolean}
 */
proto.ReportWorkMessage.prototype.getStartwork = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ReportWorkMessage} returns this
 */
proto.ReportWorkMessage.prototype.setStartwork = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.FetchReportsResult.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.FetchReportsResult.prototype.toObject = function(opt_includeInstance) {
  return proto.FetchReportsResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.FetchReportsResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.FetchReportsResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    reportsList: jspb.Message.toObjectList(msg.getReportsList(),
    proto.Report.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.FetchReportsResult}
 */
proto.FetchReportsResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.FetchReportsResult;
  return proto.FetchReportsResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.FetchReportsResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.FetchReportsResult}
 */
proto.FetchReportsResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Report;
      reader.readMessage(value,proto.Report.deserializeBinaryFromReader);
      msg.addReports(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.FetchReportsResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.FetchReportsResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.FetchReportsResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.FetchReportsResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Report.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Report reports = 1;
 * @return {!Array<!proto.Report>}
 */
proto.FetchReportsResult.prototype.getReportsList = function() {
  return /** @type{!Array<!proto.Report>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Report, 1));
};


/**
 * @param {!Array<!proto.Report>} value
 * @return {!proto.FetchReportsResult} returns this
*/
proto.FetchReportsResult.prototype.setReportsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Report=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Report}
 */
proto.FetchReportsResult.prototype.addReports = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Report, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.FetchReportsResult} returns this
 */
proto.FetchReportsResult.prototype.clearReportsList = function() {
  return this.setReportsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.FetchNearReportsMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.FetchNearReportsMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.FetchNearReportsMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.FetchNearReportsMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    radius: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.FetchNearReportsMessage}
 */
proto.FetchNearReportsMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.FetchNearReportsMessage;
  return proto.FetchNearReportsMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.FetchNearReportsMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.FetchNearReportsMessage}
 */
proto.FetchNearReportsMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRadius(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.FetchNearReportsMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.FetchNearReportsMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.FetchNearReportsMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.FetchNearReportsMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getRadius();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional double latitude = 1;
 * @return {number}
 */
proto.FetchNearReportsMessage.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.FetchNearReportsMessage} returns this
 */
proto.FetchNearReportsMessage.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double longitude = 2;
 * @return {number}
 */
proto.FetchNearReportsMessage.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.FetchNearReportsMessage} returns this
 */
proto.FetchNearReportsMessage.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double radius = 3;
 * @return {number}
 */
proto.FetchNearReportsMessage.prototype.getRadius = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.FetchNearReportsMessage} returns this
 */
proto.FetchNearReportsMessage.prototype.setRadius = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.WorkerLog.prototype.toObject = function(opt_includeInstance) {
  return proto.WorkerLog.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.WorkerLog} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.WorkerLog.toObject = function(includeInstance, msg) {
  var f, obj = {
    workerid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    date: (f = msg.getDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    secondsWorked: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.WorkerLog}
 */
proto.WorkerLog.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.WorkerLog;
  return proto.WorkerLog.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.WorkerLog} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.WorkerLog}
 */
proto.WorkerLog.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkerid(value);
      break;
    case 2:
      var value = /** @type {!proto.WorkerLog.WorkerLogStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSecondsWorked(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.WorkerLog.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.WorkerLog.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.WorkerLog} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.WorkerLog.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkerid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSecondsWorked();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.WorkerLog.WorkerLogStatus = {
  STATUS_UNKNOWN: 0,
  STATUS_STARTED: 1,
  STATUS_STOPPED: 2
};

/**
 * optional string workerId = 1;
 * @return {string}
 */
proto.WorkerLog.prototype.getWorkerid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.WorkerLog} returns this
 */
proto.WorkerLog.prototype.setWorkerid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional WorkerLogStatus status = 2;
 * @return {!proto.WorkerLog.WorkerLogStatus}
 */
proto.WorkerLog.prototype.getStatus = function() {
  return /** @type {!proto.WorkerLog.WorkerLogStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.WorkerLog.WorkerLogStatus} value
 * @return {!proto.WorkerLog} returns this
 */
proto.WorkerLog.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp date = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.WorkerLog.prototype.getDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.WorkerLog} returns this
*/
proto.WorkerLog.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.WorkerLog} returns this
 */
proto.WorkerLog.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.WorkerLog.prototype.hasDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 seconds_worked = 4;
 * @return {number}
 */
proto.WorkerLog.prototype.getSecondsWorked = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.WorkerLog} returns this
 */
proto.WorkerLog.prototype.setSecondsWorked = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.IdMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.IdMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.IdMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IdMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.IdMessage}
 */
proto.IdMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.IdMessage;
  return proto.IdMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.IdMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.IdMessage}
 */
proto.IdMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.IdMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.IdMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.IdMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IdMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.IdMessage.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.IdMessage} returns this
 */
proto.IdMessage.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ListenReportsMessage.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ListenReportsMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.ListenReportsMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ListenReportsMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListenReportsMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    idList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    userid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListenReportsMessage}
 */
proto.ListenReportsMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ListenReportsMessage;
  return proto.ListenReportsMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListenReportsMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListenReportsMessage}
 */
proto.ListenReportsMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 3:
      var value = /** @type {!proto.ListenReportsMessage.ListenReportType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ListenReportsMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ListenReportsMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListenReportsMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListenReportsMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.ListenReportsMessage.ListenReportType = {
  UNKNOWN: 0,
  MINE: 1,
  NEAR: 2,
  WORKER: 3,
  LICENSE: 4
};

/**
 * repeated string id = 1;
 * @return {!Array<string>}
 */
proto.ListenReportsMessage.prototype.getIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.ListenReportsMessage} returns this
 */
proto.ListenReportsMessage.prototype.setIdList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.ListenReportsMessage} returns this
 */
proto.ListenReportsMessage.prototype.addId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ListenReportsMessage} returns this
 */
proto.ListenReportsMessage.prototype.clearIdList = function() {
  return this.setIdList([]);
};


/**
 * optional string userId = 2;
 * @return {string}
 */
proto.ListenReportsMessage.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ListenReportsMessage} returns this
 */
proto.ListenReportsMessage.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ListenReportType type = 3;
 * @return {!proto.ListenReportsMessage.ListenReportType}
 */
proto.ListenReportsMessage.prototype.getType = function() {
  return /** @type {!proto.ListenReportsMessage.ListenReportType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.ListenReportsMessage.ListenReportType} value
 * @return {!proto.ListenReportsMessage} returns this
 */
proto.ListenReportsMessage.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateReportMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateReportMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateReportMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateReportMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    reporttypeid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    geopoint: (f = msg.getGeopoint()) && proto.GeoPoint.toObject(includeInstance, f),
    address: jspb.Message.getFieldWithDefault(msg, 3, ""),
    imageurl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    reportedby: jspb.Message.getFieldWithDefault(msg, 6, ""),
    cityid: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateReportMessage}
 */
proto.CreateReportMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateReportMessage;
  return proto.CreateReportMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateReportMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateReportMessage}
 */
proto.CreateReportMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReporttypeid(value);
      break;
    case 2:
      var value = new proto.GeoPoint;
      reader.readMessage(value,proto.GeoPoint.deserializeBinaryFromReader);
      msg.setGeopoint(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageurl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setReportedby(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCityid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateReportMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateReportMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateReportMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateReportMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReporttypeid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGeopoint();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.GeoPoint.serializeBinaryToWriter
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getImageurl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getReportedby();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCityid();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string reportTypeId = 1;
 * @return {string}
 */
proto.CreateReportMessage.prototype.getReporttypeid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateReportMessage} returns this
 */
proto.CreateReportMessage.prototype.setReporttypeid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional GeoPoint geoPoint = 2;
 * @return {?proto.GeoPoint}
 */
proto.CreateReportMessage.prototype.getGeopoint = function() {
  return /** @type{?proto.GeoPoint} */ (
    jspb.Message.getWrapperField(this, proto.GeoPoint, 2));
};


/**
 * @param {?proto.GeoPoint|undefined} value
 * @return {!proto.CreateReportMessage} returns this
*/
proto.CreateReportMessage.prototype.setGeopoint = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.CreateReportMessage} returns this
 */
proto.CreateReportMessage.prototype.clearGeopoint = function() {
  return this.setGeopoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CreateReportMessage.prototype.hasGeopoint = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string address = 3;
 * @return {string}
 */
proto.CreateReportMessage.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateReportMessage} returns this
 */
proto.CreateReportMessage.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string imageUrl = 4;
 * @return {string}
 */
proto.CreateReportMessage.prototype.getImageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateReportMessage} returns this
 */
proto.CreateReportMessage.prototype.setImageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.CreateReportMessage.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateReportMessage} returns this
 */
proto.CreateReportMessage.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string reportedBy = 6;
 * @return {string}
 */
proto.CreateReportMessage.prototype.getReportedby = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateReportMessage} returns this
 */
proto.CreateReportMessage.prototype.setReportedby = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string cityId = 7;
 * @return {string}
 */
proto.CreateReportMessage.prototype.getCityid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateReportMessage} returns this
 */
proto.CreateReportMessage.prototype.setCityid = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateReportResult.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateReportResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateReportResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateReportResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    reportid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reportstatus: jspb.Message.getFieldWithDefault(msg, 2, 0),
    reporttypeid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    geopoint: (f = msg.getGeopoint()) && proto.GeoPoint.toObject(includeInstance, f),
    address: jspb.Message.getFieldWithDefault(msg, 5, ""),
    description: jspb.Message.getFieldWithDefault(msg, 6, ""),
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    reportedby: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateReportResult}
 */
proto.CreateReportResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateReportResult;
  return proto.CreateReportResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateReportResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateReportResult}
 */
proto.CreateReportResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReportid(value);
      break;
    case 2:
      var value = /** @type {!proto.ReportStatus} */ (reader.readEnum());
      msg.setReportstatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setReporttypeid(value);
      break;
    case 4:
      var value = new proto.GeoPoint;
      reader.readMessage(value,proto.GeoPoint.deserializeBinaryFromReader);
      msg.setGeopoint(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setReportedby(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateReportResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateReportResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateReportResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateReportResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReportid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReportstatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getReporttypeid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getGeopoint();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.GeoPoint.serializeBinaryToWriter
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getReportedby();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string reportId = 1;
 * @return {string}
 */
proto.CreateReportResult.prototype.getReportid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateReportResult} returns this
 */
proto.CreateReportResult.prototype.setReportid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ReportStatus reportStatus = 2;
 * @return {!proto.ReportStatus}
 */
proto.CreateReportResult.prototype.getReportstatus = function() {
  return /** @type {!proto.ReportStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.ReportStatus} value
 * @return {!proto.CreateReportResult} returns this
 */
proto.CreateReportResult.prototype.setReportstatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string reportTypeId = 3;
 * @return {string}
 */
proto.CreateReportResult.prototype.getReporttypeid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateReportResult} returns this
 */
proto.CreateReportResult.prototype.setReporttypeid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional GeoPoint geoPoint = 4;
 * @return {?proto.GeoPoint}
 */
proto.CreateReportResult.prototype.getGeopoint = function() {
  return /** @type{?proto.GeoPoint} */ (
    jspb.Message.getWrapperField(this, proto.GeoPoint, 4));
};


/**
 * @param {?proto.GeoPoint|undefined} value
 * @return {!proto.CreateReportResult} returns this
*/
proto.CreateReportResult.prototype.setGeopoint = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.CreateReportResult} returns this
 */
proto.CreateReportResult.prototype.clearGeopoint = function() {
  return this.setGeopoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CreateReportResult.prototype.hasGeopoint = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string address = 5;
 * @return {string}
 */
proto.CreateReportResult.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateReportResult} returns this
 */
proto.CreateReportResult.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string description = 6;
 * @return {string}
 */
proto.CreateReportResult.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateReportResult} returns this
 */
proto.CreateReportResult.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp createdAt = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.CreateReportResult.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.CreateReportResult} returns this
*/
proto.CreateReportResult.prototype.setCreatedat = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.CreateReportResult} returns this
 */
proto.CreateReportResult.prototype.clearCreatedat = function() {
  return this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CreateReportResult.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string reportedBy = 8;
 * @return {string}
 */
proto.CreateReportResult.prototype.getReportedby = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateReportResult} returns this
 */
proto.CreateReportResult.prototype.setReportedby = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.StartReportWorkMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.StartReportWorkMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.StartReportWorkMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StartReportWorkMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    reportid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    workerid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    estimatedtime: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.StartReportWorkMessage}
 */
proto.StartReportWorkMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.StartReportWorkMessage;
  return proto.StartReportWorkMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.StartReportWorkMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.StartReportWorkMessage}
 */
proto.StartReportWorkMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReportid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkerid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setEstimatedtime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.StartReportWorkMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.StartReportWorkMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.StartReportWorkMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StartReportWorkMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReportid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWorkerid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEstimatedtime();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
};


/**
 * optional string reportId = 1;
 * @return {string}
 */
proto.StartReportWorkMessage.prototype.getReportid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.StartReportWorkMessage} returns this
 */
proto.StartReportWorkMessage.prototype.setReportid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string workerId = 2;
 * @return {string}
 */
proto.StartReportWorkMessage.prototype.getWorkerid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.StartReportWorkMessage} returns this
 */
proto.StartReportWorkMessage.prototype.setWorkerid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional float estimatedTime = 3;
 * @return {number}
 */
proto.StartReportWorkMessage.prototype.getEstimatedtime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.StartReportWorkMessage} returns this
 */
proto.StartReportWorkMessage.prototype.setEstimatedtime = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.FinishReportWorkMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.FinishReportWorkMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.FinishReportWorkMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.FinishReportWorkMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    reportid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    finishedcomment: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.FinishReportWorkMessage}
 */
proto.FinishReportWorkMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.FinishReportWorkMessage;
  return proto.FinishReportWorkMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.FinishReportWorkMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.FinishReportWorkMessage}
 */
proto.FinishReportWorkMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReportid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinishedcomment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.FinishReportWorkMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.FinishReportWorkMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.FinishReportWorkMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.FinishReportWorkMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReportid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFinishedcomment();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string reportId = 1;
 * @return {string}
 */
proto.FinishReportWorkMessage.prototype.getReportid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.FinishReportWorkMessage} returns this
 */
proto.FinishReportWorkMessage.prototype.setReportid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string finishedComment = 2;
 * @return {string}
 */
proto.FinishReportWorkMessage.prototype.getFinishedcomment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.FinishReportWorkMessage} returns this
 */
proto.FinishReportWorkMessage.prototype.setFinishedcomment = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Success.prototype.toObject = function(opt_includeInstance) {
  return proto.Success.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Success} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Success.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Success}
 */
proto.Success.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Success;
  return proto.Success.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Success} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Success}
 */
proto.Success.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Success.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Success.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Success} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Success.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.Success.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Success} returns this
 */
proto.Success.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.Success.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Success} returns this
 */
proto.Success.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GeoPoint.prototype.toObject = function(opt_includeInstance) {
  return proto.GeoPoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GeoPoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GeoPoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GeoPoint}
 */
proto.GeoPoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GeoPoint;
  return proto.GeoPoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GeoPoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GeoPoint}
 */
proto.GeoPoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GeoPoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GeoPoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GeoPoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GeoPoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional double latitude = 1;
 * @return {number}
 */
proto.GeoPoint.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.GeoPoint} returns this
 */
proto.GeoPoint.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double longitude = 2;
 * @return {number}
 */
proto.GeoPoint.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.GeoPoint} returns this
 */
proto.GeoPoint.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.VisibilityType = {
  VISIBILITY_UNKNOWN: 0,
  USERS: 1,
  ADMINS: 2
};

/**
 * @enum {number}
 */
proto.ReportStatus = {
  REPORT_UNKNOWN_STATUS: 0,
  REPORT_PENDING: 1,
  REPORT_IN_PROGRESS: 2,
  REPORT_COMPLETED: 3
};

/**
 * @enum {number}
 */
proto.ReportWorkStatus = {
  WORK_UNKNOWN_STATUS: 0,
  WORK_ASSIGNED: 1,
  WORK_IN_PROGRESS: 2,
  WORK_COMPLETED: 3
};

goog.object.extend(exports, proto);
