import firebase from "@firebase/app";
import 'firebase/messaging';
import "@firebase/firestore";
import "@firebase/auth";
import "@firebase/storage";

const devConfig = {
  apiKey: "AIzaSyD9tFB7b1oYXOFFecsZOZMZ-PMt2tys7Ug",
  authDomain: "giooby-67dc0.firebaseapp.com",
  projectId: "giooby-67dc0",
  storageBucket: "giooby-67dc0.appspot.com",
  messagingSenderId: "223429709903",
  appId: "1:223429709903:web:85c259a9d19b494c06da3a",
  // measurementId: "G-KFS3KGYWHM"
};

firebase.initializeApp(devConfig);
const messaging = firebase.messaging();

export const getToken = (setTokenFound) => {
  return messaging.getToken({vapidKey: 'BLKThmhv_yhjwAvElKHvYN2XL7gvPbVuIXX58sNR5ltkuPHFDu9CMkLOwdRGvtlU7_l83s6FqA8Kj7PMmDkXCt0'}).then((currentToken) => {
    if (currentToken) {
      //console.log('current token for client: ', currentToken);
      setTokenFound(true);
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log('No registration token available. Request permission to generate one.');
      setTokenFound(false);
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
});

/* var secondaryApp = firebase.initializeApp(devConfig, "Secondary");

// const firestore = firebase.firestore();
const storage = firebase.storage;
const ref = firebase.storage().ref;
const auth = firebase.auth();
const auth2 = secondaryApp.auth();
const timestamp = firebase.firestore.Timestamp.now;

export { auth, storage, timestamp, ref, auth2 }; */
