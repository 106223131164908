import React, { useState, useEffect, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RootStore } from "../../redux/Store";
import moment from "moment";
import "./style.scss";
import TeamImg from "../../assets/img/team_image.png";
import {
  TRACKING_TIME_FILTER,
  TRACKING_SELECTED_TRACKERS,
  TRACKING_SELECTED_TEAMS,
} from "../../redux/tracking/trackingTypes";
import { licenseI } from "../../redux/licenses/licensesReducer";
import { timeFilterI } from "../../redux/tracking/trackingReducer";
import { grpcFetchTrackers } from "../../redux/tracking/trackingActions";

import EndStartMarker from "./components/end-start-marker";
import Footer from "../../components/footer/index";
import FilterLeft from "./components/Filter-left";
import FilterTop from "./components/Filter-top";
import RoutePing from "./components/route-ping";
import DotRoute from "./components/dot";

import { colors, GoogleAPIKEY } from "../../const/const";
import GoogleMapReact from "google-map-react";
import { FeatureGroup, Map, Marker, Polyline } from "react-leaflet";
import * as Leaflet from "leaflet";
import LayerControlComponent from "../../components/general-component/layerControl";
import { Position, Tracker } from "../../server/tracking/protos/tracking_pb";
import { Team } from "../../server/team/protos/team_pb";
import { LatLng } from "leaflet";
import L from "leaflet";
import { renderToString } from "react-dom/server";
import { EndMarker, StartMarker } from "../../assets/svg";

interface RoutesMapProps {
  executeCalculation: any;
  Coords: any;
  currentZoom: any;
  setCenter: any;
  updateCurrentZoom: any;
}

export const RoutesMap: React.FunctionComponent<RoutesMapProps> = ({
  executeCalculation,
  Coords,
  currentZoom,
  updateCurrentZoom,
}: any) => {
  const selectedLicense: licenseI | undefined = useSelector(
    (globalState: RootStore) => globalState.licenses.selectedLicense
  );
  const teams: Team.AsObject[] = useSelector(
    (globalState: RootStore) => globalState.teams.teams
  );
  const trackerList: Tracker.AsObject[] = useSelector(
    (globalState: RootStore) => globalState.tracking.trackerList
  );
  const selectedTeams: string[] = useSelector(
    (globalState: RootStore) => globalState.tracking.selectedTeams
  );
  const selectedTrackers: string[] = useSelector(
    (globalState: RootStore) => globalState.tracking.selectedTrackers
  );
  const timeFilter: timeFilterI = useSelector(
    (globalState: RootStore) => globalState.tracking.timeFilter
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();

  let defaultValue: any;
  let defaultValueArray: any = [];

  const hourFormat = "HH:mm";
  const typeOfMomentSum = "seconds";

  const [state, setState] = useState({
    time: 0,
    timerActive: false,
    subTrackers: defaultValueArray,
    listOfColorByTracker: {},
  });

  const change = (e: any) => {
    if (currentZoom !== e.zoom) {
      updateCurrentZoom(e.zoom);
    }
  };

  const handleColorChange = (e: any, color: any, fun: any) => {
    var listOfColorByTracker = state.listOfColorByTracker;
    listOfColorByTracker[e] = color;
    setState((prevState) => ({
      ...prevState,
      listOfColorByTracker: listOfColorByTracker,
    }));
    fun();
  };

  const funTeamsToShow = () => {
    let trackersAux = trackerList;
    let subTrackers: any = [];
    teams.forEach((team: any) => {
      trackersAux.forEach((item: any) => {
        if (
          team.id === item.teamid &&
          subTrackers.indexOf(item.teamid) === -1
        ) {
          subTrackers.push(team.id);
        }
      });
    });

    dispatch({
      type: TRACKING_SELECTED_TEAMS,
      payload: subTrackers,
    });
  };

  const tickRoute = () => {
    if (timeFilter.selectedDate > timeFilter.endSelectedDate) {
      dispatch({
        type: TRACKING_TIME_FILTER,
        payload: {
          selectedDate: moment(timeFilter.endSelectedDate)
            .startOf("day")
            .toDate(),
          endSelectedDate: moment(timeFilter.endSelectedDate)
            .endOf("day")
            .toDate(),
          todayDate: defaultValue,
          timerMultiplayer: defaultValue,
          timeLineValue: 86400,
          animationMode: false,
        },
      });

      setState((prevState) => ({
        ...prevState,
        timerActive: false,
        time: 0,
      }));
    } else {
      var timeR = timeFilter.selectedDate;
      var count = moment(timeR)
        .add(timeFilter.timerMultiplayer, typeOfMomentSum)
        .toDate();
      var counter = 1 * timeFilter.timerMultiplayer;

      dispatch({
        type: TRACKING_TIME_FILTER,
        payload: {
          selectedDate: count,
          endSelectedDate: defaultValue,
          todayDate: defaultValue,
          timerMultiplayer: defaultValue,
          timeLineValue:
            state.time === 1 ? 0 : timeFilter.timeLineValue + counter,
          animationMode: true,
        },
      });
    }
  };

  const assignTeam = (id: any) => {
    var subTrackers = selectedTeams;
    var index = subTrackers.indexOf(id);
    if (index !== -1) {
      subTrackers.splice(index, 1);
    } else {
      subTrackers.push(id);
    }

    dispatch({
      type: TRACKING_SELECTED_TEAMS,
      payload: subTrackers,
    });
  };

  const assignTracker = (item: any) => {
    let trackerIndex = -1;
    var trackersAux = selectedTrackers;
    trackerIndex = trackersAux.indexOf(item.id);
    if (trackerIndex !== -1) {
      trackersAux.splice(trackerIndex, 1);
    } else {
      trackersAux.push(item.id);
    }

    dispatch({
      type: TRACKING_SELECTED_TRACKERS,
      payload: trackersAux,
    });
  };

  const startRoute = () => {
    dispatch({
      type: TRACKING_TIME_FILTER,
      payload: {
        selectedDate: defaultValue,
        endSelectedDate: defaultValue,
        todayDate: defaultValue,
        timerMultiplayer: defaultValue,
        timeLineValue: defaultValue,
        animationMode: true,
      },
    });

    setState((prevState) => ({
      ...prevState,
      timerActive: true,
    }));
  };

  const stopRoute = () => {
    setState((prevState) => ({
      ...prevState,
      timerActive: false,
    }));
  };

  const updateDate = (value: any) => {
    if (value === 0 || value === 86400) {
      dispatch({
        type: TRACKING_TIME_FILTER,
        payload: {
          selectedDate: moment(timeFilter.endSelectedDate)
            .utc(false)
            .startOf("day")
            .toDate(),
          endSelectedDate: moment(timeFilter.endSelectedDate)
            .utc(false)
            .endOf("day")
            .toDate(),
          todayDate: defaultValue,
          timerMultiplayer: defaultValue,
          timeLineValue: value,
          animationMode: false,
        },
      });

      setState((prevState) => ({
        ...prevState,
        time: 2,
        timerActive: false,
      }));
    } else {
      var timeR = moment(timeFilter.selectedDate)
        .utc(false)
        .startOf("day")
        .toDate();
      timeR.setSeconds(timeR.getSeconds() + value);

      dispatch({
        type: TRACKING_TIME_FILTER,
        payload: {
          selectedDate: timeR,
          endSelectedDate: defaultValue,
          todayDate: defaultValue,
          timerMultiplayer: defaultValue,
          timeLineValue: value,
          animationMode: true,
        },
      });

      setState((prevState) => ({
        ...prevState,
        time: 2,
        timerActive: false,
      }));
    }
  };

  useEffect(() => {
    onChangeDate(Date.now());
  }, []);

  const onChangeDate = (dateChange: any) => {
    var date = moment(dateChange).utc(false).startOf("day").toDate();
    var dateEnd = moment(dateChange).utc(false).endOf("day").toDate();

    if (date <= timeFilter.todayDate) {
      dispatch({
        type: TRACKING_TIME_FILTER,
        payload: {
          selectedDate: date,
          endSelectedDate: dateEnd,
          todayDate: defaultValue,
          timerMultiplayer: defaultValue,
          timeLineValue: 0,
          animationMode: false,
        },
      });

      if (date === timeFilter.todayDate) {
        setState((prevState) => ({
          ...prevState,
          timerActive: false,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          timerActive: false,
        }));
        dispatch(
          grpcFetchTrackers(
            {
              _id: selectedLicense?._id,
              date: moment(date)
                .utcOffset(0)
                .startOf("day")
                .toDate()
                .toISOString(),
            },
            (response: any) => {
              var listOfColorByTracker = {};
              response.forEach((e: any, index: any) => {
                listOfColorByTracker = {
                  [e.id]: colors[index],
                  ...listOfColorByTracker,
                };
              });
              setState((prevState) => ({
                ...prevState,
                listOfColorByTracker: listOfColorByTracker,
              }));
            }
          )
        );
      }
    }
  };

  const nextDay = () => {
    var date = moment(timeFilter.selectedDate)
      .utc(false)
      .startOf("day")
      .toDate();
    date.setDate(date.getDate() + 1);

    var dateEnd = moment(timeFilter.endSelectedDate)
      .utc(false)
      .endOf("day")
      .toDate();
    dateEnd.setDate(dateEnd.getDate() + 1);

    if (date <= timeFilter.todayDate) {
      dispatch({
        type: TRACKING_TIME_FILTER,
        payload: {
          selectedDate: date,
          endSelectedDate: dateEnd,
          todayDate: defaultValue,
          timerMultiplayer: defaultValue,
          timeLineValue: 0,
          animationMode: false,
        },
      });

      if (date === timeFilter.todayDate) {
        setState((prevState) => ({
          ...prevState,
          timerActive: false,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          timerActive: false,
        }));
        dispatch(
          grpcFetchTrackers(
            {
              _id: selectedLicense?._id,
              date: moment(date)
                .utcOffset(0)
                .startOf("day")
                .toDate()
                .toISOString(),
            },
            (response: any) => {
              var listOfColorByTracker = {};
              response.forEach((e: any, index: any) => {
                listOfColorByTracker = {
                  [e.id]: colors[index],
                  ...listOfColorByTracker,
                };
              });
              setState((prevState) => ({
                ...prevState,
                listOfColorByTracker: listOfColorByTracker,
              }));
            }
          )
        );
      }
    }
  };

  const lastDay = () => {
    var date = moment(timeFilter.selectedDate)
      .utc(false)
      .startOf("day")
      .toDate();
    date.setDate(date.getDate() - 1);

    var dateEnd = moment(timeFilter.endSelectedDate)
      .utc(false)
      .endOf("day")
      .toDate();
    dateEnd.setDate(dateEnd.getDate() - 1);

    dispatch({
      type: TRACKING_TIME_FILTER,
      payload: {
        selectedDate: date,
        endSelectedDate: dateEnd,
        todayDate: defaultValue,
        timerMultiplayer: defaultValue,
        timeLineValue: 0,
        animationMode: false,
      },
    });

    if (date === timeFilter.todayDate) {
      setState((prevState) => ({
        ...prevState,
        timerActive: false,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        timerActive: false,
      }));
      dispatch(
        grpcFetchTrackers(
          {
            _id: selectedLicense?._id,
            date: moment(date)
              .utcOffset(0)
              .startOf("day")
              .toDate()
              .toISOString(),
          },
          (response: any) => {
            var listOfColorByTracker = {};
            response.forEach((e: any, index: any) => {
              listOfColorByTracker = {
                [e.id]: colors[index],
                ...listOfColorByTracker,
              };
            });
            setState((prevState) => ({
              ...prevState,
              listOfColorByTracker: listOfColorByTracker,
            }));
          }
        )
      );
    }
  };

  const mapPoints = (item: Tracker.AsObject) => {
    const array: Leaflet.LatLng[] = [];
    if (
      selectedTrackers.length === 0 ||
      selectedTrackers.indexOf(item.id) !== -1
    ) {
      item.routeList.forEach((d) => {
        if (timeFilter.animationMode) {
          if (moment(d.date).isBefore(timeFilter.selectedDate)) {
            array.push(new LatLng(d.lat, d.lng));
          }
        } else {
          array.push(new LatLng(d.lat, d.lng));
        }
      });
    }
    return array;
  };

  const getDot = (item: Tracker.AsObject, index: any) => {
    return (
      item.routeList &&
      (selectedTrackers.length === 0 ||
        selectedTrackers.indexOf(item.id) !== -1) &&
      item.routeList.map((coord: any, index2: any) => {
        return timeFilter.animationMode ? (
          moment(coord.date).isBefore(timeFilter.selectedDate) ? (
            <DotRoute
              key={index2}
              lat={coord.lat}
              lng={coord.lng}
              item={item}
              listOfColorByTracker={state.listOfColorByTracker}
              zoom={currentZoom}
            ></DotRoute>
          ) : (
            defaultValue
          )
        ) : (
          <DotRoute
            key={index2}
            lat={coord.lat}
            lng={coord.lng}
            item={item}
            listOfColorByTracker={state.listOfColorByTracker}
            zoom={currentZoom}
          ></DotRoute>
        );
      })
    );
  };

  const getKilometersXHour = (
    lat1,
    lng1,
    lat2,
    lng2,
    currentTime,
    lastTime
  ) => {
    if (lat2) {
      var time =
        (moment(currentTime).toDate().getTime() -
          moment(lastTime).toDate().getTime()) /
        1000;
      var minutes = time / 60;
      var hour = minutes / 60;
      return Math.abs(calcCrow(lat1, lng1, lat2, lng2) / hour).toFixed(0);
    } else {
      return "0";
    }
  };

  const calcCrow = (lat1: any, lon1: any, lat2: any, lon2: any) => {
    var R = 6371; // km
    var dLat = toRad(lat2 - lat1);
    var dLon = toRad(lon2 - lon1);
    lat1 = toRad(lat1);
    lat2 = toRad(lat2);

    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
  };

  // Converts numeric degrees to radians
  const toRad = (value: any) => {
    return (value * Math.PI) / 180;
  };

  const getCarMarker = (item: Tracker.AsObject, index: any) => {
    if (
      item.routeList &&
      (selectedTrackers.length === 0 ||
        selectedTrackers.indexOf(item.id) !== -1)
    ) {
      if (timeFilter.animationMode) {
        let routeList = item.routeList.filter((e: Position.AsObject) => {
          return moment(e.date).isBefore(timeFilter.selectedDate);
        });
        if (routeList.length > 0)
          return (
            <Marker
              key={item.id}
              position={[
                routeList[routeList.length - 1].lat || 0,
                routeList[routeList.length - 1].lng || 0,
              ]}
              icon={L.divIcon({
                html: renderToString(
                  <div>
                    <div className="car-marker">
                      <div className="box-marker-position">
                        <span>
                          {getKilometersXHour(
                            routeList[routeList.length - 1].lat,
                            routeList[routeList.length - 1].lng,

                            routeList[routeList.length - 2]
                              ? routeList[routeList.length - 2].lat
                              : defaultValue,
                            routeList[routeList.length - 2]
                              ? routeList[routeList.length - 2].lng
                              : defaultValue,
                            routeList[routeList.length - 1],

                            routeList[routeList.length - 2]
                              ? routeList[routeList.length - 2].date
                              : defaultValue
                          ) + " km/h"}
                        </span>
                      </div>

                      <div className="image-box-marker">
                        <input
                          type="image"
                          src={
                            teams.find((e: any) => e.id === item.teamid) &&
                            teams.length > 0 &&
                            teams.find((e: any) => e.id === item.teamid)
                              ?.imagepath
                              ? teams.find((e: any) => e.id === item.teamid)
                                  ?.imagepath
                              : TeamImg
                          }
                          className="input-team-marker"
                          style={{
                            // transform: `rotate(${this.props.item.currentPosition.direction}deg)`,
                            // height: `${3 * this.props.zoom}px`,
                            // width: `${2.28 * this.props.zoom}px`,
                            height: `${2.5 * currentZoom}px`,
                            width: `${2.5 * currentZoom}px`,
                          }}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                ),
                iconSize: new L.Point(30, 30),
                className: "leaflet-div-icon-unSelected marker-level-" + index,
              })}
              onclick={() => {
                // end(item);
              }}
            ></Marker>
            // <RoutePing
            //   key={index}
            //   lat={routeList[routeList.length - 1].lat}
            //   lng={routeList[routeList.length - 1].lng}
            //   lat1={routeList[routeList.length - 1].lat}
            //   lng1={routeList[routeList.length - 1].lng}
            //   lat2={
            //     routeList[routeList.length - 2]
            //       ? routeList[routeList.length - 2].lat
            //       : defaultValue
            //   }
            //   lng2={
            //     routeList[routeList.length - 2]
            //       ? routeList[routeList.length - 2].lng
            //       : defaultValue
            //   }
            //   zoom={currentZoom}
            //   currentTime={routeList[routeList.length - 1].date}
            //   lastTime={
            //     routeList[routeList.length - 2]
            //       ? routeList[routeList.length - 2].date
            //       : defaultValue
            //   }
            //   item={item}
            // ></RoutePing>
          );
        else return defaultValue;
      } else {
        if (
          item.currentposition &&
          item.currentposition.lat &&
          item.currentposition.lng
        )
          return (
            <Marker
              key={item.id}
              position={[
                item.currentposition?.lat || 0,
                item.currentposition?.lng || 0,
              ]}
              icon={L.divIcon({
                html: renderToString(
                  <div>
                    <div className="car-marker">
                      <div className="box-marker-position">
                        <span>
                          {getKilometersXHour(
                            item.currentposition.lat,
                            item.currentposition.lng,
                            defaultValue,
                            defaultValue,
                            item.currentposition.date,
                            defaultValue
                          ) + " km/h"}
                        </span>
                      </div>

                      <div className="image-box-marker">
                        <input
                          type="image"
                          src={
                            teams.find((e: any) => e.id === item.teamid) &&
                            teams.length > 0 &&
                            teams.find((e: any) => e.id === item.teamid)
                              ?.imagepath
                              ? teams.find((e: any) => e.id === item.teamid)
                                  ?.imagepath
                              : TeamImg
                          }
                          className="input-team-marker"
                          style={{
                            // transform: `rotate(${this.props.item.currentPosition.direction}deg)`,
                            // height: `${3 * this.props.zoom}px`,
                            // width: `${2.28 * this.props.zoom}px`,
                            height: `${2.5 * currentZoom}px`,
                            width: `${2.5 * currentZoom}px`,
                          }}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                ),
                iconSize: new L.Point(30, 30),
                className: "leaflet-div-icon-unSelected marker-level-" + index,
              })}
              onclick={() => {
                // end(item);
              }}
            ></Marker>
            // <RoutePing
            //   key={index}
            //   lat={item.currentposition.lat}
            //   lng={item.currentposition.lng}
            //   lat1={item.currentposition.lat}
            //   lng1={item.currentposition.lng}
            //   lat2={defaultValue}
            //   lng2={defaultValue}
            //   zoom={currentZoom}
            //   currentTime={item.currentposition.date}
            //   lastTime={defaultValue}
            //   item={item}
            // ></RoutePing>
          );
        else return defaultValue;
      }
    } else return defaultValue;
  };

  const getStartMarker = (item: Tracker.AsObject, index: any) => {
    if (
      item.routeList &&
      (selectedTrackers.length === 0 ||
        selectedTrackers.indexOf(item.id) !== -1)
    ) {
      if (timeFilter.animationMode) {
        let routeList = item.routeList.filter((e: any) => {
          return moment(e.date).isBefore(timeFilter.selectedDate);
        });

        if (routeList.length > 0)
          return (
            <Marker
              key={item.id}
              position={[routeList[0].lat || 0, routeList[0].lng || 0]}
              icon={L.divIcon({
                html: renderToString(
                  <div
                    className={
                      "end-start-marker m" +
                      (state.listOfColorByTracker[item.id]
                        ? state.listOfColorByTracker[item.id]
                            .substr(1)
                            .toUpperCase()
                        : "000")
                    }
                    data-tip
                    data-for="sadFace"
                    style={{
                      height: `${4.5 * currentZoom}px`,
                      width: `${3.5 * currentZoom}px`,
                    }}
                  >
                    <div>
                      {1 === 1 ? (
                        <StartMarker className="marker-svg"></StartMarker>
                      ) : (
                        <EndMarker className="marker-svg"></EndMarker>
                      )}
                    </div>
                  </div>
                ),
                iconSize: new L.Point(30, 30),
                className: "leaflet-div-icon-unSelected marker-level-" + index,
              })}
              onclick={() => {
                // end(item);
              }}
            ></Marker>
            // <EndStartMarker
            //   key={index}
            //   lat={routeList[0].lat}
            //   lng={routeList[0].lng}
            //   item={item}
            //   type={1}
            //   listOfColorByTracker={state.listOfColorByTracker}
            //   zoom={currentZoom}
            // ></EndStartMarker>
          );
      } else {
        if (item.routeList.length > 0)
          return (
            <Marker
              key={item.id}
              position={[
                item.routeList[0].lat || 0,
                item.routeList[0].lng || 0,
              ]}
              icon={L.divIcon({
                html: renderToString(
                  <div
                    className={
                      "end-start-marker m" +
                      (state.listOfColorByTracker[item.id]
                        ? state.listOfColorByTracker[item.id]
                            .substr(1)
                            .toUpperCase()
                        : "000")
                    }
                    data-tip
                    data-for="sadFace"
                    style={{
                      height: `${4.5 * currentZoom}px`,
                      width: `${3.5 * currentZoom}px`,
                    }}
                  >
                    <div>
                      {1 === 1 ? (
                        <StartMarker className="marker-svg"></StartMarker>
                      ) : (
                        <EndMarker className="marker-svg"></EndMarker>
                      )}
                    </div>
                  </div>
                ),
                iconSize: new L.Point(30, 30),
                className: "leaflet-div-icon-unSelected marker-level-" + index,
              })}
              onclick={() => {
                // end(item);
              }}
            ></Marker>
            // <EndStartMarker
            //   key={index}
            //   lat={item.routeList[0].lat}
            //   lng={item.routeList[0].lng}
            //   item={item}
            //   type={1}
            //   listOfColorByTracker={state.listOfColorByTracker}
            //   zoom={currentZoom}
            // ></EndStartMarker>
          );
      }
    } else return defaultValue;
  };

  const getEndMarker = (item: Tracker.AsObject, index: any) => {
    if (
      item.routeList &&
      (selectedTrackers.length === 0 ||
        selectedTrackers.indexOf(item.id) !== -1)
    ) {
      if (timeFilter.animationMode) {
        let routeList = item.routeList;

        if (routeList.length > 0)
          return (
            <Marker
              key={item.id}
              position={[
                routeList[routeList.length - 1].lat || 0,
                routeList[routeList.length - 1].lng || 0,
              ]}
              icon={L.divIcon({
                html: renderToString(
                  <div
                    className={
                      "end-start-marker m" +
                      (state.listOfColorByTracker[item.id]
                        ? state.listOfColorByTracker[item.id]
                            .substr(1)
                            .toUpperCase()
                        : "000")
                    }
                    data-tip
                    data-for="sadFace"
                    style={{
                      height: `${4.5 * currentZoom}px`,
                      width: `${3.5 * currentZoom}px`,
                    }}
                  >
                    <div>
                      {1 === 1 ? (
                        <StartMarker className="marker-svg"></StartMarker>
                      ) : (
                        <EndMarker className="marker-svg"></EndMarker>
                      )}
                    </div>
                  </div>
                ),
                iconSize: new L.Point(30, 30),
                className: "leaflet-div-icon-unSelected marker-level-" + index,
              })}
              onclick={() => {
                // end(item);
              }}
            ></Marker>
            // <EndStartMarker
            //   key={index}
            //   lat={routeList[routeList.length - 1].lat}
            //   lng={routeList[routeList.length - 1].lng}
            //   item={item}
            //   type={2}
            //   listOfColorByTracker={state.listOfColorByTracker}
            //   zoom={currentZoom}
            // ></EndStartMarker>
          );
        else return defaultValue;
      } else {
        return defaultValue;
      }
    } else return defaultValue;
  };

  useEffect(() => {
    if (selectedLicense) executeCalculation();
    funTeamsToShow();

    var listOfColorByTracker = {};
    trackerList.forEach((e: any, index: any) => {
      listOfColorByTracker = {
        [e.id]: colors[index],
        ...listOfColorByTracker,
      };
    });

    setState((prevState) => ({
      ...prevState,
      listOfColorByTracker: listOfColorByTracker,
      time: 2,
    }));
  }, []);

  useEffect(() => {
    funTeamsToShow();
  }, [trackerList]);

  useEffect(() => {
    if (state.time >= 1 && state.timerActive) {
      setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          time: state.time + 1,
        }));
        tickRoute();
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.timerActive, state.time]);

  const leafletMapRef = React.useRef<Map>(null);
  const ref = createRef<FeatureGroup>();

  return (
    <div className="Map map-route">
      <FilterTop
        timerActive={state.timerActive}
        startRoute={startRoute}
        stopRoute={stopRoute}
        updateDate={updateDate}
        onChangeDate={onChangeDate}
        nextDay={nextDay}
        lastDay={lastDay}
      ></FilterTop>
      <div className="route-box-map">
        <FilterLeft
          listOfColorByTracker={state.listOfColorByTracker}
          handleColorChange={handleColorChange}
          assignTeam={assignTeam}
          assignTracker={assignTracker}
        ></FilterLeft>
        <Map
          style={{ height: "100%", width: "100%" }}
          ref={leafletMapRef}
          center={
            selectedLicense?.licenseSettings?.coords
              ? {
                  lng: selectedLicense.licenseSettings.coords.lat,
                  lat: selectedLicense.licenseSettings.coords.lng,
                }
              : {
                  lng: -117.010139,
                  lat: 32.805197,
                }
          }
          zoom={
            selectedLicense?.licenseSettings?.zoom
              ? selectedLicense.licenseSettings.zoom
              : 16
          }
          zoomControl={false}
        >
          <LayerControlComponent></LayerControlComponent>
          <FeatureGroup ref={ref}>
            <>
              {/* {Zone routes} */}

              {trackerList.map((item: Tracker.AsObject, index: any) => {
                return (
                  <Polyline
                    key={index}
                    color="purple"
                    positions={mapPoints(item)}
                    dashArray="4"
                  ></Polyline>
                );
              })}
              {trackerList.map((item: Tracker.AsObject, index: any) => {
                return getCarMarker(item, index);
              })}
              {trackerList.map((item: Tracker.AsObject, index: any) => {
                return getStartMarker(item, index);
              })}
              {trackerList.map((item: Tracker.AsObject, index: any) => {
                return getEndMarker(item, index);
              })}
            </>
          </FeatureGroup>
        </Map>
      </div>

      {/* <GoogleMapReact
        yesIWantToUseGoogleMapApiInternals
        shouldUnregisterMapOnUnmount={true}
        style={{
          minWidth: "1100px",
          width: "100%",
          height: "97%",
          margin: "0px",
          padding: "0px",
          position: "relative",
        }}
        bootstrapURLKeys={{
          key: GoogleAPIKEY,
        }}
        defaultCenter={state.center}
        defaultZoom={state.zoom}
        center={coords}
        zoom={currentZoom}
        options={state.options}
        onChange={change}
      >
        {trackerList.map((item: any, index: any) => {
          return getDot(item, index);
        })}
        {trackerList.map((item: any, index: any) => {
          return getCarMarker(item, index);
        })}
        {trackerList.map((item: any, index: any) => {
          return getStartMarker(item, index);
        })}
        {trackerList.map((item: any, index: any) => {
          return getEndMarker(item, index);
        })}
      </GoogleMapReact> */}

      {/* <MapContainer
        center={coords}
        zoom={currentZoom}
        style={{
          minWidth: "1100px",
          width: "100%",
          height: "97%",
          margin: "0px",
          padding: "0px",
          position: "relative",
        }}
        onzoomend={(map: any) => {
          let zoom = map.target.getZoom();
          change(zoom);
        }}
      >
        {state.trackers.map((item: any, index: any) => {
          return getDot(item, index);
        })}
        {state.trackers.map((item: any, index: any) => {
          return getCarMarker(item, index);
        })}
        {state.trackers.map((item: any, index: any) => {
          return getStartMarker(item, index);
        })}
        {state.trackers.map((item: any, index: any) => {
          return getEndMarker(item, index);
        })}
      </MapContainer> */}
    </div>
  );
};

export default RoutesMap;
