import React, { useEffect, useState } from "react";
import HeaderDialog from "../components/Header-dialog";
import SelectComponent from "../components/select-component";
import ActionButtons from "../components/Action-buttons";
import "./style.scss";
import { Arrow } from "../../assets/svg/index";
import { isEmpty } from "lodash";
import { netWorking } from "../../firebase/index";
import { ASSIGN_TO_TEAM, ASSIGN_TO_OTHER_TEAM, done } from "../../const/const";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { Report, ReportStatus } from "../../server/report/protos/report_pb";
import { AssignType, Team } from "../../server/team/protos/team_pb";
import { RootStore } from "../../redux/Store";
import { useTranslation } from "react-i18next";
import { grpcAssignToTeam } from "../../redux/teams/teamsActions";
import { licenseI } from "../../redux/licenses/licensesReducer";

interface DialogReAsignarProps {
  onCloseRequest: any;
  markerReport: Report.AsObject;
  assignedReport: any;
  teams: Team.AsObject[];
  showDialogAlertNotification: any;
}

export const DialogReAsignar: React.FunctionComponent<DialogReAsignarProps> = ({
  onCloseRequest,
  markerReport,
  assignedReport,
  teams,
  showDialogAlertNotification,
}: DialogReAsignarProps) => {
  const [state, setState] = useState<{
    selected: null | Team.AsObject;
    alreadySelected: null | Team.AsObject;
    startDate: Date;
  }>({
    selected: null,
    alreadySelected: null,
    startDate: new Date(),
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectedLicense: licenseI | undefined = useSelector(
    (globalState: RootStore) => globalState.licenses.selectedLicense
  );

  const handleChange = (date: Date) => {
    setState((prev) => ({
      ...prev,
      startDate: date,
    }));
  };

  useEffect(() => {
    var selectedTeam = teams.filter(
      (e) => e.tasksList.indexOf(markerReport.reportid) !== -1
    );
    if (selectedTeam.length > 0)
      setState((prev) => ({
        ...prev,
        selected: selectedTeam[0],
        alreadySelected: selectedTeam[0],
      }));
  }, []);

  const selectTeam = (e: Team.AsObject) => {
    setState((prev) => ({
      ...prev,
      selected: e,
    }));
  };

  const assignReport = () => {
    const tempTeam = state.selected;
    const tempReport = markerReport;
    const index = tempTeam?.tasksList.indexOf(markerReport.reportid);
    if (index !== -1) {
      showDialogAlertNotification(tempTeam);
    } else {
      var tempTeamSelected = teams.filter(
        (e: Team.AsObject) => e.tasksList.indexOf(markerReport.reportid) !== -1
      );
      // if (markerReport.reportstatus === ReportStatus.REPORT_COMPLETED) {
      //   // netWorking.post(netWorking.statistisDoneRemove, {
      //   //   districtId: selectedLicense._id,
      //   //   report: markerReport,
      //   // });
      // }
      if (tempTeamSelected !== null && !isEmpty(tempTeamSelected)) {
        var temp = tempTeamSelected[0];
        dispatch(
          grpcAssignToTeam(
            {
              id: temp.id,
              ids: [markerReport.reportid],
              type: AssignType.REMOVE_FROM_TEAM_TASKS,
            },
            () => {
              dispatch(
                grpcAssignToTeam(
                  {
                    id: tempTeam?.id || "",
                    ids: [markerReport.reportid],
                    type: AssignType.ASSIGN_TO_TEAM_TASKS,
                  },
                  () => {}
                )
              );
            }
          )
        );
        // netWorking.updateTeamAssignedReports({
        //   teamId: tempTeam?.id,
        //   teamIdToRemove: temp.id,
        //   reportsIds: [markerReport.reportid],
        //   assign: ASSIGN_TO_OTHER_TEAM,
        //   createNotification: false,
        // });
      } else {
        dispatch(
          grpcAssignToTeam(
            {
              id: tempTeam?.id || "",
              ids: [markerReport.reportid],
              type: AssignType.ASSIGN_TO_TEAM_TASKS,
            },
            () => {}
          )
        );
        // netWorking.updateTeamAssignedReports({
        //   teamId: tempTeam?.id,
        //   reportsIds: [markerReport.reportid],
        //   assign: ASSIGN_TO_TEAM,
        //   createNotification: false,
        // });
      }

      assignedReport(tempReport, tempTeam);
      onCloseRequest();
    }
  };

  const nextDay = () => {
    var date = state.startDate;
    date.setDate(date.getDate() + 1);
    handleChange(date);
  };

  const lastDay = () => {
    var date = state.startDate;
    date.setDate(date.getDate() - 1);
    handleChange(date);
  };

  const clickableFun = () => {
    if (state.alreadySelected === null && state.selected !== null) {
      return true;
    } else if (
      state.alreadySelected !== null &&
      state.alreadySelected.id !== state.selected?.id
    ) {
      return true;
    } else return false;
    // state.selected !== null &&
    //   (state.alreadySelected === null ||
    //     state.alreadySelected === undefined) &&
    //   state.alreadySelected.id !== state.selected.id;
  };

  return (
    <div className="content-dialog">
      <HeaderDialog
        onCloseRequest={onCloseRequest}
        title={t("name.reassigned")}
        description={
          t("notification.report.already.assigned.to") +
          (state.alreadySelected !== null && state.alreadySelected !== undefined
            ? state.alreadySelected.name
            : t("notification.report.already.assigned.none")) +
          t("notification.report.already.assigned.to1")
        }
      ></HeaderDialog>
      <div className="content-options">
        <div className="content-options-body">
          {/* {isEmpty(props.teams) && (
              <div className="options-body-item">
                <div className="selector-box">
                  <SelectComponent
                    initialData={"Sin equipos creados. Crea uno nuevo"}
                    data={["A", "B", "C", "A", "B", "C"]}
                  ></SelectComponent>
                </div>
                <div className="selector-button-box">
                  <BigButton data={"Crear nuevo"}></BigButton>
                </div>
              </div>
            )} */}
          <div className="options-body-item ">
            <div className="selector-box full-width-select">
              <SelectComponent
                initialData={t("form.select.team.name")}
                selected={state.selected}
                selectTeam={selectTeam}
                data={teams.filter(
                  (e: Team.AsObject) =>
                    e.jobsList.indexOf(markerReport.reporttypeid) !== -1 &&
                    e.tasksList.indexOf(markerReport.reportid) === -1
                )}
              ></SelectComponent>
            </div>
          </div>
          {/* <div className="options-body-item-big ">
            <div className="selector-box full-width-select half-text-field-1"></div>
            <div className="selector-box full-width-select half-text-field-2  date-picker">
              <DatePicker
                className={"text-field"}
                selected={state.startDate}
                onChange={handleChange}
              />
              <Arrow className="left-arrow" onClick={lastDay}></Arrow>
              <Arrow className="right-arrow" onClick={nextDay}></Arrow>
            </div>
          </div> */}
          <ActionButtons
            clickable={clickableFun()}
            doneText={t("submit.continue")}
            doneClick={assignReport}
            cancelClick={onCloseRequest}
            cancelText={t("submit.cancel")}
          ></ActionButtons>
        </div>
      </div>
    </div>
  );
};

export default DialogReAsignar;
