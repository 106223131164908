import React, { useState, createRef } from "react";
import moment from "moment";

import { pending, inProgress, done } from "../../../const/const";
import { useDispatch, useSelector } from "react-redux";
import { MenuIcon } from "../../../assets/svg/index";
import { NotificationApp } from "../../../server/notification/protos/notification_pb";
import { useTranslation } from "react-i18next";
import { Report, WorkerLog } from "../../../server/report/protos/report_pb";
// import Basura from "../assets/basura.jpg";

interface CommentaryProps {
  notification: NotificationApp.AsObject;
  type: any;
  markerReport: Report.AsObject;
  editCommentary: any;
  deleteCommentaryConfirmation: any;
  currentDistrict: any;
}

export const Commentary: React.FunctionComponent<CommentaryProps> = ({
  notification,
  type,
  markerReport,
  editCommentary,
  deleteCommentaryConfirmation,
  currentDistrict,
}: CommentaryProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let defaultValue: any;

  const exampleRef = createRef<HTMLDivElement>();

  const [state, setState] = useState({
    showDialogComment: false,
  });

  const getCommentary = () => {
    if (notification.notificationtype === pending)
      return "Hemos recibido tu reporte, estaremos en contacto para seguir tu reporte.\nGracias por tu denuncia, mejoramos la ciudad.";
    if (notification.notificationtype === inProgress)
      return "El reporte esta en proceso. \nGracias por tu denuncia";
    if (notification.notificationtype === done)
      return "El reporte ha sido solucionado. \nGracias por tu denuncia 😄";
  };

  const GetTime = (e: any) => {
    // var MyDate = Date(moment(e).toDate().getTime() * 1000);
    let time = (moment(e).toDate().getTime() - Date.now()) / 1000;
    // var year = Math.abs(Math.round(time / (60 * 60 * 24) / 365.25));
    let month = Math.abs(Math.round(time / (60 * 60 * 24 * 7 * 4)));
    let days = Math.abs(Math.round(time / (3600 * 24)));
    let minutes = Math.abs(Math.round(time / 60));
    if (month !== 0) {
      if (month >= 1)
        return t("time.start") + month + t("time.months") + t("time.end");
      else return t("time.start") + month + t("time.month") + t("time.end");
    } else if (days !== 0) {
      if (days >= 1)
        return t("time.start") + days + t("time.days") + t("time.end");
      else return t("time.start") + days + t("time.day") + t("time.end");
    } else if (minutes !== 0) {
      let hour = Math.abs(minutes / 60);
      if (hour >= 1) {
        if (hour >= 1)
          return (
            t("time.start") + Math.round(hour) + t("time.hours") + t("time.end")
          );
        else
          return (
            t("time.start") + Math.round(hour) + t("time.hour") + t("time.end")
          );
      } else {
        if (minutes >= 1)
          return t("time.start") + minutes + t("time.minutes") + t("time.end");
        else
          return t("time.start") + minutes + t("time.minute") + t("time.end");
      }
    } else {
      return t("time.now");
    }
  };

  const showDialog = () => {
    setState((prevState) => ({
      ...prevState,
      showDialogComment: !state.showDialogComment,
    }));
    /* () => { */
    if (exampleRef.current)
      exampleRef.current.scrollTop = exampleRef.current.scrollHeight;
    /* } */
  };

  const getBody = () => {
    return notification.body;
  };

  const getIcon = () => {
    let commentaryError = "";
    if (notification.body === commentaryError)
      return (
        <img
          src={"https://miciudad.s3-us-west-1.amazonaws.com/problem.png"}
          className="img-alert"
          alt=""
        />
      );
    else return defaultValue;
  };

  return (
    <div className="commentary">
      <div
        className="comment-header "
        onClick={type === 1 ? showDialog : () => {}}
      >
        <div className="comment-info">
          <img
            src={
              notification.image
                ? notification.image
                : "https://miciudad.s3-us-west-1.amazonaws.com/profile.png"
            }
            alt=""
          />
          <div>
            <div className="comment-name">
              {type === 1 ? currentDistrict.name : notification.reporttitle}
            </div>
            <div className="comment-date">
              {GetTime(notification.createdat)}
            </div>
          </div>
        </div>
        {type === 1 ? <MenuIcon></MenuIcon> : getIcon()}
      </div>
      <div className="comment-body">{getBody()}</div>
      {state.showDialogComment && (
        <div className="hidden-wall" onClick={showDialog}></div>
      )}
      {state.showDialogComment && (
        <div className="options-box" ref={exampleRef}>
          {/* <div className="clear-item" onClick={this.showDialog}></div> */}
          <div
            className="menu-item"
            onClick={() => {
              showDialog();
              deleteCommentaryConfirmation(notification);
            }}
          >
            {t("name.commentary.delete")}
          </div>
          <div
            className="menu-item"
            onClick={() => {
              showDialog();
              editCommentary(notification);
            }}
          >
            {t("name.commentary.edit")}
          </div>
        </div>
      )}
    </div>
  );
};

export default Commentary;
