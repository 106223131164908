import React, { useState, useEffect, createRef } from "react";
import queryString from "query-string";
import moment from "moment";
import "./style.scss";
import "leaflet/dist/leaflet.css";
import { Map, Marker } from "react-leaflet";
import L from "leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import NotificationGlobalDialog from "../dialogs/Notification-global-dialog";
import NotificationAlertDialog from "../dialogs/Notification-alert-dialog";
import Filter from "./components/Filter";
import { ReportTypes } from "../../redux/reportTypes/reportsReducer";
import { RootStore } from "../../redux/Store";
import { useDispatch, useSelector } from "react-redux";
import {
  Report,
  ReportStatus,
  ReportWorkStatus,
  WorkerLog,
} from "../../server/report/protos/report_pb";
import { licenseI } from "../../redux/licenses/licensesReducer";
import { useTranslation } from "react-i18next";
import {
  grpcFetchNotifications,
  // grpcListenToNotifications,
} from "../../redux/notifications/notificationsAction";
import { RequestType } from "../../server/notification/protos/notification_pb";
import { PendingRoomResponse } from "../../server/chat/protos/chat_pb";
import FilterCard from "./components/FilterCard";
import { AssignType, Team } from "../../server/team/protos/team_pb";
import { grpcAssignToTeam } from "../../redux/teams/teamsActions";
import { User } from "../../server/user/protos/user_pb";
import LayerControlComponent from "../../components/general-component/layerControl";
import { renderToString } from "react-dom/server";
import { grpcFetchChatMessages } from "../../redux/chat/chatAction";
import { SET_ROOM_ID } from "../../redux/chat/chatTypes";
import { DelayedIcon, ProblemIcon } from "../../assets/img";
import { Channels, RequestMessage } from "../../websockets/websockets.model";
import { NotificationRequest, ReportType } from "../../websockets/models/notifications.model";

interface ReportsProps {
  reportTypes: any;
  defaultFilter: any;
  updateFilter: any;
  selectedLicense: any;
  reports: any;
  teams: any;
  trackers: any;
  trackersYesterday: any;
  workers: any;
  filterType: any;
  updateCoords: any;
  executeCalculation: any;
  Coords: any;
  currentZoom: any;
  setCenter: any;
  updateCurrentZoom: any;
  showTime: any;
  socket: any;
  location: any;
  history: any;
  selectedTeam: any;
  t: any;
  menuOpen: boolean;
  subscribeToChannel: (request: RequestMessage) => void;
  unsubscribeFromChannel: (request: RequestMessage) => void;
}

export const Reports: React.FunctionComponent<ReportsProps> = ({
  reportTypes,
  teams,
  workers,
  filterType,
  updateCoords,
  executeCalculation,
  currentZoom,
  setCenter,
  updateCurrentZoom,
  location,
  history,
  menuOpen,
  subscribeToChannel,
  unsubscribeFromChannel,
}: ReportsProps) => {
  let defaultValueArray: any = [];
  let defaultValue: any;
  let positionDefault: string | undefined;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const refComments = createRef<HTMLDivElement>();
  const leafletMapRef = React.useRef<Map>(null);

  const reportsR: Report.AsObject[] = useSelector(
    (globalState: RootStore) => globalState.reportTypes.reports
  );
  const viewShowTime: boolean = useSelector(
    (globalState: RootStore) => globalState.reportTypes.viewShowTime
  );

  const reportsTypes: ReportTypes[] = useSelector(
    (globalState: RootStore) => globalState.reportTypes.reportTypesGeneral
  );
  const viewFilters: {
    pending: boolean;
    assigned: boolean;
    progress: boolean;
    completed: boolean;
    problem: boolean;
    paused: boolean;
  } = useSelector(
    (globalState: RootStore) => globalState.reportTypes.viewFilters
  );
  const filtersSelected: string[] = useSelector(
    (globalState: RootStore) => globalState.reportTypes.filtersSelected
  );

  const selectedLicense: licenseI | undefined = useSelector(
    (globalState: RootStore) => globalState.licenses.selectedLicense
  );

  const pendingRooms: PendingRoomResponse.UnseenRoom.AsObject[] = useSelector(
    (globalState: RootStore) => globalState.chat.pendingRooms
  );

  const reportTypesGeneralR: ReportTypes[] = useSelector(
    (globalState: RootStore) => globalState.reportTypes.reportTypesGeneral
  );

  const [intervalDistance, setIntervalDistance] = useState(defaultValue);
  const [maxInterval, setMaxInterval] = useState(defaultValue);
  const [params, setParams] = useState(defaultValue);
  const [state, setState] = useState<{
    center: {
      lat: 32.805197;
      lng: -117.010139;
    };
    coords: {
      lat: 32.805197;
      lng: -117.010139;
    };
    zoom: 11;
    currentZoom: 11;
    position: string | undefined;
    notificationDialog: boolean;
    assignedReport: Report.AsObject | undefined;
    toTeam: Team.AsObject | undefined;
    notifications: undefined;
    users: User.AsObject[];
    usersId: string[];
    notificationAlertDialog: boolean;
    alreadyAssignedTeam: Team.AsObject | undefined;
    groupReportsActive: boolean;
    reportsAdded: Report.AsObject[];
    visible: boolean;
    options: {
      clickableIcons: boolean;
    };
    time: 1;
    assignerTeam: Team.AsObject | undefined;
    showRouteTo: boolean;
    partialRouteTeam: Team.AsObject | undefined;
    fullRouteTeam: Team.AsObject | undefined;
    reportNotificationRoom: "";
    menuOpen: boolean;
    firstZoom: boolean;
  }>({
    center: {
      lat: 32.805197,
      lng: -117.010139,
    },
    coords: {
      lat: 32.805197,
      lng: -117.010139,
    },
    zoom: 11,
    currentZoom: 11,
    position: positionDefault,
    notificationDialog: false,
    assignedReport: defaultValue,
    toTeam: defaultValue,
    notifications: defaultValueArray,
    users: defaultValueArray,
    usersId: defaultValueArray,
    notificationAlertDialog: false,
    alreadyAssignedTeam: defaultValue,
    groupReportsActive: false,
    reportsAdded: [],
    visible: false,
    options: {
      clickableIcons: false,
    },
    time: 1,
    assignerTeam: defaultValue,
    showRouteTo: defaultValue,
    partialRouteTeam: defaultValue,
    fullRouteTeam: defaultValue,
    reportNotificationRoom: "",
    menuOpen: true,
    firstZoom: true,
  });

  const tick = () => {
    if (selectedLicense) {
      executeCalculation();
    }
  };

  const showDialogAlertNotification = (e: any) => {
    setState((prevState) => ({
      ...prevState,
      notificationAlertDialog: !state.notificationAlertDialog,
      alreadyAssignedTeam: e,
    }));
  };

  const getNotifications = (report: Report.AsObject) => {
    dispatch(
      grpcFetchNotifications(
        {
          id: report.reportid,
          type: RequestType.BY_REPORTID,
          limit: 100,
          offset: 0,
        },
        () => { }
      )
    );

    const channel = Channels.ListenNotifications;
    const request: RequestMessage = {
      data: {
        _id: report.reportid,
        report_type: ReportType.BY_REPORTID,
      } as NotificationRequest,
      channel,
    };
    unsubscribeFromChannel(request);
    subscribeToChannel(request);
  };

  const updateUser = (e: any, report: any) => {
    let users: any = [];
    let usersId: any = [];
    state.users.forEach((item: any) => {
      if (item._id !== e) {
        users.push(item);
        usersId.push(item._id);
      }
    });
    setState((prevState) => ({
      ...prevState,
      users: users,
      usersId: usersId,
    }));
  };

  const getMarker = (item: Report.AsObject) => {
    let reportType = reportsTypes.find((e: ReportTypes) => {
      return e.reporttypeid === item.reporttypeid;
    });
    if (reportType) return reportType.iconmap;
    else return defaultValue;
  };

  const checkAllStopped = (workerStatus: any) => {
    workerStatus.values.forEach((e: any) => {
      if (e.status === true) return false;
    });
    return true;
  };


  const getCreatedTime = (item: any) => {
    let actualDate = moment(Date.now());
    let createdDate = moment(
      moment.unix(item.createdat.seconds).toLocaleString()
    ).toDate();

    let differenceTime = moment.duration(actualDate.diff(createdDate));
    // let timeAsMiliseconds = differenceTime.asMilliseconds();
    let timeAsMinutes = differenceTime.asMinutes();
    let timeAsHours = differenceTime.asHours();
    let timeAsDays = differenceTime.asDays();
    let timeAsMonth = differenceTime.asMonths();
    let timeAsYears = differenceTime.asYears();

    let diffLabel = "";

    if (Math.floor(timeAsHours) < 1) {
      diffLabel = Math.floor(timeAsMinutes) + "m";
    }
    if (Math.floor(timeAsHours) >= 1) {
      let remainingMinutes = Math.floor(timeAsMinutes) % 60;
      diffLabel = Math.floor(timeAsHours) + "h " + remainingMinutes + "m";
    }
    if (Math.floor(timeAsDays) >= 1) {
      let remainingHours = Math.floor(timeAsHours) % 24
      diffLabel = Math.floor(timeAsDays) + "d " + remainingHours + "h"
    }
    if (Math.floor(timeAsMonth) >= 1) {
      diffLabel = Math.floor(timeAsMonth) + "m";
    }
    if (Math.floor(timeAsYears) >= 1) {
      diffLabel = Math.floor(timeAsYears) + "y";
    }

    return diffLabel;
  };


  const getMiniIcon = (item: Report.AsObject) => {
    let valid = false;
    if (viewFilters.problem && viewFilters.paused) {
      if (item.reportstatus === ReportStatus.REPORT_IN_PROGRESS && item.problem)
        return true;
      else if (
        item.reportstatus === ReportStatus.REPORT_IN_PROGRESS &&
        item.workerlogsList &&
        item.workerlogsList.length > 0
      ) {
        if (
          item.workerlogsList[item.workerlogsList.length - 1].status ===
          WorkerLog.WorkerLogStatus.STATUS_STOPPED
        )
          return true;
        else return false;
      } else return false;
    }
    if (viewFilters.problem) {
      if (item.reportstatus === ReportStatus.REPORT_IN_PROGRESS && item.problem)
        return true;
      else return false;
    } else if (viewFilters.paused) {
      if (
        item.reportstatus === ReportStatus.REPORT_IN_PROGRESS &&
        item.workerlogsList &&
        item.workerlogsList.length > 0
      ) {
        if (
          item.workerlogsList[item.workerlogsList.length - 1].status ===
          WorkerLog.WorkerLogStatus.STATUS_STOPPED
        )
          return true;
        else return false;
      }
    } else {
      return false;
    }
  };

  const getTypeOfMiniIcon = (item: Report.AsObject) => {
    let valid = false;
    if (viewFilters.problem && viewFilters.paused) {
      if (item.reportstatus === ReportStatus.REPORT_IN_PROGRESS && item.problem)
        return ProblemIcon;
      else if (
        item.reportstatus === ReportStatus.REPORT_IN_PROGRESS &&
        item.workerlogsList &&
        item.workerlogsList.length > 0
      ) {
        if (
          item.workerlogsList[item.workerlogsList.length - 1].status ===
          WorkerLog.WorkerLogStatus.STATUS_STOPPED
        )
          return DelayedIcon;
        else return defaultValue;
      } else return defaultValue;
    }
    if (viewFilters.problem)
      if (item.reportstatus === ReportStatus.REPORT_IN_PROGRESS && item.problem)
        return ProblemIcon;
    if (viewFilters.paused)
      if (
        item.reportstatus === ReportStatus.REPORT_IN_PROGRESS &&
        item.workerlogsList &&
        item.workerlogsList.length > 0
      ) {
        if (
          item.workerlogsList[item.workerlogsList.length - 1].status ===
          WorkerLog.WorkerLogStatus.STATUS_STOPPED
        )
          return DelayedIcon;
        else return defaultValue;
      }
  };

  const getClass = (item: Report.AsObject) => {
    let valid = false;
    if (item.reportstatus === ReportStatus.REPORT_PENDING) {
      if (
        state.reportsAdded.find((e: any) => e._id === item.reportid) !==
        undefined
      ) {
        return "marker-by-type selected-type-2 position-marker";
      } else {
        return "marker-by-type  reported-type-2 position-marker";
      }
    } else if (
      item.reportstatus === ReportStatus.REPORT_IN_PROGRESS &&
      item.workstatus === ReportWorkStatus.WORK_ASSIGNED
    ) {
      if (
        state.reportsAdded.find((e: any) => e._id === item.reportid) !==
        undefined
      ) {
        return "marker-by-type selected-type-2 position-marker";
      } else {
        if (item.problem) {
          return "marker-by-type problem-type-2 position-marker";
        } else {
          return "marker-by-type assigned-type-2 position-marker";
        }
      }
    } else if (
      item.reportstatus === ReportStatus.REPORT_IN_PROGRESS &&
      item.workstatus === ReportWorkStatus.WORK_IN_PROGRESS
    ) {
      if (
        state.reportsAdded.find((e: any) => e._id === item.reportid) !==
        undefined
      ) {
        if (valid) return "marker-by-type selected-type-2 position-marker";
        else return "marker-by-type selected-type-2 position-marker";
      } else {
        if (valid) return "marker-by-type delayed-type-2 position-marker";
        else return "marker-by-type inprogress-type-2 position-marker";
      }
    } else {
      if (
        state.reportsAdded.find((e: any) => e._id === item.reportid) !==
        undefined
      ) {
        return "marker-by-type selected-type-2 position-marker";
      } else {
        return "marker-by-type completed-type-2 position-marker";
      }
    }
  };

  const getColor = (item: Report.AsObject) => {
    let valid = false;
    if (item.reportstatus === ReportStatus.REPORT_PENDING) {
      if (
        state.reportsAdded.find((e: any) => e._id === item.reportid) !==
        undefined
      ) {
        return "#79a5dd";
      } else {
        return "#3a86ff";
      }
    } else if (
      item.reportstatus === ReportStatus.REPORT_IN_PROGRESS &&
      item.workstatus === ReportWorkStatus.WORK_ASSIGNED
    ) {
      if (
        state.reportsAdded.find((e: any) => e._id === item.reportid) !==
        undefined
      ) {
        return "#79a5dd";
      } else {
        return "#ffbe0b";
      }
    } else if (
      item.reportstatus === ReportStatus.REPORT_IN_PROGRESS &&
      item.workstatus === ReportWorkStatus.WORK_IN_PROGRESS
    ) {
      if (
        state.reportsAdded.find((e: any) => e._id === item.reportid) !==
        undefined
      ) {
        if (valid) return "#79a5dd";
        else return "#79a5dd";
      } else {
        if (valid) return "#9353c9";
        else return "#f78d37";
      }
    } else {
      if (
        state.reportsAdded.find((e: any) => e._id === item.reportid) !==
        undefined
      ) {
        return "#79a5dd";
      } else {
        return "#73b71er";
      }
    }
  };

  const end = (item: Report.AsObject) => {
    if (state.groupReportsActive) {
      if (
        item.reportstatus === ReportStatus.REPORT_PENDING ||
        item.reportstatus === ReportStatus.REPORT_IN_PROGRESS
      )
        addOrRemoveReportToGroup(item);
    } else {
      changeReport(item.reportid);
      getNotifications(item);
      setState((prevState) => ({
        ...prevState,
        visible: true,
      }));
      dispatch({
        type: SET_ROOM_ID,
        payload: item.reportid,
      });
      dispatch(grpcFetchChatMessages(item.reportid));
    }
  };

  const getPendingRooms = (item: Report.AsObject) => {
    return pendingRooms.find((d) => {
      return d.roomId === item.reportid;
    });
  };

  const getMarkerByType = (item: Report.AsObject, i: any) => {
    if (
      filtersSelected.length === 0 ||
      filtersSelected.indexOf(item.reporttypeid) !== -1
    ) {
      if (
        !viewFilters.completed &&
        !viewFilters.assigned &&
        !viewFilters.progress &&
        !viewFilters.pending
      ) {
        // console.log(params);
        return iconMarker(item, i);
      } else if (
        viewFilters.pending &&
        ReportStatus.REPORT_PENDING === item.reportstatus
      ) {
        return iconMarker(item, i);
      } else if (
        viewFilters.assigned &&
        ReportStatus.REPORT_IN_PROGRESS === item.reportstatus &&
        item.workstatus === ReportWorkStatus.WORK_ASSIGNED
      ) {
        return iconMarker(item, i);
      } else if (
        viewFilters.progress &&
        ReportStatus.REPORT_IN_PROGRESS === item.reportstatus &&
        item.workstatus === ReportWorkStatus.WORK_IN_PROGRESS
      ) {
        return iconMarker(item, i);
      } else if (
        viewFilters.completed &&
        ReportStatus.REPORT_COMPLETED === item.reportstatus
      ) {
        return iconMarker(item, i);
      }
    }
  };

  const iconMarker = (item: Report.AsObject, i: any) => {
    return (
      <Marker
        key={item.reportid}
        position={[item.geopoint?.latitude || 0, item.geopoint?.longitude || 0]}
        icon={L.divIcon({
          html: renderToString(
            <div
              onClick={() => {
                end(item);
              }}
            >
              {selectedLicense?.licenseSettings?.jobTypes?.indexOf(
                item.reporttypeid
              ) !== -1 ? (
                <div>
                  {getMarker(item) ? (
                    <div
                      className={
                        getClass(item) +
                        (state.position === item.reportid ? " blob" : "")
                      }
                      style={{
                        zIndex: i,
                      }}
                    >
                      {viewShowTime && (
                        <div
                          className="time-released-label"
                          style={{ color: getColor(item) }}
                        >
                          {getCreatedTime(item)}
                        </div>
                      )}
                      {getPendingRooms(item) && (
                        <div className="mini-icon-box-pending">
                          <p>{getPendingRooms(item)?.unseenmessages || 0}</p>
                        </div>
                      )}
                      {getMiniIcon(item) && (
                        <div className="mini-icon-box">
                          <input
                            type="image"
                            src={getTypeOfMiniIcon(item) || ""}
                            alt=""
                          ></input>
                        </div>
                      )}
                      {getMarker(item) && (
                        <input
                          type="image"
                          src={getMarker(item)}
                          alt=""
                        ></input>
                      )}
                      <div className="ring"></div>
                      <div className="ring"></div>
                      <div className="ring"></div>
                      <div className="ring"></div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              ) : (
                <div></div>
              )}
            </div>
          ),
          iconSize: new L.Point(30, 30),
          className: "leaflet-div-icon-unSelected marker-level-" + i,
        })}
        onclick={() => {
          end(item);
        }}
      ></Marker>
    );
  };

  const markerPointer = (item: Report.AsObject, i: any) => {
    if (
      selectedLicense?.licenseSettings?.jobTypes?.indexOf(item.reporttypeid) !==
      -1
    )
      return (
        <div>
          {getMarker(item) && (
            <div
              className={getClass(item)}
              style={{
                zIndex: i,
              }}
            >
              {viewShowTime && (
                <div
                  className="time-released-label"
                  style={{ color: getColor(item) }}
                >
                  {getCreatedTime(item)}
                </div>
              )}
              {getPendingRooms(item) && (
                <div className="mini-icon-box-pending">
                  <p>{getPendingRooms(item)?.unseenmessages || 0}</p>
                </div>
              )}
              {getMiniIcon(item) && (
                <div className="mini-icon-box">
                  <input
                    type="image"
                    src={getTypeOfMiniIcon(item) || ""}
                    alt=""
                  ></input>
                </div>
              )}
              {getMarker(item) && (
                <input type="image" src={getMarker(item)} alt=""></input>
              )}
            </div>
          )}
        </div>
      );
    else return <div></div>;
  };

  const assignedReport = (report: any, team: any) => {
    setState((prevState) => ({
      ...prevState,
      assignedReport: report,
      toTeam: team,
      notificationDialog: true,
    }));
  };

  const clearAssignedReport = () => {
    setState((prevState) => ({
      ...prevState,
      assignedReport: defaultValue,
      toTeam: defaultValue,
      notificationDialog: false,
    }));
  };

  const undoAssignReport = (report: Report.AsObject, team: Team.AsObject) => {
    // const tempReport = report;
    dispatch(
      grpcAssignToTeam(
        {
          id: team.id,
          ids: [report.reportid],
          type: AssignType.ASSIGN_TO_TEAM_TASKS,
        },
        () => {
          clearAssignedReport();
        }
      )
    );
    // TODO: TEST UNDO Team ASSIGN
    // netWorking.updateTeamAssignedReports({
    //   teamId: tempTeam._id,
    //   reportsIds: [report._id],
    //   assign: REMOVE_FROM_TEAM,
    //   createNotification: true,
    // });
    // clearAssignedReport();
  };

  const closeSearchReport = () => {
    history.push("/reports");
  };

  const changeReport = (e?: string) => {
    setState((prevState) => ({
      ...prevState,
      position: e,
      reportNotificationRoom: "",
    }));
  };

  const change = (e: any) => {
    if (!state.firstZoom) {
      if (currentZoom !== e.zoom) {
        updateCurrentZoom(e.zoom);
      }
    } else {
      setState((prevState) => ({
        ...prevState,
        firstZoom: false,
      }));
    }
  };

  const handleClick = (value?: any) => {
    setState((prevState) => ({
      ...prevState,
      visible: value === 1 && value ? false : !prevState.visible,
    }));
    setTimeout(function () {
      window.dispatchEvent(new Event("resize"));
    }, 101);
    leafletMapRef.current?.leafletElement.invalidateSize();
  };

  //This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
  const calcCrow = (lat1: any, lon1: any, lat2: any, lon2: any) => {
    let R = 6371; // km
    let dLat = toRad(lat2 - lat1);
    let dLon = toRad(lon2 - lon1);
    lat1 = toRad(lat1);
    lat2 = toRad(lat2);

    let a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = R * c;
    return d;
  };

  // Converts numeric degrees to radians
  const toRad = (value: any) => {
    return (value * Math.PI) / 180;
  };

  const activateGroupReports = () => {
    setState((prevState) => ({
      ...prevState,
      position: defaultValue,
      groupReportsActive: true,
      reportsAdded: defaultValueArray,
    }));
  };

  const addOrRemoveReportToGroup = (e: Report.AsObject) => {
    let NewArray: Report.AsObject[] = [...state.reportsAdded];
    let inArray = false;
    let counter = 0;
    let existCounter = 0;
    NewArray.forEach((value: any) => {
      if (value.reportid === e.reportid) {
        inArray = true;
        existCounter = counter;
      }
      counter++;
    });
    if (inArray) {
      NewArray.splice(existCounter, 1);
    } else {
      NewArray.push(e);
    }
    setState((prevState) => ({
      ...prevState,
      reportsAdded: NewArray,
    }));
  };

  const clearGroupArray = () => {
    setState((prevState) => ({
      ...prevState,
      reportsAdded: defaultValueArray,
    }));
  };

  const deactivateGroupReports = () => {
    setState((prevState) => ({
      ...prevState,
      groupReportsActive: false,
      reportsAdded: defaultValueArray,
    }));
  };

  const showRoute = (item: any) => {
    if (state.fullRouteTeam && state.fullRouteTeam?.id === item.id)
      setState((prevState) => ({
        ...prevState,
        fullRouteTeam: defaultValue,
      }));
    else
      setState((prevState) => ({
        ...prevState,
        fullRouteTeam: item,
      }));
  };

  // const assignerTeam = (item: any) => {
  //   if (state.assignerTeam && state.assignerTeam?._id === item._id)
  //     setState((prevState) => ({
  //       ...prevState,
  //       assignerTeam: defaultValue,
  //     }));
  //   else
  //     setState((prevState) => ({
  //       ...prevState,
  //       assignerTeam: item,
  //     }));
  // };

  const showPartialRoute = (item: any) => {
    setState((prevState) => ({
      ...prevState,
      partialRouteTeam: item,
    }));
  };

  const changeMenuState = () => {
    setState((prevState) => ({
      ...prevState,
      menuOpen: !state.menuOpen,
    }));
  };

  const changeOnHide = () => {
    setTimeout(function () {
      window.dispatchEvent(new Event("resize"));
    }, 101);
    leafletMapRef.current?.leafletElement.invalidateSize();
  };

  useEffect(() => {
    setIntervalDistance(
      selectedLicense?.trackingSettings.distanceInterval
        ? parseInt(selectedLicense.trackingSettings.distanceInterval)
        : 30
    );
    setMaxInterval(1000 / intervalDistance);
    tick();
  }, []);

  useEffect(() => {
    if (!state.position) {
      tick();
    }
  }, [state.position]);

  useEffect(() => {
    setParams(queryString.parse(location.search));
  }, [location.search]);

  useEffect(() => {
    setTimeout(function () {
      window.dispatchEvent(new Event("resize"));
    }, 101);
    leafletMapRef.current?.leafletElement.invalidateSize();
  }, [menuOpen]);

  const createClusterCustomIcon = function (cluster: any) {
    return L.divIcon({
      html: renderToString(
        <span>
          {cluster.getChildCount()}
          <div className="cluster-marker">
            {/* <PinBlackIcon></PinBlackIcon> */}
          </div>
        </span>
      ),
      className: "marker-cluster-custom",
      iconSize: L.point(30, 30, true),
    });
  };

  return (
    <div className="Map">
      <div className={"map-box"}>
        <Filter
          refComments={refComments}
          markerReport={
            state.position
              ? reportsR.find((d: Report.AsObject) => {
                if (d.reportid === state.position) return d;
              })
              : defaultValue
          }
          specialReport={
            defaultValue
          }
          closeSearchReport={closeSearchReport}
          filterType={filterType}
          getNotifications={getNotifications}
          assignedReport={assignedReport}
          reports={reportsR}
          changeFilter={() => { }}
          visible={state.visible}
          teams={teams}
          workers={workers}
          markerReportFunction={changeReport}
          notifications={state.notifications}
          currentDistrict={selectedLicense}
          showDialogAlertNotification={showDialogAlertNotification}
          location={location}
          groupReportsActive={state.groupReportsActive}
          reportsAdded={state.reportsAdded}
          activateGroupReports={activateGroupReports}
          addOrRemoveReportToGroup={addOrRemoveReportToGroup}
          deactivateGroupReports={deactivateGroupReports}
          clearGroupArray={clearGroupArray}
          handleClick={handleClick}
          users={state.users}
          setCenter={setCenter}
          t={t}
          updateCoords={updateCoords}
          updateUser={updateUser}
          assignerTeam={state.assignerTeam}
          updateCurrentZoom={updateCurrentZoom}
          reportTypes={reportTypes}
        ></Filter>

        <Map
          style={{ height: "100%", width: "100%" }}
          ref={leafletMapRef}
          center={
            selectedLicense?.licenseSettings?.coords
              ? {
                lng: selectedLicense.licenseSettings.coords.lat,
                lat: selectedLicense.licenseSettings.coords.lng,
              }
              : {
                lng: -117.010139,
                lat: 32.805197,
              }
          }
          zoom={
            selectedLicense?.licenseSettings?.zoom
              ? selectedLicense.licenseSettings.zoom
              : 16
          }
          zoomControl={false}
        // bounds={bounds || undefined}
        // boundsOptions={{ padding: [0, 0] }}
        >
          {/* <style>{style}</style> */}
          <LayerControlComponent></LayerControlComponent>
          <MarkerClusterGroup
            showCoverageOnHover={false}
            spiderfyDistanceMultiplier={2}
            iconCreateFunction={createClusterCustomIcon}
            maxClusterRadius={30}
          >
            {reportsR.map((item: any, i: any) => {
              if (
                item.cityid === selectedLicense?._id &&
                reportTypesGeneralR.filter(
                  (f: any) => item.reporttypeid === f.reporttypeid
                ).length > 0
              ) {
                return getMarkerByType(item, i);
              }
            })}
          </MarkerClusterGroup>
        </Map>
        <FilterCard
          reports={reportsR}
          menuOpen={state.menuOpen}
          changeMenuState={changeMenuState}
          onHide={changeOnHide}
          onOpenReport={(report) => {
            if (!!report) end(report)
          }}
        ></FilterCard>
      </div>
      {state.notificationDialog && (
        <NotificationGlobalDialog
          type={1}
          clearAssignedReport={clearAssignedReport}
          undoAssignReport={undoAssignReport}
          showUndo={true}
          assignedReport={state.assignedReport}
          toTeam={state.toTeam}
          message={"notification.report.assigned.to"}
          // successMessage={this.state.successMessage}
          t={t}
          clearMessage={() => { }}
        ></NotificationGlobalDialog>
      )}
      {state.notificationAlertDialog && (
        <NotificationAlertDialog
          type={1}
          t={t}
          alreadyAssignedTeam={state.alreadyAssignedTeam}
          showDialogAlertNotification={showDialogAlertNotification}
        ></NotificationAlertDialog>
      )}
    </div>
  );
};

export default Reports;
