import React, { useEffect } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import "./i18n.js";
import Store from "./redux/Store";
import { Provider } from "react-redux";
import { CheckAuthentication } from "./session/CheckAuthentication";
import { logoutUser } from "./redux/users/usersAction";
import { SET_SUPER_ADMIN, SET_UNAUTHENTICATED } from "./redux/user/userTypes";
import store from "./redux/Store";
import InitialRoute from "./InitialRoute";

const App: React.FC = () => {

  const { i18n } = useTranslation();

  useEffect(() => {
    CheckAuthentication();
    const language = localStorage.getItem("language");
    if (language !== undefined && language !== null) {
      i18n.changeLanguage(language);
    }
  }, [i18n]);

  window.addEventListener("storage", function (event) {
    if (event.key === "logoutEvent") {
      logoutUser();
      store.dispatch({
        type: SET_UNAUTHENTICATED,
      });
      store.dispatch({
        type: SET_SUPER_ADMIN,
        payload: false,
      });
    }
  });

  return (
    <>
      <Provider store={Store}>
        <InitialRoute></InitialRoute>
      </Provider>
    </>
  );
};

export default App;
