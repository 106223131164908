import { NotificationApp } from "../../server/notification/protos/notification_pb";
import {
  NOTIFICATIONS_LOADING,
  NOTIFICATIONS_ERROR,
  NOTIFICATIONS_LIST,
  NOTIFICATIONS_SELECTED,
  NotificationsDispatchTypes,
  NOTIFICATIONS_UPDATES,
} from "./notificationsTypes";

let defaultValue: any;
let defaultValueArray: any = [];

interface notificationsState {
  loading: boolean;
  notifications: NotificationApp.AsObject[];
  selectedNotification: NotificationApp.AsObject;
  teamAssign?: NotificationApp.AsObject;
}

const defaultState: notificationsState = {
  loading: false,
  notifications: [],
  selectedNotification: defaultValue,
};

const notificationsReducer = (
  state: notificationsState = defaultState,
  action: NotificationsDispatchTypes
): notificationsState => {
  switch (action.type) {
    case NOTIFICATIONS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case NOTIFICATIONS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case NOTIFICATIONS_LIST:
      const notificationList: NotificationApp.AsObject[] = [...action.payload];
      const actualNotification: NotificationApp.AsObject =
        state.selectedNotification;

      let defaultTeam: any;

      if (notificationList.length > 0) {
        if (actualNotification) {
          defaultTeam = notificationList.find(
            (notification: NotificationApp.AsObject) => {
              return notification.id === actualNotification.id;
            }
          );
        }
      }
      return {
        ...state,
        notifications: notificationList,
        selectedNotification: defaultTeam,
      };
    case NOTIFICATIONS_SELECTED:
      const selectedNotification: any = action.payload;
      return {
        ...state,
        selectedNotification: selectedNotification,
      };
    case NOTIFICATIONS_UPDATES:
      const array: NotificationApp.AsObject[] = [];
      let inArray = false;
      state.notifications.forEach((d: NotificationApp.AsObject) => {
        if (d.id === action.payload.id) {
          if (!action.payload.ignored) {
            array.push(action.payload);
          }
          inArray = true;
        } else {
          array.push(d);
        }
      });
      if (!inArray) {
        array.push(action.payload);
      }
      return {
        ...state,
        notifications: array,
      };
    default:
      return state;
  }
};

export default notificationsReducer;
