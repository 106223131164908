import React, { useEffect } from "react";
import HeaderDialog from "../components/Header-dialog";
import SelectComponent from "../components/select-component";
import { Arrow } from "../../assets/svg/index";
import ActionButtons from "../components/Action-buttons";
import "./style.scss";
import { ASSIGN_TO_TEAM } from "../../const/const";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { AssignType, Team } from "../../server/team/protos/team_pb";
import { Report } from "../../server/report/protos/report_pb";
import { useTranslation } from "react-i18next";
import { RootStore } from "../../redux/Store";
import { grpcAssignToTeam } from "../../redux/teams/teamsActions";

interface DialogAssignMultiProps {
  reportsAdded: Report.AsObject[];
  deactivateGroupReports: any;
  onCloseRequest: any;
}

export const DialogAssignMulti: React.FunctionComponent<DialogAssignMultiProps> =
  ({
    reportsAdded,
    deactivateGroupReports,
    onCloseRequest,
  }: DialogAssignMultiProps) => {
    const [state, setState] = React.useState<{
      selected: Team.AsObject | undefined;
      startDate: Date;
    }>({
      selected: undefined,
      startDate: new Date(),
    });
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const handleChange = (date) => {
      setState((prev) => ({ ...prev, startDate: date }));
    };

    const teams: Team.AsObject[] = useSelector(
      (globalState: RootStore) => globalState.teams.teams
    );
    useEffect(() => {
      var teamArray: Team.AsObject[] = [];
      var reports: string[] = [];
      reportsAdded.forEach((ra) => {
        var index = reports.indexOf(ra.reporttypeid);
        if (index === -1) reports.push(ra.reporttypeid);
      });

      teams.forEach((mr) => {
        var valid = true;
        reports.forEach((e) => {
          if (mr.jobsList.indexOf(e) === -1) valid = false;
        });
        if (valid) teamArray.push(mr);
      });

      setState((prev) => ({ ...prev, teams: teamArray }));
    }, []);

    const selectTeam = (e: Team.AsObject) => {
      setState((prev) => ({ ...prev, selected: e }));
    };

    const nextDay = () => {
      var date = state.startDate;
      date.setDate(date.getDate() + 1);
      setState((prev) => ({ ...prev, startDate: date }));
    };

    const lastDay = () => {
      var date = state.startDate;
      date.setDate(date.getDate() - 1);
      setState((prev) => ({ ...prev, startDate: date }));
    };

    const assignReport = () => {
      const tempTeam = state.selected;
      var tempReports = reportsAdded;
      var arrayIds: string[] = [];
      tempReports.forEach((d) => {
        arrayIds.push(d.reportid);
      });
      // TODO: TEST MULTI ASSIGN
      dispatch(
        grpcAssignToTeam(
          {
            id: tempTeam?.id || "",
            ids: [...arrayIds],
            type: AssignType.ASSIGN_TO_TEAM_TASKS,
          },
          () => {
            onCloseRequest();
            deactivateGroupReports();
          }
        )
      );
      // netWorking.post(
      //   netWorking.teamMultiAssign,
      //   {
      //     teamId: tempTeam._id,
      //     reportsIds: arrayIds,
      //     assign: ASSIGN_TO_TEAM,
      //     createNotification: true,
      //     districtId: currentDistrict._id,
      //   },
      //   () => {
      //     onCloseRequest();
      //     deactivateGroupReports();
      //   }
      // );
    };

    var Description =
      reportsAdded.length > 1 ? t("name.report") : t("name.reports");
    return (
      <div className="content-dialog">
        <HeaderDialog
          onCloseRequest={onCloseRequest}
          title={t("name.assign.report.plural")}
          description={
            t("name.assign.report.description.1") +
            reportsAdded.length +
            " " +
            Description
          }
        ></HeaderDialog>
        <div className="content-options">
          <div className="content-options-body">
            {/* {isEmpty(state.teams) && (
              <div className="options-body-item">
                <div className="selector-box">
                  <SelectComponent
                    initialData={"Sin grupos creados. Crea uno nuevo"}
                    data={["A", "B", "C", "A", "B", "C"]}
                  ></SelectComponent>
                </div>
                <div className="selector-button-box">
                  <BigButton data={"Crear nuevo"}></BigButton>
                </div>
              </div>
            )} */}
            <div className="options-body-item ">
              <div className="selector-box full-width-select">
                <SelectComponent
                  initialData={t("form.select.team.name")}
                  selected={state.selected}
                  selectTeam={selectTeam}
                  data={teams}
                ></SelectComponent>
              </div>
            </div>
            <div className="options-body-item-big ">
              <div className="selector-box full-width-select half-text-field-1 "></div>
              <div className="selector-box full-width-select half-text-field-2 date-picker">
                <DatePicker
                  className={"text-field"}
                  selected={state.startDate}
                  onChange={handleChange}
                />
                <Arrow className="left-arrow" onClick={lastDay}></Arrow>
                <Arrow className="right-arrow" onClick={nextDay}></Arrow>
              </div>
            </div>
            <ActionButtons
              clickable={state.selected !== null}
              doneText={t("submit.continue")}
              doneClick={assignReport}
              cancelClick={onCloseRequest}
              cancelText={t("submit.cancel")}
            ></ActionButtons>
          </div>
        </div>
      </div>
    );
  };

export default DialogAssignMulti;
