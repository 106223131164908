import React, { useState, Fragment } from "react";

import { AssignIcon, CloseIcon, UnGroup } from "../../../assets/svg/index";
import DialogAssignMulti from "../../dialogs/Dialog-asignar-multi";
import SimpleModal from "../../../utils/dialogs/simple-dialog";
import TileReport from "./Tile-report";
import { Report } from "../../../server/report/protos/report_pb";

interface CardGroupReportProps {
  reportTypes: any;
  groupReportsActive: any;
  teams: any;
  t: any;
  reportsAdded: Report.AsObject[];
  activateGroupReports: any;
  addOrRemoveReportToGroup: any;
  deactivateGroupReports: any;
  workers: any;
  notifications: any;
  assignerTeam: any;
  currentDistrict: any;
  clearGroupArray: any;
  showDialogAlertNotification: any;
  handleClick: any;
}

export const CardGroupReport: React.FunctionComponent<CardGroupReportProps> = ({
  reportTypes,
  groupReportsActive,
  teams,
  t,
  reportsAdded,
  activateGroupReports,
  addOrRemoveReportToGroup,
  deactivateGroupReports,
  workers,
  notifications,
  assignerTeam,
  currentDistrict,
  clearGroupArray,
  showDialogAlertNotification,
  handleClick,
}: CardGroupReportProps) => {
  const [state, setState] = useState({
    reported: false,
    assigned: false,
    showModalAssign: false,
  });

  const reportedVisibility = () => {
    setState((prevState) => ({
      ...prevState,
      reported: !state.reported,
    }));
  };

  const assignedVisibility = () => {
    setState((prevState) => ({
      ...prevState,
      assigned: !state.assigned,
    }));
  };

  /* const closeReport = () => {
    markerReportFunction(null);
  }; */

  const handleToggleModalAssign = () => {
    setState((prevState) => ({
      ...prevState,
      showModalAssign: !state.showModalAssign,
    }));
  };

  return (
    <Fragment>
      <div
        className={`${
          groupReportsActive ? "visible-box" : "hidden-box"
        } card-report`}
      >
        <div className="report-data-card report-data-card-group">
          <div className="report-img">
            <div
              className="close-button no-shadow"
              onClick={() => {
                deactivateGroupReports();
                handleClick();
              }}
            >
              <CloseIcon></CloseIcon>
            </div>
          </div>
          <div className="group-header-box">
            <div className="title-group-header">{t("name.grouped.report")}</div>
            <div className="content-group-header">
              {t("name.content")}: {reportsAdded.length}{" "}
              {reportsAdded.length >= 2 ? t("name.reports") : t("name.report")}
            </div>
            <div className="icons-group-header">
              <div className="assign-box" onClick={clearGroupArray}>
                <UnGroup></UnGroup>
                <p>{t("name.un.group.report")}</p>
              </div>
              <div className="assign-box" onClick={handleToggleModalAssign}>
                <AssignIcon></AssignIcon>
                <p>{t("name.assign")}</p>
              </div>
            </div>
          </div>
          <div className="list-reports">
            {reportsAdded.map((e: Report.AsObject) => {
              return (
                <TileReport
                  item={e}
                  addOrRemoveReportToGroup={addOrRemoveReportToGroup}
                ></TileReport>
              );
            })}
          </div>
        </div>
      </div>
      {state.showModalAssign && (
        <SimpleModal onCloseRequest={() => handleToggleModalAssign()}>
          <DialogAssignMulti
            reportsAdded={reportsAdded}
            deactivateGroupReports={deactivateGroupReports}
            onCloseRequest={() => handleToggleModalAssign()}
            /* assignedReport={assignedReport} */
          ></DialogAssignMulti>
        </SimpleModal>
      )}
    </Fragment>
  );
};

export default CardGroupReport;
