import React from "react";
import "./style.scss";
import { CloseIcon, Ignore } from "../../assets/svg/index.js";


class BDDialog extends React.Component {
  render() {
    return (
      <div className="content-dialog-big dialog-perfil">
        <div className="content-header">
          <div className="header-data">
            <div className="header-content">
              <div className="title-dialog"><Ignore></Ignore>{this.props.Title}</div>
                <div className="description for-dbdialog">
                  {this.props.Description}
                </div>
            </div>
            <div className="close-buttom" onClick={() => this.props.onCloseRequest()}>
              <CloseIcon></CloseIcon>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BDDialog;
