import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
// import FeatherIcon from "feather-icons-react";
import { Arrow } from "../../assets/svg/index";
import { RootStore } from "../../redux/Store";
import { TEAMS_SELECTED_ROUTES } from "../../redux/teams/teamsTypes";
import { Team } from "../../server/team/protos/team_pb";
import { User } from "../../server/user/protos/auth_pb";

// interface SelectComponentTeamsProps {
//   select: any;
//   selected: Team.AsObject;
//   data: Team.AsObject[];
// }

export const SelectComponentTeams: React.FunctionComponent = () => {
  const users: User.AsObject[] = useSelector(
    (globalState: RootStore) => globalState.users.users
  );
  const teams: Team.AsObject[] = useSelector(
    (globalState: RootStore) => globalState.teams.teams
  );
  const selectedTeamRoutes: Team.AsObject = useSelector(
    (globalState: RootStore) => globalState.teams.selectedTeamRoutes
  );

  const [state, setState] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectValue = (item: Team.AsObject) => {
    dispatch({
      type: TEAMS_SELECTED_ROUTES,
      payload: item,
    });
    setState(false);
  };

  const getActiveMembers = (members: any) => {
    let auxCount = 0;

    users.map((user: any) => {
      if (members.indexOf(user.id) !== -1) {
        auxCount++;
      }
    });

    return auxCount;
  };

  return (
    <div className="selector-box-content">
      <button
        className="selector"
        onClick={() => {
          if (state === undefined || !state) setState(!state);
        }}
      >
        <p>
          {selectedTeamRoutes === null || !selectedTeamRoutes
            ? t("name.none")
            : selectedTeamRoutes.name +
              " (" +
              getActiveMembers(selectedTeamRoutes.membersList) +
              ") "}
        </p>
        <Arrow></Arrow>
      </button>
      <div
        className={` ${
          state ? "options-visible" : "options-invisible"
        } selector-options`}
      >
        {teams.map((item: Team.AsObject) => {
          return (
            <button
              key={item.id}
              className="dropdown-item"
              type="button"
              onClick={() => {
                selectValue(item);
              }}
            >
              {item.name + " (" + getActiveMembers(item.membersList) + ") "}
            </button>
          );
        })}
      </div>
      {state && (
        <div
          className="hidden-wall"
          onClick={() => {
            setState(false);
          }}
        ></div>
      )}
    </div>
  );
};
export default SelectComponentTeams;
