import {TimestampChat} from "../websockets/models/chat.model"


export function dateToTimestamp(date: Date): TimestampChat {
    const seconds = Math.floor(date.getTime() / 1000);
    const nanos = date.getMilliseconds() * 1e6;
    return {
      seconds: seconds,
      nanos: nanos,
    };
  }