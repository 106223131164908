// import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';
export interface ReportRequest {
    _ids_report_type: string[];
    listen_type: ListenReportType;
    _id: string;
}

export interface ReportResponse {
    reportId: string;
    reportStatus: ReportStatus;
    reportTypeId: string;
    geoPoint: GeoPoint;
    address: string;
    description: string;
    createdAt: Date;
    reportedBy: string;
    finishedComment: string;
    estimatedTime: number;
    workStatus: ReportWorkStatus;
    workerLogs: WorkerLog[];
    imageUrl: string;
    doneImageUrl: string;
    ignored: boolean;
    cityId: string;
    likeUsers: string[];
    assignedWorkers: string[];
    arrivalImageUrl: string;
    workProgressDate: Date;
    workFinishedDate: Date;
    problem: boolean;
    assignDate: Date;
}

export enum ListenReportType {
    UNKNOWN = "UNKNOWN",
    MINE = "MINE",
    NEAR = "NEAR",
    WORKER = "WORKER",
    LICENSE = "LICENSE"
}

export enum ReportStatus {
    REPORT_UNKNOWN_STATUS = 0,
    REPORT_PENDING = 1,
    REPORT_IN_PROGRESS = 2,
    REPORT_COMPLETED = 3,
}

interface GeoPoint {
    latitude: number;
    longitude: number;
}

export enum ReportWorkStatus {
    WORK_UNKNOWN_STATUS = 0,
    WORK_ASSIGNED = 1,
    WORK_IN_PROGRESS = 2,
    WORK_COMPLETED = 3,
}

export enum WorkerLogStatus {
    STATUS_UNKNOWN = 0,
    STATUS_STARTED = 1,
    STATUS_STOPPED = 2
}

interface WorkerLog {
    workerId: string;
    status: WorkerLogStatus;
    date: Date;
    secondsWorked: number;
}