import React from "react";
import HeaderDialog from "../components/Header-dialog";
import ActionButtons from "../components/Action-buttons";
import "./style.scss";

interface ConfirmationDialogProps {
  title?: any;
  description: any;
  noBold1?: any;
  noBold2?: any;
  boldText?: any;
  doneClick: any;
  onCloseRequest: any;
  t: any;
}

export const ConfirmationDialog: React.FunctionComponent<ConfirmationDialogProps> =
  ({
    title,
    description,
    noBold1,
    noBold2,
    boldText,
    doneClick,
    onCloseRequest,
    t,
  }: any) => {
    let defaultValue: any;

    return (
      <div className="content-dialog-big dialog-perfil">
        <HeaderDialog
          onCloseRequest={onCloseRequest}
          title={title ? title : defaultValue}
          description={description}
          noBold1={noBold1 ? noBold1 : defaultValue}
          noBold2={noBold2 ? noBold2 : defaultValue}
          boldText={boldText ? boldText : defaultValue}
          profile={true}
        ></HeaderDialog>
        <div className="content-options">
          <div className="content-options-body">
            <ActionButtons
              doneText={t("submit.continue")}
              clickable={true}
              doneClick={doneClick}
              cancelClick={onCloseRequest}
              cancelText={t("submit.cancel")}
            ></ActionButtons>
          </div>
        </div>
      </div>
    );
  };

export default ConfirmationDialog;
