import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootStore } from "../../../redux/Store";
import { useTranslation } from "react-i18next";

import TeamImg from "../../../assets/img/team_image.png";
import { colors } from "../../../const/const";
import FeatherIcon from "feather-icons-react";
import { GithubPicker } from "react-color";

interface FilterLeftProps {
  listOfColorByTracker: any;
  handleColorChange: any;
  assignTeam: any;
  assignTracker: any;
}

export const FilterLeft: React.FunctionComponent<FilterLeftProps> = ({
  listOfColorByTracker,
  handleColorChange,
  assignTeam,
  assignTracker,
}: any) => {
  const users: any = useSelector(
    (globalState: RootStore) => globalState.users.users
  );
  const teams: any = useSelector(
    (globalState: RootStore) => globalState.teams.teams
  );
  const trackerList: any = useSelector(
    (globalState: RootStore) => globalState.tracking.trackerList
  );
  const selectedTeams: any = useSelector(
    (globalState: RootStore) => globalState.tracking.selectedTeams
  );
  const selectedTrackers: any = useSelector(
    (globalState: RootStore) => globalState.tracking.selectedTrackers
  );

  const { t } = useTranslation();

  const [state, setState] = useState({
    colorBoxOpen: -1,
  });

  const openBox = (e: any) => {
    if (state.colorBoxOpen === e) {
      e = -1;
    }
    setState((prevState) => ({
      ...prevState,
      colorBoxOpen: e,
    }));
  };

  const itemTeam = (team: any) => {
    var trackersAux: any = [];
    trackerList.forEach((item: any) => {
      if (team.id === item.teamid) trackersAux.push(item);
    });
    return (
      <li
        key={team.id}
        className={" filter-item done-filter-icon filter-group"}
      >
        <div className="team-box">
          <div
            className={
              selectedTeams.indexOf(team.id) !== -1
                ? "arrow-box right-arrow-1 rotate"
                : "arrow-box right-arrow-1"
            }
            onClick={() => {
              if (trackersAux.length > 0) assignTeam(team.id);
            }}
          >
            {" "}
            <FeatherIcon icon="play" size="12" />
          </div>
          <div
            className="item-box"
            onClick={() => {
              if (trackersAux.length > 0) assignTeam(team.id);
            }}
          >
            <img src={team.imagepath ? team.imagepath : TeamImg} alt="" />

            {team.name.toUpperCase()}
          </div>
        </div>

        {trackersAux.length > 0 && selectedTeams.indexOf(team.id) !== -1 && (
          <ul className={"list-show"}>
            {trackersAux.map((e: any) => {
              return itemTracker(e);
            })}
          </ul>
        )}
      </li>
    );
  };

  const itemTracker = (item: any) => {
    var worker: any;
    if (item.userid)
      worker = users.find((e: any) => {
        return e.id === item.userid;
      });
    return (
      <li
        key={item.id}
        className={
          (selectedTrackers.length > 0 &&
          selectedTrackers.indexOf(item.id) !== -1
            ? "filter-selected"
            : "") + " filter-item done-filter-icon"
        }
      >
        <div
          className="color-box"
          style={{
            backgroundColor: listOfColorByTracker[item.id],
          }}
          onClick={() => {
            openBox(item.id);
          }}
        ></div>
        {state.colorBoxOpen === item.id && (
          <GithubPicker
            colors={colors}
            onChangeComplete={(e: any) => {
              handleColorChange(item.id, e.hex, () => {
                openBox(-1);
              });
            }}
          />
        )}
        {worker !== undefined && (
          <div className="item-box" onClick={() => assignTracker(item)}>
            <img src={worker.imagePath ? worker.imagePath : TeamImg} alt="" />

            {worker && " " + worker.name + " " + worker.lastName}
          </div>
        )}
      </li> 
    );
  };

  return (
    <div className={"hide-animation filter-container-left"}>
      <div className="filter-team-box">
        <div className={"filter-header-box  "}> {t("title.all.teams")}</div>
        <ul>
          {teams.map((e: any) => {
            return itemTeam(e);
          })}
        </ul>
      </div>
    </div>
  );
};

export default FilterLeft;
