import { ChatMessage } from "../server/chat/protos/chat_pb";
import { License } from "../server/license/protos/license_pb";
import { NotificationApp } from "../server/notification/protos/notification_pb";
import { Report } from "../server/report/protos/report_pb";
import { Team } from "../server/team/protos/team_pb";
import { dateToTimestamp } from "../utils/convertsDate";
import { ChatMessageResponse, MessageFlag, MessageType } from "./models/chat.model";
import { NotificationResponse } from "./models/notifications.model";
import { ReportResponse, ReportStatus, ReportWorkStatus, WorkerLogStatus } from "./models/report.model";
import { TeamResponse } from "./models/team.model";

export class UtilitiesWS {
    static assignDataChat(chatResponse: any): ChatMessage.AsObject {
        return {
            ...chatResponse,
            createdat: dateToTimestamp(new Date(chatResponse.createdAt)),
            viewedByList: chatResponse.viewedBy,
            flag: MessageFlag[chatResponse?.flag],
            messageType: MessageType[chatResponse?.messageType],
        }
    }

    static assignDataLisence(licenseResponse: any): License.AsObject {
        return {
            ...licenseResponse,
            settings: {
                ...licenseResponse?.settings,
                licensesettings: {
                    ...licenseResponse?.settings?.licensesettings,
                    jobtypesList: licenseResponse?.settings?.licensesettings?.jobTypes
                }
            }
        }
    }

    static assignDataReport(reportResponse: ReportResponse): Report.AsObject {
        return {
            ...reportResponse,
            reportid: reportResponse.reportId,
            reportstatus: ReportStatus[reportResponse.reportStatus],
            reporttypeid: reportResponse.reportTypeId,
            geopoint: reportResponse.geoPoint,
            createdat: dateToTimestamp(new Date(reportResponse.createdAt)),
            reportedby: reportResponse.reportedBy,
            finishedcomment: reportResponse.finishedComment,
            estimatedtime: reportResponse.estimatedTime,
            workstatus: ReportWorkStatus[reportResponse.workStatus],
            imageurl: reportResponse.imageUrl,
            doneimageurl: reportResponse.doneImageUrl,
            cityid: reportResponse.cityId,
            likeusersList: reportResponse.likeUsers,
            assignedWorkersList: reportResponse.assignedWorkers,
            assignDate: dateToTimestamp(new Date(reportResponse.assignDate)),
            workProgressDate: dateToTimestamp(new Date(reportResponse.workProgressDate)),
            workFinishedDate: dateToTimestamp(new Date(reportResponse.workFinishedDate)),
            workerlogsList: reportResponse.workerLogs.map((log) => ({
                status: WorkerLogStatus[log.status],
                workerid: log.workerId,
                date: dateToTimestamp(new Date(log.date)),
                secondsWorked: log.secondsWorked
            }))
        }
    }

    static assignDataTeam(teamResponse: TeamResponse): Team.AsObject {
        return {
            bossList: teamResponse.boss,
            jobsList: teamResponse.jobs,
            membersList: teamResponse.members,
            imagepath: teamResponse.imagePath,
            licenseid: teamResponse.licenseId,
            tasksList: teamResponse.tasks,
            name: teamResponse.name,
            type: teamResponse.type,
            id: teamResponse.id,
        }
    }

    static assignDataNotification(notificationResponse: NotificationResponse): NotificationApp.AsObject {
        return {
            body: notificationResponse.body,
            cityid: notificationResponse.cityId,
            createdat: notificationResponse.createdAt,
            id: notificationResponse.id,
            ignored: notificationResponse.ignored,
            image: notificationResponse.image,
            notificationtype: notificationResponse.notificationType,
            reportid: notificationResponse.reportId,
            reportimage: notificationResponse.reportImage,
            reporttitle: notificationResponse.reportTitle,
            reporttypeid: notificationResponse.reportTypeId,
            status: notificationResponse.status,
        }
    }
}