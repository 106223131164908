import React from "react";
import { PlaceIcon } from "../../../assets/svg/index.js";
import { Bar, Police, User } from "../../../assets/img/index.js";
import FeatherIcon from "feather-icons-react";
// import facture from "../../../assets/img/dummyphotos/facture.jpg";
import { No } from "../../../assets/img/index";

export default class BoxTicket extends React.Component {
  render() {
    return (
      <div className="box-ticket-data margin-x20 ">
        <div className="tile-ticket">
          <div className="body-ticket">
            <div className="body-data">
              <div className="data-box">
                <div className="title-data-box">
                  <img src={Bar} alt=""></img>
                  <div className="text-bold">
                    ID: 901388 - Venta de alcohol a Menores
                  </div>
                </div>
                <div className="description-box">
                  {" "}
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Doloribus voluptatum quos quo facere voluptatibus. Praesentium
                  quos inventore sapiente nam corporis libero molestias
                  architecto aliquid velit saepe, eum dolor, reprehenderit
                  fugit! Lorem ipsum dolor sit, amet consectetur adipisicing
                  elit.
                </div>
                <div className="infractions-box">
                  <p className="title">Infracciones: </p>
                  <p className="item"> 123442. Venta de alcohol a menores</p>
                  <p className="item"> 123442. Venta de alcohol a menores</p>
                </div>
                <div className="details-box">
                  <div className="box-details">
                    <FeatherIcon icon="calendar" size="14" />
                    <div className="text-bold">12 Enero 2020</div>
                  </div>
                  <div className="box-details">
                    <img src={User} alt=""></img>
                    Multado por:
                    <div className="text-bold"> Anonimus</div>
                  </div>
                  <div className="box-details data-address-box">
                    <PlaceIcon title=""></PlaceIcon>
                    <div className=""> dummy direction</div>
                  </div>
                  <div className="box-details">
                    <img src={Police} alt=""></img>
                    Repartido por Insp:
                    <div className="text-bold"> Anonimus</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="body-extra">
              <div className="tag-payments tag-no-payment">
                {" "}
                <img src={No} alt="" />
                No Pagado
              </div>
              {/* <div className="tag-payments tag-correct-payment">
    {" "}
    <img src={Correct} alt="" />
    Pagado
  </div> */}
            </div>
          </div>
          <div className="bottom-ticket">
            <div className="ticket-amount">Total: $1,900</div>
            <div className="button-search btn-done-dialog">
              {this.props.t("name.cashier.search")}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
