import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RootStore } from "../../../../redux/Store";

import { byResponseI } from "../../../../redux/dashboard/dashboardReducer";
import { getMonth } from "../../../../utils/utils";

import Chart from "react-apexcharts";

export const CardChart4: React.FunctionComponent = () => {
  const reportsByResponse: byResponseI | undefined = useSelector(
    (globalState: RootStore) => globalState.dashboard.reportsByResponse
  );

  let defaultValueArray: any = [];

  const { t } = useTranslation();

  const [series, setSeries] = useState(defaultValueArray);
  const [categories, setCategories] = useState(defaultValueArray);

  const options = {
    chart: {
      id: "apexchart-example",
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    legend: {
      show: false,
    },
    fill: {
      type: "gradient",
      gradient: {
        type: "vertical",
        stops: [0, 0],
      },
    },
    tooltip: {
      theme: "dark",
      x: {
        show: true,
      },
      marker: {
        show: false,
      },
      y: {
        formatter: undefined,
        title: {
          formatter: (seriesName: any) => seriesName,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
      colors: "#fff",
      strokeColors: "#7ED321",
      strokeWidth: 2,
      strokeOpacity: 0.9,
      fillOpacity: 1,
      shape: "circle",
      radius: 2,
      hover: {
        size: 8,
        sizeOffset: 3,
      },
    },
    xaxis: {
      categories: categories,
      labels: {
        show: true,
        rotate: -45,
        rotateAlways: false,
        hideOverlappingLabels: true,
        showDuplicates: false,
        trim: true,
        minHeight: undefined,
        maxHeight: 120,
        style: {
          colors: "#0D3768",
          cssClass: "apexcharts-xaxis-label",
        },
        offsetX: 0,
        offsetY: 0,
        format: undefined,
        formatter: undefined,
      },
      axisBorder: {
        show: false,
        color: "#78909C",
        offsetX: 0,
        offsetY: 0,
      },
      axisTicks: {
        show: false,
        borderType: "solid",
        color: "#78909C",
        width: 6,
        offsetX: 0,
        offsetY: 0,
      },
      style: {
        colors: ["#0D3768"],
      },
    },
    yaxis: {
      show: true,
    },
    colors: ["#7ED321"],
  };

  const getSerie = () => {
    let name = t("subtitle.time.by.hour");
    let data: any = [];

    reportsByResponse.responseList.map((val: any) => {
      data.push(val["avgResponseTime"].toFixed(0));
    });

    return { name: name, data: data };
  };

  const getCategory = () => {
    let data: any = [];

    reportsByResponse.responseList.map((val: any) => {
      data.push(getMonth(val["month"], t) + "/" + val["year"]);
    });

    return data;
  };

  useEffect(() => {
    if (reportsByResponse?.responseList) {
      setSeries([getSerie()]);
      setCategories(getCategory());
    }
  }, [reportsByResponse]);

  return <Chart options={options} series={series} type="area" height="100%" />;
};

export default CardChart4;
