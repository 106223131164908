import { Tracker } from "../../server/tracking/protos/tracking_pb";
import moment from "moment";

import {
  TRACKING_LOADING,
  TRACKING_ERROR,
  TRACKING_ACTIVE_DATES,
  TRACKING_LIST,
  TRACKING_SELECTED_TEAMS,
  TRACKING_SELECTED_TRACKERS,
  TRACKING_TIME_FILTER,
  TrackingDispatchTypes,
} from "./trackingTypes";

let defaultValue: any;
let defaultValueArray: any = [];

export interface timeFilterI {
  selectedDate: Date;
  endSelectedDate: Date;
  todayDate: Date;
  timerMultiplayer: number;
  timeLineValue: number;
  animationMode: boolean;
}

interface trackingState {
  loading: boolean;
  activeDates: string[];
  trackerList: Tracker.AsObject[];
  selectedTeams: string[];
  selectedTrackers: string[];
  //*TOP FILTER ELEMENTS*
  timeFilter: timeFilterI;
}

const defaultState: trackingState = {
  loading: false,
  activeDates: defaultValueArray,
  trackerList: defaultValueArray,
  selectedTeams: defaultValueArray,
  selectedTrackers: defaultValueArray,
  //*TOP FILTER ELEMENTS*
  timeFilter: {
    selectedDate: moment(Date.now()).utc(false).startOf("day").toDate(),
    endSelectedDate: moment(Date.now()).utc(false).endOf("day").toDate(),
    todayDate: moment(Date.now()).utc(false).startOf("day").toDate(),
    timerMultiplayer: 60,
    timeLineValue: 0,
    animationMode: false,
  },
};

const trackingReducer = (
  state: trackingState = defaultState,
  action: TrackingDispatchTypes
): trackingState => {
  switch (action.type) {
    case TRACKING_LOADING:
      return {
        ...state,
        loading: true,
      };
    case TRACKING_ERROR:
      return {
        ...state,
        loading: false,
      };
    case TRACKING_ACTIVE_DATES:
      return {
        ...state,
        activeDates: [...action.payload],
      };
    case TRACKING_LIST:
      const trackerList: Tracker.AsObject[] = action.payload;
      return {
        ...state,
        trackerList: trackerList,
        selectedTrackers: [...defaultValueArray],
      };
    case TRACKING_SELECTED_TEAMS:
      return {
        ...state,
        selectedTeams: [...action.payload],
      };
    case TRACKING_SELECTED_TRACKERS:
      return {
        ...state,
        selectedTrackers: [...action.payload],
      };
    case TRACKING_TIME_FILTER:
      let timeFilterAux = action.payload;
      return {
        ...state,
        timeFilter: {
          ...state.timeFilter,
          selectedDate: timeFilterAux.selectedDate
            ? timeFilterAux.selectedDate
            : state.timeFilter.selectedDate,
          endSelectedDate: timeFilterAux.endSelectedDate
            ? timeFilterAux.endSelectedDate
            : state.timeFilter.endSelectedDate,
          timerMultiplayer: timeFilterAux.timerMultiplayer
            ? timeFilterAux.timerMultiplayer
            : state.timeFilter.timerMultiplayer,
          timeLineValue:
            timeFilterAux.timeLineValue || timeFilterAux.timeLineValue === 0
              ? timeFilterAux.timeLineValue
              : state.timeFilter.timeLineValue,
          animationMode: timeFilterAux.animationMode
            ? timeFilterAux.animationMode
            : state.timeFilter.animationMode,
        },
      };
    default:
      return state;
  }
};

export default trackingReducer;
