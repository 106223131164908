import {
  LanguageVariant,
  ReportType,
} from "../../server/report/protos/reportType_pb";
import {
  REPORTS_LOADING,
  REPORTS_ERROR,
  REPORTS_LIST,
  REPORTS_SELECTED,
  REPORTS_TAB,
  //View data
  REPORTS_VIEW_FILTERS,
  REPORTS_VIEW_SELECTED,
  REPORTS_VIEW_SHOW_TIME,
  REPORTS_VIEW_ZOOM,
  REPORTS_VIEW_COORDS,
  ReportsDispatchTypes,
  REPORT_TYPES_LIST,
  REPORTS_FILTER_LIST,
  REPORTS_UPDATES,
  SET_RANGE_DATE,
  CLEAR_RANGE_DATE,
} from "./reportsTypes";
import { Report } from "../../server/report/protos/report_pb";
import moment from "moment";

let defaultValue: any;
let defaultValueArray: any = [];

export interface ReportTypes {
  reporttypeid: string;
  iconmain: string;
  iconmap: string;
  it: string;
  fr: string;
  en: string;
  es: string;
  pt: string;
  parentid: string;
  reportTypes?: ReportTypes[];
}

interface reportsState {
  loading: boolean;
  reportTypes: ReportTypes[];
  reportTypesGeneral: ReportTypes[];
  reports: Report.AsObject[];
  reportsGeneral: Report.AsObject[];
  selectedReportType: object;
  tabIndex: number;
  viewFilters: {
    pending: boolean;
    assigned: boolean;
    progress: boolean;
    completed: boolean;
    problem: boolean;
    paused: boolean;
  };
  filtersSelected: string[];
  viewSelectedReport: object;
  viewShowTime: boolean;
  viewZoom: number;
  viewCoords: object;
  fromTo: {
    from: Date | null;
    to: Date | null;
  };
}

const defaultState: reportsState = {
  loading: false,
  reportTypes: defaultValueArray,
  reportTypesGeneral: defaultValueArray,
  reports: [],
  reportsGeneral: [],
  selectedReportType: defaultValue,
  tabIndex: 0,
  viewFilters: {
    pending: true,
    assigned: false,
    progress: false,
    completed: false,
    problem: false,
    paused: false,
  },
  filtersSelected: [],
  viewSelectedReport: defaultValue,
  viewShowTime: true,
  viewZoom: 11,
  viewCoords: { lat: 32.4999283, lng: -116.9743167 },
  fromTo: {
    from: null,
    to: null,
  },
};

const reportsReducer = (
  state: reportsState = defaultState,
  action: ReportsDispatchTypes
): reportsState => {
  switch (action.type) {
    case SET_RANGE_DATE:
      return {
        ...state,
        fromTo: {
          from: action.from,
          to: action.to,
        },
        reports: state.reportsGeneral.filter((d: Report.AsObject) => {
          if (action.from !== null && action.to !== null) {
            return isBetween(
              moment(
                moment.unix(d.createdat?.seconds || 0).toLocaleString()
              ).toDate(),
              action.from,
              action.to
            );
          } else return true;
        }),
      };
    // .filter((d: Report.AsObject) => {
    //   if (fromTo.from && fromTo.to)
    //     return isBetween(
    //       moment(
    //         moment.unix(d.createdat?.seconds || 0).toLocaleString()
    //       ).toDate(),
    //       fromTo.from,
    //       fromTo.to
    //     );
    //   else return true;
    // })
    case CLEAR_RANGE_DATE:
      return {
        ...state,
        fromTo: {
          from: null,
          to: null,
        },
        reports: state.reportsGeneral,
      };
    case REPORTS_FILTER_LIST:
      return {
        ...state,
        filtersSelected: [...action.payload],
      };
    case REPORTS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case REPORTS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case REPORTS_LIST:
      return {
        ...state,
        reports: action.payload.filter((d: Report.AsObject) => {
          if (state.fromTo.from !== null && state.fromTo.to !== null)
            return isBetween(
              moment(
                moment.unix(d.createdat?.seconds || 0).toLocaleString()
              ).toDate(),
              state.fromTo.from,
              state.fromTo.to
            );
          else return true;
        }),
        reportsGeneral: action.payload,
      };
    case REPORTS_UPDATES:
      const array: Report.AsObject[] = [];
      let inArray = false;
      state.reportsGeneral.forEach((d: Report.AsObject) => {
        if (d.reportid === action.payload.reportid) {
          if (!action.payload.ignored) {
            array.push(action.payload);
          }
          inArray = true;
        } else {
          array.push(d);
        }
      });
      if (!inArray) {
        array.push(action.payload);
      }
      return {
        ...state,
        reports: array.filter((d: Report.AsObject) => {
          if (state.fromTo.from !== null && state.fromTo.to !== null)
            return isBetween(
              moment(
                moment.unix(d.createdat?.seconds || 0).toLocaleString()
              ).toDate(),
              state.fromTo.from,
              state.fromTo.to
            );
          else return true;
        }),
        reportsGeneral: array,
      };
    case REPORT_TYPES_LIST:
      const list: ReportType.AsObject[] = action.payload;

      let defaultReportType: ReportTypes[] = [];
      let generalReportTypes: ReportTypes[] = [];
      if (list.length > 0) {
        list.forEach((q) => {
          if (q.parentid === "") {
            const reportTypes: ReportTypes[] = [];
            list.forEach((e) => {
              if (e.parentid === q.reporttypeid) {
                let nameArray: any = [];
                e.displaynameList.map((nameItem: LanguageVariant.AsObject) => {
                  nameArray[nameItem.langcode] = nameItem.value;
                });
                reportTypes.push({
                  reporttypeid: e.reporttypeid,
                  iconmain: e.iconmain,
                  iconmap: e.iconmap,
                  parentid: e.parentid,
                  it: nameArray["it"],
                  es: nameArray["es"],
                  en: nameArray["en"],
                  pt: nameArray["pt"],
                  fr: nameArray["fr"],
                  reportTypes: undefined,
                } as ReportTypes);
                generalReportTypes.push({
                  reporttypeid: e.reporttypeid,
                  iconmain: e.iconmain,
                  iconmap: e.iconmap,
                  parentid: e.parentid,
                  it: nameArray["it"],
                  es: nameArray["es"],
                  en: nameArray["en"],
                  pt: nameArray["pt"],
                  fr: nameArray["fr"],
                  reportTypes: undefined,
                } as ReportTypes);
              }
            });
            let nameArray: any = [];
            q.displaynameList.map((nameItem: LanguageVariant.AsObject) => {
              nameArray[nameItem.langcode] = nameItem.value;
            });
            defaultReportType.push({
              reporttypeid: q.reporttypeid,
              iconmain: q.iconmain,
              iconmap: q.iconmap,
              parentid: q.parentid,
              it: nameArray["it"],
              es: nameArray["es"],
              en: nameArray["en"],
              pt: nameArray["pt"],
              fr: nameArray["fr"],
              reportTypes: reportTypes || [],
            } as ReportTypes);
            generalReportTypes.push({
              reporttypeid: q.reporttypeid,
              iconmain: q.iconmain,
              iconmap: q.iconmap,
              parentid: q.parentid,
              it: nameArray["it"],
              es: nameArray["es"],
              en: nameArray["en"],
              pt: nameArray["pt"],
              fr: nameArray["fr"],
              reportTypes: reportTypes || [],
            } as ReportTypes);
          }
        });
      }

      return {
        ...state,
        reportTypes: defaultReportType,
        reportTypesGeneral: generalReportTypes,
      };
    case REPORTS_SELECTED:
      const reportSelected: any = action.payload;
      return {
        ...state,
        selectedReportType: reportSelected,
        loading: true,
      };
    case REPORTS_TAB:
      const reportTab: any = action.tabIndex;
      return {
        ...state,
        tabIndex: reportTab,
      };
    case REPORTS_VIEW_FILTERS:
      const viewFilters: any = action.filters;
      return {
        ...state,
        viewFilters: viewFilters,
      };
    case REPORTS_VIEW_SELECTED:
      const viewSelected: any = action.payload;
      return {
        ...state,
        viewSelectedReport: viewSelected,
      };
    case REPORTS_VIEW_SHOW_TIME:
      const viewShowTime: any = action.show;
      return {
        ...state,
        viewShowTime: viewShowTime,
      };
    case REPORTS_VIEW_ZOOM:
      const viewZoom: any = action.zoom;
      return {
        ...state,
        viewZoom: viewZoom,
      };
    case REPORTS_VIEW_COORDS:
      const viewCoords: any = action.coords;
      return {
        ...state,
        viewCoords: viewCoords,
      };
    default:
      return state;
  }
};

const isBetween = (date: Date, min: Date, max: Date) =>
  date.getTime() >= min.getTime() && date.getTime() <= max.getTime();

export default reportsReducer;
