import store from "../redux/Store";
import {
  SET_AUTHENTICATED,
  SET_SUPER_ADMIN,
  SET_UNAUTHENTICATED,
} from "../redux/user/userTypes";

import jwtDecode from "jwt-decode";
import { grpcFetchUserById } from "../redux/user/userAction";
import { logout } from "../firebase/auth";
export interface decodedToken {
  exp: number;
  id: string;
  permissions: string[];
}

export const CheckAuthentication = () => {
  const authToken = localStorage.token;
  // let expiresAt = JSON.parse(localStorage.getItem("expires_at") || "");
  // // if (session.value !== new Date().getTime() < expiresAt)

  // if (!(new Date().getTime() < expiresAt)) {
  //   store.dispatch({
  //     type: SET_UNAUTHENTICATED,
  //   });
  //   store.dispatch({
  //     type: SET_SUPER_ADMIN,
  //     payload: false,
  //   });
  // } else
  if (authToken) {
    const decodedToken: decodedToken = jwtDecode(authToken);
    // console.log("decodedToken", decodedToken);
    localStorage.setItem("exp", JSON.stringify(decodedToken.exp));
    store.dispatch(grpcFetchUserById(decodedToken.id));
    if (decodedToken.permissions.indexOf("mc.super.admin") != -1) {
      store.dispatch({
        type: SET_AUTHENTICATED,
        superAdmin: true,
      });
    } else if (decodedToken.permissions.indexOf("mc.admin") != -1) {
      store.dispatch({
        type: SET_AUTHENTICATED,
        superAdmin: false,
      });
    } else {
      logout();
      store.dispatch({
        type: SET_UNAUTHENTICATED,
      });
    }
  } else {
    logout();
    store.dispatch({
      type: SET_UNAUTHENTICATED,
    });
    store.dispatch({
      type: SET_SUPER_ADMIN,
      payload: false,
    });
  }
  //   if (decodedToken.exp * 1000 < Date.now()) {
  //     logoutUser();
  //     store.dispatch({
  //       type: SET_UNAUTHENTICATED,
  //     });
  //     store.dispatch({
  //       type: SET_SUPER_ADMIN,
  //       payload: false,
  //     });
  //     localStorage.setItem('logoutEvent', 'logout' + Math.random());
  //   } else {
  //     if (localStorage.role === ROLE.SUPER_ADMIN) {
  //       console.log('SUPER_CAMPAIGNS');
  //       // window.location.href = SUPER_CAMPAIGNS;
  //     } else {
  //       console.log('DASHBOARD');
  //       // window.location.href = PRIVATE_DASHBOARD;
  //     }
  //     axios.defaults.headers.common['Authorization'] = authToken;
  //     axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
  //     let fun = getUserData;
  //     if (localStorage.accessMode && localStorage.accessMode === 'ACTIVE') {
  //       fun = getUserDataByCampaign;
  //     }
  //     fun(
  //       decodedToken._id,
  //       (value: any) => {
  //         const deco: DecodedToken = value.data.response;
  //         console.log(value.data.response);
  //         if (deco.role !== ROLE.CAPTURIST) {
  //           localStorage.setItem('id', deco._id);
  //           localStorage.setItem('role', deco.role);
  //           localStorage.setItem(
  //             'level',
  //             deco.level ? deco.level.toString() : '',
  //           );
  //           localStorage.setItem('teamName', deco.teamName);
  //           localStorage.setItem('focus', deco.focus);
  //           localStorage.setItem('campaignId', deco.campaignId);
  //           localStorage.setItem('team', JSON.stringify(deco.team));
  //           if (
  //             localStorage.accessMode &&
  //             localStorage.accessMode === 'ACTIVE'
  //           ) {
  //             store.dispatch({
  //               type: SET_AUTHENTICATED,
  //               superAdmin: false,
  //             });
  //           } else {
  //             store.dispatch({
  //               type: SET_AUTHENTICATED,
  //               superAdmin:
  //                 localStorage.role === ROLE.SUPER_ADMIN ? true : false,
  //             });
  //           }
  //           let stateSelected: statesLogin[] = [];
  //           let citySelected: citiesLogin[] = [];
  //           try {
  //             stateSelected = deco.states;
  //             citySelected = deco.cities;
  //           } catch (error) {
  //             stateSelected = [];
  //             citySelected = [];
  //             // logoutUser();
  //             // store.dispatch({
  //             //   type: SET_UNAUTHENTICATED,
  //             // });
  //           }
  //           store.dispatch({
  //             type: SET_GLOBAL,
  //             state: stateSelected,
  //             municipality: citySelected,
  //             userData: deco,
  //           });
  //           store.dispatch({
  //             type: SET_GLOBAL_SELECTED,
  //             state: stateSelected[0],
  //             municipality: citySelected[0],
  //           });
  //         } else {
  //           logoutUser();
  //           store.dispatch({
  //             type: SET_UNAUTHENTICATED,
  //           });
  //           store.dispatch({
  //             type: SET_SUPER_ADMIN,
  //             payload: false,
  //           });
  //           localStorage.setItem('logoutEvent', 'logout' + Math.random());
  //         }
  //       },
  //       () => {
  //         logoutUser();
  //         store.dispatch({
  //           type: SET_UNAUTHENTICATED,
  //         });
  //         store.dispatch({
  //           type: SET_SUPER_ADMIN,
  //           payload: false,
  //         });
  //         localStorage.setItem('logoutEvent', 'logout' + Math.random());
  //       },
  //     );
  //   }
  // }
  // authAWS.isAuthenticated();
  // authAWS.session.subscribe((authUser) => {
  //   if (authUser) {
  //     netWorking.refreshToken(() => {
  //       if (localStorage.getItem("typeOfUser") === superAdmin) {
  //         store.dispatch({
  //           type: SET_AUTHENTICATED,
  //           superAdmin: true,
  //         });
  //       } else if (localStorage.getItem("typeOfUser") === adminServices) {
  //         store.dispatch({
  //           type: SET_AUTHENTICATED,
  //           superAdmin: false,
  //         });
  //       } else if (localStorage.getItem("typeOfUser") === adminCashier) {
  //         store.dispatch({
  //           type: SET_AUTHENTICATED,
  //           superAdmin: false,
  //         });
  //       } else if (localStorage.getItem("typeOfUser") === adminMayor) {
  //         store.dispatch({
  //           type: SET_AUTHENTICATED,
  //           superAdmin: false,
  //         });
  //       }
  //     });
  //   } else if (!authUser) {
  //     store.dispatch({
  //       type: SET_UNAUTHENTICATED,
  //     });
  //     store.dispatch({
  //       type: SET_SUPER_ADMIN,
  //       payload: false,
  //     });
  //   }
  // });
  // authAWS.sessionLogout.subscribe((value) => {
  //   if (value) {
  //     store.dispatch({
  //       type: SET_UNAUTHENTICATED,
  //     });
  //     store.dispatch({
  //       type: SET_SUPER_ADMIN,
  //       payload: false,
  //     });
  //   }
  // });
};
