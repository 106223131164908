/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as protos_reportType_pb from '../protos/reportType_pb';


export class ReportTypeServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoCreateReportType = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_reportType_pb.ReportType,
    (request: protos_reportType_pb.CreateReportTypeMessage) => {
      return request.serializeBinary();
    },
    protos_reportType_pb.ReportType.deserializeBinary
  );

  createReportType(
    request: protos_reportType_pb.CreateReportTypeMessage,
    metadata: grpcWeb.Metadata | null): Promise<protos_reportType_pb.ReportType>;

  createReportType(
    request: protos_reportType_pb.CreateReportTypeMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_reportType_pb.ReportType) => void): grpcWeb.ClientReadableStream<protos_reportType_pb.ReportType>;

  createReportType(
    request: protos_reportType_pb.CreateReportTypeMessage,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_reportType_pb.ReportType) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ReportTypeService/CreateReportType',
        request,
        metadata || {},
        this.methodInfoCreateReportType,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ReportTypeService/CreateReportType',
    request,
    metadata || {},
    this.methodInfoCreateReportType);
  }

  methodInfoFetchReportType = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_reportType_pb.FetchReportTypeResult,
    (request: protos_reportType_pb.FetchReportTypeMessage) => {
      return request.serializeBinary();
    },
    protos_reportType_pb.FetchReportTypeResult.deserializeBinary
  );

  fetchReportType(
    request: protos_reportType_pb.FetchReportTypeMessage,
    metadata: grpcWeb.Metadata | null): Promise<protos_reportType_pb.FetchReportTypeResult>;

  fetchReportType(
    request: protos_reportType_pb.FetchReportTypeMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_reportType_pb.FetchReportTypeResult) => void): grpcWeb.ClientReadableStream<protos_reportType_pb.FetchReportTypeResult>;

  fetchReportType(
    request: protos_reportType_pb.FetchReportTypeMessage,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_reportType_pb.FetchReportTypeResult) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ReportTypeService/FetchReportType',
        request,
        metadata || {},
        this.methodInfoFetchReportType,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ReportTypeService/FetchReportType',
    request,
    metadata || {},
    this.methodInfoFetchReportType);
  }

  methodInfoUpdateReportType = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_reportType_pb.ReportTypeSuccess,
    (request: protos_reportType_pb.ReportType) => {
      return request.serializeBinary();
    },
    protos_reportType_pb.ReportTypeSuccess.deserializeBinary
  );

  updateReportType(
    request: protos_reportType_pb.ReportType,
    metadata: grpcWeb.Metadata | null): Promise<protos_reportType_pb.ReportTypeSuccess>;

  updateReportType(
    request: protos_reportType_pb.ReportType,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_reportType_pb.ReportTypeSuccess) => void): grpcWeb.ClientReadableStream<protos_reportType_pb.ReportTypeSuccess>;

  updateReportType(
    request: protos_reportType_pb.ReportType,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_reportType_pb.ReportTypeSuccess) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ReportTypeService/UpdateReportType',
        request,
        metadata || {},
        this.methodInfoUpdateReportType,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ReportTypeService/UpdateReportType',
    request,
    metadata || {},
    this.methodInfoUpdateReportType);
  }

  methodInfoDeleteReportType = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_reportType_pb.ReportTypeSuccess,
    (request: protos_reportType_pb.ReportTypeIdMessage) => {
      return request.serializeBinary();
    },
    protos_reportType_pb.ReportTypeSuccess.deserializeBinary
  );

  deleteReportType(
    request: protos_reportType_pb.ReportTypeIdMessage,
    metadata: grpcWeb.Metadata | null): Promise<protos_reportType_pb.ReportTypeSuccess>;

  deleteReportType(
    request: protos_reportType_pb.ReportTypeIdMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_reportType_pb.ReportTypeSuccess) => void): grpcWeb.ClientReadableStream<protos_reportType_pb.ReportTypeSuccess>;

  deleteReportType(
    request: protos_reportType_pb.ReportTypeIdMessage,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_reportType_pb.ReportTypeSuccess) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ReportTypeService/DeleteReportType',
        request,
        metadata || {},
        this.methodInfoDeleteReportType,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ReportTypeService/DeleteReportType',
    request,
    metadata || {},
    this.methodInfoDeleteReportType);
  }

  methodInfoFetchAllReportTypes = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_reportType_pb.FetchReportTypeResult,
    (request: protos_reportType_pb.FetchAllTypesMessage) => {
      return request.serializeBinary();
    },
    protos_reportType_pb.FetchReportTypeResult.deserializeBinary
  );

  fetchAllReportTypes(
    request: protos_reportType_pb.FetchAllTypesMessage,
    metadata: grpcWeb.Metadata | null): Promise<protos_reportType_pb.FetchReportTypeResult>;

  fetchAllReportTypes(
    request: protos_reportType_pb.FetchAllTypesMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_reportType_pb.FetchReportTypeResult) => void): grpcWeb.ClientReadableStream<protos_reportType_pb.FetchReportTypeResult>;

  fetchAllReportTypes(
    request: protos_reportType_pb.FetchAllTypesMessage,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_reportType_pb.FetchReportTypeResult) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ReportTypeService/FetchAllReportTypes',
        request,
        metadata || {},
        this.methodInfoFetchAllReportTypes,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ReportTypeService/FetchAllReportTypes',
    request,
    metadata || {},
    this.methodInfoFetchAllReportTypes);
  }

  methodInfoListenReportTypeUpdates = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_reportType_pb.ReportType,
    (request: protos_reportType_pb.ListenReportTypesMessage) => {
      return request.serializeBinary();
    },
    protos_reportType_pb.ReportType.deserializeBinary
  );

  listenReportTypeUpdates(
    request: protos_reportType_pb.ListenReportTypesMessage,
    metadata?: grpcWeb.Metadata) {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/ReportTypeService/ListenReportTypeUpdates',
      request,
      metadata || {},
      this.methodInfoListenReportTypeUpdates);
  }

}

