/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as protos_notification_pb from '../protos/notification_pb';


export class NotificationServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoSendMessage = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_notification_pb.SuccessMessage,
    (request: protos_notification_pb.MessageTelegram) => {
      return request.serializeBinary();
    },
    protos_notification_pb.SuccessMessage.deserializeBinary
  );

  sendMessage(
    request: protos_notification_pb.MessageTelegram,
    metadata: grpcWeb.Metadata | null): Promise<protos_notification_pb.SuccessMessage>;

  sendMessage(
    request: protos_notification_pb.MessageTelegram,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_notification_pb.SuccessMessage) => void): grpcWeb.ClientReadableStream<protos_notification_pb.SuccessMessage>;

  sendMessage(
    request: protos_notification_pb.MessageTelegram,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_notification_pb.SuccessMessage) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/NotificationService/SendMessage',
        request,
        metadata || {},
        this.methodInfoSendMessage,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/NotificationService/SendMessage',
    request,
    metadata || {},
    this.methodInfoSendMessage);
  }

  methodInfoSendNotification = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_notification_pb.SuccessMessage,
    (request: protos_notification_pb.Notification) => {
      return request.serializeBinary();
    },
    protos_notification_pb.SuccessMessage.deserializeBinary
  );

  sendNotification(
    request: protos_notification_pb.Notification,
    metadata: grpcWeb.Metadata | null): Promise<protos_notification_pb.SuccessMessage>;

  sendNotification(
    request: protos_notification_pb.Notification,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_notification_pb.SuccessMessage) => void): grpcWeb.ClientReadableStream<protos_notification_pb.SuccessMessage>;

  sendNotification(
    request: protos_notification_pb.Notification,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_notification_pb.SuccessMessage) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/NotificationService/SendNotification',
        request,
        metadata || {},
        this.methodInfoSendNotification,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/NotificationService/SendNotification',
    request,
    metadata || {},
    this.methodInfoSendNotification);
  }

  methodInfoSendNotificationReport = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_notification_pb.SuccessMessage,
    (request: protos_notification_pb.ReportNotification) => {
      return request.serializeBinary();
    },
    protos_notification_pb.SuccessMessage.deserializeBinary
  );

  sendNotificationReport(
    request: protos_notification_pb.ReportNotification,
    metadata: grpcWeb.Metadata | null): Promise<protos_notification_pb.SuccessMessage>;

  sendNotificationReport(
    request: protos_notification_pb.ReportNotification,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_notification_pb.SuccessMessage) => void): grpcWeb.ClientReadableStream<protos_notification_pb.SuccessMessage>;

  sendNotificationReport(
    request: protos_notification_pb.ReportNotification,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_notification_pb.SuccessMessage) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/NotificationService/SendNotificationReport',
        request,
        metadata || {},
        this.methodInfoSendNotificationReport,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/NotificationService/SendNotificationReport',
    request,
    metadata || {},
    this.methodInfoSendNotificationReport);
  }

  methodInfoUpdate = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_notification_pb.SuccessMessage,
    (request: protos_notification_pb.UpdateNotification) => {
      return request.serializeBinary();
    },
    protos_notification_pb.SuccessMessage.deserializeBinary
  );

  update(
    request: protos_notification_pb.UpdateNotification,
    metadata: grpcWeb.Metadata | null): Promise<protos_notification_pb.SuccessMessage>;

  update(
    request: protos_notification_pb.UpdateNotification,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_notification_pb.SuccessMessage) => void): grpcWeb.ClientReadableStream<protos_notification_pb.SuccessMessage>;

  update(
    request: protos_notification_pb.UpdateNotification,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_notification_pb.SuccessMessage) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/NotificationService/Update',
        request,
        metadata || {},
        this.methodInfoUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/NotificationService/Update',
    request,
    metadata || {},
    this.methodInfoUpdate);
  }

  methodInfoIgnore = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_notification_pb.SuccessMessage,
    (request: protos_notification_pb.RequestIgnore) => {
      return request.serializeBinary();
    },
    protos_notification_pb.SuccessMessage.deserializeBinary
  );

  ignore(
    request: protos_notification_pb.RequestIgnore,
    metadata: grpcWeb.Metadata | null): Promise<protos_notification_pb.SuccessMessage>;

  ignore(
    request: protos_notification_pb.RequestIgnore,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_notification_pb.SuccessMessage) => void): grpcWeb.ClientReadableStream<protos_notification_pb.SuccessMessage>;

  ignore(
    request: protos_notification_pb.RequestIgnore,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_notification_pb.SuccessMessage) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/NotificationService/Ignore',
        request,
        metadata || {},
        this.methodInfoIgnore,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/NotificationService/Ignore',
    request,
    metadata || {},
    this.methodInfoIgnore);
  }

  methodInfoCreateUserData = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_notification_pb.SuccessMessage,
    (request: protos_notification_pb.UserNotification) => {
      return request.serializeBinary();
    },
    protos_notification_pb.SuccessMessage.deserializeBinary
  );

  createUserData(
    request: protos_notification_pb.UserNotification,
    metadata: grpcWeb.Metadata | null): Promise<protos_notification_pb.SuccessMessage>;

  createUserData(
    request: protos_notification_pb.UserNotification,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_notification_pb.SuccessMessage) => void): grpcWeb.ClientReadableStream<protos_notification_pb.SuccessMessage>;

  createUserData(
    request: protos_notification_pb.UserNotification,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_notification_pb.SuccessMessage) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/NotificationService/CreateUserData',
        request,
        metadata || {},
        this.methodInfoCreateUserData,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/NotificationService/CreateUserData',
    request,
    metadata || {},
    this.methodInfoCreateUserData);
  }

  methodInfoListenNotifications = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_notification_pb.NotificationApp,
    (request: protos_notification_pb.Request) => {
      return request.serializeBinary();
    },
    protos_notification_pb.NotificationApp.deserializeBinary
  );

  listenNotifications(
    request: protos_notification_pb.Request,
    metadata?: grpcWeb.Metadata) {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/NotificationService/ListenNotifications',
      request,
      metadata || {},
      this.methodInfoListenNotifications);
  }

  methodInfoFetchNotifications = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_notification_pb.FetchResult,
    (request: protos_notification_pb.RequestFetch) => {
      return request.serializeBinary();
    },
    protos_notification_pb.FetchResult.deserializeBinary
  );

  fetchNotifications(
    request: protos_notification_pb.RequestFetch,
    metadata: grpcWeb.Metadata | null): Promise<protos_notification_pb.FetchResult>;

  fetchNotifications(
    request: protos_notification_pb.RequestFetch,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_notification_pb.FetchResult) => void): grpcWeb.ClientReadableStream<protos_notification_pb.FetchResult>;

  fetchNotifications(
    request: protos_notification_pb.RequestFetch,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_notification_pb.FetchResult) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/NotificationService/FetchNotifications',
        request,
        metadata || {},
        this.methodInfoFetchNotifications,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/NotificationService/FetchNotifications',
    request,
    metadata || {},
    this.methodInfoFetchNotifications);
  }

  methodInfoFetchLastNotifications = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_notification_pb.FetchResult,
    (request: protos_notification_pb.RequestFetch) => {
      return request.serializeBinary();
    },
    protos_notification_pb.FetchResult.deserializeBinary
  );

  fetchLastNotifications(
    request: protos_notification_pb.RequestFetch,
    metadata: grpcWeb.Metadata | null): Promise<protos_notification_pb.FetchResult>;

  fetchLastNotifications(
    request: protos_notification_pb.RequestFetch,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_notification_pb.FetchResult) => void): grpcWeb.ClientReadableStream<protos_notification_pb.FetchResult>;

  fetchLastNotifications(
    request: protos_notification_pb.RequestFetch,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_notification_pb.FetchResult) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/NotificationService/FetchLastNotifications',
        request,
        metadata || {},
        this.methodInfoFetchLastNotifications,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/NotificationService/FetchLastNotifications',
    request,
    metadata || {},
    this.methodInfoFetchLastNotifications);
  }

}

