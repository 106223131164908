import React, { useState } from "react";
import HeaderDialog from "../components/Header-dialog";
import { useTranslation } from "react-i18next";
import SelectComponentGeneric from "../components/select-component-generic";
// import BigButton from "../components/Big-button-component";
import { updateDocument } from "../../firebase/index";
import ActionButtons from "../components/Action-buttons";
import "./style.scss";

interface DialogBlockedUserProps {
  userSelected: any;
  noBold1: any;
  noBold2: any;
  boldText: any;
  doneClick: any;
  onCloseRequest: any;
}

export const DialogBlockedUser: React.FunctionComponent<DialogBlockedUserProps> =
  ({
    userSelected,
    noBold1,
    noBold2,
    boldText,
    doneClick,
    onCloseRequest,
  }: DialogBlockedUserProps) => {
    const { t } = useTranslation();

    const [state, setState] = useState<{
      selected: null | string;
    }>({
      selected: null,
    });

    const selectReason = (e: any) => {
      setState((prevState) => ({
        ...prevState,
        selected: e,
      }));
    };

    return (
      <div className="content-dialog big-dialog">
        <HeaderDialog
          onCloseRequest={onCloseRequest}
          title={t("name.block.account")}
          noBold1={noBold1 !== undefined ? noBold1 : ""}
          noBold2={noBold2 !== undefined ? noBold2 : ""}
          boldText={boldText !== undefined ? boldText : ""}
          description={""}
        ></HeaderDialog>
        <div className="content-options">
          <div className="content-options-body">
            <div className="options-body-item ">
              <div className="selector-box full-width-select">
                <SelectComponentGeneric
                  initialData={t("form.select.motive")}
                  data={[
                    t("form.motive.6"),
                    t("form.motive.7"),
                    t("form.motive.8"),
                  ]}
                  select={selectReason}
                  selected={state.selected}
                ></SelectComponentGeneric>
              </div>
            </div>
            <ActionButtons
              clickable={state.selected !== null}
              doneText={t("submit.yes")}
              cancelText={t("submit.no")}
              small={true}
              doneClick={() => {
                if (state.selected !== null) doneClick(state.selected);
              }}
              cancelClick={onCloseRequest}
            ></ActionButtons>
          </div>
        </div>
      </div>
    );
  };

export default DialogBlockedUser;
