import React from "react";
import "./style.scss";

interface SimpleModalProps {
  onCloseRequest?: any;
  children: any;
}

export const SimpleModal: React.FunctionComponent<SimpleModalProps> = ({
  onCloseRequest,
  children,
}: any) => {
  return <div className="modalOverlay">{children}</div>;
};

export default SimpleModal;
