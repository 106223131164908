import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RootStore } from "../../../redux/Store";

import {
  onlyDigitsNoSpace,
  onlyLetters,
  validEmailRegex,
  validPhoneRegex,
} from "../../../utils/utils";
import {
  grpcCreateUser,
  grpcUpdateUser,
} from "../../../redux/users/usersAction";
import { grpcCheckEmailInUse } from "../../../redux/user/userAction";
import { licenseI } from "../../../redux/licenses/licensesReducer";
import { Role } from "../../../server/user/protos/auth_pb";
import { User } from "../../../server/user/protos/user_pb";

import TextFieldComponent from "../../components/text-field-component";
import ActionButtons from "../../components/Action-buttons";
import HeaderDialog from "../../components/Header-dialog";
import { Divider } from "@material-ui/core";


interface ManageWorkerModalProps {
  worker?: User.AsObject;
  clearMessage: any;
  onCloseRequest: any;
}

export const ManageWorkerModal: React.FunctionComponent<ManageWorkerModalProps> =
  ({ worker, clearMessage, onCloseRequest }: ManageWorkerModalProps) => {
    const selectedLicense: licenseI | undefined = useSelector(
      (globalState: RootStore) => globalState.licenses.selectedLicense
    );
    const roleList: Role.AsObject[] = useSelector(
      (globalState: RootStore) => globalState.users.roleList
    );

    const { t } = useTranslation();
    const dispatch = useDispatch();

    let defaultValue: any;

    const [state, setState] = useState({
      id: defaultValue,
      nombre: "",
      apellido: "",
      correo: "",
      celular: "",
      password : "",
      isPasswordTemporal : false,
      nombreInvalid: false,
      apellidoInvalid: false,
      correoInvalid: false,
      celularInvalid: false,
      passwordInvalid: false,
      messageCorreo: defaultValue,

    });

    const handleForm = (name: any, event: any) => {
      setState((prevState) => ({
        ...prevState,
        [name]: event,
      }));
      if (name === "nombre")
        setState((prevState) => ({
          ...prevState,
          nombreInvalid: false,
        }));
      if (name === "correo")
        setState((prevState) => ({
          ...prevState,
          correoInvalid: false,
        }));
      if (name === "celular")
        setState((prevState) => ({
          ...prevState,
          celularInvalid: false,
        }));
      if (name === "apellido")
        setState((prevState) => ({
          ...prevState,
          apellidoInvalid: false,
        }));
        if (name === "password")
          setState((prevState) => ({
            ...prevState,
            passwordInvalid: false,
          }));
    };

    const checkValid = () => {
      const { nombre, apellido, correo, celular, isPasswordTemporal, password } = state;
      if (!isPasswordTemporal) {
        state.password = "";
      }
      return nombre && apellido && correo && celular && (!isPasswordTemporal || password);
    };

    const checkValidFormToSubmit = () => {
      if (!validEmailRegex.test(state.correo)) {
        setState((prevState) => ({
          ...prevState,
          correoInvalid: true,
          messageCorreo: t("message.error.invalid.email"),
        }));
      }
      if (!validPhoneRegex.test(state.celular) || state.celular.length <= 8) {
        setState((prevState) => ({
          ...prevState,
          celularInvalid: true,
        }));
      }
      if (
        validPhoneRegex.test(state.celular) &&
        state.celular.length >= 9 &&
        validEmailRegex.test(state.correo)
      ) {
        const role = roleList.find((d) => {
          return d.name.includes("MC.WORKER");
        });

        dispatch(
          grpcCheckEmailInUse(
            {
              email: state.correo.toLowerCase().trim(),
            },
            (response: any) => {
              if (!worker) {
                console.log("response", response.success);
                if (response.success) {
                  setState((prevState) => ({
                    ...prevState,
                    correoInvalid: true,
                    messageCorreo: t("message.error.invalid.email.in.use"),
                  }));
                } else {
                  dispatch(
                    grpcCreateUser(
                      {
                        name: state.nombre.trim(),
                        lastname: state.apellido.trim(),
                        email: state.correo,
                        phoneNumber: state.celular,
                        role: role?.roleid || "",
                        licenseId: selectedLicense?._id || "",
                        password : state.password.trim() || "",
                        isPasswordTemporal: state.isPasswordTemporal
                      },
                      (response: any) => {
                        onCloseRequest();
                        clearMessage();
                      }
                    )
                  );
                }
              } else {
                console.log("response", response.success);
                if (
                  response.success &&
                  state.correo.toLowerCase().trim() !== worker?.email
                ) {
                  setState((prevState) => ({
                    ...prevState,
                    correoInvalid: true,
                    messageCorreo: t("message.error.invalid.email.in.use"),
                  }));
                } else {
                  dispatch(
                    grpcUpdateUser(
                      {
                        _id: state.id,
                        name: state.nombre.trim(),
                        lastname: state.apellido.trim(),
                        email: state.correo,
                        phoneNumber: state.celular,
                        //ADITIONAL INFO
                        role: role?.roleid || "",
                        username: worker.username,
                        preferredLanguage: worker.preferredLanguage,
                        imagePath: worker.imagePath,
                        licenseId: selectedLicense?._id || "",
                      },
                      (response: any) => {
                        if (response.success) {
                          onCloseRequest();
                          clearMessage();
                        }
                      }
                    )
                  );
                }
              }
            }
          )
        );
      }
    };

    useEffect(() => {
      if (worker) {
        setState((prevState) => ({
          ...prevState,
          id: worker.id,
          nombre: worker.name,
          apellido: worker.lastName,
          correo: worker.email,
          celular: worker.phoneNumber,
        }));
      }
    }, []);

    return (
      <div className="content-dialog big-dialog-x15">
        <HeaderDialog
          onCloseRequest={onCloseRequest}
          description={defaultValue}
          title={!worker ? t("name.new.worker") : t("name.edit.worker")}
          noBold1={defaultValue}
          noBold2={defaultValue}
          boldText={defaultValue}
        />
        <div className="content-options">
          <div className="content-options-body">
            <div className="options-body-item-big">
              <div className="selector-box full-width-select half-text-field-1">
                <TextFieldComponent
                  placeholder={t("name.name")}
                  value={state.nombre}
                  onChange={(event: any) => handleForm("nombre", event)}
                  regExp={onlyLetters}
                  invalid={state.nombreInvalid}
                  maxValue={15}
                  message={t("message.error.short.name")}
                ></TextFieldComponent>
              </div>
              <div className="selector-box full-width-select half-text-field-2">
                <TextFieldComponent
                  placeholder={t("name.last.name")}
                  value={state.apellido}
                  onChange={(event: any) => handleForm("apellido", event)}
                  regExp={onlyLetters}
                  invalid={state.apellidoInvalid}
                  maxValue={15}
                  message={t("message.error.short.last.name")}
                ></TextFieldComponent>
              </div>
            </div>
            <div className="options-body-item-big">
              <div className="selector-box half-width-select">
                <TextFieldComponent
                  placeholder={t("name.cellphone")}
                  value={state.celular}
                  onChange={(event: any) => handleForm("celular", event)}
                  regExp={onlyDigitsNoSpace}
                  invalid={state.celularInvalid}
                  maxValue={10}
                  message={t("message.error.invalid.cellphone")}
                ></TextFieldComponent>
              </div>
            </div>
            <div className="item-divider">
              <Divider />
            </div>
            <div className="options-body-item-big">
              <div
                className={ !state.isPasswordTemporal? "selector-box full-width-select": "selector-box half-width-select2 half-text-field-1"}>
                <TextFieldComponent
                  placeholder={t("name.email")}
                  value={state.correo}
                  onChange={(event: any) => handleForm("correo", event)}
                  invalid={state.correoInvalid}
                  maxValue={40}
                  message={state.messageCorreo}
                ></TextFieldComponent>
              </div>
              {state.isPasswordTemporal ? (
                <div
                  className={
                    !state.isPasswordTemporal
                      ? "selector-box half-width-select "
                      : "selector-box half-text-field-2"
                  }
                >
                  <TextFieldComponent
                    placeholder={t("name.password")}
                    value={state.password}
                    onChange={(event: any) => handleForm("password", event)}
                    invalid={state.passwordInvalid}
                    maxValue={10}
                    type="password"
                    
                    // message={state.messagePassword}
                  ></TextFieldComponent>
                </div>
              ) : (
                ""
              )}
            </div>
            {!worker ?  (
            <div className="switch-input">
              <p>{t("name.super.Assign.Password")}</p>
              <div className="switch-box-super">
                {/* <p>{t("name.super.off")}</p> */}
                <label
                  className={ state.isPasswordTemporal? "switch checkbox-active": "switch checkbox-deactive"}>
                  <input
                    type="checkbox"
                    checked={state.isPasswordTemporal}
                    onChange={() => {
                      setState((prevState) => ({
                        ...prevState,
                        isPasswordTemporal: !state.isPasswordTemporal,
                      }));
                    }}
                  ></input>
                  <span className="slider round"></span>
                </label>
                {/* <p>{t("name.super.on")}</p> */}
              </div>
            </div>
            ) : ""}
            <ActionButtons
              small={true}
              cancelClick={onCloseRequest}
              doneClick={checkValidFormToSubmit}
              clickable={checkValid()}
              doneText={t("submit.continue")}
              cancelText={t("submit.cancel")}
            />
          </div>
        </div>
      </div>
    );
  };

export default ManageWorkerModal;
