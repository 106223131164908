import React from "react";
import { useTranslation } from "react-i18next";

import { AddMember } from "../../../assets/svg/index";

interface EmptyBoxProps {
  showDialog: any;
}

export const EmptyBox: React.FunctionComponent<EmptyBoxProps> = ({
  showDialog,
}: any) => {
  const { t } = useTranslation();

  return (
    <div className="team-tile" onClick={showDialog}>
      <div className="team-tile-child circle-box">
        <AddMember></AddMember>
        {t("name.add.member")}
      </div>
    </div>
  );
};
