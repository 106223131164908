import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../redux/Store";
import { useTranslation } from "react-i18next";
import moment from "moment";

import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import FeatherIcon from "feather-icons-react";
import "react-calendar/dist/Calendar.css";
import Calendar from "react-calendar";
import { TRACKING_TIME_FILTER } from "../../../redux/tracking/trackingTypes";
import { timeFilterI } from "../../../redux/tracking/trackingReducer";

interface FilterTopProps {
  timerActive: any;
  startRoute: any;
  stopRoute: any;
  updateDate: any;
  onChangeDate: any;
  nextDay: any;
  lastDay: any;
}

export const FilterTop: React.FunctionComponent<FilterTopProps> = ({
  timerActive,
  startRoute,
  stopRoute,
  updateDate,
  onChangeDate,
  nextDay,
  lastDay,
}: any) => {
  const trackerList: any = useSelector(
    (globalState: RootStore) => globalState.tracking.trackerList
  );
  const activeDates: any = useSelector(
    (globalState: RootStore) => globalState.tracking.activeDates
  );
  const timeFilter: timeFilterI = useSelector(
    (globalState: RootStore) => globalState.tracking.timeFilter
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();

  let defaultValue: any;

  const hourFormat = "hh:mm:ss A";
  const hourFormat2 = "hh:mm";
  const total = 86400;

  const [state, setState] = useState({
    isSent: true,
    visible: false,
    dropdown: false,
  });

  const percentFormatter = (v: any, date: any) => {
    var startDate = moment(date).startOf("day");
    startDate.add(v, "seconds").toDate();
    return moment(startDate).format(hourFormat2).toString();
  };

  const calculationLeft = (date: any, utc: any) => {
    var hours;
    var minutes;

    if (utc) {
      hours = moment(date).utc(false).format("HH").toString();
      minutes = moment(date).utc(false).format("mm").toString();
    } else {
      hours = moment(date).format("HH").toString();
      minutes = moment(date).format("mm").toString();
    }

    var seconds = parseInt(hours) * 3600;
    seconds = seconds + parseInt(minutes) * 60;

    return (seconds * 100) / total;
  };

  const calculationWidth = (date: any, left: any) => {
    var hours = moment(date).format("HH").toString();
    var minutes = moment(date).format("mm").toString();
    var seconds = parseInt(hours) * 3600;
    seconds = seconds + parseInt(minutes) * 60;
    return (seconds * 100) / total - left;
  };

  const showCalendar = () => {
    setState((prevState) => ({
      ...prevState,
      visible: !state.visible,
    }));
  };

  const showDropdown = () => {
    setState((prevState) => ({
      ...prevState,
      dropdown: !state.dropdown,
    }));
  };

  const gotToFirstPoint = () => {
    let firstDate: any;
    trackerList.forEach((item: any) => {
      var date = item.routeList.sort((a: any, b: any) => {
        return Math.abs(moment(a.date).diff(moment(b.date).toDate()));
      })[0].date;
      if (item.routeList.length < 100) {
        date = defaultValue;
      }
      if (date)
        if (!firstDate || moment(date).isBefore(firstDate)) firstDate = date;
    });
    if (firstDate) {
      var hours = moment(firstDate).format("HH").toString();
      var minutes = moment(firstDate).format("mm").toString();
      var seconds = parseInt(hours) * 3600;
      seconds = seconds + parseInt(minutes) * 60;
      if (seconds > 3) seconds = seconds - 3;
      updateDate(seconds);
    }
  };

  const addToMultiplayer = (timer: any) => {
    dispatch({
      type: TRACKING_TIME_FILTER,
      payload: {
        selectedDate: defaultValue,
        endSelectedDate: defaultValue,
        todayDate: defaultValue,
        timerMultiplayer: timer,
        timeLineValue: defaultValue,
      },
    });
  };

  return (
    <div className={"filter-container-top"}>
      <div className="date-picker-box">
        <div className="date-picker">
          <div className="arrow-box left-arrow-1" onClick={lastDay}>
            {" "}
            <FeatherIcon icon="play" size="12" />
          </div>
          <div className="date-box">
            {moment(timeFilter.selectedDate)
              .utc(false)
              .format(t("date.format"))}
          </div>
          <div className="arrow-box right-arrow-1" onClick={nextDay}>
            {" "}
            <FeatherIcon icon="play" size="12" />
          </div>
          <div className="arrow-box calendar-icon-box" onClick={showCalendar}>
            {" "}
            <FeatherIcon icon="calendar" size="14" />
          </div>
        </div>

        {state.visible && (
          <Calendar
            onChange={(e: any) => {
              showCalendar();
              onChangeDate(e);
            }}
            locale={t("language")}
            value={moment(timeFilter.selectedDate)
              .utc(false)
              .endOf("day")
              .toDate()}
            tileClassName={({ date, view }) => {
              if (
                activeDates.find(
                  (x: any) =>
                    moment(x).utcOffset(0).format("DD-MM-YYYY") ===
                    moment(date).utcOffset(0).format("DD-MM-YYYY")
                )
              ) {
                return "highlight";
              } else {
                return "";
              }
            }}
          />
        )}
      </div>
      {state.visible && (
        <div className="hidden-wall" onClick={showCalendar}></div>
      )}
      <div className="slider-box">
        <div
          className="timer-control-box"
          onClick={() => {
            !timerActive ? startRoute() : stopRoute();
          }}
        >
          {timerActive ? (
            <FeatherIcon icon="square" size="18" fill="#fff" stroke="#fff" />
          ) : (
            <FeatherIcon icon="play" size="18" fill="#fff" stroke="#fff" />
          )}
        </div>
        <div
          className="timer-control-box"
          onClick={() => {
            gotToFirstPoint();
          }}
        >
          <FeatherIcon
            icon="fast-forward"
            size="18"
            fill="#fff"
            stroke="#fff"
          />
        </div>
        <div className="time-text">
          {moment(timeFilter.selectedDate).utc(false).format(hourFormat)}
        </div>
        <div className="sliders">
          <div className="box-sliders-custom">
            {trackerList.map((item: any, index: any) => {
              if (item?.routeList?.length > 100)
                return (
                  <div
                    key={item.id}
                    className="rc-slider-track rc-slider-track-2"
                    style={{
                      left:
                        calculationLeft(
                          item.routeList.sort((a: any, b: any) => {
                            return Math.abs(
                              moment(a.date).diff(moment(b.date).toDate())
                            );
                          })[0].date,
                          true
                        ) + "%",
                      right: "auto",
                      width:
                        calculationWidth(
                          item.routeList.sort((a: any, b: any) => {
                            return Math.abs(
                              moment(a.date).diff(moment(b.date).toDate())
                            );
                          })[item.routeList.length - 1].date,
                          calculationLeft(
                            item.routeList.sort((a: any, b: any) => {
                              return Math.abs(
                                moment(a.date).diff(moment(b.date).toDate())
                              );
                            })[0].date,
                            false
                          )
                        ) + "%",
                    }}
                  ></div>
                );
              else return defaultValue;
            })}
          </div>

          <Slider
            // dots
            tipFormatter={(v: any) => {
              return percentFormatter(v, timeFilter.selectedDate);
            }}
            step={1}
            // marks={marks2}
            min={0}
            max={total}
            tipProps={{ overlayClassName: "foo" }}
            value={timeFilter.timeLineValue}
            defaultValue={0}
            onChange={updateDate}
          />
        </div>
        <div
          className="timer-multiplayer-control-box"
          onClick={() => {
            showDropdown();
          }}
        >
          <div>
            {timeFilter.timerMultiplayer === 0.5
              ? ".5x"
              : timeFilter.timerMultiplayer + "x "}
          </div>
          <FeatherIcon icon="settings" size="14" />
        </div>
        {state.dropdown && (
          <div className="hidden-wall" onClick={showDropdown}></div>
        )}
        {state.dropdown && (
          <div className="timer-multiplayer-control-box-toggle">
            <div
              className={"timer-multiplayer-item header-timer-multiplayer"}
              onClick={() => {
                // this.props.addToMultiplayer(0.5);
                // this.showDropdown();
              }}
            >
              {t("name.speed")}
            </div>
            <div
              className={
                timeFilter.timerMultiplayer === 0.5
                  ? "timer-multiplayer-item selected-multiplayer"
                  : "timer-multiplayer-item"
              }
              onClick={() => {
                addToMultiplayer(0.5);
                showDropdown();
              }}
            >
              {timeFilter.timerMultiplayer === 0.5 && (
                <FeatherIcon icon="check" size="14" />
              )}
              {".5x"}
            </div>
            <div
              className={
                timeFilter.timerMultiplayer === 1
                  ? "timer-multiplayer-item selected-multiplayer"
                  : "timer-multiplayer-item"
              }
              onClick={() => {
                addToMultiplayer(1);
                showDropdown();
              }}
            >
              {timeFilter.timerMultiplayer === 1 && (
                <FeatherIcon icon="check" size="14" />
              )}
              {"1x"}
            </div>
            <div
              className={
                timeFilter.timerMultiplayer === 5
                  ? "timer-multiplayer-item selected-multiplayer"
                  : "timer-multiplayer-item"
              }
              onClick={() => {
                addToMultiplayer(5);
                showDropdown();
              }}
            >
              {" "}
              {timeFilter.timerMultiplayer === 5 && (
                <FeatherIcon icon="check" size="14" />
              )}
              {"5x"}
            </div>
            <div
              className={
                timeFilter.timerMultiplayer === 10
                  ? "timer-multiplayer-item selected-multiplayer"
                  : "timer-multiplayer-item"
              }
              onClick={() => {
                addToMultiplayer(10);
                showDropdown();
              }}
            >
              {" "}
              {timeFilter.timerMultiplayer === 10 && (
                <FeatherIcon icon="check" size="14" />
              )}
              {"10x"}
            </div>
            <div
              className={
                timeFilter.timerMultiplayer === 30
                  ? "timer-multiplayer-item selected-multiplayer"
                  : "timer-multiplayer-item"
              }
              onClick={() => {
                addToMultiplayer(30);
                showDropdown();
              }}
            >
              {" "}
              {timeFilter.timerMultiplayer === 30 && (
                <FeatherIcon icon="check" size="14" />
              )}
              {"30x"}
            </div>
            <div
              className={
                timeFilter.timerMultiplayer === 60
                  ? "timer-multiplayer-item selected-multiplayer"
                  : "timer-multiplayer-item"
              }
              onClick={() => {
                addToMultiplayer(60);
                showDropdown();
              }}
            >
              {" "}
              {timeFilter.timerMultiplayer === 60 && (
                <FeatherIcon icon="check" size="14" />
              )}
              {"60x"}
            </div>
            <div
              className={
                timeFilter.timerMultiplayer === 120
                  ? "timer-multiplayer-item selected-multiplayer"
                  : "timer-multiplayer-item"
              }
              onClick={() => {
                addToMultiplayer(120);
                showDropdown();
              }}
            >
              {" "}
              {timeFilter.timerMultiplayer === 120 && (
                <FeatherIcon icon="check" size="14" />
              )}
              {"120x"}
            </div>
            <div
              className={
                timeFilter.timerMultiplayer === 600
                  ? "timer-multiplayer-item selected-multiplayer"
                  : "timer-multiplayer-item"
              }
              onClick={() => {
                addToMultiplayer(600);
                showDropdown();
              }}
            >
              {" "}
              {timeFilter.timerMultiplayer === 600 && (
                <FeatherIcon icon="check" size="14" />
              )}
              {"600x"}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterTop;
