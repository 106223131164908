import {
  ListenReportsMessage,
  Success,
  IdMessage,
  FetchReportsMessage,
  FetchReportsResult,
  LikeUnlikeMessage,
  Report,
} from "./../../server/report/protos/report_pb";
import {
  REPORTS_LIST,
  REPORTS_UPDATES,
  REPORT_TYPES_LIST,
} from "./reportsTypes";
import {
  //CREATE-UPDATE REPORT TYPE
  CreateReportTypeMessage,
  ReportType,
  LanguageVariant,
  //FETCH REPORT TYPE
  FetchReportTypeMessage,
  FetchAllTypesMessage,
  ReportTypeIdMessage,
} from "../../server/report/protos/reportType_pb";
import { ReportTypeServiceClient } from "../../server/report/protos/ReportTypeServiceClientPb";
import { ReportServiceClient } from "../../server/report/protos/ReportServiceClientPb";
import { BACKEND_URL } from "../RootReducer";
import { Metadata } from "grpc-web";

const reportTypeClient = new ReportTypeServiceClient(BACKEND_URL || "");
export const reportClient = new ReportServiceClient(BACKEND_URL || "");
export const ReportHeaders: Metadata = {
  // service_name: "LicenseService",
  "Access-Control-Allow-Origin": "*",
  // crossdomain: true,
  allow_headers: "*",
  // "origin": "http://localhost:3000",
};

export function grpcFetchReportsStream(data: { licenseId: string }, fun?: any) {
  return (dispatch: any) => {
    const reqCRTReport = new ListenReportsMessage();
    reqCRTReport.setType(ListenReportsMessage.ListenReportType.LICENSE);
    reqCRTReport.setIdList([]);
    reqCRTReport.setUserid(data.licenseId);
    reportClient
      .listenReportUpdates(reqCRTReport, ReportHeaders)
      .on("data", (response) => {
        // console.log("response stream", (response as Report).toObject());
        // if (response == null) {
        // } else {
        dispatch({
          type: REPORTS_UPDATES,
          payload: (response as Report).toObject(),
        });
        //   if (fun) {
        //     fun();
        //   }
        // }
      })
      .on("error", (response) => {
        if (response == null) {
          console.log("err  stream", response);
        } else {
          // dispatch({
          //   type: REPORTS_LIST,
          //   payload: response.toObject(),
          // });
          if (fun) {
            fun();
          }
        }
      });
  };
}

export function grpcLikeReport(
  data: { reportId: string; isLike: boolean; userId: string },
  fun?: any
) {
  return (dispatch: any) => {
    const reqCRTReport = new LikeUnlikeMessage();
    reqCRTReport.setReportid(data.reportId);
    reqCRTReport.setIslike(data.isLike);
    reqCRTReport.setUserid(data.userId);

    reportClient.likeUnlikeReport(
      reqCRTReport,
      ReportHeaders,
      (err, response: Success) => {
        if (response == null) {
          console.log("err", err);
        } else {
          // dispatch({
          //   type: REPORTS_LIST,
          //   payload: response.toObject().reportsList,
          // });
          if (fun) {
            fun();
          }
        }
      }
    );
  };
}

export function grpcFetchReports(
  data: { reportIdsList: Array<string>; licenseId: string },
  fun?: any
) {
  return (dispatch: any) => {
    const reqCRTReport = new FetchReportsMessage();
    reqCRTReport.setReportIdsList(data.reportIdsList);
    reqCRTReport.setLicenseId(data.licenseId);

    reportClient.fetchReports(
      reqCRTReport,
      ReportHeaders,
      (err, response: FetchReportsResult) => {
        if (response == null) {
          console.log("err", err);
        } else {
          dispatch({
            type: REPORTS_LIST,
            payload: response.toObject().reportsList,
          });
          if (fun) {
            fun();
          }
        }
      }
    );
  };
}

export function grpcIgnoreReportByLicense(ID: string, fun?: any) {
  return (dispatch: any) => {
    const reqIdReport = new IdMessage();
    reqIdReport.setId(ID);

    reportClient.ignoreReportByLicense(
      reqIdReport,
      ReportHeaders,
      (err, response: Success) => {
        if (response == null) {
          console.log("err", err);
        } else {
          if (fun) {
            fun();
          }
        }
      }
    );
  };
}

export function grpcIgnoreReport(ID: string, fun?: any) {
  return (dispatch: any) => {
    const reqIdReport = new IdMessage();
    reqIdReport.setId(ID);

    reportClient.ignoreReport(
      reqIdReport,
      ReportHeaders,
      (err, response: Success) => {
        if (response == null) {
          console.log("err", err);
        } else {
          if (fun) {
            fun();
          }
        }
      }
    );
  };
}

export function grpcListenReportUpdates(ID: string, fun?: any) {
  return (dispatch: any) => {
    const reqIdReport = new ListenReportsMessage();
    reqIdReport.setIdList([]);
    reqIdReport.setType(0);
    reqIdReport.setUserid("");

    reportClient
      .listenReportUpdates(reqIdReport, ReportHeaders)
      .on("data", (response) => {
        if (fun) {
          fun();
        }
      })
      .on("error", (response) => {
        console.log("err", response);
      });
  };
}

export function grpcCreateReportType(data: any, fun?: any) {
  return (dispatch: any) => {
    let nameList: any = [];

    data.displayName.map((name: any) => {
      let nameAux = new LanguageVariant();
      nameAux.setLangcode(name.langCode);
      nameAux.setValue(name.value);

      nameList.push(nameAux);
    });

    const reqCRTReport = new CreateReportTypeMessage();
    reqCRTReport.setIconmain(data.iconMain);
    reqCRTReport.setIconmap(data.iconMap);
    reqCRTReport.setDisplaynameList(nameList);
    reqCRTReport.setParentid(data.parentId);

    reportTypeClient.createReportType(
      reqCRTReport,
      ReportHeaders,
      (err, response) => {
        if (response == null) {
          console.log("err", err);
        } else {
          if (fun) {
            fun();
          }
        }
      }
    );
  };
}

export function grpcUpdateReportType(data: any, fun?: any) {
  return (dispatch: any) => {
    let nameList: any = [];

    data.displayName.map((name: any) => {
      let nameAux = new LanguageVariant();
      nameAux.setLangcode(name.langCode);
      nameAux.setValue(name.value);

      nameList.push(nameAux);
    });

    const reqURTReport = new ReportType();
    reqURTReport.setReporttypeid(data._id);
    reqURTReport.setIconmain(data.iconMain);
    reqURTReport.setIconmap(data.iconMap);
    reqURTReport.setDisplaynameList(nameList);
    reqURTReport.setParentid(data.parentId);
    reqURTReport.setDeleted(false);

    reportTypeClient.updateReportType(
      reqURTReport,
      ReportHeaders,
      (err, response) => {
        if (response == null) {
          console.log("err", err);
        } else {
          // dispatch({
          //   type: REPORTS_LIST,
          //   payload: reportTypeArray,
          // });
          if (fun) {
            fun();
          }
        }
      }
    );
  };
}

export function grpcFetchReportTypes(data: any, fun?: any) {
  return (dispatch: any) => {
    const reqFRTFetch = new FetchAllTypesMessage();

    reportTypeClient.fetchAllReportTypes(
      reqFRTFetch,
      ReportHeaders,
      (err, response) => {
        if (response == null) {
          console.log("err", err);
        } else {
          let reportTypeArray: ReportType.AsObject[] = [];

          const reportTypesList = response.getReporttypesList();

          reportTypesList.map((rti: ReportType) => {
            let reportTypeItem = rti.toObject();
            // let displayName = reportTypeItem.displaynameList;
            // .getDisplaynameList();
            // console.log(reportTypeItem);
            // let nameArray: any = [];
            // displayName.map((nameItem: any) => {
            //   nameArray[nameItem.getLangcode()] = nameItem.getValue();
            // });

            // let auxItem = {
            //   _id: reportTypeItem.getReporttypeid(),
            //   reportImg: reportTypeItem.getIconmap(),
            //   appImg: reportTypeItem.getIconmain(),
            //   it: nameArray["it"],
            //   es: nameArray["es"],
            //   en: nameArray["en"],
            //   pt: nameArray["pt"],
            //   fr: nameArray["fr"],
            // };

            reportTypeArray.push(reportTypeItem);
          });

          dispatch({
            type: REPORT_TYPES_LIST,
            payload: reportTypeArray,
          });
          if (fun) {
            fun();
          }
        }
      }
    );
  };
}

export function grpcDeleteReportType(data: string, fun?: any) {
  return (dispatch: any) => {
    const reqURTReport = new ReportTypeIdMessage();
    reqURTReport.setId(data);

    reportTypeClient.deleteReportType(
      reqURTReport,
      ReportHeaders,
      (err, response) => {
        if (response == null) {
          console.log("err", err);
        } else {
          // dispatch({
          //   type: REPORTS_LIST,
          //   payload: reportTypeArray,
          // });
          if (fun) {
            fun();
          }
        }
      }
    );
  };
}
