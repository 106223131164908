import { useCallback, useState } from 'react';
import useWebSocket from 'react-use-websocket';
import { Channels, RequestMessage } from '../websockets.model';

export const useWebSocketConnection = () => {

  const url = process.env.WS_URL || 'wss://mc.api.dev.giooby.com/ws';
  const [messages, setMessages] = useState({});
  const token = localStorage.getItem("token");

  const { 
    sendJsonMessage,
    readyState,
  } = useWebSocket(url, {
    onOpen: () => {
      console.log("Connection websocket open");
    },
    onClose: () => console.log("Connection websocket closed"),
    onError: (event) => {
      console.log("WebSocket error observed" + event)
    },
    shouldReconnect: () => true,
    reconnectAttempts: 10,
    reconnectInterval: 3000,
    onMessage: (message) => {
      const parsedMessage = JSON.parse(message.data);
      const { channel, arguments: messageArguments, type } = parsedMessage;
      if (channel && type === 1) {
        setMessages((prevMessages) => ({
          ...prevMessages,
          [channel as string]: messageArguments
        }));
      }
    }
  });

  const subscribeToChannel = useCallback((request: RequestMessage) => {
    if (readyState === WebSocket.OPEN) {
      sendJsonMessage({...request, token, action: 'Subscribe'});
    } else {
      console.log("WebSocket is not open. Unable to subscribe to channel:", request);
    }
  }, [readyState, sendJsonMessage]);

  const unsubscribeFromChannel = useCallback((request: RequestMessage) => {
    if (readyState === WebSocket.OPEN) {
      sendJsonMessage({ ...request, token, action: 'Unsubscribe' });
    } else {
      console.log("WebSocket is not open. Unable to unsubscribe from channel:", request);
    }
  }, [readyState, sendJsonMessage]);

  const getMessagesByChannel = useCallback((channel: Channels) => {
    return messages[channel] || null;
  }, [messages]);

  return {
    sendJsonMessage,
    readyState,
    subscribeToChannel,
    unsubscribeFromChannel,
    getMessagesByChannel,
  };
};