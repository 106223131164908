import React from "react";
// import FeatherIcon from "feather-icons-react";
// import { Arrow } from "../../assets/svg/index";
import "./styles.scss";

class TagsComponent extends React.Component {
  handleDelete(i) {
    if (i >= 0) {
      const tags = this.props.tags.slice(0);
      const d = tags[i];
      tags.splice(i, 1);
      this.props.changeTags(tags);
      const suggestions = [].concat(this.props.suggestions, d);
      this.props.changeSuggestions(suggestions);
    }
  }

  render() {
    return (
      <div
        className={
          this.props.edit
            ? "tag-box-search tag-box-component tag-edit"
            : "tag-box-search tag-box-component tag-no-edit"
        }
      >
        <div className="selector-box-content">
          {this.props.tags.length > 0 && (
            <div
              className="react-tags__selected"
              aria-live="polite"
              aria-relevant="additions removals"
            >
              {this.props.tags.map((e, index) => (
                <button
                  key={index}
                  type="button"
                  className="react-tags__selected-tag"
                  title="Click to remove tag"
                  onClick={() =>
                    this.props.edit ? this.handleDelete(index) : () => {}
                  }
                >
                  <span className="react-tags__selected-tag-name">
                    {e.name}
                  </span>
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default TagsComponent;
// {/* <div className="selector-box-content">
//       <ReactTags
//         placeholder={this.props.placeholder}
//         tags={this.props.tags}
//         minQueryLength={1}
//         maxSuggestionsLength={8}
//         suggestions={this.props.suggestions}
//         handleDelete={this.handleDelete.bind(this)}
//         handleAddition={this.handleAddition.bind(this)}
//         handleInputChange={this.props.onChange}
//       />
//       <div className="plus-icon">
//         <Add></Add>
//       </div>
//       {this.props.invalid && (
//         <span className="error-message">{this.props.message}</span>
//       )}
//     </div> */}
