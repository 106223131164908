import { Team } from "../../server/team/protos/team_pb";

export const TEAMS_LOADING = "TEAMS_LOADING";
export const TEAMS_ERROR = "TEAMS_ERROR";
export const TEAMS_LIST = "TEAMS_LIST";
export const TEAMS_SELECTED = "TEAMS_SELECTED";
export const TEAMS_SELECTED_ROUTES = "TEAMS_SELECTED_ROUTES";

export const TEAMS_UPDATES = "TEAMS_UPDATES";

export const TEAM_ASSIGN = "TEAM_ASSIGN";

//================================================================

export interface TeamsLoading {
  type: typeof TEAMS_LOADING;
}

export interface TeamsUpdates {
  type: typeof TEAMS_UPDATES;
  payload: Team.AsObject;
}

export interface TeamsAssign {
  type: typeof TEAM_ASSIGN;
  payload: Team.AsObject;
}

export interface TeamsError {
  type: typeof TEAMS_ERROR;
}

export interface TeamsList {
  type: typeof TEAMS_LIST;
  payload: Team.AsObject[];
}

export interface TeamsSelectedRoutes {
  type: typeof TEAMS_SELECTED_ROUTES;
  payload: Team.AsObject;
}

export interface TeamsSelected {
  type: typeof TEAMS_SELECTED;
  payload: Team.AsObject;
}

export type TeamsDispatchTypes =
  | TeamsUpdates
  | TeamsLoading
  | TeamsError
  | TeamsList
  | TeamsSelected
  | TeamsSelectedRoutes
  | TeamsAssign;
