// source: protos/reportAnalytics.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.ReportByMonthMessage', null, global);
goog.exportSymbol('proto.ReportsByCategoryCustomMessage', null, global);
goog.exportSymbol('proto.ReportsByCategoryCustomResult', null, global);
goog.exportSymbol('proto.ReportsByCategoryCustomResult.Detail', null, global);
goog.exportSymbol('proto.ReportsByCategoryCustomResult.Result', null, global);
goog.exportSymbol('proto.ReportsByCategoryMessage', null, global);
goog.exportSymbol('proto.ReportsByCategoryResult', null, global);
goog.exportSymbol('proto.ReportsByCategoryResult.Detail', null, global);
goog.exportSymbol('proto.ReportsByCategoryResult.Result', null, global);
goog.exportSymbol('proto.ReportsByMonthResult', null, global);
goog.exportSymbol('proto.ReportsByMonthResult.Result', null, global);
goog.exportSymbol('proto.ReportsByStatusMessage', null, global);
goog.exportSymbol('proto.ReportsByStatusResult', null, global);
goog.exportSymbol('proto.ReportsByStatusResult.ReportDelay', null, global);
goog.exportSymbol('proto.ReportsByStatusResult.Result', null, global);
goog.exportSymbol('proto.ReportsResponseTimeMessage', null, global);
goog.exportSymbol('proto.ReportsResponseTimeResult', null, global);
goog.exportSymbol('proto.ReportsResponseTimeResult.Result', null, global);
goog.exportSymbol('proto.ReportsStatsByUsersMessage', null, global);
goog.exportSymbol('proto.ReportsStatsByUsersResult', null, global);
goog.exportSymbol('proto.ReportsStatsByUsersResult.Result', null, global);
goog.exportSymbol('proto.ReportsUniqueUsersMessage', null, global);
goog.exportSymbol('proto.ReportsUniqueUsersResult', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ReportsByStatusMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ReportsByStatusMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ReportsByStatusMessage.displayName = 'proto.ReportsByStatusMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ReportByMonthMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ReportByMonthMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ReportByMonthMessage.displayName = 'proto.ReportByMonthMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ReportsByCategoryMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ReportsByCategoryMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ReportsByCategoryMessage.displayName = 'proto.ReportsByCategoryMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ReportsByCategoryCustomMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ReportsByCategoryCustomMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ReportsByCategoryCustomMessage.displayName = 'proto.ReportsByCategoryCustomMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ReportsResponseTimeMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ReportsResponseTimeMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ReportsResponseTimeMessage.displayName = 'proto.ReportsResponseTimeMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ReportsUniqueUsersMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ReportsUniqueUsersMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ReportsUniqueUsersMessage.displayName = 'proto.ReportsUniqueUsersMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ReportsStatsByUsersMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ReportsStatsByUsersMessage.repeatedFields_, null);
};
goog.inherits(proto.ReportsStatsByUsersMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ReportsStatsByUsersMessage.displayName = 'proto.ReportsStatsByUsersMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ReportsByStatusResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ReportsByStatusResult.repeatedFields_, null);
};
goog.inherits(proto.ReportsByStatusResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ReportsByStatusResult.displayName = 'proto.ReportsByStatusResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ReportsByStatusResult.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ReportsByStatusResult.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ReportsByStatusResult.Result.displayName = 'proto.ReportsByStatusResult.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ReportsByStatusResult.ReportDelay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ReportsByStatusResult.ReportDelay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ReportsByStatusResult.ReportDelay.displayName = 'proto.ReportsByStatusResult.ReportDelay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ReportsByMonthResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ReportsByMonthResult.repeatedFields_, null);
};
goog.inherits(proto.ReportsByMonthResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ReportsByMonthResult.displayName = 'proto.ReportsByMonthResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ReportsByMonthResult.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ReportsByMonthResult.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ReportsByMonthResult.Result.displayName = 'proto.ReportsByMonthResult.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ReportsByCategoryResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ReportsByCategoryResult.repeatedFields_, null);
};
goog.inherits(proto.ReportsByCategoryResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ReportsByCategoryResult.displayName = 'proto.ReportsByCategoryResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ReportsByCategoryResult.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ReportsByCategoryResult.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ReportsByCategoryResult.Result.displayName = 'proto.ReportsByCategoryResult.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ReportsByCategoryResult.Detail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ReportsByCategoryResult.Detail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ReportsByCategoryResult.Detail.displayName = 'proto.ReportsByCategoryResult.Detail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ReportsByCategoryCustomResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ReportsByCategoryCustomResult.repeatedFields_, null);
};
goog.inherits(proto.ReportsByCategoryCustomResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ReportsByCategoryCustomResult.displayName = 'proto.ReportsByCategoryCustomResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ReportsByCategoryCustomResult.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ReportsByCategoryCustomResult.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ReportsByCategoryCustomResult.Result.displayName = 'proto.ReportsByCategoryCustomResult.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ReportsByCategoryCustomResult.Detail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ReportsByCategoryCustomResult.Detail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ReportsByCategoryCustomResult.Detail.displayName = 'proto.ReportsByCategoryCustomResult.Detail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ReportsResponseTimeResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ReportsResponseTimeResult.repeatedFields_, null);
};
goog.inherits(proto.ReportsResponseTimeResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ReportsResponseTimeResult.displayName = 'proto.ReportsResponseTimeResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ReportsResponseTimeResult.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ReportsResponseTimeResult.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ReportsResponseTimeResult.Result.displayName = 'proto.ReportsResponseTimeResult.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ReportsUniqueUsersResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ReportsUniqueUsersResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ReportsUniqueUsersResult.displayName = 'proto.ReportsUniqueUsersResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ReportsStatsByUsersResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ReportsStatsByUsersResult.repeatedFields_, null);
};
goog.inherits(proto.ReportsStatsByUsersResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ReportsStatsByUsersResult.displayName = 'proto.ReportsStatsByUsersResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ReportsStatsByUsersResult.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ReportsStatsByUsersResult.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ReportsStatsByUsersResult.Result.displayName = 'proto.ReportsStatsByUsersResult.Result';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ReportsByStatusMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.ReportsByStatusMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ReportsByStatusMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportsByStatusMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    licenseid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ReportsByStatusMessage}
 */
proto.ReportsByStatusMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ReportsByStatusMessage;
  return proto.ReportsByStatusMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ReportsByStatusMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ReportsByStatusMessage}
 */
proto.ReportsByStatusMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLicenseid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ReportsByStatusMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ReportsByStatusMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ReportsByStatusMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportsByStatusMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLicenseid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string licenseId = 1;
 * @return {string}
 */
proto.ReportsByStatusMessage.prototype.getLicenseid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ReportsByStatusMessage} returns this
 */
proto.ReportsByStatusMessage.prototype.setLicenseid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ReportByMonthMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.ReportByMonthMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ReportByMonthMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportByMonthMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    licenseid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    from: (f = msg.getFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    to: (f = msg.getTo()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ReportByMonthMessage}
 */
proto.ReportByMonthMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ReportByMonthMessage;
  return proto.ReportByMonthMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ReportByMonthMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ReportByMonthMessage}
 */
proto.ReportByMonthMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLicenseid(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFrom(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ReportByMonthMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ReportByMonthMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ReportByMonthMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportByMonthMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLicenseid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFrom();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string licenseId = 1;
 * @return {string}
 */
proto.ReportByMonthMessage.prototype.getLicenseid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ReportByMonthMessage} returns this
 */
proto.ReportByMonthMessage.prototype.setLicenseid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp from = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ReportByMonthMessage.prototype.getFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ReportByMonthMessage} returns this
*/
proto.ReportByMonthMessage.prototype.setFrom = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ReportByMonthMessage} returns this
 */
proto.ReportByMonthMessage.prototype.clearFrom = function() {
  return this.setFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ReportByMonthMessage.prototype.hasFrom = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp to = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ReportByMonthMessage.prototype.getTo = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ReportByMonthMessage} returns this
*/
proto.ReportByMonthMessage.prototype.setTo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ReportByMonthMessage} returns this
 */
proto.ReportByMonthMessage.prototype.clearTo = function() {
  return this.setTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ReportByMonthMessage.prototype.hasTo = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ReportsByCategoryMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.ReportsByCategoryMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ReportsByCategoryMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportsByCategoryMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    licenseid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ReportsByCategoryMessage}
 */
proto.ReportsByCategoryMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ReportsByCategoryMessage;
  return proto.ReportsByCategoryMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ReportsByCategoryMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ReportsByCategoryMessage}
 */
proto.ReportsByCategoryMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLicenseid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ReportsByCategoryMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ReportsByCategoryMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ReportsByCategoryMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportsByCategoryMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLicenseid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string licenseId = 1;
 * @return {string}
 */
proto.ReportsByCategoryMessage.prototype.getLicenseid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ReportsByCategoryMessage} returns this
 */
proto.ReportsByCategoryMessage.prototype.setLicenseid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ReportsByCategoryCustomMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.ReportsByCategoryCustomMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ReportsByCategoryCustomMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportsByCategoryCustomMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    licenseid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    from: (f = msg.getFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    to: (f = msg.getTo()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ReportsByCategoryCustomMessage}
 */
proto.ReportsByCategoryCustomMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ReportsByCategoryCustomMessage;
  return proto.ReportsByCategoryCustomMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ReportsByCategoryCustomMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ReportsByCategoryCustomMessage}
 */
proto.ReportsByCategoryCustomMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLicenseid(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFrom(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ReportsByCategoryCustomMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ReportsByCategoryCustomMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ReportsByCategoryCustomMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportsByCategoryCustomMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLicenseid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFrom();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string licenseId = 1;
 * @return {string}
 */
proto.ReportsByCategoryCustomMessage.prototype.getLicenseid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ReportsByCategoryCustomMessage} returns this
 */
proto.ReportsByCategoryCustomMessage.prototype.setLicenseid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp from = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ReportsByCategoryCustomMessage.prototype.getFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ReportsByCategoryCustomMessage} returns this
*/
proto.ReportsByCategoryCustomMessage.prototype.setFrom = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ReportsByCategoryCustomMessage} returns this
 */
proto.ReportsByCategoryCustomMessage.prototype.clearFrom = function() {
  return this.setFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ReportsByCategoryCustomMessage.prototype.hasFrom = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp to = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ReportsByCategoryCustomMessage.prototype.getTo = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ReportsByCategoryCustomMessage} returns this
*/
proto.ReportsByCategoryCustomMessage.prototype.setTo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ReportsByCategoryCustomMessage} returns this
 */
proto.ReportsByCategoryCustomMessage.prototype.clearTo = function() {
  return this.setTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ReportsByCategoryCustomMessage.prototype.hasTo = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ReportsResponseTimeMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.ReportsResponseTimeMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ReportsResponseTimeMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportsResponseTimeMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    licenseid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    from: (f = msg.getFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    to: (f = msg.getTo()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ReportsResponseTimeMessage}
 */
proto.ReportsResponseTimeMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ReportsResponseTimeMessage;
  return proto.ReportsResponseTimeMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ReportsResponseTimeMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ReportsResponseTimeMessage}
 */
proto.ReportsResponseTimeMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLicenseid(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFrom(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ReportsResponseTimeMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ReportsResponseTimeMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ReportsResponseTimeMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportsResponseTimeMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLicenseid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFrom();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string licenseId = 1;
 * @return {string}
 */
proto.ReportsResponseTimeMessage.prototype.getLicenseid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ReportsResponseTimeMessage} returns this
 */
proto.ReportsResponseTimeMessage.prototype.setLicenseid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp from = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ReportsResponseTimeMessage.prototype.getFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ReportsResponseTimeMessage} returns this
*/
proto.ReportsResponseTimeMessage.prototype.setFrom = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ReportsResponseTimeMessage} returns this
 */
proto.ReportsResponseTimeMessage.prototype.clearFrom = function() {
  return this.setFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ReportsResponseTimeMessage.prototype.hasFrom = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp to = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ReportsResponseTimeMessage.prototype.getTo = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ReportsResponseTimeMessage} returns this
*/
proto.ReportsResponseTimeMessage.prototype.setTo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ReportsResponseTimeMessage} returns this
 */
proto.ReportsResponseTimeMessage.prototype.clearTo = function() {
  return this.setTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ReportsResponseTimeMessage.prototype.hasTo = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ReportsUniqueUsersMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.ReportsUniqueUsersMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ReportsUniqueUsersMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportsUniqueUsersMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    licenseid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ReportsUniqueUsersMessage}
 */
proto.ReportsUniqueUsersMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ReportsUniqueUsersMessage;
  return proto.ReportsUniqueUsersMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ReportsUniqueUsersMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ReportsUniqueUsersMessage}
 */
proto.ReportsUniqueUsersMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLicenseid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ReportsUniqueUsersMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ReportsUniqueUsersMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ReportsUniqueUsersMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportsUniqueUsersMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLicenseid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string licenseId = 1;
 * @return {string}
 */
proto.ReportsUniqueUsersMessage.prototype.getLicenseid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ReportsUniqueUsersMessage} returns this
 */
proto.ReportsUniqueUsersMessage.prototype.setLicenseid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ReportsStatsByUsersMessage.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ReportsStatsByUsersMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.ReportsStatsByUsersMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ReportsStatsByUsersMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportsStatsByUsersMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    useridsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ReportsStatsByUsersMessage}
 */
proto.ReportsStatsByUsersMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ReportsStatsByUsersMessage;
  return proto.ReportsStatsByUsersMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ReportsStatsByUsersMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ReportsStatsByUsersMessage}
 */
proto.ReportsStatsByUsersMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addUserids(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ReportsStatsByUsersMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ReportsStatsByUsersMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ReportsStatsByUsersMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportsStatsByUsersMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUseridsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string userIds = 1;
 * @return {!Array<string>}
 */
proto.ReportsStatsByUsersMessage.prototype.getUseridsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.ReportsStatsByUsersMessage} returns this
 */
proto.ReportsStatsByUsersMessage.prototype.setUseridsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.ReportsStatsByUsersMessage} returns this
 */
proto.ReportsStatsByUsersMessage.prototype.addUserids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ReportsStatsByUsersMessage} returns this
 */
proto.ReportsStatsByUsersMessage.prototype.clearUseridsList = function() {
  return this.setUseridsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ReportsByStatusResult.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ReportsByStatusResult.prototype.toObject = function(opt_includeInstance) {
  return proto.ReportsByStatusResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ReportsByStatusResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportsByStatusResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    todayStats: (f = msg.getTodayStats()) && proto.ReportsByStatusResult.Result.toObject(includeInstance, f),
    days7Stats: (f = msg.getDays7Stats()) && proto.ReportsByStatusResult.Result.toObject(includeInstance, f),
    month1Stats: (f = msg.getMonth1Stats()) && proto.ReportsByStatusResult.Result.toObject(includeInstance, f),
    month6Stats: (f = msg.getMonth6Stats()) && proto.ReportsByStatusResult.Result.toObject(includeInstance, f),
    year1Stats: (f = msg.getYear1Stats()) && proto.ReportsByStatusResult.Result.toObject(includeInstance, f),
    reportDelaysList: jspb.Message.toObjectList(msg.getReportDelaysList(),
    proto.ReportsByStatusResult.ReportDelay.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ReportsByStatusResult}
 */
proto.ReportsByStatusResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ReportsByStatusResult;
  return proto.ReportsByStatusResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ReportsByStatusResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ReportsByStatusResult}
 */
proto.ReportsByStatusResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ReportsByStatusResult.Result;
      reader.readMessage(value,proto.ReportsByStatusResult.Result.deserializeBinaryFromReader);
      msg.setTodayStats(value);
      break;
    case 2:
      var value = new proto.ReportsByStatusResult.Result;
      reader.readMessage(value,proto.ReportsByStatusResult.Result.deserializeBinaryFromReader);
      msg.setDays7Stats(value);
      break;
    case 3:
      var value = new proto.ReportsByStatusResult.Result;
      reader.readMessage(value,proto.ReportsByStatusResult.Result.deserializeBinaryFromReader);
      msg.setMonth1Stats(value);
      break;
    case 4:
      var value = new proto.ReportsByStatusResult.Result;
      reader.readMessage(value,proto.ReportsByStatusResult.Result.deserializeBinaryFromReader);
      msg.setMonth6Stats(value);
      break;
    case 5:
      var value = new proto.ReportsByStatusResult.Result;
      reader.readMessage(value,proto.ReportsByStatusResult.Result.deserializeBinaryFromReader);
      msg.setYear1Stats(value);
      break;
    case 6:
      var value = new proto.ReportsByStatusResult.ReportDelay;
      reader.readMessage(value,proto.ReportsByStatusResult.ReportDelay.deserializeBinaryFromReader);
      msg.addReportDelays(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ReportsByStatusResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ReportsByStatusResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ReportsByStatusResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportsByStatusResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTodayStats();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ReportsByStatusResult.Result.serializeBinaryToWriter
    );
  }
  f = message.getDays7Stats();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.ReportsByStatusResult.Result.serializeBinaryToWriter
    );
  }
  f = message.getMonth1Stats();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.ReportsByStatusResult.Result.serializeBinaryToWriter
    );
  }
  f = message.getMonth6Stats();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.ReportsByStatusResult.Result.serializeBinaryToWriter
    );
  }
  f = message.getYear1Stats();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.ReportsByStatusResult.Result.serializeBinaryToWriter
    );
  }
  f = message.getReportDelaysList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.ReportsByStatusResult.ReportDelay.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ReportsByStatusResult.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.ReportsByStatusResult.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ReportsByStatusResult.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportsByStatusResult.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    pending: jspb.Message.getFieldWithDefault(msg, 1, 0),
    progress: jspb.Message.getFieldWithDefault(msg, 2, 0),
    completed: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ReportsByStatusResult.Result}
 */
proto.ReportsByStatusResult.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ReportsByStatusResult.Result;
  return proto.ReportsByStatusResult.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ReportsByStatusResult.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ReportsByStatusResult.Result}
 */
proto.ReportsByStatusResult.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPending(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProgress(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompleted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ReportsByStatusResult.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ReportsByStatusResult.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ReportsByStatusResult.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportsByStatusResult.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPending();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getProgress();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCompleted();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 pending = 1;
 * @return {number}
 */
proto.ReportsByStatusResult.Result.prototype.getPending = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ReportsByStatusResult.Result} returns this
 */
proto.ReportsByStatusResult.Result.prototype.setPending = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 progress = 2;
 * @return {number}
 */
proto.ReportsByStatusResult.Result.prototype.getProgress = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.ReportsByStatusResult.Result} returns this
 */
proto.ReportsByStatusResult.Result.prototype.setProgress = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 completed = 3;
 * @return {number}
 */
proto.ReportsByStatusResult.Result.prototype.getCompleted = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.ReportsByStatusResult.Result} returns this
 */
proto.ReportsByStatusResult.Result.prototype.setCompleted = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ReportsByStatusResult.ReportDelay.prototype.toObject = function(opt_includeInstance) {
  return proto.ReportsByStatusResult.ReportDelay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ReportsByStatusResult.ReportDelay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportsByStatusResult.ReportDelay.toObject = function(includeInstance, msg) {
  var f, obj = {
    reportid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    estimatedtime: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    workprogressdate: (f = msg.getWorkprogressdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ReportsByStatusResult.ReportDelay}
 */
proto.ReportsByStatusResult.ReportDelay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ReportsByStatusResult.ReportDelay;
  return proto.ReportsByStatusResult.ReportDelay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ReportsByStatusResult.ReportDelay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ReportsByStatusResult.ReportDelay}
 */
proto.ReportsByStatusResult.ReportDelay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReportid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setEstimatedtime(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setWorkprogressdate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ReportsByStatusResult.ReportDelay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ReportsByStatusResult.ReportDelay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ReportsByStatusResult.ReportDelay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportsByStatusResult.ReportDelay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReportid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEstimatedtime();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getWorkprogressdate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string reportId = 1;
 * @return {string}
 */
proto.ReportsByStatusResult.ReportDelay.prototype.getReportid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ReportsByStatusResult.ReportDelay} returns this
 */
proto.ReportsByStatusResult.ReportDelay.prototype.setReportid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float estimatedTime = 2;
 * @return {number}
 */
proto.ReportsByStatusResult.ReportDelay.prototype.getEstimatedtime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ReportsByStatusResult.ReportDelay} returns this
 */
proto.ReportsByStatusResult.ReportDelay.prototype.setEstimatedtime = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp workProgressDate = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ReportsByStatusResult.ReportDelay.prototype.getWorkprogressdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ReportsByStatusResult.ReportDelay} returns this
*/
proto.ReportsByStatusResult.ReportDelay.prototype.setWorkprogressdate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ReportsByStatusResult.ReportDelay} returns this
 */
proto.ReportsByStatusResult.ReportDelay.prototype.clearWorkprogressdate = function() {
  return this.setWorkprogressdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ReportsByStatusResult.ReportDelay.prototype.hasWorkprogressdate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Result today_stats = 1;
 * @return {?proto.ReportsByStatusResult.Result}
 */
proto.ReportsByStatusResult.prototype.getTodayStats = function() {
  return /** @type{?proto.ReportsByStatusResult.Result} */ (
    jspb.Message.getWrapperField(this, proto.ReportsByStatusResult.Result, 1));
};


/**
 * @param {?proto.ReportsByStatusResult.Result|undefined} value
 * @return {!proto.ReportsByStatusResult} returns this
*/
proto.ReportsByStatusResult.prototype.setTodayStats = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ReportsByStatusResult} returns this
 */
proto.ReportsByStatusResult.prototype.clearTodayStats = function() {
  return this.setTodayStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ReportsByStatusResult.prototype.hasTodayStats = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Result days7_stats = 2;
 * @return {?proto.ReportsByStatusResult.Result}
 */
proto.ReportsByStatusResult.prototype.getDays7Stats = function() {
  return /** @type{?proto.ReportsByStatusResult.Result} */ (
    jspb.Message.getWrapperField(this, proto.ReportsByStatusResult.Result, 2));
};


/**
 * @param {?proto.ReportsByStatusResult.Result|undefined} value
 * @return {!proto.ReportsByStatusResult} returns this
*/
proto.ReportsByStatusResult.prototype.setDays7Stats = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ReportsByStatusResult} returns this
 */
proto.ReportsByStatusResult.prototype.clearDays7Stats = function() {
  return this.setDays7Stats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ReportsByStatusResult.prototype.hasDays7Stats = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Result month1_stats = 3;
 * @return {?proto.ReportsByStatusResult.Result}
 */
proto.ReportsByStatusResult.prototype.getMonth1Stats = function() {
  return /** @type{?proto.ReportsByStatusResult.Result} */ (
    jspb.Message.getWrapperField(this, proto.ReportsByStatusResult.Result, 3));
};


/**
 * @param {?proto.ReportsByStatusResult.Result|undefined} value
 * @return {!proto.ReportsByStatusResult} returns this
*/
proto.ReportsByStatusResult.prototype.setMonth1Stats = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ReportsByStatusResult} returns this
 */
proto.ReportsByStatusResult.prototype.clearMonth1Stats = function() {
  return this.setMonth1Stats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ReportsByStatusResult.prototype.hasMonth1Stats = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Result month6_stats = 4;
 * @return {?proto.ReportsByStatusResult.Result}
 */
proto.ReportsByStatusResult.prototype.getMonth6Stats = function() {
  return /** @type{?proto.ReportsByStatusResult.Result} */ (
    jspb.Message.getWrapperField(this, proto.ReportsByStatusResult.Result, 4));
};


/**
 * @param {?proto.ReportsByStatusResult.Result|undefined} value
 * @return {!proto.ReportsByStatusResult} returns this
*/
proto.ReportsByStatusResult.prototype.setMonth6Stats = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ReportsByStatusResult} returns this
 */
proto.ReportsByStatusResult.prototype.clearMonth6Stats = function() {
  return this.setMonth6Stats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ReportsByStatusResult.prototype.hasMonth6Stats = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Result year1_stats = 5;
 * @return {?proto.ReportsByStatusResult.Result}
 */
proto.ReportsByStatusResult.prototype.getYear1Stats = function() {
  return /** @type{?proto.ReportsByStatusResult.Result} */ (
    jspb.Message.getWrapperField(this, proto.ReportsByStatusResult.Result, 5));
};


/**
 * @param {?proto.ReportsByStatusResult.Result|undefined} value
 * @return {!proto.ReportsByStatusResult} returns this
*/
proto.ReportsByStatusResult.prototype.setYear1Stats = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ReportsByStatusResult} returns this
 */
proto.ReportsByStatusResult.prototype.clearYear1Stats = function() {
  return this.setYear1Stats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ReportsByStatusResult.prototype.hasYear1Stats = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated ReportDelay report_delays = 6;
 * @return {!Array<!proto.ReportsByStatusResult.ReportDelay>}
 */
proto.ReportsByStatusResult.prototype.getReportDelaysList = function() {
  return /** @type{!Array<!proto.ReportsByStatusResult.ReportDelay>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ReportsByStatusResult.ReportDelay, 6));
};


/**
 * @param {!Array<!proto.ReportsByStatusResult.ReportDelay>} value
 * @return {!proto.ReportsByStatusResult} returns this
*/
proto.ReportsByStatusResult.prototype.setReportDelaysList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.ReportsByStatusResult.ReportDelay=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ReportsByStatusResult.ReportDelay}
 */
proto.ReportsByStatusResult.prototype.addReportDelays = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.ReportsByStatusResult.ReportDelay, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ReportsByStatusResult} returns this
 */
proto.ReportsByStatusResult.prototype.clearReportDelaysList = function() {
  return this.setReportDelaysList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ReportsByMonthResult.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ReportsByMonthResult.prototype.toObject = function(opt_includeInstance) {
  return proto.ReportsByMonthResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ReportsByMonthResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportsByMonthResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    responseList: jspb.Message.toObjectList(msg.getResponseList(),
    proto.ReportsByMonthResult.Result.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ReportsByMonthResult}
 */
proto.ReportsByMonthResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ReportsByMonthResult;
  return proto.ReportsByMonthResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ReportsByMonthResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ReportsByMonthResult}
 */
proto.ReportsByMonthResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ReportsByMonthResult.Result;
      reader.readMessage(value,proto.ReportsByMonthResult.Result.deserializeBinaryFromReader);
      msg.addResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ReportsByMonthResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ReportsByMonthResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ReportsByMonthResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportsByMonthResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResponseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ReportsByMonthResult.Result.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ReportsByMonthResult.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.ReportsByMonthResult.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ReportsByMonthResult.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportsByMonthResult.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    year: jspb.Message.getFieldWithDefault(msg, 1, 0),
    month: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pending: jspb.Message.getFieldWithDefault(msg, 3, 0),
    progress: jspb.Message.getFieldWithDefault(msg, 4, 0),
    completed: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ReportsByMonthResult.Result}
 */
proto.ReportsByMonthResult.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ReportsByMonthResult.Result;
  return proto.ReportsByMonthResult.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ReportsByMonthResult.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ReportsByMonthResult.Result}
 */
proto.ReportsByMonthResult.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonth(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPending(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProgress(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompleted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ReportsByMonthResult.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ReportsByMonthResult.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ReportsByMonthResult.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportsByMonthResult.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMonth();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPending();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getProgress();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getCompleted();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int32 year = 1;
 * @return {number}
 */
proto.ReportsByMonthResult.Result.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ReportsByMonthResult.Result} returns this
 */
proto.ReportsByMonthResult.Result.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 month = 2;
 * @return {number}
 */
proto.ReportsByMonthResult.Result.prototype.getMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.ReportsByMonthResult.Result} returns this
 */
proto.ReportsByMonthResult.Result.prototype.setMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 pending = 3;
 * @return {number}
 */
proto.ReportsByMonthResult.Result.prototype.getPending = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.ReportsByMonthResult.Result} returns this
 */
proto.ReportsByMonthResult.Result.prototype.setPending = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 progress = 4;
 * @return {number}
 */
proto.ReportsByMonthResult.Result.prototype.getProgress = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.ReportsByMonthResult.Result} returns this
 */
proto.ReportsByMonthResult.Result.prototype.setProgress = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 completed = 5;
 * @return {number}
 */
proto.ReportsByMonthResult.Result.prototype.getCompleted = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.ReportsByMonthResult.Result} returns this
 */
proto.ReportsByMonthResult.Result.prototype.setCompleted = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated Result response = 1;
 * @return {!Array<!proto.ReportsByMonthResult.Result>}
 */
proto.ReportsByMonthResult.prototype.getResponseList = function() {
  return /** @type{!Array<!proto.ReportsByMonthResult.Result>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ReportsByMonthResult.Result, 1));
};


/**
 * @param {!Array<!proto.ReportsByMonthResult.Result>} value
 * @return {!proto.ReportsByMonthResult} returns this
*/
proto.ReportsByMonthResult.prototype.setResponseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ReportsByMonthResult.Result=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ReportsByMonthResult.Result}
 */
proto.ReportsByMonthResult.prototype.addResponse = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ReportsByMonthResult.Result, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ReportsByMonthResult} returns this
 */
proto.ReportsByMonthResult.prototype.clearResponseList = function() {
  return this.setResponseList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ReportsByCategoryResult.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ReportsByCategoryResult.prototype.toObject = function(opt_includeInstance) {
  return proto.ReportsByCategoryResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ReportsByCategoryResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportsByCategoryResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    responseList: jspb.Message.toObjectList(msg.getResponseList(),
    proto.ReportsByCategoryResult.Result.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ReportsByCategoryResult}
 */
proto.ReportsByCategoryResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ReportsByCategoryResult;
  return proto.ReportsByCategoryResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ReportsByCategoryResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ReportsByCategoryResult}
 */
proto.ReportsByCategoryResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ReportsByCategoryResult.Result;
      reader.readMessage(value,proto.ReportsByCategoryResult.Result.deserializeBinaryFromReader);
      msg.addResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ReportsByCategoryResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ReportsByCategoryResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ReportsByCategoryResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportsByCategoryResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResponseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ReportsByCategoryResult.Result.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ReportsByCategoryResult.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.ReportsByCategoryResult.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ReportsByCategoryResult.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportsByCategoryResult.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    category: jspb.Message.getFieldWithDefault(msg, 1, ""),
    todayStats: (f = msg.getTodayStats()) && proto.ReportsByCategoryResult.Detail.toObject(includeInstance, f),
    days7Stats: (f = msg.getDays7Stats()) && proto.ReportsByCategoryResult.Detail.toObject(includeInstance, f),
    month1Stats: (f = msg.getMonth1Stats()) && proto.ReportsByCategoryResult.Detail.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ReportsByCategoryResult.Result}
 */
proto.ReportsByCategoryResult.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ReportsByCategoryResult.Result;
  return proto.ReportsByCategoryResult.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ReportsByCategoryResult.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ReportsByCategoryResult.Result}
 */
proto.ReportsByCategoryResult.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 2:
      var value = new proto.ReportsByCategoryResult.Detail;
      reader.readMessage(value,proto.ReportsByCategoryResult.Detail.deserializeBinaryFromReader);
      msg.setTodayStats(value);
      break;
    case 3:
      var value = new proto.ReportsByCategoryResult.Detail;
      reader.readMessage(value,proto.ReportsByCategoryResult.Detail.deserializeBinaryFromReader);
      msg.setDays7Stats(value);
      break;
    case 4:
      var value = new proto.ReportsByCategoryResult.Detail;
      reader.readMessage(value,proto.ReportsByCategoryResult.Detail.deserializeBinaryFromReader);
      msg.setMonth1Stats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ReportsByCategoryResult.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ReportsByCategoryResult.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ReportsByCategoryResult.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportsByCategoryResult.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTodayStats();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.ReportsByCategoryResult.Detail.serializeBinaryToWriter
    );
  }
  f = message.getDays7Stats();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.ReportsByCategoryResult.Detail.serializeBinaryToWriter
    );
  }
  f = message.getMonth1Stats();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.ReportsByCategoryResult.Detail.serializeBinaryToWriter
    );
  }
};


/**
 * optional string category = 1;
 * @return {string}
 */
proto.ReportsByCategoryResult.Result.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ReportsByCategoryResult.Result} returns this
 */
proto.ReportsByCategoryResult.Result.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Detail today_stats = 2;
 * @return {?proto.ReportsByCategoryResult.Detail}
 */
proto.ReportsByCategoryResult.Result.prototype.getTodayStats = function() {
  return /** @type{?proto.ReportsByCategoryResult.Detail} */ (
    jspb.Message.getWrapperField(this, proto.ReportsByCategoryResult.Detail, 2));
};


/**
 * @param {?proto.ReportsByCategoryResult.Detail|undefined} value
 * @return {!proto.ReportsByCategoryResult.Result} returns this
*/
proto.ReportsByCategoryResult.Result.prototype.setTodayStats = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ReportsByCategoryResult.Result} returns this
 */
proto.ReportsByCategoryResult.Result.prototype.clearTodayStats = function() {
  return this.setTodayStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ReportsByCategoryResult.Result.prototype.hasTodayStats = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Detail days7_stats = 3;
 * @return {?proto.ReportsByCategoryResult.Detail}
 */
proto.ReportsByCategoryResult.Result.prototype.getDays7Stats = function() {
  return /** @type{?proto.ReportsByCategoryResult.Detail} */ (
    jspb.Message.getWrapperField(this, proto.ReportsByCategoryResult.Detail, 3));
};


/**
 * @param {?proto.ReportsByCategoryResult.Detail|undefined} value
 * @return {!proto.ReportsByCategoryResult.Result} returns this
*/
proto.ReportsByCategoryResult.Result.prototype.setDays7Stats = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ReportsByCategoryResult.Result} returns this
 */
proto.ReportsByCategoryResult.Result.prototype.clearDays7Stats = function() {
  return this.setDays7Stats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ReportsByCategoryResult.Result.prototype.hasDays7Stats = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Detail month1_stats = 4;
 * @return {?proto.ReportsByCategoryResult.Detail}
 */
proto.ReportsByCategoryResult.Result.prototype.getMonth1Stats = function() {
  return /** @type{?proto.ReportsByCategoryResult.Detail} */ (
    jspb.Message.getWrapperField(this, proto.ReportsByCategoryResult.Detail, 4));
};


/**
 * @param {?proto.ReportsByCategoryResult.Detail|undefined} value
 * @return {!proto.ReportsByCategoryResult.Result} returns this
*/
proto.ReportsByCategoryResult.Result.prototype.setMonth1Stats = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ReportsByCategoryResult.Result} returns this
 */
proto.ReportsByCategoryResult.Result.prototype.clearMonth1Stats = function() {
  return this.setMonth1Stats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ReportsByCategoryResult.Result.prototype.hasMonth1Stats = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ReportsByCategoryResult.Detail.prototype.toObject = function(opt_includeInstance) {
  return proto.ReportsByCategoryResult.Detail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ReportsByCategoryResult.Detail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportsByCategoryResult.Detail.toObject = function(includeInstance, msg) {
  var f, obj = {
    pending: jspb.Message.getFieldWithDefault(msg, 1, 0),
    progress: jspb.Message.getFieldWithDefault(msg, 2, 0),
    completed: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ReportsByCategoryResult.Detail}
 */
proto.ReportsByCategoryResult.Detail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ReportsByCategoryResult.Detail;
  return proto.ReportsByCategoryResult.Detail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ReportsByCategoryResult.Detail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ReportsByCategoryResult.Detail}
 */
proto.ReportsByCategoryResult.Detail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPending(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProgress(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompleted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ReportsByCategoryResult.Detail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ReportsByCategoryResult.Detail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ReportsByCategoryResult.Detail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportsByCategoryResult.Detail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPending();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getProgress();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCompleted();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 pending = 1;
 * @return {number}
 */
proto.ReportsByCategoryResult.Detail.prototype.getPending = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ReportsByCategoryResult.Detail} returns this
 */
proto.ReportsByCategoryResult.Detail.prototype.setPending = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 progress = 2;
 * @return {number}
 */
proto.ReportsByCategoryResult.Detail.prototype.getProgress = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.ReportsByCategoryResult.Detail} returns this
 */
proto.ReportsByCategoryResult.Detail.prototype.setProgress = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 completed = 3;
 * @return {number}
 */
proto.ReportsByCategoryResult.Detail.prototype.getCompleted = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.ReportsByCategoryResult.Detail} returns this
 */
proto.ReportsByCategoryResult.Detail.prototype.setCompleted = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated Result response = 1;
 * @return {!Array<!proto.ReportsByCategoryResult.Result>}
 */
proto.ReportsByCategoryResult.prototype.getResponseList = function() {
  return /** @type{!Array<!proto.ReportsByCategoryResult.Result>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ReportsByCategoryResult.Result, 1));
};


/**
 * @param {!Array<!proto.ReportsByCategoryResult.Result>} value
 * @return {!proto.ReportsByCategoryResult} returns this
*/
proto.ReportsByCategoryResult.prototype.setResponseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ReportsByCategoryResult.Result=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ReportsByCategoryResult.Result}
 */
proto.ReportsByCategoryResult.prototype.addResponse = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ReportsByCategoryResult.Result, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ReportsByCategoryResult} returns this
 */
proto.ReportsByCategoryResult.prototype.clearResponseList = function() {
  return this.setResponseList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ReportsByCategoryCustomResult.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ReportsByCategoryCustomResult.prototype.toObject = function(opt_includeInstance) {
  return proto.ReportsByCategoryCustomResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ReportsByCategoryCustomResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportsByCategoryCustomResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    responseList: jspb.Message.toObjectList(msg.getResponseList(),
    proto.ReportsByCategoryCustomResult.Result.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ReportsByCategoryCustomResult}
 */
proto.ReportsByCategoryCustomResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ReportsByCategoryCustomResult;
  return proto.ReportsByCategoryCustomResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ReportsByCategoryCustomResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ReportsByCategoryCustomResult}
 */
proto.ReportsByCategoryCustomResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ReportsByCategoryCustomResult.Result;
      reader.readMessage(value,proto.ReportsByCategoryCustomResult.Result.deserializeBinaryFromReader);
      msg.addResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ReportsByCategoryCustomResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ReportsByCategoryCustomResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ReportsByCategoryCustomResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportsByCategoryCustomResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResponseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ReportsByCategoryCustomResult.Result.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ReportsByCategoryCustomResult.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.ReportsByCategoryCustomResult.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ReportsByCategoryCustomResult.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportsByCategoryCustomResult.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    category: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customStats: (f = msg.getCustomStats()) && proto.ReportsByCategoryCustomResult.Detail.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ReportsByCategoryCustomResult.Result}
 */
proto.ReportsByCategoryCustomResult.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ReportsByCategoryCustomResult.Result;
  return proto.ReportsByCategoryCustomResult.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ReportsByCategoryCustomResult.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ReportsByCategoryCustomResult.Result}
 */
proto.ReportsByCategoryCustomResult.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 2:
      var value = new proto.ReportsByCategoryCustomResult.Detail;
      reader.readMessage(value,proto.ReportsByCategoryCustomResult.Detail.deserializeBinaryFromReader);
      msg.setCustomStats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ReportsByCategoryCustomResult.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ReportsByCategoryCustomResult.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ReportsByCategoryCustomResult.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportsByCategoryCustomResult.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomStats();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.ReportsByCategoryCustomResult.Detail.serializeBinaryToWriter
    );
  }
};


/**
 * optional string category = 1;
 * @return {string}
 */
proto.ReportsByCategoryCustomResult.Result.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ReportsByCategoryCustomResult.Result} returns this
 */
proto.ReportsByCategoryCustomResult.Result.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Detail custom_stats = 2;
 * @return {?proto.ReportsByCategoryCustomResult.Detail}
 */
proto.ReportsByCategoryCustomResult.Result.prototype.getCustomStats = function() {
  return /** @type{?proto.ReportsByCategoryCustomResult.Detail} */ (
    jspb.Message.getWrapperField(this, proto.ReportsByCategoryCustomResult.Detail, 2));
};


/**
 * @param {?proto.ReportsByCategoryCustomResult.Detail|undefined} value
 * @return {!proto.ReportsByCategoryCustomResult.Result} returns this
*/
proto.ReportsByCategoryCustomResult.Result.prototype.setCustomStats = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ReportsByCategoryCustomResult.Result} returns this
 */
proto.ReportsByCategoryCustomResult.Result.prototype.clearCustomStats = function() {
  return this.setCustomStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ReportsByCategoryCustomResult.Result.prototype.hasCustomStats = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ReportsByCategoryCustomResult.Detail.prototype.toObject = function(opt_includeInstance) {
  return proto.ReportsByCategoryCustomResult.Detail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ReportsByCategoryCustomResult.Detail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportsByCategoryCustomResult.Detail.toObject = function(includeInstance, msg) {
  var f, obj = {
    pending: jspb.Message.getFieldWithDefault(msg, 1, 0),
    progress: jspb.Message.getFieldWithDefault(msg, 2, 0),
    completed: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ReportsByCategoryCustomResult.Detail}
 */
proto.ReportsByCategoryCustomResult.Detail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ReportsByCategoryCustomResult.Detail;
  return proto.ReportsByCategoryCustomResult.Detail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ReportsByCategoryCustomResult.Detail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ReportsByCategoryCustomResult.Detail}
 */
proto.ReportsByCategoryCustomResult.Detail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPending(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProgress(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompleted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ReportsByCategoryCustomResult.Detail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ReportsByCategoryCustomResult.Detail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ReportsByCategoryCustomResult.Detail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportsByCategoryCustomResult.Detail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPending();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getProgress();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCompleted();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 pending = 1;
 * @return {number}
 */
proto.ReportsByCategoryCustomResult.Detail.prototype.getPending = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ReportsByCategoryCustomResult.Detail} returns this
 */
proto.ReportsByCategoryCustomResult.Detail.prototype.setPending = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 progress = 2;
 * @return {number}
 */
proto.ReportsByCategoryCustomResult.Detail.prototype.getProgress = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.ReportsByCategoryCustomResult.Detail} returns this
 */
proto.ReportsByCategoryCustomResult.Detail.prototype.setProgress = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 completed = 3;
 * @return {number}
 */
proto.ReportsByCategoryCustomResult.Detail.prototype.getCompleted = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.ReportsByCategoryCustomResult.Detail} returns this
 */
proto.ReportsByCategoryCustomResult.Detail.prototype.setCompleted = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated Result response = 1;
 * @return {!Array<!proto.ReportsByCategoryCustomResult.Result>}
 */
proto.ReportsByCategoryCustomResult.prototype.getResponseList = function() {
  return /** @type{!Array<!proto.ReportsByCategoryCustomResult.Result>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ReportsByCategoryCustomResult.Result, 1));
};


/**
 * @param {!Array<!proto.ReportsByCategoryCustomResult.Result>} value
 * @return {!proto.ReportsByCategoryCustomResult} returns this
*/
proto.ReportsByCategoryCustomResult.prototype.setResponseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ReportsByCategoryCustomResult.Result=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ReportsByCategoryCustomResult.Result}
 */
proto.ReportsByCategoryCustomResult.prototype.addResponse = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ReportsByCategoryCustomResult.Result, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ReportsByCategoryCustomResult} returns this
 */
proto.ReportsByCategoryCustomResult.prototype.clearResponseList = function() {
  return this.setResponseList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ReportsResponseTimeResult.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ReportsResponseTimeResult.prototype.toObject = function(opt_includeInstance) {
  return proto.ReportsResponseTimeResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ReportsResponseTimeResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportsResponseTimeResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    responseList: jspb.Message.toObjectList(msg.getResponseList(),
    proto.ReportsResponseTimeResult.Result.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ReportsResponseTimeResult}
 */
proto.ReportsResponseTimeResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ReportsResponseTimeResult;
  return proto.ReportsResponseTimeResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ReportsResponseTimeResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ReportsResponseTimeResult}
 */
proto.ReportsResponseTimeResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ReportsResponseTimeResult.Result;
      reader.readMessage(value,proto.ReportsResponseTimeResult.Result.deserializeBinaryFromReader);
      msg.addResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ReportsResponseTimeResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ReportsResponseTimeResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ReportsResponseTimeResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportsResponseTimeResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResponseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ReportsResponseTimeResult.Result.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ReportsResponseTimeResult.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.ReportsResponseTimeResult.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ReportsResponseTimeResult.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportsResponseTimeResult.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    year: jspb.Message.getFieldWithDefault(msg, 1, 0),
    month: jspb.Message.getFieldWithDefault(msg, 2, 0),
    avgResponseTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ReportsResponseTimeResult.Result}
 */
proto.ReportsResponseTimeResult.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ReportsResponseTimeResult.Result;
  return proto.ReportsResponseTimeResult.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ReportsResponseTimeResult.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ReportsResponseTimeResult.Result}
 */
proto.ReportsResponseTimeResult.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonth(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAvgResponseTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ReportsResponseTimeResult.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ReportsResponseTimeResult.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ReportsResponseTimeResult.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportsResponseTimeResult.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMonth();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAvgResponseTime();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
};


/**
 * optional int32 year = 1;
 * @return {number}
 */
proto.ReportsResponseTimeResult.Result.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ReportsResponseTimeResult.Result} returns this
 */
proto.ReportsResponseTimeResult.Result.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 month = 2;
 * @return {number}
 */
proto.ReportsResponseTimeResult.Result.prototype.getMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.ReportsResponseTimeResult.Result} returns this
 */
proto.ReportsResponseTimeResult.Result.prototype.setMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional float avg_response_time = 3;
 * @return {number}
 */
proto.ReportsResponseTimeResult.Result.prototype.getAvgResponseTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ReportsResponseTimeResult.Result} returns this
 */
proto.ReportsResponseTimeResult.Result.prototype.setAvgResponseTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * repeated Result response = 1;
 * @return {!Array<!proto.ReportsResponseTimeResult.Result>}
 */
proto.ReportsResponseTimeResult.prototype.getResponseList = function() {
  return /** @type{!Array<!proto.ReportsResponseTimeResult.Result>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ReportsResponseTimeResult.Result, 1));
};


/**
 * @param {!Array<!proto.ReportsResponseTimeResult.Result>} value
 * @return {!proto.ReportsResponseTimeResult} returns this
*/
proto.ReportsResponseTimeResult.prototype.setResponseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ReportsResponseTimeResult.Result=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ReportsResponseTimeResult.Result}
 */
proto.ReportsResponseTimeResult.prototype.addResponse = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ReportsResponseTimeResult.Result, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ReportsResponseTimeResult} returns this
 */
proto.ReportsResponseTimeResult.prototype.clearResponseList = function() {
  return this.setResponseList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ReportsUniqueUsersResult.prototype.toObject = function(opt_includeInstance) {
  return proto.ReportsUniqueUsersResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ReportsUniqueUsersResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportsUniqueUsersResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    count: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ReportsUniqueUsersResult}
 */
proto.ReportsUniqueUsersResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ReportsUniqueUsersResult;
  return proto.ReportsUniqueUsersResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ReportsUniqueUsersResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ReportsUniqueUsersResult}
 */
proto.ReportsUniqueUsersResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ReportsUniqueUsersResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ReportsUniqueUsersResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ReportsUniqueUsersResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportsUniqueUsersResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 count = 1;
 * @return {number}
 */
proto.ReportsUniqueUsersResult.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ReportsUniqueUsersResult} returns this
 */
proto.ReportsUniqueUsersResult.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ReportsStatsByUsersResult.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ReportsStatsByUsersResult.prototype.toObject = function(opt_includeInstance) {
  return proto.ReportsStatsByUsersResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ReportsStatsByUsersResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportsStatsByUsersResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    responseList: jspb.Message.toObjectList(msg.getResponseList(),
    proto.ReportsStatsByUsersResult.Result.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ReportsStatsByUsersResult}
 */
proto.ReportsStatsByUsersResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ReportsStatsByUsersResult;
  return proto.ReportsStatsByUsersResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ReportsStatsByUsersResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ReportsStatsByUsersResult}
 */
proto.ReportsStatsByUsersResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ReportsStatsByUsersResult.Result;
      reader.readMessage(value,proto.ReportsStatsByUsersResult.Result.deserializeBinaryFromReader);
      msg.addResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ReportsStatsByUsersResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ReportsStatsByUsersResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ReportsStatsByUsersResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportsStatsByUsersResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResponseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ReportsStatsByUsersResult.Result.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ReportsStatsByUsersResult.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.ReportsStatsByUsersResult.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ReportsStatsByUsersResult.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportsStatsByUsersResult.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reportsPending: jspb.Message.getFieldWithDefault(msg, 2, 0),
    reportsInProgress: jspb.Message.getFieldWithDefault(msg, 3, 0),
    reportsCompleted: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ReportsStatsByUsersResult.Result}
 */
proto.ReportsStatsByUsersResult.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ReportsStatsByUsersResult.Result;
  return proto.ReportsStatsByUsersResult.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ReportsStatsByUsersResult.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ReportsStatsByUsersResult.Result}
 */
proto.ReportsStatsByUsersResult.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReportsPending(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReportsInProgress(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReportsCompleted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ReportsStatsByUsersResult.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ReportsStatsByUsersResult.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ReportsStatsByUsersResult.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReportsStatsByUsersResult.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReportsPending();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getReportsInProgress();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getReportsCompleted();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string userId = 1;
 * @return {string}
 */
proto.ReportsStatsByUsersResult.Result.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ReportsStatsByUsersResult.Result} returns this
 */
proto.ReportsStatsByUsersResult.Result.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 reports_pending = 2;
 * @return {number}
 */
proto.ReportsStatsByUsersResult.Result.prototype.getReportsPending = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.ReportsStatsByUsersResult.Result} returns this
 */
proto.ReportsStatsByUsersResult.Result.prototype.setReportsPending = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 reports_in_progress = 3;
 * @return {number}
 */
proto.ReportsStatsByUsersResult.Result.prototype.getReportsInProgress = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.ReportsStatsByUsersResult.Result} returns this
 */
proto.ReportsStatsByUsersResult.Result.prototype.setReportsInProgress = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 reports_completed = 4;
 * @return {number}
 */
proto.ReportsStatsByUsersResult.Result.prototype.getReportsCompleted = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.ReportsStatsByUsersResult.Result} returns this
 */
proto.ReportsStatsByUsersResult.Result.prototype.setReportsCompleted = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated Result response = 1;
 * @return {!Array<!proto.ReportsStatsByUsersResult.Result>}
 */
proto.ReportsStatsByUsersResult.prototype.getResponseList = function() {
  return /** @type{!Array<!proto.ReportsStatsByUsersResult.Result>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ReportsStatsByUsersResult.Result, 1));
};


/**
 * @param {!Array<!proto.ReportsStatsByUsersResult.Result>} value
 * @return {!proto.ReportsStatsByUsersResult} returns this
*/
proto.ReportsStatsByUsersResult.prototype.setResponseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ReportsStatsByUsersResult.Result=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ReportsStatsByUsersResult.Result}
 */
proto.ReportsStatsByUsersResult.prototype.addResponse = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ReportsStatsByUsersResult.Result, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ReportsStatsByUsersResult} returns this
 */
proto.ReportsStatsByUsersResult.prototype.clearResponseList = function() {
  return this.setResponseList([]);
};


goog.object.extend(exports, proto);
