import { AuthServiceClient } from "./../../server/user/protos/AuthServiceClientPb";
import {
  LoginRequest,
  LoginAccess,
  RefreshTokenMessage,
  SendResetPwdMessage,
  FetchRolesRequest,
  Role,
  LoginResponse,
  EmailInUseMessage,
} from "../../server/user/protos/auth_pb";
import store from "../Store";
import jwtDecode from "jwt-decode"; // you must install jwt-decode using npm
import {
  IdMessage,
  RegisterPasswordMessage,
  SuccessResponse,
  User,
} from "../../server/user/protos/user_pb";
import { userClient } from "../users/usersAction";
import { SET_USER } from "./userTypes";
import { BACKEND_URL } from "../RootReducer";
import { Metadata } from "grpc-web";

export const authClient = new AuthServiceClient(BACKEND_URL || "");
export const authHeaders: Metadata = {
  // service_name: "LicenseService",
  "Access-Control-Allow-Origin": "*",
  // crossdomain: true,
  allow_headers: "*",
  // "origin": "http://localhost:3000",
};
export function grpcLogin(
  data: { email: string; password: string },
  fun?: any,
  errFun?: any
) {
  return (dispatch: any) => {
    const reqLogin = new LoginRequest();
    reqLogin.setEmail(data.email);
    reqLogin.setLoginAccess(LoginAccess.ADMIN_ACCESS);
    reqLogin.setPassword(data.password);
    authClient.doLogin(
      reqLogin,
      authHeaders,
      (err, response: LoginResponse) => {
        if (err) {
          if (errFun) {
            errFun();
          }
        }
        if (response == null) {
          console.log("err", err);
        } else {
          if (fun) {
            fun(response.toObject());
          }
        }
      }
    );
  };
}

export function grpcRegisterPassword(
  data: { userId: string; passwordToken: string; password: string },
  fun?: any,
  errFun?: any
) {
  return (dispatch: any) => {
    const req = new RegisterPasswordMessage();
    req.setUserid(data.userId);
    req.setPasswordToken(data.passwordToken);
    req.setPassword(data.password);

    userClient.registerPassword(
      req,
      authHeaders,
      (err, response: SuccessResponse) => {
        if (err) {
          if (errFun) {
            errFun();
          }
        }
        if (response == null) {
          console.log("err", err);
        } else {
          if (fun) {
            fun(response.toObject());
          }
        }
      }
    );
  };
}

export function grpcFetchUserById(id: string, fun?: any, errFun?: any) {
  return (dispatch: any) => {
    const reqId = new IdMessage();
    reqId.setId(id);
    userClient.fetchUserById(reqId, authHeaders, (err, response: User) => {
      if (err) {
        if (errFun) {
          errFun();
        }
      }
      if (response == null) {
        console.log("err", err);
      } else {
        dispatch({
          type: SET_USER,
          payload: response.toObject(),
        });
        if (fun) {
          fun(response.toObject());
        }
      }
    });
  };
}

export function grpcSendResetPwdEmail(
  data: {
    email: string;
    language: string;
  },
  fun?: any,
  errFun?: any
) {
  return (dispatch: any) => {
    const reqPwd = new SendResetPwdMessage();
    reqPwd.setEmail(data.email);
    reqPwd.setLanguage(data.language);
    authClient.sendResetPwdEmail(
      reqPwd,
      authHeaders,
      (err, response: SuccessResponse) => {
        if (err) {
          if (errFun) {
            errFun();
          }
        }
        if (response == null) {
          console.log("err", err);
        } else {
          if (fun) {
            fun(response.toObject());
          }
        }
      }
    );
  };
}

export function grpcRefreshToken(data: any, fun?: any) {
  return (dispatch: any) => {
    const reqRefreshToken = new RefreshTokenMessage();
    reqRefreshToken.setRefreshToken("");
    authClient.refreshToken(reqRefreshToken, authHeaders, (err, response) => {
      if (response == null) {
        console.log("err", err);
      } else {
        if (fun) {
          fun();
        }
      }
    });
  };
}

export function grpcCreateRole(data: any, fun?: any) {
  return (dispatch: any) => {
    const reqRole = new Role();
    reqRole.setRoleid("");
    reqRole.setName("");
    reqRole.setPermissionsList([]);
    reqRole.setLoginaccessList([]);
    authClient.createRole(reqRole, authHeaders, (err, response) => {
      if (response == null) {
        console.log("err", err);
      } else {
        if (fun) {
          fun();
        }
      }
    });
  };
}

export function grpcUpdateRole(data: any, fun?: any) {
  return (dispatch: any) => {
    const reqRole = new Role();
    reqRole.setRoleid("");
    reqRole.setName("");
    reqRole.setPermissionsList([]);
    reqRole.setLoginaccessList([]);
    authClient.createRole(reqRole, authHeaders, (err, response) => {
      if (response == null) {
        console.log("err", err);
      } else {
        if (fun) {
          fun();
        }
      }
    });
  };
}

export function grpcCheckEmailInUse(data: any, fun?: any) {
  return (dispatch: any) => {
    const reqCEIUse = new EmailInUseMessage();
    reqCEIUse.setEmail(data.email);
    authClient.checkEmailInUse(reqCEIUse, authHeaders, (err, response) => {
      if (response == null) {
        console.log("err", err);
      } else {
        if (fun) {
          fun(response.toObject());
        }
      }
    });
  };
}

// export const CheckAuthentication = () => {
//   // return (dispatch: any) => {
//   const authToken = localStorage.token;
//   // if (authToken) {
//   const decodedToken: any = jwtDecode(authToken);
//   console.log("decodedToken", decodedToken);
//   //   if (decodedToken.exp * 1000 < Date.now()) {
//   //     logoutUser();
//   //     store.dispatch({
//   //       type: SET_UNAUTHENTICATED,
//   //     });
//   //     store.dispatch({
//   //       type: SET_SUPER_ADMIN,
//   //       payload: false,
//   //     });
//   //     localStorage.setItem("logoutEvent", "logout" + Math.random());
//   //   } else {
//   //     if (localStorage.role === ROLE.SUPER_ADMIN) {
//   //       console.log("SUPER_CAMPAIGNS");
//   //       // window.location.href = SUPER_CAMPAIGNS;
//   //     } else {
//   //       console.log("DASHBOARD");
//   //       // window.location.href = PRIVATE_DASHBOARD;
//   //     }
//   //     axios.defaults.headers.common["Authorization"] = authToken;
//   //     axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
//   //     let fun = getUserData;
//   //     if (localStorage.accessMode && localStorage.accessMode === "ACTIVE") {
//   //       fun = getUserDataByCampaign;
//   //     }
//   //     fun(
//   //       decodedToken._id,
//   //       (value: any) => {
//   //         const deco: DecodedToken = value.data.response;
//   //         console.log(value.data.response);
//   //         if (deco.role !== ROLE.CAPTURIST) {
//   //           localStorage.setItem("id", deco._id);
//   //           localStorage.setItem("role", deco.role);
//   //           localStorage.setItem(
//   //             "level",
//   //             deco.level ? deco.level.toString() : ""
//   //           );
//   //           localStorage.setItem("teamName", deco.teamName);
//   //           localStorage.setItem("focus", deco.focus);
//   //           localStorage.setItem("campaignId", deco.campaignId);
//   //           localStorage.setItem("team", JSON.stringify(deco.team));
//   //           if (
//   //             localStorage.accessMode &&
//   //             localStorage.accessMode === "ACTIVE"
//   //           ) {
//   //             store.dispatch({
//   //               type: SET_AUTHENTICATED,
//   //               superAdmin: false,
//   //             });
//   //           } else {
//   //             store.dispatch({
//   //               type: SET_AUTHENTICATED,
//   //               superAdmin:
//   //                 localStorage.role === ROLE.SUPER_ADMIN ? true : false,
//   //             });
//   //           }
//   //           let stateSelected: statesLogin[] = [];
//   //           let citySelected: citiesLogin[] = [];
//   //           try {
//   //             stateSelected = deco.states;
//   //             citySelected = deco.cities;
//   //           } catch (error) {
//   //             stateSelected = [];
//   //             citySelected = [];
//   //             // logoutUser();
//   //             // store.dispatch({
//   //             //   type: SET_UNAUTHENTICATED,
//   //             // });
//   //           }
//   //           store.dispatch({
//   //             type: SET_GLOBAL,
//   //             state: stateSelected,
//   //             municipality: citySelected,
//   //             userData: deco,
//   //           });
//   //           store.dispatch({
//   //             type: SET_GLOBAL_SELECTED,
//   //             state: stateSelected[0],
//   //             municipality: citySelected[0],
//   //           });
//   //         } else {
//   //           logoutUser();
//   //           store.dispatch({
//   //             type: SET_UNAUTHENTICATED,
//   //           });
//   //           store.dispatch({
//   //             type: SET_SUPER_ADMIN,
//   //             payload: false,
//   //           });
//   //           localStorage.setItem("logoutEvent", "logout" + Math.random());
//   //         }
//   //       },
//   //       () => {
//   //         logoutUser();
//   //         store.dispatch({
//   //           type: SET_UNAUTHENTICATED,
//   //         });
//   //         store.dispatch({
//   //           type: SET_SUPER_ADMIN,
//   //           payload: false,
//   //         });
//   //         localStorage.setItem("logoutEvent", "logout" + Math.random());
//   //       }
//   //     );
//   //   }
//   // }
//   // };
// };
