import React, { useState, useEffect, Fragment } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getLanguageS, logout } from "../firebase/auth";
import { RootStore } from "../redux/Store";
import { authAWS } from "../firebase";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import moment from "moment";
import "./style.scss";

import {
  LICENSES_ERROR,
  LICENSE_UPDATES,
  SET_INFO_STATES,
} from "../redux/licenses/licensesTypes";
import {
  grpcFeedBack,
  grpcFetchLicenses,
  grpcGetFeedbackRate,
  licenseClient,
  LicenseHeaders,
} from "../redux/licenses/licensesActions";
import {
  grpcFetchReports,
  grpcFetchReportTypes,
  reportClient,
  ReportHeaders,
} from "../redux/reportTypes/reportsActions";
import {
  grpcFetchRoles,
  grpcFetchUsers,
  grpcFetchUsersBlocked,
  grpcFetchUserSettings,
} from "../redux/users/usersAction";
import { grpcFetchActiveDates } from "../redux/tracking/trackingActions";
import { grpcFetchTeams } from "../redux/teams/teamsActions";
import {
  grpcReportsByStatus,
  grpcReportsByDate,
  grpcReportsByCategory,
  grpcReportsByResponse,
  grpcReportsByCategoryCustom,
} from "../redux/dashboard/dashboardActions";

import withAuthorization from "../session/withAuthorization";
import NavBarSuperComponent from "../components/navbar-component/navbar-super-component";
import NavBarSuper from "../components/navbar-super-component/index";
import TopBar from "../components/topbar-component/topbar-component";
import ProfileDialogSuperAdmin from "../views/dialogs/profile-superadmin-dialog";
import LoadingDialog from "../views/dialogs/loading-dialog";
import SimpleModal from "../utils/dialogs/simple-dialog";
import SettingsPage from "../views/settings-page/index";
import WorkerPage from "../views/worker-page/index";
import Calendar from "../views/calendar-page/index";
import RoutesMap from "../views/routes-page/index";
import Reports from "../views/reports-page/index";
import TeamsPage from "../views/teams-page/index";
import Profile from "../views/profile-page/index";
import UsersPage from "../views/users-page/index";
import Home from "../views/dashboard-page/index";
import {
  ReportStatus,
  ReportWorkStatus,
  Report,
} from "../server/report/protos/report_pb";
import {
  REPORTS_FILTER_LIST,
  REPORTS_UPDATES,
} from "../redux/reportTypes/reportsTypes";
import { licenseI } from "../redux/licenses/licensesReducer";
import NavBar from "../components/navbar-component/navbar-component";
import { User } from "../server/user/protos/user_pb";
import {
  FetchFeedbackRequest,
  FetchType,
  License,
} from "../server/license/protos/license_pb";
import {
  grpcFetchPendingRooms,
} from "../redux/chat/chatAction";
import Footer from "../components/footer/index";
import { CheckAuthentication } from "../session/CheckAuthentication";
import { useWebSocketConnection } from "../websockets/hooks/useWebSocketConnection";
import { ChatRequest, MessageType, MessageFlag, TimestampChat } from "../websockets/models/chat.model";
import { Channels, RequestMessage } from "../websockets/websockets.model";
import { ChatMessage } from "../server/chat/protos/chat_pb";
import { dateToTimestamp } from "../utils/convertsDate";
import { NEW_CHAT_MESSAGE } from "../redux/chat/chatTypes";
import { LicenseRequest } from "../websockets/models/license.model";
import { ListenReportType, ReportRequest, ReportResponse, WorkerLogStatus } from "../websockets/models/report.model";
import { UtilitiesWS } from "../websockets/utilidades";
import { TEAMS_UPDATES } from "../redux/teams/teamsTypes";
import { Team } from "../server/team/protos/team_pb";
import { TeamResponse } from "../websockets/models/team.model";
import { NOTIFICATIONS_UPDATES } from "../redux/notifications/notificationsTypes";


interface RoutesSuperAdminProps {
  history: any;
  location: any;
  authUser: any;
  setDistrict: any;
  loadingDistrict: any;
  listOfDistricts: any;
  selectedLicense: any;
  adminsOfaDistrict: any;
  reports: any;
  teams: any;
  trackers: any;
  activeDates: any;
  workers: any;
  statistics: any;
  statisticsOld: any;
  guests: any;
  updateDistrict: any;
  users: any;
  fetchUsers: any;
  socket: any;
  changeLanguage: any;
  reportTypes: any;
  t: any;
  language: any;
  fetchAllDistricts: any;
  superData: any;
  handleDeletedDistrict: any;
  actualPageProfile: any;
  updatePageProfile: any;
  fetchSettings: any;
  trackersYesterday: any;
  kanbanKeys: any;
  changeSelectedTeam: any;
  selectedTeam: any;
  countStatistics: any;
  changeFilter: any;
  filtersSelected: any;
  changeShowTime: any;
  showTime: any;
}

export const RoutesSuperAdmin: React.FunctionComponent<RoutesSuperAdminProps> =
  ({
    history,
    location,
    authUser,
    teams,
    trackers,
    workers,
    statistics,
    guests,
    updateDistrict,
    users,
    socket,
    changeLanguage,
    reportTypes,
    t,
    language,
    superData,
    updatePageProfile,
    trackersYesterday,
    kanbanKeys,
    changeSelectedTeam,
    selectedTeam,
    changeFilter,
    filtersSelected,
    showTime,
  }: any) => {
    const { 
      subscribeToChannel, 
      getMessagesByChannel, 
      readyState, 
      unsubscribeFromChannel,
    } = useWebSocketConnection();
  
    useEffect(() => {
      const lastMessage = getMessagesByChannel(Channels.ListenChatMessages);
      if (lastMessage) {
        const chatMessageProto = UtilitiesWS.assignDataChat(lastMessage);
        dispatch({
          type: NEW_CHAT_MESSAGE,
          payload: chatMessageProto as ChatMessage.AsObject,
        });
      }
    }, [getMessagesByChannel(Channels.ListenChatMessages)]);

    useEffect(() => {
      const lastMessage = getMessagesByChannel(Channels.ListenLicenseUpdates);
      if (lastMessage) {
        const licenseProto = UtilitiesWS.assignDataLisence(lastMessage);
        dispatch({
          type: LICENSE_UPDATES,
          payload: licenseProto as License.AsObject,
        });

        if (lastMessage.blocked || lastMessage.deleted) {
          doSignOut();
        }
      }
    }, [getMessagesByChannel(Channels.ListenLicenseUpdates)]);

    useEffect(() => {
      const lastMessage = getMessagesByChannel(Channels.ListenReportsMessage) as ReportResponse;
      if (lastMessage) {
        const reportProto = UtilitiesWS.assignDataReport(lastMessage);
        dispatch({
          type: REPORTS_UPDATES,
          payload: (reportProto),
        });
      }
    }, [getMessagesByChannel(Channels.ListenReportsMessage)]);

    useEffect(() => {
      const lastMessage = getMessagesByChannel(Channels.ListenTeam) as TeamResponse;
      if (lastMessage) {
        const teamProto = UtilitiesWS.assignDataTeam(lastMessage);
        dispatch({
          type: TEAMS_UPDATES,
          payload: (teamProto),
        });
      }
    }, [getMessagesByChannel(Channels.ListenTeam)]);

    useEffect(() => {
      const lastMessage = getMessagesByChannel(Channels.ListenNotifications);
      if (lastMessage) {
        const notificationProto = UtilitiesWS.assignDataNotification(lastMessage);
        dispatch({
          type: NOTIFICATIONS_UPDATES,
          payload: (notificationProto),
        });
      }
    }, [getMessagesByChannel(Channels.ListenNotifications)]);

    const superAdmin = useSelector(
      (globalState: RootStore) => globalState.auth.superAdmin
    );
    const user: User.AsObject | undefined = useSelector(
      (globalState: RootStore) => globalState.auth.user
    );
    const loading = useSelector(
      (globalState: RootStore) => globalState.licenses.loading
    );
    const loadingLicense = useSelector(
      (globalState: RootStore) => globalState.licenses.loadingLicense
    );
    const selectedLicense: licenseI | undefined = useSelector(
      (globalState: RootStore) => globalState.licenses.selectedLicense
    );
    const offset = useSelector(
      (globalState: RootStore) => globalState.licenses.offset
    );
    const limit = useSelector(
      (globalState: RootStore) => globalState.licenses.limit
    );

    const viewFilters: {
      pending: boolean;
      assigned: boolean;
      progress: boolean;
      completed: boolean;
      problem: boolean;
      paused: boolean;
    } = useSelector(
      (globalState: RootStore) => globalState.reportTypes.viewFilters
    );

    const reportsR: Report.AsObject[] = useSelector(
      (globalState: RootStore) => globalState.reportTypes.reports
    );

    const dateD: Array<Date> = useSelector(
      (globalState: RootStore) => globalState.dashboard.dateDate
    );
    const categoryD: Array<Date> = useSelector(
      (globalState: RootStore) => globalState.dashboard.categoryDate
    );
    const responseD: Array<Date> = useSelector(
      (globalState: RootStore) => globalState.dashboard.responseDate
    );

    const dispatch = useDispatch();

    let defaultValue: any;
    let defaultValueArray: any = [];
    let defaultCoors: { lat: number; lng: number } = {
      lat: 32.805197,
      lng: -117.010139,
    };
    const [actualLicense, setActualLicense] = useState(
      selectedLicense?._id ? selectedLicense?._id : defaultValue
    );

    const [state, setState] = useState({
      Coords: {
        lat: 32.805197,
        lng: -117.010139,
      },
      CoordsReport: {
        lat: 32.805197,
        lng: -117.010139,
      },
      CoordsRoute: {
        lat: 32.805197,
        lng: -117.010139,
      },
      CoordsUpdated: defaultValue,
      CoordsUpdatedReport: defaultValue,
      CoordsUpdatedRoute: defaultValue,
      currentZoom: 13,
      currentZoomReport: 13,
      currentZoomRoute: 13,
      profileVisible: false,
      toUpdateDistrict: defaultValue,
      updatedZoom: defaultValue,
      updatedZoomReport: defaultValue,
      updatedZoomRoute: defaultValue,
      selectedTeam: defaultValue,
      defaultFilter:
        "?pending=true&assigned=false&progress=false&completed=false&problem=false&delayed=false",
      selectedLicense: selectedLicense,
      generalSettings: false,
      visibleChat: false,
      activeChat: defaultValue,
      updateDistrict: false,
      tempChats: defaultValueArray,
      menuOpen: true,
    });

    /* ///For Chat!!
    const hideChat = () => {
      setState((prevState) => ({
        ...prevState,
        visibleChat: !state.visibleChat,
      }));
    }; */

    /// TODO: is missing the look if there is a chat already created that contains the same receptors.
    const createChat = (array: any) => {
      let chat = {
        _id: defaultValue,
        type: "normal",
        created: moment().toISOString(),
        deleted: "String",
        receptors: [authAWS.getId(), "5ece8510e01b2f25d63529c4"],
        creatorId: "String",
        tempId: Math.random().toString(36).substring(7),
        districtId: selectedLicense?._id,
      };
      let tempChats = state.tempChats;
      tempChats.push(chat);
      setState((prevState) => ({
        ...prevState,
        activeChat: chat,
        tempChats: tempChats,
      }));
    };

    /* const goToChat = (chat: any) => {
      setState((prevState) => ({
        ...prevState,
        activeChat: chat,
      }));
    };

    const setTempAndCurrentChats = (data: any, array: any, fun: any) => {
      setState((prevState) => ({
        ...prevState,
        activeChat: data,
        tempChats: array,
      })),
        fun();
    }; */

    const updateFilter = (filter: any) => {
      setState((prevState) => ({
        ...prevState,
        defaultFilter: filter,
      }));
    };

    const updateCoords = (coords: any) => {
      setState((prevState) => ({
        ...prevState,
        Coords: {
          lat: coords.latitude,
          lng: coords.longitude,
        },
        CoordsUpdated: {
          lat: coords.latitude,
          lng: coords.longitude,
        },
      }));
    };

    const updateCoordsReport = (coords: any) => {
      setState((prevState) => ({
        ...prevState,
        CoordsReport: {
          lat: coords.latitude,
          lng: coords.longitude,
        },
        CoordsUpdatedReport: {
          lat: coords.latitude,
          lng: coords.longitude,
        },
      }));
    };

    const updateCoordsRoute = (coords: any) => {
      setState((prevState) => ({
        ...prevState,
        CoordsRoute: {
          lat: coords.latitude,
          lng: coords.longitude,
        },
        CoordsUpdatedRoute: {
          lat: coords.latitude,
          lng: coords.longitude,
        },
      }));
    };

    const changeGeneralSettings = () => {
      setState((prevState) => ({
        ...prevState,
        generalSettings: !state.generalSettings,
      }));
    };

    useEffect(() => {
      executeCalculation();
    }, [viewFilters]);

    const executeCalculation = () => {
      let reportsList = defaultValue;
      if (
        viewFilters.pending ||
        viewFilters.assigned ||
        viewFilters.progress ||
        viewFilters.completed
      )
        reportsList = reportsR.filter(
          (e: Report.AsObject) =>
            (viewFilters.pending &&
              e.reportstatus === ReportStatus.REPORT_PENDING) ||
            (viewFilters.assigned &&
              e.reportstatus === ReportStatus.REPORT_IN_PROGRESS &&
              e.workstatus === ReportWorkStatus.WORK_ASSIGNED) ||
            (viewFilters.progress &&
              e.reportstatus === ReportStatus.REPORT_IN_PROGRESS &&
              e.workstatus === ReportWorkStatus.WORK_IN_PROGRESS) ||
            (viewFilters.completed &&
              e.reportstatus === ReportStatus.REPORT_COMPLETED)
        );
      else reportsList = reportsR;

      let valueCurrent = true;
      if (
        state.selectedLicense &&
        selectedLicense &&
        state.selectedLicense._id !== selectedLicense._id
      ) {
        valueCurrent = false;
        setState((prevState) => ({
          ...prevState,
          selectedLicense: selectedLicense,
        }));
      }
      if (state.updateDistrict) {
        setState((prevState) => ({
          ...prevState,
          selectedLicense: selectedLicense,
          updateDistrict: false,
        }));
        valueCurrent = true;
      }

      if (reportsList.length > 0) {
        let lngs: any = [];
        let lats: any = [];
        reportsList.forEach((element: Report.AsObject) => {
          if (element.geopoint) {
            lngs.push(element.geopoint.longitude);
            lats.push(element.geopoint.latitude);
          }
        });

        let topMost = Math.max(...lngs);
        let leftMost = Math.min(...lats);
        let rightMost = Math.max(...lats);
        let bottomMost = Math.min(...lngs);

        let lat = (leftMost + rightMost) / 2;
        let lng = (topMost + bottomMost) / 2;
        let km = calcCrow(rightMost, topMost, leftMost, bottomMost);
        let zoom = 16;

        // if (km <= 1128.49722) {
        //   zoom = 20;
        // } else if (km <= 2256.99444) {
        //   zoom = 19;
        // } else
        // if (km <= 1128.49722 || km <= 2256.99444 || km <= 4513.98888) {
        //   zoom = 18;
        // } else
        if (
          // km <= 1128.49722 ||
          // km <= 2256.99444 ||
          // km <= 4513.98888 ||
          // km <= 9027.977761 ||
          km <= 10027.977761
        ) {
          zoom = 17;
        } else if (km <= 18055.95552) {
          zoom = 16;
        } else if (km <= 36111.91104) {
          zoom = 15;
        } else if (km <= 72223.82209) {
          zoom = 14;
        } else if (km <= 144447.6442) {
          zoom = 13;
        } else if (km <= 288895.2884) {
          zoom = 12;
        } else if (km <= 577790.5767) {
          zoom = 11;
        } else if (km <= 1155581.153) {
          zoom = 10;
        } else if (km <= 2311162.307) {
          zoom = 9;
        } else if (km <= 4622324.614) {
          zoom = 8;
        } else if (km <= 9244649.227) {
          zoom = 7;
        } else if (km <= 18489298.45) {
          zoom = 6;
        } else if (km <= 36978596.91) {
          zoom = 5;
        } else if (km <= 73957193.82) {
          zoom = 4;
        } else if (km <= 147914387.6) {
          zoom = 3;
        } else if (km <= 295828775.3) {
          zoom = 2;
        } else if (km <= 591657550.5) {
          zoom = 1;
        }

        if (selectedLicense?.licenseSettings.zoom) {
          if (zoom !== selectedLicense.licenseSettings.zoom) {
            zoom = selectedLicense.licenseSettings.zoom;
          }
        }

        let coords = {
          lat: lat,
          lng: lng,
        };
        // console.log("updated ", state);
        // if (
        //   selectedLicense?.licenseSettings.coords &&
        //   selectedLicense.licenseSettings.coords.lat !== 0 &&
        //   selectedLicense.licenseSettings.coords.lng !== 0
        // ) {
        //   coords = selectedLicense.licenseSettings.coords;
        // }

        if (valueCurrent)
          setState((prevState) => ({
            ...prevState,
            Coords: !state.CoordsUpdated ? coords : state.CoordsUpdated,
            CoordsReport: !state.CoordsUpdatedReport
              ? coords
              : state.CoordsUpdatedReport,
            CoordsRoute: !state.CoordsUpdatedRoute
              ? coords
              : state.CoordsUpdatedRoute,
            currentZoom: !state.updatedZoom ? zoom : state.updatedZoom,
            currentZoomReport: !state.updatedZoomReport
              ? zoom
              : state.updatedZoomReport,
            currentZoomRoute: !state.updatedZoomRoute
              ? zoom
              : state.updatedZoomRoute,
          }));
        else {
          setState((prevState) => ({
            ...prevState,
            Coords: coords,
            CoordsReport: coords,
            CoordsRoute: coords,
            currentZoom: zoom,
            currentZoomReport: zoom,
            currentZoomRoute: zoom,
            updatedZoom: defaultValue,
            updatedZoomReport: defaultValue,
            updatedZoomRoute: defaultValue,
            CoordsUpdated: defaultValue,
            CoordsUpdatedReport: defaultValue,
            CoordsUpdatedRoute: defaultValue,
          }));
        }
      } else {
        if (selectedLicense?.licenseSettings.zoom) {
          if (valueCurrent)
            setState((prevState) => ({
              ...prevState,
              currentZoom: state.updatedZoom
                ? selectedLicense.licenseSettings.zoom
                : state.updatedZoom,
              currentZoomReport: state.updatedZoomReport
                ? selectedLicense.licenseSettings.zoom
                : state.updatedZoomReport,
              currentZoomRoute: state.updatedZoomRoute
                ? selectedLicense.licenseSettings.zoom
                : state.updatedZoomRoute,
            }));
          else {
            if (selectedLicense.licenseSettings.zoom) {
              const zoom1 = selectedLicense.licenseSettings.zoom;
              setState((prevState) => ({
                ...prevState,
                currentZoom: zoom1,
                currentZoomReport: zoom1,
                currentZoomRoute: zoom1,
                updatedZoom: defaultValue,
                updatedZoomReport: defaultValue,
                updatedZoomRoute: defaultValue,
              }));
            }
          }
        }
        if (
          selectedLicense?.licenseSettings.coords &&
          selectedLicense.licenseSettings.coords.lat !== 0 &&
          selectedLicense.licenseSettings.coords.lng !== 0
        ) {
          if (valueCurrent)
            setState((prevState) => ({
              ...prevState,
              Coords: state.CoordsUpdated
                ? selectedLicense.licenseSettings.coords
                : state.CoordsUpdated,
              CoordsReport: state.CoordsUpdatedReport
                ? selectedLicense.licenseSettings.coords
                : state.CoordsUpdatedReport,
              CoordsRoute: state.CoordsUpdatedRoute
                ? selectedLicense.licenseSettings.coords
                : state.CoordsUpdatedRoute,
            }));
          else {
            setState((prevState) => ({
              ...prevState,
              Coords: selectedLicense.licenseSettings.coords,
              CoordsReport: selectedLicense.licenseSettings.coords,
              CoordsRoute: selectedLicense.licenseSettings.coords,
              CoordsUpdated: defaultValue,
              CoordsUpdatedReport: defaultValue,
              CoordsUpdatedRoute: defaultValue,
            }));
          }
        }
      }
    };

    const calcCrow = (lat1: any, lon1: any, lat2: any, lon2: any) => {
      let R = 6371; // km
      let dLat = toRad(lat2 - lat1);
      let dLon = toRad(lon2 - lon1);
      lat1 = toRad(lat1);
      lat2 = toRad(lat2);

      let a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) *
          Math.sin(dLon / 2) *
          Math.cos(lat1) *
          Math.cos(lat2);
      let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      let d = R * c;
      return d * 10000;
    };

    // Converts numeric degrees to radians
    const toRad = (value: any) => {
      return (value * Math.PI) / 180;
    };

    const setCenter = (e: Report.AsObject) => {
      // console.log(e);
      if (state.currentZoom <= 17)
        if (e.geopoint) {
          const lat = e.geopoint.latitude;
          const lng = e.geopoint.longitude;
          setState((prevState) => ({
            ...prevState,
            Coords: {
              lat: lat,
              lng: lng - state.currentZoom * 0.00015,
            },
            CoordsReport: {
              lat: lat,
              lng: lng - state.currentZoomReport * 0.00015,
            },
            CoordsRoute: {
              lat: lat,
              lng: lng - state.currentZoomRoute * 0.00015,
            },
          }));
        }
    };

    const currentZoom = (e: any) => {
      console.log("updated currentZoom", e);
      setState((prevState) => ({
        ...prevState,
        currentZoom: e,
        updatedZoom: e,
      }));
    };

    const changeDistrict = () => {
      setState((prevState) => ({
        ...prevState,
        updateDistrict: true,
      }));
    };

    const currentZoomReport = (e: any) => {
      setState((prevState) => ({
        ...prevState,
        currentZoomReport: e,
        updatedZoomReport: e,
      }));
    };

    const currentZoomRoute = (e: any) => {
      setState((prevState) => ({
        ...prevState,
        currentZoomRoute: e,
        updatedZoomRoute: e,
      }));
    };

    const changeMenuState = () => {
      setState((prevState) => ({
        ...prevState,
        menuOpen: !state.menuOpen,
      }));
    };

    const showProfileDialog = () => {
      setState((prevState) => ({
        ...prevState,
        profileVisible: !state.profileVisible,
      }));
    };

    const doSignOut = () => {
      const goHome = () => {
        history.push("/");
      };
      logout(goHome);
      CheckAuthentication();
    };

    //***FETCH ALL INFORMATION***
    //"ANALYTICS"
    const fetchAllAnalytics = (loading: boolean) => {
      //ByDate Chart
      let fromD = new Timestamp();
      fromD.fromDate(dateD[0]);
      let toD = new Timestamp();
      toD.fromDate(dateD[1]);

      //ByCategory Chart
      let fromC = new Timestamp();
      fromC.fromDate(categoryD[0]);
      let toC = new Timestamp();
      toC.fromDate(categoryD[1]);

      //ByResponse Chart
      let fromR = new Timestamp();
      fromR.fromDate(responseD[0]);
      let toR = new Timestamp();
      toR.fromDate(responseD[1]);

      dispatch(
        grpcReportsByStatus({ _id: selectedLicense?._id, loading: loading })
      );
      dispatch(
        grpcReportsByDate({
          _id: selectedLicense?._id,
          from: fromD,
          to: toD,
          loading: loading,
        })
      );
      dispatch(
        grpcReportsByCategory({
          _id: selectedLicense?._id,
          loading: loading,
        })
      );
      dispatch(
        grpcReportsByCategoryCustom({
          _id: selectedLicense?._id,
          from: fromC,
          to: toC,
        })
      );
      dispatch(
        grpcReportsByResponse({
          _id: selectedLicense?._id,
          from: fromR,
          to: toR,
          loading: loading,
        })
      );
    };

    //"LICENSES"
    const fetchAllLicenses = () => {
      if (superAdmin) {
        dispatch(
          grpcFetchLicenses({
            query: defaultValue,
            point: {
              lat: defaultValue,
              lng: defaultValue,
            },
            type: FetchType.BY_NONE,
          })
        );
      } else {
        dispatch(
          grpcFetchLicenses({
            query: user?.licenceId || "",
            point: {
              lat: defaultValue,
              lng: defaultValue,
            },
            type: FetchType.BY_ID,
          })
        );

        grpcFetchLicenseStream({
          licenseId: user?.licenceId || "",
        });
      }
    };

    //"FEEDBACK"
    const fetchAllFeedBack = () => {
      dispatch(
        grpcFeedBack({
          id: selectedLicense?._id || "",
          type: FetchFeedbackRequest.RequestFType.BY_LICENSE_ID,
          limit: limit,
          offset: offset,
        })
      );
      dispatch(grpcGetFeedbackRate(selectedLicense?._id || ""));
    };

    //"TEAMS"
    const fetchAllTeams = () => {
      dispatch(grpcFetchTeams({ _id: selectedLicense?._id }));
      if (selectedLicense){
        const channel = Channels.ListenTeam;
        const request: RequestMessage = {
          data: { _id: selectedLicense?._id } as ChatRequest,
          channel,
        };
        unsubscribeFromChannel(request);
        subscribeToChannel(request);
      }
        // dispatch(grpcListenToTeams({ id: selectedLicense._id }));
    };

    //"ROLES"
    const fetchAllRoles = () => {
      dispatch(grpcFetchRoles([]));
    };

    //"USERS"
    const fetchAllUsers = () => {
      dispatch(grpcFetchUsers({ _id: selectedLicense?._id, role: "" }));
    };

    //"BLOCKED USERS"
    const fetchAllUsersBLocked = () => {
      dispatch(grpcFetchUsersBlocked(selectedLicense?._id));
    };

    //"REPORTTYPES"
    const fetchAllReportTypes = () => {
      if (superAdmin) {
        dispatch(
          grpcFetchReportTypes({
            idList: [],
          })
        );
      } else {
        dispatch(
          grpcFetchReportTypes({
            idList: selectedLicense?.licenseSettings.jobTypes || [],
          })
        );
      }
    };

    //"REPORT"
    const fetchAllReports = () => {
      dispatch(
        grpcFetchReports({
          reportIdsList: [],
          licenseId: selectedLicense?._id || "",
        })
      );

      grpcFetchReportsStream({
        licenseId: selectedLicense?._id || "",
      });
    };

    //"ACTIVE DATES"
    const fetchAllActiveDates = () => {
      dispatch(
        grpcFetchActiveDates({
          _id: selectedLicense?._id,
        })
      );
    };

    //"GET SETTINGS FILTERS"
    const fetchAllSettingFilters = () => {
      dispatch(
        grpcFetchUserSettings({
          licenseId: selectedLicense?._id || "",
          userId: user?.id || "",
        })
      );
    };

    //"FETCH PENDING ROOMS"
    const fetchPendingRooms = () => {
      dispatch(grpcFetchPendingRooms(selectedLicense?._id || ""));
      const channel = Channels.ListenChatMessages;
      const request: RequestMessage = {
        data: { _id: selectedLicense?._id || "" } as ChatRequest,
        channel,
      };
      unsubscribeFromChannel(request);
      subscribeToChannel(request);
    };

    //"LISTENING OF LICENSE"
    const grpcFetchLicenseStream = ({ licenseId }: { licenseId: string }) => {
      const channel = Channels.ListenLicenseUpdates;
      const request: RequestMessage = {
        data: { _id: licenseId } as LicenseRequest,
        channel,
      };
      unsubscribeFromChannel(request);
      subscribeToChannel(request);
    };

    //"LISTENING OF REPORTS"
    const grpcFetchReportsStream = (data: { licenseId: string }, fun?: any) => {
      const channel = Channels.ListenReportsMessage;
      const request: RequestMessage = {
        data: { _id: data.licenseId, _ids_report_type: [], listen_type: ListenReportType.LICENSE } as ReportRequest,
        channel,
      };
      unsubscribeFromChannel(request);
      subscribeToChannel(request);
    };

    //***CALL INITIAL DATA***
    useEffect(() => {
      if (getLanguageS() !== undefined) changeLanguage(getLanguageS());

      if(readyState === WebSocket.OPEN) {
        fetchAllLicenses();
      }
    }, [user, readyState]);

    //***CALL DATA DEPENDING ON LICENSE***
    useEffect(() => {
      if (selectedLicense?._id !== actualLicense && readyState === WebSocket.OPEN) {
        setActualLicense(selectedLicense?._id);
        fetchAllReportTypes();
        fetchAllAnalytics(true);
        fetchAllFeedBack();
        fetchAllUsers();
        fetchAllUsersBLocked();
        fetchAllTeams();
        fetchAllReports();
        fetchAllRoles();
        fetchAllActiveDates();
        fetchPendingRooms();
        fetchAllSettingFilters();
        dispatch({ type: LICENSES_ERROR });
        dispatch({
          type: REPORTS_FILTER_LIST,
          payload: [],
        });
        dispatch({
          type: SET_INFO_STATES,
          mainMode: false,
          reportMode: false,
        });
      }
    }, [selectedLicense, readyState]);

    useEffect(() => {
      if (location.pathname !== "/main") {
        dispatch({
          type: SET_INFO_STATES,
          mainMode: false,
          reportMode: false,
        });
      }
    }, [location.pathname]);

    useEffect(() => {
      if (reportsR.length > 0) fetchAllAnalytics(false);
    }, [reportsR]);

    const adminView = () => {
      return loadingLicense ? (
        <SimpleModal>
          <LoadingDialog></LoadingDialog>
        </SimpleModal>
      ) : (
        <div>
          <NavBar typeOfUser={""} />
          <div id="body" className="main-body">
            {bodyView()}
          </div>{" "}
        </div>
      );
    };

    const superAdminView = () => {
      return (
        <>
          {" "}
          <div
            className={
              state.menuOpen
                ? "super-admin-body"
                : "super-admin-body hidded-bar"
            }
          >
            <NavBarSuper
              history={history}
              location={location}
              menuOpen={state.menuOpen}
              changeMenuState={changeMenuState}
              changeGeneralSettings={changeGeneralSettings}
              showProfileDialog={showProfileDialog}
              updatePage={updatePageProfile}
              fetchAllLicenses={() => {
                fetchAllLicenses();
              }}
            ></NavBarSuper>
            {loadingLicense ? (
              <SimpleModal>
                <LoadingDialog></LoadingDialog>
              </SimpleModal>
            ) : location.pathname === "/reportTypes" || !selectedLicense ? (
              <div
                id="body-settings"
                className="main-body"
                style={{
                  width: `calc(100% - ${state.menuOpen ? "250" : "5"}px)`,
                }}
              >
                <TopBar
                  superAdmin={true}
                  reportTypes={reportTypes}
                  history={history}
                  location={location}
                  selectedLicense={selectedLicense}
                  authUserId={authUser.uid}
                  updateDistrict={updateDistrict}
                  workers={workers}
                  guests={guests}
                  users={users}
                  reports={reportsR}
                  executeCalculation={executeCalculation}
                  t={t}
                  changeLanguage={changeLanguage}
                  language={language}
                  superData={superData}
                  filterType={defaultValue}
                  changeFilter={changeFilter}
                />
                <Switch>
                  <Route
                    exact
                    path="/reportTypes"
                    render={(props: any) => (
                      <SettingsPage
                        fetchAllReportTypes={fetchAllReportTypes}
                      ></SettingsPage>
                    )}
                  />
                </Switch>
              </div>
            ) : (
              <div className="super-admin-right">
                <NavBarSuperComponent />
                <div id="body" className="main-body">
                  {bodyView()}
                </div>
              </div>
            )}{" "}
            {state.profileVisible && (
              <SimpleModal
                onCloseRequest={() => {
                  showProfileDialog();
                }}
              >
                <ProfileDialogSuperAdmin
                  fetchAllLicenses={() => {
                    fetchAllLicenses();
                  }}
                  onCloseRequest={() => {
                    showProfileDialog();
                  }}
                ></ProfileDialogSuperAdmin>
              </SimpleModal>
            )}
            {/* <Chat
      t={t}
      messagesChat={messagesChat}
      selectedLicense={selectedLicense}
      createChat={this.createChat}
      hideChat={this.hideChat}
      goToChat={this.goToChat}
      setTempAndCurrentChats={this.setTempAndCurrentChats}
      chats={chats}
      visibleChat={this.state.visibleChat}
      activeChat={this.state.activeChat}
      tempChats={this.state.tempChats}
    ></Chat> */}
          </div>
        </>
      );
    };

    const bodyView = () => {
      return (
        <Fragment>
          <div
            className={
              location.pathname === "/main"
                ? "body-main-container body-total-profile"
                : location.pathname === "/"
                ? "body-main-container body-total-dashboard"
                : location.pathname === "/reports"
                ? "body-main-container body-total-reports"
                : location.pathname === "/routing"
                ? "body-main-container body-total-routing"
                : location.pathname === "/trackerMap"
                ? "body-main-container body-total-tracking"
                : "body-main-container"
            }
          >
            <TopBar
              superAdmin={true}
              reportTypes={reportTypes}
              history={history}
              location={location}
              selectedLicense={selectedLicense}
              authUserId={authUser.uid}
              updateDistrict={updateDistrict}
              workers={workers}
              guests={guests}
              users={users}
              reports={reportsR}
              executeCalculation={executeCalculation}
              t={t}
              changeLanguage={changeLanguage}
              language={language}
              superData={superData}
              filterType={defaultValue}
              changeFilter={changeFilter}
            />
            <Switch>
              {superAdmin && (
                <Route
                  exact
                  path="/main"
                  render={(props: any) => (
                    <Profile
                      fetchAllLicenses={fetchAllLicenses}
                      fetchAllUsers={fetchAllUsers}
                    ></Profile>
                  )}
                />
              )}
              <Route exact path="/" render={(props: any) => <Home />} />
              <Route
                exact
                path="/reports"
                render={(props: any) => (
                  <Reports
                    {...props}
                    reportTypes={reportTypes}
                    defaultFilter={state.defaultFilter}
                    updateFilter={updateFilter}
                    selectedLicense={selectedLicense}
                    reports={reportsR}
                    teams={teams}
                    trackers={trackers}
                    trackersYesterday={trackersYesterday}
                    workers={workers}
                    filterType={defaultValue}
                    updateCoords={updateCoords}
                    executeCalculation={executeCalculation}
                    Coords={state.Coords}
                    currentZoom={state.currentZoom}
                    setCenter={setCenter}
                    updateCurrentZoom={currentZoom}
                    filtersSelected={filtersSelected}
                    showTime={showTime}
                    socket={socket}
                    location={location}
                    history={history}
                    selectedTeam={selectedTeam}
                    menuOpen={state.menuOpen}
                    t={t}
                    subscribeToChannel={subscribeToChannel}
                    unsubscribeFromChannel={unsubscribeFromChannel}
                  />
                )}
              />
              <Route
                exact
                path="/routing"
                render={(props: any) => (
                  <Calendar
                    {...props}
                    reportTypes={reportTypes}
                    teams={teams}
                    kanbanKeys={kanbanKeys}
                    reports={reportsR}
                    changeSelectedTeam={changeSelectedTeam}
                    statistics={statistics}
                    selectedTeam={selectedTeam}
                    Coords={state.CoordsReport}
                    updateCoords={updateCoordsReport}
                    currentZoom={state.currentZoomReport}
                    updateCurrentZoom={currentZoomReport}
                    executeCalculation={executeCalculation}
                    setCenter={setCenter}
                    location={location}
                    t={t}
                  />
                )}
              />
              <Route
                exact
                path="/trackerMap"
                render={(props: any) => (
                  <RoutesMap
                    {...props}
                    executeCalculation={executeCalculation}
                    Coords={state.CoordsRoute}
                    currentZoom={state.currentZoomRoute}
                    setCenter={setCenter}
                    updateCurrentZoom={currentZoomRoute}
                  />
                )}
              />
              <Route
                exact
                path="/workers"
                render={(props: any) => (
                  <WorkerPage fetchAllUsers={fetchAllUsers} />
                )}
              />
              <Route
                exact
                path="/teams"
                render={(props: any) => (
                  <TeamsPage
                    fetchAllTeams={fetchAllTeams}
                    fetchAllUsers={fetchAllUsers}
                  />
                )}
              />
              <Route
                exact
                path="/blocked"
                render={(props: any) => <UsersPage />}
              />
              <Redirect to="/" />
              {/* {superAdmin ?  : <Redirect to="/reports" />} */}
            </Switch>
          </div>
          <Footer t={t}></Footer>
        </Fragment>
      );
    };

    return loading ? (
      <SimpleModal>
        <LoadingDialog></LoadingDialog>
      </SimpleModal>
    ) : superAdmin ? (
      superAdminView()
    ) : (
      adminView()
    );
  };

const authCondition = (authUser: any) => authUser;
export default withAuthorization(authCondition)(RoutesSuperAdmin);
