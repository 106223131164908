import {
  LICENSES_LIST,
  LICENSE_UPDATES,
  SET_FEEDBACK_DATA,
  SET_FEEDBACK_LIST,
  SET_FEEDBACK_RATE,
} from "./licensesTypes";
import {
  //REMOVE JOBETYPE LICENSE
  RemoveJobTypeRequest,
  //BLOCK-DELETE LICENSE
  BlockOrDeleteRequest,
  //CREATE-UPDATE LICENSE
  CreateLicenseRequest,
  UpdateLicenseRequest,
  //CREATE-UPDATE LICENSE SETTINGS
  SettingRequest,
  UpdateJobTypesRequest,
  TrackingSettings,
  LicenseSettings,
  GeoPointL,
  Polygon2D,
  Point2D,
  //FETCH LICENSES
  FetchRequestL,
  License,
  FetchType,
  FetchFeedbackRequest,
  SuccessFeedbackMessage,
  FetchResponseL,
  //LISTEN
  FeedbackRateMessage,
  FeedbackRateResponse,
} from "../../server/license/protos/license_pb";
import { LicenseServiceClient } from "../../server/license/protos/LicenseServiceClientPb";
import { licenseI } from "./licensesReducer";
import { BACKEND_URL } from "../RootReducer";
import { Metadata } from "grpc-web";

export const licenseClient = new LicenseServiceClient(BACKEND_URL || "");
export const LicenseHeaders: Metadata = {
  // service_name: "LicenseService",
  "Access-Control-Allow-Origin": "*",
  // crossdomain: true,
  allow_headers: "*",
  // "origin": "http://localhost:3000",
};

export function grpcRemoveJobtypeLicense(data: any, fun?: any) {
  return (dispatch: any) => {
    const reqRJLLicense = new RemoveJobTypeRequest();
    reqRJLLicense.setReporttype(data._id);

    licenseClient.removeJobType(
      reqRJLLicense,
      LicenseHeaders,
      (err, response) => {
        if (response == null) {
          console.log("err", err);
        } else {
          if (fun) {
            fun();
          }
        }
      }
    );
  };
}

export function grpcDeleteBlockLicense(data: any, fun?: any) {
  return (dispatch: any) => {
    const reqDBLLicense = new BlockOrDeleteRequest();
    reqDBLLicense.setId(data._id);
    reqDBLLicense.setType(data.type);

    licenseClient.blockOrDelete(
      reqDBLLicense,
      LicenseHeaders,
      (err, response) => {
        if (response == null) {
          console.log("err", err);
        } else {
          if (fun) {
            fun();
          }
        }
      }
    );
  };
}

export function grpcCreateLicense(data: any, fun?: any) {
  return (dispatch: any) => {
    const reqCLLicense = new CreateLicenseRequest();
    reqCLLicense.setName(data.name);
    reqCLLicense.setPopulation(data.population);
    reqCLLicense.setCountrycode(data.countryCode);
    reqCLLicense.setCellphone(data.cellphone);
    reqCLLicense.setImagepath(data.imagePath);
    reqCLLicense.setCreatedat(data.createdAt);
    reqCLLicense.setEnddateat(data.endAt);

    licenseClient.createLicense(
      reqCLLicense,
      LicenseHeaders,
      (err, response) => {
        if (response == null) {
          console.log("err", err);
        } else {
          if (fun) {
            fun();
          }
        }
      }
    );
  };
}

export function grpcUpdateLicense(data: any, fun?: any) {
  return (dispatch: any) => {
    const reqULLicense = new UpdateLicenseRequest();
    reqULLicense.setId(data._id);
    reqULLicense.setName(data.name);
    reqULLicense.setPopulation(data.population);
    reqULLicense.setCountrycode(data.countryCode);
    reqULLicense.setCellphone(data.cellphone);
    reqULLicense.setImagepath(data.imagePath);
    reqULLicense.setEnddateat(data.endAt);

    licenseClient.updateLicense(
      reqULLicense,
      LicenseHeaders,
      (err, response) => {
        if (response == null) {
          console.log("err", err);
        } else {
          if (fun) {
            fun();
          }
        }
      }
    );
  };
}

export function grpcGetFeedbackRate(id: string, fun?: any) {
  return (dispatch: any) => {
    const reqGFRate = new FeedbackRateMessage();
    reqGFRate.setLicenseId(id);

    licenseClient.feedbackRateByLicense(
      reqGFRate,
      LicenseHeaders,
      (err, response: FeedbackRateResponse) => {
        if (response == null) {
          console.log("err", err);
        } else {
          dispatch({
            type: SET_FEEDBACK_RATE,
            payload: response.toObject(),
          });
          if (fun) {
            fun();
          }
        }
      }
    );
  };
}

export function grpcFeedBack(
  data: {
    id: string;
    type: FetchFeedbackRequest.RequestFType;
    limit: number;
    offset: number;
  },
  fun?: any
) {
  return (dispatch: any) => {
    const reqULJTLicense = new FetchFeedbackRequest();
    reqULJTLicense.setId(data.id);
    reqULJTLicense.setType(data.type);
    reqULJTLicense.setLimit(data.limit);
    reqULJTLicense.setOffset(data.offset);
    // dispatch({
    //   type: SET_FEEDBACK_DATA,
    //   limit: data.limit,
    //   offset: data.offset,
    //   requestType: data.type,
    // });
    licenseClient.fetchFeedback(
      reqULJTLicense,
      LicenseHeaders,
      (err, response: SuccessFeedbackMessage) => {
        if (response == null) {
          console.log("err", err);
        } else {
          dispatch({
            type: SET_FEEDBACK_LIST,
            payload: response.toObject().feedbacksList,
          });
          if (fun) {
            fun();
          }
        }
      }
    );
  };
}

export function grpcUpdateLicenseJobTypes(data: any, fun?: any) {
  return (dispatch: any) => {
    const reqULJTLicense = new UpdateJobTypesRequest();
    reqULJTLicense.setId(data._id);
    reqULJTLicense.setJobtypesList(data.jobTypes);

    licenseClient.updateJobTypes(
      reqULJTLicense,
      LicenseHeaders,
      (err, response) => {
        if (response == null) {
          console.log("err", err);
        } else {
          if (fun) {
            fun();
          }
        }
      }
    );
  };
}

export function grpcUpdateSetting(data: any, fun?: any) {
  return (dispatch: any) => {
    //TrackingSettings
    const reqCUSTracking = new TrackingSettings();
    reqCUSTracking.setDistanceinterval(data.distanceInterval);
    reqCUSTracking.setTimeinterval(data.timeInterval);
    reqCUSTracking.setEnable(data.enable);
    reqCUSTracking.setTrackingtype(data.trackingType);
    reqCUSTracking.setTrackingmode(data.trackingMode);

    //LicenseSettings
    const reqCUSGeo = new GeoPointL();
    reqCUSGeo.setLat(data.coords.lat);
    reqCUSGeo.setLng(data.coords.lng);

    const reqCUSLicense = new LicenseSettings();
    reqCUSLicense.setZoom(data.zoom);
    reqCUSLicense.setCoords(reqCUSGeo);
    reqCUSLicense.setJobtypesList(data.jobTypes);
    reqCUSLicense.setReportsdaysforadmin(data.daysAdmin);
    reqCUSLicense.setReportsdaysforteam(data.daysTeam);
    reqCUSLicense.setReportsdaysforuser(data.daysUser);

    //Geometry
    let pointArrayAux: any = [];

    data.points.map((point: any) => {
      const reqCUSPoint = new Point2D();
      reqCUSPoint.setLat(point.lng);
      reqCUSPoint.setLng(point.lat);

      pointArrayAux.push(reqCUSPoint);
    });

    const reqCUSPolygon = new Polygon2D();
    reqCUSPolygon.setPointList(pointArrayAux);

    //Main
    const reqCUSSetting = new SettingRequest();
    reqCUSSetting.setId(data._id);
    reqCUSSetting.setCitiesList(data.cities);
    reqCUSSetting.setTrackingsettings(reqCUSTracking);
    reqCUSSetting.setLicensesettings(reqCUSLicense);
    reqCUSSetting.setGeometry(reqCUSPolygon);
    reqCUSSetting.setLicenseid(data.licenseId);

    licenseClient.updateSettings(
      reqCUSSetting,
      LicenseHeaders,
      (err, response) => {
        console.log("err", err);
        if (response == null) {
          console.log("err", err);
        } else {
          console.log("response", response);
          if (fun) {
            fun();
          }
        }
      }
    );
  };
}

export function grpcFetchLicenses(
  data: {
    point: {
      lat: number;
      lng: number;
    };
    query: string;
    type: FetchType;
  },
  fun?: any
) {
  return (dispatch: any) => {
    const reqFLPoint = new Point2D();
    reqFLPoint.setLat(data.point.lat);
    reqFLPoint.setLng(data.point.lng);

    const reqFLFetch = new FetchRequestL();
    reqFLFetch.setQuery(data.query);
    reqFLFetch.setPoint(reqFLPoint);
    reqFLFetch.setType(data.type);

    licenseClient.fetchLicenses(
      reqFLFetch,
      LicenseHeaders,
      (err, response: FetchResponseL) => {
        if (!response) {
          console.log("err", err);
        } else {
          let licenseArray: licenseI[] = [];

          const licenseList: License.AsObject[] =
            response.toObject().licensesList;

          licenseList.map((licenseItem: License.AsObject) => {
            let settings = licenseItem.settings;

            let sTrackingSettings: TrackingSettings.AsObject | undefined =
              settings?.trackingsettings;
            let sLicenseSettings: LicenseSettings.AsObject | undefined =
              settings?.licensesettings;
            let sGeometry: Polygon2D.AsObject | undefined = settings?.geometry;
            let gPoints: Point2D.AsObject[] | undefined = sGeometry?.pointList;
            let slsGeoPoint: any = sLicenseSettings?.coords;

            let geoArray: any = [];

            gPoints?.map((geoItem: any) => {
              geoArray.push({
                lng: geoItem.lng ? geoItem.lng : geoItem.longitude,
                lat: geoItem.lat ? geoItem.lat : geoItem.latitude,
              });
            });
            const lat: number = slsGeoPoint.lng
              ? slsGeoPoint.lng
              : slsGeoPoint.longitude;
            const lng: number = slsGeoPoint.lat
              ? slsGeoPoint.lat
              : slsGeoPoint.latitude;
            let auxItem: licenseI = {
              _id: licenseItem.id,
              cities: settings?.citiesList || [],
              settingsId: settings?.id || "",
              name: licenseItem.name,
              population: licenseItem.population,
              countryCode: licenseItem.countrycode,
              cellphone: licenseItem.cellphone,
              imagePath: licenseItem.imagepath,
              createAt: licenseItem.createat,
              endAt: licenseItem.enddateat,
              blocked: licenseItem.blocked,
              deleted: licenseItem.deleted,

              trackingSettings: {
                distanceInterval: sTrackingSettings?.distanceinterval,
                timeInterval: sTrackingSettings?.timeinterval,
                enable: sTrackingSettings?.enable,
                trackingType: sTrackingSettings?.trackingtype,
                trackingMode: sTrackingSettings?.trackingmode,
              },
              licenseSettings: {
                zoom: sLicenseSettings?.zoom || 0,
                coords: {
                  lat: lat,
                  lng: lng,
                },
                jobTypes: sLicenseSettings?.jobtypesList,
                reportsDaysForTeam: sLicenseSettings?.reportsdaysforteam,
                reportsDaysForAdmin: sLicenseSettings?.reportsdaysforadmin,
                reportsDaysForUser: sLicenseSettings?.reportsdaysforuser,
              },
              geometry: {
                point: geoArray,
              },
            };

            licenseArray.push(auxItem);
          });

          dispatch({
            type: LICENSES_LIST,
            payload: licenseArray,
          });
          if (fun) {
            fun();
          }
        }
      }
    );
  };
}
