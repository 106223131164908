import { TEAMS_LIST, TEAMS_UPDATES } from "./teamsTypes";
import {
  Team,
  FindByLicenseIdRequest,
  DeleteRequest,
  AssignToTeamRequest,
  AssignType,
  Subscribe,
} from "../../server/team/protos/team_pb";
import { TeamServiceClient } from "../../server/team/protos/TeamServiceClientPb";
import { BACKEND_URL } from "../RootReducer";
import { Metadata } from "grpc-web";

const teamClient = new TeamServiceClient(BACKEND_URL || "");
const TeamHeaders: Metadata = {
  // service_name: "LicenseService",
  "Access-Control-Allow-Origin": "*",
  // crossdomain: true,
  allow_headers: "*",
  // "origin": "http://localhost:3000",
};

export function grpcCreateUpdateTeam(data: any, fun?: any) {
  return (dispatch: any) => {
    const reqCUPTeam = new Team();
    reqCUPTeam.setId(data.id);
    reqCUPTeam.setName(data.name);
    reqCUPTeam.setBossList(data.boss);
    reqCUPTeam.setMembersList(data.member);
    reqCUPTeam.setType(data.type);
    reqCUPTeam.setLicenseid(data.licenseId);
    reqCUPTeam.setImagepath(data.imagePath);
    reqCUPTeam.setTasksList(data.task);
    reqCUPTeam.setJobsList(data.job);
    teamClient.teamRequest(reqCUPTeam, TeamHeaders, (err, response) => {
      if (response == null) {
        console.log("err", err);
      } else {
        if (fun) {
          fun();
        }
      }
    });
  };
}

export function grpcAssignToTeam(
  data: { id: string; ids: string[]; type: AssignType },
  fun?: any
) {
  return (dispatch: any) => {
    const reqATAssign = new AssignToTeamRequest();
    reqATAssign.setId(data.id);
    reqATAssign.setIdsList(data.ids);
    reqATAssign.setType(data.type);

    teamClient.assignToTeam(reqATAssign, TeamHeaders, (err, response) => {
      if (response == null) {
        console.log("err", err);
      } else {
        if (fun) {
          fun();
        }
      }
    });
  };
}

export function grpcFetchTeams(data: any, fun?: any) {
  return (dispatch: any) => {
    const reqFTFetch = new FindByLicenseIdRequest();
    reqFTFetch.setId(data._id);

    teamClient.findByLicenseId(reqFTFetch, TeamHeaders, (err, response) => {
      if (response == null) {
        console.log("err", err);
      } else {
        // let teamArray: any = [];

        const teamList: Team.AsObject[] = response.toObject().teamsList;
        // teamList.map((teamItem: any) => {
        //   let auxItem = {
        //     _id: teamItem.getId(),
        //     name: teamItem.getName(),
        //     boss: teamItem.getBossList(),
        //     members: teamItem.getMembersList(),
        //     licenseId: teamItem.getLicenseid(),
        //     type: teamItem.getType() === 0 ? "WORKERS" : "ADMIN",
        //     imagePath: teamItem.getImagepath(),
        //     tasks: teamItem.getTasksList(),
        //     jobs: teamItem.getJobsList(),
        //   };

        //   teamArray.push(auxItem);
        // });

        dispatch({
          type: TEAMS_LIST,
          payload: teamList,
        });
        if (fun) {
          fun();
        }
      }
    });
  };
}

export function grpcDeleteTeam(data: any, fun?: any) {
  return (dispatch: any) => {
    const reqDTDelete = new DeleteRequest();
    reqDTDelete.setId(data.id);

    teamClient.deleteTeam(reqDTDelete, TeamHeaders, (err, response) => {
      if (response == null) {
        console.log("err", err);
      } else {
        if (fun) {
          fun();
        }
      }
    });
  };
}

// export function grpcListenToTeams(data: { id: string }, fun?: any) {
//   return (dispatch: any) => {
//     const reqATListen = new Subscribe();
//     reqATListen.setId(data.id);

//     teamClient
//       .subscribeTeam(reqATListen, TeamHeaders)
//       .on("data", (response) => {
//         console.log("team stream", (response as Team).toObject());
//         dispatch({
//           type: TEAMS_UPDATES,
//           payload: (response as Team).toObject(),
//         });
//         if (fun) {
//           fun();
//         }
//       })
//       .on("error", (err) => {
//         console.log("err", err);
//       });
//   };
// }
