import React, { useState } from "react";
import HeaderDialog from "../components/Header-dialog";
import SelectComponentGeneric from "../components/select-component-generic";
// import BigButton from "../components/Big-button-component";
import { netWorking } from "../../firebase/index";
import ActionButtons from "../components/Action-buttons";
import "./style.scss";
import { isEmpty } from "lodash";
import { REMOVE_FROM_TEAM } from "../../const/const";
import { Report } from "../../server/report/protos/report_pb";
import { AssignType, Team } from "../../server/team/protos/team_pb";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { grpcAssignToTeam } from "../../redux/teams/teamsActions";
import { grpcIgnoreReport } from "../../redux/reportTypes/reportsActions";

interface DeleteReportProps {
  onCloseRequest: any;
  markerReport: Report.AsObject;
  teams: Team.AsObject[];
  closeReport: any;
}

export const DeleteReport: React.FunctionComponent<DeleteReportProps> = ({
  onCloseRequest,
  markerReport,
  teams,
  closeReport,
}: DeleteReportProps) => {
  const [state, setState] = useState<{
    selected: null | String;
  }>({
    selected: null,
  });

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const doneClick = () => {
    if (state.selected) {
      var value = markerReport;
      console.log("value", value);
      var tempTeamSelected = teams.filter(
        (e: Team.AsObject) => e.tasksList.indexOf(markerReport.reportid) !== -1
      );
      if (tempTeamSelected !== null && !isEmpty(tempTeamSelected)) {
        var temp = tempTeamSelected[0];
        // netWorking.updateTeamAssignedReports({
        //   teamId: temp._id,
        //   reportsIds: [markerReport._id],
        //   assign: REMOVE_FROM_TEAM,
        //   createNotification: false,
        // });
        dispatch(
          grpcAssignToTeam(
            {
              id: temp?.id || "",
              ids: [markerReport.reportid],
              type: AssignType.REMOVE_FROM_TEAM_TASKS,
            },
            () => {}
            
          )
        );
      }
      dispatch(
        grpcIgnoreReport(value.reportid, () => {
          onCloseRequest();
          closeReport();
        })
      );
      // netWorking.ignoreReport("reports/delete", {
      //   _id: value._id,
      //   ignored: state.selected,
      // });
      // onCloseRequest();
      // closeReport();
    }
  };

  const select = (e: string) => {
    setState({
      selected: e,
    });
  };

  return (
    <div className="content-dialog big-dialog-x10">
      <HeaderDialog
        onCloseRequest={onCloseRequest}
        title={t("name.ignore.report")}
        description={t("name.ignore.report.question")}
      ></HeaderDialog>
      <div className="content-options">
        <div className="content-options-body">
          <div className="options-body-item ">
            <div className="selector-box full-width-select">
              <SelectComponentGeneric
                initialData={t("form.select.motive")}
                data={[
                  t("form.motive.1"),
                  t("form.motive.2"),
                  t("form.motive.3"),
                  t("form.motive.4"),
                  t("form.motive.5"),
                ]}
                select={select}
                selected={state.selected}
              ></SelectComponentGeneric>
            </div>
          </div>
          <ActionButtons
            clickable={state.selected !== null}
            doneText={t("submit.yes")}
            cancelText={t("submit.no")}
            small={true}
            doneClick={doneClick}
            cancelClick={onCloseRequest}
          ></ActionButtons>
        </div>
      </div>
    </div>
  );
};

export default DeleteReport;
