import {
  updateDateI,
  byStatusI,
  byDateI,
  byCategoryI,
  byCategoryCustomI,
  byResponseI,
} from "./dashboardReducer";

export const DASHBOARD_LOADING_BY_STATUS = "DASHBOARD_LOADING_BY_STATUS";
export const DASHBOARD_LOADING_BY_DATE = "DASHBOARD_LOADING_BY_DATE";
export const DASHBOARD_LOADING_BY_CATEGORY = "DASHBOARD_LOADING_BY_CATEGORY";
export const DASHBOARD_LOADING_BY_RESPONSE = "DASHBOARD_LOADING_BY_RESPONSE";

export const DASHBOARD_UPDATE_DATE = "DASHBOARD_UPDATE_DATE";
export const DASHBOARD_CLEAR_DATE = "DASHBOARD_CLEAR_DATE";

export const DASHBOARD_BY_STATUS = "DASHBOARD_BY_STATUS";
export const DASHBOARD_BY_DATE = "DASHBOARD_BY_DATE";
export const DASHBOARD_BY_CATEGORY = "DASHBOARD_BY_CATEGORY";
export const DASHBOARD_BY_CATEGORY_CUSTOM = "DASHBOARD_BY_CATEGORY_CUSTOM";
export const DASHBOARD_BY_RESPONSE = "DASHBOARD_BY_RESPONSE";
//================================================================
export interface DashboardLoadingByStatus {
  type: typeof DASHBOARD_LOADING_BY_STATUS;
  payload: boolean;
}

export interface DashboardLoadingByDate {
  type: typeof DASHBOARD_LOADING_BY_DATE;
  payload: boolean;
}

export interface DashboardLoadingByCategory {
  type: typeof DASHBOARD_LOADING_BY_CATEGORY;
  payload: boolean;
}

export interface DashboardLoadingByResponse {
  type: typeof DASHBOARD_LOADING_BY_RESPONSE;
  payload: boolean;
}

export interface DashboardUpdateDate {
  type: typeof DASHBOARD_UPDATE_DATE;
  payload: updateDateI;
}

export interface DashboardClearDate {
  type: typeof DASHBOARD_CLEAR_DATE;
  payload: string;
}

export interface DashboardByStatus {
  type: typeof DASHBOARD_BY_STATUS;
  payload: byStatusI;
}

export interface DashboardByDate {
  type: typeof DASHBOARD_BY_DATE;
  payload: byDateI;
}

export interface DashboardByCategory {
  type: typeof DASHBOARD_BY_CATEGORY;
  payload: byCategoryI;
}

export interface DashboardByCategoryCustom {
  type: typeof DASHBOARD_BY_CATEGORY_CUSTOM;
  payload: byCategoryCustomI;
}

export interface DashboardByResponse {
  type: typeof DASHBOARD_BY_RESPONSE;
  payload: byResponseI;
}

export type DashboardDispatchTypes =
  | DashboardLoadingByStatus
  | DashboardLoadingByDate
  | DashboardLoadingByCategory
  | DashboardLoadingByResponse
  | DashboardUpdateDate
  | DashboardClearDate
  | DashboardByStatus
  | DashboardByDate
  | DashboardByCategory
  | DashboardByCategoryCustom
  | DashboardByResponse;
