import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RootStore } from "../../redux/Store";
import "./style.scss";

import { grpcEnableUser, grpcDeleteUser } from "../../redux/users/usersAction";
import { Team } from "../../server/team/protos/team_pb";
import { User } from "../../server/user/protos/user_pb";
import { Role } from "../../server/user/protos/auth_pb";

import { ManageWorkerModal } from "./components/manageWorkerModal";
import ConfirmationDialog from "../dialogs/confirmation-dialog";
import NotificationDialog from "../dialogs/Notification-dialog";
import SimpleModal from "../../utils/dialogs/simple-dialog";
import { DeleteIcon, ChatIcon } from "../../assets/svg/index";
//Table
import TableContainer from "@material-ui/core/TableContainer";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";

interface WorkerPageProps {
  fetchAllUsers: any;
}

export const WorkerPage: React.FunctionComponent<WorkerPageProps> = ({
  fetchAllUsers,
}: any) => {
  const users: User.AsObject[] = useSelector(
    (globalState: RootStore) => globalState.users.users
  );
  const roleList: Role.AsObject[] = useSelector(
    (globalState: RootStore) => globalState.users.roleList
  );
  const teams: Team.AsObject[] = useSelector(
    (globalState: RootStore) => globalState.teams.teams
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();

  let defaultValue: any;

  const [state, setState] = useState({
    showModalAdd: false,
    showModalAdded: false,
    showModalEdit: false,
    showModalEdited: false,
    showModalDelete: false,
    userEdit: defaultValue,
    userDelete: defaultValue,
  });

  const columns = [
    { id: 0, label: "table.column.name", minWidth: 120 },
    { id: 1, label: "table.column.status", minWidth: 100 },
    {
      id: 2,
      label: "table.column.email",
      minWidth: 170,
      align: "left",
      format: (value: any) => value.toLocaleString(),
    },
    {
      id: 3,
      label: "table.column.phone",
      minWidth: 120,
      align: "left",
      format: (value: any) => value.toLocaleString(),
    },
    {
      id: 4,
      label: "table.column.team",
      minWidth: 120,
      align: "left",
      format: (value: any) => value.toFixed(2),
    },
    {
      id: 5,
      label: "table.column.enable",
      minWidth: 90,
      align: "left",
      format: (value: any) => value.toFixed(2),
    },
    {
      id: 6,
      label: "",
      minWidth: 91,
      align: "right",
      format: (value: any) => value.toFixed(2),
    },
  ];

  const clearNotificationAdd = () => {
    setState((prevState) => ({
      ...prevState,
      showModalAdded: !state.showModalAdded,
    }));
  };

  const clearNotificationEdit = () => {
    setState((prevState) => ({
      ...prevState,
      showModalEdited: !state.showModalEdited,
    }));
  };

  const handleToggleModalAdd = () => {
    setState((prevState) => ({
      ...prevState,
      showModalAdd: !state.showModalAdd,
    }));
  };

  const handleToggleModalEdit = (value: any, user?: any) => {
    setState((prevState) => ({
      ...prevState,
      showModalEdit: value,
      userEdit: user ? user : defaultValue,
    }));
  };

  const handleToggleModalDelete = (value: any, user?: any) => {
    setState((prevState) => ({
      ...prevState,
      showModalDelete: value,
      userDelete: value && user ? user : defaultValue,
    }));
  };

  const enableUser = (user: any) => {
    if (user.acceptedInvitation) {
      dispatch(
        grpcEnableUser({ _id: user.id, enable: !user.enabled }, () => {
          fetchAllUsers();
        })
      );
    }
  };

  const deleteUser = () => {
    dispatch(
      grpcDeleteUser({ _id: state.userDelete.id }, () => {
        fetchAllUsers();
        handleToggleModalDelete(false);
      })
    );
  };

  useEffect(() => {
    fetchAllUsers();
  }, []);

  return (
    <div className="worker-page">
      <div className="top-worker-page">
        <div className="amount-of-workers">
          <span>
            {
              users.filter((e: User.AsObject) => {
                return (
                  roleList.find((d) => {
                    return d.name.includes("MC.WORKER") && d.roleid === e.role;
                  }) !== undefined
                );
              }).length
            }
          </span>
          {users.filter((e: User.AsObject) => {
            return (
              roleList.find((d) => {
                return d.name.includes("MC.WORKER") && d.roleid === e.role;
              }) !== undefined
            );
          }).length === 1
            ? " " + t("title.workers.singular")
            : " " + t("title.workers")}
        </div>
        <button className="btn-done" onClick={handleToggleModalAdd}>
          {t("subtitle.add")}
        </button>
      </div>
      <Paper className="root-table">
        <TableContainer className="">
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column: any) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    className={
                      column.id === 0
                        ? "column-1 first-th"
                        : column.id === 1
                        ? "column-2"
                        : column.id === 2
                        ? "column-3"
                        : column.id === 3
                        ? "column-4"
                        : column.id === 4
                        ? "column-5"
                        : column.id === 5
                        ? "column-6"
                        : "column-7"
                    }
                    style={{
                      minWidth: column.minWidth,
                      maxWidth: column.maxWidth,
                    }}
                  >
                    {t(column.label)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {users
                .filter((e: User.AsObject) => {
                  return (
                    roleList.find((d) => {
                      return (
                        d.name.includes("MC.WORKER") && d.roleid === e.role
                      );
                    }) !== undefined
                  );
                })
                .map((item: User.AsObject, i: any) => {
                  let value = teams.filter(
                    (e: Team.AsObject) => e.membersList.indexOf(item.id) !== -1
                  );
                  let asignedValue = "";
                  if (value && value.length > 0) {
                    value.map((e: any) => {
                      if (asignedValue === "") asignedValue = e.name;
                      else asignedValue = asignedValue + ", " + e.name;
                      return true;
                    });
                  } else {
                    asignedValue = t("name.no.assigned");
                  }
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={item.id}>
                      {columns.map((column: any) => {
                        let value = "";
                        if (column.id === 0) {
                          value = item.name + " " + item.lastName;
                        } else if (column.id === 1) {
                          value = item.acceptedInvitation
                            ? t("name.active")
                            : t("name.waiting.activation");
                        } else if (column.id === 2) {
                          value = item.email;
                        } else if (column.id === 3) {
                          value = item.phoneNumber
                            ? item.phoneNumber
                            : t("name.no.register");
                        } else if (column.id === 4) {
                          value = asignedValue;
                        } else if (column.id === 5) {
                          value = "";
                        } else if (column.id === 6) {
                          value = "";
                        }
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            className={
                              column.id === 0
                                ? "column-1 first-td"
                                : column.id === 1
                                ? "column-2"
                                : column.id === 2
                                ? "column-3"
                                : column.id === 3
                                ? "column-4"
                                : column.id === 4
                                ? "column-5"
                                : column.id === 5
                                ? "column-6"
                                : "column-7 last-td"
                            }
                            onClick={() => {
                              if (column.id !== 5 && column.id !== 6)
                                handleToggleModalEdit(true, item);
                            }}
                          >
                            {column.id === 0 && (
                              <div className="first-name-image">
                                <img
                                  src={
                                    item.imagePath
                                      ? item.imagePath
                                      : "https://miciudad.s3-us-west-1.amazonaws.com/profile.png"
                                  }
                                  alt=""
                                />
                                <p>{value}</p>
                              </div>
                            )}

                            {column.id === 5 && (
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  checked={item.enabled}
                                  onChange={() => {
                                    enableUser(item);
                                  }}
                                ></input>
                                <span className="slider round"></span>
                              </label>
                            )}

                            {column.id === 6 && (
                              <div className="group-buttons-admins">
                                <div className="tile-edit-button message-circle">
                                  <ChatIcon></ChatIcon>
                                </div>
                                <div
                                  className="tile-edit-button"
                                  onClick={() => {
                                    handleToggleModalDelete(true, item);
                                  }}
                                >
                                  <DeleteIcon></DeleteIcon>
                                </div>
                              </div>
                            )}

                            {column.format && typeof value === "number" ? (
                              <p>{column.format(value)}</p>
                            ) : (
                              column.id !== 0 && <p>{value}</p>
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {state.showModalDelete && (
        <SimpleModal>
          <ConfirmationDialog
            t={t}
            doneClick={deleteUser}
            description={t("notification.worker.delete")}
            onCloseRequest={() => {
              handleToggleModalDelete(false);
            }}
          ></ConfirmationDialog>
        </SimpleModal>
      )}

      {state.showModalAdded && (
        <NotificationDialog
          type={1}
          successMessage={t("notification.worker")}
          clearMessage={clearNotificationAdd}
        ></NotificationDialog>
      )}
      {state.showModalAdd && (
        <SimpleModal>
          <ManageWorkerModal
            clearMessage={clearNotificationAdd}
            onCloseRequest={() => {
              fetchAllUsers();
              handleToggleModalAdd();
            }}
          ></ManageWorkerModal>
        </SimpleModal>
      )}
      {state.showModalEdited && (
        <NotificationDialog
          type={1}
          successMessage={t("notification.worker.edited")}
          clearMessage={clearNotificationEdit}
        ></NotificationDialog>
      )}
      {state.showModalEdit && (
        <SimpleModal>
          <ManageWorkerModal
            worker={state.userEdit}
            clearMessage={clearNotificationEdit}
            onCloseRequest={() => {
              fetchAllUsers();
              handleToggleModalEdit(false);
            }}
          ></ManageWorkerModal>
        </SimpleModal>
      )}
    </div>
  );
};

export default WorkerPage;
