import { ChatRequest } from "./models/chat.model";
import { LicenseRequest } from "./models/license.model";
import { NotificationRequest } from "./models/notifications.model";
import { ReportRequest } from "./models/report.model";
import { TeamRequest } from "./models/team.model";

export interface RequestMessage {
  channel: Channels;
  data: Message;
  token?: string | null;
  action?: Actions;
}

type Message =
  TeamRequest |
  ChatRequest |
  ReportRequest |
  LicenseRequest |
  NotificationRequest;

export enum Channels {
  ListenChatMessages = 'ListenChatMessages',
  ListenNotifications = 'ListenNotifications',
  ListenLicenseUpdates = 'ListenLicenseUpdates',
  ListenReportsMessage = 'ListenReportsMessage',
  ListenReportTypeUpdates = 'ListenReportTypeUpdates',
  ListenTeam = 'ListenTeam'
}

// type Channels =
//   'ListenChatMessages'
//   | 'ListenNotifications'
//   | 'ListenLicenseUpdates'
//   | 'ListenReportsMessage'
//   | 'ListenReportTypeUpdates';

type Actions = 'Unsubscribe' | 'Subscribe';
