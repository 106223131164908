import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RootStore } from "../../../redux/Store";

import Ratings from "react-ratings-declarative";
import { licenseI } from "../../../redux/licenses/licensesReducer";
import { grpcFeedBack } from "../../../redux/licenses/licensesActions";
import {
  Feedback,
  FetchFeedbackRequest,
} from "../../../server/license/protos/license_pb";
import { SET_FEEDBACK_DATA } from "../../../redux/licenses/licensesTypes";

export const TileResume: React.FunctionComponent = () => {
  const selectedLicense: licenseI | undefined = useSelector(
    (globalState: RootStore) => globalState.licenses.selectedLicense
  );
  const feedbackList: Feedback.AsObject[] = useSelector(
    (globalState: RootStore) => globalState.licenses.feedbackList
  );
  const feedbackTotal = useSelector(
    (globalState: RootStore) => globalState.licenses.feedbackTotal
  );
  const feedbackRate = useSelector(
    (globalState: RootStore) => globalState.licenses.feedbackRate
  );
  const limit = useSelector(
    (globalState: RootStore) => globalState.licenses.limit
  );
  const offset = useSelector(
    (globalState: RootStore) => globalState.licenses.offset
  );

  let defaultValue: any;
  let defaultValueArray: any = [];

  const [pageLength, setPageLength] = useState(1);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  let ratingColor = "#ffa303";
  let tabIndex = -1;

  const lastPage = () => {
    updateFeedbackData(offset - 1);
  };

  const pageByNumber = (page: any) => {
    updateFeedbackData(page);
  };

  const nextPage = () => {
    updateFeedbackData(offset + 1);
  };

  const getNumeration = () => {
    for (let index = 0; index < pageLength; index++) {
      return (
        <li className="page-item" key={index + 1}>
          <div
            className="page-link"
            onClick={() => {
              let indexNumber = index + 1;
              pageByNumber(indexNumber);
            }}
          >
            {index + 1}
          </div>
        </li>
      );
    }
  };

  const showFeedbacks = () => {
    dispatch(
      grpcFeedBack({
        id: selectedLicense?._id || "",
        type: FetchFeedbackRequest.RequestFType.BY_LICENSE_ID,
        limit: limit,
        offset: offset,
      })
    );
  };

  const updateFeedbackData = (page: number) => {
    dispatch({
      type: SET_FEEDBACK_DATA,
      limit: limit,
      offset: page,
      requestType: FetchFeedbackRequest.RequestFType.BY_LICENSE_ID,
    });
  };

  useEffect(() => {
    if (selectedLicense) {
      showFeedbacks();
    }
  }, [offset]);

  useEffect(() => {
    if (feedbackTotal !== 0) {
      setPageLength(Math.ceil(feedbackTotal / limit));
    }
  }, [feedbackTotal]);

  return (
    <div className="tile-card tile-card-rating">
      <div className="tile-card-header">
        <div className="tile-title">
          {feedbackRate && feedbackTotal ? feedbackTotal : 0}{" "}
          {feedbackTotal === 1
            ? t("tabs.title.feedback.singular")
            : t("tabs.title.feedback")}
          {" (" + t("tabs.title.rating") + ") "}{" "}
          {feedbackRate
            ? feedbackRate === 0
              ? "N/A"
              : feedbackRate.toFixed(2)
            : "N/A"}
        </div>
        <div className=""></div>
      </div>
      <div className="tile-card-body feedback-card">
        <div className="list-of-feedbacks">
          <div className="column-feedbacks">
            {feedbackList.slice(0, 5).map((e: any, index: any) => (
              <div className="box-feedback" key={index}>
                <div className="feedback-header">
                  <div className="feedback-header-user">
                    <div className="img-user-feedback">
                      <img
                        src={
                          e.userimagepath
                            ? e.userimagepath
                            : "https://miciudad.s3-us-west-1.amazonaws.com/profile-image.png"
                        }
                        alt=""
                        className="logo"
                      />{" "}
                    </div>
                    <div className="name-user-feedback">{e.username}</div>
                  </div>
                  <div className="feedback-header-rating">
                    <Ratings
                      rating={e.rate}
                      starRatedColor="blue"
                      numberOfStars={5}
                      widgetDimensions="15px"
                      widgetSpacings="0px"
                      name="rating"
                    >
                      <Ratings.Widget widgetRatedColor={ratingColor} />
                      <Ratings.Widget widgetRatedColor={ratingColor} />
                      <Ratings.Widget widgetRatedColor={ratingColor} />
                      <Ratings.Widget widgetRatedColor={ratingColor} />
                      <Ratings.Widget widgetRatedColor={ratingColor} />
                    </Ratings>
                  </div>
                </div>
                <div className="feedback-body">
                  <p>{e.message}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="column-feedbacks">
            {feedbackList.slice(5, limit).map((e: any, index: any) => (
              <div className="box-feedback" key={index}>
                <div className="feedback-header">
                  <div className="feedback-header-user">
                    <div className="img-user-feedback">
                      <img
                        src={
                          e.userimagepath
                            ? e.userimagepath
                            : "https://miciudad.s3-us-west-1.amazonaws.com/profile-image.png"
                        }
                        alt=""
                        className="logo"
                      />{" "}
                    </div>
                    <div className="name-user-feedback">{e.username}</div>
                  </div>
                  <div className="feedback-header-rating">
                    <Ratings
                      rating={e.rate}
                      starRatedColor="blue"
                      numberOfStars={5}
                      widgetDimensions="15px"
                      widgetSpacings="0px"
                      name="rating"
                    >
                      <Ratings.Widget widgetRatedColor={ratingColor} />
                      <Ratings.Widget widgetRatedColor={ratingColor} />
                      <Ratings.Widget widgetRatedColor={ratingColor} />
                      <Ratings.Widget widgetRatedColor={ratingColor} />
                      <Ratings.Widget widgetRatedColor={ratingColor} />
                    </Ratings>
                  </div>
                </div>
                <div className="feedback-body">
                  <p>{e.message}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="footer-page">
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-center">
              <li className={offset === 1 ? "page-item disabled" : "page-item"}>
                <div
                  className="page-link"
                  tabIndex={tabIndex}
                  onClick={lastPage}
                >
                  {t("name.last")}
                </div>
                {/* <button
                      className={this.state.offset === 0
                        ? "btn-done-dialog-last-inactive""btn-done-dialog-last"}
                      onClick={this.lastPage}
                    >
                      {this.props.t("name.last")}
                    </button> */}
              </li>
              {/* {pageLength.map((e: any, index: any) => {
                return (
                  <li className="page-item" key={index}>
                    <div
                      className="page-link"
                      onClick={() => {
                        let indexNumber = index + 1;
                        pageByNumber(indexNumber);
                      }}
                    >
                      {index + 1}
                    </div>
                  </li>
                );
              })} */}
              {getNumeration()}
              <li
                className={
                  pageLength === offset ? "page-item disabled" : "page-item"
                }
              >
                <div className="page-link" onClick={nextPage}>
                  {t("name.next")}
                </div>
                {/* <button
                      className={"btn-done-dialog-next"}
                      onClick={this.nextPage}
                    >
                      {this.props.t("name.next")}
                    </button> */}
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default TileResume;
