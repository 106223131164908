import React, { useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RootStore } from "../../redux/Store";
import "./style.scss";

import { ReportTypes } from "../../redux/reportTypes/reportsReducer";
import { TEAMS_SELECTED } from "../../redux/teams/teamsTypes";
import { Team } from "../../server/team/protos/team_pb";
import { User } from "../../server/user/protos/user_pb";

import { ManageTeamModal } from "./components/managaTeamModal";
import SimpleModal from "../../utils/dialogs/simple-dialog";
import TeamPage from "./components/team";
import TeamImg from "../../assets/img/team_image.png";
import Email from "../../assets/img/mail.png";
//Table
import TableContainer from "@material-ui/core/TableContainer";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";

interface TeamsPageProps {
  fetchAllTeams: any;
  fetchAllUsers: any;
}

export const TeamsPage: React.FunctionComponent<TeamsPageProps> = ({
  fetchAllTeams,
  fetchAllUsers,
}: any) => {
  const teams = useSelector(
    (globalState: RootStore) => globalState.teams.teams
  );
  const users = useSelector(
    (globalState: RootStore) => globalState.users.users
  );
  const reportTypes: ReportTypes[] = useSelector(
    (globalState: RootStore) => globalState.reportTypes.reportTypesGeneral
  );
  const selectedTeam = useSelector(
    (globalState: RootStore) => globalState.teams.selectedTeam
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    showModalAdd: false,
  });

  const columns = [
    { id: 0, label: "table.column.team", minWidth: 120 },
    { id: 1, label: "table.column.member", minWidth: 200 },
    {
      id: 2,
      label: "table.column.boss",
      minWidth: 200,
      align: "left",
      format: (value: any) => value.toLocaleString(),
    },
    {
      id: 3,
      label: "table.column.typeofwork",
      minWidth: 200,
      align: "left",
      format: (value: any) => value.toLocaleString(),
    },
    {
      id: 4,
      label: "",
      minWidth: 61,
      align: "right",
      format: (value: any) => value.toFixed(2),
    },
  ];

  const handleToggleModalAdd = () => {
    setState((prevState) => ({
      ...prevState,
      showModalAdd: !state.showModalAdd,
    }));
  };

  return (
    <Fragment>
      {!selectedTeam && (
        <div className="team-page">
          <div className="top-team-page">
            <div className="amount-of-teams">
              <span>{teams.length}</span>
              {teams.length === 1
                ? " " + t("title.teams.singular")
                : " " + t("title.teams")}
            </div>
            <button className="btn-done" onClick={handleToggleModalAdd}>
              {" "}
              {t("name.add")}
            </button>
          </div>
          <Paper className="root-table">
            <TableContainer className="">
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map((column: any) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className={
                          column.id === 0
                            ? "column-1 first-th"
                            : column.id === 1
                            ? "column-2"
                            : column.id === 2
                            ? "column-3"
                            : column.id === 3
                            ? "column-4"
                            : "column-5"
                        }
                        style={{
                          minWidth: column.minWidth,
                          maxWidth: column.maxWidth,
                        }}
                      >
                        {t(column.label)}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {teams.map((item: Team.AsObject, i: any) => {
                    let jobtypesString = "";
                    let Boss = t("name.no.assigned");
                    if (item.jobsList && item.jobsList.length > 0)
                      item.jobsList.map((e: any) => {
                        let reportType = reportTypes.find((d: ReportTypes) => {
                          return d.reporttypeid === e;
                        });
                        if (jobtypesString === "")
                          jobtypesString = reportType
                            ? reportType[t("language")]
                            : "";
                        else
                          jobtypesString =
                            jobtypesString +
                            ", " +
                            (reportType ? reportType[t("language")] : "");
                        return true;
                      });
                    else jobtypesString = t("name.no.assigned");
                    if (jobtypesString === "")
                      jobtypesString = t("name.no.assigned");
                    users.forEach((element: any) => {
                      if (
                        item.bossList &&
                        item.jobsList.indexOf(element._id) !== -1
                      ) {
                        if (Boss === t("name.no.assigned"))
                          Boss = element.name + " " + element.lastName;
                        else
                          Boss =
                            Boss + ", " + element.name + " " + element.lastName;
                      }
                    });

                    let arrayStrings: any = [];
                    item.membersList.forEach((element: string) => {
                      if (
                        item.bossList &&
                        item.bossList.indexOf(element) !== -1
                      ) {
                        const found = users.find(
                          (element2: User.AsObject) => element2.id === element
                        );
                        if (Boss === t("name.no.assigned"))
                          Boss = found?.name + " " + found?.lastName;
                        else
                          Boss =
                            Boss + ", " + found?.name + " " + found?.lastName;
                      }

                      const found = users.find(
                        (element2: User.AsObject) => element2.id === element
                      );
                      if (found?.name && found?.lastName) {
                        if (arrayStrings.length === 0)
                          arrayStrings.push(found.name + " " + found.lastName);
                        else
                          arrayStrings.push(
                            " " + found.name + " " + found.lastName
                          );
                      }
                    });
                    if (arrayStrings.length === 0)
                      arrayStrings.push(t("name.no.assigned"));

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={item.id}
                        onClick={() => {
                          let selectedTeamAuxiliar = teams.filter(
                            (e: Team.AsObject) => e.id === item.id
                          )[0];

                          if (selectedTeamAuxiliar)
                            dispatch({
                              type: TEAMS_SELECTED,
                              payload: selectedTeamAuxiliar,
                            });
                        }}
                      >
                        {columns.map((column: any) => {
                          var value = "";
                          if (column.id === 0) {
                            value = item.name;
                          } else if (column.id === 1) {
                            value = arrayStrings.toString();
                          } else if (column.id === 2) {
                            value = Boss;
                          } else if (column.id === 3) {
                            value = jobtypesString;
                          } else if (column.id === 4) {
                            value = "";
                          }
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              className={
                                column.id === 0
                                  ? "column-1 first-td"
                                  : column.id === 1
                                  ? "column-2"
                                  : column.id === 2
                                  ? "column-3"
                                  : column.id === 3
                                  ? "column-4"
                                  : "column-5 last-td"
                              }
                            >
                              {column.id === 0 && (
                                <div className="first-name-image">
                                  <img
                                    src={
                                      item.imagepath ? item.imagepath : TeamImg
                                    }
                                    alt=""
                                  />
                                  <p>{value}</p>
                                </div>
                              )}
                              {column.id === 4 && (
                                <img
                                  src={Email}
                                  alt=""
                                  className="email-logo"
                                />
                              )}
                              {column.format && typeof value === "number" ? (
                                <p>{column.format(value)}</p>
                              ) : (
                                column.id !== 0 && <p>{value}</p>
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          {state.showModalAdd && (
            <SimpleModal
              onCloseRequest={() => {
                handleToggleModalAdd();
              }}
            >
              <ManageTeamModal
                onCloseRequest={() => {
                  handleToggleModalAdd();
                  fetchAllTeams();
                  fetchAllUsers();
                }}
              ></ManageTeamModal>
            </SimpleModal>
          )}
        </div>
      )}
      {selectedTeam && (
        <TeamPage
          onCloseRequest={() => {
            fetchAllTeams();
            fetchAllUsers();
          }}
        ></TeamPage>
      )}
    </Fragment>
  );
};

export default TeamsPage;
