import React from "react";

class ActionButtons extends React.Component {
  render() {
    return (
      <div className={this.props.small?"small-buttons action-btn-box":"action-btn-box"}>
        <button className="btn-cancel" onClick={this.props.cancelClick}>{this.props.cancelText}</button>
        <button className={this.props.clickable?"btn-done-dialog":"btn-done-disable"} onClick={this.props.clickable?this.props.doneClick:()=>{}}> {this.props.doneText} </button>
      </div>
    );
  }
}

export default ActionButtons;
