import { Role } from "./../../server/user/protos/auth_pb.d";
import { User, UserSettings } from "../../server/user/protos/user_pb";
import {
  USERS_LOADING,
  USERS_ERROR,
  USERS_LIST,
  USERS_SELECTED,
  UsersDispatchTypes,
  SET_ROLES_LIST,
  SET_SETTINGS_FILTERS_LIST,
  SET_NEW_SETTING_FILTER,
  SET_DELETE_SETTING_FILTER,
  USERS_LIST_BLOCKED,
} from "./usersTypes";

let defaultValue: any;
let defaultValueArray: any = [];

interface usersState {
  loading: boolean;
  users: User.AsObject[];
  usersBlocked: User.AsObject[];
  selectedUser?: User.AsObject;
  roleList: Role.AsObject[];
  filtersList: UserSettings.AsObject[];
}

const defaultState: usersState = {
  loading: false,
  users: defaultValueArray,
  selectedUser: defaultValue,
  roleList: [],
  filtersList: [],
  usersBlocked: [],
};

const usersReducer = (
  state: usersState = defaultState,
  action: UsersDispatchTypes
): usersState => {
  switch (action.type) {
    case USERS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case USERS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case USERS_LIST:
      const UserList: any = action.payload;
      const actualUser: any = state.selectedUser;

      let defaultUser: any;

      if (UserList.length > 0) {
        if (actualUser) {
          defaultUser = UserList.find((lic: any) => {
            return lic._id === actualUser._id;
          });
        }
      }
      return {
        ...state,
        users: UserList,
        selectedUser: defaultUser,
      };
    case USERS_LIST_BLOCKED:
      return {
        ...state,
        usersBlocked: [...action.payload],
      };
    case USERS_SELECTED:
      const userSelected: any = action.payload;
      return {
        ...state,
        selectedUser: userSelected,
      };
    case SET_ROLES_LIST:
      return {
        ...state,
        roleList: action.payload,
      };
    case SET_SETTINGS_FILTERS_LIST:
      return {
        ...state,
        filtersList: action.payload,
      };
    case SET_NEW_SETTING_FILTER:
      const arrayNew: UserSettings.AsObject[] = [];
      const array: UserSettings.AsObject[] = [...state.filtersList];
      let found = false;
      array.forEach((d) => {
        if (d.id === action.payload.id) {
          found = true;
          arrayNew.push(action.payload);
        } else {
          arrayNew.push(d);
        }
      });
      if (!found) {
        arrayNew.push(action.payload);
      }
      console.log("array", array);
      console.log("arrayNew", arrayNew);
      return {
        ...state,
        filtersList: arrayNew,
      };
    case SET_DELETE_SETTING_FILTER:
      const arrayD: UserSettings.AsObject[] = [];
      const arrayT: UserSettings.AsObject[] = [...state.filtersList];
      arrayT.forEach((d) => {
        if (d.id !== action.payload) {
          arrayD.push(d);
        }
      });
      return {
        ...state,
        filtersList: arrayD,
      };
    default:
      return state;
  }
};

export default usersReducer;
