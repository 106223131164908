import axios from "axios";
import { authAWS } from "../firebase/index";
// export const WEB = "http://portal.demo.giooby.com/";
export const WEB = "https://mc.giooby.com/";

export const URL = "http://localhost:9000/";
export const URLWebSocket = "http://localhost:9000";

// // //DEV
// export const URL = "http://mc.api.dev.giooby.com/";
// export const URLWebSocket = "http://mc.api.dev.giooby.com";

//RELEASE
// export const URL = "http://mc.api.sdsharp.com/";
// export const URLWebSocket = "http://mc.api.sdsharp.com";

// export const URL = "http://54.67.38.19:3000/";
// export const URLWebSocket = "http://54.67.38.19:3000";

export const authRefresh = "auth/refresh";
export const reportTypes = "reportTypes";
export const deleteWorker = "reports/deleteWorker";
export const chat = "chat";
export const messageChat = "messageChat";
export const chatGet = "chat/get";
export const messageChatGet = "messageChat/get";
export const teamMultiAssign = "teams/multiAssign";
export const getCountStatistics = "reports/getCountStatistics";
export const statistisDoneRemove = "statistics/removeDone";
export const updatedJobtypes = "reports/updatedJobtypes";
export const activeDates = "tracker/activeDates";

export const get = (endpoint, fun) => {
  axios
    .get(URL + endpoint, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        crossdomain: true,
        Authorization: `Bearer ${authAWS.getAccessToken()}`,
      },
    })
    .then(function (response) {
      if (fun !== undefined) fun(response);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const post = (endpoint, data, fun) => {
  axios
    .post(URL + endpoint, data, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        crossdomain: true,
        Authorization: `Bearer ${authAWS.getAccessToken()}`,
      },
    })
    .then(function (response) {
      if (fun !== undefined) fun(response);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const put = (endpoint, data, fun) => {
  axios
    .put(URL + endpoint, data, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        crossdomain: true,
        Authorization: `Bearer ${authAWS.getAccessToken()}`,
      },
    })
    .then(function (response) {
      if (fun !== undefined) fun(response);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const refreshToken = (fun) => {
  axios
    .post(
      URL + authRefresh,
      {
        refresh_token: authAWS.getRefreshToken(),
      },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          crossdomain: true,
          Authorization: `Bearer ${authAWS.getAccessToken()}`,
        },
      }
    )
    .then(function (result) {
      if (result.data.error === 1) {
        authAWS.logout();
      } else {
        authAWS.setAccessToken(result.data.response.accessToken);
        authAWS.setRefreshToken(result.data.response.refreshToken);
        fun();
      }
    })
    .catch(function (error) {
      console.log(error);
      authAWS.logout();
    });
};

export const updateTeamAssignedReports = (data, fun) => {
  axios
    .post(URL + "teams/assign", data, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        crossdomain: true,
        Authorization: `Bearer ${authAWS.getAccessToken()}`,
      },
    })
    .then(function (response) {
      // fun(response);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const updateTeamAssignedWorkers = (data, fun) => {
  axios
    .post(URL + "teams/updateWorkers", data, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        crossdomain: true,
        Authorization: `Bearer ${authAWS.getAccessToken()}`,
      },
    })
    .then(function (response) {
      fun(response);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const ignoreReport = (collection, data, fun) => {
  axios
    .post(URL + collection, data, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        crossdomain: true,
        Authorization: `Bearer ${authAWS.getAccessToken()}`,
      },
    })
    .then(function (response) {
      // fun(response);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const likeReport = (collection, data, fun) => {
  axios
    .post(URL + collection, data, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        crossdomain: true,
        Authorization: `Bearer ${authAWS.getAccessToken()}`,
      },
    })
    .then(function (response) {
      // fun(response);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const getNotifications = (id, type, fun) => {
  axios
    .get(URL + "notifications?report=" + id + "&notificationType=" + type, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        crossdomain: true,
        Authorization: `Bearer ${authAWS.getAccessToken()}`,
      },
    })
    .then(function (response) {
      fun(response);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const uploadFiles = (data, fun) => {
  console.log(data);
  axios
    .post(URL + "uploadFile", data, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        crossdomain: true,
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${authAWS.getAccessToken()}`,
      },
    })
    .then(function (response) {
      fun(response);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const getDistrictById = (id, fun) => {
  axios
    .get(URL + "district/findById/" + id, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        crossdomain: true,
        Authorization: `Bearer ${authAWS.getAccessToken()}`,
      },
    })
    .then(function (response) {
      fun(response);
    })
    .catch(function (error) {
      // if (error.response === undefined && error.response.status === 401) {
      //   //place your reentry code
      //   authAWS.logout();
      // }
    });
};

export const getFeedback = (id, limit, page, fun) => {
  axios
    .post(
      URL + "feedback/byDistrict",
      { districtId: id, limit: limit, page: page },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          crossdomain: true,
          Authorization: `Bearer ${authAWS.getAccessToken()}`,
        },
      }
    )
    .then(function (response) {
      fun(response);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const getStatistics = (id, fullYear, fun) => {
  axios
    .post(
      URL + "statistics",
      { districtId: id, fullYear: fullYear },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          crossdomain: true,
          Authorization: `Bearer ${authAWS.getAccessToken()}`,
        },
      }
    )
    .then(function (response) {
      fun(response);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const getFeedbackAverage = (id, fun) => {
  axios
    .get(URL + "feedback/average?districtId=" + id, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        crossdomain: true,
        Authorization: `Bearer ${authAWS.getAccessToken()}`,
      },
    })
    .then(function (response) {
      fun(response);
    })
    .catch(function (error) {
      console.log(error);
    });
};
export const getAllDistricts = (fun) => {
  axios
    .get(URL + "district/", {
      headers: {
        "Access-Control-Allow-Origin": "*",
        crossdomain: true,
        Authorization: `Bearer ${authAWS.getAccessToken()}`,
      },
    })
    .then(function (response) {
      fun(response);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const getReportsByCities = (districtId, fun) => {
  axios
    .post(
      URL + "reports/byDistrict",
      {
        districtId: districtId,
      },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          crossdomain: true,
          Authorization: `Bearer ${authAWS.getAccessToken()}`,
        },
      }
    )
    .then(function (response) {
      fun(response);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const getTeamsByDistrictId = (id, fun) => {
  axios
    .get(URL + "teams/byDistrictId/" + id, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        crossdomain: true,
        Authorization: `Bearer ${authAWS.getAccessToken()}`,
      },
    })
    .then(function (response) {
      fun(response);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const getWorkersByDistrictId = (id, fun) => {
  axios
    .get(URL + "workers/byDistrictId/" + id, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        crossdomain: true,
        Authorization: `Bearer ${authAWS.getAccessToken()}`,
      },
    })
    .then(function (response) {
      fun(response);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const getTrackersByDistrictId = (id, fun) => {
  axios
    .get(URL + "tracker/byDistrictId/" + id, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        crossdomain: true,
        Authorization: `Bearer ${authAWS.getAccessToken()}`,
      },
    })
    .then(function (response) {
      fun(response);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const getTrackersByDistrictIdAndDate = (body, fun) => {
  axios
    .post(URL + "tracker/byDistrictIdAndDate", body, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        crossdomain: true,
        Authorization: `Bearer ${authAWS.getAccessToken()}`,
      },
    })
    .then(function (response) {
      fun(response);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const getUsersByCities = (body, fun) => {
  axios
    .post(URL + "users/findByCities/", body, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        crossdomain: true,
        Authorization: `Bearer ${authAWS.getAccessToken()}`,
      },
    })
    .then(function (response) {
      fun(response);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const unBlockUser = (body, fun) => {
  axios
    .post(URL + "users/block/", body, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        crossdomain: true,
        Authorization: `Bearer ${authAWS.getAccessToken()}`,
      },
    })
    .then(function (response) {
      fun(response);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const findUsers = (body, fun) => {
  axios
    .post(URL + "users/findUsers/", body, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        crossdomain: true,
        Authorization: `Bearer ${authAWS.getAccessToken()}`,
      },
    })
    .then(function (response) {
      fun(response);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const getAdminsByDistrictId = (id, fun) => {
  axios
    .get(URL + "admins/" + id, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        crossdomain: true,
        Authorization: `Bearer ${authAWS.getAccessToken()}`,
      },
    })
    .then(function (response) {
      fun(response);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const checkDistrict = (data, fun) => {
  axios
    .post(URL + "district/check", data, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        crossdomain: true,
        Authorization: `Bearer ${authAWS.getAccessToken()}`,
      },
    })
    .then(function (response) {
      fun(response);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const createDistrict = (data, fun) => {
  axios
    .post(URL + "district", data, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        crossdomain: true,
        Authorization: `Bearer ${authAWS.getAccessToken()}`,
      },
    })
    .then(function (response) {
      fun(response);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const createWorker = (data, fun) => {
  axios
    .post(URL + "workers", data, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        crossdomain: true,
        Authorization: `Bearer ${authAWS.getAccessToken()}`,
      },
    })
    .then(function (response) {
      fun(response);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const createTeam = (data, fun) => {
  axios
    .post(URL + "teams", data, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        crossdomain: true,
        Authorization: `Bearer ${authAWS.getAccessToken()}`,
      },
    })
    .then(function (response) {
      fun(response);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const createUserWithEmailAndPassword = (data, fun) => {
  axios
    .post(URL + "admins", data, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        crossdomain: true,
        Authorization: `Bearer ${authAWS.getAccessToken()}`,
      },
    })
    .then(function (response) {
      fun(response);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const createNewUserWithEmailAndPassword = (data, fun) => {
  axios
    .post(URL + "admins/newAdmin", data, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        crossdomain: true,
        Authorization: `Bearer ${authAWS.getAccessToken()}`,
      },
    })
    .then(function (response) {
      fun(response);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const updateAdminsUsers = (data, fun) => {
  axios
    .post(URL + "admins/createdPassword", data, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        crossdomain: true,
        Authorization: `Bearer ${authAWS.getAccessToken()}`,
      },
    })
    .then(function (response) {
      fun(response);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const verifyPasswordResetCode = (data, fun) => {
  axios
    .post(URL + "admins/checkCode", data, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        crossdomain: true,
        Authorization: `Bearer ${authAWS.getAccessToken()}`,
      },
    })
    .then(function (response) {
      fun(response);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const confirmPasswordReset = (data, fun) => {
  axios
    .post(URL + "admins/confirmPassword", data, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        crossdomain: true,
        Authorization: `Bearer ${authAWS.getAccessToken()}`,
      },
    })
    .then(function (response) {
      fun(response);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const sendPasswordResetEmail = (data, fun) => {
  axios
    .post(URL + "admins/sendEmail", data, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        crossdomain: true,
        Authorization: `Bearer ${authAWS.getAccessToken()}`,
      },
    })
    .then(function (response) {
      fun(response);
    })
    .catch(function (error) {
      console.log(error);
    });
};
