import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../redux/Store";
import { useTranslation } from "react-i18next";

import {
  grpcDeleteTeam,
  grpcAssignToTeam,
} from "../../../redux/teams/teamsActions";

import { AddWorkerModal } from "./addWorkerModal";
import NotificationDialog from "../../dialogs/Notification-dialog.js";
import ConfirmationDialog from "../../dialogs/confirmation-dialog";
import SimpleModal from "../../../utils/dialogs/simple-dialog";
import { ManageWorkerModal } from "../../worker-page/components/manageWorkerModal";
import { ManageTeamModal } from "./managaTeamModal";
import Footer from "../../../components/footer/index";
import { WorkerTile } from "./worker-tile";
import { EmptyBox } from "./empty-box";
import { Edit, DeleteIcon } from "../../../assets/svg/index.js";
import BitMap from "../../../assets/img/profile-image.png";
import TeamImg from "../../../assets/img/team_image.png";
import Message from "../assets/mail.png";
import { AssignType, Team } from "../../../server/team/protos/team_pb";
import { ReportTypes } from "../../../redux/reportTypes/reportsReducer";
import { User } from "../../../server/user/protos/user_pb";

interface TeamProps {
  onCloseRequest: any;
}

export const TeamPage: React.FunctionComponent<TeamProps> = ({
  onCloseRequest,
}: any) => {
  const users = useSelector(
    (globalState: RootStore) => globalState.users.users
  );
  const reportTypes = useSelector(
    (globalState: RootStore) => globalState.reportTypes.reportTypes
  );
  const selectedTeam: Team.AsObject = useSelector(
    (globalState: RootStore) => globalState.teams.selectedTeam
  );

  let defaultValue: any;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [state, setState] = useState<{
    menu: number;
    showModalAdd: boolean;
    showModalAdded: boolean;
    showModalEdit: boolean;
    showModalEditWorker: boolean;
    showModalTryOfDelete: boolean;
    showModalComfirm: boolean;
    type: any;
    item: Team.AsObject;
    subtype: any;
    worker: User.AsObject;
  }>({
    menu: 0,
    showModalAdd: false,
    showModalAdded: false,
    showModalEdit: false,
    showModalEditWorker: false,
    showModalTryOfDelete: false,
    showModalComfirm: false,
    type: defaultValue,
    item: defaultValue,
    subtype: defaultValue,
    worker: defaultValue,
  });

  const changeMenu = (e: any) => {
    if (state.menu !== e)
      setState((prevState) => ({
        ...prevState,
        menu: e,
      }));
  };

  const getReportType = (e: any) => {
    let reportType = reportTypes.find((t: ReportTypes) => {
      return t.reporttypeid === e;
    });
    if (reportType)
      return (
        <Fragment key={e + "-fragment"}>
          <div className="jobtype-tile" key={e + "-parent"}>
            <div
              className={
                !reportType.iconmap
                  ? "radius"
                  : "box-image-types neutral-bg-type"
              }
            >
              <img
                src={!reportType.iconmap ? BitMap : reportType.iconmap}
                alt=""
              ></img>
            </div>
            <div className="type-text">{reportType[t("language")]}</div>
          </div>
          {reportType.reportTypes?.map((d: ReportTypes) => {
            if (selectedTeam.jobsList.indexOf(d.reporttypeid) !== -1)
              return (
                <div
                  className="jobtype-tile sub-jobtype"
                  key={d.reporttypeid + "-sub"}
                >
                  <div
                    className={
                      !d.iconmap ? "radius" : "box-image-types neutral-bg-type"
                    }
                  >
                    <img src={!d.iconmap ? BitMap : d.iconmap} alt=""></img>
                  </div>
                  <div className="type-text">{d[t("language")]}</div>
                </div>
              );
          })}
        </Fragment>
      );
    else return null;
  };

  const handleToggleModalEdit = () => {
    setState((prevState) => ({
      ...prevState,
      showModalEdit: !state.showModalEdit,
    }));
  };

  const handleToggleModalAdd = () => {
    setState((prevState) => ({
      ...prevState,
      showModalAdd: !state.showModalAdd,
    }));
  };

  const handleToggleModalComfirm = () => {
    setState((prevState) => ({
      ...prevState,
      showModalComfirm: !state.showModalComfirm,
      type: defaultValue,
      item: defaultValue,
      subtype: defaultValue,
    }));
  };

  const handleToggleModalEditWorker = (value: any, user?: any) => {
    setState((prevState) => ({
      ...prevState,
      showModalEditWorker: value,
      worker: user ? user : defaultValue,
    }));
  };

  const handleToggleModalTryOfDelete = () => {
    setState((prevState) => ({
      ...prevState,
      showModalTryOfDelete: !state.showModalTryOfDelete,
    }));
  };

  const removeFromTeam = () => {
    dispatch(
      grpcAssignToTeam(
        {
          id: selectedTeam.id,
          ids: [state.item.id],
          type: 0,
        },
        () => {
          handleToggleModalComfirm();
          onCloseRequest();
        }
      )
    );
    if (selectedTeam.bossList.indexOf(state.item.id) !== -1) {
      dispatch(
        grpcAssignToTeam(
          {
            id: selectedTeam.id,
            ids: [state.item.id],
            type: 1,
          },
          () => {
            handleToggleModalComfirm();
            onCloseRequest();
          }
        )
      );
    }
  };

  const addAsLeader = () => {
    dispatch(
      grpcAssignToTeam(
        {
          id: selectedTeam.id,
          ids: [state.item.id],
          type: AssignType.ASSIGN_TO_TEAM_BOSS,
        },
        () => {
          handleToggleModalComfirm();
          onCloseRequest();
        }
      )
    );
  };

  const removeFromLeader = () => {
    dispatch(
      grpcAssignToTeam(
        {
          id: selectedTeam.id,
          ids: [state.item.id],
          type: AssignType.REMOVE_FROM_TEAM_BOSS,
        },
        () => {
          handleToggleModalComfirm();
          onCloseRequest();
        }
      )
    );
  };

  const deleteTeam = () => {
    dispatch(
      grpcDeleteTeam(
        {
          id: state.item.id,
        },
        () => {
          handleToggleModalComfirm();
          onCloseRequest();
        }
      )
    );
  };

  const action = (type: any, item: Team.AsObject, subtype: any) => {
    setState((prevState) => ({
      ...prevState,
      showModalComfirm: true,
      type: type,
      item: item,
      subtype: subtype,
    }));
  };

  return (
    <div className="team-view">
      <div className="body-wv">
        <div className="card-wv">
          <div className="header-team">
            <div className="edit-button" onClick={handleToggleModalEdit}>
              {t("name.edit")} <Edit></Edit>
            </div>
            <div className="img-team">
              {" "}
              <img
                src={!selectedTeam.imagepath ? TeamImg : selectedTeam.imagepath}
                alt=""
              ></img>
            </div>
            <div className="name-team">
              {selectedTeam.name ? selectedTeam.name : ""}
            </div>
          </div>
          <div className="list-box">
            <div className="title-list">{t("table.column.typeofwork")}:</div>
            <div className="list-team">
              {selectedTeam.jobsList.map((e: any) => getReportType(e))}
            </div>
          </div>
          <div className="divider"></div>
          <div className="footer-card">
            <div
              className="delete-box"
              onClick={() => {
                if (selectedTeam.tasksList.length < 1)
                  action(0, selectedTeam, 1);
                else {
                  handleToggleModalTryOfDelete();
                }
              }}
            >
              {" "}
              <DeleteIcon></DeleteIcon> {t("name.delete.team")}
            </div>
          </div>
        </div>

        <div className="team-wv">
          <div className="filters-team-wv">
            <div
              className={state.menu === 0 ? "active-filter" : ""}
              onClick={() => changeMenu(0)}
            >
              {t("name.member")}
            </div>
            {/* <div
              className={this.state.menu === 1 ? "active-filter" : ""}
              onClick={() => this.changeMenu(1)}
            >
              Mapa
            </div> */}
          </div>
          <div className="list-team-wv">
            {users ? (
              users
                .filter(
                  (e: User.AsObject) =>
                    selectedTeam.membersList.indexOf(e.id) !== -1
                )
                .map((e: any) => (
                  <WorkerTile
                    key={Math.random().toString(36).substring(7)}
                    action={action}
                    item={e}
                    handleToggleModalEditWorker={() => {
                      handleToggleModalEditWorker(true, e);
                    }}
                  ></WorkerTile>
                ))
            ) : (
              <div></div>
            )}
            <EmptyBox showDialog={handleToggleModalAdd}></EmptyBox>
          </div>
        </div>
      </div>
      {/* ADD WORKER TO TEAM */}
      {state.showModalAdd && (
        <SimpleModal>
          <AddWorkerModal
            onCloseRequest={() => {
              handleToggleModalAdd();
              onCloseRequest();
            }}
          ></AddWorkerModal>
        </SimpleModal>
      )}
      {/* EDIT TEAM INFORMATION */}
      {state.showModalEdit && (
        <SimpleModal>
          <ManageTeamModal
            onCloseRequest={() => {
              handleToggleModalEdit();
              onCloseRequest();
            }}
          ></ManageTeamModal>
        </SimpleModal>
      )}
      {/* EDIT WORKER INFORMATION */}
      {state.showModalEditWorker && (
        <SimpleModal>
          <ManageWorkerModal
            worker={state.worker}
            clearMessage={() => {}}
            onCloseRequest={() => {
              handleToggleModalEditWorker(false);
              onCloseRequest();
            }}
          ></ManageWorkerModal>
        </SimpleModal>
      )}
      {/* CONFIRM ACTIONS (ADD LEADER / REMOVE LEADER / DELETE TEAM / REMOVE FROM TEAM) */}
      {state.showModalComfirm && (
        <SimpleModal>
          <ConfirmationDialog
            t={t}
            doneClick={
              state.type === 0
                ? state.subtype === 0
                  ? removeFromTeam
                  : deleteTeam
                : state.subtype === 0
                ? removeFromLeader
                : addAsLeader
            }
            description={
              state.type === 0
                ? state.subtype === 0
                  ? t("notification.team.removal")
                  : t("notification.team.delete")
                : state.subtype === 0
                ? t("notification.team.remove.boss")
                : t("notification.team.add.boss")
            }
            onCloseRequest={() => {
              handleToggleModalComfirm();
            }}
          ></ConfirmationDialog>
        </SimpleModal>
      )}
      {/* SHOW NOTIFICATION FOR TRY OF TEAM DELETATION */}
      {state.showModalTryOfDelete && (
        <NotificationDialog
          type={2}
          errorMessage={t("notification.team.error.delete")}
          clearMessage={handleToggleModalTryOfDelete}
        ></NotificationDialog>
      )}
      {/* SHOW NOTIFICATION FOR ADDED WORKER */}
      {/* {state.showModalAdded && (
        <NotificationDialog
          type={1}
          successMessage={t("notification.team")}
          clearMessage={clearMessage}
        ></NotificationDialog>
      )} */}
    </div>
  );
};

export default TeamPage;
