import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../redux/Store";
import { useTranslation } from "react-i18next";

import { USER_ROLE, onlyLetters, validEmailRegex } from "../../../utils/utils";
import {
  grpcCreateUser,
  grpcUpdateUser,
  grpcDeleteUser,
  grpcResendEmailVerification,
} from "../../../redux/users/usersAction";

//Table
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextFieldComponent from "../../components/text-field-component";
import ConfirmationDialog from "../../dialogs/confirmation-dialog";
import SimpleModal from "../../../utils/dialogs/simple-dialog";
import { Edit, DeleteIcon } from "../../../assets/svg/index";
import ActionButtons from "../../components/Action-buttons";
import { licenseI } from "../../../redux/licenses/licensesReducer";
import { User } from "../../../server/user/protos/user_pb";
import { Role } from "../../../server/user/protos/auth_pb";
import { grpcCheckEmailInUse } from "../../../redux/user/userAction";
import NotificationGlobalDialog from "../../dialogs/Notification-global-dialog";

interface TileUsersProps {
  fetchAllUsers: any;
  fetchAllLicenses: any;
}

export const TileUsers: React.FunctionComponent<TileUsersProps> = ({
  fetchAllUsers,
  fetchAllLicenses,
}: any) => {
  const selectedLicense: licenseI | undefined = useSelector(
    (globalState: RootStore) => globalState.licenses.selectedLicense
  );
  const users: User.AsObject[] = useSelector(
    (globalState: RootStore) => globalState.users.users
  );

  const roleList: Role.AsObject[] = useSelector(
    (globalState: RootStore) => globalState.users.roleList
  );
  let defaultValue: any;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    message: defaultValue,
    nameMessage: defaultValue,
    showModalDelete: false,
    showAddUser: false,
    showEditUser: false,

    nameForAdd: "",
    emailForAdd: "",
    selectedForAdd: defaultValue,
    invalidEmailForAdd: false,
    invalidNameForAdd: false,

    editUser: defaultValue,
    nameForEdit: "",
    emailForEdit: "",
    selectedForEdit: defaultValue,
    invalidEmailForEdit: false,
    invalidNameForEdit: false,

    deleteUser: defaultValue,

    showNotification: false,
    emailForNotification: "",
  });

  const columns = [
    { id: 0, label: "table.column.name", minWidth: 120 },
    { id: 1, label: "table.column.email", minWidth: 170 },
    {
      id: 2,
      label: "table.column.type.of.account",
      minWidth: 120,
      align: "left",
      format: (value: any) => value.toLocaleString(),
    },
    {
      id: 3,
      label: "name.send.email",
      minWidth: 80,
      align: "right",
      format: (value: any) => value.toLocaleString(),
    },
    {
      id: 4,
      label: "",
      minWidth: 91,
      align: "right",
      format: (value: any) => value.toFixed(2),
    },
  ];

  const handleForm = (name: any, event: any) => {
    setState((prevState) => ({
      ...prevState,
      [name]: event,
    }));
    if (name === "emailForAdd")
      setState((prevState) => ({
        ...prevState,
        invalidEmailForAdd: false,
      }));
    if (name === "emailForEdit")
      setState((prevState) => ({
        ...prevState,
        invalidEmailForEdit: false,
      }));
    if (name === "nameForAdd")
      setState((prevState) => ({
        ...prevState,
        invalidNameForAdd: false,
      }));
    if (name === "nameForEdit")
      setState((prevState) => ({
        ...prevState,
        invalidNameForEdit: false,
      }));
  };

  const getValidSubmit = () => {
    if (state.editUser) {
      if (
        state.nameForEdit !== "" &&
        state.emailForEdit !== "" &&
        state.nameForEdit &&
        state.emailForEdit
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (
        state.nameForAdd !== "" &&
        state.emailForAdd !== "" &&
        state.nameForAdd &&
        state.emailForAdd
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const handleToggleAdd = (state: any) => {
    setState((prevState) => ({
      ...prevState,
      showAddUser: state,
      showEditUser: false,
      nameForAdd: defaultValue,
      emailForAdd: defaultValue,
      selectedForAdd: defaultValue,
      invalidEmailForAdd: false,
      invalidNameForAdd: false,
    }));
  };

  const createProfile = () => {
    if (!validEmailRegex.test(state.emailForAdd)) {
      setState((prevState) => ({
        ...prevState,
        invalidEmailForAdd: true,
        message: t("message.error.invalid.email"),
      }));
      return;
    } else if (state.emailForAdd === "") {
      setState((prevState) => ({
        ...prevState,
        invalidNameForAdd: true,
        message: t("message.error.invalid.invalidName"),
      }));
      return;
    } else {
      const role = roleList.find((d) => {
        return d.name.includes("MC.ADMIN");
      });

      dispatch(
        grpcCheckEmailInUse(
          {
            email: state.emailForAdd.toLowerCase().trim(),
          },
          (response: any) => {
            console.log("response", response.success);
            if (response.success) {
              setState((prevState) => ({
                ...prevState,
                invalidEmailForAdd: true,
                message: t("message.error.invalid.email.in.use"),
              }));
            } else {
              dispatch(
                grpcCreateUser(
                  {
                    name: state.nameForAdd.trim(),
                    lastname: "",
                    email: state.emailForAdd.toLowerCase(),
                    phoneNumber: "",
                    //NEED TO ADD ROLE FIELD
                    role: role?.roleid || "",
                    licenseId: selectedLicense?._id || "",
                  },
                  (response: any) => {
                    fetchAllUsers();
                    handleToggleAdd(false);
                  }
                )
              );
            }
          }
        )
      );
    }
  };

  const handleToggleEdit = (state: any, user?: any) => {
    let type = defaultValue;

    if (state) {
      if (user.role === USER_ROLE.SERVICES) {
        type = t("form.user.type.1");
      }
      if (user.role === USER_ROLE.LEADER) {
        type = t("form.user.type.2");
      }
      if (user.role === USER_ROLE.SUPERVISOR) {
        type = t("form.user.type.3");
      }
      if (user.role === USER_ROLE.CASHIER) {
        type = t("form.user.type.4");
      }
    }

    setState((prevState) => ({
      ...prevState,
      showAddUser: false,
      showEditUser: state,
      editUser: state ? user : defaultValue,
      nameForEdit: state ? user.name : defaultValue,
      emailForEdit: state ? user.email : defaultValue,
      selectedForEdit: type,
      invalidEmailForEdit: false,
      invalidNameForEdit: false,
    }));
  };

  const resendEmailVerification = (user: User.AsObject) => {
    dispatch(
      grpcResendEmailVerification({ userId: user.id }, (response: any) => {
        // console.log("response", response.success);
        setNotificationDialog(user.email);
      })
    );
  };

  const updateProfile = () => {
    if (!validEmailRegex.test(state.emailForEdit)) {
      setState((prevState) => ({
        ...prevState,
        invalidEmailForEdit: true,
        message: t("message.error.invalid.email"),
      }));
      return;
    } else if (state.emailForEdit === "") {
      setState((prevState) => ({
        ...prevState,
        invalidNameForEdit: true,
        message: t("message.error.invalid.invalidName"),
      }));
      return;
    } else {
      const role = roleList.find((d) => {
        return d.name.includes("MC.ADMIN");
      });
      dispatch(
        grpcCheckEmailInUse(
          {
            email: state.emailForEdit.toLowerCase().trim(),
          },
          (response: any) => {
            console.log("response", response.success);
            if (
              response.success &&
              state.emailForEdit.toLowerCase().trim() !== state.editUser?.email
            ) {
              setState((prevState) => ({
                ...prevState,
                invalidEmailForEdit: true,
                message: t("message.error.invalid.email.in.use"),
              }));
            } else {
              dispatch(
                grpcUpdateUser(
                  {
                    _id: state.editUser.id,
                    username: state.nameForEdit.trim(),
                    preferredLanguage: t("language"),
                    imagePath: "",
                    name: state.nameForEdit.trim(),
                    lastname: "",
                    email: state.emailForEdit.toLowerCase(),
                    phoneNumber: "",
                    //NEED TO ADD ROLE FIELD
                    role: role?.roleid || "",
                    licenseId: selectedLicense?._id || "",
                  },
                  (response: any) => {
                    fetchAllUsers();
                    handleToggleEdit(false);
                  }
                )
              );
            }
          }
        )
      );
    }
  };

  const handleToggleModalDelete = (user?: any) => {
    setState((prevState) => ({
      ...prevState,
      showModalDelete: !state.showModalDelete,
      deleteUser: user ? user : defaultValue,
    }));
  };

  const deleteProfile = () => {
    dispatch(
      grpcDeleteUser({ _id: state.deleteUser.id }, () => {
        fetchAllUsers();
        handleToggleModalDelete();

        handleToggleEdit(false);
        handleToggleAdd(false);
      })
    );
  };

  const clearNotificationDialog = () => {
    setState((prevState) => ({
      ...prevState,
      emailForNotification: "",
      showNotification: false,
    }));
  };

  const setNotificationDialog = (email: any) => {
    setState((prevState) => ({
      ...prevState,
      emailForNotification: email,
      showNotification: true,
    }));
  };

  return (
    <div className="tile-card-admins">
      <div className="top-worker-page">
        <div className="amount-of-workers">
          <span>
            {
              users.filter((e) => {
                return (
                  roleList.find((d) => {
                    return d.name.includes("MC.ADMIN") && d.roleid === e.role;
                  }) !== undefined
                );
              }).length
            }
          </span>{" "}
          {users.filter((e) => {
            return (
              roleList.find((d) => {
                return d.name.includes("MC.ADMIN") && d.roleid === e.role;
              }) !== undefined
            );
          }).length === 1
            ? t("tabs.title.administrators.singular")
            : t("tabs.title.administrators")}
        </div>
        <button
          className="btn-done-dialog-admin"
          onClick={() => {
            handleToggleAdd(true);
          }}
        >
          {t("subtitle.add")}
        </button>
      </div>
      {state.showAddUser && (
        <div className="profile-card-edit">
          <div className="edit-box">
            <div className="options-body-item-big ">
              <div className="text">{t("name.super.name")}</div>{" "}
              <div className="selector-box full-width-select">
                <TextFieldComponent
                  placeholder={t("name.name")}
                  onChange={(event: any) => {
                    handleForm("nameForAdd", event);
                  }}
                  value={state.nameForAdd}
                  regExp={onlyLetters}
                  invalid={state.invalidNameForAdd}
                  maxValue={20}
                  message={state.nameMessage}
                ></TextFieldComponent>
              </div>
            </div>
            <div className="options-body-item-big ">
              <div className="text">{t("name.super.email")}</div>{" "}
              <div className="selector-box full-width-select">
                <TextFieldComponent
                  placeholder={t("name.email")}
                  onChange={(event: any) => {
                    handleForm("emailForAdd", event);
                  }}
                  value={state.emailForAdd}
                  invalid={state.invalidEmailForAdd}
                  maxValue={40}
                  message={state.message}
                ></TextFieldComponent>
              </div>
            </div>
            <ActionButtons
              doneText={
                selectedLicense ? t("submit.save") : t("submit.confirm")
              }
              clickable={getValidSubmit()}
              doneClick={() => {
                createProfile();
              }}
              cancelClick={() => {
                handleToggleAdd(false);
              }}
              cancelText={t("submit.cancel")}
            ></ActionButtons>
          </div>
        </div>
      )}
      {state.showEditUser && (
        <div className="profile-card-edit">
          <div className="edit-box">
            <div className="options-body-item-big ">
              <div className="text">{t("name.super.name")}</div>{" "}
              <div className="selector-box full-width-select">
                <TextFieldComponent
                  placeholder={t("name.name")}
                  onChange={(event: any) => {
                    handleForm("nameForEdit", event);
                  }}
                  value={state.nameForEdit}
                  regExp={onlyLetters}
                  invalid={state.invalidNameForEdit}
                  maxValue={20}
                  message={state.nameMessage}
                ></TextFieldComponent>
              </div>
            </div>
            <div className="options-body-item-big ">
              <div className="text">{t("name.super.email")}</div>{" "}
              <div className="selector-box full-width-select">
                <TextFieldComponent
                  placeholder={t("name.email")}
                  onChange={(event: any) => {
                    handleForm("emailForEdit", event);
                  }}
                  value={state.emailForEdit}
                  invalid={state.invalidEmailForEdit}
                  maxValue={40}
                  message={state.message}
                ></TextFieldComponent>
              </div>
            </div>
            <ActionButtons
              doneText={
                selectedLicense ? t("submit.save") : t("submit.confirm")
              }
              clickable={getValidSubmit()}
              doneClick={() => {
                updateProfile();
              }}
              cancelClick={() => {
                handleToggleEdit(false);
              }}
              cancelText={t("submit.cancel")}
            ></ActionButtons>
          </div>
        </div>
      )}
      <Paper className="root-table">
        <TableContainer className="">
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column: any) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    className={
                      column.id === 0
                        ? "column-1 first-th"
                        : column.id === 1
                        ? "column-2"
                        : column.id === 2
                        ? "column-3"
                        : column.id === 3
                        ? "column-4"
                        : "column-5"
                    }
                    style={{ minWidth: column.minWidth }}
                  >
                    {t(column.label)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {users
                .filter((e) => {
                  return (
                    roleList.find((d) => {
                      return d.name.includes("MC.ADMIN") && d.roleid === e.role;
                    }) !== undefined
                  );
                })
                .map((item: User.AsObject, i: any) => {
                  if (item.id === state.editUser?._id) {
                    return defaultValue;
                  } else
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={item.id}
                      >
                        {columns.map((column: any) => {
                          let value = "";
                          if (column.id === 0) {
                            value = item.name + " " + item.lastName;
                          } else if (column.id === 1) {
                            value = item.email;
                          } else if (column.id === 2) {
                            value = t("form.user.type.1");
                          } else if (column.id === 3) {
                            value = "";
                          } else if (column.id === 4) {
                            value = "";
                          }
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              className={
                                column.id === 0
                                  ? "column-1 first-td"
                                  : column.id === 1
                                  ? "column-2"
                                  : column.id === 2
                                  ? "column-3"
                                  : column.id === 3
                                  ? "column-4"
                                  : "column-5 last-td"
                              }
                            >
                              {column.id === 3 && (
                                <button
                                  className={
                                    item.acceptedInvitation
                                      ? "btn-done desbloq-btn disabled"
                                      : "btn-done desbloq-btn"
                                  }
                                  onClick={() => {
                                    // console.log(item);
                                    if (!item.acceptedInvitation)
                                      resendEmailVerification(item);
                                  }}
                                >
                                  {t("name.un.resend")}
                                </button>
                              )}
                              {column.id === 4 && (
                                <div className="group-buttons-admins">
                                  <div
                                    className="tile-edit-button"
                                    onClick={() => {
                                      document
                                        ?.querySelector(".profile-page")
                                        ?.scrollIntoView({
                                          behavior: "smooth",
                                        });
                                      handleToggleEdit(true, item);
                                    }}
                                  >
                                    <Edit></Edit>
                                  </div>
                                  <div
                                    className="tile-edit-button"
                                    onClick={() => {
                                      handleToggleModalDelete(item);
                                    }}
                                  >
                                    <DeleteIcon></DeleteIcon>
                                  </div>
                                </div>
                              )}

                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {state.showModalDelete && (
        <SimpleModal
          onCloseRequest={() => {
            handleToggleModalDelete();
          }}
        >
          <ConfirmationDialog
            title={t("name.delete.account")}
            description={""}
            noBold1={t("notification.delete.text1")}
            noBold2={t("notification.delete.text2")}
            boldText={state.deleteUser ? state.deleteUser.name + " " : ""}
            doneClick={deleteProfile}
            t={t}
            onCloseRequest={() => {
              handleToggleModalDelete();
            }}
          ></ConfirmationDialog>
        </SimpleModal>
      )}
      {state.showNotification && (
        <NotificationGlobalDialog
          type={1}
          clearAssignedReport={clearNotificationDialog}
          undoAssignReport={() => {}}
          showUndo={false}
          assignedReport={{}}
          toTeam={{ name: state.emailForNotification }}
          message={"notification.email.sent.to"}
          // successMessage={this.state.successMessage}
          t={t}
          clearMessage={() => {}}
        ></NotificationGlobalDialog>
      )}
    </div>
  );
};

export default TileUsers;
