/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as protos_reportAnalytics_pb from '../protos/reportAnalytics_pb';


export class AnalyticsServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoReportsByStatus = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_reportAnalytics_pb.ReportsByStatusResult,
    (request: protos_reportAnalytics_pb.ReportsByStatusMessage) => {
      return request.serializeBinary();
    },
    protos_reportAnalytics_pb.ReportsByStatusResult.deserializeBinary
  );

  reportsByStatus(
    request: protos_reportAnalytics_pb.ReportsByStatusMessage,
    metadata: grpcWeb.Metadata | null): Promise<protos_reportAnalytics_pb.ReportsByStatusResult>;

  reportsByStatus(
    request: protos_reportAnalytics_pb.ReportsByStatusMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_reportAnalytics_pb.ReportsByStatusResult) => void): grpcWeb.ClientReadableStream<protos_reportAnalytics_pb.ReportsByStatusResult>;

  reportsByStatus(
    request: protos_reportAnalytics_pb.ReportsByStatusMessage,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_reportAnalytics_pb.ReportsByStatusResult) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/AnalyticsService/ReportsByStatus',
        request,
        metadata || {},
        this.methodInfoReportsByStatus,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/AnalyticsService/ReportsByStatus',
    request,
    metadata || {},
    this.methodInfoReportsByStatus);
  }

  methodInfoReportsByMonthsxYear = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_reportAnalytics_pb.ReportsByMonthResult,
    (request: protos_reportAnalytics_pb.ReportByMonthMessage) => {
      return request.serializeBinary();
    },
    protos_reportAnalytics_pb.ReportsByMonthResult.deserializeBinary
  );

  reportsByMonthsxYear(
    request: protos_reportAnalytics_pb.ReportByMonthMessage,
    metadata: grpcWeb.Metadata | null): Promise<protos_reportAnalytics_pb.ReportsByMonthResult>;

  reportsByMonthsxYear(
    request: protos_reportAnalytics_pb.ReportByMonthMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_reportAnalytics_pb.ReportsByMonthResult) => void): grpcWeb.ClientReadableStream<protos_reportAnalytics_pb.ReportsByMonthResult>;

  reportsByMonthsxYear(
    request: protos_reportAnalytics_pb.ReportByMonthMessage,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_reportAnalytics_pb.ReportsByMonthResult) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/AnalyticsService/ReportsByMonthsxYear',
        request,
        metadata || {},
        this.methodInfoReportsByMonthsxYear,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/AnalyticsService/ReportsByMonthsxYear',
    request,
    metadata || {},
    this.methodInfoReportsByMonthsxYear);
  }

  methodInfoReportsByCategory = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_reportAnalytics_pb.ReportsByCategoryResult,
    (request: protos_reportAnalytics_pb.ReportsByCategoryMessage) => {
      return request.serializeBinary();
    },
    protos_reportAnalytics_pb.ReportsByCategoryResult.deserializeBinary
  );

  reportsByCategory(
    request: protos_reportAnalytics_pb.ReportsByCategoryMessage,
    metadata: grpcWeb.Metadata | null): Promise<protos_reportAnalytics_pb.ReportsByCategoryResult>;

  reportsByCategory(
    request: protos_reportAnalytics_pb.ReportsByCategoryMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_reportAnalytics_pb.ReportsByCategoryResult) => void): grpcWeb.ClientReadableStream<protos_reportAnalytics_pb.ReportsByCategoryResult>;

  reportsByCategory(
    request: protos_reportAnalytics_pb.ReportsByCategoryMessage,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_reportAnalytics_pb.ReportsByCategoryResult) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/AnalyticsService/ReportsByCategory',
        request,
        metadata || {},
        this.methodInfoReportsByCategory,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/AnalyticsService/ReportsByCategory',
    request,
    metadata || {},
    this.methodInfoReportsByCategory);
  }

  methodInfoReportsByCategoryCustom = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_reportAnalytics_pb.ReportsByCategoryCustomResult,
    (request: protos_reportAnalytics_pb.ReportsByCategoryCustomMessage) => {
      return request.serializeBinary();
    },
    protos_reportAnalytics_pb.ReportsByCategoryCustomResult.deserializeBinary
  );

  reportsByCategoryCustom(
    request: protos_reportAnalytics_pb.ReportsByCategoryCustomMessage,
    metadata: grpcWeb.Metadata | null): Promise<protos_reportAnalytics_pb.ReportsByCategoryCustomResult>;

  reportsByCategoryCustom(
    request: protos_reportAnalytics_pb.ReportsByCategoryCustomMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_reportAnalytics_pb.ReportsByCategoryCustomResult) => void): grpcWeb.ClientReadableStream<protos_reportAnalytics_pb.ReportsByCategoryCustomResult>;

  reportsByCategoryCustom(
    request: protos_reportAnalytics_pb.ReportsByCategoryCustomMessage,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_reportAnalytics_pb.ReportsByCategoryCustomResult) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/AnalyticsService/ReportsByCategoryCustom',
        request,
        metadata || {},
        this.methodInfoReportsByCategoryCustom,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/AnalyticsService/ReportsByCategoryCustom',
    request,
    metadata || {},
    this.methodInfoReportsByCategoryCustom);
  }

  methodInfoReportsResponseTime = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_reportAnalytics_pb.ReportsResponseTimeResult,
    (request: protos_reportAnalytics_pb.ReportsResponseTimeMessage) => {
      return request.serializeBinary();
    },
    protos_reportAnalytics_pb.ReportsResponseTimeResult.deserializeBinary
  );

  reportsResponseTime(
    request: protos_reportAnalytics_pb.ReportsResponseTimeMessage,
    metadata: grpcWeb.Metadata | null): Promise<protos_reportAnalytics_pb.ReportsResponseTimeResult>;

  reportsResponseTime(
    request: protos_reportAnalytics_pb.ReportsResponseTimeMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_reportAnalytics_pb.ReportsResponseTimeResult) => void): grpcWeb.ClientReadableStream<protos_reportAnalytics_pb.ReportsResponseTimeResult>;

  reportsResponseTime(
    request: protos_reportAnalytics_pb.ReportsResponseTimeMessage,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_reportAnalytics_pb.ReportsResponseTimeResult) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/AnalyticsService/ReportsResponseTime',
        request,
        metadata || {},
        this.methodInfoReportsResponseTime,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/AnalyticsService/ReportsResponseTime',
    request,
    metadata || {},
    this.methodInfoReportsResponseTime);
  }

  methodInfoReportUniqueUserCount = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_reportAnalytics_pb.ReportsUniqueUsersResult,
    (request: protos_reportAnalytics_pb.ReportsUniqueUsersMessage) => {
      return request.serializeBinary();
    },
    protos_reportAnalytics_pb.ReportsUniqueUsersResult.deserializeBinary
  );

  reportUniqueUserCount(
    request: protos_reportAnalytics_pb.ReportsUniqueUsersMessage,
    metadata: grpcWeb.Metadata | null): Promise<protos_reportAnalytics_pb.ReportsUniqueUsersResult>;

  reportUniqueUserCount(
    request: protos_reportAnalytics_pb.ReportsUniqueUsersMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_reportAnalytics_pb.ReportsUniqueUsersResult) => void): grpcWeb.ClientReadableStream<protos_reportAnalytics_pb.ReportsUniqueUsersResult>;

  reportUniqueUserCount(
    request: protos_reportAnalytics_pb.ReportsUniqueUsersMessage,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_reportAnalytics_pb.ReportsUniqueUsersResult) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/AnalyticsService/ReportUniqueUserCount',
        request,
        metadata || {},
        this.methodInfoReportUniqueUserCount,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/AnalyticsService/ReportUniqueUserCount',
    request,
    metadata || {},
    this.methodInfoReportUniqueUserCount);
  }

  methodInfoReportsStatsByUsers = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_reportAnalytics_pb.ReportsStatsByUsersResult,
    (request: protos_reportAnalytics_pb.ReportsStatsByUsersMessage) => {
      return request.serializeBinary();
    },
    protos_reportAnalytics_pb.ReportsStatsByUsersResult.deserializeBinary
  );

  reportsStatsByUsers(
    request: protos_reportAnalytics_pb.ReportsStatsByUsersMessage,
    metadata: grpcWeb.Metadata | null): Promise<protos_reportAnalytics_pb.ReportsStatsByUsersResult>;

  reportsStatsByUsers(
    request: protos_reportAnalytics_pb.ReportsStatsByUsersMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_reportAnalytics_pb.ReportsStatsByUsersResult) => void): grpcWeb.ClientReadableStream<protos_reportAnalytics_pb.ReportsStatsByUsersResult>;

  reportsStatsByUsers(
    request: protos_reportAnalytics_pb.ReportsStatsByUsersMessage,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_reportAnalytics_pb.ReportsStatsByUsersResult) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/AnalyticsService/ReportsStatsByUsers',
        request,
        metadata || {},
        this.methodInfoReportsStatsByUsers,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/AnalyticsService/ReportsStatsByUsers',
    request,
    metadata || {},
    this.methodInfoReportsStatsByUsers);
  }

}

