import React from "react";
import { useTranslation } from "react-i18next";

import { Add, Close } from "../../../assets/svg/index";
import { ReportTypes } from "../../../redux/reportTypes/reportsReducer";

interface TileReportTypesProps {
  reportTypeGroup: ReportTypes;
  ignoreList: string[];
  typeFlag: "ADD" | "REMOVE" | "SHOW";
  changeTagsR?: any;
}

export const TileReportTypes: React.FunctionComponent<TileReportTypesProps> = ({
  reportTypeGroup,
  ignoreList,
  typeFlag,
  changeTagsR,
}: TileReportTypesProps) => {
  const { t } = useTranslation();

  const removeFromList = (
    father: string,
    id: string,
    sons: string[],
    all?: string
  ) => {
    const newList: string[] = ignoreList;
    if (all !== undefined) {
      if (typeFlag === "ADD") {
        sons.forEach((d) => {
          const index = ignoreList.indexOf(d);
          if (index === -1) {
            newList.push(d);
          }
        });
        const indexF = ignoreList.indexOf(father);
        if (indexF === -1) {
          newList.push(father);
        }
      } else if (typeFlag === "REMOVE") {
        sons.forEach((d) => {
          const index = ignoreList.indexOf(d);
          if (index !== -1) {
            newList.splice(index, 1);
          }
        });
        const indexF = ignoreList.indexOf(father);
        if (indexF !== -1) {
          newList.splice(indexF, 1);
        }
      }
    } else {
      const index = ignoreList.indexOf(id);
      if (index === -1) {
        newList.push(id);
        const indexF = ignoreList.indexOf(father);
        if (indexF === -1) {
          newList.push(father);
        }
      } else {
        newList.splice(index, 1);
        if (
          sons.filter((d) => {
            if (newList.indexOf(d) !== -1) {
              return d;
            }
          }).length === 0
        ) {
          const indexF = ignoreList.indexOf(father);
          if (indexF !== -1) {
            newList.splice(indexF, 1);
          }
        }
      }
    }

    changeTagsR(newList);
  };

  return (
    <div className="report-type-group">
      <div className="title-box">
        <p>{reportTypeGroup[t("language")]}</p>
        <div
          className="tile-edit-button"
          onClick={() => {
            if (typeFlag !== "SHOW") {
              removeFromList(
                reportTypeGroup.reporttypeid,
                "",
                reportTypeGroup.reportTypes?.map((e) => e.reporttypeid) || [],
                ""
              );
            }
          }}
        >
          {typeFlag === "ADD" && <Add></Add>}
          {typeFlag === "REMOVE" && <Close></Close>}
        </div>
      </div>
      {reportTypeGroup.reportTypes !== undefined &&
        reportTypeGroup.reportTypes.length > 0 && (
          <div className="list-son-report-types">
            {reportTypeGroup.reportTypes?.map((d: ReportTypes) => {
              if (
                typeFlag === "ADD" &&
                ignoreList.indexOf(d.reporttypeid) === -1
              )
                return (
                  <div className="son-item" key={d.reporttypeid}>
                    <p>{d[t("language")]}</p>
                    <div
                      className="tile-edit-button"
                      onClick={() => {
                        removeFromList(
                          reportTypeGroup.reporttypeid,
                          d.reporttypeid,
                          reportTypeGroup.reportTypes?.map(
                            (e) => e.reporttypeid
                          ) || []
                        );
                      }}
                    >
                      {<Add></Add>}
                    </div>
                  </div>
                );
              else if (
                typeFlag === "REMOVE" &&
                ignoreList.indexOf(d.reporttypeid) !== -1
              )
                return (
                  <div className="son-item" key={d.reporttypeid}>
                    <p>{d[t("language")]}</p>
                    <div
                      className="tile-edit-button"
                      onClick={() => {
                        removeFromList(
                          reportTypeGroup.reporttypeid,
                          d.reporttypeid,
                          reportTypeGroup.reportTypes?.map(
                            (e) => e.reporttypeid
                          ) || []
                        );
                      }}
                    >
                      {<Close></Close>}
                    </div>
                  </div>
                );
              if (
                typeFlag === "SHOW" &&
                ignoreList.indexOf(d.reporttypeid) !== -1
              )
                return (
                  <div className="son-item" key={d.reporttypeid}>
                    <p>{d[t("language")]}</p>
                    <div className="tile-edit-button" onClick={() => {}}></div>
                  </div>
                );
            })}
          </div>
        )}
    </div>
  );
};

export default TileReportTypes;
