
export type ChatsMessagesResponse = ChatMessageResponse[];

export interface ChatRequest {
  _id: string;
}

export interface ChatMessageResponse {
  id: string;
  messageBody: string;
  messageFile: string;
  roomId: string;
  sentBy: string;
  senderName: string;
  senderImage: string;
  createdAt: Date;
  viewedBy: string[];
  flag: MessageFlag;
  messageType: MessageType;
}

export enum MessageFlag {
  NOFLAG = 0,
  PROBLEM = 1,
}

export enum MessageType {
  TEXT = 0,
  IMAGE = 1,
  AUDIO = 2,
}

export interface TimestampChat {
  seconds: number;
  nanos: number;
}

