import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ImageUploader from "react-images-upload";
import { useTranslation } from "react-i18next";
import resizeImage from "resize-image";
import moment from "moment";
import "./style.scss";

import { grpcCreateLicense } from "../../redux/licenses/licensesActions";

import TextFieldComponent from "../components/text-field-component";
import ActionButtons from "../components/Action-buttons";
import HeaderDialog from "../components/Header-dialog";
import BitMap from "../../assets/img/profile-image.png";
import { onlyLettersNumbersSpaces } from "../../utils/utils";

interface ProfileDialogSuperAdminProps {
  onCloseRequest: any;
  fetchAllLicenses: any;
}

export const ProfileDialogSuperAdmin: React.FunctionComponent<ProfileDialogSuperAdminProps> =
  ({ onCloseRequest, fetchAllLicenses }: any) => {
    let defaultValue: any;

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [state, setState] = useState({
      isAvailable: true,
      name: "",
      tempImg: defaultValue,
      tempUrl: defaultValue,
    });

    const addProfile = () => {
      handleForm("isAvailable", false);
      //NEEDS TO CHECK EXISTS CITY NAME
      dispatch(
        grpcCreateLicense(
          {
            name: state.name.trim(),
            population: 0,
            countryCode: "",
            cellphone: "",
            imagePath: !state.tempUrl ? "" : state.tempUrl,
            createdAt: moment.utc(new Date()).format(),
            endAt: "",
          },
          () => {
            onCloseRequest();
            fetchAllLicenses();
          }
        )
      );
    };

    const handleForm = (name: any, event: any) => {
      setState((prevState) => ({
        ...prevState,
        [name]: event,
      }));
    };

    const getValidSubmit = () => {
      if (state.name) {
        return true;
      } else {
        return false;
      }
    };

    const uploadImageProfile = (value: any) => {
      validateExtension(value[value.length - 1])
        ? convertImageToBase64(value[value.length - 1])
        : console.log("error");
    };

    const validateExtension = (document: any) => {
      return /\.(gif|jpg|jpeg|tiff|png)$/i.test(
        document !== undefined &&
          document.name !== undefined &&
          document.name !== null
          ? document.name
          : ""
      );
    };

    const convertImageToBase64 = (documentFile: any) => {
      const reader = new FileReader();
      reader.onload = (upload: any) => {
        let dataUrl = upload.target.result;
        let image = new Image();
        image.src = dataUrl;
        image.onload = () => {
          const ImgBase64 = resizeImage.resize(
            image,
            300,
            300,
            resizeImage.JPEG
          );
          // this.uploadToStorageAndGetURL(documentFile, ImgBase64);
          setState((prevState) => ({
            ...prevState,
            tempImg: ImgBase64 ? ImgBase64 : documentFile,
            tempUrl: ImgBase64 ? ImgBase64 : documentFile,
            ImgBase64: ImgBase64 ? true : false,
          }));
        };
      };
      reader.readAsDataURL(documentFile);
    };

    return (
      <div className="content-dialog dialog-perfil big-dialog-x15">
        <HeaderDialog
          onCloseRequest={() => {
            onCloseRequest();
          }}
          title={t("title.profile.new")}
          description={""}
          profile={true}
          noBold1={""}
          noBold2={""}
          boldText={""}
          children={
            <div className="foto-perfil-box">
              <div className="title-perfil">{t("name.photo")}</div>
              <div className="foto-perfil-team">
                <img src={!state.tempImg ? BitMap : state.tempImg} alt=""></img>
                <ImageUploader
                  className="file-upload"
                  buttonText="Choose images"
                  withLabel={false}
                  withPreview={false}
                  withIcon={false}
                  onChange={uploadImageProfile}
                  imgExtension={[".gif", ".png", ".gif"]}
                  accept={".png"}
                  maxFileSize={5242880}
                />
                <div className="text">{t("form.photo")}</div>{" "}
              </div>
            </div>
          }
        ></HeaderDialog>
        <div className="content-options">
          <div className="content-options-body tabs-body">
            <div className="options-body-item-big super-city">
              <div className="selector-box full-width-select">
                <TextFieldComponent
                  placeholder={t("name.name")}
                  onChange={(event: any) => {
                    handleForm("name", event);
                  }}
                  value={state.name}
                  regExp={onlyLettersNumbersSpaces}
                  invalid={false}
                  maxValue={20}
                  message={""}
                ></TextFieldComponent>
              </div>
            </div>
            <ActionButtons
              doneText={t("submit.confirm")}
              clickable={getValidSubmit()}
              doneClick={state.isAvailable ? addProfile : defaultValue}
              cancelClick={() => {
                onCloseRequest();
              }}
              cancelText={t("submit.cancel")}
            ></ActionButtons>
          </div>
        </div>
      </div>
    );
  };

export default ProfileDialogSuperAdmin;
