import { TRACKING_ACTIVE_DATES, TRACKING_LIST } from "./trackingTypes";
import {
  Tracker,
  ActiveDatesRequest,
  ByLicenseAndDateRequest,
  ByIdRequest,
} from "../../server/tracking/protos/tracking_pb";
import { TrackingServiceClient } from "../../server/tracking/protos/TrackingServiceClientPb";
import { BACKEND_URL } from "../RootReducer";

const trackingClient = new TrackingServiceClient(BACKEND_URL || "");
const TrackingHeaders: any = {
  /* service_name: "TeamService",
  "Access-Control-Allow-Origin": "*",
  crossdomain: true,
  allow_headers: "*", */
  //Origin: "http://localhost:3000",
};

// export function grpcSuscribeStream(data: any, fun?: any) {
//   return (dispatch: any) => {
//     const reqSSSuscribe = new ByIdRequest();
//     reqSSSuscribe.setDate(data.date);
//     reqSSSuscribe.setLicenseid(data.licenseId);
//     reqSSSuscribe.setUserid(data.userId);

//     trackingClient
//       .subscribe(reqSSSuscribe, TrackingHeaders)
//       .on("data", (response) => {})
//       .on("error", (response) => {
//         if (response == null) {
//           console.log("err  stream", response);
//         } else {
//           // dispatch({
//           //   type: REPORTS_LIST,
//           //   payload: response.toObject(),
//           // });
//           if (fun) {
//             fun();
//           }
//         }
//       });
//   };
// }

export function grpcFetchActiveDates(data: any, fun?: any) {
  return (dispatch: any) => {
    const reqFADFetch = new ActiveDatesRequest();
    reqFADFetch.setLicenseid(data._id);

    trackingClient.getActiveDates(
      reqFADFetch,
      TrackingHeaders,
      (err, response) => {
        if (response == null) {
          console.log("err", err);
        } else {
          const trackingActiveDates: string[] = response.toObject().datesList;

          dispatch({
            type: TRACKING_ACTIVE_DATES,
            payload: trackingActiveDates,
          });
          if (fun) {
            fun();
          }
        }
      }
    );
  };
}

export function grpcFetchTrackers(data: any, fun?: any) {
  return (dispatch: any) => {
    const reqFTFetch = new ByLicenseAndDateRequest();
    reqFTFetch.setLicenseid(data._id);
    reqFTFetch.setDate(data.date);

    trackingClient.getByLicenseAndDate(
      reqFTFetch,
      TrackingHeaders,
      (err, response) => {
        if (response == null) {
          console.log("err", err);
        } else {
          const trackersList: Tracker.AsObject[] =
            response.toObject().trackersList;

          dispatch({
            type: TRACKING_LIST,
            payload: trackersList,
          });
          if (fun) {
            fun(trackersList);
          }
        }
      }
    );
  };
}
