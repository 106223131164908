import React, { useEffect, useState } from "react";
import { createDocument } from "../../../firebase/index";
import { isEmpty } from "lodash";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import TextAreaFieldComponent from "../../components/text-area-field-component";
import SimpleModal from "../../../utils/dialogs/simple-dialog";
import ImgCommentDialog from "../../dialogs/Img-comment-dialog";
import CommentaryPrivate from "./Commentary-private";
import { RootStore } from "../../../redux/Store";
import {
  ChatMessage,
  PendingRoomResponse,
} from "../../../server/chat/protos/chat_pb";
import { Report } from "../../../server/report/protos/report_pb";
import { Team } from "../../../server/team/protos/team_pb";
import { licenseI } from "../../../redux/licenses/licensesReducer";
import {
  grpcFetchChatMessages,
  grpcMarkAsViewed,
  grpcSendTaskMessage,
} from "../../../redux/chat/chatAction";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { SET_ROOM_ID, SET_UNSEEN_ROOMS } from "../../../redux/chat/chatTypes";

interface ChatReportProps {
  notifications: any;
  refComments: any;
  t: any;
  markerReport: Report.AsObject;
  editCommentary: any;
  deleteCommentaryConfirmation: any;
  currentDistrict: licenseI | undefined;
  team: Team.AsObject | undefined;
  assignedWorkers: any;
}

export const ChatReport: React.FunctionComponent<ChatReportProps> = ({
  notifications,
  refComments,
  t,
  markerReport,
  editCommentary,
  deleteCommentaryConfirmation,
  currentDistrict,
  team,
  assignedWorkers,
}: ChatReportProps) => {
  //const exampleRef = createRef<HTMLDivElement>();
  let defaultValue: any;

  const chat: ChatMessage.AsObject[] = useSelector(
    (globalState: RootStore) => globalState.chat.chat
  );

  const pendingRooms: PendingRoomResponse.UnseenRoom.AsObject[] = useSelector(
    (globalState: RootStore) => globalState.chat.pendingRooms
  );

  const dispatch = useDispatch();
  const [state, setState] = useState({
    comment: "",
    commentValid: false,
    image: defaultValue,
    showModalImg: false,
  });

  useEffect(() => {
    // dispatch(grpcMarkAsViewed({
    // }));
  }, []);

  useEffect(() => {
    const array: string[] = [];
    if (chat.length > 0) {
      chat.forEach((d) => {
        if (d.viewedByList.indexOf(currentDistrict?._id || "") === -1) {
          array.push(d.id);
        }
      });
      dispatch(
        grpcMarkAsViewed({
          messageIds: array,
          userId: currentDistrict?._id || "",
        })
      );
      const arrayPending: PendingRoomResponse.UnseenRoom.AsObject[] =
        pendingRooms.filter((d) => {
          if (d.roomId !== markerReport.reportid) return d;
        });
      dispatch({
        type: SET_UNSEEN_ROOMS,
        payload: arrayPending,
      });
    }
  }, [chat]);

  const handleForm = (name: any, event: any) => {
    const value = event.target.value;
    event.preventDefault();
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const checkValidFormToSubmit = () => {
    if (!isEmpty(state.comment)) {
      //   this.setState({ commentValid: true });
      let array: string[] = [];
      let time = new Timestamp();
      time.fromDate(moment(Date.now()).toDate());

      array.push(currentDistrict?._id || "");
      if (team && team.membersList) array.push(...team.membersList);
      if (markerReport.assignedWorkersList)
        array.push(...markerReport.assignedWorkersList);

      dispatch(
        grpcSendTaskMessage(
          array,
          {
            id: "",
            messageBody: state.comment,
            messageFile: state.image || "",
            messageType: ChatMessage.MessageType.TEXT,
            roomId: markerReport.reportid,
            sentBy: currentDistrict?._id || "",
            senderName: currentDistrict?.name || "",
            senderImage:
              currentDistrict?.imagePath ||
              "https://miciudad.s3-us-west-1.amazonaws.com/profile-image.png",
            viewedByList: [currentDistrict?._id || ""],
            flag: 0,
          },
          time,
          () => {
            setState((prevState) => ({
              ...prevState,
              comment: "",
            }));
            if (refComments?.current)
              refComments.current.scrollTop = refComments.current.scrollHeight;
          }
        )
      );
      // createDocument(
      //   "notifications",
      //   {
      //     newState: markerReport.reportState,
      //     notificationType: "messageNotification",
      //     notificationImage: !currentDistrict.photoUrl
      //       ? "https://miciudad.s3-us-west-1.amazonaws.com/profile-image.png"
      //       : currentDistrict.photoUrl,
      //     districtId: currentDistrict._id,
      //     report: markerReport._id,
      //     reportType: markerReport.type,
      //     reportTitle: markerReport.description,
      //     reportImage: markerReport.imageUrl,
      //     title: currentDistrict.name,
      //     body: state.comment,
      //     created: moment().toISOString(),
      //     userIds: array,
      //     viewed: false,
      //   },
      //   () => {
      //     setState((prevState) => ({
      //       ...prevState,
      //       comment: "",
      //     }));
      //     /* () => { */
      //     refComments.current.scrollTop = refComments.current.scrollHeight;
      //     /* } */
      //     // this.props.onCloseRequest();
      //     // this.props.getNotifications(this.props.markerReport);
      //   }
      // );
    }
  };

  const handleToggleModalImg = () => {
    setState((prevState) => ({
      ...prevState,
      showModalImg: !state.showModalImg,
    }));
  };

  const setImage = (image: any) => {
    setState((prevState) => ({
      ...prevState,
      image: image,
    }));
    /* () => { */
    handleToggleModalImg();
    /* } */
  };

  const onKeyDown = (event: any) => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      checkValidFormToSubmit();
    }
  };

  const getCommentary = (item: ChatMessage.AsObject, i: any) => {
    return (
      <CommentaryPrivate
        key={i}
        notification={item}
        t={t}
        type={2}
        markerReport={markerReport}
        editCommentary={editCommentary}
        deleteCommentaryConfirmation={deleteCommentaryConfirmation}
        currentDistrict={currentDistrict}
        setImage={setImage}
      ></CommentaryPrivate>
    );
  };

  useEffect(() => {
    setTimeout(function () {
      let scrollDiv = document.querySelector(".commentary-body-private");
      if (scrollDiv) {
        scrollDiv.scrollTop = scrollDiv.scrollHeight;
      }
    }, 101);
  }, []);

  return (
    <div className="private-comment-box">
      <div className="commentary-body-private" ref={refComments}>
        {chat.map(function (item: ChatMessage.AsObject, i: any) {
          return getCommentary(item, i);
        })}
        <div className="empty-box"></div>
      </div>
      <div className="add-comment-box">
        <div className="box-edit">
          <img
            src={
              currentDistrict?.imagePath
                ? currentDistrict.imagePath
                : "https://miciudad.s3-us-west-1.amazonaws.com/profile.png"
            }
            alt=""
          />
          <div className="options-body-item-big text-area-box">
            <div className="selector-box full-width-select">
              <TextAreaFieldComponent
                placeholder={t("private.comment.placeholder")}
                value={state.comment}
                onChange={(event: any) => handleForm("comment", event)}
                invalid={state.commentValid}
                onKeyDown={onKeyDown}
                message={t("name.commentary.invalid")}
              ></TextAreaFieldComponent>
            </div>
          </div>
        </div>
        <div className="box-button">
          <div className="comment-btn" onClick={checkValidFormToSubmit}>
            {t("private.comment.add")}
          </div>
        </div>
      </div>
      {state.showModalImg && (
        <SimpleModal onCloseRequest={() => handleToggleModalImg()}>
          <ImgCommentDialog
            t={t}
            image={state.image}
            onCloseRequest={() => handleToggleModalImg()}
          ></ImgCommentDialog>
        </SimpleModal>
      )}
    </div>
  );
};

export default ChatReport;
