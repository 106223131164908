import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  pending,
  inProgress,
  limpieza,
  riego,
  poda,
  pintura,
  parques,
  alumbrado,
  basura,
  desazolve,
  escombros,
  REPORT_PROGRESS_ASSIGNED,
  REPORT_PROGRESS_PROGRESS,
} from "../../../const/const";

import {
  // DeleteIcon,
  // AssignIcon,
  PlaceIcon,
  // UserIcon,
  // ArrowIcon,
  // LikeIcon,
  // InfoIcon,
  // CloseIcon,
  Options,
  // UnGroup,
} from "../../../assets/svg/index.js";
import {
  Report,
  ReportStatus,
  ReportWorkStatus,
  WorkerLog,
} from "../../../server/report/protos/report_pb";
import { RootStore } from "../../../redux/Store";
import { ReportTypes } from "../../../redux/reportTypes/reportsReducer";
import { useTranslation } from "react-i18next";

interface TileReportProps {
  item: Report.AsObject;
  addOrRemoveReportToGroup: any;
}

export const TileReport: React.FunctionComponent<TileReportProps> = ({
  item,
  addOrRemoveReportToGroup,
}: TileReportProps) => {
  let defaultValue: any;
  const { t } = useTranslation();

  const reportTypes: ReportTypes[] = useSelector(
    (globalState: RootStore) => globalState.reportTypes.reportTypesGeneral
  );

  const [state, setState] = useState({
    showDialog: false,
  });

  const getMarker = () => {
    let reportType = reportTypes.find((e: ReportTypes) => {
      return e.reporttypeid === item.reporttypeid;
    });
    if (reportType) return reportType.iconmap;
    else return defaultValue;
  };

  const getType = () => {
    let value = reportTypes.find(
      (e: ReportTypes) => e.reporttypeid === item.reporttypeid
    );
    if (value) {
      return value[t("language")];
    } else return "";
  };

  const showDialog = () => {
    setState((prevState) => ({
      ...prevState,
      showDialog: !state.showDialog,
    }));
  };

  const getClass = () => {
    let valid = false;
    if (item.reportstatus === ReportStatus.REPORT_PENDING) {
      return "marker-by-type  reported-type-2";
    } else if (
      item.reportstatus === ReportStatus.REPORT_IN_PROGRESS &&
      item.workstatus === ReportWorkStatus.WORK_ASSIGNED
    ) {
      return "marker-by-type assigned-type-2";
    } else if (
      item.reportstatus === ReportStatus.REPORT_IN_PROGRESS &&
      item.workstatus === ReportWorkStatus.WORK_IN_PROGRESS
    ) {
      if (item.workerlogsList) {
        item.workerlogsList.forEach((e: WorkerLog.AsObject) => {
          if (e.status === WorkerLog.WorkerLogStatus.STATUS_UNKNOWN)
            valid = true;
        });
      }
      if (valid) return "marker-by-type  problem-type-2";
      else return "marker-by-type inprogress-type-2";
    } else {
      return "marker-by-type completed-type-2";
    }
  };

  return (
    <div className="report-group-box">
      <div className="content-report-group">
        <div className="type-report-group pending-marker">
          <div className={getClass()}>
            {getMarker() && (
              <input type="image" src={getMarker()} alt=""></input>
            )}
          </div>
          {getType()}
        </div>
        <div className="description-report-group">{item.description}</div>
        <div>
          <div className="data-address-box ">
            <PlaceIcon></PlaceIcon>
            <p>{item.address}</p>
          </div>
        </div>
      </div>
      <div className="menu-report-group">
        <Options className="option-button" onClick={showDialog}></Options>
      </div>
      {state.showDialog && (
        <div className="menu-report-tile">
          <div
            onClick={() => {
              addOrRemoveReportToGroup(item);
            }}
          >
            {t("name.remove.group")}
          </div>
        </div>
      )}
    </div>
  );
};

export default TileReport;
