import React from "react";
import "./style.scss";
import { css } from "@emotion/react";
// First way to import
// Another way to import. This is recommended to reduce bundle size
import ScaleLoader from "react-spinners/ScaleLoader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: #0d3768;
`;

class LoadingDialog extends React.Component {
  render() {
    return (
      <div className="sweet-loading">
        <ScaleLoader
          css={override}
          sizeUnit={"px"}
          size={150}
          color={"#123abc"}
          loading={true}
        />
      </div>
    );
  }
}

export default LoadingDialog;
