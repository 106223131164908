import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../redux/Store";
import { useTranslation } from "react-i18next";

import { grpcRemoveJobtypeLicense } from "../../../redux/licenses/licensesActions";
import { grpcDeleteReportType } from "../../../redux/reportTypes/reportsActions";
import { ReportTypes } from "../../../redux/reportTypes/reportsReducer";

import TileReportTypesSave from "./save-tile-report-types";
import ItemGroup from "./item-group";
import SimpleModal from "../../../utils/dialogs/simple-dialog";
import ConfirmationDialog from "../../dialogs/confirmation-dialog";

interface SettingsPage {
  fetchAllReportTypes: any;
}

export const ListReportTypes: React.FunctionComponent<SettingsPage> = ({
  fetchAllReportTypes,
}: any) => {
  const reportTypes = useSelector(
    (globalState: RootStore) => globalState.reportTypes.reportTypes
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();
  let defaultValue: ReportTypes | undefined;

  const [state, setState] = useState({
    showEditMode: false,
    showModalAdd: false,
    showModalDelete: false,
    reportType: defaultValue,
    reportTypeFather: defaultValue,
    reportTypeDelete: defaultValue,
  });

  const columns = [
    { id: 0, label: "tabs.map", minWidth: 40 },
    { id: 1, label: "name.report", minWidth: 40 },
    { id: 2, label: "languages.italian", align: "left", minWidth: 60 },
    { id: 3, label: "languages.spanish", align: "left", minWidth: 60 },
    { id: 4, label: "languages.english", align: "left", minWidth: 60 },
    { id: 5, label: "languages.portuguese", align: "left", minWidth: 60 },
    { id: 6, label: "languages.french", align: "left", minWidth: 60 },
    {
      id: 7,
      label: "",
      minWidth: 20,
      align: "right",
      format: (value: any) => value.toFixed(2),
    },
  ];

  const handleToggleModalDelete = (
    value: boolean,
    reportType?: ReportTypes
  ) => {
    setState((prevState) => ({
      ...prevState,
      showModalDelete: value,
      reportTypeDelete: reportType,
    }));
  };

  const handleToggleManage = (
    edit: boolean,
    value: boolean,
    reportType?: ReportTypes
  ) => {
    if (edit)
      setState((prevState) => ({
        ...prevState,
        showEditMode: value,
        reportType: reportType,
        reportTypeFather: undefined,
      }));
    else
      setState((prevState) => ({
        ...prevState,
        showEditMode: value,
        reportTypeFather: reportType,
      }));
  };

  const ignoreReportTypeFromLicenses = () => {
    dispatch(
      grpcRemoveJobtypeLicense({ _id: state.reportTypeDelete?.reporttypeid })
    );
  };

  const deleteReportType = () => {
    dispatch(
      grpcDeleteReportType(state.reportTypeDelete?.reporttypeid || "", () => {
        ignoreReportTypeFromLicenses();
        fetchAllReportTypes();
        handleToggleModalDelete(false);

        if (
          state.reportTypeDelete?.reporttypeid ===
          state.reportType?.reporttypeid
        ) {
          handleToggleManage(true, false);
        }
      })
    );
  };

  return (
    <div className="body-list-report-types">
      <div className="top-settings-page">
        <div className="amount-of-reports">
          <span>{reportTypes.length}</span> {t("title.type.of.reports")}
        </div>
        <div>
          {" "}
          <button
            className="btn-done-dialog-admin"
            onClick={() => {
              handleToggleManage(true, true);
            }}
          >
            {t("subtitle.add")}
          </button>
        </div>
      </div>
      {state.showEditMode && (
        <TileReportTypesSave
          key={Math.random().toString(36).substring(7)}
          handleToggleManage={handleToggleManage}
          reportTypeFather={state.reportTypeFather}
          reportType={state.reportType}
          onCloseRequest={() => {
            fetchAllReportTypes();
          }}
        >
          {" "}
        </TileReportTypesSave>
      )}
      <div className="item-header">
        <div className="column-1">
          <h3> {t(columns[0].label)}</h3>
        </div>
        <div className="column-2">
          <h3> {t(columns[1].label)}</h3>
        </div>
        <div className="column-3">
          <h3> {t(columns[2].label)}</h3>
        </div>
        <div className="column-4">
          <h3> {t(columns[3].label)}</h3>
        </div>
        <div className="column-5">
          <h3> {t(columns[4].label)}</h3>
        </div>
        <div className="column-6">
          <h3> {t(columns[5].label)}</h3>
        </div>
        <div className="column-7">
          <h3> {t(columns[6].label)}</h3>
        </div>
        <div className="column-8">
          <h3> {t(columns[7].label)}</h3>
        </div>
      </div>
      <div className="item-lists">
        {reportTypes.map((e: ReportTypes) => {
          return (
            <ItemGroup
              key={e.reporttypeid}
              reportTypeGroup={e}
              handleToggleModalDelete={handleToggleModalDelete}
              handleToggleManage={handleToggleManage}
            ></ItemGroup>
          );
        })}
      </div>
      {state.showModalDelete && (
        <SimpleModal>
          <ConfirmationDialog
            t={t}
            doneClick={deleteReportType}
            description={t("notification.delete.report.type")}
            onCloseRequest={() => {
              handleToggleModalDelete(false, state.reportTypeDelete);
            }}
          ></ConfirmationDialog>
        </SimpleModal>
      )}
    </div>
  );
};

export default ListReportTypes;
