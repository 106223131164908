import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Report } from "../../../server/report/protos/report_pb";
import FilterStatus from "./filter-status";
import FilterSettingsTab from "./FIlterSettingsTab";
import FilterPendientChats from "./FilterPendientChats";

interface FilterCardProps {
  reports: Report.AsObject[];
  menuOpen: boolean;
  changeMenuState: () => void;
  onHide: () => void;
  onOpenReport: (report: Report.AsObject | undefined) => void;
}

enum TypeFilter {
  filters = 0,
  favorites = 1,
  close = 2,
  chats = 3,
}

export const FilterCard: React.FunctionComponent<FilterCardProps> = ({
  reports,
  menuOpen,
  changeMenuState,
  onHide,
  onOpenReport,
}: FilterCardProps) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(TypeFilter.filters);
  return (
    <div
      className={
        menuOpen ? "filter-status-box" : "filter-status-box filter-hidden"
      }
    >
      <div
        className={selected === TypeFilter.filters ? "filters-tab active" : "filters-tab"}
        onClick={() => {
          setSelected(selected === TypeFilter.filters ? TypeFilter.close : TypeFilter.filters);
          if (selected === TypeFilter.filters || selected === TypeFilter.close) {
            changeMenuState();
            onHide();
          }
        }}
      >
        {t("name.filters")}
      </div>

      <div
        className={
          selected === 1 ? "setting-filters active" : "setting-filters"
        }
        onClick={() => {
          setSelected(selected === TypeFilter.favorites ? TypeFilter.close : TypeFilter.favorites);
          if (selected === TypeFilter.favorites || selected === TypeFilter.close) {
            changeMenuState();
            onHide();
          }
        }}
      >
        {t("name.favorites")}
      </div>

      <div
        className={
          selected === TypeFilter.chats ? "chat-filters active" : "chat-filters"
        }
        onClick={() => {
          setSelected(selected === TypeFilter.chats ? TypeFilter.close : TypeFilter.chats);
          if (selected === TypeFilter.chats || selected === TypeFilter.close) {
            changeMenuState();
            onHide();
          }
        }}
      >
        {t("name.chatsPendients")}
      </div>

      {selected === TypeFilter.filters && (
        <FilterStatus
          reports={reports}
          menuOpen={menuOpen}
          changeMenuState={changeMenuState}
        ></FilterStatus>
      )}
      {selected === TypeFilter.favorites && <FilterSettingsTab></FilterSettingsTab>}
      {selected === TypeFilter.chats && <FilterPendientChats onOpenReport={onOpenReport}></FilterPendientChats>}
    </div>
  );
};

export default FilterCard;
