import React, { useState } from "react";
import "./style.scss";
import { NavLink, useLocation } from "react-router-dom";
import { licenseI } from "../../redux/licenses/licensesReducer";
import { RootStore } from "../../redux/Store";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
interface NavBarSuperComponentProps {}

export const NavBarSuperComponent: React.FunctionComponent<NavBarSuperComponentProps> =
  ({}: NavBarSuperComponentProps) => {
    const [visible, setState] = useState<boolean>(false);
    const selectedLicense: licenseI | undefined = useSelector(
      (globalState: RootStore) => globalState.licenses.selectedLicense
    );
    const { t } = useTranslation();

    const location = useLocation();
    const addMenu = () => {
      setState(true);
    };

    const removeMenu = () => {
      setState(false);
    };

    /// Nav
    return (
      <div>
        <div id="navbar-super" className="navBar">
          <NavLink
            to="/main"
            className={
              (location.pathname === "/main" ? "active-menu-super" : "") +
              " nav-item profile nav-item-super"
            }
          >
            <img
              src={
                selectedLicense?.imagePath
                  ? selectedLicense.imagePath
                  : "https://miciudad.s3-us-west-1.amazonaws.com/profile-image.png"
              }
              alt=""
              className="logo"
            />
            <p className="title-menu">{t("title.profile")}</p>
          </NavLink>

          <NavLink
            to="/"
            className={
              (location.pathname === "/" ? "active-menu-super" : "") +
              " nav-item dashboard nav-item-super"
            }
          >
            <div className="logo" />
            <p className="title-menu">{t("title.dashboard")}</p>
          </NavLink>

          <NavLink
            to="/reports"
            className={
              (location.pathname === "/reports" ||
              location.pathname === "/reports/pendientes" ||
              location.pathname === "/reports/enproceso" ||
              location.pathname === "/reports/solucionado"
                ? "active-menu-super"
                : "") + " nav-item reports nav-item-super"
            }
            // onMouseEnter={this.addMenu}
            // onMouseLeave={this.removeMenu}
          >
            <div className="logo" />
            <p className="title-menu">{t("title.reports")}</p>
          </NavLink>
          <NavLink
            to="/routing"
            activeClassName="active-menu-super"
            exact
            className="nav-item calendar nav-item-super"
          >
            <div className="logo" />
            <p className="title-menu">{t("title.calendar")}</p>
          </NavLink>
          <NavLink
            to="/trackerMap"
            className={
              (location.pathname === "/trackerMap" ? "active-menu-super" : "") +
              " nav-item routesMap nav-item-super"
            }
            // onMouseEnter={this.addMenu}
            // onMouseLeave={this.removeMenu}
          >
            <div className="logo" />
            <p className="title-menu">{t("title.route")}</p>
          </NavLink>
          <NavLink
            to="/workers"
            activeClassName="active-menu-super"
            exact
            className="nav-item users  nav-item-super"
          >
            <div className="logo" />
            <p className="title-menu">{t("title.workers")}</p>
          </NavLink>
          <NavLink
            to="/teams"
            activeClassName="active-menu-super"
            exact
            className="nav-item teams  nav-item-super"
          >
            <div className="logo" />
            <p className="title-menu">{t("title.teams")}</p>
          </NavLink>

          <NavLink
            to="/blocked"
            activeClassName="active-menu-super"
            exact
            className="nav-item blocked  nav-item-super"
          >
            <div className="logo" />
            <p className="title-menu">{t("title.users")}</p>
          </NavLink>
        </div>
        <div
          className={` ${visible ? "enable" : "disable"} drop-menu`}
          onMouseEnter={addMenu}
          onMouseLeave={removeMenu}
        >
          <NavLink to="/reports/pendientes">
            <div
              className={
                (location.pathname === "/reports/pendientes"
                  ? "drop-item-selected"
                  : "") + " drop-item"
              }
            >
              <p>Pendientes</p>
            </div>
          </NavLink>
          <NavLink to="/reports/enproceso">
            <div
              className={
                (location.pathname === "/reports/enproceso"
                  ? "drop-item-selected"
                  : "") + " drop-item"
              }
            >
              <p>En Proceso</p>
            </div>
          </NavLink>
          <NavLink to="/reports/solucionado">
            <div
              className={
                (location.pathname === "/reports/solucionado"
                  ? "drop-item-selected"
                  : "") + " drop-item"
              }
            >
              <p>Solucionado</p>
            </div>
          </NavLink>
        </div>
      </div>
    );
  };

export default NavBarSuperComponent;
