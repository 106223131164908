import React from "react";
import "./App.css";
import { Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Routes from "./routes/routes";
import RoutesSuperAdmin from "./routes/routeSuperAdmin";
import Login from "./views/login-page";
import withAuthentication from "./session/withAuthentication";
import { css } from "@emotion/react";
import ScaleLoader from "react-spinners/ScaleLoader";
import { useTranslation } from "react-i18next";
import { changeLanguageS } from "./firebase/auth";
import "./i18n.js";
import { withCookies } from "react-cookie";
import { onMessageListener } from "./firebase/config";

import { RootStore } from "./redux/Store";
import { useSelector } from "react-redux";
import history from './history';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: #0d3768;
`;

const InitialRoute: React.FC = (props: any) => {
  const authenticated = useSelector(
    (globalState: RootStore) => globalState.auth.authenticated
  );

  const superAdmin = useSelector(
    (globalState: RootStore) => globalState.auth.superAdmin
  );

  const { t, i18n } = useTranslation();
  const [show, setShow] = React.useState(false);
  const [notification, setNotification] = React.useState({
    title: "",
    body: "",
  });

  // getToken(setTokenFound);

  onMessageListener()
    .then((payload) => {
      setShow(true);
      console.log(payload);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
    })
    .catch((err) => console.log("failed: ", err));

  const changeLanguage = (value: any) => {
    i18n.changeLanguage(value);
    changeLanguageS(value);
  };
  /* if (props.loadingUser) return renderLoader();
  else */
  return (
    <Router history={history}>
      {authenticated ? (
        <RoutesSuperAdmin
          setDistrict={props.setDistrict}
          loading={props.loading}
          loadingDistrict={props.loadingDistrict}
          listOfDistricts={props.listOfDistricts}
          fetchDistrict={props.fetchDistrict}
          fetchData={props.fetchData}
          currentDistrict={props.currentDistrict}
          adminsOfaDistrict={props.adminsOfaDistrict}
          reports={props.reports}
          teams={props.teams}
          trackers={props.trackers}
          activeDates={props.activeDates}
          workers={props.workers}
          statistics={props.statistics}
          statisticsOld={props.statisticsOld}
          guests={props.guests}
          updateDistrict={props.updateDistrict}
          users={props.users}
          fetchUsers={props.fetchUsers}
          socket={props.socket}
          changeLanguage={changeLanguage}
          reportTypes={props.reportTypes}
          t={t}
          language={i18n.language}
          fetchAllDistricts={props.fetchAllDistricts}
          superData={props.superData}
          handleDeletedDistrict={props.handleDeletedDistrict}
          actualPageProfile={props.actualPageProfile}
          updatePageProfile={props.updatePageProfile}
          fetchSettings={props.fetchSettings}
          trackersYesterday={props.trackersYesterday}
          kanbanKeys={props.kanbanKeys}
          changeSelectedTeam={props.changeSelectedTeam}
          selectedTeam={props.selectedTeam}
          messagesChat={props.messagesChat}
          chats={props.chats}
          countStatistics={props.countStatistics}
          changeFilter={props.changeFilter}
          filtersSelected={props.filtersSelected}
          changeShowTime={props.changeShowTime}
          showTime={props.showTime}
        ></RoutesSuperAdmin>
      ) : (
        <Login> </Login>
      )}
    </Router>
  );
};

export default withCookies(withAuthentication(InitialRoute));
