import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../redux/Store";
import { useTranslation } from "react-i18next";
import moment from "moment";

import {
  REPORTS_FILTER_LIST,
  REPORTS_VIEW_FILTERS,
} from "../../../redux/reportTypes/reportsTypes";
import {
  REPORT_PROGRESS_ASSIGNED,
  REPORT_PROGRESS_PROGRESS,
} from "../../../const/const";

import { DelayedIcon, ProblemIcon } from "../../../assets/img/index";
import { pending, inProgress, done } from "../../../const/const";
import FeatherIcon from "feather-icons-react";
import {
  Report,
  ReportStatus,
  ReportWorkStatus,
} from "../../../server/report/protos/report_pb";
import { licenseI } from "../../../redux/licenses/licensesReducer";
import { User, UserSettings } from "../../../server/user/protos/user_pb";
import TextFieldComponent from "../../components/text-field-component";
import {
  grpcDeleteUserSettings,
  grpcSetUserSettings,
} from "../../../redux/users/usersAction";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import ActionButtons from "../../components/Action-buttons";
import { CloseIcon, DeleteIcon } from "../../../assets/svg";
import { onlyLettersNumbersSpaces } from "../../../utils/utils";

interface FilterSettingsTabProps { }

export const FilterSettingsTab: React.FunctionComponent<FilterSettingsTabProps> =
  ({ }: FilterSettingsTabProps) => {
    const selectedLicense: licenseI | undefined = useSelector(
      (globalState: RootStore) => globalState.licenses.selectedLicense
    );
    const user: User.AsObject | undefined = useSelector(
      (globalState: RootStore) => globalState.auth.user
    );
    const viewFilters: {
      pending: boolean;
      assigned: boolean;
      progress: boolean;
      completed: boolean;
      problem: boolean;
      paused: boolean;
    } = useSelector(
      (globalState: RootStore) => globalState.reportTypes.viewFilters
    );
    const filtersSelected: string[] = useSelector(
      (globalState: RootStore) => globalState.reportTypes.filtersSelected
    );

    const filtersList: UserSettings.AsObject[] = useSelector(
      (globalState: RootStore) => globalState.users.filtersList
    );

    const [state, setState] = React.useState({
      name: "",
    });

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleForm = (name: any, event: any) => {
      setState((prevState) => ({
        ...prevState,
        [name]: event,
      }));
    };

    const saveSettings = () => {
      let time = new Timestamp();
      time.fromDate(moment(Date.now()).toDate());
      const array: string[] = [];
      const arrayReportTypesList: string[] = [...filtersSelected];

      if (viewFilters.assigned) {
        array.push("assigned");
      }
      if (viewFilters.completed) {
        array.push("completed");
      }
      if (viewFilters.paused) {
        array.push("paused");
      }
      if (viewFilters.pending) {
        array.push("pending");
      }
      if (viewFilters.problem) {
        array.push("problem");
      }
      if (viewFilters.progress) {
        array.push("progress");
      }

      dispatch(
        grpcSetUserSettings(
          {
            id: "",
            name: state.name.trim(),
            filtersList: array,
            reporttypesList: arrayReportTypesList,
            createdat: time,
            updatedat: time,
            userid: user?.id || "",
            licenseid: selectedLicense?._id || "",
          },
          () => {
            setState((prevState) => ({
              ...prevState,
              name: "",
            }));
          }
        )
      );
    };

    const setFilters = (item: UserSettings.AsObject) => {
      const filters: {
        pending: boolean;
        assigned: boolean;
        progress: boolean;
        completed: boolean;
        problem: boolean;
        paused: boolean;
      } = {
        pending: false,
        assigned: false,
        progress: false,
        completed: false,
        problem: false,
        paused: false,
      };

      if (item.filtersList.indexOf("assigned") !== -1) {
        filters.assigned = true;
      }
      if (item.filtersList.indexOf("completed") !== -1) {
        filters.completed = true;
      }
      if (item.filtersList.indexOf("paused") !== -1) {
        filters.paused = true;
      }
      if (item.filtersList.indexOf("pending") !== -1) {
        filters.pending = true;
      }
      if (item.filtersList.indexOf("problem") !== -1) {
        filters.problem = true;
      }
      if (item.filtersList.indexOf("progress") !== -1) {
        filters.progress = true;
      }
      dispatch({
        type: REPORTS_VIEW_FILTERS,
        filters: filters,
      });
      dispatch({
        type: REPORTS_FILTER_LIST,
        payload: item.reporttypesList,
      });
    };

    return (
      <div className="settings-box">
        <div className="filter-status-title">{t("name.filters")}</div>
        <div className="filter-list-box">
          {filtersList.map((d: UserSettings.AsObject, index) => {
            return (
              <div className="filter-item" key={index}>
                <DeleteIcon
                  onClick={() => {
                    dispatch(grpcDeleteUserSettings(d.id));
                  }}
                ></DeleteIcon>
                <span
                  onClick={() => {
                    setFilters(d);
                  }}
                >
                  {d.name}
                </span>
              </div>
            );
          })}
        </div>
        <div className="form-box">
          <div className="selector-box full-width-select">
            <TextFieldComponent
              placeholder={t("form.name.filter")}
              onChange={(event: any) => {
                handleForm("name", event);
              }}
              value={state.name}
              regExp={onlyLettersNumbersSpaces}
              maxValue={20}
              invalid={false}
              message=""
            ></TextFieldComponent>
          </div>
          <ActionButtons
            doneText={t("submit.continue")}
            clickable={state.name !== "" ? true : false}
            doneClick={saveSettings}
            cancelClick={() => {
              //   onCloseRequest();
            }}
            cancelText={t("submit.cancel")}
          ></ActionButtons>
        </div>
      </div>
    );
  };

export default FilterSettingsTab;
