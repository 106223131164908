import React from "react";
import "./style.scss";

class ImgDialog extends React.Component {
  getTitle = (index) => {
    if (index === 0) return this.props.t("name.img.1");
    if (index === 1) return this.props.t("name.img.2");
    if (index === 2) return this.props.t("name.img.3");
  };

  render() {
    var value1 = -1;
    return (
      <div className="sweet-img-dialog">
        <div
          className="dissmiser-box"
          onClick={this.props.onCloseRequest}
        ></div>
        <div
          id="carouselIndicators"
          className="carousel slide"
          data-ride="carousel"
        >
          <div className="carousel-inner">
            {this.props.imgs.map((e, index) => {
              value1++;
              if (e !== undefined && e !== "")
                return (
                  <div
                    key={value1}
                    className={
                      value1 !== 0 ? "carousel-item" : "carousel-item active"
                    }
                  >
                    <div className="title-carousel">{this.getTitle(index)}</div>
                    <img src={e} className="d-block w-100" alt="..." />
                  </div>
                );
              else return <div></div>;
            })}
          </div>
          {(this.props.imgs[1] !== undefined && this.props.imgs[1] !== "") ||
          (this.props.imgs[2] !== undefined && this.props.imgs[2] !== "") ? (
            <a
              className="carousel-control-prev"
              href="#carouselIndicators"
              role="button"
              data-slide="prev"
            >
              <div className="arrow-box">
                {" "}
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
              </div>
              <span className="sr-only">Previous</span>
            </a>
          ) : (
            <div></div>
          )}
          {(this.props.imgs[1] !== undefined && this.props.imgs[1] !== "") ||
          (this.props.imgs[2] !== undefined && this.props.imgs[2] !== "") ? (
            <a
              className="carousel-control-next"
              href="#carouselIndicators"
              role="button"
              data-slide="next"
            >
              <div className="arrow-box">
                {" "}
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
              </div>
              <span className="sr-only">Next</span>
            </a>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    );
  }
}

export default ImgDialog;
