/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as protos_license_pb from '../protos/license_pb';


export class LicenseServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoCreateLicense = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_license_pb.SuccessWithIDMessage,
    (request: protos_license_pb.CreateLicenseRequest) => {
      return request.serializeBinary();
    },
    protos_license_pb.SuccessWithIDMessage.deserializeBinary
  );

  createLicense(
    request: protos_license_pb.CreateLicenseRequest,
    metadata: grpcWeb.Metadata | null): Promise<protos_license_pb.SuccessWithIDMessage>;

  createLicense(
    request: protos_license_pb.CreateLicenseRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_license_pb.SuccessWithIDMessage) => void): grpcWeb.ClientReadableStream<protos_license_pb.SuccessWithIDMessage>;

  createLicense(
    request: protos_license_pb.CreateLicenseRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_license_pb.SuccessWithIDMessage) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/LicenseService/CreateLicense',
        request,
        metadata || {},
        this.methodInfoCreateLicense,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/LicenseService/CreateLicense',
    request,
    metadata || {},
    this.methodInfoCreateLicense);
  }

  methodInfoUpdateLicense = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_license_pb.SuccessMessageL,
    (request: protos_license_pb.UpdateLicenseRequest) => {
      return request.serializeBinary();
    },
    protos_license_pb.SuccessMessageL.deserializeBinary
  );

  updateLicense(
    request: protos_license_pb.UpdateLicenseRequest,
    metadata: grpcWeb.Metadata | null): Promise<protos_license_pb.SuccessMessageL>;

  updateLicense(
    request: protos_license_pb.UpdateLicenseRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_license_pb.SuccessMessageL) => void): grpcWeb.ClientReadableStream<protos_license_pb.SuccessMessageL>;

  updateLicense(
    request: protos_license_pb.UpdateLicenseRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_license_pb.SuccessMessageL) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/LicenseService/UpdateLicense',
        request,
        metadata || {},
        this.methodInfoUpdateLicense,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/LicenseService/UpdateLicense',
    request,
    metadata || {},
    this.methodInfoUpdateLicense);
  }

  methodInfoUpdateSettings = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_license_pb.SuccessMessageL,
    (request: protos_license_pb.SettingRequest) => {
      return request.serializeBinary();
    },
    protos_license_pb.SuccessMessageL.deserializeBinary
  );

  updateSettings(
    request: protos_license_pb.SettingRequest,
    metadata: grpcWeb.Metadata | null): Promise<protos_license_pb.SuccessMessageL>;

  updateSettings(
    request: protos_license_pb.SettingRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_license_pb.SuccessMessageL) => void): grpcWeb.ClientReadableStream<protos_license_pb.SuccessMessageL>;

  updateSettings(
    request: protos_license_pb.SettingRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_license_pb.SuccessMessageL) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/LicenseService/UpdateSettings',
        request,
        metadata || {},
        this.methodInfoUpdateSettings,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/LicenseService/UpdateSettings',
    request,
    metadata || {},
    this.methodInfoUpdateSettings);
  }

  methodInfoUpdateJobTypes = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_license_pb.SuccessMessageL,
    (request: protos_license_pb.UpdateJobTypesRequest) => {
      return request.serializeBinary();
    },
    protos_license_pb.SuccessMessageL.deserializeBinary
  );

  updateJobTypes(
    request: protos_license_pb.UpdateJobTypesRequest,
    metadata: grpcWeb.Metadata | null): Promise<protos_license_pb.SuccessMessageL>;

  updateJobTypes(
    request: protos_license_pb.UpdateJobTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_license_pb.SuccessMessageL) => void): grpcWeb.ClientReadableStream<protos_license_pb.SuccessMessageL>;

  updateJobTypes(
    request: protos_license_pb.UpdateJobTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_license_pb.SuccessMessageL) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/LicenseService/UpdateJobTypes',
        request,
        metadata || {},
        this.methodInfoUpdateJobTypes,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/LicenseService/UpdateJobTypes',
    request,
    metadata || {},
    this.methodInfoUpdateJobTypes);
  }

  methodInfoBlockOrDelete = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_license_pb.SuccessMessageL,
    (request: protos_license_pb.BlockOrDeleteRequest) => {
      return request.serializeBinary();
    },
    protos_license_pb.SuccessMessageL.deserializeBinary
  );

  blockOrDelete(
    request: protos_license_pb.BlockOrDeleteRequest,
    metadata: grpcWeb.Metadata | null): Promise<protos_license_pb.SuccessMessageL>;

  blockOrDelete(
    request: protos_license_pb.BlockOrDeleteRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_license_pb.SuccessMessageL) => void): grpcWeb.ClientReadableStream<protos_license_pb.SuccessMessageL>;

  blockOrDelete(
    request: protos_license_pb.BlockOrDeleteRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_license_pb.SuccessMessageL) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/LicenseService/BlockOrDelete',
        request,
        metadata || {},
        this.methodInfoBlockOrDelete,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/LicenseService/BlockOrDelete',
    request,
    metadata || {},
    this.methodInfoBlockOrDelete);
  }

  methodInfoRemoveJobType = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_license_pb.SuccessMessageL,
    (request: protos_license_pb.RemoveJobTypeRequest) => {
      return request.serializeBinary();
    },
    protos_license_pb.SuccessMessageL.deserializeBinary
  );

  removeJobType(
    request: protos_license_pb.RemoveJobTypeRequest,
    metadata: grpcWeb.Metadata | null): Promise<protos_license_pb.SuccessMessageL>;

  removeJobType(
    request: protos_license_pb.RemoveJobTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_license_pb.SuccessMessageL) => void): grpcWeb.ClientReadableStream<protos_license_pb.SuccessMessageL>;

  removeJobType(
    request: protos_license_pb.RemoveJobTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_license_pb.SuccessMessageL) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/LicenseService/RemoveJobType',
        request,
        metadata || {},
        this.methodInfoRemoveJobType,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/LicenseService/RemoveJobType',
    request,
    metadata || {},
    this.methodInfoRemoveJobType);
  }

  methodInfoFindPoint = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_license_pb.SuccessWithIDMessage,
    (request: protos_license_pb.FindPointRequest) => {
      return request.serializeBinary();
    },
    protos_license_pb.SuccessWithIDMessage.deserializeBinary
  );

  findPoint(
    request: protos_license_pb.FindPointRequest,
    metadata: grpcWeb.Metadata | null): Promise<protos_license_pb.SuccessWithIDMessage>;

  findPoint(
    request: protos_license_pb.FindPointRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_license_pb.SuccessWithIDMessage) => void): grpcWeb.ClientReadableStream<protos_license_pb.SuccessWithIDMessage>;

  findPoint(
    request: protos_license_pb.FindPointRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_license_pb.SuccessWithIDMessage) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/LicenseService/FindPoint',
        request,
        metadata || {},
        this.methodInfoFindPoint,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/LicenseService/FindPoint',
    request,
    metadata || {},
    this.methodInfoFindPoint);
  }

  methodInfoFetchLicenses = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_license_pb.FetchResponseL,
    (request: protos_license_pb.FetchRequestL) => {
      return request.serializeBinary();
    },
    protos_license_pb.FetchResponseL.deserializeBinary
  );

  fetchLicenses(
    request: protos_license_pb.FetchRequestL,
    metadata: grpcWeb.Metadata | null): Promise<protos_license_pb.FetchResponseL>;

  fetchLicenses(
    request: protos_license_pb.FetchRequestL,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_license_pb.FetchResponseL) => void): grpcWeb.ClientReadableStream<protos_license_pb.FetchResponseL>;

  fetchLicenses(
    request: protos_license_pb.FetchRequestL,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_license_pb.FetchResponseL) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/LicenseService/FetchLicenses',
        request,
        metadata || {},
        this.methodInfoFetchLicenses,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/LicenseService/FetchLicenses',
    request,
    metadata || {},
    this.methodInfoFetchLicenses);
  }

  methodInfoGetImagePath = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_license_pb.GetImageResponse,
    (request: protos_license_pb.GetImageRequest) => {
      return request.serializeBinary();
    },
    protos_license_pb.GetImageResponse.deserializeBinary
  );

  getImagePath(
    request: protos_license_pb.GetImageRequest,
    metadata: grpcWeb.Metadata | null): Promise<protos_license_pb.GetImageResponse>;

  getImagePath(
    request: protos_license_pb.GetImageRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_license_pb.GetImageResponse) => void): grpcWeb.ClientReadableStream<protos_license_pb.GetImageResponse>;

  getImagePath(
    request: protos_license_pb.GetImageRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_license_pb.GetImageResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/LicenseService/GetImagePath',
        request,
        metadata || {},
        this.methodInfoGetImagePath,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/LicenseService/GetImagePath',
    request,
    metadata || {},
    this.methodInfoGetImagePath);
  }

  methodInfoCreateFeedback = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_license_pb.SuccessMessageL,
    (request: protos_license_pb.Feedback) => {
      return request.serializeBinary();
    },
    protos_license_pb.SuccessMessageL.deserializeBinary
  );

  createFeedback(
    request: protos_license_pb.Feedback,
    metadata: grpcWeb.Metadata | null): Promise<protos_license_pb.SuccessMessageL>;

  createFeedback(
    request: protos_license_pb.Feedback,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_license_pb.SuccessMessageL) => void): grpcWeb.ClientReadableStream<protos_license_pb.SuccessMessageL>;

  createFeedback(
    request: protos_license_pb.Feedback,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_license_pb.SuccessMessageL) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/LicenseService/CreateFeedback',
        request,
        metadata || {},
        this.methodInfoCreateFeedback,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/LicenseService/CreateFeedback',
    request,
    metadata || {},
    this.methodInfoCreateFeedback);
  }

  methodInfoFetchFeedback = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_license_pb.SuccessFeedbackMessage,
    (request: protos_license_pb.FetchFeedbackRequest) => {
      return request.serializeBinary();
    },
    protos_license_pb.SuccessFeedbackMessage.deserializeBinary
  );

  fetchFeedback(
    request: protos_license_pb.FetchFeedbackRequest,
    metadata: grpcWeb.Metadata | null): Promise<protos_license_pb.SuccessFeedbackMessage>;

  fetchFeedback(
    request: protos_license_pb.FetchFeedbackRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_license_pb.SuccessFeedbackMessage) => void): grpcWeb.ClientReadableStream<protos_license_pb.SuccessFeedbackMessage>;

  fetchFeedback(
    request: protos_license_pb.FetchFeedbackRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_license_pb.SuccessFeedbackMessage) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/LicenseService/FetchFeedback',
        request,
        metadata || {},
        this.methodInfoFetchFeedback,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/LicenseService/FetchFeedback',
    request,
    metadata || {},
    this.methodInfoFetchFeedback);
  }

  methodInfoAdRequest = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_license_pb.SuccessMessageL,
    (request: protos_license_pb.Ad) => {
      return request.serializeBinary();
    },
    protos_license_pb.SuccessMessageL.deserializeBinary
  );

  adRequest(
    request: protos_license_pb.Ad,
    metadata: grpcWeb.Metadata | null): Promise<protos_license_pb.SuccessMessageL>;

  adRequest(
    request: protos_license_pb.Ad,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_license_pb.SuccessMessageL) => void): grpcWeb.ClientReadableStream<protos_license_pb.SuccessMessageL>;

  adRequest(
    request: protos_license_pb.Ad,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_license_pb.SuccessMessageL) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/LicenseService/AdRequest',
        request,
        metadata || {},
        this.methodInfoAdRequest,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/LicenseService/AdRequest',
    request,
    metadata || {},
    this.methodInfoAdRequest);
  }

  methodInfoFetchAds = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_license_pb.FetchAdsResponse,
    (request: protos_license_pb.FetchAdsRequest) => {
      return request.serializeBinary();
    },
    protos_license_pb.FetchAdsResponse.deserializeBinary
  );

  fetchAds(
    request: protos_license_pb.FetchAdsRequest,
    metadata: grpcWeb.Metadata | null): Promise<protos_license_pb.FetchAdsResponse>;

  fetchAds(
    request: protos_license_pb.FetchAdsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_license_pb.FetchAdsResponse) => void): grpcWeb.ClientReadableStream<protos_license_pb.FetchAdsResponse>;

  fetchAds(
    request: protos_license_pb.FetchAdsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_license_pb.FetchAdsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/LicenseService/FetchAds',
        request,
        metadata || {},
        this.methodInfoFetchAds,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/LicenseService/FetchAds',
    request,
    metadata || {},
    this.methodInfoFetchAds);
  }

  methodInfoListenLicenseUpdates = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_license_pb.License,
    (request: protos_license_pb.ListenMessage) => {
      return request.serializeBinary();
    },
    protos_license_pb.License.deserializeBinary
  );

  listenLicenseUpdates(
    request: protos_license_pb.ListenMessage,
    metadata?: grpcWeb.Metadata) {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/LicenseService/ListenLicenseUpdates',
      request,
      metadata || {},
      this.methodInfoListenLicenseUpdates);
  }

  methodInfoFeedbackRateByLicense = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_license_pb.FeedbackRateResponse,
    (request: protos_license_pb.FeedbackRateMessage) => {
      return request.serializeBinary();
    },
    protos_license_pb.FeedbackRateResponse.deserializeBinary
  );

  feedbackRateByLicense(
    request: protos_license_pb.FeedbackRateMessage,
    metadata: grpcWeb.Metadata | null): Promise<protos_license_pb.FeedbackRateResponse>;

  feedbackRateByLicense(
    request: protos_license_pb.FeedbackRateMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_license_pb.FeedbackRateResponse) => void): grpcWeb.ClientReadableStream<protos_license_pb.FeedbackRateResponse>;

  feedbackRateByLicense(
    request: protos_license_pb.FeedbackRateMessage,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_license_pb.FeedbackRateResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/LicenseService/FeedbackRateByLicense',
        request,
        metadata || {},
        this.methodInfoFeedbackRateByLicense,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/LicenseService/FeedbackRateByLicense',
    request,
    metadata || {},
    this.methodInfoFeedbackRateByLicense);
  }

}

