import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout, getUser } from "../../firebase/auth";
import { RootStore } from "../../redux/Store";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { isEmpty } from "lodash";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/styles/rsuite-default.min.css";

// import moment from "moment";
import "./style.scss";

import {
  limpieza,
  riego,
  poda,
  pintura,
  parques,
  alumbrado,
  basura,
  desazolve,
  escombros,
  REPORT_PROGRESS_ASSIGNED,
  REPORT_PROGRESS_PROGRESS,
  pending,
  inProgress,
  done,
  // superAdmin,
  // worker,
  adminServices,
  adminSupervisors,
  adminCashier,
  adminMayor,
} from "../../const/const";
import { TEAMS_SELECTED } from "../../redux/teams/teamsTypes";

// import { NavLink } from "react-router-dom";
import LanguageSelector from "./components/language-selector";
import NotificationBell from "./components/notification-bell";
import PerfilDialog from "../../views/dialogs/perfil-dialog";
import SimpleModal from "../../utils/dialogs/simple-dialog";
import TeamImg from "../../assets/img/team_image.png";
import BitMap from "../../assets/img/bitmap.png";
import FeatherIcon from "feather-icons-react";
import {
  Arrow,
  Limpieza,
  Riego,
  Poda,
  Pintura,
  Parques,
  Alumbrado,
  Basura,
  Desazolve,
  Escombro,
} from "../../assets/svg/index";
import { ReportTypes } from "../../redux/reportTypes/reportsReducer";
import {
  Report,
  ReportStatus,
  ReportWorkStatus,
} from "../../server/report/protos/report_pb";
import { useTranslation } from "react-i18next";
import FilterItem from "./components/filter-item";
import {
  CLEAR_RANGE_DATE,
  REPORTS_FILTER_LIST,
  REPORTS_VIEW_SHOW_TIME,
  SET_RANGE_DATE,
} from "../../redux/reportTypes/reportsTypes";
import { licenseI } from "../../redux/licenses/licensesReducer";
import { Team } from "../../server/team/protos/team_pb";
import { User } from "../../server/user/protos/user_pb";
import { CheckAuthentication } from "../../session/CheckAuthentication";
import { ValueType } from "rsuite/lib/DateRangePicker";
import { getCalendarLocale } from "../../utils/utils";
import moment from "moment";
import SelectComponentTeams from "../../views/components/select-component-teams";

interface TopBarProps {
  superAdmin: any;
  reportTypes: any;
  history: any;
  location: any;
  selectedLicense: licenseI | undefined;
  authUserId: any;
  updateDistrict: any;
  //teams: any;
  workers: any;
  guests: any;
  users: any;
  reports: any;
  executeCalculation: any;
  t: any;
  changeLanguage: any;
  language: any;
  superData: any;
  filterType: any;
  changeFilter: any;
}

export const TopBar: React.FunctionComponent<TopBarProps> = ({
  // superAdmin,
  reportTypes,
  history,
  location,
  currentDistrict,
  authUserId,
  updateDistrict,
  //teams,
  workers,
  guests,
  users,
  reports,
  executeCalculation,
  changeLanguage,
  language,
  superData,
  filterType,
  changeFilter,
}: any) => {
  const teams: Team.AsObject[] = useSelector(
    (globalState: RootStore) => globalState.teams.teams
  );
  const selectedTeam: Team.AsObject = useSelector(
    (globalState: RootStore) => globalState.teams.selectedTeam
  );

  const selectedLicense: licenseI | undefined = useSelector(
    (globalState: RootStore) => globalState.licenses.selectedLicense
  );

  const selectedUser: User.AsObject | undefined = useSelector(
    (globalState: RootStore) => globalState.auth.user
  );

  const superAdmin: boolean = useSelector(
    (globalState: RootStore) => globalState.auth.superAdmin
  );

  const viewFilters: {
    pending: boolean;
    assigned: boolean;
    progress: boolean;
    completed: boolean;
    problem: boolean;
    paused: boolean;
  } = useSelector(
    (globalState: RootStore) => globalState.reportTypes.viewFilters
  );

  const viewShowTime: boolean = useSelector(
    (globalState: RootStore) => globalState.reportTypes.viewShowTime
  );

  const filtersSelected: string[] = useSelector(
    (globalState: RootStore) => globalState.reportTypes.filtersSelected
  );

  const reportTypesR: ReportTypes[] = useSelector(
    (globalState: RootStore) => globalState.reportTypes.reportTypes
  );

  const reportTypesGeneralR: ReportTypes[] = useSelector(
    (globalState: RootStore) => globalState.reportTypes.reportTypesGeneral
  );

  const reportsR: Report.AsObject[] = useSelector(
    (globalState: RootStore) => globalState.reportTypes.reports
  );

  const dispatch = useDispatch();
  const { t } = useTranslation();

  let defaultValue: any;
  let defaultValueArray: any = [];

  const [state, setState] = useState({
    menuVisible: false,
    perfilVisible: false,
    workerResult: defaultValueArray,
    teamResult: defaultValueArray,
    userResult: defaultValueArray,
    reportsResult: defaultValueArray,
    selectorKey: "",
    filterTypeVisible: false,
    user: getUser(),
  });

  const doSignOut = () => {
    openMenu();
    const goHome = () => {
      history.push("/");
    };
    logout(goHome);
    CheckAuthentication();
  };

  const getFilterString = () => {
    let value = "";
    filtersSelected.forEach((e: any) => {
      let valuedata = reportTypesR.find((rt: ReportTypes) => {
        return rt.reporttypeid === e;
      });
      if (valuedata) {
        if (value === "") value = valuedata[t("language")];
        else value = value + ", " + valuedata[t("language")];
      }
    });
    if (value === "") value = t("report.type.all");
    return value;
  };

  const changeShowTime = () => {
    dispatch({
      type: REPORTS_VIEW_SHOW_TIME,
      show: !viewShowTime,
    });
  };

  const resetSelector = () => {
    setState((prevState) => ({
      ...prevState,
      selectorKey: Math.random().toString(36).substring(7),
    }));
  };

  const showFilterType = () => {
    setState((prevState) => ({
      ...prevState,
      filterTypeVisible: !state.filterTypeVisible,
    }));
  };

  const getCount = (value: any) => {
    let params = queryString.parse(location.search);
    // if (isEmpty(params) || params?.id) {
    //   if (!filterType) {
    //     if (!value) return reports.length;
    //     else return reports.filter((e: any) => e.type === value).length;
    //   } else {
    //     if (!value)
    //       return reports.filter((e: any) => e.reportState === filterType)
    //         .length;
    //     else
    //       return reports.filter(
    //         (e: any) => e.type === value && e.reportState === filterType
    //       ).length;
    //   }
    // } else {
    if (!value) {
      if (
        !viewFilters.pending &&
        !viewFilters.assigned &&
        !viewFilters.progress &&
        !viewFilters.completed
      ) {
        return reportsR.filter(
          (e: Report.AsObject) =>
            selectedLicense?.licenseSettings?.jobTypes?.indexOf(
              e.reporttypeid
            ) !== -1 &&
            e.cityid === selectedLicense?._id &&
            reportTypesGeneralR.filter(
              (f: any) => e.reporttypeid === f.reporttypeid
            ).length > 0
        ).length;
      } else
        return reportsR.filter(
          (e: Report.AsObject) =>
            selectedLicense?.licenseSettings?.jobTypes?.indexOf(
              e.reporttypeid
            ) !== -1 &&
            e.cityid === selectedLicense?._id &&
            reportTypesGeneralR.filter(
              (f: any) => e.reporttypeid === f.reporttypeid
            ).length > 0 &&
            ((viewFilters.pending &&
              e.reportstatus === ReportStatus.REPORT_PENDING) ||
              (viewFilters.assigned &&
                e.reportstatus === ReportStatus.REPORT_IN_PROGRESS &&
                e.workstatus === ReportWorkStatus.WORK_ASSIGNED) ||
              (viewFilters.progress &&
                e.reportstatus === ReportStatus.REPORT_IN_PROGRESS &&
                e.workstatus === ReportWorkStatus.WORK_IN_PROGRESS) ||
              (viewFilters.completed &&
                e.reportstatus === ReportStatus.REPORT_COMPLETED))
        ).length;
    } else {
      if (
        !viewFilters.pending &&
        !viewFilters.assigned &&
        !viewFilters.progress &&
        !viewFilters.completed
      ) {
        return reportsR.filter(
          (e: Report.AsObject) =>
            e.reporttypeid === value &&
            reportTypesGeneralR.filter(
              (f: any) => e.reporttypeid === f.reporttypeid
            ).length > 0
        ).length;
      } else if (viewFilters.pending) {
        return reportsR.filter(
          (e: Report.AsObject) =>
            e.reporttypeid === value &&
            reportTypesGeneralR.filter(
              (f: any) => e.reporttypeid === f.reporttypeid
            ).length > 0 &&
            ((viewFilters.pending &&
              e.reportstatus === ReportStatus.REPORT_PENDING) ||
              (viewFilters.assigned &&
                e.reportstatus === ReportStatus.REPORT_IN_PROGRESS &&
                e.workstatus === ReportWorkStatus.WORK_ASSIGNED) ||
              (viewFilters.progress &&
                e.reportstatus === ReportStatus.REPORT_IN_PROGRESS &&
                e.workstatus === ReportWorkStatus.WORK_IN_PROGRESS) ||
              (viewFilters.completed &&
                e.reportstatus === ReportStatus.REPORT_COMPLETED))
        ).length;
      } else if (viewFilters.assigned) {
        return reportsR.filter(
          (e: Report.AsObject) =>
            e.reporttypeid === value &&
            reportTypesGeneralR.filter(
              (f: any) => e.reporttypeid === f.reporttypeid
            ).length > 0 &&
            ((viewFilters.pending &&
              e.reportstatus === ReportStatus.REPORT_PENDING) ||
              (viewFilters.assigned &&
                e.reportstatus === ReportStatus.REPORT_IN_PROGRESS &&
                e.workstatus === ReportWorkStatus.WORK_ASSIGNED) ||
              (viewFilters.progress &&
                e.reportstatus === ReportStatus.REPORT_IN_PROGRESS &&
                e.workstatus === ReportWorkStatus.WORK_IN_PROGRESS) ||
              (viewFilters.completed &&
                e.reportstatus === ReportStatus.REPORT_COMPLETED))
        ).length;
      } else if (viewFilters.progress) {
        return reportsR.filter(
          (e: Report.AsObject) =>
            e.reporttypeid === value &&
            reportTypesGeneralR.filter(
              (f: any) => e.reporttypeid === f.reporttypeid
            ).length > 0 &&
            ((viewFilters.pending &&
              e.reportstatus === ReportStatus.REPORT_PENDING) ||
              (viewFilters.assigned &&
                e.reportstatus === ReportStatus.REPORT_IN_PROGRESS &&
                e.workstatus === ReportWorkStatus.WORK_ASSIGNED) ||
              (viewFilters.progress &&
                e.reportstatus === ReportStatus.REPORT_IN_PROGRESS &&
                e.workstatus === ReportWorkStatus.WORK_IN_PROGRESS) ||
              (viewFilters.completed &&
                e.reportstatus === ReportStatus.REPORT_COMPLETED))
        ).length;
      } else if (viewFilters.completed) {
        return reportsR.filter(
          (e: Report.AsObject) =>
            e.reporttypeid === value &&
            reportTypesGeneralR.filter(
              (f: any) => e.reporttypeid === f.reporttypeid
            ).length > 0 &&
            ((viewFilters.pending &&
              e.reportstatus === ReportStatus.REPORT_PENDING) ||
              (viewFilters.assigned &&
                e.reportstatus === ReportStatus.REPORT_IN_PROGRESS &&
                e.workstatus === ReportWorkStatus.WORK_ASSIGNED) ||
              (viewFilters.progress &&
                e.reportstatus === ReportStatus.REPORT_IN_PROGRESS &&
                e.workstatus === ReportWorkStatus.WORK_IN_PROGRESS) ||
              (viewFilters.completed &&
                e.reportstatus === ReportStatus.REPORT_COMPLETED))
        ).length;
      }
    }
    // }
  };

  const setFilter = (e: string[]) => {
    // changeFilter(e);
    dispatch({
      type: REPORTS_FILTER_LIST,
      payload: e,
    });
  };

  const showFilters = () => {
    return (
      <div className="filter">
        <div className="filter-status-title">{t("name.filters")}</div>
        <ul>
          <li
            onClick={() => {
              const array: string[] = [];
              setFilter(array);
            }}
            className={
              (filtersSelected.length === 0
                ? "filter-selected filter-header"
                : "") + " filter-item filter-header "
            }
          >
            {" "}
            <div className="checkbox-selected">
              <FeatherIcon icon="check" size="14" />
            </div>
            {t("report.type.all")} ({getCount(defaultValue)}){" "}
          </li>
          {/* {selectedLicense?.licenseSettings?.jobTypes?.length === 0 &&
            reportTypesR.map((e: ReportTypes) => {
              return (
                <FilterItem reportType={e} getCount={getCount}></FilterItem>
              );
            })} */}
          {selectedLicense?.licenseSettings?.jobTypes &&
            selectedLicense?.licenseSettings?.jobTypes?.length > 0 &&
            reportTypesR.map((e: ReportTypes) => {
              return selectedLicense?.licenseSettings?.jobTypes?.indexOf(
                e.reporttypeid
              ) !== -1 ? (
                <FilterItem reportType={e} getCount={getCount}></FilterItem>
              ) : null;
            })}
        </ul>
      </div>
    );
  };

  const getLocation = () => {
    if (location.pathname === "/main") {
      return <div className="title-data">{t("title.profile")}</div>;
    }
    if (location.pathname === "/") {
      return <div className="title-data">{t("title.dashboard")}</div>;
    }
    if (location.pathname === "/reports") {
      // let params = queryString.parse(this.props.location.search);
      return (
        <div className="top-filter">
          <div className="title-data">{t("title.reports")}</div>
          <div className="filter-type-box">
            <div className="filter-type-selector" onClick={showFilterType}>
              <div>{getFilterString()}</div>
              <Arrow></Arrow>
            </div>
            {state.filterTypeVisible && (
              <div className="filter-type-list">{showFilters()}</div>
            )}
          </div>
          <div className="show-time-filter">
            <div
              onClick={() => {
                changeShowTime();
              }}
              className={
                (viewShowTime ? "filter-selected" : "") +
                " filter-item done-filter-icon"
              }
            >
              <div className="checkbox-selected">
                <FeatherIcon icon="check" size="14" />
              </div>
              <div className="type-text">{t("name.time")}</div>
            </div>
          </div>
          {state.filterTypeVisible && (
            <div className="outside-trigger" onClick={showFilterType}></div>
          )}
          <div className="date-picker-reports">
            <DateRangePicker
              locale={getCalendarLocale(t)}
              appearance="default"
              placeholder={t("calendar.title.placeholder")}
              style={{ width: 220 }}
              disabledDate={DateRangePicker.afterToday()}
              onChange={(
                date: ValueType,
                event: React.SyntheticEvent<HTMLElement>
              ) => {
                dispatch({
                  type: SET_RANGE_DATE,
                  from: moment(date[0]).startOf("day").utc(false).toDate(),
                  to: moment(date[1]).endOf("day").utc(false).toDate(),
                });
              }}
              onOk={(
                date: ValueType,
                event: React.SyntheticEvent<HTMLElement>
              ) => {
                dispatch({
                  type: SET_RANGE_DATE,
                  from: moment(date[0]).startOf("day").utc(false).toDate(),
                  to: moment(date[1]).endOf("day").utc(false).toDate(),
                });
              }}
              onClean={(event: React.SyntheticEvent<HTMLElement>) => {
                dispatch({
                  type: CLEAR_RANGE_DATE,
                });
              }}
            />
          </div>
        </div>
      );
    }
    if (location.pathname === "/routing") {
      return (
        <div className="top-filter">
          <div className="title-data">{t("title.calendar")}</div>
          <div className="team-selector">
            <SelectComponentTeams></SelectComponentTeams>
          </div>
        </div>
      );
    }
    if (location.pathname === "/trackerMap") {
      return <div className="title-data">{t("title.route")}</div>;
    }
    if (location.pathname === "/workers") {
      return <div className="title-data">{t("title.workers")}</div>;
    }
    if (location.pathname === "/teams") {
      if (!selectedTeam) {
        return <div className="title-data">{t("title.teams")}</div>;
      } else {
        return (
          <div className="title-data-with-child">
            <div
              className="father-title-data"
              onClick={() => {
                dispatch({
                  type: TEAMS_SELECTED,
                  payload: defaultValue,
                });
              }}
            >
              {t("title.teams") + " /"}
            </div>
            &nbsp;
            <div className="child-title-data">{selectedTeam.name || ""}</div>
          </div>
        );
      }
    }
    if (location.pathname === "/blocked") {
      return <div className="title-data">{t("title.users")}</div>;
    }
    if (location.pathname === "/reportTypes") {
      return <div className="title-data">{t("title.type.of.reports")}</div>;
    }
  };

  const openMenu = () => {
    setState((prevState) => ({
      ...prevState,
      menuVisible: !state.menuVisible,
    }));
  };

  const showPerfilDialog = () => {
    setState((prevState) => ({
      ...prevState,
      perfilVisible: !state.perfilVisible,
      menuVisible: false,
    }));
  };

  const searchBox = () => {
    if (location.pathname === "/blocked") {
      return (
        <div className="search-box">
          <input
            placeholder={t("placeholder.search")}
            className="search"
            onChange={searchUsers}
          />
          <div className="icon-search">
            <FeatherIcon icon="search" size="18" />
          </div>
          {state.userResult.length > 0 ? (
            <div className="result-search">
              {state.userResult.map((result: any) => {
                return (
                  <div
                    key={result.id}
                    className="result-search-box"
                    onClick={() => {
                      history.push({
                        pathname: "/blocked", //path
                        search: "?id=" + result.id,
                      });
                      setState((prevState) => ({
                        ...prevState,
                        workerResult: defaultValueArray,
                        teamResult: defaultValueArray,
                        reportsResult: defaultValueArray,
                        userResult: defaultValueArray,
                      }));
                    }}
                  >
                    <img
                      src={
                        result.imagePath && result.imagePath !== ""
                          ? result.imagePath
                          : TeamImg
                      }
                      alt=""
                    />
                    {result.name}
                  </div>
                );
              })}
            </div>
          ) : (
            <div></div>
          )}
          {state.userResult.length > 0 ? (
            <div
              className="outside-trigger"
              onClick={() => {
                setState((prevState) => ({
                  ...prevState,
                  workerResult: defaultValueArray,
                  teamResult: defaultValueArray,
                  reportsResult: defaultValueArray,
                  userResult: defaultValueArray,
                }));
              }}
            ></div>
          ) : (
            <div></div>
          )}
        </div>
      );
    } else if (location.pathname === "/workers") {
      return (
        <div className="search-box">
          <input
            placeholder={t("placeholder.search")}
            className="search"
            onChange={searchWorkers}
          />
          <div className="icon-search">
            <FeatherIcon icon="search" size="18" />
          </div>
          {state.workerResult.length > 0 ? (
            <div className="result-search">
              {state.workerResult.map((result: any) => {
                return (
                  <div
                    key={result.id}
                    className="result-search-box"
                    onClick={() => {
                      history.push({
                        pathname: "/workers", //path
                        search: "?id=" + result.id,
                      });
                      setState((prevState) => ({
                        ...prevState,
                        workerResult: defaultValueArray,
                        teamResult: defaultValueArray,
                        reportsResult: defaultValueArray,
                        userResult: defaultValueArray,
                      }));
                    }}
                  >
                    <img
                      src={
                        result.profileImg !== null && result.profileImg !== ""
                          ? result.profileImg
                          : TeamImg
                      }
                      alt=""
                    />
                    {result.name + " " + result.lastName}
                  </div>
                );
              })}
            </div>
          ) : (
            <div></div>
          )}
          {state.workerResult.length > 0 ? (
            <div
              className="outside-trigger"
              onClick={() => {
                setState((prevState) => ({
                  ...prevState,
                  workerResult: defaultValueArray,
                  teamResult: defaultValueArray,
                  reportsResult: defaultValueArray,
                  userResult: defaultValueArray,
                }));
              }}
            ></div>
          ) : (
            <div></div>
          )}
        </div>
      );
    } else if (location.pathname === "/teams") {
      return (
        <div className="search-box">
          <input
            placeholder={t("placeholder.search")}
            className="search"
            onChange={searchTeams}
          />
          <div className="icon-search">
            <FeatherIcon icon="search" size="18" />
          </div>
          {state.teamResult.length > 0 ? (
            <div className="result-search">
              {state.teamResult.map((result: any) => {
                return (
                  <div
                    key={result._id}
                    className="result-search-box"
                    onClick={() => {
                      history.push({
                        pathname: "/teams", //path
                        search: "?id=" + result._id,
                      });
                      setState((prevState) => ({
                        ...prevState,
                        workerResult: defaultValueArray,
                        teamResult: defaultValueArray,
                        reportsResult: defaultValueArray,
                        userResult: defaultValueArray,
                      }));
                    }}
                  >
                    <img
                      src={
                        result.teamImg && result.teamImg !== ""
                          ? result.teamImg
                          : TeamImg
                      }
                      alt=""
                    />
                    {result.name}
                  </div>
                );
              })}
            </div>
          ) : (
            <div></div>
          )}
          {state.teamResult.length > 0 ? (
            <div
              className="outside-trigger"
              onClick={() => {
                setState((prevState) => ({
                  ...prevState,
                  workerResult: defaultValueArray,
                  teamResult: defaultValueArray,
                  reportsResult: defaultValueArray,
                  userResult: defaultValueArray,
                }));
              }}
            ></div>
          ) : (
            <div></div>
          )}
        </div>
      );
    }
    if (location.pathname === "/reports") {
      return (
        <div className="search-box">
          <input
            placeholder={t("placeholder.search")}
            className="search"
            onChange={searchReports}
          />
          <div className="icon-search">
            <FeatherIcon icon="search" size="18" />
          </div>
          {state.reportsResult.length > 0 ? (
            <div className="result-search">
              {state.reportsResult.map((result: any) => {
                return (
                  <div
                    key={result._id}
                    className="result-search-box"
                    onClick={() => {
                      history.push({
                        pathname: "/reports", //path
                        search: "?id=" + result._id,
                      });
                      setState((prevState) => ({
                        ...prevState,
                        workerResult: defaultValueArray,
                        teamResult: defaultValueArray,
                        reportsResult: defaultValueArray,
                        userResult: defaultValueArray,
                      }));
                    }}
                  >
                    <div className="reports-result">
                      <div className="title-search-result">
                        <b>{result.type}</b>
                      </div>
                      <div className="description-search-result">
                        {result.description}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div></div>
          )}
          {state.reportsResult.length > 0 ? (
            <div
              className="outside-trigger"
              onClick={() => {
                setState((prevState) => ({
                  ...prevState,
                  workerResult: defaultValueArray,
                  teamResult: defaultValueArray,
                  reportsResult: defaultValueArray,
                  userResult: defaultValueArray,
                }));
              }}
            ></div>
          ) : (
            <div></div>
          )}
        </div>
      );
    }
    if (location.pathname === "/routing") {
      return (
        <div className="search-box">
          <input
            placeholder={t("placeholder.search")}
            className="search"
            onChange={searchReports}
          />
          <div className="icon-search">
            <FeatherIcon icon="search" size="18" />
          </div>
          {state.reportsResult.length > 0 ? (
            <div className="result-search">
              {state.reportsResult.map((result: any) => {
                return (
                  <div
                    key={result._id}
                    className="result-search-box"
                    onClick={() => {
                      history.push({
                        pathname: "/routing", //path
                        search: "?id=" + result._id,
                      });
                      setState((prevState) => ({
                        ...prevState,
                        workerResult: defaultValueArray,
                        teamResult: defaultValueArray,
                        reportsResult: defaultValueArray,
                        userResult: defaultValueArray,
                      }));
                    }}
                  >
                    <div className="reports-result">
                      <div className="title-search-result">
                        <b>{result.type}</b>
                      </div>
                      <div className="description-search-result">
                        {result.description}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div></div>
          )}
          {state.reportsResult.length > 0 ? (
            <div
              className="outside-trigger"
              onClick={() => {
                setState((prevState) => ({
                  ...prevState,
                  workerResult: defaultValueArray,
                  teamResult: defaultValueArray,
                  reportsResult: defaultValueArray,
                  userResult: defaultValueArray,
                }));
              }}
            ></div>
          ) : (
            <div></div>
          )}
        </div>
      );
    } else {
      return (
        <div className="search-box">
          <input placeholder={t("placeholder.search")} className="search" />
          <div className="icon-search">
            <FeatherIcon icon="search" size="18" />
          </div>
          {/* <div className="result-search"></div> */}
        </div>
      );
    }
  };

  const searchWorkers = (event: any) => {
    event.preventDefault();
    let List = defaultValueArray;
    if (event !== "") {
      List = workers.filter((w: any) => {
        return (
          (w.name.toLowerCase() + " " + w.lastName.toLowerCase()).includes(
            event.target.value.toLowerCase()
          ) || w.email.toLowerCase().includes(event.target.value.toLowerCase())
        );
      });
      guests
        .filter((w: any) => {
          return (
            (w.name.toLowerCase() + " " + w.lastName.toLowerCase()).includes(
              event.target.value.toLowerCase()
            ) ||
            w.email.toLowerCase().includes(event.target.value.toLowerCase())
          );
        })
        .forEach((element: any) => {
          List.push(element);
        });
    }

    setState((prevState) => ({
      ...prevState,
      workerResult: List,
      teamResult: defaultValueArray,
      reportsResult: defaultValueArray,
      userResult: defaultValueArray,
    }));
  };

  const searchTeams = (event: any) => {
    event.preventDefault();
    let List = defaultValueArray;
    if (event !== "") {
      List = teams.filter((t) => {
        return t.name.toLowerCase().includes(event.target.value.toLowerCase());
      });
    }

    setState((prevState) => ({
      ...prevState,
      workerResult: defaultValueArray,
      teamResult: List,
      reportsResult: defaultValueArray,
      userResult: defaultValueArray,
    }));
  };

  const searchReports = (event: any) => {
    event.preventDefault();
    let List = defaultValueArray;
    if (event !== "") {
      List = reports.filter((w: any) => {
        return (
          w.type.toLowerCase().includes(event.target.value.toLowerCase()) ||
          w.description.toLowerCase().includes(event.target.value.toLowerCase())
        );
      });
    }

    setState((prevState) => ({
      ...prevState,
      workerResult: defaultValueArray,
      teamResult: defaultValueArray,
      reportsResult: List,
      userResult: defaultValueArray,
    }));
  };

  const searchUsers = (event: any) => {
    event.preventDefault();
    let List = defaultValueArray;
    if (event !== "") {
      List = users.filter((u: any) => {
        return (
          (u.name.toLowerCase() + " " + u.lastName.toLowerCase()).includes(
            event.target.value.toLowerCase()
          ) || u.email.toLowerCase().includes(event.target.value.toLowerCase())
        );
      });
    }

    setState((prevState) => ({
      ...prevState,
      workerResult: defaultValueArray,
      teamResult: defaultValueArray,
      reportsResult: defaultValueArray,
      userResult: List,
    }));
  };

  const getTypeUser = () => {
    // if (state.user?.typeOfUser === adminServices) {
    return t("form.user.type.1");
    // } else if (state.user?.typeOfUser === adminMayor) {
    //   return t("form.user.type.2");
    // } else if (state.user?.typeOfUser === adminSupervisors) {
    //   return t("form.user.type.3");
    // } else if (state.user?.typeOfUser === adminCashier) {
    //   return t("form.user.type.4");
    // }
  };

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      selectorKey: Math.random().toString(36).substring(7),
    }));
  }, []);

  return (
    <div className="container-fluid bg-light top-bar">
      <div className="top-bar-items">
        <div className="title-top-bar">{getLocation()}</div>
        <div className="right-top-item">
          {/* {this.searchBox()} */}
          <div
            className={
              !superAdmin ? "profile-row" : "profile-row super-profile"
            }
          >
            <div className="right-top-bar">
              {/* {!this.props.superAdmin && (
                <img src={Email} alt="" className="email-logo" />
              )} */}
              <div
                className={
                  superAdmin
                    ? "profile-container-super profile-container"
                    : "profile-container"
                }
                onClick={openMenu}
              >
                {/* <div className="profile-logo"></div> */}
                {!superAdmin && (
                  <img
                    className="profile-logo"
                    src={
                      selectedLicense && selectedLicense.imagePath
                        ? selectedLicense.imagePath
                        : BitMap
                    }
                    alt=" "
                  ></img>
                )}
                {!superAdmin && (
                  <div className="box-profile-names">
                    <div className="profile-name">
                      <div className="text-strong">{state.user?.name}</div>
                      <div className="text-normal">
                        <p> {selectedLicense ? selectedLicense.name : ""}</p>
                        {/* <div className="text-normal"> */}
                        <p>{getTypeUser()}</p>
                        {/* </div> */}
                      </div>
                      <FeatherIcon icon="chevron-down" size="18" />
                    </div>
                    {/* <div className="profile-name">
                      <div className="text-normal">{getTypeUser()}</div>
                    </div> */}
                  </div>
                )}
                {superAdmin && (
                  <div className="box-profile-names">
                    <div className="profile-name">
                      <div className="text-normal super-admin-text">
                        {selectedUser
                          ? selectedUser.name + " " + selectedUser.lastName
                          : ""}
                      </div>{" "}
                      <FeatherIcon icon="chevron-down" size="18" />
                    </div>
                  </div>
                )}
              </div>
              <NotificationBell></NotificationBell>
              <LanguageSelector></LanguageSelector>
            </div>
          </div>
        </div>
        {state.menuVisible && (
          <div className="hidden-wall" onClick={openMenu}></div>
        )}
        {state.menuVisible && (
          <div className="menu-popup">
            {!superAdmin && (
              <div
                className="menu-item menu-item-top"
                onClick={showPerfilDialog}
              >
                {t("edit.profile")}
              </div>
            )}
            <div className="menu-item menu-item-bottom" onClick={doSignOut}>
              {t("close.profile")}
            </div>
          </div>
        )}
        {state.perfilVisible && (
          <SimpleModal
            onCloseRequest={() => {
              showPerfilDialog();
            }}
          >
            <PerfilDialog
              onCloseRequest={() => {
                showPerfilDialog();
              }}
            ></PerfilDialog>
          </SimpleModal>
        )}
      </div>
    </div>
  );
};

export default TopBar;
