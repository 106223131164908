import React from 'react';
import {withRouter} from 'react-router-dom';
import AuthUserContext from './AuthUserContext';

const withAuthorization = (condition) => (Component) => {
    class WithAuthorization extends React.Component {
        componentDidMount() {
        }

        render() {
            return (
                <AuthUserContext.Consumer>
                    {authUser => authUser ? <Component {...this.props} authUser={authUser}/> : null}
                </AuthUserContext.Consumer>
            );
        }
    }

    return withRouter(WithAuthorization);
};

export default withAuthorization;