/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.19.0
// source: protos/chat.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as protos_chat_pb from '../protos/chat_pb'; // proto import: "protos/chat.proto"


export class ChatServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorSendTaskMessage = new grpcWeb.MethodDescriptor(
    '/ChatService/SendTaskMessage',
    grpcWeb.MethodType.UNARY,
    protos_chat_pb.TaskMessage,
    protos_chat_pb.MessageSuccess,
    (request: protos_chat_pb.TaskMessage) => {
      return request.serializeBinary();
    },
    protos_chat_pb.MessageSuccess.deserializeBinary
  );

  sendTaskMessage(
    request: protos_chat_pb.TaskMessage,
    metadata?: grpcWeb.Metadata | null): Promise<protos_chat_pb.MessageSuccess>;

  sendTaskMessage(
    request: protos_chat_pb.TaskMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protos_chat_pb.MessageSuccess) => void): grpcWeb.ClientReadableStream<protos_chat_pb.MessageSuccess>;

  sendTaskMessage(
    request: protos_chat_pb.TaskMessage,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protos_chat_pb.MessageSuccess) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChatService/SendTaskMessage',
        request,
        metadata || {},
        this.methodDescriptorSendTaskMessage,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChatService/SendTaskMessage',
    request,
    metadata || {},
    this.methodDescriptorSendTaskMessage);
  }

  methodDescriptorFetchChatMessages = new grpcWeb.MethodDescriptor(
    '/ChatService/FetchChatMessages',
    grpcWeb.MethodType.UNARY,
    protos_chat_pb.FetchMessageChat,
    protos_chat_pb.MessagesResponse,
    (request: protos_chat_pb.FetchMessageChat) => {
      return request.serializeBinary();
    },
    protos_chat_pb.MessagesResponse.deserializeBinary
  );

  fetchChatMessages(
    request: protos_chat_pb.FetchMessageChat,
    metadata?: grpcWeb.Metadata | null): Promise<protos_chat_pb.MessagesResponse>;

  fetchChatMessages(
    request: protos_chat_pb.FetchMessageChat,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protos_chat_pb.MessagesResponse) => void): grpcWeb.ClientReadableStream<protos_chat_pb.MessagesResponse>;

  fetchChatMessages(
    request: protos_chat_pb.FetchMessageChat,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protos_chat_pb.MessagesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChatService/FetchChatMessages',
        request,
        metadata || {},
        this.methodDescriptorFetchChatMessages,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChatService/FetchChatMessages',
    request,
    metadata || {},
    this.methodDescriptorFetchChatMessages);
  }

  methodDescriptorMarkAsViewed = new grpcWeb.MethodDescriptor(
    '/ChatService/MarkAsViewed',
    grpcWeb.MethodType.UNARY,
    protos_chat_pb.MarkViewedMessage,
    protos_chat_pb.MessageSuccess,
    (request: protos_chat_pb.MarkViewedMessage) => {
      return request.serializeBinary();
    },
    protos_chat_pb.MessageSuccess.deserializeBinary
  );

  markAsViewed(
    request: protos_chat_pb.MarkViewedMessage,
    metadata?: grpcWeb.Metadata | null): Promise<protos_chat_pb.MessageSuccess>;

  markAsViewed(
    request: protos_chat_pb.MarkViewedMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protos_chat_pb.MessageSuccess) => void): grpcWeb.ClientReadableStream<protos_chat_pb.MessageSuccess>;

  markAsViewed(
    request: protos_chat_pb.MarkViewedMessage,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protos_chat_pb.MessageSuccess) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChatService/MarkAsViewed',
        request,
        metadata || {},
        this.methodDescriptorMarkAsViewed,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChatService/MarkAsViewed',
    request,
    metadata || {},
    this.methodDescriptorMarkAsViewed);
  }

  methodDescriptorFetchPendingRooms = new grpcWeb.MethodDescriptor(
    '/ChatService/FetchPendingRooms',
    grpcWeb.MethodType.UNARY,
    protos_chat_pb.PendingRoomMessage,
    protos_chat_pb.PendingRoomResponse,
    (request: protos_chat_pb.PendingRoomMessage) => {
      return request.serializeBinary();
    },
    protos_chat_pb.PendingRoomResponse.deserializeBinary
  );

  fetchPendingRooms(
    request: protos_chat_pb.PendingRoomMessage,
    metadata?: grpcWeb.Metadata | null): Promise<protos_chat_pb.PendingRoomResponse>;

  fetchPendingRooms(
    request: protos_chat_pb.PendingRoomMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protos_chat_pb.PendingRoomResponse) => void): grpcWeb.ClientReadableStream<protos_chat_pb.PendingRoomResponse>;

  fetchPendingRooms(
    request: protos_chat_pb.PendingRoomMessage,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protos_chat_pb.PendingRoomResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChatService/FetchPendingRooms',
        request,
        metadata || {},
        this.methodDescriptorFetchPendingRooms,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChatService/FetchPendingRooms',
    request,
    metadata || {},
    this.methodDescriptorFetchPendingRooms);
  }

}

