/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.19.0
// source: protos/user.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as protos_user_pb from '../protos/user_pb'; // proto import: "protos/user.proto"


export class UserServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorFetchUserById = new grpcWeb.MethodDescriptor(
    '/UserService/FetchUserById',
    grpcWeb.MethodType.UNARY,
    protos_user_pb.IdMessage,
    protos_user_pb.User,
    (request: protos_user_pb.IdMessage) => {
      return request.serializeBinary();
    },
    protos_user_pb.User.deserializeBinary
  );

  fetchUserById(
    request: protos_user_pb.IdMessage,
    metadata?: grpcWeb.Metadata | null): Promise<protos_user_pb.User>;

  fetchUserById(
    request: protos_user_pb.IdMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protos_user_pb.User) => void): grpcWeb.ClientReadableStream<protos_user_pb.User>;

  fetchUserById(
    request: protos_user_pb.IdMessage,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protos_user_pb.User) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/UserService/FetchUserById',
        request,
        metadata || {},
        this.methodDescriptorFetchUserById,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/UserService/FetchUserById',
    request,
    metadata || {},
    this.methodDescriptorFetchUserById);
  }

  methodDescriptorEnableUser = new grpcWeb.MethodDescriptor(
    '/UserService/EnableUser',
    grpcWeb.MethodType.UNARY,
    protos_user_pb.EnableMessage,
    protos_user_pb.SuccessResponse,
    (request: protos_user_pb.EnableMessage) => {
      return request.serializeBinary();
    },
    protos_user_pb.SuccessResponse.deserializeBinary
  );

  enableUser(
    request: protos_user_pb.EnableMessage,
    metadata?: grpcWeb.Metadata | null): Promise<protos_user_pb.SuccessResponse>;

  enableUser(
    request: protos_user_pb.EnableMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protos_user_pb.SuccessResponse) => void): grpcWeb.ClientReadableStream<protos_user_pb.SuccessResponse>;

  enableUser(
    request: protos_user_pb.EnableMessage,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protos_user_pb.SuccessResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/UserService/EnableUser',
        request,
        metadata || {},
        this.methodDescriptorEnableUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/UserService/EnableUser',
    request,
    metadata || {},
    this.methodDescriptorEnableUser);
  }

  methodDescriptorFetchUsers = new grpcWeb.MethodDescriptor(
    '/UserService/FetchUsers',
    grpcWeb.MethodType.UNARY,
    protos_user_pb.FetchMessage,
    protos_user_pb.UsersResponse,
    (request: protos_user_pb.FetchMessage) => {
      return request.serializeBinary();
    },
    protos_user_pb.UsersResponse.deserializeBinary
  );

  fetchUsers(
    request: protos_user_pb.FetchMessage,
    metadata?: grpcWeb.Metadata | null): Promise<protos_user_pb.UsersResponse>;

  fetchUsers(
    request: protos_user_pb.FetchMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protos_user_pb.UsersResponse) => void): grpcWeb.ClientReadableStream<protos_user_pb.UsersResponse>;

  fetchUsers(
    request: protos_user_pb.FetchMessage,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protos_user_pb.UsersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/UserService/FetchUsers',
        request,
        metadata || {},
        this.methodDescriptorFetchUsers,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/UserService/FetchUsers',
    request,
    metadata || {},
    this.methodDescriptorFetchUsers);
  }

  methodDescriptorFindUsers = new grpcWeb.MethodDescriptor(
    '/UserService/FindUsers',
    grpcWeb.MethodType.UNARY,
    protos_user_pb.FindIdsMessage,
    protos_user_pb.UsersResponse,
    (request: protos_user_pb.FindIdsMessage) => {
      return request.serializeBinary();
    },
    protos_user_pb.UsersResponse.deserializeBinary
  );

  findUsers(
    request: protos_user_pb.FindIdsMessage,
    metadata?: grpcWeb.Metadata | null): Promise<protos_user_pb.UsersResponse>;

  findUsers(
    request: protos_user_pb.FindIdsMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protos_user_pb.UsersResponse) => void): grpcWeb.ClientReadableStream<protos_user_pb.UsersResponse>;

  findUsers(
    request: protos_user_pb.FindIdsMessage,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protos_user_pb.UsersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/UserService/FindUsers',
        request,
        metadata || {},
        this.methodDescriptorFindUsers,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/UserService/FindUsers',
    request,
    metadata || {},
    this.methodDescriptorFindUsers);
  }

  methodDescriptorSaveUser = new grpcWeb.MethodDescriptor(
    '/UserService/SaveUser',
    grpcWeb.MethodType.UNARY,
    protos_user_pb.User,
    protos_user_pb.SuccessResponse,
    (request: protos_user_pb.User) => {
      return request.serializeBinary();
    },
    protos_user_pb.SuccessResponse.deserializeBinary
  );

  saveUser(
    request: protos_user_pb.User,
    metadata?: grpcWeb.Metadata | null): Promise<protos_user_pb.SuccessResponse>;

  saveUser(
    request: protos_user_pb.User,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protos_user_pb.SuccessResponse) => void): grpcWeb.ClientReadableStream<protos_user_pb.SuccessResponse>;

  saveUser(
    request: protos_user_pb.User,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protos_user_pb.SuccessResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/UserService/SaveUser',
        request,
        metadata || {},
        this.methodDescriptorSaveUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/UserService/SaveUser',
    request,
    metadata || {},
    this.methodDescriptorSaveUser);
  }

  methodDescriptorCreateUser = new grpcWeb.MethodDescriptor(
    '/UserService/CreateUser',
    grpcWeb.MethodType.UNARY,
    protos_user_pb.UserCreateMessage,
    protos_user_pb.SuccessResponse,
    (request: protos_user_pb.UserCreateMessage) => {
      return request.serializeBinary();
    },
    protos_user_pb.SuccessResponse.deserializeBinary
  );

  createUser(
    request: protos_user_pb.UserCreateMessage,
    metadata?: grpcWeb.Metadata | null): Promise<protos_user_pb.SuccessResponse>;

  createUser(
    request: protos_user_pb.UserCreateMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protos_user_pb.SuccessResponse) => void): grpcWeb.ClientReadableStream<protos_user_pb.SuccessResponse>;

  createUser(
    request: protos_user_pb.UserCreateMessage,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protos_user_pb.SuccessResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/UserService/CreateUser',
        request,
        metadata || {},
        this.methodDescriptorCreateUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/UserService/CreateUser',
    request,
    metadata || {},
    this.methodDescriptorCreateUser);
  }

  methodDescriptorResendEmailVerification = new grpcWeb.MethodDescriptor(
    '/UserService/ResendEmailVerification',
    grpcWeb.MethodType.UNARY,
    protos_user_pb.IdMessage,
    protos_user_pb.SuccessResponse,
    (request: protos_user_pb.IdMessage) => {
      return request.serializeBinary();
    },
    protos_user_pb.SuccessResponse.deserializeBinary
  );

  resendEmailVerification(
    request: protos_user_pb.IdMessage,
    metadata?: grpcWeb.Metadata | null): Promise<protos_user_pb.SuccessResponse>;

  resendEmailVerification(
    request: protos_user_pb.IdMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protos_user_pb.SuccessResponse) => void): grpcWeb.ClientReadableStream<protos_user_pb.SuccessResponse>;

  resendEmailVerification(
    request: protos_user_pb.IdMessage,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protos_user_pb.SuccessResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/UserService/ResendEmailVerification',
        request,
        metadata || {},
        this.methodDescriptorResendEmailVerification,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/UserService/ResendEmailVerification',
    request,
    metadata || {},
    this.methodDescriptorResendEmailVerification);
  }

  methodDescriptorUpdateData = new grpcWeb.MethodDescriptor(
    '/UserService/UpdateData',
    grpcWeb.MethodType.UNARY,
    protos_user_pb.UpdateDataMessage,
    protos_user_pb.SuccessResponse,
    (request: protos_user_pb.UpdateDataMessage) => {
      return request.serializeBinary();
    },
    protos_user_pb.SuccessResponse.deserializeBinary
  );

  updateData(
    request: protos_user_pb.UpdateDataMessage,
    metadata?: grpcWeb.Metadata | null): Promise<protos_user_pb.SuccessResponse>;

  updateData(
    request: protos_user_pb.UpdateDataMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protos_user_pb.SuccessResponse) => void): grpcWeb.ClientReadableStream<protos_user_pb.SuccessResponse>;

  updateData(
    request: protos_user_pb.UpdateDataMessage,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protos_user_pb.SuccessResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/UserService/UpdateData',
        request,
        metadata || {},
        this.methodDescriptorUpdateData,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/UserService/UpdateData',
    request,
    metadata || {},
    this.methodDescriptorUpdateData);
  }

  methodDescriptorDeleteUser = new grpcWeb.MethodDescriptor(
    '/UserService/DeleteUser',
    grpcWeb.MethodType.UNARY,
    protos_user_pb.IdMessage,
    protos_user_pb.SuccessResponse,
    (request: protos_user_pb.IdMessage) => {
      return request.serializeBinary();
    },
    protos_user_pb.SuccessResponse.deserializeBinary
  );

  deleteUser(
    request: protos_user_pb.IdMessage,
    metadata?: grpcWeb.Metadata | null): Promise<protos_user_pb.SuccessResponse>;

  deleteUser(
    request: protos_user_pb.IdMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protos_user_pb.SuccessResponse) => void): grpcWeb.ClientReadableStream<protos_user_pb.SuccessResponse>;

  deleteUser(
    request: protos_user_pb.IdMessage,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protos_user_pb.SuccessResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/UserService/DeleteUser',
        request,
        metadata || {},
        this.methodDescriptorDeleteUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/UserService/DeleteUser',
    request,
    metadata || {},
    this.methodDescriptorDeleteUser);
  }

  methodDescriptorSetNotificationEnabled = new grpcWeb.MethodDescriptor(
    '/UserService/SetNotificationEnabled',
    grpcWeb.MethodType.UNARY,
    protos_user_pb.UpdateNotificationMessage,
    protos_user_pb.SuccessResponse,
    (request: protos_user_pb.UpdateNotificationMessage) => {
      return request.serializeBinary();
    },
    protos_user_pb.SuccessResponse.deserializeBinary
  );

  setNotificationEnabled(
    request: protos_user_pb.UpdateNotificationMessage,
    metadata?: grpcWeb.Metadata | null): Promise<protos_user_pb.SuccessResponse>;

  setNotificationEnabled(
    request: protos_user_pb.UpdateNotificationMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protos_user_pb.SuccessResponse) => void): grpcWeb.ClientReadableStream<protos_user_pb.SuccessResponse>;

  setNotificationEnabled(
    request: protos_user_pb.UpdateNotificationMessage,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protos_user_pb.SuccessResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/UserService/SetNotificationEnabled',
        request,
        metadata || {},
        this.methodDescriptorSetNotificationEnabled,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/UserService/SetNotificationEnabled',
    request,
    metadata || {},
    this.methodDescriptorSetNotificationEnabled);
  }

  methodDescriptorBlockUser = new grpcWeb.MethodDescriptor(
    '/UserService/BlockUser',
    grpcWeb.MethodType.UNARY,
    protos_user_pb.BlockUserMessage,
    protos_user_pb.SuccessResponse,
    (request: protos_user_pb.BlockUserMessage) => {
      return request.serializeBinary();
    },
    protos_user_pb.SuccessResponse.deserializeBinary
  );

  blockUser(
    request: protos_user_pb.BlockUserMessage,
    metadata?: grpcWeb.Metadata | null): Promise<protos_user_pb.SuccessResponse>;

  blockUser(
    request: protos_user_pb.BlockUserMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protos_user_pb.SuccessResponse) => void): grpcWeb.ClientReadableStream<protos_user_pb.SuccessResponse>;

  blockUser(
    request: protos_user_pb.BlockUserMessage,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protos_user_pb.SuccessResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/UserService/BlockUser',
        request,
        metadata || {},
        this.methodDescriptorBlockUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/UserService/BlockUser',
    request,
    metadata || {},
    this.methodDescriptorBlockUser);
  }

  methodDescriptorFetchBlockedUsers = new grpcWeb.MethodDescriptor(
    '/UserService/FetchBlockedUsers',
    grpcWeb.MethodType.UNARY,
    protos_user_pb.IdMessage,
    protos_user_pb.UsersResponse,
    (request: protos_user_pb.IdMessage) => {
      return request.serializeBinary();
    },
    protos_user_pb.UsersResponse.deserializeBinary
  );

  fetchBlockedUsers(
    request: protos_user_pb.IdMessage,
    metadata?: grpcWeb.Metadata | null): Promise<protos_user_pb.UsersResponse>;

  fetchBlockedUsers(
    request: protos_user_pb.IdMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protos_user_pb.UsersResponse) => void): grpcWeb.ClientReadableStream<protos_user_pb.UsersResponse>;

  fetchBlockedUsers(
    request: protos_user_pb.IdMessage,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protos_user_pb.UsersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/UserService/FetchBlockedUsers',
        request,
        metadata || {},
        this.methodDescriptorFetchBlockedUsers,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/UserService/FetchBlockedUsers',
    request,
    metadata || {},
    this.methodDescriptorFetchBlockedUsers);
  }

  methodDescriptorFetchUserDetails = new grpcWeb.MethodDescriptor(
    '/UserService/FetchUserDetails',
    grpcWeb.MethodType.UNARY,
    protos_user_pb.IdMessage,
    protos_user_pb.UserDetailsResponse,
    (request: protos_user_pb.IdMessage) => {
      return request.serializeBinary();
    },
    protos_user_pb.UserDetailsResponse.deserializeBinary
  );

  fetchUserDetails(
    request: protos_user_pb.IdMessage,
    metadata?: grpcWeb.Metadata | null): Promise<protos_user_pb.UserDetailsResponse>;

  fetchUserDetails(
    request: protos_user_pb.IdMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protos_user_pb.UserDetailsResponse) => void): grpcWeb.ClientReadableStream<protos_user_pb.UserDetailsResponse>;

  fetchUserDetails(
    request: protos_user_pb.IdMessage,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protos_user_pb.UserDetailsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/UserService/FetchUserDetails',
        request,
        metadata || {},
        this.methodDescriptorFetchUserDetails,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/UserService/FetchUserDetails',
    request,
    metadata || {},
    this.methodDescriptorFetchUserDetails);
  }

  methodDescriptorCheckLoginUser = new grpcWeb.MethodDescriptor(
    '/UserService/CheckLoginUser',
    grpcWeb.MethodType.UNARY,
    protos_user_pb.UserCheckLoginMessage,
    protos_user_pb.UserCheckResponse,
    (request: protos_user_pb.UserCheckLoginMessage) => {
      return request.serializeBinary();
    },
    protos_user_pb.UserCheckResponse.deserializeBinary
  );

  checkLoginUser(
    request: protos_user_pb.UserCheckLoginMessage,
    metadata?: grpcWeb.Metadata | null): Promise<protos_user_pb.UserCheckResponse>;

  checkLoginUser(
    request: protos_user_pb.UserCheckLoginMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protos_user_pb.UserCheckResponse) => void): grpcWeb.ClientReadableStream<protos_user_pb.UserCheckResponse>;

  checkLoginUser(
    request: protos_user_pb.UserCheckLoginMessage,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protos_user_pb.UserCheckResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/UserService/CheckLoginUser',
        request,
        metadata || {},
        this.methodDescriptorCheckLoginUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/UserService/CheckLoginUser',
    request,
    metadata || {},
    this.methodDescriptorCheckLoginUser);
  }

  methodDescriptorRegisterPassword = new grpcWeb.MethodDescriptor(
    '/UserService/RegisterPassword',
    grpcWeb.MethodType.UNARY,
    protos_user_pb.RegisterPasswordMessage,
    protos_user_pb.SuccessResponse,
    (request: protos_user_pb.RegisterPasswordMessage) => {
      return request.serializeBinary();
    },
    protos_user_pb.SuccessResponse.deserializeBinary
  );

  registerPassword(
    request: protos_user_pb.RegisterPasswordMessage,
    metadata?: grpcWeb.Metadata | null): Promise<protos_user_pb.SuccessResponse>;

  registerPassword(
    request: protos_user_pb.RegisterPasswordMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protos_user_pb.SuccessResponse) => void): grpcWeb.ClientReadableStream<protos_user_pb.SuccessResponse>;

  registerPassword(
    request: protos_user_pb.RegisterPasswordMessage,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protos_user_pb.SuccessResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/UserService/RegisterPassword',
        request,
        metadata || {},
        this.methodDescriptorRegisterPassword,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/UserService/RegisterPassword',
    request,
    metadata || {},
    this.methodDescriptorRegisterPassword);
  }

  methodDescriptorChangePassword = new grpcWeb.MethodDescriptor(
    '/UserService/ChangePassword',
    grpcWeb.MethodType.UNARY,
    protos_user_pb.ChangePasswordMessage,
    protos_user_pb.SuccessResponse,
    (request: protos_user_pb.ChangePasswordMessage) => {
      return request.serializeBinary();
    },
    protos_user_pb.SuccessResponse.deserializeBinary
  );

  changePassword(
    request: protos_user_pb.ChangePasswordMessage,
    metadata?: grpcWeb.Metadata | null): Promise<protos_user_pb.SuccessResponse>;

  changePassword(
    request: protos_user_pb.ChangePasswordMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protos_user_pb.SuccessResponse) => void): grpcWeb.ClientReadableStream<protos_user_pb.SuccessResponse>;

  changePassword(
    request: protos_user_pb.ChangePasswordMessage,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protos_user_pb.SuccessResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/UserService/ChangePassword',
        request,
        metadata || {},
        this.methodDescriptorChangePassword,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/UserService/ChangePassword',
    request,
    metadata || {},
    this.methodDescriptorChangePassword);
  }

  methodDescriptorCheckPasswordToken = new grpcWeb.MethodDescriptor(
    '/UserService/CheckPasswordToken',
    grpcWeb.MethodType.UNARY,
    protos_user_pb.CheckTokenMessage,
    protos_user_pb.ValidToken,
    (request: protos_user_pb.CheckTokenMessage) => {
      return request.serializeBinary();
    },
    protos_user_pb.ValidToken.deserializeBinary
  );

  checkPasswordToken(
    request: protos_user_pb.CheckTokenMessage,
    metadata?: grpcWeb.Metadata | null): Promise<protos_user_pb.ValidToken>;

  checkPasswordToken(
    request: protos_user_pb.CheckTokenMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protos_user_pb.ValidToken) => void): grpcWeb.ClientReadableStream<protos_user_pb.ValidToken>;

  checkPasswordToken(
    request: protos_user_pb.CheckTokenMessage,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protos_user_pb.ValidToken) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/UserService/CheckPasswordToken',
        request,
        metadata || {},
        this.methodDescriptorCheckPasswordToken,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/UserService/CheckPasswordToken',
    request,
    metadata || {},
    this.methodDescriptorCheckPasswordToken);
  }

  methodDescriptorChangeLicenseStatus = new grpcWeb.MethodDescriptor(
    '/UserService/ChangeLicenseStatus',
    grpcWeb.MethodType.UNARY,
    protos_user_pb.ChangeLicenseStatusMessage,
    protos_user_pb.SuccessResponse,
    (request: protos_user_pb.ChangeLicenseStatusMessage) => {
      return request.serializeBinary();
    },
    protos_user_pb.SuccessResponse.deserializeBinary
  );

  changeLicenseStatus(
    request: protos_user_pb.ChangeLicenseStatusMessage,
    metadata?: grpcWeb.Metadata | null): Promise<protos_user_pb.SuccessResponse>;

  changeLicenseStatus(
    request: protos_user_pb.ChangeLicenseStatusMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protos_user_pb.SuccessResponse) => void): grpcWeb.ClientReadableStream<protos_user_pb.SuccessResponse>;

  changeLicenseStatus(
    request: protos_user_pb.ChangeLicenseStatusMessage,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protos_user_pb.SuccessResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/UserService/ChangeLicenseStatus',
        request,
        metadata || {},
        this.methodDescriptorChangeLicenseStatus,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/UserService/ChangeLicenseStatus',
    request,
    metadata || {},
    this.methodDescriptorChangeLicenseStatus);
  }

}

export class UserSettingsServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorSetUserSettings = new grpcWeb.MethodDescriptor(
    '/UserSettingsService/SetUserSettings',
    grpcWeb.MethodType.UNARY,
    protos_user_pb.UserSettings,
    protos_user_pb.UserSettings,
    (request: protos_user_pb.UserSettings) => {
      return request.serializeBinary();
    },
    protos_user_pb.UserSettings.deserializeBinary
  );

  setUserSettings(
    request: protos_user_pb.UserSettings,
    metadata?: grpcWeb.Metadata | null): Promise<protos_user_pb.UserSettings>;

  setUserSettings(
    request: protos_user_pb.UserSettings,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protos_user_pb.UserSettings) => void): grpcWeb.ClientReadableStream<protos_user_pb.UserSettings>;

  setUserSettings(
    request: protos_user_pb.UserSettings,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protos_user_pb.UserSettings) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/UserSettingsService/SetUserSettings',
        request,
        metadata || {},
        this.methodDescriptorSetUserSettings,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/UserSettingsService/SetUserSettings',
    request,
    metadata || {},
    this.methodDescriptorSetUserSettings);
  }

  methodDescriptorFetchUserSettings = new grpcWeb.MethodDescriptor(
    '/UserSettingsService/FetchUserSettings',
    grpcWeb.MethodType.UNARY,
    protos_user_pb.FetchSettingsMessage,
    protos_user_pb.UserSettingResponse,
    (request: protos_user_pb.FetchSettingsMessage) => {
      return request.serializeBinary();
    },
    protos_user_pb.UserSettingResponse.deserializeBinary
  );

  fetchUserSettings(
    request: protos_user_pb.FetchSettingsMessage,
    metadata?: grpcWeb.Metadata | null): Promise<protos_user_pb.UserSettingResponse>;

  fetchUserSettings(
    request: protos_user_pb.FetchSettingsMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protos_user_pb.UserSettingResponse) => void): grpcWeb.ClientReadableStream<protos_user_pb.UserSettingResponse>;

  fetchUserSettings(
    request: protos_user_pb.FetchSettingsMessage,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protos_user_pb.UserSettingResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/UserSettingsService/FetchUserSettings',
        request,
        metadata || {},
        this.methodDescriptorFetchUserSettings,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/UserSettingsService/FetchUserSettings',
    request,
    metadata || {},
    this.methodDescriptorFetchUserSettings);
  }

  methodDescriptorDeleteUserSettings = new grpcWeb.MethodDescriptor(
    '/UserSettingsService/DeleteUserSettings',
    grpcWeb.MethodType.UNARY,
    protos_user_pb.IdMessage,
    protos_user_pb.SuccessResponse,
    (request: protos_user_pb.IdMessage) => {
      return request.serializeBinary();
    },
    protos_user_pb.SuccessResponse.deserializeBinary
  );

  deleteUserSettings(
    request: protos_user_pb.IdMessage,
    metadata?: grpcWeb.Metadata | null): Promise<protos_user_pb.SuccessResponse>;

  deleteUserSettings(
    request: protos_user_pb.IdMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protos_user_pb.SuccessResponse) => void): grpcWeb.ClientReadableStream<protos_user_pb.SuccessResponse>;

  deleteUserSettings(
    request: protos_user_pb.IdMessage,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protos_user_pb.SuccessResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/UserSettingsService/DeleteUserSettings',
        request,
        metadata || {},
        this.methodDescriptorDeleteUserSettings,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/UserSettingsService/DeleteUserSettings',
    request,
    metadata || {},
    this.methodDescriptorDeleteUserSettings);
  }

}

