import { Role } from "../../server/user/protos/auth_pb";
import { User, UserSettings } from "../../server/user/protos/user_pb";

export const USERS_LOADING = "USERS_LOADING";
export const USERS_ERROR = "USERS_ERROR";
export const USERS_LIST = "USERS_LIST";
export const USERS_LIST_BLOCKED = "USERS_LIST_BLOCKED";
export const USERS_SELECTED = "USERS_SELECTED";
export const SET_ROLES_LIST = "SET_ROLES_LIST";

export const SET_SETTINGS_FILTERS_LIST = "SET_SETTINGS_FILTERS_LIST";
export const SET_NEW_SETTING_FILTER = "SET_NEW_SETTING_FILTER";
export const SET_DELETE_SETTING_FILTER = "SET_DELETE_SETTING_FILTER";

export interface SetSettingsFiltersList {
  type: typeof SET_SETTINGS_FILTERS_LIST;
  payload: UserSettings.AsObject[];
}

export interface SetNewSettingFilter {
  type: typeof SET_NEW_SETTING_FILTER;
  payload: UserSettings.AsObject;
}

export interface SetDeleteSettingFilter {
  type: typeof SET_DELETE_SETTING_FILTER;
  payload: string;
}

export interface SetRolesList {
  type: typeof SET_ROLES_LIST;
  payload: Role.AsObject[];
}
//================================================================
export interface UsersLoading {
  type: typeof USERS_LOADING;
}

export interface UsersError {
  type: typeof USERS_ERROR;
}

export interface UsersBlockedList {
  type: typeof USERS_LIST_BLOCKED;
  payload: User.AsObject[];
}

export interface UsersList {
  type: typeof USERS_LIST;
  payload: User.AsObject[];
}

export interface UsersSelected {
  type: typeof USERS_SELECTED;
  payload: User.AsObject;
}

export type UsersDispatchTypes =
  | UsersBlockedList
  | SetDeleteSettingFilter
  | SetNewSettingFilter
  | SetSettingsFiltersList
  | UsersLoading
  | UsersError
  | UsersList
  | UsersSelected
  | SetRolesList;
