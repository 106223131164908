import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RootStore } from "../../../redux/Store";

import { grpcUpdateSetting } from "../../../redux/licenses/licensesActions";

import TextFieldComponent from "../../components/text-field-component";
import ActionButtons from "../../components/Action-buttons";
import { Edit } from "../../../assets/svg/index";
import { licenseI } from "../../../redux/licenses/licensesReducer";
import { onlyDigitsNoSpace, validNumbersRegex } from "../../../utils/utils";

interface TileSettingsServicesProps {
  fetchAllLicenses: any;
}

export const TileSettingsServices: React.FunctionComponent<TileSettingsServicesProps> =
  ({ fetchAllLicenses }: any) => {
    const selectedLicense: licenseI | undefined = useSelector(
      (globalState: RootStore) => globalState.licenses.selectedLicense
    );

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [state, setState] = useState({
      enable: selectedLicense?.trackingSettings.enable
        ? selectedLicense.trackingSettings.enable
        : false,
      trackingType: selectedLicense?.trackingSettings.trackingType
        ? selectedLicense.trackingSettings.trackingType
        : "BOSS",
      distanceInterval:
        !selectedLicense?.trackingSettings.distanceInterval &&
        selectedLicense?.trackingSettings.distanceInterval !== ""
          ? 0
          : parseInt(selectedLicense.trackingSettings.distanceInterval),
      timeInterval:
        !selectedLicense?.trackingSettings.timeInterval &&
        selectedLicense?.trackingSettings.timeInterval !== ""
          ? 0
          : parseInt(selectedLicense.trackingSettings.timeInterval),
      teamDoneReportsDays:
        !selectedLicense?.licenseSettings.reportsDaysForTeam &&
        selectedLicense?.licenseSettings.reportsDaysForTeam !== 0
          ? 0
          : selectedLicense.licenseSettings.reportsDaysForTeam,
      adminDoneReportsDays:
        !selectedLicense?.licenseSettings.reportsDaysForAdmin &&
        selectedLicense?.licenseSettings.reportsDaysForAdmin !== 0
          ? 0
          : selectedLicense.licenseSettings.reportsDaysForAdmin,
      userDoneReportsDays:
        !selectedLicense?.licenseSettings.reportsDaysForUser &&
        selectedLicense?.licenseSettings.reportsDaysForUser !== 0
          ? 0
          : selectedLicense.licenseSettings.reportsDaysForUser,
      editMode: false,
      //Validation variables
      distanceIntervalInvalid: false,
      timeIntervalInvalid: false,
      teamDoneReportsDaysInvalid: false,
      adminDoneReportsDaysInvalid: false,
      userDoneReportsDaysInvalid: false,
    });

    console.log("state", state);

    const changeToEdit = (value: any) => {
      setState((prevState) => ({
        ...prevState,
        editMode: value,
      }));

      if (!value) {
        setState((prevState) => ({
          ...prevState,
          enable: selectedLicense?.trackingSettings.enable
            ? selectedLicense.trackingSettings.enable
            : false,
          trackingType: selectedLicense?.trackingSettings.trackingType
            ? selectedLicense.trackingSettings.trackingType
            : "BOSS",
          distanceInterval:
            !selectedLicense?.trackingSettings.distanceInterval &&
            selectedLicense?.trackingSettings.distanceInterval !== ""
              ? 0
              : parseInt(selectedLicense.trackingSettings.distanceInterval),
          timeInterval:
            !selectedLicense?.trackingSettings.timeInterval &&
            selectedLicense?.trackingSettings.timeInterval !== ""
              ? 0
              : parseInt(selectedLicense.trackingSettings.timeInterval),
          teamDoneReportsDays:
            !selectedLicense?.licenseSettings.reportsDaysForTeam &&
            selectedLicense?.licenseSettings.reportsDaysForTeam !== 0
              ? 0
              : selectedLicense.licenseSettings.reportsDaysForTeam,
          adminDoneReportsDays:
            !selectedLicense?.licenseSettings.reportsDaysForAdmin &&
            selectedLicense?.licenseSettings.reportsDaysForAdmin !== 0
              ? 0
              : selectedLicense.licenseSettings.reportsDaysForAdmin,
          userDoneReportsDays:
            !selectedLicense?.licenseSettings.reportsDaysForUser &&
            selectedLicense?.licenseSettings.reportsDaysForUser !== 0
              ? 0
              : selectedLicense.licenseSettings.reportsDaysForUser,
        }));
      }
    };

    const handleForm = (name: any, event: any) => {
      if (validNumbersRegex.test(event)) {
        setState((prevState) => ({
          ...prevState,
          [name]: event,
        }));
      }

      if (name === "distanceInterval")
        setState((prevState) => ({
          ...prevState,
          distanceIntervalInvalid: false,
        }));
      if (name === "timeInterval")
        setState((prevState) => ({
          ...prevState,
          timeIntervalInvalid: false,
        }));
      if (name === "teamDoneReportsDays")
        setState((prevState) => ({
          ...prevState,
          teamDoneReportsDaysInvalid: false,
        }));
      if (name === "adminDoneReportsDays")
        setState((prevState) => ({
          ...prevState,
          adminDoneReportsDaysInvalid: false,
        }));
      if (name === "userDoneReportsDays")
        setState((prevState) => ({
          ...prevState,
          userDoneReportsDaysInvalid: false,
        }));
    };

    const updateProfile = () => {
      if (
        state.distanceInterval < 100 ||
        state.timeInterval < 15 ||
        state.teamDoneReportsDays < 1 ||
        state.adminDoneReportsDays < 1 ||
        state.userDoneReportsDays < 1
      ) {
        setState((prevState) => ({
          ...prevState,
          distanceIntervalInvalid: state.distanceInterval < 100 ? true : false,
          timeIntervalInvalid: state.timeInterval < 15 ? true : false,
          teamDoneReportsDaysInvalid:
            state.teamDoneReportsDays < 1 ? true : false,
          adminDoneReportsDaysInvalid:
            state.adminDoneReportsDays < 1 ? true : false,
          userDoneReportsDaysInvalid:
            state.userDoneReportsDays < 1 ? true : false,
        }));
        return;
      }

      if (selectedLicense) {
        let auxArray: any = [];

        if (selectedLicense.geometry.point) {
          let coordinates = selectedLicense.geometry.point
            ? selectedLicense.geometry.point
            : [];

          coordinates.map((coord: any) => {
            auxArray.push({
              lat: coord.lng,
              lng: coord.lat,
            });
          });
        }
        dispatch(
          grpcUpdateSetting(
            {
              _id: selectedLicense.settingsId,
              licenseId: selectedLicense._id,
              cities: selectedLicense.cities,

              points: auxArray,

              distanceInterval: state.enable
                ? state.distanceInterval.toString()
                : selectedLicense.trackingSettings.distanceInterval,
              timeInterval: state.enable
                ? state.timeInterval.toString()
                : selectedLicense.trackingSettings.timeInterval,
              enable: state.enable,
              trackingType: state.enable
                ? state.trackingType
                : selectedLicense.trackingSettings.trackingType,
              trackingMode: selectedLicense.trackingSettings.trackingMode,

              zoom: selectedLicense.licenseSettings.zoom,
              coords: {
                lng: selectedLicense?.licenseSettings.coords.lat,
                lat: selectedLicense?.licenseSettings.coords.lng,
              },
              jobTypes: selectedLicense.licenseSettings.jobTypes,
              daysAdmin: state.enable
                ? state.adminDoneReportsDays
                : selectedLicense.licenseSettings.reportsDaysForAdmin,
              daysTeam: state.enable
                ? state.teamDoneReportsDays
                : selectedLicense.licenseSettings.reportsDaysForTeam,
              daysUser: state.enable
                ? state.userDoneReportsDays
                : selectedLicense.licenseSettings.reportsDaysForUser,
            },
            () => {
              fetchAllLicenses();
              changeToEdit(false);
            }
          )
        );
      }
    };

    console.log("state", state);

    const getValidSubmit = () => {
      if (state.enable) {
        if (
          (state.distanceInterval || state.distanceInterval === 0) &&
          (state.timeInterval || state.timeInterval === 0) &&
          (state.teamDoneReportsDays || state.teamDoneReportsDays === 0) &&
          (state.adminDoneReportsDays || state.adminDoneReportsDays === 0) &&
          (state.userDoneReportsDays || state.userDoneReportsDays === 0)
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    };

    return !state.editMode ? (
      <div className="tile-card tile-card-services">
        <div className="tile-card-header">
          <div className="tile-title">{t("tabs.title.settings.services")}</div>
          <div
            className="tile-edit-button"
            onClick={() => {
              changeToEdit(true);
            }}
          >
            <Edit></Edit>
          </div>
        </div>
        <div className="tile-card-body">
          <div className="edit-box services-public">
            {/* byRoute Field */}
            <div className="options-body-item-big ">
              <div className="text">{t("name.super.tracker.active")}</div>{" "}
              <div className="selector-box full-width-select">
                <div className="text">
                  {selectedLicense?.trackingSettings.enable
                    ? t("name.super.on")
                    : t("name.super.off")}{" "}
                </div>{" "}
              </div>
            </div>
            {/* byPosition Field */}
            <div
              className={
                selectedLicense?.trackingSettings.enable
                  ? "options-body-item-big "
                  : "options-body-item-big hidden-box"
              }
            >
              <div className="text">{t("name.super.tracker.type")}</div>{" "}
              <div className="selector-box full-width-select">
                <div className="text">
                  {selectedLicense?.trackingSettings.trackingType !== "BOSS"
                    ? t("name.super.all")
                    : t("name.boss")}{" "}
                </div>{" "}
              </div>
            </div>
            {/* distanceInterval Field */}
            <div
              className={
                selectedLicense?.trackingSettings.enable
                  ? "options-body-item-big "
                  : "options-body-item-big hidden-box"
              }
            >
              <div className="text">{t("name.super.each.meters")}</div>{" "}
              <div className="selector-box full-width-select">
                <div className="text">
                  {parseInt(
                    selectedLicense?.trackingSettings.distanceInterval || "0"
                  )}{" "}
                  {selectedLicense?.trackingSettings.distanceInterval === "1"
                    ? t("name.super.meter")
                    : t("name.super.meters")}
                </div>{" "}
              </div>
            </div>
            {/* timeInterval Field */}
            <div
              className={
                selectedLicense?.trackingSettings.enable
                  ? "options-body-item-big "
                  : "options-body-item-big hidden-box"
              }
            >
              <div className="text">{t("name.super.each.mins")}</div>{" "}
              <div className="selector-box full-width-select">
                <div className="text">
                  {parseInt(
                    selectedLicense?.trackingSettings.timeInterval || "0"
                  )}{" "}
                  {selectedLicense?.trackingSettings.timeInterval === "1"
                    ? t("name.super.min")
                    : t("name.super.mins")}
                </div>{" "}
              </div>
            </div>
            {/* teamComplete Field */}
            <div
              className={
                selectedLicense?.trackingSettings.enable
                  ? "options-body-item-big "
                  : "options-body-item-big hidden-box"
              }
            >
              <div className="text">{t("name.super.team.days")}</div>{" "}
              <div className="selector-box full-width-select">
                <div className="text">
                  {selectedLicense?.licenseSettings.reportsDaysForTeam || "0"}{" "}
                  {selectedLicense?.licenseSettings.reportsDaysForTeam === 1
                    ? t("name.super.day")
                    : t("name.super.days")}
                </div>{" "}
              </div>
            </div>
            {/* adminComplete Field */}
            <div
              className={
                selectedLicense?.trackingSettings.enable
                  ? "options-body-item-big "
                  : "options-body-item-big hidden-box"
              }
            >
              <div className="text">{t("name.super.admin.days")}</div>{" "}
              <div className="selector-box full-width-select">
                <div className="text">
                  {selectedLicense?.licenseSettings.reportsDaysForAdmin || "0"}{" "}
                  {selectedLicense?.licenseSettings.reportsDaysForAdmin === 1
                    ? t("name.super.day")
                    : t("name.super.days")}
                </div>{" "}
              </div>
            </div>
            {/* userComplete Field */}
            <div
              className={
                selectedLicense?.trackingSettings.enable
                  ? "options-body-item-big "
                  : "options-body-item-big hidden-box"
              }
            >
              <div className="text">{t("name.super.user.days")}</div>{" "}
              <div className="selector-box full-width-select">
                <div className="text">
                  {selectedLicense?.licenseSettings.reportsDaysForUser || "0"}{" "}
                  {selectedLicense?.licenseSettings.reportsDaysForUser === 1
                    ? t("name.super.day")
                    : t("name.super.days")}
                </div>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className="tile-card tile-card-services tile-card-to-edit">
        <div className="tile-card-header">
          <div className="tile-title">{t("tabs.title.settings.services")}</div>
          <div></div>
        </div>
        <div className="tile-card-body">
          <div className="edit-box services-public">
            {/* byRoute Field */}
            <div className="options-body-item-big ">
              <div className="text">{t("name.super.tracker.active")}</div>{" "}
              <div className="switch-box-super switch-update">
                <p>{t("name.super.off")}</p>
                <label
                  className={
                    state.enable
                      ? "switch checkbox-active"
                      : "switch checkbox-disabled"
                  }
                >
                  <input
                    type="checkbox"
                    checked={state.enable}
                    onChange={() => {
                      setState((prevState) => ({
                        ...prevState,
                        enable: !state.enable,
                      }));
                    }}
                  ></input>
                  <span className="slider round"></span>
                </label>
                <p>{t("name.super.on")}</p>
              </div>
            </div>
            {/* byPosition Field */}
            <div
              className={
                state.enable
                  ? "options-body-item-big "
                  : "options-body-item-big hidden-box"
              }
            >
              <div className="text">{t("name.super.tracker.type")}</div>{" "}
              <div className="switch-box-super switch-update">
                <p>{t("name.boss")}</p>
                <label
                  className={
                    state.trackingType !== "BOSS"
                      ? "switch checkbox-active"
                      : "switch checkbox-deactive"
                  }
                >
                  <input
                    type="checkbox"
                    checked={state.trackingType !== "BOSS"}
                    onChange={() => {
                      setState((prevState) => ({
                        ...prevState,
                        trackingType:
                          state.trackingType === "BOSS" ? "ALL" : "BOSS",
                      }));
                    }}
                  ></input>
                  <span className="slider round"></span>
                </label>
                <p>{t("name.super.all")}</p>
              </div>
            </div>
            {/* distanceInterval Field */}
            <div
              className={
                state.enable
                  ? "options-body-item-big "
                  : "options-body-item-big hidden-box"
              }
            >
              <div className="text">{t("name.super.each.meters")}</div>{" "}
              <div className="selector-box full-width-select">
                <TextFieldComponent
                  placeholder={"100"}
                  onChange={(event: any) => {
                    handleForm("distanceInterval", event);
                  }}
                  value={state.distanceInterval}
                  regExp={onlyDigitsNoSpace}
                  invalid={state.distanceIntervalInvalid}
                  maxValue={4}
                  message={t("error.super.message.minimun") + ": 100"}
                ></TextFieldComponent>
              </div>
            </div>
            {/* timeInterval Field */}
            <div
              className={
                state.enable
                  ? "options-body-item-big "
                  : "options-body-item-big hidden-box"
              }
            >
              <div className="text">{t("name.super.each.mins")}</div>{" "}
              <div className="selector-box full-width-select">
                <TextFieldComponent
                  placeholder={"15"}
                  onChange={(event: any) => {
                    handleForm("timeInterval", event);
                  }}
                  value={state.timeInterval}
                  regExp={onlyDigitsNoSpace}
                  invalid={state.timeIntervalInvalid}
                  maxValue={4}
                  message={t("error.super.message.minimun") + ": 15"}
                ></TextFieldComponent>
              </div>
            </div>
            {/* teamComplete Field */}
            <div
              className={
                state.enable
                  ? "options-body-item-big "
                  : "options-body-item-big hidden-box"
              }
            >
              <div className="text">
                {t("name.super.team.days")} {t("name.super.days")}
              </div>{" "}
              <div className="selector-box full-width-select">
                <TextFieldComponent
                  placeholder={"1"}
                  onChange={(event: any) => {
                    handleForm("teamDoneReportsDays", event);
                  }}
                  value={state.teamDoneReportsDays}
                  regExp={onlyDigitsNoSpace}
                  invalid={state.teamDoneReportsDaysInvalid}
                  maxValue={2}
                  message={t("error.super.message.minimun") + ": 1"}
                ></TextFieldComponent>
              </div>
            </div>
            {/* adminComplete Field */}
            <div
              className={
                state.enable
                  ? "options-body-item-big "
                  : "options-body-item-big hidden-box"
              }
            >
              <div className="text">
                {t("name.super.admin.days")} {t("name.super.days")}
              </div>{" "}
              <div className="selector-box full-width-select">
                <TextFieldComponent
                  placeholder={"1"}
                  onChange={(event: any) => {
                    handleForm("adminDoneReportsDays", event);
                  }}
                  value={state.adminDoneReportsDays}
                  regExp={onlyDigitsNoSpace}
                  invalid={state.adminDoneReportsDaysInvalid}
                  maxValue={2}
                  message={t("error.super.message.minimun") + ": 1"}
                ></TextFieldComponent>
              </div>
            </div>
            {/* userComplete Field */}
            <div
              className={
                state.enable
                  ? "options-body-item-big "
                  : "options-body-item-big hidden-box"
              }
            >
              <div className="text">
                {t("name.super.user.days")} {t("name.super.days")}
              </div>{" "}
              <div className="selector-box full-width-select">
                <TextFieldComponent
                  placeholder={"1"}
                  onChange={(event: any) => {
                    handleForm("userDoneReportsDays", event);
                  }}
                  value={state.userDoneReportsDays}
                  regExp={onlyDigitsNoSpace}
                  invalid={state.userDoneReportsDaysInvalid}
                  maxValue={2}
                  message={t("error.super.message.minimun") + ": 1"}
                ></TextFieldComponent>
              </div>
            </div>
            <div className="buttons-box-edit">
              <ActionButtons
                doneText={
                  selectedLicense ? t("submit.save") : t("submit.confirm")
                }
                clickable={getValidSubmit()}
                doneClick={() => {
                  updateProfile();
                }}
                cancelClick={() => {
                  changeToEdit(false);
                }}
                cancelText={t("submit.cancel")}
              ></ActionButtons>
            </div>
          </div>
        </div>
      </div>
    );
  };

export default TileSettingsServices;
