import React from "react";
import {
  pending,
  inProgress,
  REPORT_PROGRESS_ASSIGNED,
  REPORT_PROGRESS_PROGRESS,
} from "../../../const/const";
import moment from "moment";
import {
  Report,
  ReportStatus,
  ReportWorkStatus,
} from "../../../server/report/protos/report_pb";

interface ReportIconProps {
  item: Report.AsObject;
  index: any;
  lat: any;
  lng: any;
  zoom: any;
}

export const ReportIcon: React.FunctionComponent<ReportIconProps> = ({
  item,
  index,
  lat,
  lng,
  zoom,
}: ReportIconProps) => {
  const getClass = () => {
    let valid = false;

    if (item.reportstatus === ReportStatus.REPORT_PENDING)
      return "reported-type ";
    else if (
      item.reportstatus === ReportStatus.REPORT_IN_PROGRESS &&
      item.workstatus === ReportWorkStatus.WORK_ASSIGNED
    )
      return "assigned-type ";
    else if (
      item.reportstatus === ReportStatus.REPORT_IN_PROGRESS &&
      item.workstatus === ReportWorkStatus.WORK_IN_PROGRESS
    ) {
      if (item.estimatedtime && item.estimatedtime > 0) {
        let date = item.workProgressDate;
        let dateUpdated = moment(date).add(item.estimatedtime, "seconds");
        if (moment(dateUpdated).isBefore(Date.now())) valid = true;
        else valid = false;
      }
      if (item.problem && item.problem) return "problem-type";
      else if (valid) return "delayed-type";
      // if (valid) return "problem-type";
      else return "inprogress-type ";
    } else return "completed-type ";
  };

  return (
    <div
      className={"report-dot " + getClass()}
      style={{
        height: `${2.5 * zoom}px`,
        width: `${2.5 * zoom}px`,
      }}
    >
      {parseInt(index) + 1}
    </div>
  );
};

export default ReportIcon;
