import {
  ChatMessage,
  PendingRoomResponse,
} from "../../server/chat/protos/chat_pb";
import {
  ChatDispatchTypes,
  LOADING_CHAT,
  NEW_CHAT_MESSAGE,
  SET_CHAT,
  SET_ROOM_ID,
  SET_UNSEEN_ROOMS,
} from "./chatTypes";

export interface chatState {
  loading: boolean;
  roomId: string;
  chat: ChatMessage.AsObject[];
  newMessagesIds: string[];
  pendingRooms: PendingRoomResponse.UnseenRoom.AsObject[];
}

export const defaultState: chatState = {
  loading: false,
  roomId: "",
  chat: [],
  newMessagesIds: [],
  pendingRooms: [],
};

export const chatReducer = (
  state: chatState = defaultState,
  action: ChatDispatchTypes
): chatState => {

  switch (action.type) {
    case LOADING_CHAT:
      return {
        ...state,
        loading: !state.loading,
      };
    case SET_CHAT:
      return {
        ...state,
        chat: action.payload,
      };
    case SET_ROOM_ID:
      return {
        ...state,
        chat: [],
        roomId: action.payload,
      };
    case SET_UNSEEN_ROOMS:
      return {
        ...state,
        pendingRooms: action.payload,
      };
    case NEW_CHAT_MESSAGE:
      const newMessagesIds: string[] = [...state.newMessagesIds];
      if (newMessagesIds.indexOf(action.payload.id) === -1) {
        newMessagesIds.push(action.payload.id);
      }

      return {
        ...state,
        loading: true,
        chat: assignChats(state, action.payload),
        pendingRooms: assignPendingRooms(state, action.payload),
        newMessagesIds: newMessagesIds,
      };
    default:
      return state;
  }
};

const assignPendingRooms = (
  state: chatState,
  chat: ChatMessage.AsObject
): PendingRoomResponse.UnseenRoom.AsObject[] => {
  const roomExists = state.pendingRooms.some(
    (room) => room.roomId === chat.roomId
  );

  if (!roomExists)
    return [
      ...state.pendingRooms,
      {
        roomId: chat.roomId,
        lastsendername: chat.senderName,
        lastsenderimage: chat.senderImage,
        lastflag: chat.flag,
        lastmessage: chat.messageBody,
        unseenmessages: 1,
        lastdate: chat.createdat,
      },
    ];

  return state.pendingRooms.map(
    (room) =>
      (room.roomId === chat.roomId &&
        state.roomId !== chat.roomId && {
        ...room,
        lastsendername: chat.senderName,
        lastsenderimage: chat.senderImage,
        lastflag: chat.flag,
        lastmessage: chat.messageBody,
        unseenmessages: room.unseenmessages + 1,
        lastdate: chat.createdat,
      }) || { ...room }
  );
};

const assignChats = (
  state: chatState,
  chatNew: ChatMessage.AsObject
): ChatMessage.AsObject[] => {
  const chatExists = state.pendingRooms.some(
    (chat) => chat.roomId === chatNew.roomId
  );
  if (!chatExists) return [...state.chat, { ...chatNew }];

  return state.chat.map(
    (chat) =>
      (chat.id === chatNew.id &&
        state.roomId === chat.roomId && {
        ...chatNew,
      }) || { ...chat }
  );
};
