/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as protos_auth_pb from '../protos/auth_pb';


export class AuthServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoDoUuidLogin = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_auth_pb.UserLoginResponse,
    (request: protos_auth_pb.UuidLoginRequest) => {
      return request.serializeBinary();
    },
    protos_auth_pb.UserLoginResponse.deserializeBinary
  );

  doUuidLogin(
    request: protos_auth_pb.UuidLoginRequest,
    metadata: grpcWeb.Metadata | null): Promise<protos_auth_pb.UserLoginResponse>;

  doUuidLogin(
    request: protos_auth_pb.UuidLoginRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_auth_pb.UserLoginResponse) => void): grpcWeb.ClientReadableStream<protos_auth_pb.UserLoginResponse>;

  doUuidLogin(
    request: protos_auth_pb.UuidLoginRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_auth_pb.UserLoginResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/AuthService/DoUuidLogin',
        request,
        metadata || {},
        this.methodInfoDoUuidLogin,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/AuthService/DoUuidLogin',
    request,
    metadata || {},
    this.methodInfoDoUuidLogin);
  }

  methodInfoRefreshToken = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_auth_pb.RefreshTokenResponse,
    (request: protos_auth_pb.RefreshTokenMessage) => {
      return request.serializeBinary();
    },
    protos_auth_pb.RefreshTokenResponse.deserializeBinary
  );

  refreshToken(
    request: protos_auth_pb.RefreshTokenMessage,
    metadata: grpcWeb.Metadata | null): Promise<protos_auth_pb.RefreshTokenResponse>;

  refreshToken(
    request: protos_auth_pb.RefreshTokenMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_auth_pb.RefreshTokenResponse) => void): grpcWeb.ClientReadableStream<protos_auth_pb.RefreshTokenResponse>;

  refreshToken(
    request: protos_auth_pb.RefreshTokenMessage,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_auth_pb.RefreshTokenResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/AuthService/RefreshToken',
        request,
        metadata || {},
        this.methodInfoRefreshToken,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/AuthService/RefreshToken',
    request,
    metadata || {},
    this.methodInfoRefreshToken);
  }

  methodInfoDoLogin = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_auth_pb.LoginResponse,
    (request: protos_auth_pb.LoginRequest) => {
      return request.serializeBinary();
    },
    protos_auth_pb.LoginResponse.deserializeBinary
  );

  doLogin(
    request: protos_auth_pb.LoginRequest,
    metadata: grpcWeb.Metadata | null): Promise<protos_auth_pb.LoginResponse>;

  doLogin(
    request: protos_auth_pb.LoginRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_auth_pb.LoginResponse) => void): grpcWeb.ClientReadableStream<protos_auth_pb.LoginResponse>;

  doLogin(
    request: protos_auth_pb.LoginRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_auth_pb.LoginResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/AuthService/DoLogin',
        request,
        metadata || {},
        this.methodInfoDoLogin,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/AuthService/DoLogin',
    request,
    metadata || {},
    this.methodInfoDoLogin);
  }

  methodInfoSendResetPwdEmail = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_auth_pb.SuccessResponse,
    (request: protos_auth_pb.SendResetPwdMessage) => {
      return request.serializeBinary();
    },
    protos_auth_pb.SuccessResponse.deserializeBinary
  );

  sendResetPwdEmail(
    request: protos_auth_pb.SendResetPwdMessage,
    metadata: grpcWeb.Metadata | null): Promise<protos_auth_pb.SuccessResponse>;

  sendResetPwdEmail(
    request: protos_auth_pb.SendResetPwdMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_auth_pb.SuccessResponse) => void): grpcWeb.ClientReadableStream<protos_auth_pb.SuccessResponse>;

  sendResetPwdEmail(
    request: protos_auth_pb.SendResetPwdMessage,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_auth_pb.SuccessResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/AuthService/SendResetPwdEmail',
        request,
        metadata || {},
        this.methodInfoSendResetPwdEmail,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/AuthService/SendResetPwdEmail',
    request,
    metadata || {},
    this.methodInfoSendResetPwdEmail);
  }

  methodInfoValidatePin = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_auth_pb.ValidatePinResponse,
    (request: protos_auth_pb.ValidatePinMessage) => {
      return request.serializeBinary();
    },
    protos_auth_pb.ValidatePinResponse.deserializeBinary
  );

  validatePin(
    request: protos_auth_pb.ValidatePinMessage,
    metadata: grpcWeb.Metadata | null): Promise<protos_auth_pb.ValidatePinResponse>;

  validatePin(
    request: protos_auth_pb.ValidatePinMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_auth_pb.ValidatePinResponse) => void): grpcWeb.ClientReadableStream<protos_auth_pb.ValidatePinResponse>;

  validatePin(
    request: protos_auth_pb.ValidatePinMessage,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_auth_pb.ValidatePinResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/AuthService/ValidatePin',
        request,
        metadata || {},
        this.methodInfoValidatePin,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/AuthService/ValidatePin',
    request,
    metadata || {},
    this.methodInfoValidatePin);
  }

  methodInfoCheckEmailInUse = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_auth_pb.SuccessResponse,
    (request: protos_auth_pb.EmailInUseMessage) => {
      return request.serializeBinary();
    },
    protos_auth_pb.SuccessResponse.deserializeBinary
  );

  checkEmailInUse(
    request: protos_auth_pb.EmailInUseMessage,
    metadata: grpcWeb.Metadata | null): Promise<protos_auth_pb.SuccessResponse>;

  checkEmailInUse(
    request: protos_auth_pb.EmailInUseMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_auth_pb.SuccessResponse) => void): grpcWeb.ClientReadableStream<protos_auth_pb.SuccessResponse>;

  checkEmailInUse(
    request: protos_auth_pb.EmailInUseMessage,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_auth_pb.SuccessResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/AuthService/CheckEmailInUse',
        request,
        metadata || {},
        this.methodInfoCheckEmailInUse,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/AuthService/CheckEmailInUse',
    request,
    metadata || {},
    this.methodInfoCheckEmailInUse);
  }

  methodInfoFetchRoles = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_auth_pb.RolesResponse,
    (request: protos_auth_pb.FetchRolesRequest) => {
      return request.serializeBinary();
    },
    protos_auth_pb.RolesResponse.deserializeBinary
  );

  fetchRoles(
    request: protos_auth_pb.FetchRolesRequest,
    metadata: grpcWeb.Metadata | null): Promise<protos_auth_pb.RolesResponse>;

  fetchRoles(
    request: protos_auth_pb.FetchRolesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_auth_pb.RolesResponse) => void): grpcWeb.ClientReadableStream<protos_auth_pb.RolesResponse>;

  fetchRoles(
    request: protos_auth_pb.FetchRolesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_auth_pb.RolesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/AuthService/FetchRoles',
        request,
        metadata || {},
        this.methodInfoFetchRoles,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/AuthService/FetchRoles',
    request,
    metadata || {},
    this.methodInfoFetchRoles);
  }

  methodInfoCreateRole = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_auth_pb.SuccessResponse,
    (request: protos_auth_pb.Role) => {
      return request.serializeBinary();
    },
    protos_auth_pb.SuccessResponse.deserializeBinary
  );

  createRole(
    request: protos_auth_pb.Role,
    metadata: grpcWeb.Metadata | null): Promise<protos_auth_pb.SuccessResponse>;

  createRole(
    request: protos_auth_pb.Role,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_auth_pb.SuccessResponse) => void): grpcWeb.ClientReadableStream<protos_auth_pb.SuccessResponse>;

  createRole(
    request: protos_auth_pb.Role,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_auth_pb.SuccessResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/AuthService/CreateRole',
        request,
        metadata || {},
        this.methodInfoCreateRole,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/AuthService/CreateRole',
    request,
    metadata || {},
    this.methodInfoCreateRole);
  }

  methodInfoUpdateRole = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_auth_pb.SuccessResponse,
    (request: protos_auth_pb.Role) => {
      return request.serializeBinary();
    },
    protos_auth_pb.SuccessResponse.deserializeBinary
  );

  updateRole(
    request: protos_auth_pb.Role,
    metadata: grpcWeb.Metadata | null): Promise<protos_auth_pb.SuccessResponse>;

  updateRole(
    request: protos_auth_pb.Role,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_auth_pb.SuccessResponse) => void): grpcWeb.ClientReadableStream<protos_auth_pb.SuccessResponse>;

  updateRole(
    request: protos_auth_pb.Role,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_auth_pb.SuccessResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/AuthService/UpdateRole',
        request,
        metadata || {},
        this.methodInfoUpdateRole,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/AuthService/UpdateRole',
    request,
    metadata || {},
    this.methodInfoUpdateRole);
  }

}

