import React from "react";
import Logo from "../../../assets/img/logo.png";
import { Button, Form, Input } from "antd";
import { Eye, ClosedEye } from "../../../assets/svg/index";
import NotificationDialog from "../../dialogs/Notification-dialog";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { grpcRegisterPassword } from "../../../redux/user/userAction";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import { SuccessResponse } from "../../../server/user/protos/user_pb";

const FormItem = Form.Item;
//https://sds-miciudad.firebaseapp.com/action
interface PasswordConfirmationViewProps {
  actionCode: string;
  userId: string;
}

export const PasswordConfirmationView: React.FunctionComponent<PasswordConfirmationViewProps> =
  ({ actionCode, userId }: PasswordConfirmationViewProps) => {
    const [state, setState] = React.useState({
      eyeActive: false,
      password: "",
      newPassword: "",
      eyeActive2: false,
      isLoading: false,
      isSent: false,
      isError: false,
      errorMessage: "",
      successMessage: "",
      failPasswordMessage: "",
      time: 0,
      isOn: false,
      start: 0,
    });

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const redirect = useHistory();

    const [counter, setCounter] = React.useState(1);
    const [toggle, setToggle] = React.useState(false);

    React.useEffect(() => {
      if (toggle) {
        if (counter > 0) setTimeout(() => setCounter(counter - 1), 1000);
        if (counter <= 0) {
          if (toggle) {
            setToggle(false);
            redirect.push("/");
          }
        }
      } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [counter, toggle]);

    const handleResetPassword = (event) => {
      event.preventDefault();
      // const newPassword = state.password;
      // console.log(state.newPassword);
      // Save the new password.
      if (state.password !== state.newPassword) {
        setState((prev) => ({
          ...prev,
          failPasswordMessage: "error.message.passwords.dont.match",
          password: "",
          newPassword: "",
        }));
      } else {
        dispatch(
          grpcRegisterPassword(
            {
              userId: userId,
              passwordToken: actionCode,
              password: state.newPassword.trim(),
            },
            (response: SuccessResponse.AsObject) => {
              if (response.success) {
                setState((prev) => ({
                  ...prev,
                  success: true,
                  isSent: true,
                  password: "",
                  newPassword: "",
                  successMessage: t("success.message.password.change"),
                }));
                setCounter(2);
                setToggle(true);
              } else {
                setState((prev) => ({
                  ...prev,
                  error: t("error.message.while.changing"),
                  isSent: false,
                  isError: true,
                  errorMessage: t("error.message.while.changing"),
                  password: "",
                  newPassword: "",
                }));
              }
            }
          )
        );
      }
    };

    const clearMessage = () => {
      setState((prev) => ({
        ...prev,
        isSent: false,
      }));
    };

    const clearError = () => {
      setState((prev) => ({
        ...prev,
        isError: false,
      }));
    };

    const handlePasswordForm = (name, event) => {
      const value = event.target.value;
      event.preventDefault();
      setState((prev) => ({
        ...prev,
        [name]: value,
        loginStatus: "",
        validUser: "",
      }));
    };

    return (
      <div>
        <Form
          onSubmit={handleResetPassword}
          className="login-box password-confirmation"
        >
          <div className="login-logo">
            <img src={Logo} alt="" />
            Giooby
          </div>
          <div className="data-box">
            <div className="data-box-title">
              <div className="title">{t("login.new.password")}</div>
              <div className="sub-title">
                {t("login.introduce.new.password")}
              </div>
            </div>
            <div className="inputs-box">
              <div className="password-box">
                <div>{t("login.new.password")}</div>
                <FormItem>
                  <Input
                    suffix={
                      state.eyeActive ? (
                        <Eye
                          className="eye-deactive password-eye"
                          onClick={() => {
                            setState((prev) => ({
                              ...prev,
                              eyeActive: !state.eyeActive,
                            }));
                          }}
                        ></Eye>
                      ) : (
                        <ClosedEye
                          className="eye-deactive password-eye-closed"
                          onClick={() => {
                            setState((prev) => ({
                              ...prev,
                              eyeActive: !state.eyeActive,
                            }));
                          }}
                        ></ClosedEye>
                      )
                    }
                    value={state.password}
                    placeholder=""
                    className="password-login"
                    onChange={(event) => handlePasswordForm("password", event)}
                    type={state.eyeActive ? "" : "password"}
                  />
                </FormItem>
              </div>
              <div className="password-box">
                <div>{t("login.new.password.confirm")}</div>
                <FormItem hasFeedback help={t(state.failPasswordMessage)}>
                  <Input
                    suffix={
                      state.eyeActive2 ? (
                        <Eye
                          className="eye-deactive password-eye"
                          onClick={() => {
                            setState((prev) => ({
                              ...prev,
                              eyeActive2: !state.eyeActive2,
                            }));
                          }}
                        ></Eye>
                      ) : (
                        <ClosedEye
                          className="eye-deactive password-eye-closed"
                          onClick={() => {
                            setState((prev) => ({
                              ...prev,
                              eyeActive2: !state.eyeActive2,
                            }));
                          }}
                        ></ClosedEye>
                      )
                    }
                    value={state.newPassword}
                    placeholder=""
                    className="password-login"
                    onChange={(event) =>
                      handlePasswordForm("newPassword", event)
                    }
                    type={state.eyeActive2 ? "" : "password"}
                  />
                </FormItem>
              </div>
            </div>
            <div className="btn-box">
              <FormItem>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-done"
                  // onClick={() => typeOfLoginChange(1)}
                  // loading={state.isLoading}
                >
                  {t("submit.confirm")}
                </Button>
              </FormItem>
            </div>
          </div>
          <div className="copyright">
            &copy; {t("copyright.footer")}{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://giooby.com/"
            >
              Giooby.com
            </a>
          </div>
        </Form>

        {state.isSent && (
          <NotificationDialog
            type={1}
            successMessage={state.successMessage}
            clearMessage={clearMessage}
          ></NotificationDialog>
        )}
        {state.isError && (
          <NotificationDialog
            type={2}
            clearMessage={clearError}
            errorMessage={state.errorMessage}
          ></NotificationDialog>
        )}
      </div>
    );
  };

export default PasswordConfirmationView;
