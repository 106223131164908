import {
  DASHBOARD_BY_STATUS,
  DASHBOARD_BY_DATE,
  DASHBOARD_BY_CATEGORY,
  DASHBOARD_BY_RESPONSE,
  DASHBOARD_BY_CATEGORY_CUSTOM,
  DASHBOARD_LOADING_BY_STATUS,
  DASHBOARD_LOADING_BY_DATE,
  DASHBOARD_LOADING_BY_CATEGORY,
  DASHBOARD_LOADING_BY_RESPONSE,
} from "./dashboardTypes";
import {
  ReportsByStatusMessage,
  ReportByMonthMessage,
  ReportsByCategoryMessage,
  ReportsByCategoryCustomMessage,
  ReportsResponseTimeMessage,
  ReportsStatsByUsersMessage,
  ReportsUniqueUsersMessage,
} from "../../server/report/protos/reportAnalytics_pb";
import { AnalyticsServiceClient } from "../../server/report/protos/ReportAnalyticsServiceClientPb";
import { BACKEND_URL } from "../RootReducer";
import { Metadata } from "grpc-web";

const dashboardClient = new AnalyticsServiceClient(BACKEND_URL || "");
const DashboardHeaders: Metadata = {
  // service_name: "AnalyticsService",
  "Access-Control-Allow-Origin": "*",
  // crossdomain: true,
  allow_headers: "*",
  // "origin": "http://localhost:3000",
};

export function grpcReportUniqueUserCount(data: string, fun?: any) {
  return (dispatch: any) => {
    const req = new ReportsUniqueUsersMessage();
    req.setLicenseid(data);

    dashboardClient.reportUniqueUserCount(
      req,
      DashboardHeaders,
      (err, response) => {
        if (response == null) {
          console.log("err", err);
        } else {
          const UserData = response.toObject();
          if (fun) {
            fun(UserData);
          }
        }
      }
    );
  };
}

export function grpcReportsStatsByUsers(data: string[], fun?: any) {
  return (dispatch: any) => {
    const req = new ReportsStatsByUsersMessage();
    req.setUseridsList(data);

    dashboardClient.reportsStatsByUsers(
      req,
      DashboardHeaders,
      (err, response) => {
        if (response == null) {
          console.log("err", err);
        } else {
          const UserData = response.toObject().responseList;
          console.log("UserData", UserData);
          if (fun) {
            fun(UserData);
          }
        }
      }
    );
  };
}

export function grpcReportsByStatus(data: any, fun?: any) {
  return (dispatch: any) => {
    if (data.loading) {
      dispatch({
        type: DASHBOARD_LOADING_BY_STATUS,
        payload: true,
      });
    }

    const reqRBSStatus = new ReportsByStatusMessage();
    reqRBSStatus.setLicenseid(data._id);

    dashboardClient.reportsByStatus(
      reqRBSStatus,
      DashboardHeaders,
      (err, response) => {
        if (response == null) {
          console.log("err", err);
        } else {
          const dataByStatus = response.toObject();

          dispatch({
            type: DASHBOARD_BY_STATUS,
            payload: dataByStatus,
          });
          if (fun) {
            fun();
          }
        }
      }
    );
  };
}

export function grpcReportsByDate(data: any, fun?: any) {
  return (dispatch: any) => {
    if (data.loading) {
      dispatch({
        type: DASHBOARD_LOADING_BY_DATE,
        payload: true,
      });
    }

    const reqRBDDate = new ReportByMonthMessage();
    reqRBDDate.setLicenseid(data._id);
    reqRBDDate.setFrom(data.from);
    reqRBDDate.setTo(data.to);

    dashboardClient.reportsByMonthsxYear(
      reqRBDDate,
      DashboardHeaders,
      (err, response) => {
        if (response == null) {
          console.log("err", err);
        } else {
          const dataByDate = response.toObject();

          dispatch({
            type: DASHBOARD_BY_DATE,
            payload: dataByDate,
          });
          if (fun) {
            fun();
          }
        }
      }
    );
  };
}

export function grpcReportsByCategory(data: any, fun?: any) {
  return (dispatch: any) => {
    if (data.loading) {
      dispatch({
        type: DASHBOARD_LOADING_BY_CATEGORY,
        payload: true,
      });
    }

    const reqRBCCategory = new ReportsByCategoryMessage();
    reqRBCCategory.setLicenseid(data._id);

    dashboardClient.reportsByCategory(
      reqRBCCategory,
      DashboardHeaders,
      (err, response) => {
        if (response == null) {
          console.log("err", err);
        } else {
          const dataByCategory = response.toObject();

          dispatch({
            type: DASHBOARD_BY_CATEGORY,
            payload: dataByCategory,
          });
          if (fun) {
            fun();
          }
        }
      }
    );
  };
}

export function grpcReportsByCategoryCustom(data: any, fun?: any) {
  return (dispatch: any) => {
    const reqRBCCCustom = new ReportsByCategoryCustomMessage();
    reqRBCCCustom.setLicenseid(data._id);
    reqRBCCCustom.setFrom(data.from);
    reqRBCCCustom.setTo(data.to);

    dashboardClient.reportsByCategoryCustom(
      reqRBCCCustom,
      DashboardHeaders,
      (err, response) => {
        if (response == null) {
          console.log("err", err);
        } else {
          const dataByCategoryCustom = response.toObject();

          dispatch({
            type: DASHBOARD_BY_CATEGORY_CUSTOM,
            payload: dataByCategoryCustom,
          });
          if (fun) {
            fun();
          }
        }
      }
    );
  };
}

export function grpcReportsByResponse(data: any, fun?: any) {
  return (dispatch: any) => {
    if (data.loading) {
      dispatch({
        type: DASHBOARD_LOADING_BY_RESPONSE,
        payload: true,
      });
    }
    const reqRBRResponse = new ReportsResponseTimeMessage();
    reqRBRResponse.setLicenseid(data._id);
    reqRBRResponse.setFrom(data.from);
    reqRBRResponse.setTo(data.to);

    dashboardClient.reportsResponseTime(
      reqRBRResponse,
      DashboardHeaders,
      (err, response) => {
        if (response == null) {
          console.log("err", err);
        } else {
          const dataByResponse = response.toObject();
          dispatch({
            type: DASHBOARD_BY_RESPONSE,
            payload: dataByResponse,
          });
          if (fun) {
            fun();
          }
        }
      }
    );
  };
}
