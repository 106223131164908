import {
  Feedback,
  FetchFeedbackRequest,
} from "../../server/license/protos/license_pb";
import {
  LICENSES_LOADING,
  LICENSES_ERROR,
  LICENSE_UPDATES,
  LICENSES_LIST,
  LICENSES_SELECTED,
  LICENSES_TAB,
  LicensesDispatchTypes,
  SET_FEEDBACK_DATA,
  SET_FEEDBACK_LIST,
  SET_FEEDBACK_RATE,
  SET_INFO_STATES,
} from "./licensesTypes";

let defaultValue: any;
let defaultValueArray: any = [];

export interface licenseI {
  _id: string;
  cities: string[];
  settingsId: string;
  name: string;
  population: string;
  countryCode: string;
  cellphone: string;
  imagePath: string;
  createAt: string;
  endAt: string;
  blocked: boolean;
  deleted: boolean;
  trackingSettings: {
    distanceInterval?: string;
    timeInterval?: string;
    enable?: boolean;
    trackingType?: string;
    trackingMode?: string;
  };
  licenseSettings: {
    zoom: number | undefined;
    coords: {
      lat: number;
      lng: number;
    };
    jobTypes?: string[];
    reportsDaysForTeam?: number;
    reportsDaysForAdmin?: number;
    reportsDaysForUser?: number;
  };
  geometry: {
    point: any[];
  };
}

interface licensesState {
  loading: boolean;
  loadingLicense: boolean;
  licenses: licenseI[];
  selectedLicense?: licenseI;
  layer: any;
  tabIndex: number;
  limit: number;
  offset: number;
  requestType: FetchFeedbackRequest.RequestFType;
  feedbackList: Feedback.AsObject[];
  feedbackTotal: number;
  feedbackRate: number;
  mainMode: boolean;
  reportMode: boolean;
}

const defaultState: licensesState = {
  loading: false,
  loadingLicense: false,
  licenses: defaultValueArray,
  selectedLicense: defaultValue,
  layer: defaultValue,
  tabIndex: 0,
  limit: 10,
  offset: 1,
  requestType: FetchFeedbackRequest.RequestFType.BY_LICENSE_ID,
  feedbackList: [],
  feedbackTotal: 0,
  feedbackRate: 0,
  mainMode: false,
  reportMode: false,
};

const licensesReducer = (
  state: licensesState = defaultState,
  action: LicensesDispatchTypes
): licensesState => {
  switch (action.type) {
    case LICENSES_LOADING:
      return {
        ...state,
        loading: true,
        loadingLicense: true,
      };
    case LICENSES_ERROR:
      return {
        ...state,
        loading: false,
      };
    case LICENSE_UPDATES:
      const LicenseUpdate: any = action.payload;
      const actualLicense1: any = state.selectedLicense;

      actualLicense1.licenseSettings.jobTypes =
        LicenseUpdate.settings.licensesettings.jobtypesList;
      return {
        ...state,
        selectedLicense: actualLicense1,
      };
    case LICENSES_LIST:
      const LicenseList: any = action.payload;
      const actualLicense2: any = state.selectedLicense;

      let defaultLicense: any;

      if (LicenseList.length > 0) {
        if (!actualLicense2) {
          defaultLicense = LicenseList[0];
        } else {
          defaultLicense = LicenseList.find((lic: any) => {
            return lic._id === actualLicense2._id;
          });
          if (!defaultLicense) {
            defaultLicense = LicenseList[0];
          }
        }
      }

      let licenseGeometryA: any = [];
      let auxA: any = [];

      if (defaultLicense?.geometry?.point.length > 0) {
        defaultLicense.geometry.point.map((p: any) => {
          auxA.push([p.lng, p.lat]);
        });

        licenseGeometryA.push(auxA);
      }

      return {
        ...state,
        licenses: LicenseList,
        loading: false,
        selectedLicense: defaultLicense,
        layer: licenseGeometryA,
      };
    case LICENSES_SELECTED:
      const licenseSelected: any = action.payload;

      let licenseGeometryB: any = [];
      let auxB: any = [];

      if (licenseSelected.geometry.point.length > 0) {
        licenseSelected.geometry.point.map((p: any) => {
          auxB.push([p.lng, p.lat]);
        });

        licenseGeometryB.push(auxB);
      }

      return {
        ...state,
        selectedLicense: licenseSelected,
        layer: licenseGeometryB,
        loadingLicense: false,
      };
    case LICENSES_TAB:
      const licenseTab: any = action.tabIndex;
      return {
        ...state,
        tabIndex: licenseTab,
      };

    case SET_FEEDBACK_DATA:
      return {
        ...state,
        limit: action.limit,
        offset: action.offset,
        requestType: action.requestType,
      };
    case SET_FEEDBACK_LIST:
      return {
        ...state,
        feedbackList: action.payload,
      };
    case SET_FEEDBACK_RATE:
      return {
        ...state,
        feedbackTotal: action.payload.ratecount,
        feedbackRate: action.payload.avgRate,
      };
    case SET_INFO_STATES:
      return {
        ...state,
        mainMode: action.mainMode,
        reportMode: action.reportMode,
      };
    default:
      return state;
  }
};

export default licensesReducer;
