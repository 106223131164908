import React, { useState, useEffect } from "react";
import { updateDocument } from "../../../firebase/index.js";
import { FacebookShareButton } from "react-share";
import ReactTooltip from "react-tooltip";
import { css } from "@emotion/react";
import moment from "moment";

import {
  pending,
  inProgress,
  done,
  limpieza,
  riego,
  poda,
  pintura,
  parques,
  alumbrado,
  basura,
  desazolve,
  escombros,
  ASSIGN_TO_TEAM,
  REMOVE_FROM_TEAM,
  REPORT_PROGRESS_ASSIGNED,
  REPORT_PROGRESS_PROGRESS,
} from "../../../const/const";

import {
  DeleteIcon,
  PlaceIcon,
  UserIcon,
  ArrowIcon,
  LikeIcon,
  InfoIcon,
  CloseIcon,
  Ignore,
  ClockIcon,
} from "../../../assets/svg/index";
import {
  MedalBronze,
  MedalGold,
  MedalPlatinum,
  MedalSilver,
} from "../../../assets/img/index";
// import NotificationAlertDialog from "../../dialogs/Notification-alert-dialog";
// import Basura from "../../../assets/img/basura.jpg";
// import {getAddress} from "../../../utils/geocode"
import ConfirmationDialog from "../../dialogs/confirmation-dialog";
import SimpleModal from "../../../utils/dialogs/simple-dialog";
import CommentaryDialog from "../../dialogs/Commentary-dialog";
import BlockedUser from "../../dialogs/Blocked-user-dialog";
import DialogReAsignar from "../../dialogs/Dialog-reasignar";
import DialogAsignar from "../../dialogs/Dialog-asignar";
import DeleteReport from "../../dialogs/delete-dialog";
import WorkerReport from "../components/Worker-report";
import ScaleLoader from "react-spinners/ScaleLoader";
import ChatReport from "../components/chat-report";
import Commentary from "../components/Commentary";
import ImgDialog from "../../dialogs/Img-dialog";
import Email from "../../../assets/img/mail.png";
import FeatherIcon from "feather-icons-react";
import {
  Report,
  ReportStatus,
  ReportWorkStatus,
} from "../../../server/report/protos/report_pb";
import { useDispatch, useSelector } from "react-redux";
import { ReportTypes } from "../../../redux/reportTypes/reportsReducer";
import { RootStore } from "../../../redux/Store";
import { useTranslation } from "react-i18next";
import { AssignType, Team } from "../../../server/team/protos/team_pb";
import { grpcAssignToTeam } from "../../../redux/teams/teamsActions";
import { grpcLikeReport } from "../../../redux/reportTypes/reportsActions";
import { User } from "../../../server/user/protos/user_pb";
import {
  NotificationApp,
  RequestIgnore,
  RequestType,
} from "../../../server/notification/protos/notification_pb";
import { licenseI } from "../../../redux/licenses/licensesReducer";
import { grpcIgnoreNotification } from "../../../redux/notifications/notificationsAction";
import { WEB_URL } from "../../../redux/RootReducer";
import {
  grpcBlockUser,
  grpcFetchUserById,
  grpcFetchUsersBlocked,
} from "../../../redux/users/usersAction";
import { grpcReportsStatsByUsers } from "../../../redux/dashboard/dashboardActions";
import { ReportsStatsByUsersResult } from "../../../server/report/protos/reportAnalytics_pb";
import { SET_CHAT, SET_ROOM_ID } from "../../../redux/chat/chatTypes";

interface CardReportProps {
  refComments: any;
  reportTypes: any;
  assignedReport: any;
  markerReport: Report.AsObject;
  teams: any;
  t: any;
  markerReportFunction: any;
  workers: any;
  notifications: any;
  currentDistrict: any;
  showDialogAlertNotification: any;
  users: any;
  assignerTeam: any;
  getNotifications: any;
  setCenter: any;
  updateUser: any;
  updateCoords: any;
  updateCurrentZoom: any;
  handleClick: any;
}

export const CardReport: React.FunctionComponent<CardReportProps> = ({
  refComments,
  reportTypes,
  assignedReport,
  markerReport,
  markerReportFunction,
  workers,
  showDialogAlertNotification,
  // users,
  assignerTeam,
  getNotifications,
  setCenter,
  updateUser,
  updateCoords,
  updateCurrentZoom,
  handleClick,
}: CardReportProps) => {
  let defaultValue: any;

  const reportTypesGeneral: ReportTypes[] = useSelector(
    (globalState: RootStore) => globalState.reportTypes.reportTypesGeneral
  );

  const reportsGeneral: Report.AsObject[] = useSelector(
    (globalState: RootStore) => globalState.reportTypes.reportsGeneral
  );

  const reports: Report.AsObject[] = useSelector(
    (globalState: RootStore) => globalState.reportTypes.reports
  );

  const teams: Team.AsObject[] = useSelector(
    (globalState: RootStore) => globalState.teams.teams
  );

  const users: User.AsObject[] = useSelector(
    (globalState: RootStore) => globalState.users.users
  );

  const notifications: NotificationApp.AsObject[] = useSelector(
    (globalState: RootStore) => globalState.notifications.notifications
  );

  const selectedLicense: licenseI | undefined = useSelector(
    (globalState: RootStore) => globalState.licenses.selectedLicense
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: #0d3768;
  `;

  const [user, setUser] = useState<User.AsObject | undefined>(undefined);
  const [userData, setUserData] = useState<
    ReportsStatsByUsersResult.Result.AsObject[] | undefined
  >(undefined);

  const [state, setState] = useState({
    reported: false,
    assigned: false,
    showModalAssign: false,
    showModalDelete: false,
    showModalReAssign: false,
    showModalImg: false,
    showModalCommentary: false,
    typeCommentary: defaultValue,
    showModalComfirm: false,
    showModalBlock: false,
    showModalComfirmAutoAssign: false,
    userSelected: defaultValue,
    commentary: defaultValue,
    slider: 0,
  });

  useEffect(() => {
    getNotifications(markerReport);
    setUser(undefined);
    dispatch(
      grpcFetchUserById(markerReport.reportedby, (response: User.AsObject) => {
        setUser(response);
        dispatch(
          grpcReportsStatsByUsers(
            [response.id],
            (response: ReportsStatsByUsersResult.Result.AsObject[]) => {
              setUserData(response);
            }
          )
        );
      })
    );
    setCenter(markerReport);
  }, [markerReport]);


  const GetTime = (start: Date, end: Date) => {
    let msec =
      moment(end).toDate().getTime() - moment(start).toDate().getTime();
    let mins = Math.floor(msec / 60000);
    let hrs = Math.floor(mins / 60);
    let days = Math.floor(hrs / 24);
    mins = mins % 60;
    hrs = hrs % 24;

    if (days === 0 && hrs === 0 && mins >= 0)
      return mins + t("time.minutes") + t("time.ago");
    if (days === 0 && hrs > 0)
      return (
        hrs + t("time.hours") + " " + mins + t("time.minutes") + t("time.ago")
      );
    if (days > 0) return days + t("time.days") + t("time.ago");
  };

  const GetTimeHours = (time: number) => {
    if (!time) return 0 + t("time.minutes");
    else {
      let mins = Math.floor(time / 60);
      let hrs = Math.floor(mins / 60);
      let days = Math.floor(hrs / 24);
      mins = mins % 60;
      hrs = hrs % 24;
      if (days === 0 && hrs === 0 && mins >= 0) return mins + t("time.minutes");
      if (days === 0 && hrs > 0)
        return hrs + t("time.hours") + " " + mins + t("time.minutes");
      if (days > 0) return days + t("time.days");
    }
  };

  const reportedVisibility = () => {
    setState((prevState) => ({
      ...prevState,
      reported: !state.reported,
    }));
  };

  const assignedVisibility = () => {
    setState((prevState) => ({
      ...prevState,
      assigned: !state.assigned,
    }));
  };

  const closeReport = () => {
    markerReportFunction(defaultValue);
    dispatch({
      type: SET_ROOM_ID,
      payload: "",
    });
    dispatch({
      type: SET_CHAT,
      payload: [],
    });
  };

  const changeSlider = (e: any) => {
    setState((prevState) => ({
      ...prevState,
      slider: e,
    }));
  };

  const handleToggleModalReAssign = () => {
    setState((prevState) => ({
      ...prevState,
      showModalReAssign: !state.showModalReAssign,
    }));
  };

  const handleToggleModalComfirmAutoAssign = () => {
    setState((prevState) => ({
      ...prevState,
      showModalComfirmAutoAssign: !state.showModalComfirmAutoAssign,
    }));
  };

  const handleToggleModalAssign = () => {
    setState((prevState) => ({
      ...prevState,
      showModalAssign: !state.showModalAssign,
    }));
  };

  const handleToggleModalDelete = () => {
    setState((prevState) => ({
      ...prevState,
      showModalDelete: !state.showModalDelete,
    }));
  };

  const handleToggleModalImg = () => {
    setState((prevState) => ({
      ...prevState,
      showModalImg: !state.showModalImg,
    }));
  };

  const handleToggleModalCommentary = () => {
    setState((prevState) => ({
      ...prevState,
      showModalCommentary: !state.showModalCommentary,
    }));
  };

  const handleToggleModalComfirm = () => {
    setState((prevState) => ({
      ...prevState,
      showModalComfirm: !state.showModalComfirm,
    }));
  };

  const handleToggleModalBlock = () => {
    setState((prevState) => ({
      ...prevState,
      showModalBlock: !state.showModalBlock,
    }));
  };

  const getTag = () => {
    if (markerReport.reportstatus === ReportStatus.REPORT_PENDING) {
      return (
        <div className="tag-pending">
          {t("status.pending.singular")}
          <div className="circule"></div>
        </div>
      );
    } else if (
      markerReport.reportstatus === ReportStatus.REPORT_IN_PROGRESS &&
      markerReport.workstatus === ReportWorkStatus.WORK_ASSIGNED
    ) {
      return (
        <div className="tag-assigned">
          {t("status.assigned")} <div className="circule"></div>
        </div>
      );
    } else if (
      markerReport.reportstatus === ReportStatus.REPORT_IN_PROGRESS &&
      markerReport.workstatus === ReportWorkStatus.WORK_IN_PROGRESS
    ) {
      return (
        <div className="tag-process">
          {t("status.in.process")} <div className="circule"></div>
        </div>
      );
    } else {
      return (
        <div className="tag-done">
          {t("status.completed.singular")}
          <div className="circule"></div>
        </div>
      );
    }
  };

  const autoAssign = () => {
    const tempTeam: Team.AsObject | undefined = teams.find(
      (e: Team.AsObject) => e.tasksList.indexOf(markerReport.reportid) !== -1
    );
    const tempReport: Report.AsObject = markerReport;
    const index = tempTeam?.tasksList.indexOf(markerReport.reportid);
    // if (index !== -1) {
    if (tempTeam) {
      dispatch(
        grpcAssignToTeam(
          {
            id: tempTeam.id,
            ids: [markerReport.reportid],
            type:
              index !== -1
                ? AssignType.REMOVE_FROM_TEAM_TASKS
                : AssignType.ASSIGN_TO_TEAM_TASKS,
          },
          () => {
            assignedReport(tempReport, tempTeam);
            // this.props.onCloseRequest();
            handleToggleModalComfirmAutoAssign();
          }
        )
      );
    }

    //   netWorking.updateTeamAssignedReports({
    //     teamId: tempTeam._id,
    //     reportsIds: [markerReport.reportid],
    //     assign: REMOVE_FROM_TEAM,
    //     createNotification: true,
    //   });
    // } else {
    //   netWorking.updateTeamAssignedReports({
    //     teamId: tempTeam._id,
    //     reportsIds: [markerReport.reportid],
    //     assign: ASSIGN_TO_TEAM,
    //     createNotification: true,
    //   });
    //   assignedReport(tempReport, tempTeam);
    //   // this.props.onCloseRequest();
    //   handleToggleModalComfirmAutoAssign();
    // }
  };

  const getRightAction = () => {
    if (markerReport.reportstatus === ReportStatus.REPORT_PENDING) {
      return (
        <div
          className="assign-box"
          onClick={
            assignerTeam
              ? handleToggleModalComfirmAutoAssign
              : handleToggleModalAssign
          }
        >
          <p>{t("name.assign")}</p>
        </div>
      );
    } else if (markerReport.reportstatus === ReportStatus.REPORT_IN_PROGRESS) {
      return (
        <div className="assign-box" onClick={handleToggleModalReAssign}>
          <p>{t("name.re.assign")}</p>
        </div>
      );
    } else {
      return (
        <div className="completed-buttons-box">
          <div className="assign-box" onClick={handleToggleModalReAssign}>
            <p>{t("name.re.assign")}</p>
          </div>
          <div className="assign-box share-button">
            <FacebookShareButton
              url={WEB_URL}
              quote={
                t("facebook.share.1") +
                " " +
                getType() +
                " " +
                t("facebook.share.3") +
                " " +
                markerReport.address.trim() +
                ". " +
                selectedLicense?.name +
                " " +
                t("facebook.share.2")
              }
              className="Demo__some-network__share-button"
              // onClick={(event) => {
              //   // console.log(event);
              // }}
              hashtag={"#Giooby"}
            >
              {/* <p>{this.props.t("name.share")}</p> */}
              <div className="box-share">
                <FeatherIcon icon="share-2" size="13" />
              </div>
            </FacebookShareButton>
          </div>
        </div>
      );
    }
  };

  const getLeftAction = () => {
    if (markerReport.reportstatus === ReportStatus.REPORT_PENDING) {
      return (
        <div className="delete-box" onClick={handleToggleModalDelete}>
          <DeleteIcon></DeleteIcon>
        </div>
      );
    } else if (markerReport.reportstatus === ReportStatus.REPORT_IN_PROGRESS) {
      return (
        <div className="delete-box" onClick={handleToggleModalDelete}>
          <DeleteIcon></DeleteIcon>
        </div>
      );
    } else {
      return (
        <div className="delete-box" onClick={handleToggleModalDelete}>
          <DeleteIcon></DeleteIcon>
        </div>
      );
    }
  };

  const getType = () => {
    let value = reportTypesGeneral.find(
      (e: ReportTypes) => e.reporttypeid === markerReport.reporttypeid
    );
    if (value) {
      return value[t("language")];
    } else
      switch (markerReport.reporttypeid) {
        case limpieza:
          return t("report.type.cleaning");
        case riego:
          return t("report.type.irrigation");
        case poda:
          return t("report.type.pruning");
        case pintura:
          return t("report.type.painting");
        case parques:
          return t("report.type.parks");
        case alumbrado:
          return t("report.type.street.lighting");
        case basura:
          return t("report.type.garbage");
        case desazolve:
          return t("report.type.unwind");
        case escombros:
          return t("report.type.debris");
        default:
          return t("report.type.cleaning");
      }
  };

  const Like = () => {
    let report = markerReport;
    if (markerReport.likeusersList) {
      let index = markerReport.likeusersList.indexOf(
        selectedLicense?._id || ""
      );

      if (index !== -1) {
        dispatch(
          grpcLikeReport({
            reportId: report.reportid,
            isLike: false,
            userId: selectedLicense?._id || "",
          })
        );
        // netWorking.likeReport("reports/like", {
        //   _id: report.reportid,
        //   likeUser: currentDistrict._id,
        //   isLike: false,
        // });
      } else {
        dispatch(
          grpcLikeReport({
            reportId: report.reportid,
            isLike: true,
            userId: selectedLicense?._id || "",
          })
        );
        // netWorking.likeReport("reports/like", {
        //   _id: report.reportid,
        //   likeUser: currentDistrict._id,
        //   isLike: true,
        // });
      }
    } else {
      dispatch(
        grpcLikeReport({
          reportId: report.reportid,
          isLike: true,
          userId: selectedLicense?._id || "",
        })
      );
      // var report = this.props.markerReport;
      // netWorking.likeReport("reports/like", {
      //   _id: report.reportid,
      //   likeUser: currentDistrict._id,
      //   isLike: true,
      // });
    }
  };

  const openCommentDialog = (e: NotificationApp.AsObject, type: any) => {
    setState((prevState) => ({
      ...prevState,
      showModalCommentary: !state.showModalCommentary,
      commentary: e,
      typeCommentary: type,
    }));
  };

  const deleteCommentaryConfirmation = (e: any) => {
    setState((prevState) => ({
      ...prevState,
      showModalComfirm: !state.showModalComfirm,
      commentary: e,
    }));
  };

  const removeCommentary = () => {
    dispatch(
      grpcIgnoreNotification(
        {
          id: state.commentary.id,
          typeIgnore: RequestIgnore.RequestIgnoreType.IGNORE,
          typeRequest: RequestType.BY_NOTIFICATION_ID,
        },
        () => {
          setState((prevState) => ({
            ...prevState,
            showModalComfirm: !state.showModalComfirm,
            commentary: defaultValue,
          }));
          getNotifications(markerReport);
        }
      )
    );
    // updateDocument(
    //   "notifications/ignore",
    //   state.commentary._id,
    //   {
    //     reportId: markerReport.reportid,
    //     ignored: true,
    //   },
    //   () => {
    //     setState((prevState) => ({
    //       ...prevState,
    //       showModalComfirm: !state.showModalComfirm,
    //       commentary: defaultValue,
    //     }));
    //     getNotifications(markerReport);
    //   }
    // );
  };

  const addBlock = (e: string) => {
    let blockReason = "";
    if (t("form.motive.6") === e) {
      blockReason = "6";
    } else if (t("form.motive.7") === e) {
      blockReason = "7";
    } else {
      blockReason = "8";
    }

    // console.log("reason block", e);
    // console.log("user block", user);

    dispatch(
      grpcBlockUser(
        {
          _id: user?.id || "",
          licenseId: selectedLicense?._id || "",
          motive: blockReason,
          block: true,
        },
        (response) => {
          Lock(defaultValue);
          dispatch(
            grpcFetchUserById(
              markerReport.reportedby,
              (response: User.AsObject) => {
                setUser(response);
              }
            )
          );
          fetchAllUsersBLocked();
        }
      )
    );
  };

  //"USERS"
  const fetchAllUsersBLocked = () => {
    dispatch(grpcFetchUsersBlocked(selectedLicense?._id));
  };

  const Lock = (e: any) => {
    setState((prevState) => ({
      ...prevState,
      userSelected: e,
      showModalBlock: !state.showModalBlock,
    }));
  };

  const goToAddress = () => {
    updateCoords({
      latitude: markerReport.geopoint?.latitude,
      longitude: markerReport.geopoint?.longitude,
    });
    updateCurrentZoom(17);
  };

  const getInsignia = (
    user: ReportsStatsByUsersResult.Result.AsObject | undefined
  ) => {
    const reportsCount =
      (user?.reportsCompleted || 0) +
      (user?.reportsInProgress || 0) +
      (user?.reportsPending || 0);
    if (reportsCount >= 0 && reportsCount < 10)
      return (
        <div className="box-insignia">
          <img src={MedalBronze} alt="" />
          {t("insignia.bronze")}
        </div>
      );
    if (reportsCount >= 10 && reportsCount < 20)
      return (
        <div className="box-insignia">
          <img src={MedalSilver} alt="" />
          {t("insignia.silver")}
        </div>
      );
    if (reportsCount >= 20 && reportsCount < 50)
      return (
        <div className="box-insignia">
          <img src={MedalGold} alt="" />
          {t("insignia.oro")}
        </div>
      );
    if (reportsCount >= 50)
      return (
        <div className="box-insignia">
          <img src={MedalPlatinum} alt="" />
          {t("insignia.platinum")}
        </div>
      );
  };

  const getUsersCard = () => {
    if (user) {
      const found = user.blockedbyList.find((d) => {
        if (d.licenseId === selectedLicense?._id) {
          return d;
        }
      });
      return (
        <div>
          {state.reported && (
            <div className="hidden-wall" onClick={reportedVisibility}></div>
          )}
          <div className="data-assigned-to-box" onClick={reportedVisibility}>
            <UserIcon></UserIcon>
            <p>
              {t("name.reported.by")}
              {!user.username ? (
                <strong>{" " + user.name + " " + user.lastName}</strong>
              ) : (
                <strong>{" " + user.username}</strong>
              )}
            </p>
            {found && <Ignore></Ignore>}
            {/* <div className="action-arrow">
                <ArrowIcon></ArrowIcon>
              </div> */}
          </div>
          {!found && (
            <div
              className={` ${
                state.reported ? "visible-box" : "hidden-box"
              } assigned-box-to-block`}
            >
              <div className="triangle-parent">
                <div className="triangle-box"></div>
              </div>
              <div className="user-box">
                <div className="box-data-user">
                  {user.imagePath ? (
                    <img src={user.imagePath} alt="" />
                  ) : (
                    <img
                      src={
                        "https://miciudad.s3-us-west-1.amazonaws.com/profile.png"
                      }
                      alt=""
                    />
                  )}
                  <strong>
                    {!user.username ? (
                      <strong>{" " + user.name + " " + user.lastName}</strong>
                    ) : (
                      <strong>{" " + user.username}</strong>
                    )}
                  </strong>
                </div>
                {getInsignia(
                  userData && userData.length > 0
                    ? userData.find((d) => {
                        return d.userid === user.id;
                      })
                    : undefined
                )}
              </div>
              <div className="bottom-user-box">
                <div>
                  <img src={Email} alt="" className="email-logo" />{" "}
                  {t("name.send.message")}
                </div>
                <div
                  onClick={() => {
                    reportedVisibility();
                    Lock(user);
                  }}
                >
                  <Ignore></Ignore> {t("name.block.account")}
                </div>
              </div>
            </div>
          )}
        </div>
      );
    }
  };

  const getWorkerBox = () => {
    const result: Team.AsObject | undefined = teams.find(
      (e: Team.AsObject) => e.tasksList.indexOf(markerReport.reportid) !== -1
    );
    const assignedWorkers = markerReport.assignedWorkersList;
    // console.log(result);
    if (result) {
      return (
        <div>
          <div className="data-assigned-to-box" onClick={assignedVisibility}>
            <UserIcon></UserIcon>
            <p>
              {t("name.assigned.to")}
              <strong>{result.name}</strong>
            </p>
            <div className="action-arrow">
              <ArrowIcon></ArrowIcon>
            </div>
          </div>
          <div
            className={` ${
              state.assigned ? "visible-box" : "hidden-box"
            } assigned-box`}
          >
            {users
              .filter(
                (e: User.AsObject) => result.membersList.indexOf(e.id) !== -1
              )
              .map(function (item: User.AsObject, i: any) {
                return (
                  <WorkerReport
                    worker={item}
                    key={i}
                    assignedWorkers={assignedWorkers}
                  ></WorkerReport>
                );
              })}
          </div>
        </div>
      );
    } else {
      return defaultValue;
    }
  };

  const getAssignedToFinished = () => {
    // console.log(result);
    if (
      markerReport.reportstatus === ReportStatus.REPORT_IN_PROGRESS &&
      markerReport.assignDate
    ) {
      return (
        <div className="data-address-box">
          <ClockIcon></ClockIcon>
          <p>
            {t("name.assigned.time")}
            {GetTime(
              moment(
                moment.unix(markerReport.assignDate.seconds).toLocaleString()
              ).toDate(),
              moment(Date()).toDate()
            )}
          </p>
        </div>
      );
    } else {
      return defaultValue;
    }
  };

  const getStartedToFinished = () => {
    // console.log(result);
    if (
      markerReport.reportstatus === ReportStatus.REPORT_COMPLETED &&
      markerReport.workProgressDate
    ) {
      return (
        <div className="data-address-box">
          <ClockIcon></ClockIcon>
          <p>
            {t("name.completed.time")}
            {GetTime(
              moment(
                moment
                  .unix(markerReport.workProgressDate.seconds)
                  .toLocaleString()
              ).toDate(),
              moment(Date()).toDate()
            )}
          </p>
        </div>
      );
    } else {
      return defaultValue;
    }
  };

  const getEstimated = () => {
    // console.log(result);
    if (
      (markerReport.reportstatus === ReportStatus.REPORT_IN_PROGRESS &&
        markerReport.workstatus === ReportWorkStatus.WORK_IN_PROGRESS) ||
      markerReport.reportstatus === ReportStatus.REPORT_COMPLETED
    ) {
      return (
        <div className="data-address-box">
          <ClockIcon></ClockIcon>
          <p>
            {t("name.estimated.time")}
            {GetTimeHours(markerReport.estimatedtime)}
          </p>
        </div>
      );
    } else {
      return defaultValue;
    }
  };

  const getCreated = () => {
    // console.log(markerReport);
    if (markerReport.createdat) {
      return (
        <div className="data-address-box">
          <ClockIcon></ClockIcon>
          <p>
            {t("name.started.time")}
            {GetTime(
              moment(
                moment.unix(markerReport.createdat.seconds).toLocaleString()
              ).toDate(),
              moment(Date()).toDate()
            )}
          </p>
        </div>
      );
    } else {
      return defaultValue;
    }
  };

  const getCommentary = (item: NotificationApp.AsObject, i: any, type: any) => {
    return (
      <Commentary
        key={i}
        notification={item}
        type={type}
        markerReport={markerReport}
        editCommentary={openCommentDialog}
        deleteCommentaryConfirmation={deleteCommentaryConfirmation}
        currentDistrict={selectedLicense}
      ></Commentary>
    );
  };

  // useEffect(() => {
  //   console.log("entro aqui");
  //   // reports
  //   let reportsAux = reports.find(
  //     (report: Report.AsObject) => markerReport.reportid === report.reportid
  //   );
  //   console.log("reportsAux", reportsAux);
  //   console.log("reports", reports);
  //   if (!reportsAux) {
  //     console.log("eliminar");
  //     closeReport();
  //   }
  // }, [reports]);

  return (
    <div>
      <div
        className={`${markerReport ? "visible-box" : "hidden-box"} card-report`}
      >
        <div className="report-data-card">
          <div className="report-img">
            <div
              className="report-imgs-box"
              onClick={() => {
                handleToggleModalImg();
              }}
            >
              <div className="img-box">
                <div className="loading">
                  <ScaleLoader
                    css={override}
                    color={"#123abc"}
                    loading={true}
                  />
                </div>
                <div className="title-img-box">{t("name.img.1")}</div>
                <img src={markerReport.imageurl} className="" alt="..." />
              </div>
              {markerReport.arrivalImageUrl &&
                markerReport.arrivalImageUrl !== "" && (
                  <div className="img-box">
                    <div className="loading">
                      <ScaleLoader
                        css={override}
                        color={"#123abc"}
                        loading={true}
                      />
                    </div>
                    <div className="title-img-box">{t("name.img.2")}</div>
                    <img
                      src={markerReport.arrivalImageUrl}
                      className=""
                      alt="..."
                    />
                  </div>
                )}
              {markerReport.doneimageurl && markerReport.doneimageurl !== "" && (
                <div className="img-box">
                  <div className="loading">
                    <ScaleLoader
                      css={override}
                      color={"#123abc"}
                      loading={true}
                    />
                  </div>
                  <div className="title-img-box">{t("name.img.3")}</div>
                  <img src={markerReport.doneimageurl} className="" alt="..." />
                </div>
              )}
            </div>

            <div
              className="close-button"
              onClick={() => {
                closeReport();
                handleClick();
              }}
            >
              <CloseIcon></CloseIcon>
            </div>
          </div>
          <div className="report-data">
            <div className="main-box">
              <div className="first-box">
                <div
                  className={
                    markerReport.likeusersList &&
                    markerReport.likeusersList.indexOf(
                      selectedLicense?._id || ""
                    ) !== -1
                      ? "data-title Liked"
                      : "data-title"
                  }
                >
                  {getType()} <LikeIcon onClick={Like}></LikeIcon>{" "}
                  {markerReport.likeusersList
                    ? markerReport.likeusersList.length
                    : 0}
                </div>
                {getRightAction()}
              </div>
              <div className="second-box">
                <div className={"tag-box"}>{getTag()}</div>
                {getLeftAction()}
              </div>
              {/* <div className="col-7">
                  <div className="row">
                    <div
                      className={
                        this.props.markerReport.likeUsers !== undefined &&
                        this.props.markerReport.likeUsers.indexOf(
                          this.props.currentDistrict._id
                        ) !== -1
                          ? "col-12 data-title Liked"
                          : "col-12 data-title"
                      }
                    >
                      {this.getType()} <LikeIcon onClick={this.Like}></LikeIcon>{" "}
                      {this.props.markerReport.likeUsers !== undefined
                        ? this.props.markerReport.likeUsers.length
                        : 0}
                    </div>
                    <div className="col-12 data-description">
                      {this.props.markerReport.description}
                    </div>
                    <div className="col-12">{this.getTag()}</div>
                  </div>
                </div>
                <div className="col-5 action-box">
                  {this.getLeftAction()}
                  {this.getRightAction()}
                      </div>*/}
            </div>
          </div>
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item">
              <a
                className="nav-link active"
                id="home-tab"
                data-toggle="tab"
                href="#info"
                role="tab"
                aria-controls="home"
                aria-selected="true"
              >
                {t("tabs.info")}
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="comment-tab"
                data-toggle="tab"
                href="#comment"
                role="tab"
                aria-controls="comment"
                aria-selected="false"
              >
                {t("tabs.comment")}
              </a>
            </li>
            <li
              className="nav-item"
              onClick={() => {
                setTimeout(function () {
                  let scrollDiv = document.querySelector(
                    ".commentary-body-private"
                  );
                  if (scrollDiv) {
                    console.log(
                      "scrollDiv.scrollHeight",
                      scrollDiv.scrollHeight
                    );
                    scrollDiv.scrollTop = scrollDiv.scrollHeight;
                  }
                }, 170);
              }}
            >
              <a
                className="nav-link"
                id="comment-private-tab"
                data-toggle="tab"
                href="#comment-private"
                role="tab"
                aria-controls="comment-private"
                aria-selected="false"
              >
                {t("tabs.comment.private")}
              </a>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            {/* Tab INFO */}
            <div
              className="tab-pane fade show active"
              id="info"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <div className="report-extra">
                <div className="description-extra">
                  <div className="data-assigned-to-box">
                    <p>{markerReport.description}</p>
                  </div>
                </div>
                <div
                  className="data-address-box data-address-box-hover"
                  onClick={goToAddress}
                >
                  <PlaceIcon></PlaceIcon>
                  <p>{markerReport.address}</p>
                </div>
                {getUsersCard()}
                {getWorkerBox()}
                {getEstimated()}
                {getAssignedToFinished()}
                {getStartedToFinished()}
                {getCreated()}
              </div>
            </div>
            {/* Tab PUBLIC */}
            <div
              className="tab-pane fade"
              id="comment"
              role="tabpanel"
              aria-labelledby="comment-tab"
            >
              <div className="report-comentarios">
                <div className="commentary-header">
                  <div className="commentary-header-title">
                    {t("name.commentaries")}
                    <InfoIcon data-tip data-for="sadFace"></InfoIcon>
                    <ReactTooltip
                      id="sadFace"
                      className="tool-tip"
                      type="info"
                      effect="solid"
                      place="right"
                      multiline={true}
                    >
                      <span>{t("name.commentary.tooltip")}</span>
                    </ReactTooltip>
                  </div>
                  <div
                    className="commentary-header-add"
                    onClick={() => openCommentDialog(defaultValue, 1)}
                  >
                    {t("name.commentary.add")}
                    <FeatherIcon icon="message-square" size="15" />
                  </div>
                </div>
                <div className="commentary-body">
                  {notifications
                    .sort(
                      (
                        a: NotificationApp.AsObject,
                        b: NotificationApp.AsObject
                      ) => {
                        return (
                          moment(b.createdat).toDate().getTime() -
                          moment(a.createdat).toDate().getTime()
                        );
                      }
                    )
                    .map(function (item: NotificationApp.AsObject, i: any) {
                      return getCommentary(item, i, 1);
                    })}
                </div>
              </div>
            </div>
            {/* Tab INTERN */}
            <div
              className="tab-pane fade"
              id="comment-private"
              role="tabpanel"
              aria-labelledby="comment-private-tab"
            >
              <div
                className="report-comentarios"
                onLoadedData={() => {
                  let scrollDiv = document.querySelector(
                    ".commentary-body-private"
                  );
                  if (scrollDiv) {
                    console.log(
                      "scrollDiv.scrollHeight",
                      scrollDiv.scrollHeight
                    );
                    scrollDiv.scrollTop = scrollDiv.scrollHeight;
                  }
                }}
              >
                <div className="commentary-header">
                  <div className="commentary-header-title">
                    {t("name.commentaries")}
                    <InfoIcon data-tip data-for="sadFace"></InfoIcon>
                    <ReactTooltip
                      id="sadFace"
                      className="tool-tip"
                      type="info"
                      effect="solid"
                      place="right"
                      multiline={true}
                    >
                      <span>{t("name.commentary.tooltip")}</span>
                    </ReactTooltip>
                  </div>
                  {/* <div
                      className="commentary-header-add"
                      onClick={() => this.openCommentDialog(null, 2)}
                    >
                      {this.props.t("name.commentary.add")}
                      <FeatherIcon icon="message-square" size="15" />
                    </div> */}
                </div>
                <ChatReport
                  notifications={notifications.sort(
                    (
                      a: NotificationApp.AsObject,
                      b: NotificationApp.AsObject
                    ) => {
                      return (
                        moment(a.createdat).toDate().getTime() -
                        moment(b.createdat).toDate().getTime()
                      );
                    }
                  )}
                  refComments={refComments}
                  t={t}
                  markerReport={markerReport}
                  editCommentary={openCommentDialog}
                  deleteCommentaryConfirmation={deleteCommentaryConfirmation}
                  currentDistrict={selectedLicense}
                  team={teams.find(
                    (e: Team.AsObject) =>
                      e.tasksList.indexOf(markerReport.reportid) !== -1
                  )}
                  assignedWorkers={markerReport.assignedWorkersList}
                ></ChatReport>
              </div>
            </div>
          </div>
        </div>
      </div>
      {state.showModalReAssign && (
        <SimpleModal onCloseRequest={() => handleToggleModalReAssign()}>
          <DialogReAsignar
            teams={teams}
            markerReport={markerReport}
            onCloseRequest={() => handleToggleModalReAssign()}
            assignedReport={assignedReport}
            showDialogAlertNotification={showDialogAlertNotification}
          ></DialogReAsignar>
        </SimpleModal>
      )}
      {state.showModalAssign && (
        <SimpleModal
          onCloseRequest={() => {
            handleToggleModalAssign();
          }}
        >
          <DialogAsignar
            teams={teams}
            markerReport={markerReport}
            onCloseRequest={() => {
              handleToggleModalAssign();
            }}
            assignedReport={assignedReport}
          ></DialogAsignar>
        </SimpleModal>
      )}
      {state.showModalDelete && (
        <SimpleModal
          onCloseRequest={() => {
            handleToggleModalDelete();
            
          }}
        >
          <DeleteReport
            teams={teams}
            closeReport={() => {
              closeReport();
            }}
            markerReport={markerReport}
            onCloseRequest={() => {
              handleToggleModalDelete();
              handleClick();
            }}
          ></DeleteReport>
        </SimpleModal>
      )}
      {state.showModalBlock && (
        <SimpleModal onCloseRequest={() => handleToggleModalBlock()}>
          <BlockedUser
            userSelected={state.userSelected}
            noBold1={t("name.block.account1")}
            noBold2={t("name.block.account2")}
            boldText={
              state.userSelected
                ? !state.userSelected.userName
                  ? " " +
                    state.userSelected.name +
                    " " +
                    state.userSelected.lastName +
                    " "
                  : " " + state.userSelected.userName + " "
                : ""
            }
            doneClick={addBlock}
            onCloseRequest={() => handleToggleModalBlock()}
          ></BlockedUser>
        </SimpleModal>
      )}
      {state.showModalImg && (
        <SimpleModal onCloseRequest={() => handleToggleModalImg()}>
          <ImgDialog
            t={t}
            imgs={[
              markerReport.imageurl,
              markerReport.arrivalImageUrl,
              markerReport.doneimageurl,
            ]}
            onCloseRequest={() => handleToggleModalImg()}
          ></ImgDialog>
        </SimpleModal>
      )}
      {state.showModalCommentary && (
        <SimpleModal onCloseRequest={() => handleToggleModalCommentary()}>
          <CommentaryDialog
            commentary={state.commentary}
            typeCommentary={state.typeCommentary}
            markerReport={markerReport}
            currentDistrict={selectedLicense}
            getNotifications={getNotifications}
            team={teams.find(
              (e) => e.tasksList.indexOf(markerReport.reportid) !== -1
            )}
            assignedWorkers={markerReport.assignedWorkersList}
            onCloseRequest={() => handleToggleModalCommentary()}
          ></CommentaryDialog>
        </SimpleModal>
      )}
      {state.showModalComfirm && (
        <SimpleModal onCloseRequest={() => handleToggleModalComfirm()}>
          <ConfirmationDialog
            description={t("notification.commentary.remove")}
            doneClick={removeCommentary}
            t={t}
            onCloseRequest={() => handleToggleModalComfirm()}
          ></ConfirmationDialog>
        </SimpleModal>
      )}
      {state.showModalComfirmAutoAssign && (
        <SimpleModal
          onCloseRequest={() => handleToggleModalComfirmAutoAssign()}
        >
          <ConfirmationDialog
            description={""}
            title={t("name.auto.assign")}
            noBold1={t("notification.un.auto.text1")}
            noBold2={t("notification.un.auto.text2")}
            boldText={
              teams.find(
                (e: Team.AsObject) =>
                  e.tasksList.indexOf(markerReport.reportid) !== -1
              )
                ? teams.find(
                    (e: Team.AsObject) =>
                      e.tasksList.indexOf(markerReport.reportid) !== -1
                  )?.name || ""
                : ""
            }
            doneClick={autoAssign}
            t={t}
            onCloseRequest={() => handleToggleModalComfirmAutoAssign()}
          ></ConfirmationDialog>
        </SimpleModal>
      )}
    </div>
  );
};

export default CardReport;
