import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { BitMap } from "../../../assets/img";
import { ReportTypes } from "../../../redux/reportTypes/reportsReducer";
import { Add, DeleteIcon, Edit, MenuIcon } from "../../../assets/svg";

interface ItemGroupState {
  reportTypeGroup: ReportTypes;
  handleToggleManage: any;
  handleToggleModalDelete: any;
}

export const ItemGroup: React.FunctionComponent<ItemGroupState> = ({
  reportTypeGroup,
  handleToggleManage,
  handleToggleModalDelete,
}: // fetchAllReportTypes,
ItemGroupState) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [active, setActive] = useState<boolean>(false);

  return (
    <div className="item-group">
      <div className="item-father">
        <div className="column-1">
          <div className="foto-perfil-team">
            <div className={"box-image-types normal-bg-type "}>
              <img
                src={reportTypeGroup.iconmap ? reportTypeGroup.iconmap : BitMap}
                alt=""
              ></img>
            </div>
          </div>
        </div>
        <div className="column-2">
          <div className="foto-perfil-team appImg-list">
            <img
              src={reportTypeGroup.iconmain ? reportTypeGroup.iconmain : BitMap}
              alt=""
            ></img>
          </div>
        </div>
        <div className="column-3">
          <p>{reportTypeGroup.it}</p>
        </div>
        <div className="column-4">
          {" "}
          <p>{reportTypeGroup.es}</p>
        </div>
        <div className="column-5">
          {" "}
          <p>{reportTypeGroup.en}</p>
        </div>
        <div className="column-6">
          {" "}
          <p>{reportTypeGroup.pt}</p>
        </div>
        <div className="column-7">
          {" "}
          <p>{reportTypeGroup.fr}</p>
        </div>
        <div className="column-8">
          <div className="group-buttons-admins">
            <div
              className="tile-edit-button"
              onClick={() => {
                document
                  ?.querySelector(".settings-page")
                  ?.scrollIntoView({ behavior: "smooth" });
                handleToggleManage(false, true, reportTypeGroup);
              }}
            >
              <Add></Add>
            </div>
            <div
              className="tile-edit-button"
              onClick={() => {
                document
                  ?.querySelector(".settings-page")
                  ?.scrollIntoView({ behavior: "smooth" });
                handleToggleManage(true, true, reportTypeGroup);
              }}
            >
              <Edit></Edit>
            </div>
            <div
              className="tile-edit-button"
              onClick={() => {
                handleToggleModalDelete(true, reportTypeGroup);
              }}
            >
              <DeleteIcon></DeleteIcon>
            </div>
            {reportTypeGroup.reportTypes !== undefined &&
              reportTypeGroup.reportTypes.length > 0 && (
                <div
                  className="tile-edit-button"
                  style={{
                    backgroundColor: active ? "#4C72A1" : "white",
                    color: active ? "white" : "black",
                  }}
                  onClick={() => {
                    setActive((status) => !status);
                  }}
                >
                  {reportTypeGroup.reportTypes?.length}
                </div>
              )}
          </div>
        </div>
      </div>
      {active && (
        <div className="item-group-sons">
          {reportTypeGroup.reportTypes?.map((d: ReportTypes) => {
            return (
              <div className="item-son" key={d.reporttypeid}>
                <div className="column-1">
                  <div className="foto-perfil-team">
                    <div className={"box-image-types normal-bg-type "}>
                      <img src={d.iconmap ? d.iconmap : BitMap} alt=""></img>
                    </div>
                  </div>
                </div>
                <div className="column-2">
                  <div className="foto-perfil-team appImg-list">
                    <img src={d.iconmain ? d.iconmain : BitMap} alt=""></img>
                  </div>
                </div>
                <div className="column-3">
                  <p>{d.it}</p>
                </div>
                <div className="column-4">
                  {" "}
                  <p>{d.es}</p>
                </div>
                <div className="column-5">
                  {" "}
                  <p>{d.en}</p>
                </div>
                <div className="column-6">
                  {" "}
                  <p>{d.pt}</p>
                </div>
                <div className="column-7">
                  {" "}
                  <p>{d.fr}</p>
                </div>
                <div className="column-8">
                  <div className="group-buttons-admins">
                    <div
                      className="tile-edit-button"
                      onClick={() => {
                        document
                          ?.querySelector(".settings-page")
                          ?.scrollIntoView({ behavior: "smooth" });
                        handleToggleManage(true, true, d);
                      }}
                    >
                      <Edit></Edit>
                    </div>
                    <div
                      className="tile-edit-button"
                      onClick={() => {
                        handleToggleModalDelete(true, d);
                      }}
                    >
                      <DeleteIcon></DeleteIcon>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ItemGroup;
