import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../redux/Store";
import { useTranslation } from "react-i18next";
import "react-telephone-input/css/default.css";
import resizeImage from "resize-image";
import moment from "moment";

import {
  onlyDigitsNoSpace,
  onlyLettersNumbersSpaces,
  validPhoneRegex,
} from "../../../utils/utils";
import { licenseI } from "../../../redux/licenses/licensesReducer";
import { grpcUpdateLicense } from "../../../redux/licenses/licensesActions";

import BitMap from "../../../assets/img/profile-image.png";
import { Edit } from "../../../assets/svg/index";
import { Ignore } from "../../../assets/svg/index";
import TextFieldComponent from "../../components/text-field-component";
import ActionButtons from "../../components/Action-buttons";
import ReactTelInput from "react-telephone-input";
import ImageUploader from "react-images-upload";
import { SET_INFO_STATES } from "../../../redux/licenses/licensesTypes";

interface TileInfoProps {
  fetchAllLicenses: any;
}

export const TileInfo: React.FunctionComponent<TileInfoProps> = ({
  fetchAllLicenses,
}: any) => {
  const selectedLicense: licenseI | undefined = useSelector(
    (globalState: RootStore) => globalState.licenses.selectedLicense
  );
  const mainMode: boolean = useSelector(
    (globalState: RootStore) => globalState.licenses.mainMode
  );
  const reportMode: boolean = useSelector(
    (globalState: RootStore) => globalState.licenses.reportMode
  );

  const dateFormat = "DD/MM/YYYY";
  let defaultValue: any;
  let defaultValueArray: any = [];

  let inputProps = {
    readOnly: true,
  };

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [populationInit, setPopulationInit] = useState(defaultValue);
  const [currentUsersInit, setCurrentUsersInit] = useState(defaultValue);

  const [state, setState] = useState({
    name: selectedLicense?.name ? selectedLicense.name : "",
    population: selectedLicense?.population ? selectedLicense.population : "",
    cellphone: selectedLicense?.cellphone ? selectedLicense.cellphone : "",
    selectedCountry: selectedLicense?.countryCode
      ? selectedLicense.countryCode
      : "us",
    tempImg: selectedLicense?.imagePath
      ? selectedLicense.imagePath
      : defaultValue,
    tempUrl: defaultValue,
    cellphoneInvalid: false,
  });

  const changeToEdit = () => {
    dispatch({
      type: SET_INFO_STATES,
      mainMode: !mainMode,
      reportMode: reportMode,
    });
  };

  const uploadImageProfile = (value: any) => {
    validateExtension(value[value.length - 1])
      ? convertImageToBase64(value[value.length - 1])
      : console.log("error");
  };

  const validateExtension = (document: any) => {
    return /\.(gif|jpg|jpeg|tiff|png)$/i.test(
      document !== undefined &&
        document.name !== undefined &&
        document.name !== null
        ? document.name
        : ""
    );
  };

  const convertImageToBase64 = (documentFile: any) => {
    const reader = new FileReader();
    reader.onload = (upload: any) => {
      let dataUrl = upload.target.result;
      let image = new Image();
      image.src = dataUrl;
      image.onload = () => {
        const ImgBase64 = resizeImage.resize(image, 150, 150, resizeImage.JPEG);
        setState((prevState) => ({
          ...prevState,
          tempImg: ImgBase64 ? ImgBase64 : documentFile,
          tempUrl: ImgBase64 ? ImgBase64 : documentFile,
          ImgBase64: ImgBase64 ? true : false,
        }));
      };
    };
    reader.readAsDataURL(documentFile);
  };

  const select = (select: any) => {
    setState((prevState) => ({
      ...prevState,
      selectedCountry: select.iso2,
    }));
  };

  const handleForm = (name: any, event: any) => {
    if (name === "population") {
      if (!isNaN(event)) {
        setState((prevState) => ({
          ...prevState,
          [name]: event,
        }));
      }
    } else if (name === "cellphone") {
      if (!isNaN(event)) {
        setState((prevState) => ({
          ...prevState,
          [name]: event,
          cellphoneInvalid: false,
        }));
      }
    } else
      setState((prevState) => ({
        ...prevState,
        [name]: event,
      }));
  };

  const getValidSubmit = () => {
    if (
      state.name &&
      state.cellphone &&
      state.population &&
      state.selectedCountry
    ) {
      return true;
    } else {
      return false;
    }
  };

  const updateProfile = () => {
    if (!validPhoneRegex.test(state.cellphone) || state.cellphone.length <= 8) {
      setState((prevState) => ({
        ...prevState,
        cellphoneInvalid: true,
      }));
      return;
    }

    if (state.name === selectedLicense?.name) {
      dispatch(
        grpcUpdateLicense(
          {
            _id: selectedLicense._id,
            name: state.name.trim(),
            population: state.population,
            countryCode: state.selectedCountry,
            cellphone: state.cellphone,
            imagePath: state.tempUrl
              ? state.tempUrl
              : selectedLicense.imagePath,
            endAt: "",
          },
          () => {
            fetchAllLicenses();
            changeToEdit();
          }
        )
      );
    } else {
      //NEEDS TO CHECK EXISTS CITY NAME
      dispatch(
        grpcUpdateLicense(
          {
            _id: selectedLicense?._id,
            name: state.name.trim(),
            population: state.population,
            countryCode: state.selectedCountry,
            cellphone: state.cellphone,
            imagePath: state.tempUrl
              ? state.tempUrl
              : selectedLicense?.imagePath,
            endAt: "",
          },
          () => {
            fetchAllLicenses();
            changeToEdit();
          }
        )
      );
    }
  };

  useEffect(() => {
    let citiesAux = defaultValueArray;
    if (selectedLicense)
      selectedLicense.cities.forEach((element: any) => {
        if (citiesAux.length === 0) citiesAux.push(element);
        else {
          citiesAux.push(", " + element);
        }
      });

    let populationAux =
      selectedLicense && selectedLicense.population
        ? selectedLicense.population
        : 0;

    // TODO: usersCount
    let currentUsersAux = 0;

    setPopulationInit(populationAux);
    setCurrentUsersInit(currentUsersAux);
  }, []);

  return !mainMode ? (
    <div className="profile-card">
      <div className="profile-main">
        <div className="column">
          <div className="logo-box-profile">
            <img
              src={
                selectedLicense?.imagePath
                  ? selectedLicense.imagePath
                  : "https://miciudad.s3-us-west-1.amazonaws.com/profile-image.png"
              }
              alt=""
              className="logo"
            />
            {selectedLicense && selectedLicense.blocked ? (
              <Ignore></Ignore>
            ) : (
              <div></div>
            )}
          </div>
          <div className="title-profile">
            {selectedLicense && selectedLicense.name}
          </div>

          {selectedLicense && selectedLicense.blocked ? (
            <div className="blocked-tag">{t("name.blocked")}</div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
      <div className="profile-data">
        <div className="data-row">
          <div className="data-row-element">
            <div className="title-profile">{t("name.population")}</div>
            <div className="subtitle-profile">
              {String(populationInit).replace(/(.)(?=(\d{3})+$)/g, "$1,")}
            </div>
          </div>
          <div className="data-row-element">
            <div className="title-profile">{t("name.percentage.of.users")}</div>
            <div className="subtitle-profile">
              {Math.round(
                ((currentUsersInit * 100) / (populationInit || 1)) * 1000
              ) / 1000}
              % {t("name.location.of")}
            </div>
          </div>
        </div>
        <div className="data-row">
          <div className="data-row-element">
            <div className="title-profile">{t("name.users.on.app")}</div>
            <div className="subtitle-profile">{currentUsersInit}</div>
          </div>
          <div className="data-row-element">
            <div className="title-profile">{t("name.creation.date")}</div>
            <div className="subtitle-profile">
              {selectedLicense && selectedLicense.createAt
                ? moment(selectedLicense.createAt).format(dateFormat)
                : "-"}
            </div>
          </div>
        </div>
      </div>
      <div className="tile-edit-button" onClick={changeToEdit}>
        <Edit></Edit>
      </div>
    </div>
  ) : (
    <div className="profile-card-edit">
      <div className="edit-box">
        <div className="foto-perfil-box">
          <div className="foto-perfil-team">
            <img src={!state.tempImg ? BitMap : state.tempImg} alt=""></img>
            <ImageUploader
              className="file-upload"
              buttonText="Choose images"
              withLabel={false}
              withPreview={false}
              withIcon={false}
              onChange={uploadImageProfile}
              imgExtension={[".gif", ".png", ".gif"]}
              accept={".png"}
              maxFileSize={5242880}
            />
            <div className="text">{t("form.photo")}</div>{" "}
          </div>
        </div>
        <div className="options-body-item-big ">
          <div className="text">{t("name.super.name.city")}</div>{" "}
          <div className="selector-box full-width-select edit-profile-text-box">
            <TextFieldComponent
              placeholder={state.name ? state.name : t("name.super.name.city")}
              onChange={(event: any) => {
                handleForm("name", event);
              }}
              value={state.name}
              regExp={onlyLettersNumbersSpaces}
              invalid={false}
              maxValue={20}
              message={t("message.error.invalid.name")}
            ></TextFieldComponent>
          </div>
        </div>
        <div className="options-body-item-big ">
          <div className="text">{t("name.super.code.country")}</div>{" "}
          <div className="selector-box full-width-select cel-box-edit country-picker">
            <ReactTelInput
              defaultCountry={state.selectedCountry}
              flagsImagePath="./assets/flags.png"
              onChange={(telNumber: any, selectedCountry: any) => {
                select(selectedCountry);
              }}
              autoFormat={false}
              inputProps={inputProps}
            />
          </div>
          <div className="text text-cel">{t("name.super.cell")}</div>{" "}
          <div className="selector-box full-width-select cel-box-edit ">
            <TextFieldComponent
              placeholder={
                state.cellphone ? state.cellphone : t("name.cellphone")
              }
              value={state.cellphone}
              regExp={onlyDigitsNoSpace}
              onChange={(event: any) => handleForm("cellphone", event)}
              invalid={state.cellphoneInvalid}
              maxValue={10}
              message={t("message.error.invalid.cellphone")}
            ></TextFieldComponent>
          </div>
        </div>
        <div className="options-body-item-big ">
          <div className="text">{t("name.super.population")}</div>{" "}
          <div className="selector-box full-width-select edit-profile-text-box">
            <TextFieldComponent
              placeholder={
                state.population ? state.population : t("name.population")
              }
              onChange={(event: any) => handleForm("population", event)}
              regExp={onlyDigitsNoSpace}
              value={state.population}
              invalid={false}
              maxValue={12}
              message={t("message.error.short.name")}
            ></TextFieldComponent>
          </div>
        </div>
        <ActionButtons
          doneText={selectedLicense ? t("submit.confirm") : t("submit.save")}
          clickable={getValidSubmit()}
          doneClick={() => {
            updateProfile();
          }}
          cancelClick={changeToEdit}
          cancelText={t("submit.cancel")}
        ></ActionButtons>
      </div>
    </div>
  );
};

export default TileInfo;
