import React from "react";
import "./style.scss";
// import { css } from "@emotion/core";
import FeatherIcon from "feather-icons-react";
// First way to import
// Another way to import. This is recommended to reduce bundle size

class NotificationAlertDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      time: 0,
    };
  }
  componentDidMount() {
    this.intervalID = setInterval(() => this.tick(), 1000);
  }
  componentWillUnmount() {
    clearInterval(this.intervalID);
  }
  tick() {
    this.setState(
      {
        time: this.state.time + 1,
      },
      () => {
        if (this.state.time === 5) this.props.showDialogAlertNotification(null);
      }
    );
  }
  render() {
    return (
      <div className="notification-box">
        <div
          className="notification-general"
          onClick={() => {
            this.props.showDialogAlertNotification(null);
          }}
        >
          <div
            className="content-notification"
            // onClick={() => this.props.clearMessage()}
          >
            <div className="data-notification">
              <FeatherIcon icon="check" size="28" />
              <div>
                {this.props.t("notification.report.already.assigned") +
                  this.props.alreadyAssignedTeam.name}
              </div>
            </div>
            <div>{/* <div className="notification-undo">Undo</div> */}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default NotificationAlertDialog;
