import React from "react";
import "./style.scss";
// import { css } from "@emotion/core";
import FeatherIcon from "feather-icons-react";
// First way to import
// Another way to import. This is recommended to reduce bundle size

class NotificationGlobalDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      time: 0,
    };
  }
  componentDidMount() {
    this.intervalID = setInterval(() => this.tick(), 1000);
  }
  componentWillUnmount() {
    clearInterval(this.intervalID);
  }
  tick() {
    this.setState(
      {
        time: this.state.time + 1,
      },
      () => {
        if (this.state.time === 5) this.props.clearAssignedReport();
      }
    );
  }

  render() {
    return (
      <div className="notification-box">
        <div className="notification-general">
          <div
            className="content-notification"
            // onClick={() => this.props.clearMessage()}
          >
            <div
              className="data-notification"
              onClick={this.props.clearAssignedReport}
            >
              <FeatherIcon icon="check" size="28" />
              <div>
                {this.props.t(this.props.message)}{" "}
                {this.props.toTeam.name}
              </div>
            </div>
            {(this.props.showUndo) && (
              <div>
                <div
                  className="notification-undo"
                  onClick={() => {
                    this.props.undoAssignReport(
                      this.props.assignedReport,
                      this.props.toTeam
                    );
                  }}
                >
                  {this.props.t("name.undo")}
                </div>
              </div>  
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default NotificationGlobalDialog;
