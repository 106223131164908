import React, { useState } from "react";
import HeaderDialog from "../components/Header-dialog";
import { Arrow } from "../../assets/svg/index";
import SelectComponent from "../components/select-component";
import ActionButtons from "../components/Action-buttons";
import "./style.scss";
import { netWorking } from "../../firebase/index";
import { ASSIGN_TO_TEAM, REMOVE_FROM_TEAM } from "../../const/const";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Report } from "../../server/report/protos/report_pb";
import { AssignType, Team } from "../../server/team/protos/team_pb";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { grpcAssignToTeam } from "../../redux/teams/teamsActions";

interface DialogAsignarProps {
  onCloseRequest: any;
  markerReport: Report.AsObject;
  assignedReport: any;
  teams: Team.AsObject[];
}

export const DialogAsignar: React.FunctionComponent<DialogAsignarProps> = ({
  onCloseRequest,
  markerReport,
  assignedReport,
  teams,
}: DialogAsignarProps) => {
  const [state, setState] = useState<{
    selected: null | Team.AsObject;
    startDate: Date;
  }>({
    selected: null,
    startDate: new Date(),
  });

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectTeam = (e) => {
    setState((prevState) => ({
      ...prevState,
      selected: e,
    }));
  };

  const handleChange = (date) => {
    setState((prevState) => ({
      ...prevState,
      startDate: date,
    }));
  };

  const nextDay = () => {
    var date = state.startDate;
    date.setDate(date.getDate() + 1);
    handleChange(date);
  };

  const lastDay = () => {
    var date = state.startDate;
    date.setDate(date.getDate() - 1);
    handleChange(date);
  };

  const assignReport = () => {
    const tempTeam = state.selected;
    const tempReport = markerReport;
    const index = tempTeam?.tasksList.indexOf(markerReport.reportid);
    dispatch(
      grpcAssignToTeam(
        {
          id: tempTeam?.id || "",
          ids: [markerReport.reportid],
          type:
            index !== -1
              ? AssignType.REMOVE_FROM_TEAM_TASKS
              : AssignType.ASSIGN_TO_TEAM_TASKS,
        },
        () => {
          assignedReport(tempReport, tempTeam);
          onCloseRequest();
        }
      )
    );
    // if (index !== -1) {
    //   // netWorking.updateTeamAssignedReports({
    //   //   teamId: tempTeam?.id,
    //   //   reportsIds: [markerReport._id],
    //   //   assign: REMOVE_FROM_TEAM,
    //   //   createNotification: true,
    //   // });
    //   // props.assignedReport(tempReport, tempTeam);
    //   // props.onCloseRequest();
    // } else {
    //   // netWorking.updateTeamAssignedReports({
    //   //   teamId: tempTeam._id,
    //   //   reportsIds: [props.markerReport._id],
    //   //   assign: ASSIGN_TO_TEAM,
    //   //   createNotification: true,
    //   // });
    //   // props.assignedReport(tempReport, tempTeam);
    //   // props.onCloseRequest();
    // }
  };

  return (
    <div className="content-dialog">
      <HeaderDialog
        onCloseRequest={onCloseRequest}
        title={t("name.assign.report")}
        description={t("name.assign.report.description")}
      ></HeaderDialog>
      <div className="content-options">
        <div className="content-options-body">
          {/* {isEmpty(props.teams) && (
              <div className="options-body-item">
                <div className="selector-box">
                  <SelectComponent
                    initialData={"Sin grupos creados. Crea uno nuevo"}
                    data={["A", "B", "C", "A", "B", "C"]}
                  ></SelectComponent>
                </div>
                <div className="selector-button-box">
                  <BigButton data={"Crear nuevo"}></BigButton>
                </div>
              </div>
            )} */}
          <div className="options-body-item ">
            <div className="selector-box full-width-select">
              <SelectComponent
                initialData={t("form.select.team.name")}
                selected={state.selected}
                selectTeam={selectTeam}
                data={teams.filter(
                  (e) => e.jobsList.indexOf(markerReport.reporttypeid) !== -1
                )}
              ></SelectComponent>
            </div>
          </div>
          {/* <div className="options-body-item ">
              <div className="selector-box full-width-select">
              <div className="selector-box-content">
                <DatePicker
                 className={"text-field"}
                  selected={state.startDate}
                  onChange={handleChange}
                />
                </div>
              </div>
            </div> */}
          {/* <div className="options-body-item-big ">
            <div className="selector-box full-width-select half-text-field-1"></div>
            <div className="selector-box full-width-select half-text-field-2  date-picker">
              <DatePicker
                className={"text-field"}
                selected={state.startDate}
                onChange={handleChange}
              />
              <Arrow className="left-arrow" onClick={lastDay}></Arrow>
              <Arrow className="right-arrow" onClick={nextDay}></Arrow>
            </div>
          </div> */}
          <ActionButtons
            clickable={state.selected !== null}
            doneText={t("submit.continue")}
            doneClick={assignReport}
            cancelClick={onCloseRequest}
            cancelText={t("submit.cancel")}
          ></ActionButtons>
        </div>
      </div>
    </div>
  );
};

export default DialogAsignar;
