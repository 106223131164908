import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../redux/Store";
import { useTranslation } from "react-i18next";

import { grpcUpdateSetting } from "../../../redux/licenses/licensesActions";
import { licenseI } from "../../../redux/licenses/licensesReducer";

import { Edit } from "../../../assets/svg/index";
import LayerControlComponent from "../../../components/general-component/layerControl";
import { Map } from "react-leaflet";
import "leaflet/dist/leaflet.css";

interface TileMapProps {
  fetchAllLicenses: any;
}

export const TileMap: React.FunctionComponent<TileMapProps> = ({
  fetchAllLicenses,
}: any) => {
  const selectedLicense: licenseI | undefined = useSelector(
    (globalState: RootStore) => globalState.licenses.selectedLicense
  );

  const leafletMapRef = React.useRef<Map>(null);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    coords: {
      lng: -117.010139,
      lat: 32.805197,
    },
    zoom: 16,
    options: {
      clickableIcons: false,
    },
    editMode: false,
  });

  React.useEffect(() => {
    if (selectedLicense) {
      setState((prev) => ({
        ...prev,
        coords: selectedLicense?.licenseSettings?.coords
          ? {
              lng: selectedLicense.licenseSettings.coords.lat,
              lat: selectedLicense.licenseSettings.coords.lng,
            }
          : {
              lng: -117.010139,
              lat: 32.805197,
            },
        zoom: selectedLicense?.licenseSettings?.zoom
          ? selectedLicense.licenseSettings.zoom
          : 16,
      }));
    }
  }, [selectedLicense]);

  const changeToEdit = () => {
    setState((prevState) => ({
      ...prevState,
      editMode: !state.editMode,
    }));
  };

  const change = (e: any) => {
    if (state.zoom !== e)
      setState((prevState) => ({
        ...prevState,
        zoom: e,
      }));
  };

  const updateProfile = () => {
    if (selectedLicense) {
      let auxArray: any = [];

      if (selectedLicense.geometry.point) {
        let coordinates = selectedLicense.geometry.point
          ? selectedLicense.geometry.point
          : [];

        coordinates.map((coord: any) => {
          auxArray.push({
            lat: coord.lng,
            lng: coord.lat,
          });
        });
      }

      dispatch(
        grpcUpdateSetting(
          {
            _id: selectedLicense.settingsId,
            licenseId: selectedLicense._id,
            cities: selectedLicense.cities,

            points: auxArray,

            distanceInterval: selectedLicense.trackingSettings.distanceInterval,
            timeInterval: selectedLicense.trackingSettings.timeInterval,
            enable: selectedLicense.trackingSettings.enable,
            trackingType: selectedLicense.trackingSettings.trackingType,
            trackingMode: selectedLicense.trackingSettings.trackingMode,

            zoom: state.zoom,
            coords: state.coords,
            jobTypes: selectedLicense.licenseSettings.jobTypes,
            daysAdmin: selectedLicense.licenseSettings.reportsDaysForAdmin,
            daysTeam: selectedLicense.licenseSettings.reportsDaysForTeam,
            daysUser: selectedLicense.licenseSettings.reportsDaysForUser,
          },
          () => {
            fetchAllLicenses();
            changeToEdit();
          }
        )
      );
    }
  };

  return (
    <div className="tile-card map-card">
      <div className="tile-card-header">
        <div className="tile-title">{t("tabs.title.map")}</div>
        {!state.editMode ? (
          <div className="tile-edit-button" onClick={changeToEdit}>
            <Edit></Edit>
          </div>
        ) : (
          <div>
            {" "}
            <button className="btn-done-dialog-admin" onClick={updateProfile}>
              {t("name.save.changes")}
            </button>
          </div>
        )}
      </div>
      <div className="tile-card-body">
        <div className={!state.editMode ? "hide-controls map-tab" : " map-tab"}>
          <Map
            style={{ height: "100%", width: "100%" }}
            ref={leafletMapRef}
            center={
              selectedLicense?.licenseSettings?.coords
                ? {
                    lng: selectedLicense.licenseSettings.coords.lat,
                    lat: selectedLicense.licenseSettings.coords.lng,
                  }
                : {
                    lng: -117.010139,
                    lat: 32.805197,
                  }
            }
            zoom={
              selectedLicense?.licenseSettings?.zoom
                ? selectedLicense.licenseSettings.zoom
                : 16
            }
            zoomControl={false}
            ondragend={(map: any) => {
              setState((prevState) => ({
                ...prevState,
                coords: {
                  lng: map.target.getCenter().lng,
                  lat: map.target.getCenter().lat,
                },
              }));
            }}
            onzoomend={(map: any) => {
              change(map.target.getZoom());
            }}
          >
            <LayerControlComponent></LayerControlComponent>
          </Map>
        </div>
        {!state.editMode && <div className="block-map"></div>}
      </div>
    </div>
  );
};

export default TileMap;
