import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RootStore } from "../../redux/Store";
import "./style.scss";

import { grpcDeleteBlockLicense } from "../../redux/licenses/licensesActions";
import { grpcChangeLicenseStatus } from "../../redux/users/usersAction";
import {
  LICENSES_TAB,
  SET_INFO_STATES,
} from "../../redux/licenses/licensesTypes";
import { licenseI } from "../../redux/licenses/licensesReducer";

import { Ignore, DeleteIcon } from "../../assets/svg/index";
import ConfirmationDialog from "../dialogs/confirmation-dialog";
import SimpleModal from "../../utils/dialogs/simple-dialog";
import TileInfo from "./components/tile-info";
import TileServices from "./components/tile-services";
import TileUsers from "./components/tile-users";
import TileSettingsServices from "./components/tile-setting-services";
import TileMap from "./components/tile-map";
import TileArea from "./components/tile-area";
import TileResume from "./components/tile-resume";
import { grpcIgnoreNotification } from "../../redux/notifications/notificationsAction";
import {
  RequestIgnore,
  RequestType,
} from "../../server/notification/protos/notification_pb";
import { grpcIgnoreReportByLicense } from "../../redux/reportTypes/reportsActions";

interface ProfileProps {
  fetchAllUsers: any;
  fetchAllLicenses: any;
}

export const Profile: React.FunctionComponent<ProfileProps> = ({
  fetchAllUsers,
  fetchAllLicenses,
}: any) => {
  const selectedLicense: licenseI | undefined = useSelector(
    (globalState: RootStore) => globalState.licenses.selectedLicense
  );
  const tabLicense: any = useSelector(
    (globalState: RootStore) => globalState.licenses.tabIndex
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    showModalBlock: false,
    showModalDelete: false,
  });

  const handleToggleModalBlock = () => {
    setState((prevState) => ({
      ...prevState,
      showModalBlock: !state.showModalBlock,
    }));
  };

  const handleToggleModalDelete = () => {
    setState((prevState) => ({
      ...prevState,
      showModalDelete: !state.showModalDelete,
    }));
  };

  const blockMain = () => {
    if (selectedLicense) {
      dispatch(
        grpcDeleteBlockLicense({ _id: selectedLicense._id, type: 0 }, () => {
          fetchAllLicenses();
          handleToggleModalBlock();
        })
      );
      dispatch(
        grpcChangeLicenseStatus({
          licenseId: selectedLicense._id,
          licenseStatus: selectedLicense?.blocked ? 0 : 1,
        })
      );
      //DONT KNOW IF WE NEED TO BLOCK ALL FROM LICENSE
    }
  };

  const deleteMain = () => {
    if (selectedLicense) {
      dispatch(
        grpcDeleteBlockLicense({ _id: selectedLicense._id, type: 1 }, () => {
          fetchAllLicenses();
          handleToggleModalDelete();
        })
      );
      dispatch(
        grpcChangeLicenseStatus({
          licenseId: selectedLicense._id,
          licenseStatus: 2,
        })
      );
      ignoreNotificationsFromLicenses();
      ignoreReportsFromLicenses();
      //DONT KNOW IF WE NEED TO DELETE ALL FROM LICENSE
    }
  };

  const ignoreNotificationsFromLicenses = () => {
    dispatch(
      grpcIgnoreNotification({
        id: selectedLicense?._id || "",
        typeIgnore: RequestIgnore.RequestIgnoreType.IGNORE,
        typeRequest: RequestType.BY_CITYID,
      })
    );
  };

  const ignoreReportsFromLicenses = () => {
    dispatch(grpcIgnoreReportByLicense(selectedLicense?._id || ""));
  };

  useEffect(() => {
    fetchAllUsers();
  }, []);

  useEffect(() => {
    if (tabLicense !== 0) {
      dispatch({ type: SET_INFO_STATES, mainMode: false, reportMode: false });
    }
  }, [tabLicense]);

  return (
    <div className="profile-page">
      <div className="group-buttons">
        <div className="tile-edit-button" onClick={handleToggleModalDelete}>
          {t("name.super.delete")}
          <DeleteIcon></DeleteIcon>
        </div>
        <div className="tile-edit-button" onClick={handleToggleModalBlock}>
          {selectedLicense && selectedLicense.blocked
            ? t("name.super.unblock")
            : t("name.super.block")}
          <Ignore></Ignore>
        </div>
      </div>

      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <li className="nav-item">
          <a
            className={tabLicense === 0 ? "nav-link active" : "nav-link"}
            id="information-tab2"
            data-toggle="tab"
            href="#info2"
            role="tab"
            aria-controls="home2"
            aria-selected={tabLicense === 0 ? "true" : "false"}
            onClick={() => {
              dispatch({ type: LICENSES_TAB, tabIndex: 0 });
            }}
          >
            {t("tabs.info")}
          </a>
        </li>
        <li className="nav-item">
          <a
            className={tabLicense === 1 ? "nav-link active" : "nav-link"}
            id="admin-tab2"
            data-toggle="tab"
            href="#admin2"
            role="tab"
            aria-controls="contact2"
            aria-selected={tabLicense === 1 ? "true" : "false"}
            onClick={() => {
              dispatch({ type: LICENSES_TAB, tabIndex: 1 });
            }}
          >
            {t("tabs.administrators")}
          </a>
        </li>
        <li className="nav-item">
          <a
            className={tabLicense === 2 ? "nav-link active" : "nav-link"}
            id="settings-tab2"
            data-toggle="tab"
            href="#settings2"
            role="tab"
            aria-controls="contact"
            aria-selected={tabLicense === 2 ? "true" : "false"}
            onClick={() => {
              dispatch({ type: LICENSES_TAB, tabIndex: 2 });
            }}
          >
            {t("tabs.settings")}
          </a>
        </li>
        <li className="nav-item">
          <a
            className={tabLicense === 3 ? "nav-link active" : "nav-link"}
            id="map-tab2"
            data-toggle="tab"
            href="#map2"
            role="tab"
            aria-controls="map"
            aria-selected={tabLicense === 3 ? "true" : "false"}
            onClick={() => {
              dispatch({ type: LICENSES_TAB, tabIndex: 3 });
            }}
          >
            {t("tabs.map")}
          </a>
        </li>
        <li className="nav-item">
          <a
            className={tabLicense === 4 ? "nav-link active" : "nav-link"}
            id="area-tab2"
            data-toggle="tab"
            href="#area2"
            role="tab"
            aria-controls="area"
            aria-selected={tabLicense === 4 ? "true" : "false"}
            onClick={() => {
              dispatch({ type: LICENSES_TAB, tabIndex: 4 });
            }}
          >
            {t("tabs.area")}
          </a>
        </li>
        <li className="nav-item">
          <a
            className={tabLicense === 5 ? "nav-link active" : "nav-link"}
            id="resume-tab2"
            data-toggle="tab"
            href="#resume2"
            role="tab"
            aria-controls="resume"
            aria-selected={tabLicense === 5 ? "true" : "false"}
            onClick={() => {
              dispatch({ type: LICENSES_TAB, tabIndex: 5 });
            }}
          >
            {t("tabs.resume")}
          </a>
        </li>
      </ul>
      <div className="tab-content" id="myTabContent">
        {/* Tab Information */}
        <div
          className={
            tabLicense === 0 ? "tab-pane fade show active" : "tab-pane fade"
          }
          id="info2"
          role="tabpanel"
          key={Math.random().toString(36).substring(7)}
          aria-labelledby="information-tab2"
        >
          <TileInfo fetchAllLicenses={fetchAllLicenses}></TileInfo>
          <TileServices fetchAllLicenses={fetchAllLicenses}></TileServices>
        </div>

        {/* Tab Admins */}
        <div
          className={
            tabLicense === 1 ? "tab-pane fade show active" : "tab-pane fade"
          }
          id="admin2"
          role="tabpanel"
          key={Math.random().toString(36).substring(7)}
          aria-labelledby="admin-tab2"
        >
          <TileUsers
            fetchAllUsers={fetchAllUsers}
            fetchAllLicenses={fetchAllLicenses}
          ></TileUsers>
        </div>

        {/* Tab Settings */}
        <div
          className={
            tabLicense === 2 ? "tab-pane fade show active" : "tab-pane fade"
          }
          id="settings2"
          role="tabpanel"
          key={Math.random().toString(36).substring(7)}
          aria-labelledby="settings-tab2"
        >
          <TileSettingsServices
            fetchAllLicenses={fetchAllLicenses}
          ></TileSettingsServices>
        </div>

        {/* Tab Map */}
        <div
          className={
            tabLicense === 3 ? "tab-pane fade show active" : "tab-pane fade"
          }
          id="map2"
          role="tabpanel"
          key={Math.random().toString(36).substring(7)}
          aria-labelledby="map-tab2"
        >
          <TileMap fetchAllLicenses={fetchAllLicenses}></TileMap>
        </div>

        {/* Tab Area */}
        <div
          className={
            tabLicense === 4 ? "tab-pane fade show active" : "tab-pane fade"
          }
          id="area2"
          role="tabpanel"
          key={Math.random().toString(36).substring(7)}
          aria-labelledby="area-tab2"
        >
          <TileArea fetchAllLicenses={fetchAllLicenses}></TileArea>
        </div>

        {/* Tab Resume */}
        <div
          className={
            tabLicense === 5 ? "tab-pane fade show active" : "tab-pane fade"
          }
          id="resume2"
          role="tabpanel"
          key={Math.random().toString(36).substring(7)}
          aria-labelledby="resume-tab2"
        >
          <TileResume></TileResume>
        </div>
      </div>

      {state.showModalBlock && (
        <SimpleModal
          onCloseRequest={() => {
            handleToggleModalBlock();
          }}
        >
          <ConfirmationDialog
            t={t}
            doneClick={blockMain}
            description={
              selectedLicense?.blocked
                ? t("notification.un.block.city")
                : t("notification.block.city")
            }
            onCloseRequest={() => {
              handleToggleModalBlock();
            }}
          ></ConfirmationDialog>
        </SimpleModal>
      )}
      {state.showModalDelete && (
        <SimpleModal
          onCloseRequest={() => {
            handleToggleModalDelete();
          }}
        >
          <ConfirmationDialog
            t={t}
            doneClick={deleteMain}
            description={t("notification.delete.city")}
            onCloseRequest={() => {
              handleToggleModalDelete();
            }}
          ></ConfirmationDialog>
        </SimpleModal>
      )}
    </div>
  );
};

export default Profile;
