import React from "react";
// import FeatherIcon from "feather-icons-react";
import { Arrow } from "../../assets/svg/index";

class SelectComponentGeneric extends React.Component {
  state = {
    visible: false
  };

  render() {
    const selectValue = item => {
      this.props.select(item);
      this.setState({
        visible: false
      });
    };
    return (
      <div className="selector-box-content">
        <button
          className="selector"
          onClick={() => {
            if (this.props.disable === undefined || !this.props.disable)
              this.setState({ visible: !this.state.visible });
          }}
        >
          <p>
            {this.props.selected == null
              ? this.props.initialData
              : this.props.selected}
          </p>
          <Arrow></Arrow>
        </button>
        <div
          className={` ${
            this.state.visible ? "options-visible" : "options-invisible"
          } selector-options`}
        >
          {this.props.data.map(function(item, i) {
            return (
              <button
                key={i}
                className="dropdown-item"
                type="button"
                onClick={() => {
                  selectValue(item);
                }}
              >
                {item}
              </button>
            );
          })}
        </div>
      </div>
    );
  }
}
export default SelectComponentGeneric;
