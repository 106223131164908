export const onlyDigits = RegExp(/^[0-9\s]*$/);
export const onlyDigitsNoSpace = RegExp(/^\d+$/);

export const onlyLetters = RegExp(/^[a-zA-ZñÑ\s]*$/);

export const onlyLettersNumbersSpaces = RegExp(
  /^[A-Za-z0-9ñÑ ]*[A-Za-z0-9ñÑ][A-Za-z0-9ñÑ ]*$/
);

export const validEmailRegex = RegExp(
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
);

export const validPhoneRegex = RegExp(
  /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/i
);

export const validNumbersRegex = RegExp(/^[0-9]*$/i);

export enum USER_ROLE {
  ADMIN = "ADMIN",
  WORKER = "WORKER",
  SERVICES = "SERVICES",
  LEADER = "LEADER",
  SUPERVISOR = "SUPERVISOR",
  CASHIER = "CASHIER",
}

export enum REPORT_TYPE_CHART {
  PENDING = "pending",
  PROGRESS = "progress",
  COMPLETED = "completed",
}

export enum CHART_TYPE_FIELD {
  DATE_DATE = "dateDate",
  CATEGORY_DATE = "categoryDate",
  RESPONSE_DATE = "responseDate",
}

export enum CHART_TIME {
  TODAY = "TODAY",
  WEEK = "WEEK",
  MONTH = "MONTH",
  CUSTOM = "CUSTOM",
}

export const getCalendarLocale = (t: any) => {
  let locale = {
    sunday: t("calendar.sunday"),
    monday: t("calendar.monday"),
    tuesday: t("calendar.tuesday"),
    wednesday: t("calendar.wednesday"),
    thursday: t("calendar.thursday"),
    friday: t("calendar.friday"),
    saturday: t("calendar.saturday"),
    ok: "OK",
    today: t("calendar.today"),
    yesterday: t("calendar.yesterday"),
    last7Days: t("calendar.last7days"),
    hours: t("calendar.hours"),
    minutes: t("calendar.minutes"),
    seconds: t("calendar.seconds"),
  };

  return locale;
};

export const getMonth = (month: any, t: any) => {
  let monthString: any;
  switch (month) {
    case 1:
      monthString = t("month.january.initials");
      break;
    case 2:
      monthString = t("month.february.initials");
      break;
    case 3:
      monthString = t("month.march.initials");
      break;
    case 4:
      monthString = t("month.april.initials");
      break;
    case 5:
      monthString = t("month.may.initials");
      break;
    case 6:
      monthString = t("month.june.initials");
      break;
    case 7:
      monthString = t("month.july.initials");
      break;
    case 8:
      monthString = t("month.august.initials");
      break;
    case 9:
      monthString = t("month.september.initials");
      break;
    case 10:
      monthString = t("month.october.initials");
      break;
    case 11:
      monthString = t("month.november.initials");
      break;
    case 12:
      monthString = t("month.december.initials");
      break;
    default:
      break;
  }
  return monthString;
};
