import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../redux/Store";
import ImageUploader from "react-images-upload";
import { useTranslation } from "react-i18next";
import resizeImage from "resize-image";

import {
  grpcCreateReportType,
  grpcUpdateReportType,
} from "../../../redux/reportTypes/reportsActions";

import TextFieldComponent from "../../components/text-field-component";
import ActionButtons from "../../components/Action-buttons";
import BitMap from "../../../assets/img/profile-image.png";
import { ReportTypes } from "../../../redux/reportTypes/reportsReducer";
import { onlyLetters } from "../../../utils/utils";

interface TileReportTypesSaveProps {
  handleToggleManage: any;
  onCloseRequest: any;
  reportTypeFather?: ReportTypes;
  reportType?: ReportTypes;
}

export const TileReportTypesSave: React.FunctionComponent<TileReportTypesSaveProps> =
  ({
    handleToggleManage,
    onCloseRequest,
    reportTypeFather,
    reportType,
  }: TileReportTypesSaveProps) => {
    // const selectedReportType: any = useSelector(
    //   (globalState: RootStore) => globalState.reportTypes.selectedReportType
    // );
    const { t } = useTranslation();
    const dispatch = useDispatch();

    let defaultValue: any;

    const [state, setState] = useState({
      toEdit: defaultValue,
    });

    const handleFormEdit = (name: any, event: any) => {
      let toEditAux = state.toEdit;
      toEditAux[name] = event;
      setState((prevState) => ({
        ...prevState,
        toEdit: toEditAux,
      }));
    };

    const getValidSubmit = () => {
      if (
        state.toEdit?.reportImg &&
        state.toEdit?.appImg &&
        state.toEdit?.es &&
        state.toEdit?.it &&
        state.toEdit?.fr &&
        state.toEdit?.pt &&
        state.toEdit?.en
      ) {
        return true;
      } else {
        return false;
      }
    };

    const createProfile = () => {
      dispatch(
        grpcCreateReportType(
          {
            iconMain: state.toEdit?.reportImg,
            iconMap: state.toEdit?.appImg,
            displayName: [
              {
                langCode: "es",
                value: state.toEdit?.es.trim(),
              },
              {
                langCode: "it",
                value: state.toEdit?.it.trim(),
              },
              {
                langCode: "fr",
                value: state.toEdit?.fr.trim(),
              },
              {
                langCode: "pt",
                value: state.toEdit?.pt.trim(),
              },
              {
                langCode: "en",
                value: state.toEdit?.en.trim(),
              },
            ],
            parentId: reportTypeFather?.reporttypeid || "",
          },
          () => {
            onCloseRequest();
            handleToggleManage(true, false);
          }
        )
      );
    };

    const updateProfile = () => {
      dispatch(
        grpcUpdateReportType(
          {
            _id: state.toEdit?._id,
            iconMain: state.toEdit?.reportImg,
            iconMap: state.toEdit?.appImg,
            parentId: state.toEdit?.parentid ? state.toEdit?.parentid : "",
            displayName: [
              {
                langCode: "es",
                value: state.toEdit?.es.trim(),
              },
              {
                langCode: "it",
                value: state.toEdit?.it.trim(),
              },
              {
                langCode: "fr",
                value: state.toEdit?.fr.trim(),
              },
              {
                langCode: "pt",
                value: state.toEdit?.pt.trim(),
              },
              {
                langCode: "en",
                value: state.toEdit?.en.trim(),
              },
            ],
          },
          () => {
            onCloseRequest();
            handleToggleManage(true, false);
          }
        )
      );
    };

    const uploadImageProfile = (value: any, mode: any, name: any) => {
      validateExtension(value[value.length - 1])
        ? convertImageToBase64(value[value.length - 1], mode, name)
        : console.log("error");
    };

    const validateExtension = (document: any) => {
      return /\.(gif|jpg|jpeg|tiff|png)$/i.test(
        document !== undefined &&
          document.name !== undefined &&
          document.name !== null
          ? document.name
          : ""
      );
    };

    const getClass = (key: any) => {
      switch (key) {
        case 0:
          return "reported-type ";
        case 1:
          return "assigned-type ";
        case 2:
          return "inprogress-type ";
        case 3:
          return "problem-type ";
        case 4:
          return "completed-type ";
        default:
          return "";
      }
    };

    const convertImageToBase64 = (documentFile: any, mode: any, name: any) => {
      const reader = new FileReader();
      reader.onload = (upload: any) => {
        let dataUrl = upload.target.result;
        let image = new Image();
        image.src = dataUrl;
        image.onload = () => {
          const ImgBase64 = resizeImage.resize(
            image,
            100,
            100,
            resizeImage.PNG
          );
          let toUpdate = state[mode];
          toUpdate[name] = ImgBase64 ? ImgBase64 : documentFile;
          setState((prevState) => ({
            ...prevState,
            [mode]: toUpdate,
          }));
        };
      };
      reader.readAsDataURL(documentFile);
    };

    useEffect(() => {
      setState((prevState) => ({
        ...prevState,
        toEdit: {
          _id: reportType ? reportType.reporttypeid : defaultValue,
          reportImg: reportType ? reportType.iconmain : defaultValue,
          appImg: reportType ? reportType.iconmap : defaultValue,
          parentid: reportType ? reportType.parentid : defaultValue,
          es: reportType ? reportType.es : defaultValue,
          it: reportType ? reportType.it : defaultValue,
          fr: reportType ? reportType.fr : defaultValue,
          pt: reportType ? reportType.pt : defaultValue,
          en: reportType ? reportType.en : defaultValue,
        },
      }));
    }, []);

    return (
      <div className="profile-card-edit report-types-edit">
        <div className="box-edit-data">
          <div className="column-box-report-type">
            <div className="title-column">{t("name.images")}</div>
            <div className="foto-perfil-box img-box-report-type">
              <div className="foto-perfil-team">
                <div
                  className={!state.toEdit?.reportImg ? "" : "box-image-types"}
                >
                  <img
                    src={
                      !state.toEdit?.reportImg ? BitMap : state.toEdit.reportImg
                    }
                    alt=""
                  ></img>
                </div>

                <ImageUploader
                  className="file-upload"
                  buttonText="Choose images"
                  withLabel={false}
                  withPreview={false}
                  withIcon={false}
                  onChange={(value: any) => {
                    uploadImageProfile(value, "toEdit", "reportImg");
                  }}
                  imgExtension={[".gif", ".png", ".gif"]}
                  accept={".png"}
                  maxFileSize={5242880}
                />
                <div className="text">
                  <div className="text-title">{t("name.edit.image")}</div>
                  <div className="text-subtitle">
                    *{t("name.edit.image.to.map")}
                  </div>
                  <div className="text-subtitle">
                    **{t("name.edit.image.size")}
                  </div>
                </div>
              </div>
            </div>
            <div className="foto-perfil-box img-box-report-type">
              <div className="foto-perfil-team  appImg-box">
                <img
                  src={!state.toEdit?.appImg ? BitMap : state.toEdit.appImg}
                  alt=""
                ></img>
                <ImageUploader
                  className="file-upload"
                  buttonText="Choose images"
                  withLabel={false}
                  withPreview={false}
                  withIcon={false}
                  onChange={(value: any) => {
                    uploadImageProfile(value, "toEdit", "appImg");
                  }}
                  imgExtension={[".gif", ".png", ".gif"]}
                  accept={".png"}
                  maxFileSize={5242880}
                />
                <div className="text">
                  <div className="text-title">{t("name.edit.image")}</div>
                  <div className="text-subtitle">
                    *{t("name.edit.image.to.app")}
                  </div>
                  <div className="text-subtitle">
                    **{t("name.edit.image.size")}
                  </div>
                </div>{" "}
              </div>
            </div>
          </div>
          <div className="column-box-report-type language-report-type-box">
            <div className="title-column">{t("name.edit.language")}</div>
            <div className="options-body-item-big ">
              <div className="text">{t("languages.italian")}</div>{" "}
              <div className="selector-box full-width-select">
                <TextFieldComponent
                  placeholder={t("languages.italian")}
                  onChange={(event: any) => {
                    handleFormEdit("it", event);
                  }}
                  value={state.toEdit?.it || ""}
                  regExp={onlyLetters}
                  invalid={false}
                  maxValue={20}
                  message={t("message.error.short.name")}
                ></TextFieldComponent>
              </div>
            </div>
            <div className="options-body-item-big ">
              <div className="text">{t("languages.spanish")}</div>{" "}
              <div className="selector-box full-width-select">
                <TextFieldComponent
                  placeholder={t("languages.spanish")}
                  onChange={(event: any) => {
                    handleFormEdit("es", event);
                  }}
                  value={state.toEdit?.es || ""}
                  regExp={onlyLetters}
                  invalid={false}
                  maxValue={20}
                  message={t("message.error.invalid.email")}
                ></TextFieldComponent>
              </div>
            </div>
            <div className="options-body-item-big ">
              <div className="text">{t("languages.english")}</div>{" "}
              <div className="selector-box full-width-select">
                <TextFieldComponent
                  placeholder={t("languages.english")}
                  onChange={(event: any) => {
                    handleFormEdit("en", event);
                  }}
                  value={state.toEdit?.en || ""}
                  regExp={onlyLetters}
                  invalid={false}
                  maxValue={20}
                  message={t("message.error.short.name")}
                ></TextFieldComponent>
              </div>
            </div>
            <div className="options-body-item-big ">
              <div className="text">{t("languages.portuguese")}</div>{" "}
              <div className="selector-box full-width-select">
                <TextFieldComponent
                  placeholder={t("languages.portuguese")}
                  onChange={(event: any) => {
                    handleFormEdit("pt", event);
                  }}
                  value={state.toEdit?.pt || ""}
                  regExp={onlyLetters}
                  invalid={false}
                  maxValue={20}
                  message={t("message.error.invalid.email")}
                ></TextFieldComponent>
              </div>
            </div>
            <div className="options-body-item-big ">
              <div className="text">{t("languages.french")}</div>{" "}
              <div className="selector-box full-width-select">
                <TextFieldComponent
                  placeholder={t("languages.french")}
                  onChange={(event: any) => {
                    handleFormEdit("fr", event);
                  }}
                  value={state.toEdit?.fr || ""}
                  regExp={onlyLetters}
                  invalid={false}
                  maxValue={20}
                  message={t("message.error.invalid.email")}
                ></TextFieldComponent>
              </div>
            </div>
          </div>
          <div className="column-box-report-type">
            <div className="title-column">{t("name.edit.preview")}</div>
            {[
              "name.img.reported",
              "name.img.assigned",
              "name.img.inprogress",
              "name.img.problem",
              "name.img.completed",
            ].map((e: any, index: any) => {
              return (
                <div key={index} className="preview-icons">
                  <div className="text">{t(e)}</div>
                  <div
                    className={
                      (!state.toEdit?.reportImg
                        ? "radius"
                        : "box-image-types ") + getClass(index)
                    }
                  >
                    <img
                      src={
                        !state.toEdit?.reportImg
                          ? BitMap
                          : state.toEdit?.reportImg
                      }
                      alt=""
                    ></img>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="edit-box report-type-buttons">
          <ActionButtons
            doneText={t("submit.confirm")}
            clickable={getValidSubmit()}
            doneClick={() => {
              if (state.toEdit?._id) updateProfile();
              else createProfile();
            }}
            cancelClick={() => {
              handleToggleManage(true, false);
              //addEdit(false, false, {});
            }}
            cancelText={t("submit.cancel")}
          ></ActionButtons>
        </div>
      </div>
    );
  };

export default TileReportTypesSave;
