import React, { useState, useEffect, createRef } from "react";
import mergeRefs from "react-merge-refs";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import TileCalendar from "./tile-calendar";
import { Report } from "../../../server/report/protos/report_pb";
import { ReportTypes } from "../../../redux/reportTypes/reportsReducer";
import { AssignType, Team } from "../../../server/team/protos/team_pb";
import { useTranslation } from "react-i18next";
import { grpcAssignToTeam } from "../../../redux/teams/teamsActions";

// fake data generator
// const getItems = count =>
//   Array.from({ length: count }, (v, k) => k).map(k => ({
//     id: `item-${k}`,
//     content: `item ${k}`
//   }));

// a little function to help us with reordering the result

interface ListDragProps {
  type: any;
  openTab: any;
  t: any;
  selectedTeam: Team.AsObject;
  users: any;
  inProgress: Report.AsObject[];
  inProgressR: Report.AsObject[];
  completed: Report.AsObject[];
  remarkedReport: any;
  reportTypes: ReportTypes[];
  isDraggableDisabled: any;
}

export const ListDrag: React.FunctionComponent<ListDragProps> = ({
  type,
  openTab,
  selectedTeam,
  users,
  inProgress,
  inProgressR,
  completed,
  remarkedReport,
  reportTypes,
  isDraggableDisabled,
}: ListDragProps) => {
  let defaultValue: Report.AsObject[] = [];
  const exampleRef = createRef<HTMLDivElement>();

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [state, setState] = useState<{
    items: Report.AsObject[];
    items2: Report.AsObject[];
    items3: Report.AsObject[];
  }>({
    items: defaultValue,
    items2: defaultValue,
    items3: defaultValue,
  });

  const reorder = (list: Report.AsObject[], startIndex: any, endIndex: any) => {
    const result: Report.AsObject[] = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items: Report.AsObject[] = reorder(
      state.items,
      result.source.index,
      result.destination.index
    );
    let listOfIds: any = [];
    let team: Team.AsObject;
    if (type === 1) {
      team = selectedTeam;
      items.forEach((e: Report.AsObject) => {
        listOfIds.push(e.reportid);
      });
      state.items2.forEach((e: Report.AsObject) => {
        listOfIds.push(e.reportid);
      });
      state.items3.forEach((e: Report.AsObject) => {
        listOfIds.push(e.reportid);
      });

      team.tasksList = listOfIds;
      updateTeamsTask(team.id, team.tasksList);
    }
    if (type === 2) {
      team = selectedTeam;
      state.items2.forEach((e: Report.AsObject) => {
        listOfIds.push(e.reportid);
      });
      items.forEach((e: any) => {
        listOfIds.push(e.reportid);
      });
      state.items3.forEach((e: Report.AsObject) => {
        listOfIds.push(e.reportid);
      });

      team.tasksList = listOfIds;
      updateTeamsTask(team.id, team.tasksList);
    }
    if (type === 3) {
      team = selectedTeam;
      state.items2.forEach((e: Report.AsObject) => {
        listOfIds.push(e.reportid);
      });
      state.items3.forEach((e: Report.AsObject) => {
        listOfIds.push(e.reportid);
      });
      items.forEach((e: Report.AsObject) => {
        listOfIds.push(e.reportid);
      });

      team.tasksList = listOfIds;
      updateTeamsTask(team.id, team.tasksList);
    }

    setState((prevState) => ({
      ...prevState,
      items,
    }));
  };

  const updateTeamsTask = (id: string, tasksList: string[]) => {
    dispatch(
      grpcAssignToTeam({
        id: id,
        ids: tasksList,
        type: AssignType.UPDATE_TO_TEAM_TASKS_POSITION,
      })
    );
  };

  useEffect(() => {
    if (type === 1) {
      setState((prevState) => ({
        ...prevState,
        items: inProgress,
        items2: inProgressR,
        items3: completed,
      }));
    }
    if (type === 2) {
      setState((prevState) => ({
        ...prevState,
        items: inProgressR,
        items2: inProgress,
        items3: completed,
      }));
    }
    if (type === 3) {
      setState((prevState) => ({
        ...prevState,
        items: completed,
        items2: inProgress,
        items3: inProgressR,
      }));
    }
  }, []);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" direction="vertical">
        {(provided: any, snapshot: any) => (
          <div
            className="list-droppable"
            ref={mergeRefs([exampleRef, provided.innerRef])}
            {...provided.droppableProps}
          >
            {/* <div
              className="arrow-left-list"
              onClick={() => {
                this.exampleRef.current.scrollLeft =
                  this.exampleRef.current.scrollLeft - 200;
              }}
            >
              <FeatherIcon icon="arrow-left" size="18" />
            </div> */}

            {state.items.map((item: Report.AsObject, index: any) => (
              <Draggable
                isDragDisabled={isDraggableDisabled}
                key={item.reportid}
                draggableId={item.reportid}
                index={index}
              >
                {(provided: any, snapshot: any) => (
                  <TileCalendar
                    users={users}
                    t={t}
                    index={selectedTeam.tasksList.indexOf(item.reportid)}
                    key={item.reportid}
                    item={item}
                    // reportsList={team.reports.indexOf(item._id)}
                    provided={provided}
                    snapshot={snapshot}
                    openTab={openTab}
                    type={type}
                    remarkedReport={remarkedReport}
                    reportTypes={reportTypes}
                  ></TileCalendar>
                )}
              </Draggable>
            ))}
            {provided.placeholder}

            {/* <div
              className="arrow-right-list"
              onClick={() => {
                this.exampleRef.current.scrollLeft =
                  this.exampleRef.current.scrollLeft + 200;
              }}
            >
              <FeatherIcon icon="arrow-right" size="18" />
            </div> */}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ListDrag;
