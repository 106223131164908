import React from "react";
import "./style.scss";

class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        <div className="copyright">
          &copy; {this.props.t("copyright.footer")}{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://giooby.com/"
          >
            Giooby.com
          </a>
        </div>
      </div>
    );
  }
}

export default Footer;
