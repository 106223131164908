import { Role } from "../../server/user/protos/auth_pb";
import { User } from "../../server/user/protos/user_pb";
import {
  LOADING_USER,
  SET_AUTHENTICATED,
  SET_UNAUTHENTICATED,
  SET_USER,
  SET_SUPER_ADMIN,
  UserDispatchTypes,
} from "./userTypes";

export interface userState {
  authenticated: boolean;
  superAdmin: boolean;
  loading: boolean;
  user?: User.AsObject;

}

export const defaultState: userState = {
  authenticated: false,
  superAdmin: false,
  loading: false,
};

export const userReducer = (
  state: userState = defaultState,
  action: UserDispatchTypes
): userState => {
  switch (action.type) {
    case SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: true,
        superAdmin: action.superAdmin,
        loading: false,
      };
    case SET_UNAUTHENTICATED:
      return {
        ...state,
        authenticated: false,
        superAdmin: false,
        loading: false,
      };
    case SET_USER:
      console.log(action.payload);
      return {
        ...state,
        authenticated: true,
        loading: false,
        user: action.payload,
      };
    case SET_SUPER_ADMIN:
      console.log(action.payload);
      return {
        ...state,
        superAdmin: action.payload,
      };

    case LOADING_USER:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};
