/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as protos_report_pb from '../protos/report_pb';


export class ReportServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoCreateReport = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_report_pb.CreateReportResult,
    (request: protos_report_pb.CreateReportMessage) => {
      return request.serializeBinary();
    },
    protos_report_pb.CreateReportResult.deserializeBinary
  );

  createReport(
    request: protos_report_pb.CreateReportMessage,
    metadata: grpcWeb.Metadata | null): Promise<protos_report_pb.CreateReportResult>;

  createReport(
    request: protos_report_pb.CreateReportMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_report_pb.CreateReportResult) => void): grpcWeb.ClientReadableStream<protos_report_pb.CreateReportResult>;

  createReport(
    request: protos_report_pb.CreateReportMessage,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_report_pb.CreateReportResult) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ReportService/CreateReport',
        request,
        metadata || {},
        this.methodInfoCreateReport,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ReportService/CreateReport',
    request,
    metadata || {},
    this.methodInfoCreateReport);
  }

  methodInfoStartReportWork = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_report_pb.Success,
    (request: protos_report_pb.StartReportWorkMessage) => {
      return request.serializeBinary();
    },
    protos_report_pb.Success.deserializeBinary
  );

  startReportWork(
    request: protos_report_pb.StartReportWorkMessage,
    metadata: grpcWeb.Metadata | null): Promise<protos_report_pb.Success>;

  startReportWork(
    request: protos_report_pb.StartReportWorkMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_report_pb.Success) => void): grpcWeb.ClientReadableStream<protos_report_pb.Success>;

  startReportWork(
    request: protos_report_pb.StartReportWorkMessage,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_report_pb.Success) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ReportService/StartReportWork',
        request,
        metadata || {},
        this.methodInfoStartReportWork,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ReportService/StartReportWork',
    request,
    metadata || {},
    this.methodInfoStartReportWork);
  }

  methodInfoFinishReportWork = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_report_pb.Success,
    (request: protos_report_pb.FinishReportWorkMessage) => {
      return request.serializeBinary();
    },
    protos_report_pb.Success.deserializeBinary
  );

  finishReportWork(
    request: protos_report_pb.FinishReportWorkMessage,
    metadata: grpcWeb.Metadata | null): Promise<protos_report_pb.Success>;

  finishReportWork(
    request: protos_report_pb.FinishReportWorkMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_report_pb.Success) => void): grpcWeb.ClientReadableStream<protos_report_pb.Success>;

  finishReportWork(
    request: protos_report_pb.FinishReportWorkMessage,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_report_pb.Success) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ReportService/FinishReportWork',
        request,
        metadata || {},
        this.methodInfoFinishReportWork,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ReportService/FinishReportWork',
    request,
    metadata || {},
    this.methodInfoFinishReportWork);
  }

  methodInfoListenReportUpdates = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_report_pb.Report,
    (request: protos_report_pb.ListenReportsMessage) => {
      return request.serializeBinary();
    },
    protos_report_pb.Report.deserializeBinary
  );

  listenReportUpdates(
    request: protos_report_pb.ListenReportsMessage,
    metadata?: grpcWeb.Metadata) {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/ReportService/ListenReportUpdates',
      request,
      metadata || {},
      this.methodInfoListenReportUpdates);
  }

  methodInfoAssignReport = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_report_pb.Success,
    (request: protos_report_pb.AssignMessage) => {
      return request.serializeBinary();
    },
    protos_report_pb.Success.deserializeBinary
  );

  assignReport(
    request: protos_report_pb.AssignMessage,
    metadata: grpcWeb.Metadata | null): Promise<protos_report_pb.Success>;

  assignReport(
    request: protos_report_pb.AssignMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_report_pb.Success) => void): grpcWeb.ClientReadableStream<protos_report_pb.Success>;

  assignReport(
    request: protos_report_pb.AssignMessage,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_report_pb.Success) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ReportService/AssignReport',
        request,
        metadata || {},
        this.methodInfoAssignReport,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ReportService/AssignReport',
    request,
    metadata || {},
    this.methodInfoAssignReport);
  }

  methodInfoWorkReport = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_report_pb.Report,
    (request: protos_report_pb.ReportWorkMessage) => {
      return request.serializeBinary();
    },
    protos_report_pb.Report.deserializeBinary
  );

  workReport(
    request: protos_report_pb.ReportWorkMessage,
    metadata: grpcWeb.Metadata | null): Promise<protos_report_pb.Report>;

  workReport(
    request: protos_report_pb.ReportWorkMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_report_pb.Report) => void): grpcWeb.ClientReadableStream<protos_report_pb.Report>;

  workReport(
    request: protos_report_pb.ReportWorkMessage,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_report_pb.Report) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ReportService/WorkReport',
        request,
        metadata || {},
        this.methodInfoWorkReport,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ReportService/WorkReport',
    request,
    metadata || {},
    this.methodInfoWorkReport);
  }

  methodInfoFetchNearReports = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_report_pb.FetchReportsResult,
    (request: protos_report_pb.FetchNearReportsMessage) => {
      return request.serializeBinary();
    },
    protos_report_pb.FetchReportsResult.deserializeBinary
  );

  fetchNearReports(
    request: protos_report_pb.FetchNearReportsMessage,
    metadata: grpcWeb.Metadata | null): Promise<protos_report_pb.FetchReportsResult>;

  fetchNearReports(
    request: protos_report_pb.FetchNearReportsMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_report_pb.FetchReportsResult) => void): grpcWeb.ClientReadableStream<protos_report_pb.FetchReportsResult>;

  fetchNearReports(
    request: protos_report_pb.FetchNearReportsMessage,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_report_pb.FetchReportsResult) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ReportService/FetchNearReports',
        request,
        metadata || {},
        this.methodInfoFetchNearReports,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ReportService/FetchNearReports',
    request,
    metadata || {},
    this.methodInfoFetchNearReports);
  }

  methodInfoFetchReportsByUser = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_report_pb.FetchReportsResult,
    (request: protos_report_pb.IdMessage) => {
      return request.serializeBinary();
    },
    protos_report_pb.FetchReportsResult.deserializeBinary
  );

  fetchReportsByUser(
    request: protos_report_pb.IdMessage,
    metadata: grpcWeb.Metadata | null): Promise<protos_report_pb.FetchReportsResult>;

  fetchReportsByUser(
    request: protos_report_pb.IdMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_report_pb.FetchReportsResult) => void): grpcWeb.ClientReadableStream<protos_report_pb.FetchReportsResult>;

  fetchReportsByUser(
    request: protos_report_pb.IdMessage,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_report_pb.FetchReportsResult) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ReportService/FetchReportsByUser',
        request,
        metadata || {},
        this.methodInfoFetchReportsByUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ReportService/FetchReportsByUser',
    request,
    metadata || {},
    this.methodInfoFetchReportsByUser);
  }

  methodInfoFetchReport = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_report_pb.Report,
    (request: protos_report_pb.IdMessage) => {
      return request.serializeBinary();
    },
    protos_report_pb.Report.deserializeBinary
  );

  fetchReport(
    request: protos_report_pb.IdMessage,
    metadata: grpcWeb.Metadata | null): Promise<protos_report_pb.Report>;

  fetchReport(
    request: protos_report_pb.IdMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_report_pb.Report) => void): grpcWeb.ClientReadableStream<protos_report_pb.Report>;

  fetchReport(
    request: protos_report_pb.IdMessage,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_report_pb.Report) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ReportService/FetchReport',
        request,
        metadata || {},
        this.methodInfoFetchReport,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ReportService/FetchReport',
    request,
    metadata || {},
    this.methodInfoFetchReport);
  }

  methodInfoIgnoreReport = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_report_pb.Success,
    (request: protos_report_pb.IdMessage) => {
      return request.serializeBinary();
    },
    protos_report_pb.Success.deserializeBinary
  );

  ignoreReport(
    request: protos_report_pb.IdMessage,
    metadata: grpcWeb.Metadata | null): Promise<protos_report_pb.Success>;

  ignoreReport(
    request: protos_report_pb.IdMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_report_pb.Success) => void): grpcWeb.ClientReadableStream<protos_report_pb.Success>;

  ignoreReport(
    request: protos_report_pb.IdMessage,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_report_pb.Success) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ReportService/IgnoreReport',
        request,
        metadata || {},
        this.methodInfoIgnoreReport,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ReportService/IgnoreReport',
    request,
    metadata || {},
    this.methodInfoIgnoreReport);
  }

  methodInfoIgnoreReportByLicense = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_report_pb.Success,
    (request: protos_report_pb.IdMessage) => {
      return request.serializeBinary();
    },
    protos_report_pb.Success.deserializeBinary
  );

  ignoreReportByLicense(
    request: protos_report_pb.IdMessage,
    metadata: grpcWeb.Metadata | null): Promise<protos_report_pb.Success>;

  ignoreReportByLicense(
    request: protos_report_pb.IdMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_report_pb.Success) => void): grpcWeb.ClientReadableStream<protos_report_pb.Success>;

  ignoreReportByLicense(
    request: protos_report_pb.IdMessage,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_report_pb.Success) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ReportService/IgnoreReportByLicense',
        request,
        metadata || {},
        this.methodInfoIgnoreReportByLicense,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ReportService/IgnoreReportByLicense',
    request,
    metadata || {},
    this.methodInfoIgnoreReportByLicense);
  }

  methodInfoUpdateReport = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_report_pb.Success,
    (request: protos_report_pb.UpdateReportMessage) => {
      return request.serializeBinary();
    },
    protos_report_pb.Success.deserializeBinary
  );

  updateReport(
    request: protos_report_pb.UpdateReportMessage,
    metadata: grpcWeb.Metadata | null): Promise<protos_report_pb.Success>;

  updateReport(
    request: protos_report_pb.UpdateReportMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_report_pb.Success) => void): grpcWeb.ClientReadableStream<protos_report_pb.Success>;

  updateReport(
    request: protos_report_pb.UpdateReportMessage,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_report_pb.Success) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ReportService/UpdateReport',
        request,
        metadata || {},
        this.methodInfoUpdateReport,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ReportService/UpdateReport',
    request,
    metadata || {},
    this.methodInfoUpdateReport);
  }

  methodInfoLikeUnlikeReport = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_report_pb.Success,
    (request: protos_report_pb.LikeUnlikeMessage) => {
      return request.serializeBinary();
    },
    protos_report_pb.Success.deserializeBinary
  );

  likeUnlikeReport(
    request: protos_report_pb.LikeUnlikeMessage,
    metadata: grpcWeb.Metadata | null): Promise<protos_report_pb.Success>;

  likeUnlikeReport(
    request: protos_report_pb.LikeUnlikeMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_report_pb.Success) => void): grpcWeb.ClientReadableStream<protos_report_pb.Success>;

  likeUnlikeReport(
    request: protos_report_pb.LikeUnlikeMessage,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_report_pb.Success) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ReportService/LikeUnlikeReport',
        request,
        metadata || {},
        this.methodInfoLikeUnlikeReport,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ReportService/LikeUnlikeReport',
    request,
    metadata || {},
    this.methodInfoLikeUnlikeReport);
  }

  methodInfoFetchReportStat = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_report_pb.ReportStatsResult,
    (request: protos_report_pb.FetchReportStats) => {
      return request.serializeBinary();
    },
    protos_report_pb.ReportStatsResult.deserializeBinary
  );

  fetchReportStat(
    request: protos_report_pb.FetchReportStats,
    metadata: grpcWeb.Metadata | null): Promise<protos_report_pb.ReportStatsResult>;

  fetchReportStat(
    request: protos_report_pb.FetchReportStats,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_report_pb.ReportStatsResult) => void): grpcWeb.ClientReadableStream<protos_report_pb.ReportStatsResult>;

  fetchReportStat(
    request: protos_report_pb.FetchReportStats,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_report_pb.ReportStatsResult) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ReportService/FetchReportStat',
        request,
        metadata || {},
        this.methodInfoFetchReportStat,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ReportService/FetchReportStat',
    request,
    metadata || {},
    this.methodInfoFetchReportStat);
  }

  methodInfoFetchReports = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_report_pb.FetchReportsResult,
    (request: protos_report_pb.FetchReportsMessage) => {
      return request.serializeBinary();
    },
    protos_report_pb.FetchReportsResult.deserializeBinary
  );

  fetchReports(
    request: protos_report_pb.FetchReportsMessage,
    metadata: grpcWeb.Metadata | null): Promise<protos_report_pb.FetchReportsResult>;

  fetchReports(
    request: protos_report_pb.FetchReportsMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_report_pb.FetchReportsResult) => void): grpcWeb.ClientReadableStream<protos_report_pb.FetchReportsResult>;

  fetchReports(
    request: protos_report_pb.FetchReportsMessage,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_report_pb.FetchReportsResult) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ReportService/FetchReports',
        request,
        metadata || {},
        this.methodInfoFetchReports,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ReportService/FetchReports',
    request,
    metadata || {},
    this.methodInfoFetchReports);
  }

  methodInfoSetAssignedWorkers = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_report_pb.Success,
    (request: protos_report_pb.ReportAssignedWorkers) => {
      return request.serializeBinary();
    },
    protos_report_pb.Success.deserializeBinary
  );

  setAssignedWorkers(
    request: protos_report_pb.ReportAssignedWorkers,
    metadata: grpcWeb.Metadata | null): Promise<protos_report_pb.Success>;

  setAssignedWorkers(
    request: protos_report_pb.ReportAssignedWorkers,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_report_pb.Success) => void): grpcWeb.ClientReadableStream<protos_report_pb.Success>;

  setAssignedWorkers(
    request: protos_report_pb.ReportAssignedWorkers,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_report_pb.Success) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ReportService/SetAssignedWorkers',
        request,
        metadata || {},
        this.methodInfoSetAssignedWorkers,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ReportService/SetAssignedWorkers',
    request,
    metadata || {},
    this.methodInfoSetAssignedWorkers);
  }

  methodInfoSetReportsVisibility = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_report_pb.Success,
    (request: protos_report_pb.SetReportsVisibilityMessage) => {
      return request.serializeBinary();
    },
    protos_report_pb.Success.deserializeBinary
  );

  setReportsVisibility(
    request: protos_report_pb.SetReportsVisibilityMessage,
    metadata: grpcWeb.Metadata | null): Promise<protos_report_pb.Success>;

  setReportsVisibility(
    request: protos_report_pb.SetReportsVisibilityMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_report_pb.Success) => void): grpcWeb.ClientReadableStream<protos_report_pb.Success>;

  setReportsVisibility(
    request: protos_report_pb.SetReportsVisibilityMessage,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_report_pb.Success) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ReportService/SetReportsVisibility',
        request,
        metadata || {},
        this.methodInfoSetReportsVisibility,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ReportService/SetReportsVisibility',
    request,
    metadata || {},
    this.methodInfoSetReportsVisibility);
  }

  methodInfoFetchReportsForCron = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_report_pb.FetchReportsResult,
    (request: protos_report_pb.FetchReportsMessageCron) => {
      return request.serializeBinary();
    },
    protos_report_pb.FetchReportsResult.deserializeBinary
  );

  fetchReportsForCron(
    request: protos_report_pb.FetchReportsMessageCron,
    metadata: grpcWeb.Metadata | null): Promise<protos_report_pb.FetchReportsResult>;

  fetchReportsForCron(
    request: protos_report_pb.FetchReportsMessageCron,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_report_pb.FetchReportsResult) => void): grpcWeb.ClientReadableStream<protos_report_pb.FetchReportsResult>;

  fetchReportsForCron(
    request: protos_report_pb.FetchReportsMessageCron,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_report_pb.FetchReportsResult) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ReportService/FetchReportsForCron',
        request,
        metadata || {},
        this.methodInfoFetchReportsForCron,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ReportService/FetchReportsForCron',
    request,
    metadata || {},
    this.methodInfoFetchReportsForCron);
  }

}

