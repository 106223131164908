import React, { useState } from "react";
import "./style.scss";
import RoutesWithoutAuth from "../../routes/routesWithoutAuth";
import LanguageSelector from "../../components/topbar-component/components/language-selector";

interface LoginProps {}

export const Login: React.FunctionComponent<LoginProps> = ({}: LoginProps) => {
  const [selectorKey, setSelectorKey] = useState("");
  const [typeOfLogin, setTypeOfLogin] = useState(1);

  const typeOfLoginChange = (e) => {
    setTypeOfLogin(e);
  };

  const resetSelector = () => {
    setSelectorKey(Math.random().toString(36).substring(7));
  };

  return (
    <div className="login-background">
      <div className="login">
        <LanguageSelector></LanguageSelector>
        <RoutesWithoutAuth></RoutesWithoutAuth>
        {/* {this.state.typeOfLogin === 1 ? (
            <LoginView typeOfLoginChange={this.typeOfLoginChange}></LoginView>
          ) : this.state.typeOfLogin === 2 ? (
            <ForgotView typeOfLoginChange={this.typeOfLoginChange}></ForgotView>
          ) : (
            <PasswordConfirmationView
              typeOfLoginChange={this.typeOfLoginChange}
            ></PasswordConfirmationView>
          )} */}
      </div>
    </div>
  );
};

export default Login;
