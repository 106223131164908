import { ChatServiceClient } from "../../server/chat/protos/ChatServiceClientPb";
import { BACKEND_URL } from "../RootReducer";
import { Metadata } from "grpc-web";
import {
  ChatMessage,
  MarkViewedMessage,
  MessagesResponse,
  MessageSuccess,
  PendingRoomMessage,
  PendingRoomResponse,
  TaskMessage,
  FetchMessageChat,
} from "../../server/chat/protos/chat_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { SET_CHAT, SET_UNSEEN_ROOMS } from "./chatTypes";
import { grpc } from "@improbable-eng/grpc-web";
import { ChatService } from "../../server/chat/protos/chat_pb_service";
import { message } from "antd";

export const chatClient = new ChatServiceClient(BACKEND_URL || "");
export const chatHeaders: Metadata = {
  // service_name: "LicenseService",
  "Access-Control-Allow-Origin": "*",
  // crossdomain: true,
  allow_headers: "*",
  // "origin": "http://localhost:3000",
};

export function grpcSendTaskMessage(
  members: string[],
  message: ChatMessage.AsObject,
  createdAt: Timestamp,
  fun?: any
) {
  return (dispatch: any) => {
    try {
      const req = new TaskMessage();
      req.setMembersList(members);

      const reqMessage = new ChatMessage();

      reqMessage.setCreatedat(createdAt);
      reqMessage.setFlag(message.flag);
      reqMessage.setId(message.id);
      reqMessage.setMessageBody(message.messageBody);
      reqMessage.setMessageFile("");
      reqMessage.setMessageType(message.messageType);
      reqMessage.setRoomId(message.roomId);
      reqMessage.setSenderImage(message.senderImage);
      reqMessage.setSenderName(message.senderName);
      reqMessage.setSentBy(message.sentBy);
      reqMessage.setViewedByList(message.viewedByList);

      req.setMessage(reqMessage);
      console.log("req", req.toObject());
      // const myTransport = grpc.CrossBrowserHttpTransport({
      //   withCredentials: true,
      // });
      grpc.invoke(ChatService.SendTaskMessage, {
        request: req,
        host: BACKEND_URL || "",
        transport: grpc.XhrTransport({
          withCredentials: false,
        }),
        onHeaders: (headers: grpc.Metadata) => {
          headers.append("Access-Control-Allow-Origin", "*");
          console.log(headers);
        },
        onMessage: (res: any) => {
          console.log("res", res);
          if (fun) {
            fun();
          }
        },
        onEnd: (code: grpc.Code, msg: string, trailers: any) => {
          console.log("Echo.onEnd", code, msg, trailers);
        },
      });
      // console.log("req", req.toObject());
      // chatClient.sendTaskMessage(
      //   req,
      //   chatHeaders,
      //   (err, response: MessageSuccess) => {
      //     if (response == null) {
      //       console.log("err", err);
      //     } else {
      //       console.log("data ", response);
      //       if (fun) {
      //         fun();
      //       }
      //     }
      //   }
      // );
      // console.log("req", send);
    } catch (error) {
      console.log("error", error);
    }
  };
}

export function grpcFetchChatMessages(roomId: string, fun?: any) {
  return (dispatch: any) => {
    const req = new FetchMessageChat();
    req.setRoomId(roomId);

    chatClient.fetchChatMessages(
      req,
      chatHeaders,
      (err, response: MessagesResponse) => {
        if (response == null) {
          console.log("err", err);
        } else {
          dispatch({
            type: SET_CHAT,
            payload: response.toObject().messagesList,
          });
          if (fun) {
            fun();
          }
        }
      }
    );
  };
}

export function grpcListenChatMessages(id: string, fun?: any) {
  return (dispatch: any) => {
    // const req = new ListenMessages();
    // req.setId(id);
    // console.log("id", id);
    // const client = grpc.client(ChatService.ListenChatMessages, {
    //   host: BACKEND_URL || "",
    //   transport: grpc.XhrTransport({
    //     withCredentials: false,
    //   }),
    // });
    // client.onHeaders((headers: grpc.Metadata) => {
    //   headers.append("Access-Control-Allow-Origin", "*");
    //   console.log(headers);
    // });
    // client.onMessage((message: any) => {
    //   console.log("message", message);
    // });
    // client.onEnd((code: grpc.Code, msg: string, trailers: any) => {
    //   console.log("Echo.onEnd", code, msg, trailers);
    // });
    // client.start();
    // client.send(req);
    // return chatClient.listenChatMessages(req, chatHeaders);
  };
}

export function grpcMarkAsViewed(
  data: {
    messageIds: string[];
    userId: string;
  },
  fun?: any
) {
  return (dispatch: any) => {
    console.log(data);
    const req = new MarkViewedMessage();
    req.setMessageIdsList(data.messageIds);
    req.setUserId(data.userId);
    console.log("data", req.toObject());
    chatClient.markAsViewed(
      req,
      chatHeaders,
      (err, response: MessageSuccess) => {
        if (response == null) {
          console.log("err", err);
        } else {
          console.log("response", response.toObject());
          if (fun) {
            fun();
          }
        }
      }
    );
  };
}

export function grpcFetchPendingRooms(userId: string, fun?: any) {
  return (dispatch: any) => {
    const req = new PendingRoomMessage();
    req.setUserId(userId);

    chatClient.fetchPendingRooms(
      req,
      chatHeaders,
      (err, response: PendingRoomResponse) => {
        if (response == null) {
          console.log("err", err);
        } else {
          dispatch({
            type: SET_UNSEEN_ROOMS,
            payload: response.toObject().roomsList,
          });
          if (fun) {
            fun();
          }
        }
      }
    );
  };
}
