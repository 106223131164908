import React, { useState } from "react";
import { Notification, ClockIcon } from "../../../../src/assets/svg/index";

export const NotificationBell: React.FunctionComponent = () => {
  const [visible, setVisible] = useState(false);

  return (
    <div
      className="notification-section"
      onClick={() => {
        setVisible(!visible);
      }}
    >
      <Notification></Notification>
      {visible && (
        <div className="notification-list">
          <div className="notification-element">
            <div className="notification-image">
              <img
                src="https://miciudad.s3-us-west-1.amazonaws.com/profile.png"
                alt=""
              />
            </div>
            <div className="notification-description">{"Hello World1"}</div>
            <div className="notification-date">
              <ClockIcon></ClockIcon>
              {"1h"}
            </div>
          </div>
          <div className="notification-element">
            <div className="notification-image">
              <img
                src="https://miciudad.s3-us-west-1.amazonaws.com/profile.png"
                alt=""
              />
            </div>
            <div className="notification-description">{"Hello World1"}</div>
            <div className="notification-date">
              <ClockIcon></ClockIcon>
              {"1h"}
            </div>
          </div>
        </div>
      )}
      {visible && (
        <div
          className="hidden-wall"
          onClick={() => {
            setVisible(!visible);
          }}
        ></div>
      )}
    </div>
  );
};

export default NotificationBell;
