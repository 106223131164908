import React, { useEffect } from "react";

import { Arrow, CloseIcon } from "../../../assets/svg";
import CardGroupReport from "./Card-group-report";
import CardReport from "./Card-report";
import { Report } from "../../../server/report/protos/report_pb";

interface FilterProps {
  refComments: any;
  markerReport?: Report.AsObject;
  specialReport: any;
  closeSearchReport: any;
  filterType: any;
  getNotifications: any;
  assignedReport: any;
  reports: Report.AsObject[];
  changeFilter: any;
  visible: any;
  teams: any;
  workers: any;
  markerReportFunction: any;
  notifications: any;
  currentDistrict: any;
  showDialogAlertNotification: any;
  location: any;
  groupReportsActive: any;
  reportsAdded: any;
  activateGroupReports: any;
  addOrRemoveReportToGroup: any;
  deactivateGroupReports: any;
  clearGroupArray: any;
  handleClick: any;
  users: any;
  setCenter: any;
  t: any;
  updateCoords: any;
  updateUser: any;
  assignerTeam: any;
  updateCurrentZoom: any;
  reportTypes: any;
}

export const Filter: React.FunctionComponent<FilterProps> = ({
  refComments,
  markerReport,
  specialReport,
  closeSearchReport,
  getNotifications,
  assignedReport,
  visible,
  teams,
  workers,
  markerReportFunction,
  notifications,
  currentDistrict,
  showDialogAlertNotification,
  groupReportsActive,
  reportsAdded,
  activateGroupReports,
  addOrRemoveReportToGroup,
  deactivateGroupReports,
  clearGroupArray,
  handleClick,
  users,
  setCenter,
  t,
  updateCoords,
  updateUser,
  assignerTeam,
  updateCurrentZoom,
  reportTypes,
}: FilterProps) => {
  return (
    <div
      className={`${visible ? "hide-animation" : "show-animation "
        } filter-container`}
    >
      <div className="filter-close" onClick={handleClick}>
        <Arrow></Arrow>
      </div>
      {markerReport && (
        <CardReport
          refComments={refComments}
          reportTypes={reportTypes}
          assignedReport={assignedReport}
          markerReport={markerReport}
          teams={teams}
          t={t}
          markerReportFunction={markerReportFunction}
          workers={workers}
          notifications={notifications}
          currentDistrict={currentDistrict}
          showDialogAlertNotification={showDialogAlertNotification}
          users={users}
          assignerTeam={assignerTeam}
          getNotifications={getNotifications}
          setCenter={setCenter}
          updateUser={updateUser}
          updateCoords={updateCoords}
          updateCurrentZoom={updateCurrentZoom}
          handleClick={handleClick}
        ></CardReport>
      )}
      {specialReport && (
        <CardReport
          refComments={refComments}
          reportTypes={reportTypes}
          assignedReport={specialReport}
          markerReport={specialReport}
          teams={teams}
          t={t}
          markerReportFunction={closeSearchReport}
          workers={workers}
          notifications={notifications}
          currentDistrict={currentDistrict}
          showDialogAlertNotification={showDialogAlertNotification}
          users={users}
          assignerTeam={assignerTeam}
          getNotifications={getNotifications}
          setCenter={setCenter}
          updateUser={updateUser}
          updateCoords={updateCoords}
          updateCurrentZoom={updateCurrentZoom}
          handleClick={handleClick}
        ></CardReport>
      )}
      {groupReportsActive && (
        <CardGroupReport
          reportTypes={reportTypes}
          groupReportsActive={groupReportsActive}
          teams={teams}
          t={t}
          reportsAdded={reportsAdded}
          activateGroupReports={activateGroupReports}
          addOrRemoveReportToGroup={addOrRemoveReportToGroup}
          deactivateGroupReports={deactivateGroupReports}
          workers={workers}
          notifications={notifications}
          assignerTeam={assignerTeam}
          currentDistrict={currentDistrict}
          clearGroupArray={clearGroupArray}
          showDialogAlertNotification={showDialogAlertNotification}
          handleClick={handleClick}
        ></CardGroupReport>
      )}
      {!markerReport && !specialReport && !groupReportsActive &&
        <div className="section-empty">
          <div className="close-button-empty-report">
            <div className="close-button" onClick={() => {
              handleClick();
            }}>
              <CloseIcon></CloseIcon>
            </div>
          </div>
          <div>
            <p>{t("empty-report")}</p>
          </div>
        </div>
      }
    </div>
  );
};

export default Filter;
