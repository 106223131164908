import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RootStore } from "../../redux/Store";
import "./style.scss";

import { LICENSES_SELECTED } from "../../redux/licenses/licensesTypes";

import { AddMember, Ignore } from "../../assets/svg/index";
import FeatherIcon from "feather-icons-react";
import { licenseI } from "../../redux/licenses/licensesReducer";

interface NavBarSuperProps {
  history: any;
  location: any;
  showProfileDialog: any;
  updatePage: any;
  changeGeneralSettings: any;
  changeMenuState: any;
  menuOpen: any;
  fetchAllLicenses: any;
}

export const NavBarSuper: React.FunctionComponent<NavBarSuperProps> = ({
  history,
  location,
  showProfileDialog,
  updatePage,
  changeGeneralSettings,
  changeMenuState,
  menuOpen,
  fetchAllLicenses,
}: any) => {
  const licenses = useSelector(
    (globalState: RootStore) => globalState.licenses.licenses
  );
  const selectedLicense: licenseI | undefined = useSelector(
    (globalState: RootStore) => globalState.licenses.selectedLicense
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();

  let defaultValue: any;

  const [state, setState] = useState({
    visible: true,
    visibleSetting: false,
    onSettings: false,
    menuOpen: true,
  });

  const show = () => {
    setState((prevState) => ({
      ...prevState,
      visible: !state.visible,
    }));
  };

  const showSetting = () => {
    setState((prevState) => ({
      ...prevState,
      visibleSetting: !state.visibleSetting,
    }));
  };

  return (
    <div
      className={
        menuOpen ? "bar-super-admin" : "bar-super-admin bar-super-hidded"
      }
    >
      <div className="filter-open" onClick={changeMenuState}>
        <FeatherIcon
          icon={menuOpen ? "chevron-left" : "chevron-right"}
          size="18"
          color="white"
        />
      </div>
      <div className="logo-item">
        <div className="main-logo" />
      </div>
      <div className="new-city">
        <div
          className={
            state.visible
              ? "arrow-box right-arrow-1 rotate"
              : "arrow-box right-arrow-1"
          }
          onClick={() => {
            show();
          }}
        >
          {" "}
          <FeatherIcon icon="play" size="10" />
        </div>
        <div className="text">{t("name.cities")}</div>
        <AddMember
          onClick={() => {
            showProfileDialog();
          }}
        ></AddMember>
      </div>
      {state.visible && (
        <div className="districts-list">
          {licenses.map((e: any) => {
            return (
              <div
                key={e._id}
                onClick={() => {
                  // if (location.pathname === "/reportTypes") {
                  //   changeGeneralSettings();
                  // }
                  //changeSelectedTeam(defaultValue);
                  //setDistrict(e, defaultValue);
                  //changeDistrict();
                  updatePage(0);

                  history.push("/");

                  if (selectedLicense?._id !== e._id) {
                    dispatch({ type: LICENSES_SELECTED, payload: e });
                  }
                }}
                className={
                  location.pathname !== "/reportTypes" &&
                  selectedLicense?._id === e._id
                    ? "active-menu nav-item"
                    : " nav-item "
                }
              >
                <div className="row-district">
                  <img
                    src={
                      e.imagePath
                        ? e.imagePath
                        : "https://miciudad.s3-us-west-1.amazonaws.com/profile-image.png"
                    }
                    alt=""
                  />
                  <p className="title-menu">{e.name}</p>
                </div>
                <div>{e.blocked ? <Ignore></Ignore> : <div></div>}</div>
              </div>
            );
          })}
        </div>
      )}
      <div
        className="general-settings-button"
        onClick={() => {
          showSetting();
        }}
      >
        <div
          className={
            state.visibleSetting
              ? "arrow-box right-arrow-1 rotate"
              : "arrow-box right-arrow-1"
          }
        >
          {" "}
          <FeatherIcon icon="play" size="10" />
        </div>
        <div className="text">{t("name.settings.1")}</div>
      </div>
      {state.visibleSetting && (
        <div className="districts-list">
          {["title.type.of.reports"].map((e: any, index: any) => {
            return (
              <div
                key={index}
                onClick={() => {
                  history.push("/reportTypes");
                }}
                className={
                  location.pathname === "/reportTypes"
                    ? "active-menu nav-item"
                    : " nav-item "
                }
              >
                <div className="row-district">
                  <p className="title-menu">{t(e)}</p>
                </div>
                <div>{e.blocked ? <Ignore></Ignore> : <div></div>}</div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default NavBarSuper;
