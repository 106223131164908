import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../redux/Store";
import { useTranslation } from "react-i18next";

import { grpcUpdateLicenseJobTypes } from "../../../redux/licenses/licensesActions";
import { ReportTypes } from "../../../redux/reportTypes/reportsReducer";
import { licenseI } from "../../../redux/licenses/licensesReducer";

import { Edit } from "../../../assets/svg/index";
import ActionButtons from "../../components/Action-buttons";
import TileReportTypes from "./tile-reportType";
import { SET_INFO_STATES } from "../../../redux/licenses/licensesTypes";

interface TileServicesProps {
  fetchAllLicenses: any;
}

export const TileServices: React.FunctionComponent<TileServicesProps> = ({
  fetchAllLicenses,
}: any) => {
  const selectedLicense: licenseI | undefined = useSelector(
    (globalState: RootStore) => globalState.licenses.selectedLicense
  );
  const reportTypes: ReportTypes[] = useSelector(
    (globalState: RootStore) => globalState.reportTypes.reportTypes
  );
  const mainMode: boolean = useSelector(
    (globalState: RootStore) => globalState.licenses.mainMode
  );
  const reportMode: boolean = useSelector(
    (globalState: RootStore) => globalState.licenses.reportMode
  );

  let defaultValueArray: string[] = [];

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    tagsToUpdate: defaultValueArray,
    tagsRNoEdit: defaultValueArray,
    suggestionsR: defaultValueArray,
    suggestionsRNoEdit: defaultValueArray,
    currentDistrict: selectedLicense,
  });

  useEffect(() => {
    setInitialValues();
  }, []);

  const setInitialValues = () => {
    let suggestionsArray: any = [];
    let tagsArray: any = [];

    if (selectedLicense && selectedLicense.licenseSettings.jobTypes)
      tagsArray.push(...selectedLicense.licenseSettings.jobTypes);

    setState((prevState) => ({
      ...prevState,
      tagsToUpdate: tagsArray,
      tagsRNoEdit: tagsArray,
      suggestionsR: suggestionsArray,
      suggestionsRNoEdit: suggestionsArray,
    }));
  };

  const changeToEdit = () => {
    dispatch({
      type: SET_INFO_STATES,
      mainMode: mainMode,
      reportMode: !reportMode,
    });
    setInitialValues();
  };

  const changeTagsR = (tagsToUpdate: any) => {
    setState((prevState) => ({
      ...prevState,
      tagsToUpdate: tagsToUpdate,
    }));
  };

  const UpdateProfile = () => {
    if (selectedLicense)
      dispatch(
        grpcUpdateLicenseJobTypes(
          {
            _id: selectedLicense.settingsId,
            jobTypes: state.tagsToUpdate,
          },
          () => {
            fetchAllLicenses();
            changeToEdit();
          }
        )
      );
  };

  const inArray = (father: ReportTypes) => {
    let inArr = false;

    if (
      state.tagsToUpdate.indexOf(father.reporttypeid) === -1 ||
      father.reportTypes?.filter(
        (s) => state.tagsToUpdate.indexOf(s.reporttypeid) !== -1
      ).length !== father.reportTypes?.length
    ) {
      return true;
    }
    return inArr;
  };

  return !reportMode ? (
    <div className="tile-card">
      <div className="tile-card-header">
        <div className="tile-title">{t("tabs.title.services")}</div>
        <div className="tile-edit-button" onClick={changeToEdit}>
          <Edit></Edit>
        </div>
      </div>
      <div className="tile-card-body view-mode">
        <div className="list-report-types">
          {reportTypes.map((d: ReportTypes) => {
            if (
              (selectedLicense?.licenseSettings.jobTypes || []).indexOf(
                d.reporttypeid
              ) !== -1
            )
              return (
                <TileReportTypes
                  key={d.reporttypeid}
                  ignoreList={state.tagsRNoEdit}
                  typeFlag={"SHOW"}
                  reportTypeGroup={d}
                ></TileReportTypes>
              );
          })}
        </div>
      </div>
    </div>
  ) : (
    <div className="tile-card tile-card-to-edit">
      <div className="tile-card-header">
        <div className="tile-title">{t("tabs.title.services")}</div>
        <div></div>
      </div>
      <div className="tile-card-body ">
        <div className="tags-box-edit">
          <div className="tile-subtitle">{t("name.selecteds")}</div>

          <div className="list-report-types">
            {reportTypes.map((d: ReportTypes) => {
              if (state.tagsToUpdate.indexOf(d.reporttypeid) !== -1)
                return (
                  <TileReportTypes
                    key={d.reporttypeid}
                    ignoreList={state.tagsToUpdate}
                    typeFlag={"REMOVE"}
                    reportTypeGroup={d}
                    changeTagsR={changeTagsR}
                  ></TileReportTypes>
                );
            })}
          </div>
        </div>
        <div className="tags-box-edit">
          <div className="tile-subtitle">{t("name.none.selecteds")}</div>

          <div className="list-report-types">
            {reportTypes.map((d: ReportTypes) => {
              if (inArray(d))
                return (
                  <TileReportTypes
                    key={d.reporttypeid}
                    ignoreList={state.tagsToUpdate}
                    typeFlag={"ADD"}
                    reportTypeGroup={d}
                    changeTagsR={changeTagsR}
                  ></TileReportTypes>
                );
            })}
          </div>
        </div>

        <div className="buttons-box-edit">
          <ActionButtons
            doneText={t("submit.save")}
            clickable={true}
            doneClick={() => {
              UpdateProfile();
            }}
            cancelClick={changeToEdit}
            cancelText={t("submit.cancel")}
          ></ActionButtons>
        </div>
      </div>
    </div>
  );
};

export default TileServices;
