import { Role } from "../../server/user/protos/auth_pb";
import { User } from "../../server/user/protos/user_pb";

export const LOADING_USER = "LOADING_USER";
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_UNAUTHENTICATED = "SET_UNAUTHENTICATED";
export const SET_USER = "SET_USER";
export const SET_SUPER_ADMIN = "SET_SUPER_ADMIN";

export type UserData = {};

export interface UserLoading {
  type: typeof LOADING_USER;
}


export interface SetUserAuthenticated {
  type: typeof SET_AUTHENTICATED;
  superAdmin: boolean;
}

export interface SetUserUnauthenticated {
  type: typeof SET_UNAUTHENTICATED;
}

export interface SetUser {
  type: typeof SET_USER;
  payload: User.AsObject;
}

export interface SetUserSuperAdmin {
  type: typeof SET_SUPER_ADMIN;
  payload: boolean;
}

export type UserDispatchTypes =
  | UserLoading
  | SetUserAuthenticated
  | SetUserUnauthenticated
  | SetUser
  | SetUserSuperAdmin;
