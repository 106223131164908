import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RootStore } from "../../../redux/Store";
import moment from "moment";
import "./style.scss";

import {
  byCategoryI,
  byStatusI,
  byCategoryCustomI,
  responseByCategoryI,
  responseByCategoryCustomI,
} from "../../../redux/dashboard/dashboardReducer";
import {
  DASHBOARD_CLEAR_DATE,
  DASHBOARD_UPDATE_DATE,
} from "../../../redux/dashboard/dashboardTypes";
import {
  CHART_TIME,
  CHART_TYPE_FIELD,
  getCalendarLocale,
} from "../../../utils/utils";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { licenseI } from "../../../redux/licenses/licensesReducer";

import CardChart4 from "./components/card-chart-4";
import CardChart3 from "./components/card-chart-3";
import { DateRangePicker } from "rsuite";
import { ValueType } from "rsuite/lib/DateRangePicker";
import {
  grpcReportsByCategoryCustom,
  grpcReportsByDate,
  grpcReportsByResponse,
} from "../../../redux/dashboard/dashboardActions";
import LoadingDialog from "../../dialogs/loading-dialog";
import ChartModal from "../../../utils/dialogs/chart-dialog";

export const DashboardMayor: React.FunctionComponent = () => {
  const selectedLicense: licenseI | undefined = useSelector(
    (globalState: RootStore) => globalState.licenses.selectedLicense
  );
  const reports: any = useSelector(
    (globalState: RootStore) => globalState.reportTypes.reports
  );
  const reportTypesGeneral: any = useSelector(
    (globalState: RootStore) => globalState.reportTypes.reportTypesGeneral
  );
  const reportsByStatus: byStatusI | undefined = useSelector(
    (globalState: RootStore) => globalState.dashboard.reportsByStatus
  );
  const reportsByCategory: byCategoryI = useSelector(
    (globalState: RootStore) => globalState.dashboard.reportsByCategory
  );
  const reportsByCategoryCustom: byCategoryCustomI = useSelector(
    (globalState: RootStore) => globalState.dashboard.reportsByCategoryCustom
  );
  const dateD: Array<Date> = useSelector(
    (globalState: RootStore) => globalState.dashboard.dateDate
  );
  const categoryD: Array<Date> = useSelector(
    (globalState: RootStore) => globalState.dashboard.categoryDate
  );
  const responseD: Array<Date> = useSelector(
    (globalState: RootStore) => globalState.dashboard.responseDate
  );
  const loadingByStatus: boolean = useSelector(
    (globalState: RootStore) => globalState.dashboard.loadingByStatus
  );
  const loadingByDate: boolean = useSelector(
    (globalState: RootStore) => globalState.dashboard.loadingByDate
  );
  const loadingByCategory: boolean = useSelector(
    (globalState: RootStore) => globalState.dashboard.loadingByCategory
  );
  const loadingByResponse: boolean = useSelector(
    (globalState: RootStore) => globalState.dashboard.loadingByResponse
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectedTime, setSelectedTime] = useState("");
  const [selectedReport, setSelectedReport] = useState("");

  const style = `
    .rs-picker-menu {
      left: unset !important;
      right: 0px !important;
    }
  `;

  const getByStatusBoxA = () => {
    return (
      <Fragment>
        <div className="reports-data-box">
          <div className="title-data-box">
            <span className="report-border"></span>
            <div className="content">{t("subtitle.total.reports.pending")}</div>
          </div>
          <div className="today-box">
            {reportsByStatus?.todayStats.pending || 0}
          </div>
          <div className="today-box">
            {reportsByStatus?.days7Stats.pending || 0}
          </div>
          <div className="today-box">
            {reportsByStatus?.month1Stats.pending || 0}
          </div>
        </div>
        <div className="pending-data-box">
          <div className="title-data-box">
            <span className="pending-border"></span>
            <div className="content">
              {t("subtitle.total.reports.inProgress")}
            </div>
          </div>
          <div className="today-box">
            {reportsByStatus?.todayStats.progress || 0}
          </div>
          <div className="today-box">
            {reportsByStatus?.days7Stats.progress || 0}
          </div>
          <div className="today-box">
            {reportsByStatus?.month1Stats.progress || 0}
          </div>
        </div>
        <div className="completed-data-box">
          <div className="title-data-box ">
            <span className="completed-border"></span>
            <div className="content">
              {t("subtitle.total.reports.completed")}
            </div>
          </div>
          <div className="today-box">
            {reportsByStatus?.todayStats.completed || 0}
          </div>
          <div className="today-box">
            {reportsByStatus?.days7Stats.completed || 0}
          </div>
          <div className="today-box">
            {reportsByStatus?.month1Stats.completed || 0}
          </div>
        </div>
        <div className="info-data-box">
          <div className="top-data-title"></div>
          <div className="top-data">
            <span className="top-data-tile">{t("subtitle.today")}</span>
          </div>
          <div className="top-data">
            <span className="top-data-tile">{t("subtitle.week")}</span>
          </div>
          <div className="top-data">
            <span className="top-data-tile">{t("subtitle.month")}</span>
          </div>
        </div>
      </Fragment>
    );
  };

  const getByStatusBoxB = () => {
    return (
      <Fragment>
        <div className="reports-data-box total-data-box bottom-space">
          <div className="title-data-box">
            <span className="total-border"></span>
            <div className="content">
              <div className="completed-text">{t("subtitle.completed")}</div>/
              <div className="reported-text">{t("subtitle.reported")}</div>
            </div>
          </div>
          <div className="today-box no-border-right letter-size">
            {t("subtitle.6.months")}
          </div>
          <div className="today-box">
            <div className="completed-number letter-b-size">
              {reportsByStatus?.month6Stats.completed || 0}
            </div>
            /
            <div className="reported-number">
              {reportsByStatus?.month6Stats.pending +
                reportsByStatus?.month6Stats.progress +
                reportsByStatus?.month6Stats.completed || 0}
            </div>
          </div>
          <div className="today-box no-border-right letter-size">
            {t("subtitle.1.year")}
          </div>
          <div className="today-box">
            <div className="completed-number letter-b-size">
              {reportsByStatus?.year1Stats.completed || 0}
            </div>
            /
            <div className="reported-number">
              {reportsByStatus?.year1Stats.pending +
                reportsByStatus?.year1Stats.progress + 
                reportsByStatus?.year1Stats.completed || 0}
            </div>
          </div>
        </div>
        <div className="pending-data-box total-data-box">
          <div className="title-data-box">
            <span className="problems-border"></span>
            <div className="content">{t("subtitle.problems.overtime")}</div>
          </div>
          <div className="today-box no-border-right letter-size">25%:</div>
          <div className="today-box">{getCountProblems(0.25) || 0}</div>
          <div className="today-box no-border-right letter-size">50%:</div>
          <div className="today-box">{getCountProblems(0.5) || 0}</div>
          <div className="today-box no-border-right letter-size">100%:</div>
          <div className="today-box">{getCountProblems(1) || 0}</div>
        </div>
      </Fragment>
    );
  };

  const getByCategoryBox = (report: responseByCategoryI) => {
    let todayTotal =
      report.todayStats.pending +
      report.todayStats.progress +
      report.todayStats.completed;
    let weekTotal =
      report.days7Stats.pending +
      report.days7Stats.progress +
      report.days7Stats.completed;
    let monthTotal =
      report.month1Stats.pending +
      report.month1Stats.progress +
      report.month1Stats.completed;

    let customTotal = 0;
    let customPending = 0;
    let customProgress = 0;
    let customComplete = 0;

    let reportName = "";

    reportsByCategoryCustom?.responseList.map((custom: responseByCategoryCustomI) => {
        if (custom.category === report.category) {
          customTotal = custom.customStats.pending + custom.customStats.progress + custom.customStats.completed;
          customPending = custom.customStats.pending;
          customProgress = custom.customStats.progress;
          customComplete = custom.customStats.completed;
        }
      }
    );

    reportTypesGeneral.map((rep: any) => {
      if (rep.reporttypeid === report.category) {
        reportName = rep[t("language")];
      }
    });

    return (
      <Fragment>
        <div key={"category-" + report.category} className="reports-data-box">
          <div className="title-data-box">
            <span className="response-border"></span>
            <div className="content">{reportName}</div>
          </div>
          <div
            key={"box-today" + report.category}
            className={
              CHART_TIME.TODAY === selectedTime &&
              report.category === selectedReport
                ? "today-box box-option active"
                : "today-box box-option"
            }
            onClick={() => {
              setSelectedReport(report.category);
              setSelectedTime(
                CHART_TIME.TODAY === selectedTime &&
                  report.category === selectedReport
                  ? ""
                  : CHART_TIME.TODAY
              );
            }}
          >
            {todayTotal || 0}
          </div>
          <div
            key={"box-week" + report.category}
            className={
              CHART_TIME.WEEK === selectedTime &&
              report.category === selectedReport
                ? "today-box box-option active"
                : "today-box box-option"
            }
            onClick={() => {
              setSelectedReport(report.category);
              setSelectedTime(
                CHART_TIME.WEEK === selectedTime &&
                  report.category === selectedReport
                  ? ""
                  : CHART_TIME.WEEK
              );
            }}
          >
            {weekTotal || 0}
          </div>
          <div
            key={"box-month" + report.category}
            className={
              CHART_TIME.MONTH === selectedTime &&
              report.category === selectedReport
                ? "today-box box-option active"
                : "today-box box-option"
            }
            onClick={() => {
              setSelectedReport(report.category);
              setSelectedTime(
                CHART_TIME.MONTH === selectedTime &&
                  report.category === selectedReport
                  ? ""
                  : CHART_TIME.MONTH
              );
            }}
          >
            {monthTotal || 0}
          </div>
          <div
            key={"box-custom" + report.category}
            className={
              CHART_TIME.CUSTOM === selectedTime &&
              report.category === selectedReport
                ? "today-box box-option active"
                : "today-box box-option"
            }
            onClick={() => {
              setSelectedReport(report.category);
              setSelectedTime(
                CHART_TIME.CUSTOM === selectedTime &&
                  report.category === selectedReport
                  ? ""
                  : CHART_TIME.CUSTOM
              );
            }}
          >
            {customTotal || 0}
          </div>
        </div>
        {selectedReport === report.category && selectedTime !== "" && (
          <div className="report-detail-data-box">
            <div className="today-box no-border-right letter-size">
              {t("subtitle.total.reports.pending")}
            </div>
            <div key={"box-pending" + report.category} className="today-box">
              {selectedTime === CHART_TIME.TODAY
                ? report.todayStats.pending
                : selectedTime === CHART_TIME.WEEK
                ? report.days7Stats.pending
                : selectedTime === CHART_TIME.MONTH
                ? report.month1Stats.pending
                : customPending}
            </div>
            <div className="today-box no-border-right letter-size">
              {t("subtitle.total.reports.inProgress")}
            </div>
            <div key={"box-progress" + report.category} className="today-box">
              {selectedTime === CHART_TIME.TODAY
                ? report.todayStats.progress
                : selectedTime === CHART_TIME.WEEK
                ? report.days7Stats.progress
                : selectedTime === CHART_TIME.MONTH
                ? report.month1Stats.progress
                : customProgress}
            </div>
            <div className="today-box no-border-right letter-size">
              {t("subtitle.total.reports.completed")}
            </div>
            <div key={"box-completed" + report.category} className="today-box">
              {selectedTime === CHART_TIME.TODAY
                ? report.todayStats.completed
                : selectedTime === CHART_TIME.WEEK
                ? report.days7Stats.completed
                : selectedTime === CHART_TIME.MONTH
                ? report.month1Stats.completed
                : customComplete}
            </div>
          </div>
        )}
      </Fragment>
    );
  };

  const getByDateBox = () => {
    return (
      <Fragment>
        <div className="main-chart-title">
          <div className="title-data">{t("subtitle.trouble.reports")}</div>
          <div className="date-pickers">
            {/* CHART BY DATE */}
            <div className="start-date-box">
              <DateRangePicker
                locale={getCalendarLocale(t)}
                appearance="default"
                placeholder="Default"
                style={{ width: 280 }}
                value={[dateD[0], dateD[1]]}
                disabledDate={DateRangePicker.afterToday()}
                onChange={(
                  date: ValueType,
                  event: React.SyntheticEvent<HTMLElement>
                ) => {
                  dispatch({
                    type: DASHBOARD_UPDATE_DATE,
                    payload: {
                      rangeDate: [date[0], date[1]],
                      field: CHART_TYPE_FIELD.DATE_DATE,
                    },
                  });
                }}
                onOk={(
                  date: ValueType,
                  event: React.SyntheticEvent<HTMLElement>
                ) => {
                  dispatch({
                    type: DASHBOARD_UPDATE_DATE,
                    payload: {
                      rangeDate: [date[0], date[1]],
                      field: CHART_TYPE_FIELD.DATE_DATE,
                    },
                  });
                }}
                onClean={(event: React.SyntheticEvent<HTMLElement>) => {
                  dispatch({
                    type: DASHBOARD_CLEAR_DATE,
                    payload: CHART_TYPE_FIELD.DATE_DATE,
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div className="chart-graph-container">
          <CardChart3></CardChart3>
        </div>
      </Fragment>
    );
  };

  const getByResponseBox = () => {
    return (
      <Fragment>
        <div className="main-chart-title">
          <div className="title-data">{t("subtitle.time.reports")}</div>
          <div className="date-pickers">
            {/* CHART BY DATE */}
            <div className="start-date-box">
              <DateRangePicker
                locale={getCalendarLocale(t)}
                appearance="default"
                placeholder="Default"
                style={{ width: 280 }}
                value={[responseD[0], responseD[1]]}
                disabledDate={DateRangePicker.afterToday()}
                onChange={(
                  date: ValueType,
                  event: React.SyntheticEvent<HTMLElement>
                ) => {
                  dispatch({
                    type: DASHBOARD_UPDATE_DATE,
                    payload: {
                      rangeDate: [date[0], date[1]],
                      field: CHART_TYPE_FIELD.RESPONSE_DATE,
                    },
                  });
                }}
                onOk={(
                  date: ValueType,
                  event: React.SyntheticEvent<HTMLElement>
                ) => {
                  dispatch({
                    type: DASHBOARD_UPDATE_DATE,
                    payload: {
                      rangeDate: [date[0], date[1]],
                      field: CHART_TYPE_FIELD.RESPONSE_DATE,
                    },
                  });
                }}
                onClean={(event: React.SyntheticEvent<HTMLElement>) => {
                  dispatch({
                    type: DASHBOARD_CLEAR_DATE,
                    payload: CHART_TYPE_FIELD.RESPONSE_DATE,
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div className="chart-graph-container">
          <CardChart4></CardChart4>
        </div>
      </Fragment>
    );
  };

  const getCountProblems = (value: any) => {
    return reportsByStatus?.reportDelaysList.filter((e: any) => {
      if (e.estimatedtime && e.estimatedtime > 0) {
        let date = e.workprogressdate;
        let por = e.estimatedtime * value;
        let total = e.estimatedtime + por;
        total = Math.floor(total).toFixed(0);
        let dateUpdated = moment(date).add(total, "seconds");

        let auxReport = reports.find((rp: any) => {
          return rp.reportid === e.reportid;
        });

        if (
          auxReport &&
          auxReport?.reportstatus === 2 &&
          auxReport?.workstatus === 2 &&
          moment(dateUpdated).isBefore(Date.now())
        ) {
          if (value === 0.25) {
            if (!getCountProblems2(0.5, e) && !getCountProblems2(1, e))
              return true;
            else return false;
          }
          if (value === 0.5) {
            if (!getCountProblems2(1, e)) return true;
            else return false;
          } else return true;
        } else return false;
      } else return false;
    }).length;
  };

  const getCountProblems2 = (value: any, e: any) => {
    if (e.estimatedtime && e.estimatedtime > 0) {
      let date = e.workprogressdate;
      let por = e.estimatedtime * value;
      let total = e.estimatedtime + por;
      total = Math.floor(total).toFixed(0);
      let dateUpdated = moment(date).add(total, "seconds");

      let auxReport = reports.find((rp: any) => {
        return rp.reportid === e.reportid;
      });

      if (
        auxReport &&
        auxReport?.reportstatus === 2 &&
        auxReport?.workstatus === 2 &&
        moment(dateUpdated).isBefore(Date.now())
      ) {
        return true;
      } else return false;
    } else return false;
  };

  useEffect(() => {
    //ByDate Chart
    let fromD = new Timestamp();
    fromD.fromDate(moment(dateD[0]).startOf("day").toDate());
    let toD = new Timestamp();
    toD.fromDate(moment(dateD[1]).endOf("day").toDate());

    dispatch(
      grpcReportsByDate({
        _id: selectedLicense?._id,
        from: fromD,
        to: toD,
        loading: false,
      })
    );
  }, [dateD]);

  useEffect(() => {
    //ByCategory Chart
    let fromC = new Timestamp();
    fromC.fromDate(moment(categoryD[0]).startOf("day").toDate());
    let toC = new Timestamp();
    toC.fromDate(moment(categoryD[1]).endOf("day").toDate());

    dispatch(
      grpcReportsByCategoryCustom({
        _id: selectedLicense?._id,
        from: fromC,
        to: toC,
      })
    );
  }, [categoryD]);

  useEffect(() => {
    //ByResponse Chart
    let fromR = new Timestamp();
    fromR.fromDate(moment(responseD[0]).startOf("day").toDate());
    let toR = new Timestamp();
    toR.fromDate(moment(responseD[1]).endOf("day").toDate());

    dispatch(
      grpcReportsByResponse({
        _id: selectedLicense?._id,
        from: fromR,
        to: toR,
        loading: false,
      })
    );
  }, [responseD]);

  return (
    <div className="dashboard-page">
      <div className="chart-column">
        <div className="chart-element-table">
          <div className="card-element bottom-space">{getByStatusBoxA()}</div>
          <div className="card-element">{getByStatusBoxB()}</div>
          {loadingByStatus && (
            <ChartModal>
              <LoadingDialog></LoadingDialog>
            </ChartModal>
          )}
        </div>
        <div className="chart-element">
          <div className="card-element">
            <div className="title-data title-bottom-space">
              {t("subtitle.category.reports")}
            </div>
            {reportsByCategory?.responseList.map(
              (report: responseByCategoryI, index: number) => {
                if (
                  reportTypesGeneral.filter(
                    (f: any) => report.category === f.reporttypeid
                  ).length > 0
                )
                return <div  key={index}>{getByCategoryBox(report)}</div>;
              }
            )}
            <div className="info-data-box info-top-higher">
              <div className="top-data-title"></div>
              <div className="top-data">
                <span className="top-data-tile">{t("subtitle.today")}</span>
              </div>
              <div className="top-data">
                <span className="top-data-tile">{t("subtitle.week")}</span>
              </div>
              <div className="top-data">
                <span className="top-data-tile">{t("subtitle.month")}</span>
              </div>
              <div className="top-data top-cursor">
                <span className="top-data-tile">{t("Custom")}</span>
              </div>
            </div>
          </div>
          {loadingByCategory && (
            <ChartModal>
              <LoadingDialog></LoadingDialog>
            </ChartModal>
          )}
        </div>
      </div>
      <div className="chart-column-graph">
        <div className="chart-element">
          <div className="card-element">{getByDateBox()}</div>
          {loadingByDate && (
            <ChartModal>
              <LoadingDialog></LoadingDialog>
            </ChartModal>
          )}
        </div>
        <div className="chart-element">
          <div className="card-element">{getByResponseBox()}</div>
          {loadingByResponse && (
            <ChartModal>
              <LoadingDialog></LoadingDialog>
            </ChartModal>
          )}
        </div>
      </div>
      <style>{style}</style>
    </div>
  );
};

export default DashboardMayor;
