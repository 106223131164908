import {
  ChatMessage,
  PendingRoomResponse,
} from "../../server/chat/protos/chat_pb";

export const LOADING_CHAT = "LOADING_CHAT";
export const SET_CHAT = "SET_CHAT";
export const SET_ROOM_ID = "SET_ROOM_ID";
export const SET_UNSEEN_ROOMS = "SET_UNSEEN_ROOMS";
export const NEW_CHAT_MESSAGE = "NEW_CHAT_MESSAGE";

export interface ChatLoading {
  type: typeof LOADING_CHAT;
}

export interface SetChat {
  type: typeof SET_CHAT;
  payload: ChatMessage.AsObject[];
}

export interface SetRoomId {
  type: typeof SET_ROOM_ID;
  payload: string;
}

export interface SetUnSeenRooms {
  type: typeof SET_UNSEEN_ROOMS;
  payload: PendingRoomResponse.UnseenRoom.AsObject[];
}

export interface SetChatMessage {
  type: typeof NEW_CHAT_MESSAGE;
  payload: ChatMessage.AsObject;
}

export type ChatDispatchTypes =
  | ChatLoading
  | SetChat
  | SetRoomId
  | SetUnSeenRooms
  | SetChatMessage;
