import { Report } from "../../server/report/protos/report_pb";
import { ReportType } from "../../server/report/protos/reportType_pb";

export const REPORTS_LOADING = "REPORTS_LOADING";
export const REPORTS_ERROR = "REPORTS_ERROR";
export const REPORTS_LIST = "REPORTS_LIST";
export const REPORTS_SELECTED = "REPORTS_SELECTED";
export const REPORTS_TAB = "REPORTS_TAB";
export const REPORTS_UPDATES = "REPORTS_UPDATES";

export const REPORT_TYPES_LIST = "REPORT_TYPES_LIST";

export const REPORTS_FILTER_LIST = "REPORTS_FILTER_LIST";

export const REPORTS_VIEW_FILTERS = "REPORTS_VIEW_FILTERS";
export const REPORTS_VIEW_SELECTED = "REPORTS_VIEW_SELECTED";
export const REPORTS_VIEW_SHOW_TIME = "REPORTS_VIEW_SHOW_TIME";
export const REPORTS_VIEW_ZOOM = "REPORTS_VIEW_ZOOM";
export const REPORTS_VIEW_COORDS = "REPORTS_VIEW_COORDS";

export const SET_RANGE_DATE = "SET_RANGE_DATE";
export const CLEAR_RANGE_DATE = "CLEAR_RANGE_DATE";

//================================================================

export interface ClearRangeDate {
  type: typeof CLEAR_RANGE_DATE;
}

export interface SetRangeDate {
  type: typeof SET_RANGE_DATE;
  from: Date;
  to: Date;
}

export interface ReportsUpdates {
  type: typeof REPORTS_UPDATES;
  payload: Report.AsObject;
}
export interface ReportsFiltersSet {
  type: typeof REPORTS_FILTER_LIST;
  payload: string[];
}

export interface ReportsLoading {
  type: typeof REPORTS_LOADING;
}

export interface ReportsError {
  type: typeof REPORTS_ERROR;
}

export interface ReportTypesList {
  type: typeof REPORT_TYPES_LIST;
  payload: ReportType.AsObject[];
}

export interface ReportsList {
  type: typeof REPORTS_LIST;
  payload: Report.AsObject[];
}

export interface ReportsSelected {
  type: typeof REPORTS_SELECTED;
  payload: object;
}

export interface ReportsTab {
  type: typeof REPORTS_TAB;
  tabIndex: number;
}

export interface ReportsViewFilters {
  type: typeof REPORTS_VIEW_FILTERS;
  filters: object;
}

export interface ReportsViewSelected {
  type: typeof REPORTS_VIEW_SELECTED;
  payload: object;
}

export interface ReportsViewShowTime {
  type: typeof REPORTS_VIEW_SHOW_TIME;
  show: boolean;
}

export interface ReportsViewZoom {
  type: typeof REPORTS_VIEW_ZOOM;
  zoom: object;
}

export interface ReportsViewCoords {
  type: typeof REPORTS_VIEW_COORDS;
  coords: object;
}

export type ReportsDispatchTypes =
  | SetRangeDate
  | ClearRangeDate
  | ReportsUpdates
  | ReportsFiltersSet
  | ReportsLoading
  | ReportsError
  | ReportsList
  | ReportsSelected
  | ReportsTab
  | ReportsViewFilters
  | ReportsViewSelected
  | ReportsViewShowTime
  | ReportsViewZoom
  | ReportsViewCoords
  | ReportTypesList;
