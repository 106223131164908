import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RootStore } from "../../redux/Store";
import "./style.scss";

import { REPORTS_TAB } from "../../redux/reportTypes/reportsTypes";

import Footer from "../../components/footer/index";
import ListReportTypes from "./components/list-report-types";

interface SettingsPage {
  fetchAllReportTypes: any;
}

export const SettingsPage: React.FunctionComponent<SettingsPage> = ({
  fetchAllReportTypes,
}: any) => {
  const tabReportTypes: any = useSelector(
    (globalState: RootStore) => globalState.reportTypes.tabIndex
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className="settings-page">
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <li className="nav-item">
          <a
            className={tabReportTypes === 0 ? "nav-link active" : "nav-link"}
            id="reportType-tab2"
            data-toggle="tab"
            href="#reportType2"
            role="tab"
            aria-controls="reportType2"
            aria-selected={tabReportTypes === 0 ? "true" : "false"}
            onClick={() => {
              dispatch({ type: REPORTS_TAB, tabIndex: 0 });
            }}
          >
            {/* {this.props.t("tabs.info")} */}
            {t("placeholder.services")}
          </a>
        </li>
      </ul>
      <div className="tab-content-settings" id="myTabContent">
        {/* Tab INFO */}
        <div
          className={
            tabReportTypes === 0 ? "tab-pane fade show active" : "tab-pane fade"
          }
          id="reportType2"
          role="tabpanel"
          key={Math.random().toString(36).substring(7)}
          aria-labelledby="reportType-tab2"
        >
          <ListReportTypes
            fetchAllReportTypes={fetchAllReportTypes}
          ></ListReportTypes>
        </div>
      </div>
      <Footer t={t}></Footer>
    </div>
  );
};

export default SettingsPage;
