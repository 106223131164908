/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as protos_team_pb from '../protos/team_pb';


export class TeamServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoTeamRequest = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_team_pb.SuccessMessage,
    (request: protos_team_pb.Team) => {
      return request.serializeBinary();
    },
    protos_team_pb.SuccessMessage.deserializeBinary
  );

  teamRequest(
    request: protos_team_pb.Team,
    metadata: grpcWeb.Metadata | null): Promise<protos_team_pb.SuccessMessage>;

  teamRequest(
    request: protos_team_pb.Team,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_team_pb.SuccessMessage) => void): grpcWeb.ClientReadableStream<protos_team_pb.SuccessMessage>;

  teamRequest(
    request: protos_team_pb.Team,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_team_pb.SuccessMessage) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/TeamService/TeamRequest',
        request,
        metadata || {},
        this.methodInfoTeamRequest,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/TeamService/TeamRequest',
    request,
    metadata || {},
    this.methodInfoTeamRequest);
  }

  methodInfoDeleteTeam = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_team_pb.SuccessMessage,
    (request: protos_team_pb.DeleteRequest) => {
      return request.serializeBinary();
    },
    protos_team_pb.SuccessMessage.deserializeBinary
  );

  deleteTeam(
    request: protos_team_pb.DeleteRequest,
    metadata: grpcWeb.Metadata | null): Promise<protos_team_pb.SuccessMessage>;

  deleteTeam(
    request: protos_team_pb.DeleteRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_team_pb.SuccessMessage) => void): grpcWeb.ClientReadableStream<protos_team_pb.SuccessMessage>;

  deleteTeam(
    request: protos_team_pb.DeleteRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_team_pb.SuccessMessage) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/TeamService/DeleteTeam',
        request,
        metadata || {},
        this.methodInfoDeleteTeam,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/TeamService/DeleteTeam',
    request,
    metadata || {},
    this.methodInfoDeleteTeam);
  }

  methodInfoFetchTeam = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_team_pb.FetchResponseTeam,
    (request: protos_team_pb.FetchRequestTeam) => {
      return request.serializeBinary();
    },
    protos_team_pb.FetchResponseTeam.deserializeBinary
  );

  fetchTeam(
    request: protos_team_pb.FetchRequestTeam,
    metadata: grpcWeb.Metadata | null): Promise<protos_team_pb.FetchResponseTeam>;

  fetchTeam(
    request: protos_team_pb.FetchRequestTeam,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_team_pb.FetchResponseTeam) => void): grpcWeb.ClientReadableStream<protos_team_pb.FetchResponseTeam>;

  fetchTeam(
    request: protos_team_pb.FetchRequestTeam,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_team_pb.FetchResponseTeam) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/TeamService/FetchTeam',
        request,
        metadata || {},
        this.methodInfoFetchTeam,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/TeamService/FetchTeam',
    request,
    metadata || {},
    this.methodInfoFetchTeam);
  }

  methodInfoSubscribeTeam = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_team_pb.Team,
    (request: protos_team_pb.Subscribe) => {
      return request.serializeBinary();
    },
    protos_team_pb.Team.deserializeBinary
  );

  subscribeTeam(
    request: protos_team_pb.Subscribe,
    metadata?: grpcWeb.Metadata) {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/TeamService/SubscribeTeam',
      request,
      metadata || {},
      this.methodInfoSubscribeTeam);
  }

  methodInfoAssignToTeam = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_team_pb.SuccessMessage,
    (request: protos_team_pb.AssignToTeamRequest) => {
      return request.serializeBinary();
    },
    protos_team_pb.SuccessMessage.deserializeBinary
  );

  assignToTeam(
    request: protos_team_pb.AssignToTeamRequest,
    metadata: grpcWeb.Metadata | null): Promise<protos_team_pb.SuccessMessage>;

  assignToTeam(
    request: protos_team_pb.AssignToTeamRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_team_pb.SuccessMessage) => void): grpcWeb.ClientReadableStream<protos_team_pb.SuccessMessage>;

  assignToTeam(
    request: protos_team_pb.AssignToTeamRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_team_pb.SuccessMessage) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/TeamService/AssignToTeam',
        request,
        metadata || {},
        this.methodInfoAssignToTeam,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/TeamService/AssignToTeam',
    request,
    metadata || {},
    this.methodInfoAssignToTeam);
  }

  methodInfoFindByTask = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_team_pb.FindByTaskResponse,
    (request: protos_team_pb.FindByTaskRequest) => {
      return request.serializeBinary();
    },
    protos_team_pb.FindByTaskResponse.deserializeBinary
  );

  findByTask(
    request: protos_team_pb.FindByTaskRequest,
    metadata: grpcWeb.Metadata | null): Promise<protos_team_pb.FindByTaskResponse>;

  findByTask(
    request: protos_team_pb.FindByTaskRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_team_pb.FindByTaskResponse) => void): grpcWeb.ClientReadableStream<protos_team_pb.FindByTaskResponse>;

  findByTask(
    request: protos_team_pb.FindByTaskRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_team_pb.FindByTaskResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/TeamService/FindByTask',
        request,
        metadata || {},
        this.methodInfoFindByTask,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/TeamService/FindByTask',
    request,
    metadata || {},
    this.methodInfoFindByTask);
  }

  methodInfoFindByLicenseId = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_team_pb.FindByLicenseIdResponse,
    (request: protos_team_pb.FindByLicenseIdRequest) => {
      return request.serializeBinary();
    },
    protos_team_pb.FindByLicenseIdResponse.deserializeBinary
  );

  findByLicenseId(
    request: protos_team_pb.FindByLicenseIdRequest,
    metadata: grpcWeb.Metadata | null): Promise<protos_team_pb.FindByLicenseIdResponse>;

  findByLicenseId(
    request: protos_team_pb.FindByLicenseIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_team_pb.FindByLicenseIdResponse) => void): grpcWeb.ClientReadableStream<protos_team_pb.FindByLicenseIdResponse>;

  findByLicenseId(
    request: protos_team_pb.FindByLicenseIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_team_pb.FindByLicenseIdResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/TeamService/FindByLicenseId',
        request,
        metadata || {},
        this.methodInfoFindByLicenseId,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/TeamService/FindByLicenseId',
    request,
    metadata || {},
    this.methodInfoFindByLicenseId);
  }

}

