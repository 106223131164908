export interface NotificationRequest {
    _id: string;
    report_type: ReportType;
}

export enum ReportType {
    UNKNOWN = 'UNKNOWN',
    BY_USER = 'BY_USER',
    BY_REPORTID = 'BY_REPORTID',
    BY_CITYID = 'BY_CITYID',
    BY_NOTIFICATION_ID = 'BY_NOTIFICATION_ID',
}

enum NotificationType {
    CUSTOM = "CUSTOM",
    RECEIVED = "RECEIVED",
    ASSIGNED = "ASSIGNED",
    IN_PROGRESS = "IN_PROGRESS",
    DONE = "DONE"
}

export interface NotificationResponse {
    image: string;
    body: string;
    reportId: string;
    reportTypeId: string;
    status: number;
    createdAt: string;
    notificationType: NotificationType;
    reportImage: string;
    cityId: string;
    reportTitle: string;
    id: string;
    ignored: boolean;
}