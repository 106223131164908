import React, { useState } from "react";
import moment from "moment";

// import Basura from "../assets/basura.jpg";
// import { MenuIcon } from "../../../assets/svg/index";
import { ChatMessage } from "../../../server/chat/protos/chat_pb";
import ReactAudioPlayer from "react-audio-player";

interface CommentaryPrivateProps {
  notification: ChatMessage.AsObject;
  t: any;
  type: any;
  markerReport: any;
  editCommentary: any;
  deleteCommentaryConfirmation: any;
  currentDistrict: any;
  setImage: any;
}

export const CommentaryPrivate: React.FunctionComponent<CommentaryPrivateProps> =
  ({
    notification,
    t,
    type,
    markerReport,
    editCommentary,
    deleteCommentaryConfirmation,
    currentDistrict,
    setImage,
  }: CommentaryPrivateProps) => {
    //const exampleRef = createRef<HTMLDivElement>();
    const [state, setState] = useState({
      showDialogComment: false,
    });

    const GetTime = (e: Date) => {
      // var MyDate = Date(moment(e).toDate().getTime() * 1000);
      let time = (moment(e).toDate().getTime() - Date.now()) / 1000;
      // var year = Math.abs(Math.round(time / (60 * 60 * 24) / 365.25));
      let month = Math.abs(Math.round(time / (60 * 60 * 24 * 7 * 4)));
      let days = Math.abs(Math.round(time / (3600 * 24)));
      let minutes = Math.abs(Math.round(time / 60));
      if (month !== 0) {
        if (month >= 1)
          return t("time.start") + month + t("time.months") + t("time.end");
        else return t("time.start") + month + t("time.month") + t("time.end");
      } else if (days !== 0) {
        if (days >= 1)
          return t("time.start") + days + t("time.days") + t("time.end");
        else return t("time.start") + days + t("time.day") + t("time.end");
      } else if (minutes !== 0) {
        let hour = Math.abs(minutes / 60);
        if (hour >= 1) {
          if (hour >= 1)
            return (
              t("time.start") +
              Math.round(hour) +
              t("time.hours") +
              t("time.end")
            );
          else
            return (
              t("time.start") +
              Math.round(hour) +
              t("time.hour") +
              t("time.end")
            );
        } else {
          if (minutes >= 1)
            return (
              t("time.start") + minutes + t("time.minutes") + t("time.end")
            );
          else
            return t("time.start") + minutes + t("time.minute") + t("time.end");
        }
      } else {
        return t("time.now");
      }
    };

    const showDialog = () => {
      setState((prevState) => ({
        ...prevState,
        showDialogComment: !state.showDialogComment,
      }));
    };

    const getAudio = () => {
      return <ReactAudioPlayer src={notification.messageFile} controls />;
    };

    const getImage = () => {
      return (
        <img
          src={notification.messageFile}
          className="img-body"
          alt=""
          onClick={() => {
            setImage(notification.messageFile);
          }}
        />
      );
    };

    const getBody = () => {
      return notification.messageBody;
    };

    const getMessage = () => {
      if (notification.messageType === ChatMessage.MessageType.TEXT) {
        return getBody();
      } else if (notification.messageType === ChatMessage.MessageType.IMAGE) {
        return getImage();
      } else if (notification.messageType === ChatMessage.MessageType.AUDIO) {
        return getAudio();
      } else {
        return "";
      }
    };

    const getProblem = () => {
      // var commentaryError = "";
      // if (this.props.markerReport.workersLog !== undefined) {
      //   this.props.markerReport.workersLog.forEach((e) => {
      //     if (e.problem !== undefined && e.problem === true)
      //       commentaryError = e.reason;
      //   });
      // }
      if (notification.flag === 1) return true;
      else return false;
    };

    return (
      <div
        className={
          getProblem()
            ? "commentary commentary-private comment-problem"
            : "commentary commentary-private"
        }
      >
        <div
          className="main-comment-img "
          onClick={type === 1 ? showDialog : () => {}}
        >
          <div className="comment-info">
            <img
              src={
                notification.senderImage
                  ? notification.senderImage
                  : "https://miciudad.s3-us-west-1.amazonaws.com/profile.png"
              }
              alt=""
            />
          </div>
        </div>
        <div className="comment-body">
          <div className="title-comment-box comment-header-private">
            <div className="comment-name">{notification.senderName}</div>
            <div className="comment-date">
              { GetTime(moment(moment.unix(notification.createdat?.seconds || 0).toLocaleString()).toDate())}
            </div>
          </div>
          <div className="body-box">{getMessage()}</div>
        </div>
        {state.showDialogComment && (
          <div className="hidden-wall" onClick={showDialog}></div>
        )}
        {state.showDialogComment && (
          <div className="options-box">
            <div className="clear-item" onClick={showDialog}></div>
            <div
              className="menu-item"
              onClick={() => {
                showDialog();
                deleteCommentaryConfirmation(notification);
              }}
            >
              {t("name.commentary.delete")}
            </div>
            <div
              className="menu-item"
              onClick={() => {
                showDialog();
                editCommentary(notification);
              }}
            >
              {t("name.commentary.edit")}
            </div>
          </div>
        )}
      </div>
    );
  };

export default CommentaryPrivate;
