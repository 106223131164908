import { Team } from "../../server/team/protos/team_pb";
import {
  TEAMS_LOADING,
  TEAMS_ERROR,
  TEAMS_LIST,
  TEAMS_SELECTED,
  TEAMS_SELECTED_ROUTES,
  TeamsDispatchTypes,
  TEAM_ASSIGN,
  TEAMS_UPDATES,
} from "./teamsTypes";

let defaultValue: any;
let defaultValueArray: any = [];

interface teamsState {
  loading: boolean;
  teams: Team.AsObject[];
  selectedTeam: Team.AsObject;
  selectedTeamRoutes: Team.AsObject;
  teamAssign?: Team.AsObject;
}

const defaultState: teamsState = {
  loading: false,
  teams: defaultValueArray,
  selectedTeam: defaultValue,
  selectedTeamRoutes: defaultValue,
};

const teamsReducer = (
  state: teamsState = defaultState,
  action: TeamsDispatchTypes
): teamsState => {
  switch (action.type) {
    case TEAMS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case TEAMS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case TEAMS_LIST:
      const teamList: Team.AsObject[] = action.payload;
      const actualTeam: Team.AsObject = state.selectedTeam;
      const actualTeamRoutes: Team.AsObject = state.selectedTeamRoutes;

      let defaultTeam: any;
      let defaultTeamRoutes: any;

      if (teamList.length > 0) {
        if (actualTeam) {
          defaultTeam = teamList.find((team: Team.AsObject) => {
            return team.id === actualTeam.id;
          });
        }
      }

      if (teamList.length > 0) {
        if (actualTeamRoutes) {
          defaultTeamRoutes = teamList.find((team: Team.AsObject) => {
            return team.id === actualTeamRoutes.id;
          });
          if (!defaultTeamRoutes) {
            defaultTeamRoutes = teamList[0];
          }
        } else {
          defaultTeamRoutes = teamList[0];
        }
      }
      return {
        ...state,
        teams: teamList,
        selectedTeam: defaultTeam,
        selectedTeamRoutes: defaultTeamRoutes,
      };
    case TEAMS_SELECTED:
      const teamSelected: any = action.payload;
      return {
        ...state,
        selectedTeam: teamSelected,
      };
    case TEAMS_SELECTED_ROUTES:
      const teamSelectedRoutes: any = action.payload;
      return {
        ...state,
        selectedTeamRoutes: teamSelectedRoutes,
      };
    case TEAM_ASSIGN:
      return {
        ...state,
        teamAssign:
          state.teamAssign && state.teamAssign?.id === action.payload.id
            ? undefined
            : action.payload,
      };
    case TEAMS_UPDATES:
      const array: Team.AsObject[] = [];
      let inArray = false;

      state.teams.forEach((d: Team.AsObject) => {
        if (d.id === action.payload.id) {
          // if (!action.payload.ignored) {
          array.push(action.payload);
          // }
          inArray = true;
        } else {
          array.push(d);
        }
      });
      if (!inArray) {
        array.push(action.payload);
      }

      if (state.selectedTeam) {
        if (state.selectedTeam.id === action.payload.id) {
          return {
            ...state,
            teams: array,
            selectedTeam: action.payload,
          };
        } else {
          return {
            ...state,
            teams: array,
          };
        }
      } else {
        return {
          ...state,
          teams: array,
        };
      }

    default:
      return state;
  }
};

export default teamsReducer;
