import { NotificationApp } from "../../server/notification/protos/notification_pb";

export const NOTIFICATIONS_LOADING = "NOTIFICATIONS_LOADING";
export const NOTIFICATIONS_ERROR = "NOTIFICATIONS_ERROR";
export const NOTIFICATIONS_LIST = "NOTIFICATIONS_LIST";
export const NOTIFICATIONS_SELECTED = "NOTIFICATIONS_SELECTED";

export const NOTIFICATIONS_UPDATES = "NOTIFICATIONS_UPDATES";

//================================================================

export interface NotificationsLoading {
  type: typeof NOTIFICATIONS_LOADING;
}

export interface NotificationsError {
  type: typeof NOTIFICATIONS_ERROR;
}

export interface NotificationsList {
  type: typeof NOTIFICATIONS_LIST;
  payload: NotificationApp.AsObject[];
}

export interface NotificationsSelected {
  type: typeof NOTIFICATIONS_SELECTED;
  payload: NotificationApp.AsObject;
}

export interface NotificationsUpdates {
  type: typeof NOTIFICATIONS_UPDATES;
  payload: NotificationApp.AsObject;
}

export type NotificationsDispatchTypes =
  | NotificationsUpdates
  | NotificationsLoading
  | NotificationsError
  | NotificationsList
  | NotificationsSelected;
