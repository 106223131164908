import {
  Feedback,
  FeedbackRateResponse,
  FetchFeedbackRequest,
} from "../../server/license/protos/license_pb";
import { licenseI } from "./licensesReducer";
import { License } from "../../server/license/protos/license_pb";

export const LICENSES_LOADING = "LICENSES_LOADING";
export const LICENSES_ERROR = "LICENSES_ERROR";
export const LICENSE_UPDATES = "LICENSE_UPDATES";
export const LICENSES_LIST = "LICENSES_LIST";
export const LICENSES_SELECTED = "LICENSES_SELECTED";
export const LICENSES_TAB = "LICENSES_TAB";
export const SET_FEEDBACK_DATA = "SET_FEEDBACK_DATA";
export const SET_FEEDBACK_LIST = "SET_FEEDBACK_LIST";
export const SET_FEEDBACK_RATE = "SET_FEEDBACK_RATE";

export const SET_INFO_STATES = "SET_INFO_STATES";

//================================================================

export interface LicenseUpdates {
  type: typeof LICENSE_UPDATES;
  payload: License.AsObject;
}
export interface SetFeedbackData {
  type: typeof SET_FEEDBACK_DATA;
  limit: number;
  offset: number;
  requestType: FetchFeedbackRequest.RequestFType;
}

export interface SetFeedbackList {
  type: typeof SET_FEEDBACK_LIST;
  payload: Feedback.AsObject[];
}

export interface SetFeedbackRate {
  type: typeof SET_FEEDBACK_RATE;
  payload: FeedbackRateResponse.AsObject;
}

export interface LicensesLoading {
  type: typeof LICENSES_LOADING;
}

export interface LicensesError {
  type: typeof LICENSES_ERROR;
}

export interface LicensesList {
  type: typeof LICENSES_LIST;
  payload: licenseI[];
}

export interface LicensesSelected {
  type: typeof LICENSES_SELECTED;
  payload: licenseI;
}

export interface LicensesTab {
  type: typeof LICENSES_TAB;
  tabIndex: number;
}

export interface SetInfoStates {
  type: typeof SET_INFO_STATES;
  mainMode: boolean;
  reportMode: boolean;
}

export type LicensesDispatchTypes =
  | LicenseUpdates
  | SetFeedbackData
  | SetFeedbackList
  | SetFeedbackRate
  | LicensesLoading
  | LicensesError
  | LicensesList
  | LicensesSelected
  | LicensesTab
  | SetInfoStates;
