import React, { useState, useEffect, Fragment } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import { useSelector } from "react-redux";
import {
  // pending,
  inProgress,
  done,
  REPORT_PROGRESS_ASSIGNED,
  REPORT_PROGRESS_PROGRESS,
  GoogleAPIKEY,
} from "../../../const/const";

import SelectComponentGeneric from "../../components/select-component-generic";
import Footer from "../../../components/footer/index";
import GoogleMapReact from "google-map-react";
import ReportIcon from "./report-icon";
import Calendar from "react-calendar";
import * as Icon from "react-feather";
import ListDrag from "./List-Drag";
import {
  Report,
  ReportStatus,
  ReportWorkStatus,
} from "../../../server/report/protos/report_pb";
import { ReportTypes } from "../../../redux/reportTypes/reportsReducer";
import { Team } from "../../../server/team/protos/team_pb";
import { Marker } from "react-leaflet";
import L from "leaflet";
import { renderToString } from "react-dom/server";
import { FeatureGroup, Map, Polyline } from "react-leaflet";
import LayerControlComponent from "../../../components/general-component/layerControl";
import { licenseI } from "../../../redux/licenses/licensesReducer";
import { RootStore } from "../../../redux/Store";

interface KanBanBoxProps {
  openTab: any;
  updateCoords: any;
  center: any;
  zoom: any;
  defaultCenter: any;
  defaultZoom: any;
  change: any;
  selectedTeam: Team.AsObject;
  remarkedReport: any;
  selectedZone: any;
  users: any;
  selectZone: any;
  t: any;
  reportTypes: ReportTypes[];
  reports: Report.AsObject[];
}

export const KanBanBox: React.FunctionComponent<KanBanBoxProps> = ({
  openTab,
  updateCoords,
  center,
  zoom,
  defaultCenter,
  defaultZoom,
  change,
  selectedTeam,
  remarkedReport,
  selectedZone,
  users,
  selectZone,
  t,
  reportTypes,
  reports,
}: KanBanBoxProps) => {
  const selectedLicense: licenseI | undefined = useSelector(
    (globalState: RootStore) => globalState.licenses.selectedLicense
  );

  let defaultValue: Report.AsObject[] = [];

  const [state, setState] = useState<{
    startDate: Date;
    visible: boolean;
    progress: Report.AsObject[];
    progressR: Report.AsObject[];
    completed: Report.AsObject[];
    allReports: Report.AsObject[];
    CoordsReport: {
      lat: number;
      lng: number;
    };
    currentZoomReport: number;
  }>({
    startDate: new Date(),
    visible: false,
    progress: defaultValue,
    progressR: defaultValue,
    completed: defaultValue,
    allReports: defaultValue,
    CoordsReport: {
      lat: 32.805197,
      lng: -117.010139,
    },
    currentZoomReport: 13,
  });

  const calcCrow = (lat1: any, lon1: any, lat2: any, lon2: any) => {
    let R = 6371; // km
    let dLat = toRad(lat2 - lat1);
    let dLon = toRad(lon2 - lon1);
    lat1 = toRad(lat1);
    lat2 = toRad(lat2);

    let a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = R * c;
    return d * 10000;
  };

  const toRad = (value: any) => {
    return (value * Math.PI) / 180;
  };

  /* const KanBanBox = (props) => {
  const [startDate, setDate] = useState(new Date());
  const [visible, showCalendar] = useState(false); */

  // const handleChange = (date) => {
  //   setDate(date);
  // };

  // const nextDay = () => {
  //   var date = startDate;
  //   date.setDate(date.getDate() + 1);
  //   setDate(date);
  // };

  // const lastDay = () => {
  //   var date = startDate;
  //   date.setDate(date.getDate() - 1);
  //   setDate(date);
  // };

  useEffect(() => {
    //Filter for Reportados List
    let progressAux: any = [];
    let progressRAux: any = [];
    let completedAux: any = [];

    let inProgress1 = reports.filter(
      (e: Report.AsObject) =>
        selectedTeam &&
        ReportStatus.REPORT_IN_PROGRESS === e.reportstatus &&
        e.workstatus === ReportWorkStatus.WORK_ASSIGNED &&
        selectedTeam.tasksList.indexOf(e.reportid) !== -1
    );

    let inProgressR1 = reports.filter(
      (e: Report.AsObject) =>
        e.reportstatus === ReportStatus.REPORT_IN_PROGRESS &&
        e.workstatus === ReportWorkStatus.WORK_IN_PROGRESS &&
        selectedTeam &&
        selectedTeam.tasksList.indexOf(e.reportid) !== -1
    );
    let completed1 = reports.filter(
      (e: Report.AsObject) =>
        e.reportstatus === ReportStatus.REPORT_COMPLETED &&
        selectedTeam &&
        selectedTeam.tasksList.indexOf(e.reportid) !== -1
    );

    if (selectedTeam)
      selectedTeam.tasksList.forEach((e: any) => {
        let report1R = inProgress1.find(
          (r: Report.AsObject) => r.reportid === e
        );
        let report2R = inProgressR1.find(
          (r: Report.AsObject) => r.reportid === e
        );
        let report3R = completed1.find(
          (r: Report.AsObject) => r.reportid === e
        );
        if (report1R) {
          progressAux.push(report1R);
        }
        if (report2R) {
          progressRAux.push(report2R);
        }
        if (report3R) {
          completedAux.push(report3R);
        }
      });

    let allReports = [...progressAux, ...progressRAux];

    setState((prevState) => ({
      ...prevState,
      progress: progressAux,
      progressR: progressRAux,
      completed: completedAux,
      allReports: allReports,
    }));

    if (allReports.length > 0) {
      let lngs: any = [];
      let lats: any = [];
      allReports.forEach((element: Report.AsObject) => {
        lngs.push(element.geopoint?.longitude);
        lats.push(element.geopoint?.latitude);
      });

      let topMost = Math.max(...lngs);
      let leftMost = Math.min(...lats);
      let rightMost = Math.max(...lats);
      let bottomMost = Math.min(...lngs);

      let lat = (leftMost + rightMost) / 2;
      let lng = (topMost + bottomMost) / 2;
      let km = calcCrow(rightMost, topMost, leftMost, bottomMost);
      let zoom = 16;

      if (km <= 10027.977761) {
        zoom = 17;
      } else if (km <= 18055.95552) {
        zoom = 16;
      } else if (km <= 36111.91104) {
        zoom = 15;
      } else if (km <= 72223.82209) {
        zoom = 14;
      } else if (km <= 144447.6442) {
        zoom = 13;
      } else if (km <= 288895.2884) {
        zoom = 12;
      } else if (km <= 577790.5767) {
        zoom = 11;
      } else if (km <= 1155581.153) {
        zoom = 10;
      } else if (km <= 2311162.307) {
        zoom = 9;
      } else if (km <= 4622324.614) {
        zoom = 8;
      } else if (km <= 9244649.227) {
        zoom = 7;
      } else if (km <= 18489298.45) {
        zoom = 6;
      } else if (km <= 36978596.91) {
        zoom = 5;
      } else if (km <= 73957193.82) {
        zoom = 4;
      } else if (km <= 147914387.6) {
        zoom = 3;
      } else if (km <= 295828775.3) {
        zoom = 2;
      } else if (km <= 591657550.5) {
        zoom = 1;
      }

      let coords = {
        lat: lat,
        lng: lng,
      };

      setState((prevState) => ({
        ...prevState,
        CoordsReport: coords,
        currentZoomReport: zoom,
      }));
    }
  }, []);

  const leafletMapRef = React.useRef<Map>(null);
  const ref = React.createRef<FeatureGroup>();

  return (
    <Fragment>
      <div className="drag-list-container">
        <div className="container-kanban">
          <div className="reported-list-kanban">
            <span className="identifier"></span>
            <div className="right-content-kanban">
              <span>
                {t("status.assigned").toUpperCase() +
                  " (" +
                  state.progress?.length +
                  ")"}
              </span>
              <ListDrag
                type={1}
                openTab={openTab}
                t={t}
                key={Math.random().toString(36).substring(7)}
                selectedTeam={selectedTeam}
                users={users}
                inProgress={state.progress}
                inProgressR={state.progressR}
                completed={state.completed}
                remarkedReport={remarkedReport}
                reportTypes={reportTypes}
                isDraggableDisabled={false}
              ></ListDrag>
            </div>
          </div>
        </div>
        <div className="container-kanban">
          <div className="progress-list-kanban">
            <span className="identifier"></span>
            <div className="right-content-kanban">
              <span>
                {t("status.process").toUpperCase() +
                  " (" +
                  state.progressR.length +
                  ")"}
              </span>
              <ListDrag
                type={2}
                t={t}
                openTab={openTab}
                key={Math.random().toString(36).substring(7)}
                selectedTeam={selectedTeam}
                users={users}
                inProgress={state.progress}
                inProgressR={state.progressR}
                completed={state.completed}
                remarkedReport={remarkedReport}
                reportTypes={reportTypes}
                isDraggableDisabled={true}
              ></ListDrag>
            </div>
          </div>
        </div>
      </div>
      <div className="map-box">
        {/* </GoogleMapReact> */}
        <Map
          style={{ height: "100%", width: "100%" }}
          ref={leafletMapRef}
          center={
            selectedLicense?.licenseSettings?.coords
              ? {
                  lng: selectedLicense.licenseSettings.coords.lat,
                  lat: selectedLicense.licenseSettings.coords.lng,
                }
              : {
                  lng: -117.010139,
                  lat: 32.805197,
                }
          }
          zoom={
            selectedLicense?.licenseSettings?.zoom
              ? selectedLicense.licenseSettings.zoom
              : 16
          }
          zoomControl={false}
          // bounds={bounds || undefined}
          // boundsOptions={{ padding: [0, 0] }}
        >
          <LayerControlComponent></LayerControlComponent>
          <FeatureGroup ref={ref}>
            <>
              {/* {Zone routes} */}
              {state.allReports.map((item: Report.AsObject, i: any) => {
                return (
                  <Marker
                    key={item.reportid}
                    position={[
                      item.geopoint?.latitude || 0,
                      item.geopoint?.longitude || 0,
                    ]}
                    icon={L.divIcon({
                      html: renderToString(
                        <div>
                          <ReportIcon
                            key={"report-" + i}
                            item={item}
                            index={i}
                            lat={item.geopoint?.latitude}
                            lng={item.geopoint?.longitude}
                            zoom={zoom}
                          ></ReportIcon>
                        </div>
                      ),
                      iconSize: new L.Point(30, 30),
                      className:
                        "leaflet-div-icon-unSelected marker-level-" + i,
                    })}
                    onclick={() => {
                      // end(item);
                    }}
                  ></Marker>
                );
              })}
            </>
          </FeatureGroup>
        </Map>
        {/* </GoogleMapReact> */}
      </div>
    </Fragment>
  );
};

export default KanBanBox;
