import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../redux/Store";
import ImageUploader from "react-images-upload";
import { useTranslation } from "react-i18next";
import resizeImage from "resize-image";

import { grpcCreateUpdateTeam } from "../../../redux/teams/teamsActions";

import TeamTileReportTypes from "./teamTileReportType";
import TextFieldComponent from "../../components/text-field-component";
import ActionButtons from "../../components/Action-buttons";
import HeaderDialog from "../../components/Header-dialog";
import TeamImg from "../../../assets/img/team_image.png";
import { ReportTypes } from "../../../redux/reportTypes/reportsReducer";
import { Team } from "../../../server/team/protos/team_pb";
import { licenseI } from "../../../redux/licenses/licensesReducer";
import { onlyLettersNumbersSpaces } from "../../../utils/utils";

interface ManageTeamModalProps {
  onCloseRequest: any;
}

export const ManageTeamModal: React.FunctionComponent<ManageTeamModalProps> = ({
  onCloseRequest,
}: any) => {
  const selectedLicense: licenseI | undefined = useSelector(
    (globalState: RootStore) => globalState.licenses.selectedLicense
  );
  const selectedTeam: Team.AsObject = useSelector(
    (globalState: RootStore) => globalState.teams.selectedTeam
  );
  const reportTypes: ReportTypes[] = useSelector(
    (globalState: RootStore) => globalState.reportTypes.reportTypes
  );
  const reportTypesGeneral: ReportTypes[] = useSelector(
    (globalState: RootStore) => globalState.reportTypes.reportTypesGeneral
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();

  let defaultValue: any;
  let defaultValueArray: any = [];

  const [state, setState] = useState({
    name: selectedTeam ? selectedTeam.name : "",
    tempImg: selectedTeam ? selectedTeam.imagepath : defaultValue,
    tempUrl: defaultValue,

    tags: defaultValueArray,
    suggestions: defaultValueArray,

    tagsToUpdate: defaultValueArray,
    tagsRNoEdit: defaultValueArray,
    suggestionsR: defaultValueArray,
    suggestionsRNoEdit: defaultValueArray,
  });

  const addTeam = () => {
    let jobsRemoveArray: any = [];

    reportTypesGeneral.forEach((e: any) => {
      if (state.tagsToUpdate.indexOf(e.reporttypeid) != -1) {
        jobsRemoveArray.push(e.reporttypeid);
      }
    });

    if (!selectedTeam) {
      dispatch(
        grpcCreateUpdateTeam(
          {
            id: "",
            name: state.name.trim(),
            boss: defaultValueArray,
            member: defaultValueArray,
            type: 0,
            licenseId: selectedLicense?._id,
            imagePath: state.tempUrl ? state.tempUrl : "",
            task: defaultValueArray,
            job: state.tagsToUpdate,
          },
          () => {
            onCloseRequest();
          }
        )
      );
    } else {
      dispatch(
        grpcCreateUpdateTeam(
          {
            id: selectedTeam.id,
            name: state.name.trim(),
            licenseId: selectedTeam.licenseid,
            imagePath: state.tempUrl ? state.tempUrl : selectedTeam.imagepath,
            job: state.tagsToUpdate,
          },
          () => {
            onCloseRequest();
          }
        )
      );
    }
  };

  const handleForm = (name: any, event: any) => {
    setState((prevState) => ({
      ...prevState,
      [name]: event,
    }));
  };

  const uploadImageProfile = (value: any) => {
    validateExtension(value[value.length - 1])
      ? convertImageToBase64(value[value.length - 1])
      : console.log("error");
  };

  const validateExtension = (document: any) => {
    return /\.(gif|jpg|jpeg|tiff|png)$/i.test(
      document !== undefined &&
        document.name !== undefined &&
        document.name !== null
        ? document.name
        : ""
    );
  };

  const convertImageToBase64 = (documentFile: any) => {
    const reader = new FileReader();
    reader.onload = (upload: any) => {
      let dataUrl = upload.target.result;
      let image = new Image();
      image.src = dataUrl;
      image.onload = () => {
        const ImgBase64 = resizeImage.resize(image, 300, 300, resizeImage.JPEG);
        // this.uploadToStorageAndGetURL(documentFile, ImgBase64);
        setState((prevState) => ({
          ...prevState,
          tempImg: ImgBase64 ? ImgBase64 : documentFile,
          tempUrl: ImgBase64 ? ImgBase64 : documentFile,
          ImgBase64: ImgBase64 ? true : false,
        }));
      };
    };
    reader.readAsDataURL(documentFile);
  };

  const inArray = (father: ReportTypes) => {
    let inArr = false;

    if (
      state.tagsToUpdate.indexOf(father.reporttypeid) === -1 ||
      father.reportTypes?.filter(
        (s) => state.tagsToUpdate.indexOf(s.reporttypeid) !== -1
      ).length !== father.reportTypes?.length
    ) {
      return true;
    }
    return inArr;
  };

  const changeTagsR = (tagsToUpdate: any) => {
    setState((prevState) => ({
      ...prevState,
      tagsToUpdate: tagsToUpdate,
    }));
  };

  useEffect(() => {
    let suggestionsArray: any = [];
    let tagsArray: any = [];

    if (selectedTeam && selectedTeam.jobsList)
      tagsArray.push(...selectedTeam.jobsList);

    setState((prevState) => ({
      ...prevState,
      tagsToUpdate: tagsArray,
      tagsRNoEdit: tagsArray,
      suggestionsR: suggestionsArray,
      suggestionsRNoEdit: suggestionsArray,
    }));
  }, []);

  return (
    <div className="content-dialog-xl dialog-perfil">
      <HeaderDialog
        onCloseRequest={() => {
          onCloseRequest();
        }}
        title={!selectedTeam ? t("subtitle.new.team") : t("subtitle.edit.team")}
        description={""}
        profile={true}
        noBold1={""}
        noBold2={""}
        boldText={""}
        children={
          <div className="foto-perfil-box">
            <div className="title-perfil">{t("name.photo")}</div>
            <div className="foto-perfil-team">
              <img src={!state.tempImg ? TeamImg : state.tempImg} alt=""></img>
              <ImageUploader
                className="file-upload"
                buttonText="Choose images"
                withLabel={false}
                withPreview={false}
                withIcon={false}
                onChange={uploadImageProfile}
                imgExtension={[".gif", ".png", ".gif"]}
                accept={".png"}
                maxFileSize={5242880}
              />
              <div className="text">{t("form.photo")}</div>{" "}
            </div>
          </div>
        }
      ></HeaderDialog>
      <div className="content-options">
        <div className="content-options-body">
          <div className="options-body-item bottom-margin">
            <div className="selector-box full-width-select">
              <TextFieldComponent
                placeholder={state.name ? state.name : t("form.name.team")}
                onChange={(event: any) => {
                  handleForm("name", event);
                }}
                value={state.name}
                regExp={onlyLettersNumbersSpaces}
                maxValue={20}
                invalid={false}
                message=""
              ></TextFieldComponent>
            </div>
          </div>
          <div className="options-body-item-tag search-tag">
            <div className="selector-box full-width-select">
              <div className="tags-box-edit">
                <div className="tile-subtitle">{t("name.selecteds")}</div>

                <div className="list-report-types">
                  {reportTypes.map((d: ReportTypes) => {
                    if (state.tagsToUpdate.indexOf(d.reporttypeid) !== -1)
                      return (
                        <TeamTileReportTypes
                          key={d.reporttypeid + "-a"}
                          ignoreList={state.tagsToUpdate}
                          typeFlag={"REMOVE"}
                          reportTypeGroup={d}
                          changeTagsR={changeTagsR}
                        ></TeamTileReportTypes>
                      );
                  })}
                </div>

                {/* <TagsComponent
                  suggestions={state.suggestions}
                  tags={state.tags}
                  t={t}
                  edit={true}
                  changeTags={changeTags}
                  changeSuggestions={changeSuggestions}
                ></TagsComponent> */}
              </div>
            </div>
          </div>
          <div className="options-body-item-tag search-tag">
            <div className="selector-box full-width-select">
              <div className="tags-box-edit">
                <div className="tile-subtitle">{t("name.none.selecteds")}</div>

                <div className="list-report-types">
                  {reportTypes.map((d: ReportTypes) => {
                    if (
                      (
                        selectedLicense?.licenseSettings?.jobTypes || []
                      ).indexOf(d.reporttypeid) !== -1
                    ) {
                      if (inArray(d))
                        return (
                          <TeamTileReportTypes
                            key={d.reporttypeid + "-b"}
                            ignoreList={state.tagsToUpdate}
                            typeFlag={"ADD"}
                            reportTypeGroup={d}
                            changeTagsR={changeTagsR}
                          ></TeamTileReportTypes>
                        );
                    }
                  })}
                </div>

                {/* <TagsComponent
                  suggestions={state.tags}
                  tags={state.suggestions}
                  t={t}
                  edit={true}
                  changeTags={changeSuggestions}
                  changeSuggestions={changeTags}
                ></TagsComponent> */}
              </div>
            </div>
          </div>
          <ActionButtons
            doneText={t("submit.continue")}
            clickable={state.name ? true : false}
            doneClick={addTeam}
            cancelClick={() => {
              onCloseRequest();
            }}
            cancelText={t("submit.cancel")}
          ></ActionButtons>
        </div>
      </div>
    </div>
  );
};

export default ManageTeamModal;
