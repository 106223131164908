import { combineReducers } from "redux";
// import pokemonReducer from "./PokemonReducer";
import dashboardReducer from "./dashboard/dashboardReducer";
import teamsReducer from "./teams/teamsReducer";
import usersReducer from "./users/usersReducer";
import trackingReducer from "./tracking/trackingReducer";
import licensesReducer from "./licenses/licensesReducer";
import reportsReducer from "./reportTypes/reportsReducer";
import notificationsReducer from "./notifications/notificationsReducer";
import { userReducer } from "./user/userReducer";
import { chatReducer } from "./chat/chatReducer";

// export const BACKEND_URL = "http://52.223.53.211:8585";
export const BACKEND_URL = "https://mc.api.dev.giooby.com";
// export const BACKEND_URL = "http://localhost:8585";

export const WEB_URL = "https://mc.dev.giooby.com";

const appReducer = combineReducers({
  dashboard: dashboardReducer,
  teams: teamsReducer,
  users: usersReducer,
  tracking: trackingReducer,
  licenses: licensesReducer,
  reportTypes: reportsReducer,
  notifications: notificationsReducer,
  auth: userReducer,
  chat: chatReducer,
});

const RootReducer = (state: any, action: any) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === "USER_LOGGED_OUT") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default RootReducer;
