import React from "react";
import moment from "moment";

import {
  pending,
  inProgress,
  limpieza,
  riego,
  poda,
  pintura,
  parques,
  alumbrado,
  basura,
  desazolve,
  escombros,
  REPORT_PROGRESS_ASSIGNED,
  REPORT_PROGRESS_PROGRESS,
} from "../../../const/const";

import {
  // podaNeutral,
  podaPending,
  podaInProgress,
  podaAssigned,
  podaCompleted,
  // riegoNeutral,
  riegoPending,
  riegoInProgress,
  riegoAssigned,
  riegoCompleted,
  // AlumbradoNeutral,
  AlumbradoPending,
  AlumbradoInProgress,
  AlumbradoAssigned,
  AlumbradoCompleted,
  // BasuraNeutral,
  BasuraPending,
  BasuraInProgress,
  BasuraAssigned,
  BasuraCompleted,
  // desazolveNeutral,
  desazolvePending,
  desazolveInProgress,
  desazolveAssigned,
  desazolveCompleted,
  // escombroNeutral,
  escombroPending,
  escombroInProgress,
  escombroAssigned,
  escombroCompleted,
  // limpiezaNeutral,
  limpiezaPending,
  limpiezaInProgress,
  limpiezaAssigned,
  limpiezaCompleted,
  // parquesNeutral,
  parquesPending,
  parquesInProgress,
  parquesAssigned,
  parquesCompleted,
  // pinturaNeutral,
  pinturaPending,
  pinturaInProgress,
  pinturaAssigned,
  pinturaCompleted,
  /* pinturaSelected,
  riegoSelected,
  podaSelected,
  parquesSelected,
  limpiezaSelected,
  escombroSelected,
  desazolveSelected,
  BasuraSelected,
  AlumbradoSelected, */
} from "../../../assets/img/index";
import { ClockIcon, Ignore } from "../../../assets/svg/index";
import { PlaceIcon } from "../../../assets/svg/index";
import {
  Report,
  ReportStatus,
  ReportWorkStatus,
} from "../../../server/report/protos/report_pb";
import { ReportTypes } from "../../../redux/reportTypes/reportsReducer";

interface TileCalendarProps {
  users: any;
  t: any;
  index: any;
  item: Report.AsObject;
  provided: any;
  snapshot: any;
  openTab: any;
  type: any;
  remarkedReport: any;
  reportTypes: ReportTypes[];
}

export const TileCalendar: React.FunctionComponent<TileCalendarProps> = ({
  users,
  t,
  index,
  item,
  provided,
  snapshot,
  openTab,
  type,
  remarkedReport,
  reportTypes,
}: TileCalendarProps) => {
  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    // styles we need to apply on draggables
    ...draggableStyle,
    // console.log();
  });

  const getType = () => {
    let reportType = reportTypes.find((e: ReportTypes) => {
      return e.reporttypeid === item.reporttypeid;
    });
    if (reportType) return reportType[t("language")];
    else return "";
  };

  const getMarker = () => {
    let reportType = reportTypes.find((e: ReportTypes) => {
      return e.reporttypeid === item.reporttypeid;
    });
    if (reportType) return reportType.iconmap;

    /* if (added) {
          return limpiezaSelected;
        } else { */
    // if (item.reportstatus === ReportStatus.REPORT_PENDING)
    //   return limpiezaPending;
    // else if (
    //   item.reportstatus === ReportStatus.REPORT_IN_PROGRESS &&
    //   item.workstatus === ReportWorkStatus.WORK_ASSIGNED
    // )
    //   return limpiezaAssigned;
    // else if (
    //   item.reportstatus === ReportStatus.REPORT_IN_PROGRESS &&
    //   item.workstatus === ReportWorkStatus.WORK_IN_PROGRESS
    // )
    //   return limpiezaInProgress;
    // else return limpiezaCompleted;
    /* } */
  };

  const GetTime = (e: any) => {
    let time = (moment(e).toDate().getTime() - Date.now()) / 1000;
    // var year = Math.abs(Math.round(time / (60 * 60 * 24) / 365.25));
    let month = Math.abs(Math.round(time / (60 * 60 * 24 * 7 * 4)));
    let days = Math.abs(Math.round(time / (3600 * 24)));
    let minutes = Math.abs(Math.round(time / 60));
    if (month !== 0) {
      if (month >= 1)
        return t("time.start") + month + t("time.months") + t("time.end");
      else return t("time.start") + month + t("time.month") + t("time.end");
    } else if (days !== 0) {
      if (days >= 1)
        return t("time.start") + days + t("time.days") + t("time.end");
      else return t("time.start") + days + t("time.day") + t("time.end");
    } else if (minutes !== 0) {
      let hour = Math.abs(minutes / 60);
      if (hour >= 1) {
        if (hour >= 1)
          return (
            t("time.start") + Math.round(hour) + t("time.hours") + t("time.end")
          );
        else
          return (
            t("time.start") + Math.round(hour) + t("time.hour") + t("time.end")
          );
      } else {
        if (minutes >= 1)
          return t("time.start") + minutes + t("time.minutes") + t("time.end");
        else
          return t("time.start") + minutes + t("time.minute") + t("time.end");
      }
    } else {
      return t("time.now");
    }
  };

  const getSearchClass = () => {
    if (type === 1) return "item-droppable  item-droppable-searched-0";
    if (type === 2) return "item-droppable  item-droppable-searched-1";
    else return "item-droppable  item-droppable-searched-2";
  };

  const getClass = () => {
    let valid = false;
    // if (item.workersLog !== undefined) {
    //   item.workersLog.forEach((e) => {
    //     if (e.problem !== undefined && e.problem === true) valid = true;
    //   });
    // }
    if (item.reportstatus === ReportStatus.REPORT_PENDING)
      return "reported-type ";
    else if (
      item.reportstatus === ReportStatus.REPORT_IN_PROGRESS &&
      item.workstatus === ReportWorkStatus.WORK_ASSIGNED
    )
      return "assigned-type ";
    else if (
      item.reportstatus === ReportStatus.REPORT_IN_PROGRESS &&
      item.workstatus === ReportWorkStatus.WORK_IN_PROGRESS
    ) {
      if (item.estimatedtime && item.estimatedtime > 0) {
        let date = item.workProgressDate;
        let dateUpdated = moment(date).add(item.estimatedtime, "seconds");
        if (moment(dateUpdated).isBefore(Date.now())) valid = true;
        else valid = false;
      }
      if (item.problem && item.problem) return "problem-type";
      else if (valid) return "delayed-type";
      // if (valid) return "problem-type";
      else return "inprogress-type ";
    } else return "completed-type ";
  };

  return (
    <div
      ref={provided.innerRef}
      className={
        snapshot.mode && !snapshot.draggingOver
          ? "item-droppable item-droppable-error"
          : remarkedReport && remarkedReport === item.reportid
          ? getSearchClass()
          : "item-droppable"
      }
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
    >
      <div className="icon-report">
        <div className={getClass()}>
          <img src={getMarker()} alt="" />
        </div>
      </div>
      <div className="error-droppable-item">
        <Ignore></Ignore>
      </div>
      <div className="priority-droppable-item"></div>
      <div className="content-droppable-item">
        <div className="content-aligner">
          <div className="left-content">
            <div className="title-droppable-item">
              {" "}
              <div>
                {index + 1 + "- "}
                {getType()}
              </div>
              {/* <div>
            <img src={this.getMarker()} alt="" />
          </div> */}
            </div>
            <div className="description-droppable-item">
              <p>{item.description}</p>
            </div>
            <div className="bottom-content-droppable-item">
              {/* <UserTile
            t={t}
            users={users}
            item={item}
          ></UserTile> */}
              {(type === 1 || type === 2) && (
                <div className="time-tile">
                  <div className="title-time-tile">
                    {t("subtitle.time.started")}
                  </div>
                  <div className="data-time-tile">
                    <ClockIcon></ClockIcon>
                    {(type === 1 || type === 2) &&
                      item.createdat &&
                      GetTime(
                        moment.unix(item.createdat.seconds).toLocaleString()
                      )}
                  </div>
                </div>
              )}
              {type === 3 && (
                <div className="time-tile">
                  <div className="title-time-tile">
                    {t("subtitle.time.after")}
                  </div>
                  <div className="data-time-tile">
                    <ClockIcon></ClockIcon>
                    {type === 3 &&
                      item.workProgressDate &&
                      GetTime(
                        moment
                          .unix(item.workProgressDate.seconds)
                          .toLocaleString()
                      )}
                  </div>
                </div>
              )}
              {type !== 1 && (
                <div className="time-tile">
                  <div className="title-time-tile">
                    {type === 2
                      ? t("subtitle.time.after")
                      : t("subtitle.time.before")}
                  </div>
                  <div className="data-time-tile">
                    <ClockIcon></ClockIcon>
                    {type === 2
                      ? item.workProgressDate &&
                        GetTime(
                          moment
                            .unix(item.workProgressDate.seconds)
                            .toLocaleString()
                        )
                      : item.workFinishedDate &&
                        GetTime(
                          moment
                            .unix(item.workFinishedDate.seconds)
                            .toLocaleString()
                        )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="right-content">
            <img src={item.imageurl} className="" alt="..." />
          </div>
        </div>
      </div>
      <div className="bottom-droppable-item">
        <div
          className="data-address-box"
          onClick={() => {
            openTab(
              "http://maps.google.com/maps?z=12&t=m&q=loc:" +
                item.geopoint?.latitude +
                "," +
                item.geopoint?.longitude
            );
          }}
        >
          <PlaceIcon></PlaceIcon>
          <p>{item.address && item.address}</p>
        </div>
      </div>
    </div>
  );
};

export default TileCalendar;
