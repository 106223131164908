// Please use your own credentials here
import { BehaviorSubject } from "rxjs";
export const session = new BehaviorSubject(false);
export const sessionLogout = new BehaviorSubject(false);

// parses the result after authentication from URL hash
// handleAuthentication = () => {
//   this.auth0.parseHash((err, authResult) => {
//     console.log(authResult)
//     if (authResult && authResult.accessToken && authResult.idToken) {
//       this.setSession(authResult);
//       history.replace('/');
//     } else if (err) {
//       history.replace('/');
//       console.log(err);
//     }
//   });
// }

// Sets user details in localStorage
export const setSession = (authResult: any, check: any) => {
  // Set the time that the access token will expire at
  // let expiresAt = JSON.stringify(authResult.expiresIn + new Date().getTime());

  // If there is a value on the `scope` param from the authResult,
  // use it to set scopes in the session for the user. Otherwise
  // use the scopes as requested. If no scopes were requested,
  // set it to nothing

  localStorage.setItem("token", authResult.accessToken);
  localStorage.setItem("refresh_token", authResult.refreshToken);

  // localStorage.setItem("expires_at", expiresAt);
  // localStorage.setItem("email", authResult.email);
  // localStorage.setItem("name", authResult.name);
  // localStorage.setItem("lastName", authResult.lastName);
  // localStorage.setItem("id", authResult.id);
  // localStorage.setItem("districtId", authResult.districtId);
  // localStorage.setItem("typeOfUser", authResult.typeOfUser);
  // navigate to the home route
  check();
};

// removes user details from localStorage
export const logout = (history?: any) => {
  // Clear access token and ID token from local storage
  localStorage.removeItem("token");
  localStorage.removeItem("refresh_token");
  // localStorage.removeItem("expires_at");
  // localStorage.removeItem("id");
  // localStorage.removeItem("districtId");
  // localStorage.removeItem("email");
  // localStorage.removeItem("name");
  // localStorage.removeItem("lastName");
  // localStorage.removeItem("typeOfUser");
  // navigate to the home route
  if (history !== null && history !== undefined) history();
  sessionLogout.next(true);
};

// checks if the user is authenticated
export const isAuthenticated = () => {
  // Check whether the current time is past the
  // access token's expiry time
  let expiresAt = JSON.parse(localStorage.getItem("expires_at") || "");
  // if (session.value !== new Date().getTime() < expiresAt)
  session.next(new Date().getTime() < expiresAt);
  if (!(new Date().getTime() < expiresAt)) {
    logout();
  }
  // return new Date().getTime() < expiresAt;
};

export const getAccessToken = () => {
  const accessToken = localStorage.getItem("access_token");
  return accessToken;
};

export const getRefreshToken = () => {
  const refreshToken = localStorage.getItem("refresh_token");
  return refreshToken;
};

export const setAccessToken = (token) => {
  const accessToken = localStorage.setItem("access_token", token);
  return accessToken;
};

export const setRefreshToken = (token) => {
  const refreshToken = localStorage.setItem("refresh_token", token);
  return refreshToken;
};

export const getUserEmail = () => {
  return localStorage.getItem("email");
};

export const getId = () => {
  return localStorage.getItem("id");
};

export const setName = (name) => {
  const nameItem = localStorage.setItem("name", name);
  return nameItem;
};

export const getUser = () => {
  if (localStorage.getItem("id") !== undefined)
    return {
      id: localStorage.getItem("id"),
      districtId: localStorage.getItem("districtId"),
      email: localStorage.getItem("email"),
      name: localStorage.getItem("name"),
      lastName: localStorage.getItem("lastName"),
      typeOfUser: localStorage.getItem("typeOfUser"),
    };
  return null;
};

export const changeLanguageS = (e) => {
  localStorage.setItem("language", e);
  return true;
};

export const getLanguageS = () => {
  return localStorage.getItem("language");
};
