import moment from "moment";

import {
  DASHBOARD_LOADING_BY_STATUS,
  DASHBOARD_LOADING_BY_DATE,
  DASHBOARD_LOADING_BY_CATEGORY,
  DASHBOARD_LOADING_BY_RESPONSE,
  DASHBOARD_UPDATE_DATE,
  DASHBOARD_CLEAR_DATE,
  DASHBOARD_BY_STATUS,
  DASHBOARD_BY_DATE,
  DASHBOARD_BY_CATEGORY,
  DASHBOARD_BY_CATEGORY_CUSTOM,
  DASHBOARD_BY_RESPONSE,
  DashboardDispatchTypes,
} from "./dashboardTypes";

let defaultValue: any;

export interface updateDateI {
  rangeDate: Array<Date>;
  field: string;
}

export interface byStatusI {
  todayStats: any;
  days7Stats: any;
  month1Stats: any;
  month6Stats: any;
  year1Stats: any;
  reportDelaysList: any[];
}

export interface byDateI {
  responseList: responseByDateI[];
}

export interface responseByDateI {
  year: number;
  month: number;
  pending: number;
  progress: number;
  completed: number;
}

export interface byCategoryI {
  responseList: responseByCategoryI[];
}

export interface responseByCategoryI {
  category: string;
  todayStats: any;
  days7Stats: any;
  month1Stats: any;
}

export interface byCategoryCustomI {
  responseList: responseByCategoryCustomI[];
}

export interface responseByCategoryCustomI {
  category: string;
  customStats: any;
}

export interface byResponseI {
  responseList: responseByResponseI[];
}

export interface responseByResponseI {
  year: number;
  month: number;
  avgResponseTime: number;
}

interface dashboardState {
  loadingByStatus: boolean;
  loadingByDate: boolean;
  loadingByCategory: boolean;
  loadingByResponse: boolean;
  reportsByStatus: byStatusI;
  reportsByDate: byDateI;
  reportsByCategory: byCategoryI;
  reportsByCategoryCustom: byCategoryCustomI;
  reportsByResponse: byResponseI;
  dateDate: Array<Date>;
  categoryDate: Array<Date>;
  responseDate: Array<Date>;
}

const defaultState: dashboardState = {
  loadingByStatus: false,
  loadingByDate: false,
  loadingByCategory: false,
  loadingByResponse: false,
  reportsByStatus: defaultValue,
  reportsByDate: defaultValue,
  reportsByCategory: defaultValue,
  reportsByCategoryCustom: defaultValue,
  reportsByResponse: defaultValue,
  dateDate: [moment().startOf("year").toDate(), moment().endOf("day").toDate()],
  categoryDate: [
    moment().startOf("year").toDate(),
    moment().endOf("day").toDate(),
  ],
  responseDate: [
    moment().startOf("year").toDate(),
    moment().endOf("day").toDate(),
  ],
};

const dashboardReducer = (
  state: dashboardState = defaultState,
  action: DashboardDispatchTypes
): dashboardState => {
  switch (action.type) {
    case DASHBOARD_LOADING_BY_STATUS:
      return {
        ...state,
        loadingByStatus: action.payload,
      };
    case DASHBOARD_LOADING_BY_DATE:
      return {
        ...state,
        loadingByDate: action.payload,
      };
    case DASHBOARD_LOADING_BY_CATEGORY:
      return {
        ...state,
        loadingByCategory: action.payload,
      };
    case DASHBOARD_LOADING_BY_RESPONSE:
      return {
        ...state,
        loadingByResponse: action.payload,
      };
    case DASHBOARD_UPDATE_DATE:
      let rangeDate = action.payload.rangeDate;
      let field = action.payload.field;
      return {
        ...state,
        [field]: [
          moment(rangeDate[0]).startOf("day").utc(false).toDate(),
          moment(rangeDate[1]).endOf("day").utc(false).toDate(),
        ],
      };
    case DASHBOARD_CLEAR_DATE:
      let clearField = action.payload;
      return {
        ...state,
        [clearField]: [
          moment().startOf("year").utc(false).toDate(),
          moment().endOf("day").utc(false).toDate(),
        ],
      };
    case DASHBOARD_BY_STATUS:
      return {
        ...state,
        reportsByStatus: action.payload,
        loadingByStatus: false,
      };
    case DASHBOARD_BY_DATE:
      return {
        ...state,
        reportsByDate: action.payload,
        loadingByDate: false,
      };
    case DASHBOARD_BY_CATEGORY:
      return {
        ...state,
        reportsByCategory: action.payload,
        loadingByCategory: false,
      };
    case DASHBOARD_BY_CATEGORY_CUSTOM:
      return {
        ...state,
        reportsByCategoryCustom: action.payload,
      };
    case DASHBOARD_BY_RESPONSE:
      return {
        ...state,
        reportsByResponse: action.payload,
        loadingByResponse: false,
      };
    default:
      return state;
  }
};

export default dashboardReducer;
