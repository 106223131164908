import React from "react";
import "./style.scss";

interface ChartModalProps {
  onCloseRequest?: any;
  children: any;
}

export const ChartModal: React.FunctionComponent<ChartModalProps> = ({
  onCloseRequest,
  children,
}: any) => {
  return <div className="modalOverchat">{children}</div>;
};

export default ChartModal;
