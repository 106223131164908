import React from 'react';
import { useSelector } from "react-redux";
import { RootStore } from '../../../redux/Store';
import { Report } from "../../../server/report/protos/report_pb";
import { ChatMessage, PendingRoomResponse } from '../../../server/chat/protos/chat_pb';
import { useTranslation } from 'react-i18next';

interface FilterPendientChatsProps {
    onOpenReport: (report: Report.AsObject | undefined) => void;
}

const FilterPendientChats: React.FunctionComponent<FilterPendientChatsProps> = ({ onOpenReport }: FilterPendientChatsProps) => {
    const { t } = useTranslation();

    const pendingRooms: PendingRoomResponse.UnseenRoom.AsObject[] = useSelector(
        (globalState: RootStore) => globalState.chat.pendingRooms
    );

    const reportsR: Report.AsObject[] = useSelector(
        (globalState: RootStore) => globalState.reportTypes.reports
    );

    const pendingRoomsDetails = pendingRooms.map((room) => {
        const report = reportsR.find((report) => report.reportid === room.roomId);
        return {
            ...room,
            report,
        };
    }).sort((a, b) => {
        const aSeconds = a.lastdate?.seconds ?? 0;
        const bSeconds = b.lastdate?.seconds ?? 0;
        const aNanos = a.lastdate?.nanos ?? 0;
        const bNanos = b.lastdate?.nanos ?? 0;
        if (bSeconds !== aSeconds) {
            return bSeconds - aSeconds;
        }
        return bNanos - aNanos;
    });;

    return (
        <div className="chats-box">
            <div className="title">
                {t("name.chatsPendients")}
            </div>
            {pendingRoomsDetails.map((detail, index) => {
                return (
                    <div className='chat-container' key={index} onClick={() => onOpenReport(detail.report)}>
                        <div className="image-container">
                            <img
                                src={
                                    detail.lastsenderimage
                                        ? detail.lastsenderimage
                                        : "https://miciudad.s3-us-west-1.amazonaws.com/profile.png"
                                }
                            />
                        </div>
                        <div className="text-container">
                            <span>{detail?.report?.address}</span>
                            <h6>{detail.lastsendername}</h6>
                            <span>{detail.lastmessage}</span>
                        </div>
                        <div className="span-container">
                            <span>{detail.unseenmessages}</span>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default FilterPendientChats;