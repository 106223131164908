import { Tracker } from "../../server/tracking/protos/tracking_pb";
import { timeFilterI } from "./trackingReducer";

export const TRACKING_LOADING = "TRACKING_LOADING";
export const TRACKING_ERROR = "TRACKING_ERROR";
export const TRACKING_ACTIVE_DATES = "TRACKING_ACTIVE_DATES";
export const TRACKING_LIST = "TRACKING_TRACKERS_LIST";
export const TRACKING_SELECTED_TEAMS = "TRACKING_SELECTED_TEAMS";
export const TRACKING_SELECTED_TRACKERS = "TRACKING_SELECTED_TRACKERS";

export const TRACKING_TIME_FILTER = "TRACKING_TIME_FILTER";
//================================================================

export interface TrackingLoading {
  type: typeof TRACKING_LOADING;
}

export interface TrackingError {
  type: typeof TRACKING_ERROR;
}

export interface TrackingActiveDates {
  type: typeof TRACKING_ACTIVE_DATES;
  payload: string[];
}

export interface TrackingList {
  type: typeof TRACKING_LIST;
  payload: Tracker.AsObject[];
}

export interface TrackingSelectedTeams {
  type: typeof TRACKING_SELECTED_TEAMS;
  payload: string[];
}

export interface TrackingSelectedTrackers {
  type: typeof TRACKING_SELECTED_TRACKERS;
  payload: string[];
}

export interface TrackingTimeFilter {
  type: typeof TRACKING_TIME_FILTER;
  payload: timeFilterI;
}

export type TrackingDispatchTypes =
  | TrackingLoading
  | TrackingError
  | TrackingActiveDates
  | TrackingList
  | TrackingSelectedTeams
  | TrackingSelectedTrackers
  | TrackingTimeFilter;
