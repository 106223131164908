import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../redux/Store";
import { useTranslation } from "react-i18next";

import { grpcAssignToTeam } from "../../../redux/teams/teamsActions";

import HeaderDialog from "../../components/Header-dialog";
import TagsComponent from "../../components/tags-component";
import ActionButtons from "../../components/Action-buttons";
import { Team } from "../../../server/team/protos/team_pb";
import { User } from "../../../server/user/protos/user_pb";

interface AddWorkerModalProps {
  onCloseRequest: any;
}

export const AddWorkerModal: React.FunctionComponent<AddWorkerModalProps> = ({
  onCloseRequest,
}: any) => {
  const selectedTeam: Team.AsObject = useSelector(
    (globalState: RootStore) => globalState.teams.selectedTeam
  );
  const users: User.AsObject[] = useSelector(
    (globalState: RootStore) => globalState.users.users
  );
  const roleList: any = useSelector(
    (globalState: RootStore) => globalState.users.roleList
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();

  let defaultValue: any;
  let defaultValueArray: any = [];

  const [state, setState] = useState({
    name: "",
    tags: defaultValueArray,
    suggestions: defaultValueArray,
  });

  const addTeam = () => {
    let membersInsertArray: any = [];
    let membersRemoveArray: any = [];

    state.tags.forEach((e: any) => membersInsertArray.push(e.id));
    state.suggestions.forEach((e: any) => membersRemoveArray.push(e.id));

    if (membersRemoveArray.length > 0) {
      dispatch(
        grpcAssignToTeam(
          {
            id: selectedTeam.id,
            ids: membersRemoveArray,
            type: 0,
          },
          () => {
            onCloseRequest();
          }
        )
      );
    }

    if (membersInsertArray.length > 0) {
      dispatch(
        grpcAssignToTeam(
          {
            id: selectedTeam.id,
            ids: membersInsertArray,
            type: 4,
          },
          () => {
            onCloseRequest();
          }
        )
      );
    }
  };

  const changeTags = (tags: any) => {
    setState((prevState) => ({
      ...prevState,
      tags: tags,
    }));
  };

  const changeSuggestions = (suggestions: any) => {
    setState((prevState) => ({
      ...prevState,
      suggestions: suggestions,
    }));
  };

  useEffect(() => {
    let suggestionsArray: any = [];
    let tagsArray: any = [];

    const workerRole = roleList.find((role: any) => role.name === "MC.WORKER");

    users.forEach((e: User.AsObject) => {
      if (e.role === workerRole.roleid)
        if (selectedTeam.membersList.indexOf(e.id) !== -1)
          tagsArray.push({ id: e.id, name: e.name + " " + e.lastName });
        else
          suggestionsArray.push({ id: e.id, name: e.name + " " + e.lastName });
    });

    setState((prevState) => ({
      ...prevState,
      tags: tagsArray,
      suggestions: suggestionsArray,
    }));
  }, []);

  return (
    <div className="content-dialog-big dialog-perfil">
      <HeaderDialog
        onCloseRequest={onCloseRequest}
        description={defaultValue}
        title={t("name.add.member")}
        noBold1={defaultValue}
        noBold2={defaultValue}
        boldText={defaultValue}
      ></HeaderDialog>
      <div className="content-options">
        <div className="content-options-body">
          <div className="options-body-item-tag search-tag">
            <div className="selector-box full-width-select">
              <div className="tags-box-edit">
                <div className="tile-subtitle">{t("name.selecteds")}</div>
                <TagsComponent
                  suggestions={state.suggestions}
                  tags={state.tags}
                  t={t}
                  edit={true}
                  changeTags={changeTags}
                  changeSuggestions={changeSuggestions}
                ></TagsComponent>
              </div>
            </div>
          </div>
          <div className="options-body-item-tag search-tag">
            <div className="selector-box full-width-select">
              <div className="tags-box-edit">
                <div className="tile-subtitle">{t("name.none.selecteds")}</div>
                <TagsComponent
                  suggestions={state.tags}
                  tags={state.suggestions}
                  t={t}
                  edit={true}
                  changeTags={changeSuggestions}
                  changeSuggestions={changeTags}
                ></TagsComponent>
              </div>
            </div>
          </div>
          <ActionButtons
            doneText={t("submit.continue")}
            clickable={state.tags && state.tags.length > 0 ? true : false}
            doneClick={addTeam}
            cancelClick={onCloseRequest}
            cancelText={t("submit.cancel")}
          ></ActionButtons>
        </div>
      </div>
    </div>
  );
};

export default AddWorkerModal;
