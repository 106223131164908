import React, { useState } from "react";
import { Button, Form, Input } from "antd";
import Logo from "../../../assets/img/logo.png";
import { netWorking, authAWS } from "../../../firebase";
// import SimpleModal from "../../../utils/dialogs/simple-dialog.js";
import NotificationDialog from "../../dialogs/Notification-dialog";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import { grpcSendResetPwdEmail } from "../../../redux/user/userAction";
import { SuccessResponse } from "../../../server/user/protos/user_pb";

const FormItem = Form.Item;

interface ForgotViewProps {}

export const ForgotView: React.FunctionComponent<ForgotViewProps> =
  ({}: ForgotViewProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const redirect = useHistory();

    const [state, setState] = useState({
      eyeActive: false,
      email: "",
      isLoading: false,
      isSent: false,
      isError: false,
      successMessage: t("password.success.message"),
    });

    const sendPasswordReset = (event) => {
      event.preventDefault();

      dispatch(
        grpcSendResetPwdEmail(
          {
            email: state.email.trim().toLowerCase(),
            language: authAWS.getLanguageS() || "es",
          },
          (response: SuccessResponse.AsObject) => {
            if (response.success) {
              setState((prev) => ({
                ...prev,
                isLoading: false,
                isSent: true,
                email: "",
              }));
            } else {
              setState((prev) => ({
                ...prev,
                isLoading: false,
                isError: true,
              }));
            }
          },
          () => {
            setState((prev) => ({
              ...prev,
              isLoading: false,
              isError: true,
            }));
          }
        )
      );
      // netWorking.sendPasswordResetEmail(
      //   {
      //     email: state.email.trim().toLowerCase(),
      //     language: authAWS.getLanguageS(),
      //   },
      //   (result) => {
      //     if (result.data.response.error === 0) {
      //       setState((prev) => ({
      //         ...prev,
      //         isLoading: false,
      //         isSent: true,
      //         email: "",
      //       }));
      //     } else
      //       setState((prev) => ({
      //         ...prev,
      //         isLoading: false,
      //         isError: true,
      //       }));
      //   }
      // );
    };

    const clearMessage = () => {
      setState((prev) => ({
        ...prev,
        isSent: false,
      }));
    };

    const clearError = () => {
      setState((prev) => ({
        ...prev,
        isError: false,
      }));
    };

    const handleLoginForm = (name, event) => {
      const value = event.target.value;
      event.preventDefault();
      setState((prev) => ({
        ...prev,
        [name]: value,
      }));
    };

    return (
      <div>
        <Form
          onSubmit={sendPasswordReset}
          className="login-box forgot-password"
        >
          <div className="login-logo">
            <img src={Logo} alt="" />
            Giooby
          </div>
          <div className="data-box">
            <div className="data-box-title">
              <div className="title">{t("name.redo.password")}</div>
              <div className="sub-title">
                {" "}
                {t("name.redo.password.description")}
              </div>
            </div>
            <div className="inputs-box">
              <div className="email-box">
                <div>{t("name.email")}</div>
                <FormItem>
                  <Input
                    value={state.email}
                    placeholder=""
                    onChange={(event) => handleLoginForm("email", event)}
                    className="email-login"
                  />
                </FormItem>
              </div>
            </div>
            <div className="btn-box">
              <span className="click-forgot" onClick={() => redirect.push("/")}>
                {t("name.sign.in")}
              </span>
              <FormItem>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-done"
                  onClick={() =>
                    setState((prev) => ({
                      ...prev,
                      isLoading: true,
                    }))
                  }
                  // loading={state.isLoading}
                >
                  {t("submit.send")}
                </Button>
              </FormItem>
            </div>
          </div>
          <div className="copyright">
            &copy; {t("copyright.footer")}{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://giooby.com/"
            >
              Giooby.com
            </a>
          </div>
        </Form>
        {state.isSent && (
          <NotificationDialog
            type={1}
            successMessage={state.successMessage}
            clearMessage={clearMessage}
          ></NotificationDialog>
        )}
        {state.isError && (
          <NotificationDialog
            type={2}
            errorMessage={t("name.email.no.register")}
            clearMessage={clearError}
          ></NotificationDialog>
        )}
      </div>
    );
  };

export default ForgotView;
