/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as protos_tracking_pb from '../protos/tracking_pb';


export class TrackingServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoUpdateTracking = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_tracking_pb.TrackerResponse,
    (request: protos_tracking_pb.Tracker) => {
      return request.serializeBinary();
    },
    protos_tracking_pb.TrackerResponse.deserializeBinary
  );

  updateTracking(
    request: protos_tracking_pb.Tracker,
    metadata: grpcWeb.Metadata | null): Promise<protos_tracking_pb.TrackerResponse>;

  updateTracking(
    request: protos_tracking_pb.Tracker,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_tracking_pb.TrackerResponse) => void): grpcWeb.ClientReadableStream<protos_tracking_pb.TrackerResponse>;

  updateTracking(
    request: protos_tracking_pb.Tracker,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_tracking_pb.TrackerResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/TrackingService/UpdateTracking',
        request,
        metadata || {},
        this.methodInfoUpdateTracking,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/TrackingService/UpdateTracking',
    request,
    metadata || {},
    this.methodInfoUpdateTracking);
  }

  methodInfoAddEventVote = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_tracking_pb.SuccessMessage,
    (request: protos_tracking_pb.EventVote) => {
      return request.serializeBinary();
    },
    protos_tracking_pb.SuccessMessage.deserializeBinary
  );

  addEventVote(
    request: protos_tracking_pb.EventVote,
    metadata: grpcWeb.Metadata | null): Promise<protos_tracking_pb.SuccessMessage>;

  addEventVote(
    request: protos_tracking_pb.EventVote,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_tracking_pb.SuccessMessage) => void): grpcWeb.ClientReadableStream<protos_tracking_pb.SuccessMessage>;

  addEventVote(
    request: protos_tracking_pb.EventVote,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_tracking_pb.SuccessMessage) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/TrackingService/AddEventVote',
        request,
        metadata || {},
        this.methodInfoAddEventVote,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/TrackingService/AddEventVote',
    request,
    metadata || {},
    this.methodInfoAddEventVote);
  }

  methodInfoAddEventTicket = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_tracking_pb.SuccessMessage,
    (request: protos_tracking_pb.EventTicket) => {
      return request.serializeBinary();
    },
    protos_tracking_pb.SuccessMessage.deserializeBinary
  );

  addEventTicket(
    request: protos_tracking_pb.EventTicket,
    metadata: grpcWeb.Metadata | null): Promise<protos_tracking_pb.SuccessMessage>;

  addEventTicket(
    request: protos_tracking_pb.EventTicket,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_tracking_pb.SuccessMessage) => void): grpcWeb.ClientReadableStream<protos_tracking_pb.SuccessMessage>;

  addEventTicket(
    request: protos_tracking_pb.EventTicket,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_tracking_pb.SuccessMessage) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/TrackingService/AddEventTicket',
        request,
        metadata || {},
        this.methodInfoAddEventTicket,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/TrackingService/AddEventTicket',
    request,
    metadata || {},
    this.methodInfoAddEventTicket);
  }

  methodInfoGetActiveDates = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_tracking_pb.ActiveDatesResponse,
    (request: protos_tracking_pb.ActiveDatesRequest) => {
      return request.serializeBinary();
    },
    protos_tracking_pb.ActiveDatesResponse.deserializeBinary
  );

  getActiveDates(
    request: protos_tracking_pb.ActiveDatesRequest,
    metadata: grpcWeb.Metadata | null): Promise<protos_tracking_pb.ActiveDatesResponse>;

  getActiveDates(
    request: protos_tracking_pb.ActiveDatesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_tracking_pb.ActiveDatesResponse) => void): grpcWeb.ClientReadableStream<protos_tracking_pb.ActiveDatesResponse>;

  getActiveDates(
    request: protos_tracking_pb.ActiveDatesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_tracking_pb.ActiveDatesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/TrackingService/GetActiveDates',
        request,
        metadata || {},
        this.methodInfoGetActiveDates,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/TrackingService/GetActiveDates',
    request,
    metadata || {},
    this.methodInfoGetActiveDates);
  }

  methodInfoGetByLicenseAndDate = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_tracking_pb.ByLicenseAndDateResponse,
    (request: protos_tracking_pb.ByLicenseAndDateRequest) => {
      return request.serializeBinary();
    },
    protos_tracking_pb.ByLicenseAndDateResponse.deserializeBinary
  );

  getByLicenseAndDate(
    request: protos_tracking_pb.ByLicenseAndDateRequest,
    metadata: grpcWeb.Metadata | null): Promise<protos_tracking_pb.ByLicenseAndDateResponse>;

  getByLicenseAndDate(
    request: protos_tracking_pb.ByLicenseAndDateRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: protos_tracking_pb.ByLicenseAndDateResponse) => void): grpcWeb.ClientReadableStream<protos_tracking_pb.ByLicenseAndDateResponse>;

  getByLicenseAndDate(
    request: protos_tracking_pb.ByLicenseAndDateRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: protos_tracking_pb.ByLicenseAndDateResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/TrackingService/GetByLicenseAndDate',
        request,
        metadata || {},
        this.methodInfoGetByLicenseAndDate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/TrackingService/GetByLicenseAndDate',
    request,
    metadata || {},
    this.methodInfoGetByLicenseAndDate);
  }

  methodInfoSubscribe = new grpcWeb.AbstractClientBase.MethodInfo(
    protos_tracking_pb.ByIdResponse,
    (request: protos_tracking_pb.ByIdRequest) => {
      return request.serializeBinary();
    },
    protos_tracking_pb.ByIdResponse.deserializeBinary
  );

  subscribe(
    request: protos_tracking_pb.ByIdRequest,
    metadata?: grpcWeb.Metadata) {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/TrackingService/Subscribe',
      request,
      metadata || {},
      this.methodInfoSubscribe);
  }

}

