import {
  NOTIFICATIONS_LOADING,
  NOTIFICATIONS_ERROR,
  NOTIFICATIONS_LIST,
  NOTIFICATIONS_SELECTED,
  NOTIFICATIONS_UPDATES,
} from "./notificationsTypes";
import { NotificationServiceClient } from "../../server/notification/protos/NotificationServiceClientPb";
import {
  FetchResult,
  RequestFetch,
  RequestType,
  Request,
  NotificationApp,
  UpdateNotification,
  SuccessMessage,
  RequestIgnore,
  ReportNotification,
  NotificationType,
} from "../../server/notification/protos/notification_pb";
import { BACKEND_URL } from "../RootReducer";
import { Metadata } from "grpc-web";

const notificationClient = new NotificationServiceClient(BACKEND_URL || "");
const notificationHeaders: Metadata = {
  // service_name: "LicenseService",
  "Access-Control-Allow-Origin": "*",
  // crossdomain: true,
  allow_headers: "*",
  // "origin": "http://localhost:3000",
};

export function grpcFetchNotifications(
  data: { id: string; type: RequestType; limit: number; offset: number },
  fun?: any
) {
  return (dispatch: any) => {
    const reqCUPNotification = new RequestFetch();
    reqCUPNotification.setId(data.id);
    reqCUPNotification.setType(data.type);
    reqCUPNotification.setLimit(data.limit);
    reqCUPNotification.setOffset(data.offset);
    notificationClient.fetchNotifications(
      reqCUPNotification,
      notificationHeaders,
      (err, response: FetchResult) => {
        if (response == null) {
          console.log("err", err);
        } else {
          dispatch({
            type: NOTIFICATIONS_LIST,
            payload: response.toObject().notificationsList,
          });
          if (fun) {
            fun();
          }
        }
      }
    );
  };
}

// export function grpcListenToNotifications(
//   data: { id: string; userId: string; type: RequestType },
//   fun?: any
// ) {
//   return (dispatch: any) => {
//     const reqATListen = new Request();
//     reqATListen.setId(data.id);
//     reqATListen.setUserid(data.userId);
//     reqATListen.setType(data.type);

//     notificationClient
//       .listenNotifications(reqATListen, notificationHeaders)
//       .on("data", (response) => {
//         console.log("noti stream", response as NotificationApp);
//         dispatch({
//           type: NOTIFICATIONS_UPDATES,
//           payload: (response as NotificationApp).toObject(),
//         });
//         if (fun) {
//           fun();
//         }
//       })
//       .on("error", (err) => {
//         console.log("err", err);
//       });
//   };
// }

export function grpcCreateNotification(
  value: {
    cityid: string;
    notificationimage: string;
    notificationtype: NotificationType;
    reportid: string;
    reportimage: string;
    title: string;
    reporttypeid: string;
    userid: string;
    sentby: string;
    body: string;
    reporttitle: string;
  },
  fun?: any
) {
  return (dispatch: any) => {
    const reqCUPNotification = new ReportNotification();
    reqCUPNotification.setBody(value.body);
    reqCUPNotification.setCityid(value.cityid);
    reqCUPNotification.setNotificationimage(value.notificationimage);
    reqCUPNotification.setNotificationtype(value.notificationtype);
    reqCUPNotification.setReportid(value.reportid);
    reqCUPNotification.setReportimage(value.reportimage);
    reqCUPNotification.setTitle(value.title);
    reqCUPNotification.setReporttypeid(value.reporttypeid);
    reqCUPNotification.setUserid(value.userid);
    reqCUPNotification.setSentby(value.sentby);
    reqCUPNotification.setReporttitle(value.reporttitle);

    // reqCUPNotification.setBody(data.body);
    notificationClient.sendNotificationReport(
      reqCUPNotification,
      notificationHeaders,
      (err, response: SuccessMessage) => {
        if (response == null) {
          console.log("err", err);
        } else {
          if (fun) {
            fun();
          }
        }
      }
    );
  };
}

export function grpcUpdateNotification(
  data: { id: string; body: string },
  fun?: any
) {
  return (dispatch: any) => {
    const reqCUPNotification = new UpdateNotification();
    reqCUPNotification.setId(data.id);
    reqCUPNotification.setBody(data.body);
    console.log("commetn", reqCUPNotification);
    notificationClient.update(
      reqCUPNotification,
      notificationHeaders,
      (err, response: SuccessMessage) => {
        if (fun) {
          fun();
        }
        if (response == null) {
          console.log("err", err);
        } else {
          if (fun) {
            fun();
          }
        }
      }
    );
  };
}

export function grpcIgnoreNotification(
  data: {
    id: string;
    typeIgnore: RequestIgnore.RequestIgnoreType;
    typeRequest: RequestType;
  },
  fun?: any
) {
  return (dispatch: any) => {
    const reqCUPNotification = new RequestIgnore();
    reqCUPNotification.setId(data.id);
    reqCUPNotification.setType(data.typeIgnore);
    reqCUPNotification.setRequesttype(data.typeRequest);
    notificationClient.ignore(
      reqCUPNotification,
      notificationHeaders,
      (err, response: SuccessMessage) => {
        if (fun) {
          fun();
        }
        if (response == null) {
          console.log("err", err);
        } else {
          if (fun) {
            fun();
          }
        }
      }
    );
  };
}
