import React, { useState, useEffect } from "react";
import queryString from "query-string";
import { isEmpty } from "lodash";
import "./style.scss";
import { useSelector } from "react-redux";
import KanBanBox from "./components/kanban-box";
import { RootStore } from "../../redux/Store";
import { Report } from "../../server/report/protos/report_pb";
import { Team } from "../../server/team/protos/team_pb";
import { ReportTypes } from "../../redux/reportTypes/reportsReducer";
import { useTranslation } from "react-i18next";

interface CalendarProps {
  reportTypes: any;
  teams: any;
  kanbanKeys: any;
  reports: any;
  changeSelectedTeam: any;
  statistics: any;
  selectedTeam: any;
  Coords: any;
  updateCoords: any;
  currentZoom: any;
  updateCurrentZoom: any;
  executeCalculation: any;
  setCenter: any;
  location: any;
  t: any;
}

export const Calendar: React.FunctionComponent<CalendarProps> = ({
  Coords,
  updateCoords,
  currentZoom,
  updateCurrentZoom,
  location,
}: any) => {
  let defaultValue: any;
  const { t } = useTranslation();
  const [params, setParams] = useState(defaultValue);
  const [state, setState] = useState<{
    center: {
      lat: number;
      lng: number;
    };
    coords: {
      lat: number;
      lng: number;
    };
    zoom: number;
    currentZoom: number;
    visible: boolean;
    // selectedTeam: Team.AsObject;
    selectedZone: any;
    selectedReports: any[];
    users: any[];
    usersId: any[];
    tab: any;
  }>({
    center: {
      lat: 32.805197,
      lng: -117.010139,
    },
    coords: {
      lat: 32.805197,
      lng: -117.010139,
    },
    zoom: 11,
    currentZoom: 11,
    visible: false,
    // selectedTeam: defaultValue,
    selectedZone: defaultValue,
    selectedReports: [],
    users: [],
    usersId: [],
    tab: defaultValue,
  });

  const reportsR: Report.AsObject[] = useSelector(
    (globalState: RootStore) => globalState.reportTypes.reportsGeneral
  );
  const reportTypes: ReportTypes[] = useSelector(
    (globalState: RootStore) => globalState.reportTypes.reportTypesGeneral
  );
  const teams: Team.AsObject[] = useSelector(
    (globalState: RootStore) => globalState.teams.teams
  );
  const selectedTeamRoutes: Team.AsObject = useSelector(
    (globalState: RootStore) => globalState.teams.selectedTeamRoutes
  );

  /* const handleClick = () => {
    setState((prevState) => ({
      ...prevState,
      visible: !state.visible,
    }));
  }; */

  const selectZone = (e: any) => {
    setState((prevState) => ({
      ...prevState,
      selectedZone: e,
    }));
  };

  // const changeSelectedTeamCalendar = (team: Team.AsObject) => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     selectedTeam: team,
  //   }));
  // };

  // const changeTeam = (team: Team.AsObject) => {
  //   if (reportsR) changeSelectedTeamCalendar(team);
  // };

  const openTab = (url: any) => {
    if (state.tab) {
      state.tab.close();
    }
    let win = window.open(url, "_blank", "");
    if (win?.focus) {
      win.focus();
    }
    setState((prevState) => ({
      ...prevState,
      tab: win,
    }));
    return false;
  };

  const change = (e: any) => {
    // console.log(e);
    if (currentZoom !== e.zoom) {
      updateCurrentZoom(e.zoom);
    }
  };

  // useEffect(() => {
  //   if (!selectedTeamRoutes && teams[0] && reportsR) {
  //     changeSelectedTeamCalendar(teams[0]);
  //   }
  // }, []);

  useEffect(() => {
    setParams(queryString.parse(location.search));
  }, [location.search]);

  const getReports = () => {
    if (selectedTeamRoutes && teams) {
      const array: string[] =
        teams.find((e: Team.AsObject) => {
          return e.id === selectedTeamRoutes.id;
        })?.tasksList || [];
      return reportsR.filter((e: Report.AsObject) => {
        return array.indexOf(e.reportid) !== -1;
      });
    } else {
      return [];
    }
    // reportsR.filter((e: Report.AsObject) =>
    //             !state.selectedTeam && teams
    //               ? teams[0]
    //                 ? teams[0].tasksList
    //                   ? teams[0].tasksList.indexOf(e.reportid) !== -1
    //                   : []
    //                 : []
    //               : teams.find((e: Team.AsObject) => {
    //                   return e.id === state.selectedTeam;
    //                 })
    //               ? teams.find((e: Team.AsObject) => {
    //                   return e.id === state.selectedTeam;
    //                 }).tasksList
    //               : []
    //               ? teams
    //                   .find((e: Team.AsObject) => {
    //                     return e.id === state.selectedTeam;
    //                   })
    //                   .tasksList.indexOf(e.reportid) !== -1
    //               : []
    //           )
  };
  return (
    <div className="routes-page" /* style={{ height: "93%", width: "100%" }} */>
      {/*<FilterTeam
          selectedTeam={
            this.state.selectedTeam === null
              ? this.props.teams[0] !== undefined
                ? this.props.teams[0]
                : null
              : this.state.selectedTeam
          }
          changeTeam={changeTeam}
          visible={this.state.visible}
          handleClick={handleClick}
          teams={this.props.teams}
          t={this.props.t}
        ></FilterTeam>*/}
      {/* <div className="team-selector">
        <SelectComponentTeams></SelectComponentTeams>
      </div> */}
      <KanBanBox
        openTab={openTab}
        updateCoords={updateCoords}
        center={Coords}
        zoom={currentZoom}
        defaultCenter={state.center}
        defaultZoom={state.zoom}
        change={change}
        selectedTeam={selectedTeamRoutes}
        remarkedReport={!isEmpty(params) ? params.id : defaultValue}
        selectedZone={state.selectedZone}
        users={state.users}
        selectZone={selectZone}
        t={t}
        key={Math.random().toString(36).substring(7)}
        reportTypes={reportTypes}
        reports={reportsR ? getReports() : []}
      ></KanBanBox>
    </div>
  );
};

export default Calendar;
