import React, { Fragment } from "react";
import DashboardMayor from "./component-pages/dashboard-mayor-page";

export const Home: React.FunctionComponent = () => {
  return (
    <Fragment>
      <DashboardMayor></DashboardMayor>
    </Fragment>
  );
};

export default Home;
