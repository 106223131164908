import React, { useState, useEffect } from "react";
import { ReportTypes } from "../../../redux/reportTypes/reportsReducer";
import FeatherIcon from "feather-icons-react";
import { BitMap } from "../../../assets/img";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../redux/Store";
import { REPORTS_FILTER_LIST } from "../../../redux/reportTypes/reportsTypes";
import { licenseI } from "../../../redux/licenses/licensesReducer";

interface FilterItemProps {
  reportType: ReportTypes;
  getCount: any;
}

export const FilterItem: React.FunctionComponent<FilterItemProps> = ({
  reportType,
  getCount,
}: FilterItemProps) => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const filtersSelected: string[] = useSelector(
    (globalState: RootStore) => globalState.reportTypes.filtersSelected
  );
  const selectedLicense: licenseI | undefined = useSelector(
    (globalState: RootStore) => globalState.licenses.selectedLicense
  );

  const setFilter = (e: string[]) => {
    // changeFilter(e);
    dispatch({
      type: REPORTS_FILTER_LIST,
      payload: e,
    });
  };

  return (
    <>
      <li
        key={reportType.reporttypeid}
        onClick={() => {
          var array = filtersSelected;
          const index = array.indexOf(reportType.reporttypeid);
          if (index !== -1) {
            array.splice(index, 1);
            reportType.reportTypes?.forEach((a) => {
              const index = array.indexOf(a.reporttypeid);
              if (index !== -1) {
                array.splice(index, 1);
              }
            });
          } else {
            array.push(reportType.reporttypeid);
            reportType.reportTypes?.forEach((a) => {
              const index = array.indexOf(a.reporttypeid);
              if (index === -1) {
                array.push(a.reporttypeid);
              }
            });
          }
          setFilter(array);
        }}
        className={
          (filtersSelected.indexOf(reportType.reporttypeid) !== -1
            ? "filter-selected"
            : "") + " filter-item done-filter-icon"
        }
      >
        <div className="checkbox-selected">
          <FeatherIcon icon="check" size="14" />
        </div>
        <div
          className={
            !reportType.iconmap || reportType.iconmap === ""
              ? "radius"
              : "box-image-types neutral-bg-type"
          }
        >
          <img
            src={
              !reportType.iconmap || reportType.iconmap === ""
                ? BitMap
                : reportType.iconmap
            }
            alt=""
          ></img>
        </div>
        <div className="type-text">
          {" "}
          {reportType[t("language")]} ({getCount(reportType.reporttypeid)})
        </div>
      </li>
      {reportType.reportTypes?.map((d) => {
        if (
          (selectedLicense?.licenseSettings?.jobTypes || []).indexOf(
            d.reporttypeid
          ) !== -1
        )
          return (
            <li
              key={d.reporttypeid}
              onClick={() => {
                var array = filtersSelected;
                const index = array.indexOf(d.reporttypeid);
                if (index !== -1) {
                  array.splice(index, 1);
                } else {
                  array.push(d.reporttypeid);
                }
                setFilter(array);
              }}
              className={
                (filtersSelected.indexOf(d.reporttypeid) !== -1
                  ? "filter-selected  sub-filter"
                  : "") + " filter-item done-filter-icon  sub-filter"
              }
            >
              <div className="checkbox-selected">
                <FeatherIcon icon="check" size="14" />
              </div>
              <div
                className={
                  d.iconmap === null || d.iconmap === ""
                    ? "radius"
                    : "box-image-types "
                }
              >
                <img
                  src={
                    d.iconmap === null || d.iconmap === "" ? BitMap : d.iconmap
                  }
                  alt=""
                ></img>
              </div>
              <div className="type-text">
                {" "}
                {d[t("language")]} ({getCount(d.reporttypeid)})
              </div>
            </li>
          );
      })}
    </>
  );
};

export default FilterItem;
