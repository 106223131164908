import {
  SET_DELETE_SETTING_FILTER,
  SET_NEW_SETTING_FILTER,
  SET_ROLES_LIST,
  SET_SETTINGS_FILTERS_LIST,
  USERS_LIST,
  USERS_LIST_BLOCKED,
} from "./usersTypes";
import {
  //CREATE-UPDATE USERS
  UserCreateMessage,
  UpdateDataMessage,
  //BLOCK-DELETE USERS
  EnableMessage,
  IdMessage,
  //FETCH USERS
  FetchMessage,
  User,
  BlockUserMessage,
  UserSettings,
  FetchSettingsMessage,
  UserSettingResponse,
  SuccessResponse,
  //CHANGE LICENSE STATUS
  ChangeLicenseStatusMessage,
} from "../../server/user/protos/user_pb";
import {
  UserServiceClient,
  UserSettingsServiceClient,
} from "../../server/user/protos/UserServiceClientPb";
import axios from "axios";
import store from "../Store";
import { BACKEND_URL } from "../RootReducer";
import { Metadata } from "grpc-web";
import { authClient, authHeaders } from "../user/userAction";
import { FetchRolesRequest } from "../../server/user/protos/auth_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";

export const userClient = new UserServiceClient(BACKEND_URL || "");
export const userSettingsClient = new UserSettingsServiceClient(
  BACKEND_URL || ""
);

export const UserHeaders: Metadata = {
  // service_name: "LicenseService",
  "Access-Control-Allow-Origin": "*",
  // crossdomain: true,
  allow_headers: "*",
  // "origin": "http://localhost:3000",
};

export function grpcResendEmailVerification(data: any, fun?: any) {
  return (dispatch: any) => {
    const reqRSEmail = new IdMessage();
    reqRSEmail.setId(data.userId);

    userClient.resendEmailVerification(
      reqRSEmail,
      UserHeaders,
      (err, response: SuccessResponse) => {
        if (response === null) {
          console.log("err", err);
        } else {
          if (fun) {
            fun();
          }
        }
      }
    );
  };
}

export function grpcChangeLicenseStatus(data: any, fun?: any) {
  return (dispatch: any) => {
    const reqCLStatus = new ChangeLicenseStatusMessage();
    reqCLStatus.setLicenseid(data.licenseId);
    reqCLStatus.setLicensestatus(data.licenseStatus);

    userClient.changeLicenseStatus(
      reqCLStatus,
      UserHeaders,
      (err, response: SuccessResponse) => {
        if (response === null) {
          console.log("err", err);
        } else {
          if (fun) {
            fun();
          }
        }
      }
    );
  };
}

export function grpcSetUserSettings(
  data: {
    id: string;
    name: string;
    filtersList: Array<string>;
    reporttypesList: Array<string>;
    createdat?: Timestamp;
    updatedat?: Timestamp;
    userid: string;
    licenseid: string;
  },
  fun?: any
) {
  return (dispatch: any) => {
    const reqEUUser = new UserSettings();
    reqEUUser.setId(data.id);
    reqEUUser.setName(data.name);
    reqEUUser.setFiltersList(data.filtersList);
    reqEUUser.setReporttypesList(data.reporttypesList);
    reqEUUser.setCreatedat(data.createdat);
    reqEUUser.setUpdatedat(data.updatedat);
    reqEUUser.setUserid(data.userid);
    reqEUUser.setLicenseid(data.licenseid);

    userSettingsClient.setUserSettings(
      reqEUUser,
      UserHeaders,
      (err, response: UserSettings) => {
        if (response === null) {
          console.log("err", err);
        } else {
          dispatch({
            type: SET_NEW_SETTING_FILTER,
            payload: response.toObject(),
          });
          if (fun) {
            fun();
          }
        }
      }
    );
  };
}

export function grpcFetchUserSettings(
  data: {
    licenseId: string;
    userId: string;
  },
  fun?: any
) {
  return (dispatch: any) => {
    const reqEUUser = new FetchSettingsMessage();
    reqEUUser.setLicenseid(data.licenseId);
    reqEUUser.setUserid(data.userId);

    userSettingsClient.fetchUserSettings(
      reqEUUser,
      UserHeaders,
      (err, response: UserSettingResponse) => {
        if (response == null) {
          console.log("err", err);
        } else {
          dispatch({
            type: SET_SETTINGS_FILTERS_LIST,
            payload: response.toObject().responseList,
          });
          if (fun) {
            fun();
          }
        }
      }
    );
  };
}

export function grpcDeleteUserSettings(data: string, fun?: any) {
  return (dispatch: any) => {
    const reqEUUser = new IdMessage();
    reqEUUser.setId(data);

    userSettingsClient.deleteUserSettings(
      reqEUUser,
      UserHeaders,
      (err, response: SuccessResponse) => {
        if (response == null) {
          console.log("err", err);
        } else {
          dispatch({
            type: SET_DELETE_SETTING_FILTER,
            payload: data,
          });
          if (fun) {
            fun();
          }
        }
      }
    );
  };
}

export function grpcEnableUser(data: any, fun?: any) {
  return (dispatch: any) => {
    const reqEUUser = new EnableMessage();
    reqEUUser.setId(data._id);
    reqEUUser.setEnable(data.enable);

    userClient.enableUser(reqEUUser, UserHeaders, (err, response) => {
      if (response == null) {
        console.log("err", err);
      } else {
        if (fun) {
          fun();
        }
      }
    });
  };
}

export function grpcBlockUser(
  data: {
    _id: string;
    licenseId: string;
    motive: string;
    block: boolean;
  },
  fun?: any
) {
  return (dispatch: any) => {
    console.log("data", data);

    const reqBUUser = new BlockUserMessage();
    reqBUUser.setUserid(data._id);
    reqBUUser.setLicenseid(data.licenseId);
    reqBUUser.setBlock(data.block);
    reqBUUser.setMotive(data.motive);

    userClient.blockUser(reqBUUser, UserHeaders, (err, response) => {
      if (response == null) {
        console.log("err", err);
      } else {
        if (fun) {
          fun(response.toObject());
        }
      }
    });
  };
}

export function grpcFetchUserById(data: string, fun?: any) {
  return (dispatch: any) => {
    const reqBUUser = new IdMessage();
    reqBUUser.setId(data);

    userClient.fetchUserById(reqBUUser, UserHeaders, (err, response: User) => {
      if (response == null) {
        console.log("err", err);
      } else {
        if (fun) {
          fun(response.toObject());
        }
      }
    });
  };
}

export function grpcCreateUser(
  data: {
    name: string;
    lastname: string;
    email: string;
    phoneNumber: string;
    role: string;
    licenseId: string;
    password?: string;
    isPasswordTemporal? : boolean; 
  },
  fun?: any
) {
  return (dispatch: any) => {
    const reqCUUser = new UserCreateMessage();
    reqCUUser.setName(data.name);
    reqCUUser.setLastName(data.lastname);
    reqCUUser.setEmail(data.email);
    reqCUUser.setPhoneNumber(data.phoneNumber);
    reqCUUser.setRole(data.role);
    reqCUUser.setLicenceId(data.licenseId);
    if (data.password) {
      reqCUUser.setPassword(data.password);
    }
    reqCUUser.setIspasswordtemporal(data?.isPasswordTemporal ?? false)
    userClient.createUser(reqCUUser, UserHeaders, (err, response) => {
      if (response == null) {
        console.log("err", err);
      } else {
        if (fun) {
          let responseAux = {
            success: response.getSuccess(),
            message: response.getMessage(),
          };
          fun(responseAux);
        }
      }
    });
  };
}


export function grpcUpdateUser(
  data: {
    _id: string;
    username: string;
    preferredLanguage: string;
    name: string;
    lastname: string;
    email: string;
    phoneNumber: string;
    imagePath: string;
    role: string;
    licenseId: string;
  },
  fun?: any
) {
  return (dispatch: any) => {
    const reqUUUser = new UpdateDataMessage();
    reqUUUser.setUserId(data._id);
    reqUUUser.setUsername(data.username);
    reqUUUser.setPreferredLanguage(data.preferredLanguage);
    reqUUUser.setImagePath(data.imagePath);
    reqUUUser.setName(data.name);
    reqUUUser.setLastName(data.lastname);
    reqUUUser.setEmail(data.email);
    reqUUUser.setPhoneNumber(data.phoneNumber);

    userClient.updateData(reqUUUser, UserHeaders, (err, response) => {
      if (response == null) {
        console.log("err", err);
      } else {
        if (fun) {
          let responseAux = {
            success: response.getSuccess(),
            message: response.getMessage(),
          };
          fun(responseAux);
        }
      }
    });
  };
}

export function grpcFetchRoles(ids: string[], fun?: any) {
  return (dispatch: any) => {
    const reqFetchRoles = new FetchRolesRequest();
    reqFetchRoles.setIdsList(ids);
    authClient.fetchRoles(reqFetchRoles, authHeaders, (err, response) => {
      if (response == null) {
        console.log("err", err);
      } else {
        dispatch({
          type: SET_ROLES_LIST,
          payload: response.toObject().rolesList,
        });
        if (fun) {
          fun();
        }
      }
    });
  };
}

export function grpcDeleteUser(data: any, fun?: any) {
  return (dispatch: any) => {
    const reqDUUser = new IdMessage();
    reqDUUser.setId(data._id);

    userClient.deleteUser(reqDUUser, UserHeaders, (err, response) => {
      if (response == null) {
        console.log("err", err);
      } else {
        if (fun) {
          fun();
        }
      }
    });
  };
}

export function grpcFetchUsersBlocked(data: any, fun?: any) {
  return (dispatch: any) => {
    const reqFUUser = new IdMessage();
    reqFUUser.setId(data);

    userClient.fetchBlockedUsers(reqFUUser, UserHeaders, (err, response) => {
      if (response == null) {
        console.log("err", err);
      } else {
        // let userArray: any = [];

        const userList: User.AsObject[] = response.toObject().usersList;
        dispatch({
          type: USERS_LIST_BLOCKED,
          payload: userList,
        });

        if (fun) {
          fun();
        }
      }
    });
  };
}

export function grpcFetchUsers(data: any, fun?: any) {
  return (dispatch: any) => {
    const reqFUUser = new FetchMessage();
    reqFUUser.setLicenceId(data._id);
    reqFUUser.setRoleId(data.role);

    userClient.fetchUsers(reqFUUser, UserHeaders, (err, response) => {
      if (response == null) {
        console.log("err", err);
      } else {
        // let userArray: any = [];

        const userList: User.AsObject[] = response.toObject().usersList;

        // userList.map((userItem: any) => {
        //   let auxItem = {
        //     _id: userItem.getId(),
        //     name: userItem.getName(),
        //     lastName: userItem.getLastName(),
        //     email: userItem.getEmail(),
        //     cellphone: userItem.getPhoneNumber(),
        //     username: userItem.getUsername(),
        //     uuid: userItem.getUuid(),
        //     imagePath: userItem.getImagePath(),
        //     licenseId: userItem.getLicenceId(),
        //     notificationEnabled: userItem.getNotificationEnabled(),
        //     preferredLanguage: userItem.getPreferredLanguage(),
        //     acceptedInvitation: userItem.getAcceptedInvitation(),
        //     blocked: userItem.getBlocked(),
        //     enabled: userItem.getEnabled(),
        //   };

        //   userArray.push(auxItem);
        // });

        dispatch({
          type: USERS_LIST,
          payload: userList,
        });

        if (fun) {
          fun();
        }
      }
    });
  };
}

export const logoutUser = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("expires_at");
  localStorage.removeItem("email");
  localStorage.removeItem("firstName");
  localStorage.removeItem("lastName");
  localStorage.removeItem("id");
  localStorage.removeItem("role");
  localStorage.removeItem("districtId");
  localStorage.removeItem("typeOfUser");
  localStorage.removeItem("campaignId");
  localStorage.removeItem("team");
  localStorage.removeItem("accessMode");
  delete axios.defaults.headers.common["Authorization"];

  store.dispatch({
    type: "USER_LOGGED_OUT",
  });
};
