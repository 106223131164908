import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RootStore } from "../../redux/Store";
import "./style.scss";

import { ReportsStatsByUsersResult } from "../../server/report/protos/reportAnalytics_pb";
import { grpcReportsStatsByUsers } from "../../redux/dashboard/dashboardActions";
import { licenseI } from "../../redux/licenses/licensesReducer";
import { User } from "../../server/user/protos/user_pb";
import {
  grpcBlockUser,
  grpcFetchUsersBlocked,
} from "../../redux/users/usersAction";

import ConfirmationDialog from "../dialogs/confirmation-dialog";
import SimpleModal from "../../utils/dialogs/simple-dialog";
import Email from "../../assets/img/mail.png";
import {
  MedalBronze,
  MedalGold,
  MedalPlatinum,
  MedalSilver,
} from "../../assets/img/index.js";
//Table
import TableContainer from "@material-ui/core/TableContainer";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";

export const UsersPage: React.FunctionComponent = () => {
  const users: User.AsObject[] = useSelector(
    (globalState: RootStore) => globalState.users.usersBlocked
  );
  const selectedLicense: licenseI | undefined = useSelector(
    (globalState: RootStore) => globalState.licenses.selectedLicense
  );
  const [userData, setUserData] = useState<
    ReportsStatsByUsersResult.Result.AsObject[] | undefined
  >(undefined);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  let defaultValue: any;

  const [state, setState] = useState<{
    showModalUnblock: false;
    blockUser: undefined | User.AsObject;
  }>({
    showModalUnblock: false,
    blockUser: defaultValue,
  });

  React.useEffect(() => {
    const array: string[] = [];

    const userIds: string[] = users.map((d) => {
      return d.id;
    });
    array.push(...userIds);

    dispatch(
      grpcReportsStatsByUsers(
        array,
        (response: ReportsStatsByUsersResult.Result.AsObject[]) => {
          setUserData(response);
        }
      )
    );
  }, [users]);

  const columns = [
    { id: 0, label: "table.column.name", minWidth: 120 },
    { id: 1, label: "table.column.email", minWidth: 170 },
    {
      id: 2,
      label: "table.column.insignia",
      minWidth: 70,
      align: "left",
      format: (value: any) => value.toLocaleString(),
    },
    {
      id: 3,
      label: "table.column.phone",
      minWidth: 120,
      align: "left",
      format: (value: any) => value.toLocaleString(),
    },
    {
      id: 4,
      label: "table.column.motive.of.block",
      minWidth: 170,
      align: "left",
      format: (value: any) => value.toFixed(2),
    },
    {
      id: 5,
      label: "",
      minWidth: 80,
      align: "right",
      format: (value: any) => value.toFixed(2),
    },
    {
      id: 6,
      label: "",
      minWidth: 61,
      align: "right",
      format: (value: any) => value.toFixed(2),
    },
  ];

  const unblockUser = () => {
    dispatch(
      grpcBlockUser(
        {
          _id: state.blockUser?.id || "",
          licenseId: selectedLicense?._id || "",
          motive: "",
          block: false,
        },
        () => {
          fetchAllUsersBLocked();
          handleToggleModalUnblock(false);
        }
      )
    );
  };

  const fetchAllUsersBLocked = () => {
    dispatch(grpcFetchUsersBlocked(selectedLicense?._id));
  };

  const handleToggleModalUnblock = (value: any, user?: any) => {
    setState((prevState) => ({
      ...prevState,
      showModalUnblock: value,
      blockUser: user,
    }));
  };

  return (
    <div className="user-page">
      <div className="top-user-page">
        <div className="amount-of-users">
          <span>{users.length}</span>
          {users.length === 1
            ? " " + t("title.users.singular")
            : " " + t("title.users")}
        </div>
      </div>
      <Paper className="root-table">
        <TableContainer className="">
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column: any) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    className={
                      column.id === 0
                        ? "column-1 first-th"
                        : column.id === 1
                        ? "column-2"
                        : column.id === 2
                        ? "column-3"
                        : column.id === 3
                        ? "column-4"
                        : column.id === 4
                        ? "column-5"
                        : column.id === 5
                        ? "column-6"
                        : "column-7"
                    }
                    style={{
                      minWidth: column.minWidth,
                      maxWidth: column.maxWidth,
                    }}
                  >
                    {t(column.label)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((item: User.AsObject, i: any) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={item.id}>
                    {columns.map((column: any) => {
                      let value = "";
                      let insignia = defaultValue;
                      if (column.id === 0) {
                        !item.username || item.username === ""
                          ? (value = " " + item.name + " " + item.lastName)
                          : (value = " " + item.username);
                      } else if (column.id === 1) {
                        if (!item.email || item.email === "") {
                          value = t("name.none");
                        } else value = item.email;
                      } else if (column.id === 2) {
                        const data = userData?.find((d) => {
                          return d.userid === item.id;
                        });
                        const reportsCount =
                          (data?.reportsCompleted || 0) +
                          (data?.reportsInProgress || 0) +
                          (data?.reportsPending || 0);
                        if (reportsCount >= 0 && reportsCount < 10)
                          insignia = MedalBronze;
                        else if (reportsCount >= 10 && reportsCount < 20)
                          insignia = MedalSilver;
                        else if (reportsCount >= 20 && reportsCount < 50)
                          insignia = MedalGold;
                        else if (reportsCount >= 50) insignia = MedalPlatinum;
                        else if (!reportsCount) {
                          insignia = MedalBronze;
                        }
                        value = "";
                      } else if (column.id === 3) {
                        value = item.phoneNumber
                          ? item.phoneNumber
                          : t("name.no.register");
                      } else if (column.id === 4) {
                        value = t("form.motive.8");
                      } else if (column.id === 5) {
                        value = "";
                      } else if (column.id === 6) {
                        value = "";
                      }

                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          className={
                            column.id === 0
                              ? "column-1 first-td"
                              : column.id === 1
                              ? "column-2"
                              : column.id === 2
                              ? "column-3"
                              : column.id === 3
                              ? "column-4"
                              : column.id === 4
                              ? "column-5"
                              : column.id === 5
                              ? "column-6"
                              : "column-7 last-td"
                          }
                        >
                          {column.id === 0 && (
                            <div className="first-name-image">
                              <img
                                src={
                                  item.imagePath
                                    ? item.imagePath
                                    : "https://miciudad.s3-us-west-1.amazonaws.com/profile.png"
                                }
                                alt=""
                              />
                              <p>{value}</p>
                            </div>
                          )}
                          {column.id === 2 && <img src={insignia} alt="" />}

                          {column.id === 5 && (
                            <button
                              className="btn-done desbloq-btn"
                              onClick={() =>
                                handleToggleModalUnblock(true, item)
                              }
                            >
                              {t("name.un.block")}
                            </button>
                          )}

                          {column.id === 6 && (
                            <img src={Email} alt="" className="email-logo" />
                          )}

                          {column.format && typeof value === "number" ? (
                            <p>{column.format(value)}</p>
                          ) : (
                            column.id !== 0 && <p>{value}</p>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {state.showModalUnblock && (
        <SimpleModal
          onCloseRequest={() => {
            handleToggleModalUnblock(false);
          }}
        >
          <ConfirmationDialog
            title={t("name.un.block.account")}
            description={""}
            noBold1={t("notification.un.block.text1")}
            noBold2={t("notification.un.block.text2")}
            boldText={
              state.blockUser
                ? !state.blockUser?.username
                  ? " " +
                    state.blockUser?.name +
                    " " +
                    state.blockUser?.lastName +
                    " "
                  : " " + state.blockUser?.username + " "
                : ""
            }
            doneClick={unblockUser}
            t={t}
            onCloseRequest={() => {
              handleToggleModalUnblock(false);
            }}
          ></ConfirmationDialog>
        </SimpleModal>
      )}
    </div>
  );
};

export default UsersPage;
