import React from "react";
import Footer from "../../components/footer/index";
import BoxTicket from "./components/box-ticket";

export default class PublicTicketPage extends React.Component {
  state = {
    ticketNumber: "",
    ticketToName: "",
    offset: 0,
    pageLength: [],
    tickets: ["", "", "", ""],
  };

  render() {
    return (
      <div className="cashier-page-public">
        <div className="cashier-body-ticket">
          <div className="column-ticket">
            <div className="row-ticket box-ticket-comment">
              <BoxTicket t={this.props.t}></BoxTicket>
            </div>
          </div>
        </div>
        Footer
        <Footer t={this.props.t}></Footer>
      </div>
    );
  }
}
