import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../redux/Store";
import { useTranslation } from "react-i18next";
import moment from "moment";

import {
  CLEAR_RANGE_DATE,
  REPORTS_VIEW_FILTERS,
  SET_RANGE_DATE,
} from "../../../redux/reportTypes/reportsTypes";
import {
  REPORT_PROGRESS_ASSIGNED,
  REPORT_PROGRESS_PROGRESS,
} from "../../../const/const";
import { ValueType } from "rsuite/lib/DateRangePicker";

import { DelayedIcon, ProblemIcon } from "../../../assets/img/index";
import { pending, inProgress, done } from "../../../const/const";
import FeatherIcon from "feather-icons-react";
import {
  Report,
  ReportStatus,
  ReportWorkStatus,
  WorkerLog,
} from "../../../server/report/protos/report_pb";
import { licenseI } from "../../../redux/licenses/licensesReducer";
import { ReportTypes } from "../../../redux/reportTypes/reportsReducer";
import { DateRangePicker } from "rsuite";
import { getCalendarLocale } from "../../../utils/utils";

interface FilterStatusProps {
  reports: Report.AsObject[];
  menuOpen: any;
  changeMenuState: any;
}

export const FilterStatus: React.FunctionComponent<FilterStatusProps> = ({
  reports,
  menuOpen,
  changeMenuState,
}: FilterStatusProps) => {
  let defaultValue: any;

  const selectedLicense: licenseI | undefined = useSelector(
    (globalState: RootStore) => globalState.licenses.selectedLicense
  );
  const reportTypesGeneralR: ReportTypes[] = useSelector(
    (globalState: RootStore) => globalState.reportTypes.reportTypesGeneral
  );
  const viewFilters: {
    pending: boolean;
    assigned: boolean;
    progress: boolean;
    completed: boolean;
    problem: boolean;
    paused: boolean;
  } = useSelector(
    (globalState: RootStore) => globalState.reportTypes.viewFilters
  );
  const filtersSelected: string[] = useSelector(
    (globalState: RootStore) => globalState.reportTypes.filtersSelected
  );
  const reportTypesGeneral: any = useSelector(
    (globalState: RootStore) => globalState.reportTypes.reportTypesGeneral
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [maxWidht, setMaxWidht] = useState(defaultValue);
  const style = `
    .rs-picker-menu {
      left: unset !important;
      right: 15px !important;
      top: 364px !important;
    }
  `;

  const updateFilter = (item: any, value: any) => {
    let filters = { ...viewFilters };
    filters[item] = value;

    dispatch({ type: REPORTS_VIEW_FILTERS, filters: filters });
  };

  const resize = () => {
    setMaxWidht(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", resize.bind(this));
  }, []);

  const getCount = (value: any) => {
    if (value === 1)
      return reports.filter(
        (e: Report.AsObject) =>
          (filtersSelected.length === 0 ||
            filtersSelected.indexOf(e.reporttypeid) !== -1) &&
          selectedLicense?.licenseSettings?.jobTypes?.indexOf(
            e.reporttypeid
          ) !== -1 &&
          e.cityid === selectedLicense?._id &&
          reportTypesGeneralR.filter(
            (f: any) => e.reporttypeid === f.reporttypeid
          ).length > 0 &&
          e.reportstatus === ReportStatus.REPORT_PENDING
      ).length;
    if (value === 2) {
      return reports.filter(
        (e: Report.AsObject) =>
          (filtersSelected.length === 0 ||
            filtersSelected.indexOf(e.reporttypeid) !== -1) &&
          selectedLicense?.licenseSettings?.jobTypes?.indexOf(
            e.reporttypeid
          ) !== -1 &&
          e.cityid === selectedLicense?._id &&
          reportTypesGeneralR.filter(
            (f: any) => e.reporttypeid === f.reporttypeid
          ).length > 0 &&
          ReportStatus.REPORT_IN_PROGRESS === e.reportstatus &&
          e.workstatus === ReportWorkStatus.WORK_ASSIGNED
      ).length;
    }
    if (value === 3)
      return reports.filter(
        (e: Report.AsObject) =>
          (filtersSelected.length === 0 ||
            filtersSelected.indexOf(e.reporttypeid) !== -1) &&
          selectedLicense?.licenseSettings?.jobTypes?.indexOf(
            e.reporttypeid
          ) !== -1 &&
          e.cityid === selectedLicense?._id &&
          reportTypesGeneralR.filter(
            (f: any) => e.reporttypeid === f.reporttypeid
          ).length > 0 &&
          e.reportstatus === ReportStatus.REPORT_IN_PROGRESS &&
          e.workstatus === ReportWorkStatus.WORK_IN_PROGRESS
      ).length;
    if (value === 4)
      return reports.filter(
        (e: Report.AsObject) =>
          (filtersSelected.length === 0 ||
            filtersSelected.indexOf(e.reporttypeid) !== -1) &&
          selectedLicense?.licenseSettings?.jobTypes?.indexOf(
            e.reporttypeid
          ) !== -1 &&
          e.cityid === selectedLicense?._id &&
          reportTypesGeneralR.filter(
            (f: any) => e.reporttypeid === f.reporttypeid
          ).length > 0 &&
          e.reportstatus === ReportStatus.REPORT_COMPLETED
      ).length;
    if (value === 5)
      return reports.filter(
        (e: Report.AsObject) =>
          (filtersSelected.length === 0 ||
            filtersSelected.indexOf(e.reporttypeid) !== -1) &&
          selectedLicense?.licenseSettings?.jobTypes?.indexOf(
            e.reporttypeid
          ) !== -1 &&
          e.cityid === selectedLicense?._id &&
          reportTypesGeneralR.filter(
            (f: any) => e.reporttypeid === f.reporttypeid
          ).length > 0 &&
          e.reportstatus === ReportStatus.REPORT_IN_PROGRESS &&
          e.problem
      ).length;
    if (value === 6) {
      return reports.filter((e: Report.AsObject) => {
        // console.log("reportes", e);
        if (
          e.reportstatus === ReportStatus.REPORT_IN_PROGRESS &&
          e.workerlogsList &&
          e.workerlogsList.length > 0
        ) {
          // console.log("reportes", e);
          // let date = e.workProgressDate;
          // let dateUpdated = moment(date).add(e.estimatedtime, "seconds");
          if (
            (filtersSelected.length === 0 ||
              filtersSelected.indexOf(e.reporttypeid) !== -1) &&
            selectedLicense?.licenseSettings?.jobTypes?.indexOf(
              e.reporttypeid
            ) !== -1 &&
            e.cityid === selectedLicense?._id &&
            reportTypesGeneralR.filter(
              (f: any) => e.reporttypeid === f.reporttypeid
            ).length > 0 &&
            e.workerlogsList[e.workerlogsList.length - 1].status ===
              WorkerLog.WorkerLogStatus.STATUS_STOPPED
            // &&
            // e.workstatus === ReportWorkStatus.WORK_IN_PROGRESS &&
            // moment(dateUpdated).isBefore(Date.now())
          )
            return true;
          else return false;
        } else return false;
      }).length;
    }
  };

  return (
    <div className="filter-box">
      <div className="filter-status-title">{t("name.filters")}</div>
      <div className="filter-status-subtitle">{t("name.status")}</div>
      <div className="semaphore-box">
        <div
          className={
            viewFilters.pending
              ? "item-box-selected item-box pending"
              : "item-box pending"
          }
          onClick={() => {
            updateFilter("pending", !viewFilters.pending);
          }}
        >
          {t("status.pending") + " (" + getCount(1) + ") "}
          <div className="circule"></div>
        </div>
        <div
          className={
            viewFilters.assigned
              ? "item-box-selected item-box in-assigned"
              : "item-box in-assigned"
          }
          onClick={() => {
            updateFilter("assigned", !viewFilters.assigned);
          }}
        >
          {t("status.assigned") + " (" + getCount(2) + ") "}
          <div className="circule"></div>
        </div>
        <div
          className={
            viewFilters.progress
              ? "item-box-selected item-box in-process"
              : "item-box in-process"
          }
          onClick={() => {
            updateFilter("progress", !viewFilters.progress);
          }}
        >
          {t("status.process") + " (" + getCount(3) + ") "}
          <div className="circule"></div>
        </div>
        <div
          className={
            viewFilters.completed
              ? "item-box-selected item-box completed"
              : "item-box completed"
          }
          onClick={() => {
            updateFilter("completed", !viewFilters.completed);
          }}
        >
          {t("status.completed") + " (" + getCount(4) + ") "}
          <div className="circule"></div>
        </div>
      </div>
      <div className="filter-status-subtitle problem-filter">
        {t("name.others.status")}
      </div>
      <div className="semaphore-box problem-box">
        <div
          className={
            viewFilters.problem
              ? "item-box-selected item-box problem"
              : "item-box problem"
          }
          onClick={() => {
            updateFilter("problem", !viewFilters.problem);
          }}
        >
          {t("status.problem") + " (" + getCount(5) + ") "}
          <img src={ProblemIcon} />
        </div>
        <div
          className={
            viewFilters.paused
              ? "item-box-selected item-box delayed"
              : "item-box delayed"
          }
          onClick={() => {
            updateFilter("paused", !viewFilters.paused);
          }}
        >
          {t("status.paused") + " (" + getCount(6) + ") "}
          <img src={DelayedIcon} />
        </div>
      </div>
      {maxWidht <= 1024 && (
        <>
          <div className="filter-status-subtitle problem-filter filter-show-mini">
            {t("name.others.creation")}
          </div>
          <div className="date-picker-reports filter-show-mini">
            <DateRangePicker
              locale={getCalendarLocale(t)}
              appearance="default"
              placeholder={t("calendar.title.placeholder")}
              style={{ width: 220 }}
              disabledDate={DateRangePicker.afterToday()}
              onChange={(
                date: ValueType,
                event: React.SyntheticEvent<HTMLElement>
              ) => {
                dispatch({
                  type: SET_RANGE_DATE,
                  from: moment(date[0]).startOf("day").utc(false).toDate(),
                  to: moment(date[1]).endOf("day").utc(false).toDate(),
                });
              }}
              onOk={(
                date: ValueType,
                event: React.SyntheticEvent<HTMLElement>
              ) => {
                dispatch({
                  type: SET_RANGE_DATE,
                  from: moment(date[0]).startOf("day").utc(false).toDate(),
                  to: moment(date[1]).endOf("day").utc(false).toDate(),
                });
              }}
              onClean={(event: React.SyntheticEvent<HTMLElement>) => {
                dispatch({
                  type: CLEAR_RANGE_DATE,
                });
              }}
            />
            <style>{style}</style>
          </div>
        </>
      )}
    </div>
  );
};

export default FilterStatus;
