import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../redux/Store";
import { useTranslation } from "react-i18next";
import "./style.scss";

import { grpcUpdateUser } from "../../redux/users/usersAction";
import { onlyLettersNumbersSpaces } from "../../utils/utils";

import TextFieldComponent from "../components/text-field-component";
import ActionButtons from "../components/Action-buttons";
import HeaderDialog from "../components/Header-dialog";

interface PerfilDialogProps {
  onCloseRequest: any;
}

export const PerfilDialog: React.FunctionComponent<PerfilDialogProps> = ({
  onCloseRequest,
}: any) => {
  const user: any = useSelector(
    (globalState: RootStore) => globalState.auth.user
  );

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [state, setState] = useState({
    name: user?.name ? user.name : "",
  });

  const handleForm = (name: any, event: any) => {
    setState((prevState) => ({
      ...prevState,
      [name]: event,
    }));
  };

  const updateProfile = () => {
    dispatch(
      grpcUpdateUser(
        {
          _id: user.id,
          username: user.username,
          preferredLanguage: user.preferredLanguage,
          imagePath: user?.imagePath || "",
          name: state.name.trim(),
          lastname: user.lastName,
          email: user.email,
          phoneNumber: user.phoneNumber,
          //NEED TO ADD ROLE FIELD
          role: user?.role || "",
          licenseId: user?.licenceId || "",
        },
        (response: any) => {
          if (response.success) {
            onCloseRequest();
            window.location.reload();
          }
        }
      )
    );
  };

  return (
    <div className="content-dialog dialog-perfil">
      <HeaderDialog
        onCloseRequest={onCloseRequest}
        title={t("title.profile.edit")}
        description={""}
        profile={true}
      ></HeaderDialog>
      <div className="content-options">
        <div className="content-options-body">
          <div className="options-body-item-big ">
            <div className="selector-box full-width-select">
              <TextFieldComponent
                placeholder={user !== null ? user.name : ""}
                onChange={(event: any) => {
                  handleForm("name", event);
                }}
                value={state.name}
                regExp={onlyLettersNumbersSpaces}
                invalid={false}
                maxValue={20}
                message="Correo invalido"
              ></TextFieldComponent>
            </div>
          </div>
          <ActionButtons
            doneText={t("submit.save")}
            clickable={state.name !== "" ? true : false}
            doneClick={updateProfile}
            cancelClick={onCloseRequest}
            cancelText={t("submit.cancel")}
          ></ActionButtons>
        </div>
      </div>
    </div>
  );
};

export default PerfilDialog;
