import React from "react";
// import FeatherIcon from "feather-icons-react";
// import { Arrow } from "../../assets/svg/index";

class TextFieldComponent extends React.Component {
  render() {
    return (
      <div className="selector-box-content">
        <input
          maxLength={
            this.props.maxValue !== undefined ? this.props.maxValue : undefined
          }
          type={this.props.type !== undefined ? this.props.type: "text"}
          placeholder={this.props.placeholder}
          className={this.props.invalid ? "text-field-invalid" : "text-field"}
          onChange={(data) => {
            if (
              this.props.regExp === undefined ||
              this.props.regExp.test(data.target.value) ||
              data.target.value === ''
            ) {
              this.props.onChange(data.target.value);
            }
          }
          }
          value={this.props.value}
          autoComplete="off"
          disabled={
            this.props.disabled !== undefined ? this.props.disabled : false
          }
        />
        {this.props.invalid && (
          <span className="error-message">{this.props.message}</span>
        )}
      </div>
    );
  }
}

export default TextFieldComponent;
