import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../redux/Store";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";

import { grpcUpdateSetting } from "../../../redux/licenses/licensesActions";
import { licenseI } from "../../../redux/licenses/licensesReducer";

import { Edit } from "../../../assets/svg/index";
import LayerControlComponent from "../../../components/general-component/layerControl";
import ConfirmationDialog from "../../dialogs/confirmation-dialog";
import SimpleModal from "../../../utils/dialogs/simple-dialog";

import { Map } from "react-leaflet";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "@geoman-io/leaflet-geoman-free";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

const colorActive = `rgba(78, 205, 196, .5)`;
const colorBaseActive = `rgba(78, 205, 196, 1)`;

interface TileAreaProps {
  fetchAllLicenses: any;
}

export const TileArea: React.FunctionComponent<TileAreaProps> = ({
  fetchAllLicenses,
}: any) => {
  const selectedLicense: licenseI | undefined = useSelector(
    (globalState: RootStore) => globalState.licenses.selectedLicense
  );
  const layer: any = useSelector(
    (globalState: RootStore) => globalState.licenses.layer
  );

  let defaultValue: any;
  let defaultLayersGeo: any[] = [];

  const [state, setState] = useState({
    coords: {
      lng: -117.010139,
      lat: 32.805197,
    },
    zoom: 16,
    options: {
      clickableIcons: false,
    },
    editMode: false,
  });

  useEffect(() => {
    if (selectedLicense) {
      setState((prev) => ({
        ...prev,
        coords: selectedLicense?.licenseSettings?.coords
          ? selectedLicense.licenseSettings.coords
          : {
              lng: -117.010139,
              lat: 32.805197,
            },
        zoom: selectedLicense?.licenseSettings?.zoom
          ? selectedLicense.licenseSettings.zoom
          : 16,
      }));
    }
  }, [selectedLicense]);

  const [showDelete, setShowDelete] = useState(false);
  const [selected, setSelected] = useState(defaultValue);
  const [layerToSave, setLayerToSave] = useState(
    layer && layer[0] ? layer : defaultValue
  );
  const [layersGeoJson, setLayersGeoJson] = useState(defaultLayersGeo);
  const [listBounds, setListBounds] = useState(defaultLayersGeo);
  const [bounds, setBounds] = useState(defaultValue);

  const leafletMapRef = React.useRef<Map>(null);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const changeToEdit = () => {
    setState((prevState) => ({
      ...prevState,
      editMode: !state.editMode,
    }));
  };

  const handleToggleModalDelete = (value: any) => {
    setShowDelete(false);

    if (value) {
      setSelected(defaultValue);
      setLayerToSave(defaultValue);
    }
  };

  const updateProfile = (layer: any) => {
    if (state.editMode) {
      let auxArray: any = [];

      if (selectedLicense && layer) {
        let coordinates = layer[0]?.coordinates
          ? layer[0]?.coordinates[0]
          : layer[0]?.geometry.coordinates[0];

        coordinates.map((coord: any) => {
          auxArray.push({
            lat: coord[0],
            lng: coord[1],
          });
        });
      }

      dispatch(
        grpcUpdateSetting(
          {
            _id: selectedLicense?.settingsId,
            licenseId: selectedLicense?._id,
            cities: selectedLicense?.cities,

            points: auxArray,

            distanceInterval:
              selectedLicense?.trackingSettings.distanceInterval,
            timeInterval: selectedLicense?.trackingSettings.timeInterval,
            enable: selectedLicense?.trackingSettings.enable,
            trackingType: selectedLicense?.trackingSettings.trackingType,
            trackingMode: selectedLicense?.trackingSettings.trackingMode,

            zoom: selectedLicense?.licenseSettings.zoom,
            coords: {
              lng: selectedLicense?.licenseSettings.coords.lat,
              lat: selectedLicense?.licenseSettings.coords.lng,
            },
            jobTypes: selectedLicense?.licenseSettings.jobTypes,
            daysAdmin: selectedLicense?.licenseSettings.reportsDaysForAdmin,
            daysTeam: selectedLicense?.licenseSettings.reportsDaysForTeam,
            daysUser: selectedLicense?.licenseSettings.reportsDaysForUser,
          },
          () => {
            fetchAllLicenses();
          }
        )
      );
    }
  };

  const [style, updateStyle] = useState(`
    .leaflet-pm-toolbar .leaflet-pm-icon-polygon::after {
      content: '${t("mc.area.lbl.draw")}';
    } .button-container.active .leaflet-pm-actions-container .action-removeLastVertex::after{
      content: '${t("mc.area.lbl.undo")}';
    }
    .leaflet-left{
      display: ${state.editMode && !layerToSave ? "block" : "none"};
    }
  `);

  useEffect(() => {
    updateStyle(`
      .leaflet-pm-toolbar .leaflet-pm-icon-polygon::after {
        content: '${t("mc.area.lbl.draw")}';
      }
      .button-container.active .leaflet-pm-actions-container .action-removeLastVertex::after{
        content: '${t("mc.area.lbl.undo")}';
      }
      .leaflet-left{
        display: ${state.editMode && !layerToSave ? "block" : "none"};
      }
    `);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, state.editMode, layerToSave]);

  //*CREATE LAYER FOR MAP*
  const AddLayer = (polygon: any, index: number) => {
    const mapElement = leafletMapRef.current?.leafletElement;
    if (mapElement) {
      return L.geoJSON(
        { ...polygon, index: index + 1 },
        {
          style: {
            weight: 0,
            opacity: 0,
            color: colorBaseActive,
            dashArray: "",
            fillOpacity: 1,
            fillColor: colorActive,
          },
          onEachFeature: (feature, layer) => {
            layer
              .addTo(mapElement)
              .bindTooltip(String(index + 1), {
                permanent: true,
                direction: "auto",
                className: "my-labels",
              })
              .openTooltip();

            layer.on("pm:edit", (e: any) => {
              setBounds(undefined);
              if (e.target && e.target.pm) {
                setLayersGeoJson((prevState: any) => {
                  const array1: any = [];
                  prevState.forEach((d: any) => {
                    if (d._id === e.target.feature._id) {
                      const lay = e.layer.toGeoJSON();
                      array1.push({
                        ...lay,
                        coordinates: lay.geometry.coordinates,
                        originalCoordinates: lay.geometry,
                      });
                    } else {
                      array1.push(d);
                    }
                  });
                  return array1;
                });
                setSelected((prevState: any) => {
                  if (prevState?.feature._id === e.target.feature._id) {
                    return {
                      ...e.target,
                      feature: { ...e.target.feature, ...e.target.toGeoJSON() },
                    };
                  } else return prevState;
                });
              }
            });
            layer.on("pm:cut", function (e: any) {
              if (e.target && e.target.pm) {
                layer.off("pm:edit");
                setLayersGeoJson((prevState: any) => {
                  const array1: any = [];

                  prevState.forEach((d: any) => {
                    if (d._id === e.target.feature._id) {
                      const lay = e.layer.toGeoJSON();
                      const value = {
                        ...e.target.feature,
                        ...lay,
                      };
                      array1.push(value);
                    } else {
                      array1.push(d);
                    }
                  });
                  return [array1];
                });
              }
            });
            layer.on("click", function (e) {
              setBounds(undefined);
              setSelected((prevState: any) => {
                if (prevState?.setStyle) {
                  prevState.unbindTooltip();
                  prevState
                    .bindTooltip(String(prevState.feature.index), {
                      permanent: true,
                      direction: "center",
                      className: "my-labels",
                    })
                    .openTooltip();
                }
                e.target.unbindTooltip();
                e.target
                  .bindTooltip(String(e.target.feature.index), {
                    permanent: true,
                    direction: "center",
                    className: `my-labels selected-${e.target.feature._id}`,
                  })
                  .openTooltip();
                e.target.setStyle({
                  weight: 2,
                  opacity: 1,
                  color: colorBaseActive,
                  dashArray: "",
                  fillOpacity: 1,
                  fillColor: colorActive,
                });
                prevState?.pm.disable();
                return e.target;
              });
              e.target.pm.enable();
            });
            if ((layer as any).feature._id === selected?.feature._id) {
              layer.fireEvent("click");
            }
          },
        }
      ).getBounds();
    } else return null;
  };

  //*SET MAP FUNCTIONS*
  useEffect(() => {
    if (leafletMapRef.current) {
      const mapElement = leafletMapRef.current.leafletElement;

      (mapElement as any).pm.setGlobalOptions({ pmIgnore: false });
      (mapElement as any).pm.setLang(i18n.language);

      (mapElement as any).pm.addControls({
        drawMarker: false,
        drawCircle: false,
        drawCircleMarker: false,
        drawPolyline: false,
        drawRectangle: false,
        drawPolygon: true,
        editMode: false,
        dragMode: false,
        cutPolygon: false,
        removalMode: false,
        rotateMode: false,
        zoomMode: false,
      });
      mapElement.off("pm:create");
      mapElement.on("pm:create", (e: any) => {
        if (e.target && e.target.pm) {
          setBounds(undefined);
          setLayerToSave([e.layer.toGeoJSON().geometry.coordinates[0]]);
        }
      });
      mapElement.off("pm:remove");
      mapElement.on("pm:remove", (e: any) => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leafletMapRef.current, i18n.language]);

  //*LISTEN LICENSE GEOMETRY*
  useEffect(() => {
    const layersGeoJsonL: any[] = [];
    if (layerToSave?.length > 0) {
      const colorActive = `rgba(78, 205, 196, .5)`;
      const colorBaseActive = `rgba(78, 205, 196, 1)`;

      layersGeoJsonL.push({
        color: colorActive,
        colorBase: colorBaseActive,
        _id: selectedLicense?._id,
        geometry: { type: "Polygon", coordinates: layerToSave },
        type: "Feature",
      });
    }

    setLayersGeoJson(layersGeoJsonL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layerToSave]);

  //*SET BOUNDS WITH GEOMETRY*
  useEffect(() => {
    const mapElement = leafletMapRef.current?.leafletElement;
    if (mapElement) {
      mapElement.eachLayer((layer: any) => {
        if (
          layer._url === undefined &&
          layer._bounds !== undefined &&
          layer._events !== undefined &&
          layer instanceof L.Layer
        )
          layer.remove();
      });

      const array: any = [];
      layersGeoJson?.forEach((e: any, index: number) => {
        let bound = defaultValue;
        if (e) bound = AddLayer(e, index);
        if (bound) array.push({ _id: e._id, bound });
      });
      setListBounds(array);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layersGeoJson]);

  //*SET BOUNDS WITH GEOMETRY*
  useEffect(() => {
    if (listBounds.length > 0) {
      var bounds = L.latLngBounds([]);

      listBounds.forEach((e: any) => {
        bounds.extend(e.bound);
      });

      if (bounds.isValid()) setBounds(bounds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listBounds]);

  return (
    <div className="tile-card map-card">
      <div className="tile-card-header">
        <div className="tile-title">{t("tabs.title.map")}</div>
        {!state.editMode ? (
          <div className="tile-edit-button" onClick={changeToEdit}>
            <Edit></Edit>
          </div>
        ) : (
          <div>
            {" "}
            <button
              className="btn-done-dialog-admin"
              onClick={() => {
                updateProfile(
                  layersGeoJson?.length > 0 ? layersGeoJson : defaultValue
                );
              }}
            >
              {t("name.save.changes")}
            </button>
          </div>
        )}
      </div>
      <div className="tile-card-body">
        <div className={!state.editMode ? "hide-controls map-tab" : " map-tab"}>
          <Map
            style={{ height: "100%", width: "100%" }}
            ref={leafletMapRef}
            center={
              selectedLicense?.licenseSettings?.coords
                ? {
                    lng: selectedLicense.licenseSettings.coords.lat,
                    lat: selectedLicense.licenseSettings.coords.lng,
                  }
                : {
                    lng: -117.010139,
                    lat: 32.805197,
                  }
            }
            zoom={
              selectedLicense?.licenseSettings?.zoom
                ? selectedLicense.licenseSettings.zoom
                : 16
            }
            zoomControl={false}
            bounds={bounds || undefined}
            boundsOptions={{ padding: [0, 0] }}
          >
            <style>{style}</style>
            <LayerControlComponent></LayerControlComponent>

            {selected && (
              <button
                className="delete-button"
                onClick={() => {
                  setShowDelete(true);
                }}
              >
                {t("mc.area.lbl.delete")}
                {/* <DeleteIcon></DeleteIcon> */}
              </button>
            )}
          </Map>
        </div>
        {!state.editMode && <div className="block-map"></div>}
      </div>
      {showDelete && (
        <SimpleModal>
          <ConfirmationDialog
            t={t}
            doneClick={() => {
              handleToggleModalDelete(true);
            }}
            description={t("notification.area.removal")}
            onCloseRequest={() => {
              handleToggleModalDelete(false);
            }}
          ></ConfirmationDialog>
        </SimpleModal>
      )}
    </div>
  );
};

export default TileArea;
