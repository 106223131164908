import React from "react";
// import FeatherIcon from "feather-icons-react";
import { Arrow } from "../../assets/svg/index";

class SelectComponent extends React.Component {
  state = {
    visible: false
  };

  render() {
    const selectValue = item => {
      this.props.selectTeam(item);
      this.setState({
        visible: false
      });
    };
    return (
      <div className="selector-box-content">
        <button
          className="selector"
          onClick={() => {
            this.setState({ visible: !this.state.visible });
          }}
        >
          <p>
            {this.props.selected == null
              ? this.props.initialData
              : this.props.selected.name}
          </p>
          <Arrow></Arrow>
        </button>
        <div
          className={` ${
            this.state.visible ? "options-visible" : "options-invisible"
          } selector-options`}
        >
          {this.props.data.map(function(item, i) {
            return (
              <button
                key={i}
                className="dropdown-item"
                type="button"
                onClick={() => {
                  selectValue(item);
                }}
              >
                {item.name}
              </button>
            );
          })}
        </div>
        {this.state.visible && (
            <div 
              className="hidden-wall" 
              onClick={() => {
                this.setState({ visible: !this.state.visible });
              }}>  
            </div>
        )}
      </div>
    );
  }
}

export default SelectComponent;
