import React, { useState } from "react";
import "./style.scss";
import { NavLink, useLocation } from "react-router-dom";
import {
  // superAdmin,
  adminServices,
  // adminCashier,
  adminMayor,
} from "../../const/const";
import { licenseI } from "../../redux/licenses/licensesReducer";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RootStore } from "../../redux/Store";
interface NavBarComponentProps {
  typeOfUser: string;
}

export const NavBar: React.FunctionComponent<NavBarComponentProps> = ({
  typeOfUser,
}: NavBarComponentProps) => {
  const [visible, setState] = useState<boolean>(false);
  const selectedLicense: licenseI | undefined = useSelector(
    (globalState: RootStore) => globalState.licenses.selectedLicense
  );
  const { t } = useTranslation();

  const location = useLocation();
  const addMenu = () => {
    setState(true);
  };

  const removeMenu = () => {
    setState(false);
  };

  /// Nav
  return (
    <div>
      <div id="navbar" className="navBar">
        <div className="logo-item">
          <div className="main-logo" />
        </div>
        <div>
          <NavLink
            to="/"
            className={
              (location.pathname === "/" ? "active-menu" : "") +
              " nav-item dashboard"
            }
          >
            <div className="logo" />
            <p className="title-menu">{t("title.dashboard")}</p>
          </NavLink>

          <NavLink
            to="/reports"
            className={
              (location.pathname === "/reports" ||
              location.pathname === "/reports/pendientes" ||
              location.pathname === "/reports/enproceso" ||
              location.pathname === "/reports/solucionado"
                ? "active-menu"
                : "") + " nav-item reports"
            }
            // onMouseEnter={this.addMenu}
            // onMouseLeave={this.removeMenu}
          >
            <div className="logo" />
            <p className="title-menu">{t("title.reports")}</p>
          </NavLink>
          <NavLink
            to="/routing"
            activeClassName="active-menu"
            exact
            className="nav-item calendar"
          >
            <div className="logo" />
            <p className="title-menu">{t("title.calendar")}</p>
          </NavLink>
          <NavLink
            to="/trackerMap"
            className={
              (location.pathname === "/trackerMap" ? "active-menu" : "") +
              " nav-item routesMap"
            }
            // onMouseEnter={this.addMenu}
            // onMouseLeave={this.removeMenu}
          >
            <div className="logo" />
            <p className="title-menu">{t("title.route")}</p>
          </NavLink>
          <NavLink
            to="/workers"
            activeClassName="active-menu"
            exact
            className="nav-item users"
          >
            <div className="logo" />
            <p className="title-menu">{t("title.workers")}</p>
          </NavLink>
          <NavLink
            to="/teams"
            activeClassName="active-menu"
            exact
            className="nav-item teams"
          >
            <div className="logo" />
            <p className="title-menu">{t("title.teams")}</p>
          </NavLink>

          <NavLink
            to="/blocked"
            activeClassName="active-menu"
            exact
            className="nav-item blocked"
          >
            <div className="logo" />
            <p className="title-menu">{t("title.users")}</p>
          </NavLink>
        </div>
      </div>
      <div
        className={` ${visible ? "enable" : "disable"} drop-menu`}
        onMouseEnter={addMenu}
        onMouseLeave={removeMenu}
      >
        <NavLink to="/reports/pendientes">
          <div
            className={
              (location.pathname === "/reports/pendientes"
                ? "drop-item-selected"
                : "") + " drop-item"
            }
          >
            <p>Pendientes</p>
          </div>
        </NavLink>
        <NavLink to="/reports/enproceso">
          <div
            className={
              (location.pathname === "/reports/enproceso"
                ? "drop-item-selected"
                : "") + " drop-item"
            }
          >
            <p>En Proceso</p>
          </div>
        </NavLink>
        <NavLink to="/reports/solucionado">
          <div
            className={
              (location.pathname === "/reports/solucionado"
                ? "drop-item-selected"
                : "") + " drop-item"
            }
          >
            <p>Solucionado</p>
          </div>
        </NavLink>
      </div>
    </div>
  );
};

export default NavBar;
