import React from "react";
// import FeatherIcon from "feather-icons-react";
// import { Arrow } from "../../assets/svg/index";

class TextAreaFieldComponent extends React.Component {
  render() {
    return (
      <div className="selector-box-content">
        <textarea
          placeholder={this.props.placeholder}
          className={this.props.invalid ? "text-field-invalid" : "text-field"}
          onChange={this.props.onChange}
          value={this.props.value}
          onKeyDown={
            this.props.onKeyDown !== undefined ? this.props.onKeyDown : null
          }
        />
        {this.props.invalid && (
          <span className="error-message">{this.props.message}</span>
        )}
      </div>
    );
  }
}

export default TextAreaFieldComponent;
