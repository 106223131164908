import React from "react";
import { User } from "../../../server/user/protos/user_pb";

interface WorkerReportProps {
  worker: User.AsObject;
  assignedWorkers: any;
}

export const WorkerReport: React.FunctionComponent<WorkerReportProps> = ({
  worker,
  assignedWorkers,
}: WorkerReportProps) => {
  return (
    <div
      className={
        assignedWorkers.indexOf(worker.id) !== -1
          ? "worker-box worker-assigned-box"
          : "worker-box"
      }
    >
      {worker.imagePath ? (
        <img src={worker.imagePath} alt="" />
      ) : (
        <img
          src="https://miciudad.s3-us-west-1.amazonaws.com/profile.png"
          alt=""
        />
      )}
      <strong>
        {worker.name}
        {" " + worker.lastName}
      </strong>
    </div>
  );
};

export default WorkerReport;
