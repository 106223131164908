import React from "react";
import AuthUserContext from "./AuthUserContext";
import * as io from "socket.io-client";
import {
  superAdmin,
  adminServices,
  adminCashier,
  adminMayor,
} from "../const/const";
import moment from "moment";
import { authAWS } from "../firebase";

const withAuthentication = (Component) =>
  class WithAuthentication extends React.Component {
    state = {
      authUser: authAWS.getUser(),
      loading: true,
      loadingUser: true,
      loadingDistrict: true,
      district: null,
      superAdmin: false,
      superData: null,
      adminsOfaDistrict: [],
      notifications: [],
      listOfDistricts: [],
      reports: [],
      teams: [],
      users: [],
      workers: [],
      guests: [],
      statistics: null,
      statisticsOld: null,
      subscriptions: [],
      deleted: false,
      mounted: null,
      socket: null,
      rooms: [],
      trackers: [],
      trackersYesterday: [],
      reportTypes: [],
      actualPageProfile: 0,
      selectedTeam: null,
      messagesChat: [],
      chats: [],
      activeDates: [],
      countStatistics: null,
      kanbanKeys: [
        Math.random().toString(36).substring(7),
        Math.random().toString(36).substring(7),
        Math.random().toString(36).substring(7),
      ],
      filtersSelected: [],
      showTime: true,
    };

    // componentDidMount() {
    //   authAWS.isAuthenticated();
    //   authAWS.session.subscribe((authUser) => {
    //     if (authUser !== null && authUser) {
    //       if (this.state.mounted === null || !this.state.mounted)
    //         this.checkUserInSessionOrFetchUser(authAWS.getUser());
    //     } else if (
    //       authUser === null ||
    //       this.state.mounted === null ||
    //       this.state.mounted
    //     )
    //       this.setState(
    //         {
    //           authUser: null,
    //           loadingUser: false,
    //           mounted: false,
    //         },
    //         () => {
    //           console.log("checkUserInSessionOrFetchUser SetState");
    //         }
    //       );
    //   });
    //   authAWS.sessionLogout.subscribe((value) => {
    //     if (value)
    //       this.setState(
    //         {
    //           authUser: null,
    //           loadingUser: false,
    //           mounted: false,
    //         },
    //         () => {}
    //       );
    //   });
    // }
    updatePageProfile = (e) => {
      this.setState({
        actualPageProfile: e,
      });
    };

    // TODO: CHECK REFRESH TOKEN
    // checkUserInSessionOrFetchUser = (authUser) => {
    //   netWorking.refreshToken(() => {
    //     this.setState(
    //       {
    //         authUser: authUser,
    //       },
    //       () => {
    //         if (authUser.typeOfUser === superAdmin) {
    //           this.setState(
    //             {
    //               superAdmin: true,
    //               loading: true,
    //               loadingDistrict: true,
    //               loadingUser: false,
    //               superData: authUser,
    //             },
    //             () => {
    //               this.fetchAllDistricts();
    //             }
    //           );
    //         }
    //         if (authUser.typeOfUser === adminServices) {
    //           this.setState(
    //             {
    //               loadingUser: false,
    //               superAdmin: false,
    //               loading: true,
    //               loadingDistrict: true,
    //             },
    //             () => {
    //               this.fetchDistrict(() => {
    //                 this.fetchData(authUser.districtId);
    //               }, authUser.districtId);
    //             }
    //           );
    //         }
    //         if (authUser.typeOfUser === adminCashier) {
    //           this.setState(
    //             {
    //               loadingUser: false,
    //               superAdmin: false,
    //               loading: true,
    //               loadingDistrict: true,
    //             },
    //             () => {
    //               this.fetchDistrict(() => {
    //                 this.fetchData(authUser.districtId);
    //               }, authUser.districtId);
    //             }
    //           );
    //         }
    //         if (authUser.typeOfUser === adminMayor) {
    //           this.setState(
    //             {
    //               loadingUser: false,
    //               superAdmin: false,
    //               loading: true,
    //               loadingDistrict: true,
    //             },
    //             () => {
    //               this.fetchDistrict(() => {
    //                 this.fetchData(authUser.districtId);
    //               }, authUser.districtId);
    //             }
    //           );
    //         }
    //       }
    //     );
    //   });
    // };

    changeShowTime = () => {
      this.setState({
        showTime: !this.state.showTime,
      });
    };

    changeFilter = (e) => {
      if (e === null) {
        this.setState(
          {
            filtersSelected: [],
          },
          () => {}
        );
      } else {
        var array = this.state.filtersSelected;
        const index = array.indexOf(e);
        if (index !== -1) {
          array.splice(index, 1);
        } else {
          array.push(e);
        }
        this.setState(
          {
            filtersSelected: array,
          },
          () => {}
        );
      }
    };

    changeSelectedTeam = (selected) => {
      this.setState({
        selectedTeam: selected,
      });
    };
    /**
     *
     * @memberof App
     *
     *  Used to fetch all de data from firebase at the creation of the app.
     *
     */
    fetchData = (id, superAdmin) => {
      this.fetchSettings();
      this.fetchReports(id);
      this.fetchStatistics(id);
      this.getCountStatistics(id);
      this.fetchStatisticsOld(id);
      this.fetchTeams(id);
      this.fetchTrackersYesterday(id);
      this.fetchWorkers(id);
      this.fetchTrackers(id);
      this.getActiveDates(id);
      // this.fetchChatsAndMessages(id);
      if (superAdmin) this.getAdminsOfaDistrict(id);

      this.clearSocket(() => {
        this.checkSocket(() => {});
      });
      // this.checkSocket(() => {});
      // this.fetchUsers(id);
      // this.fetchGuests(id);
      //district
    };

    fetchSettings = () => {
      this.fetchReportTypes();
    };

    // TODO: REPORT TYPES
    fetchReportTypes = () => {
      this.setState({ loadingDistrict: true }, () => {
        // netWorking.get(netWorking.reportTypes, (result) => {
        //   this.setState(
        //     {
        //       reportTypes: result.data.response,
        //       loadingReports: false,
        //       loadingDistrict: false,
        //     },
        //     () => {}
        //   );
        // });
      });
    };

    // TODO: ACTIVE DATES
    getActiveDates = (id) => {
      // netWorking.get(netWorking.activeDates + id, (result) => {
      //   this.setState(
      //     {
      //       activeDates: result.data.response,
      //     },
      //     () => {}
      //   );
      // });
    };
    // TODO: SET DISTRICT
    setDistrict = (e, district, fun) => {
      // if (e === null) {
      //   var listOfDistricts = this.state.listOfDistricts;
      //   if (district !== null) {
      //     let districtIndex = -1;
      //     this.state.listOfDistricts.forEach((e, index) => {
      //       if (e._id === district._id) {
      //         districtIndex = index;
      //       }
      //     });
      //     if (districtIndex !== -1) listOfDistricts.splice(districtIndex, 1);
      //   }
      //   this.setState(
      //     {
      //       district: null,
      //       deleted: true,
      //       adminsOfaDistrict: [],
      //       listOfDistricts: listOfDistricts,
      //     },
      //     () => {
      //       if (fun !== undefined) fun();
      //     }
      //   );
      // } else {
      //   this.setState(
      //     {
      //       district: e,
      //       loadingDistrict: false,
      //       adminsOfaDistrict: [],
      //     },
      //     () => {
      //       // this.state.subscriptions.forEach((e) => e());
      //       this.fetchDistrict(() => {
      //         this.fetchData(e._id, true);
      //       }, e._id);
      //     }
      //   );
      // }
    };

    // TODO: CHECKSOCKET
    checkSocket = (fun) => {
      // if (
      //   this.state.socket === null &&
      //   authAWS.getAccessToken() !== undefined &&
      //   authAWS.getAccessToken() !== ""
      // ) {
      //   const s = io(netWorking.URLWebSocket, {
      //     query: {
      //       token: authAWS.getAccessToken(),
      //     },
      //   });
      //   s.on("onSocket", (response) => {
      //     var rooms = this.state.rooms;
      //     rooms.forEach((e) => {
      //       s.off(e);
      //     });
      //     rooms = [];
      //     console.log("connected");
      //     rooms.push("reportTypes");
      //     rooms.push("district" + this.state.district._id);
      //     rooms.push("reports" + this.state.district._id);
      //     rooms.push("statistics" + this.state.district._id);
      //     rooms.push("teams" + this.state.district._id);
      //     rooms.push("workers" + this.state.district._id);
      //     rooms.push("tracker" + this.state.district._id);
      //     rooms.push("chats" + authAWS.getId());
      //     rooms.push("messageChat" + authAWS.getId());
      //     this.setState(
      //       {
      //         rooms: rooms,
      //         socket: s,
      //       },
      //       () => {
      //         this.setRooms();
      //       }
      //     );
      //   });
      //   s.on("UnAuthenticated", (response) => {
      //     console.log(response);
      //   });
      //   s.on("disconnect", (reason) => {
      //     console.log(reason === "ping timeout");
      //     console.log("disconnect");
      //   });
      // } else {
      //   fun();
      // }
    };

    // TODO: SET ROOMS
    setRooms = () => {
      // District
      // this.state.socket.emit("join", [
      //   {
      //     token: authAWS.getAccessToken(),
      //     room: "district" + this.state.district._id,
      //   },
      // ]);
      // this.state.socket.on("district" + this.state.district._id, (response) => {
      //   this.setState(
      //     {
      //       district: response.data,
      //     },
      //     () => {
      //       if (!this.state.superAdmin && this.state.district.blocked) {
      //         authAWS.logout();
      //       }
      //     }
      //   );
      // });
      // ///Report Types
      // this.state.socket.emit("join", [
      //   {
      //     token: authAWS.getAccessToken(),
      //     room: "reportTypes",
      //   },
      // ]);
      // this.state.socket.on("reportTypes", (response) => {
      //   var reportTypes = this.state.reportTypes;
      //   if (response.typeOfData === "update") {
      //     let reportIndex = -1;
      //     this.state.reportTypes.forEach((e, index) => {
      //       if (e._id === response.data._id) {
      //         reportIndex = index;
      //       }
      //     });
      //     if (reportIndex !== -1) {
      //       reportTypes.splice(reportIndex, 1);
      //       reportTypes.push(response.data);
      //     }
      //   }
      //   if (response.typeOfData === "create") {
      //     let reportIndex = -1;
      //     this.state.reportTypes.forEach((e, index) => {
      //       if (e._id === response.data._id) {
      //         reportIndex = index;
      //       }
      //     });
      //     if (reportIndex !== -1) {
      //       reportTypes.splice(reportIndex, 1);
      //       reportTypes.push(response.data);
      //     } else reportTypes.push(response.data);
      //   }
      //   if (response.typeOfData === "delete") {
      //     let reportIndex = -1;
      //     this.state.reportTypes.forEach((e, index) => {
      //       if (e._id === response.data._id) {
      //         reportIndex = index;
      //       }
      //     });
      //     if (reportIndex !== -1) reportTypes.splice(reportIndex, 1);
      //   }
      //   this.setState({
      //     reportTypes: reportTypes,
      //   });
      // });
      // /// Reports
      // this.state.socket.emit("join", [
      //   {
      //     token: authAWS.getAccessToken(),
      //     room: "reports" + this.state.district._id,
      //   },
      // ]);
      // this.state.socket.on("reports" + this.state.district._id, (response) => {
      //   var reports = this.state.reports;
      //   this.getCountStatistics(this.state.district._id);
      //   if (response.typeOfData === "update") {
      //     let reportIndex = -1;
      //     this.state.reports.forEach((e, index) => {
      //       if (e._id === response.data._id) {
      //         reportIndex = index;
      //       }
      //     });
      //     if (reportIndex !== -1) {
      //       reports.splice(reportIndex, 1);
      //       reports.push(response.data);
      //     } else {
      //       reports.push(response.data);
      //     }
      //   }
      //   if (response.typeOfData === "create") {
      //     let reportIndex = -1;
      //     this.state.reports.forEach((e, index) => {
      //       if (e._id === response.data._id) {
      //         reportIndex = index;
      //       }
      //     });
      //     if (reportIndex !== -1) {
      //       reports.splice(reportIndex, 1);
      //       reports.push(response.data);
      //     } else reports.push(response.data);
      //   }
      //   if (response.typeOfData === "delete") {
      //     let reportIndex = -1;
      //     this.state.reports.forEach((e, index) => {
      //       if (e._id === response.data._id) {
      //         reportIndex = index;
      //       }
      //     });
      //     if (reportIndex !== -1) reports.splice(reportIndex, 1);
      //   }
      //   this.setState({
      //     reports: reports.sort((a, b) => {
      //       return moment(a.created).toDate() - moment(b.created).toDate();
      //     }),
      //   });
      // });
      // ///Statistics
      // this.state.socket.emit("join", [
      //   {
      //     token: authAWS.getAccessToken(),
      //     room: "statistics" + this.state.district._id,
      //   },
      // ]);
      // this.state.socket.on(
      //   "statistics" + this.state.district._id,
      //   (response) => {
      //     var statistics = this.state.statistics;
      //     if (response.typeOfData === "update") {
      //       statistics = response.data;
      //     }
      //     if (response.typeOfData === "create") {
      //       statistics = response.data;
      //     }
      //     if (response.typeOfData === "delete") {
      //       statistics = response.data;
      //     }
      //     this.setState({
      //       statistics: statistics,
      //     });
      //   }
      // );
      // ///Teams
      // this.state.socket.emit("join", [
      //   {
      //     token: authAWS.getAccessToken(),
      //     room: "teams" + this.state.district._id,
      //   },
      // ]);
      // this.state.socket.on("teams" + this.state.district._id, (response) => {
      //   let teams = this.state.teams;
      //   console.log("executed Teamsss!!!  !!! !! ");
      //   if (response.typeOfData === "update") {
      //     let teamIndex = -1;
      //     this.state.teams.forEach((e, index) => {
      //       if (e._id === response.data._id) {
      //         teamIndex = index;
      //       }
      //     });
      //     if (teamIndex !== -1) {
      //       teams.splice(teamIndex, 1);
      //       teams.push(response.data);
      //     }
      //   }
      //   if (response.typeOfData === "create") {
      //     let teamIndex = -1;
      //     this.state.teams.forEach((e, index) => {
      //       if (e._id === response.data._id) {
      //         teamIndex = index;
      //       }
      //     });
      //     if (teamIndex !== -1) {
      //       teams.splice(teamIndex, 1);
      //       teams.push(response.data);
      //     } else {
      //       teams.push(response.data);
      //     }
      //   }
      //   if (response.typeOfData === "delete") {
      //     let teamIndex = -1;
      //     this.state.teams.forEach((e, index) => {
      //       if (e._id === response.data._id) {
      //         teamIndex = index;
      //       }
      //     });
      //     if (teamIndex !== -1) teams.splice(teamIndex, 1);
      //   }
      //   this.setState({
      //     teams: teams.sort((a, b) => a.name.localeCompare(b.name)),
      //   });
      // });
      // ///Workers
      // this.state.socket.emit("join", [
      //   {
      //     token: authAWS.getAccessToken(),
      //     room: "workers" + this.state.district._id,
      //   },
      // ]);
      // this.state.socket.on("workers" + this.state.district._id, (response) => {
      //   var workers = this.state.workers;
      //   if (response.typeOfData === "update") {
      //     let workerIndex = -1;
      //     this.state.workers.forEach((e, index) => {
      //       if (e._id === response.data._id) {
      //         workerIndex = index;
      //       }
      //     });
      //     if (workerIndex !== -1) {
      //       workers.splice(workerIndex, 1);
      //       workers.push(response.data);
      //     }
      //   }
      //   if (response.typeOfData === "create") {
      //     let workerIndex = -1;
      //     this.state.workers.forEach((e, index) => {
      //       if (e._id === response.data._id) {
      //         workerIndex = index;
      //       }
      //     });
      //     if (workerIndex !== -1) {
      //       workers.splice(workerIndex, 1);
      //       workers.push(response.data);
      //     } else workers.push(response.data);
      //   }
      //   if (response.typeOfData === "delete") {
      //     let workerIndex = -1;
      //     this.state.workers.forEach((e, index) => {
      //       if (e._id === response.data._id) {
      //         workerIndex = index;
      //       }
      //     });
      //     if (workerIndex !== -1) workers.splice(workerIndex, 1);
      //   }
      //   this.setState({
      //     workers: workers.sort((a, b) => a.name.localeCompare(b.name)),
      //   });
      // });
      // ///Trackers
      // this.state.socket.emit("join", [
      //   {
      //     token: authAWS.getAccessToken(),
      //     room: "tracker" + this.state.district._id,
      //   },
      // ]);
      // this.state.socket.on("tracker" + this.state.district._id, (response) => {
      //   var trackers = this.state.trackers;
      //   if (response.typeOfData === "update") {
      //     let trackerIndex = -1;
      //     this.state.trackers.forEach((e, index) => {
      //       if (e._id === response.data._id) {
      //         trackerIndex = index;
      //       }
      //     });
      //     if (trackerIndex !== -1) {
      //       trackers.splice(trackerIndex, 1);
      //       trackers.push(response.data);
      //     }
      //   }
      //   if (response.typeOfData === "create") {
      //     let trackerIndex = -1;
      //     this.state.trackers.forEach((e, index) => {
      //       if (e._id === response.data._id) {
      //         trackerIndex = index;
      //       }
      //     });
      //     if (trackerIndex !== -1) {
      //       trackers.splice(trackerIndex, 1);
      //       trackers.push(response.data);
      //     } else trackers.push(response.data);
      //   }
      //   if (response.typeOfData === "delete") {
      //     let trackerIndex = -1;
      //     this.state.trackers.forEach((e, index) => {
      //       if (e._id === response.data._id) {
      //         trackerIndex = index;
      //       }
      //     });
      //     if (trackerIndex !== -1) trackers.splice(trackerIndex, 1);
      //   }
      //   this.setState({
      //     trackers: trackers,
      //   });
      // });
      // // "messageChat" + authAWS.getId()
      // ///Chat
      // this.state.socket.emit("join", [
      //   {
      //     token: authAWS.getAccessToken(),
      //     room: "chats" + authAWS.getId(),
      //   },
      // ]);
      // this.state.socket.on("chats" + authAWS.getId(), (response) => {
      //   var chats = this.state.chats;
      //   if (response.typeOfData === "update") {
      //     let chatIndex = -1;
      //     this.state.chats.forEach((e, index) => {
      //       if (e._id === response.data._id) {
      //         chatIndex = index;
      //       }
      //     });
      //     if (chatIndex !== -1) {
      //       chats.splice(chatIndex, 1);
      //       chats.push(response.data);
      //     }
      //   }
      //   if (response.typeOfData === "create") {
      //     let chatIndex = -1;
      //     this.state.chats.forEach((e, index) => {
      //       if (e._id === response.data._id) {
      //         chatIndex = index;
      //       }
      //     });
      //     if (chatIndex !== -1) {
      //       chats.splice(chatIndex, 1);
      //       chats.push(response.data);
      //     } else chats.push(response.data);
      //   }
      //   if (response.typeOfData === "delete") {
      //     let chatIndex = -1;
      //     this.state.chats.forEach((e, index) => {
      //       if (e._id === response.data._id) {
      //         chatIndex = index;
      //       }
      //     });
      //     if (chatIndex !== -1) chats.splice(chatIndex, 1);
      //   }
      //   this.setState({
      //     chats: chats,
      //   });
      // });
      // ///messageChat
      // this.state.socket.emit("join", [
      //   {
      //     token: authAWS.getAccessToken(),
      //     room: "messageChat" + authAWS.getId(),
      //   },
      // ]);
      // this.state.socket.on("messageChat" + authAWS.getId(), (response) => {
      //   var messagesChat = this.state.messagesChat;
      //   if (response.typeOfData === "update") {
      //     let messagesChatIndex = -1;
      //     this.state.messagesChat.forEach((e, index) => {
      //       if (e._id === response.data._id) {
      //         messagesChatIndex = index;
      //       }
      //     });
      //     if (messagesChatIndex !== -1) {
      //       messagesChat.splice(messagesChatIndex, 1);
      //       messagesChat.push(response.data);
      //     }
      //   }
      //   if (response.typeOfData === "create") {
      //     let messagesChatIndex = -1;
      //     this.state.messagesChat.forEach((e, index) => {
      //       if (e._id === response.data._id) {
      //         messagesChatIndex = index;
      //       }
      //     });
      //     if (messagesChatIndex !== -1) {
      //       messagesChat.splice(messagesChatIndex, 1);
      //       messagesChat.push(response.data);
      //     } else messagesChat.push(response.data);
      //   }
      //   if (response.typeOfData === "delete") {
      //     let messagesChatIndex = -1;
      //     this.state.messagesChat.forEach((e, index) => {
      //       if (e._id === response.data._id) {
      //         messagesChatIndex = index;
      //       }
      //     });
      //     if (messagesChatIndex !== -1)
      //       messagesChat.splice(messagesChatIndex, 1);
      //   }
      //   this.setState({
      //     messagesChat: messagesChat,
      //   });
      // });
    };

    //TODO: CLEAR SOCKET
    clearSocket = (fun) => {
      // var rooms = this.state.rooms;
      // if (this.state.socket !== null && this.state.socket !== undefined) {
      //   rooms.forEach((e) => {
      //     this.state.socket.off(e);
      //   });
      //   this.state.socket.disconnect();
      //   this.state.socket.close();
      //   this.setState(
      //     {
      //       subscriptions: [],
      //       rooms: [],
      //       socket: null,
      //     },
      //     () => {
      //       fun();
      //     }
      //   );
      // } else {
      //   this.setState(
      //     {
      //       subscriptions: [],
      //       rooms: [],
      //       socket: null,
      //     },
      //     () => {
      //       fun();
      //     }
      //   );
      // }
    };

    // TODO: ADMINS PER DISTRICT
    getAdminsOfaDistrict = (id) => {
      // netWorking.getAdminsByDistrictId(id, (result) => {
      //   this.setState({
      //     adminsOfaDistrict: result.data.response,
      //   });
      // });
    };

    // TODO: FETCH ALL DISTRICTS
    fetchAllDistricts = () => {
      // netWorking.getAllDistricts((result) => {
      //   var full =
      //     (result.data.response[0] !== undefined && this.state.deleted) ||
      //     this.state.district === null;
      //   this.setState(
      //     {
      //       loading: false,
      //       listOfDistricts: result.data.response.sort((a, b) =>
      //         a.name.localeCompare(b.name)
      //       ),
      //       district:
      //         (result.data.response[0] !== undefined && this.state.deleted) ||
      //         this.state.district === null
      //           ? result.data.response[0]
      //           : this.state.district,
      //       deleted: false,
      //     },
      //     () => {
      //       if (full && result.data.response[0] !== undefined) {
      //         this.fetchDistrict(() => {
      //           this.fetchData(result.data.response[0]._id, true);
      //         }, result.data.response[0]._id);
      //       } else if (!full && result.data.response[0] !== undefined)
      //         this.fetchDistrict(() => {
      //           this.fetchData(this.state.district._id, true);
      //         }, this.state.district._id);
      //       else
      //         this.setState({
      //           loadingGuests: false,
      //           loadingDistrict: false,
      //         });
      //     }
      //   );
      // });
    };

    /**
     *
     * @memberof App
     *
     *  Used to fetch all de data of the current district from firebase at the creation of the app.
     *
     */
    // TODO: FETCH DISTRICT
    fetchDistrict = (e, id) => {
      // this.setState({ loadingDistrict: true }, () => {
      //   netWorking.getDistrictById(id, (result) => {
      //     this.setState(
      //       {
      //         district: result.data.response,
      //         statistics: null,
      //         loadingDistrict: true,
      //       },
      //       () => {
      //         e();
      //       }
      //     );
      //   });
      // });
    };
    // TODO: UPDATE DISTRICT
    handleDeletedDistrict = (response) => {
      // var reports = this.state.reports;
      // let reportIndex = -1;
      // this.state.reports.forEach((e, index) => {
      //   if (e._id === response._id) {
      //     reportIndex = index;
      //   }
      // });
      // if (reportIndex !== -1) reports.splice(reportIndex, 1);
      // this.setState({
      //   reports: reports.sort((a, b) => {
      //     return moment(a.created).toDate() - moment(b.created).toDate();
      //   }),
      // });
    };

    // TODO: UPDATE DISTRICT
    updateDistrict = (id) => {
      // this.setState({ loadingDistrict: true }, () => {
      //   netWorking.getDistrictById(id, (result) => {
      //     this.setState({
      //       district: result.data.response,
      //       loadingDistrict: false,
      //     });
      //   });
      // });
    };

    /**
     *
     * @memberof App
     *
     *  Used to fetch all de data of reports from firebase at the creation of the app.
     *
     */
    // TODO: FETCH REPORTS
    fetchReports = (id) => {
      // if (this.state.district !== undefined)
      //   this.setState({ loadingReports: true }, () => {
      //     netWorking.getReportsByCities(this.state.district._id, (result) => {
      //       this.setState(
      //         {
      //           reports: result.data.response.sort((a, b) => {
      //             return (
      //               moment(a.created).toDate() - moment(b.created).toDate()
      //             );
      //           }),
      //           loadingReports: false,
      //         },
      //         () => {}
      //       );
      //     });
      //   });
    };

    /**
     *
     * @memberof App
     *
     *  Used to fetch all de data of reports from firebase at the creation of the app.
     *
     */
    //TODO:  Statistics
    fetchStatistics = (id) => {
      // var fullYear = moment(Date.now()).toDate().getFullYear();
      // if (this.state.district !== undefined)
      //   this.setState({ loadingReports: true }, () => {
      //     netWorking.getStatistics(id, fullYear, (result) => {
      //       this.setState(
      //         {
      //           statistics: result.data.response,
      //           loadingReports: false,
      //         },
      //         () => {}
      //       );
      //     });
      //   });
    };

    // TODO: GET COUNT STATISTICS
    getCountStatistics = (id) => {
      // var date = moment(Date.now()).startOf("day").toDate();
      // if (this.state.district !== undefined)
      //   this.setState({ loadingReports: true }, () => {
      //     netWorking.post(
      //       netWorking.getCountStatistics,
      //       {
      //         districtId: id,
      //         date: date,
      //         jobTypes: this.state.district.jobTypes,
      //       },
      //       (result) => {
      //         this.setState(
      //           {
      //             countStatistics: result.data.response,
      //             loadingReports: false,
      //           },
      //           () => {}
      //         );
      //       }
      //     );
      //   });
    };

    // TODO: FETCH OLD STATISTICS
    fetchStatisticsOld = (id) => {
      // var fullYear = moment(Date.now())
      //   .subtract(1, "year")
      //   .toDate()
      //   .getFullYear();
      // if (this.state.district !== undefined)
      //   this.setState({ loadingReports: true }, () => {
      //     netWorking.getStatistics(id, fullYear, (result) => {
      //       this.setState(
      //         {
      //           statisticsOld: result.data.response,
      //           loadingReports: false,
      //         },
      //         () => {}
      //       );
      //     });
      //   });
    };

    /**
     *
     * @memberof App
     *
     *  Used to fetch all de data of teams from firebase at the creation of the app.
     * TODO: FETCH TEAMS
     */
    fetchTeams = (id) => {
      // if (this.state.district !== undefined)
      //   this.setState({ loadingTeams: true }, () => {
      //     netWorking.getTeamsByDistrictId(id, (result) => {
      //       this.setState(
      //         {
      //           teams: result.data.response.sort((a, b) =>
      //             a.name.localeCompare(b.name)
      //           ),
      //           loadingTeams: false,
      //         },
      //         function () {}
      //       );
      //     });
      //   });
    };

    /**
     *
     * @memberof App
     *
     *  Used to fetch all de data of users from firebase at the creation of the app.
     * TODO: FETCH USERS
     */
    fetchUsers = () => {
      // if (this.state.district !== undefined && this.state.district !== null)
      //   this.setState({ loadingUsers: true }, () => {
      //     netWorking.getUsersByCities(
      //       { districtId: this.state.district._id },
      //       (result) => {
      //         this.setState(
      //           {
      //             users: result.data.response,
      //             loadingUsers: false,
      //           },
      //           function () {
      //             // console.log(data);
      //           }
      //         );
      //       }
      //     );
      //   });
    };

    /**
     *
     * @memberof App
     *
     *  Used to fetch all de data of workers from firebase at the creation of the app.
     * TODO: FETCH WORKERS
     */
    fetchWorkers = (id) => {
      // if (this.state.district !== undefined)
      //   this.setState({ loadingWorkers: true }, () => {
      //     netWorking.getWorkersByDistrictId(id, (result) => {
      //       this.setState(
      //         {
      //           workers: result.data.response.sort((a, b) =>
      //             a.name.localeCompare(b.name)
      //           ),
      //           loadingWorkers: false,
      //           loadingDistrict: false,
      //         },
      //         function () {}
      //       );
      //     });
      //   });
    };

    // TODO: FETCH TRACKERS
    fetchTrackers = (id) => {
      // if (this.state.district !== undefined)
      //   this.setState({ loadingWorkers: true }, () => {
      //     netWorking.getTrackersByDistrictIdAndDate(
      //       {
      //         districtId: id,
      //         created: moment(Date.now()).utc(false).startOf("day").toDate(),
      //       },
      //       (result) => {
      //         this.setState(
      //           {
      //             trackers: result.data.response,
      //             loadingWorkers: false,
      //             loadingDistrict: false,
      //             loading: false,
      //           },
      //           function () {}
      //         );
      //       }
      //     );
      //   });
    };

    //TODO: FETCH YESTERDAY
    fetchTrackersYesterday = (id) => {
      // if (this.state.district !== undefined)
      //   this.setState({ loadingWorkers: true }, () => {
      //     var date = moment(Date.now()).startOf("day").toDate();
      //     date.setDate(date.getDate() - 1);
      //     netWorking.getTrackersByDistrictIdAndDate(
      //       {
      //         districtId: id,
      //         created: date,
      //       },
      //       (result) => {
      //         this.setState(
      //           {
      //             trackersYesterday: result.data.response,
      //           },
      //           function () {}
      //         );
      //       }
      //     );
      //   });
    };

    /**
     *
     * @memberof App
     *
     *  Used to fetch all de data of reports from firebase at the creation of the app.
     * TODO: FETCH CHATS
     */
    fetchChatsAndMessages = (id) => {
      // if (this.state.district !== undefined)
      //   this.setState({ loadingReports: true }, () => {
      //     netWorking.post(
      //       netWorking.chatGet,
      //       {
      //         receptorId: authAWS.getId(),
      //       },
      //       (result) => {
      //         this.setState(
      //           {
      //             chats: result.data.response.sort((a, b) => {
      //               return (
      //                 moment(a.created).toDate() - moment(b.created).toDate()
      //               );
      //             }),
      //             loadingReports: false,
      //           },
      //           () => {
      //             var idArray = [];
      //             this.state.chats.forEach((c) => {
      //               idArray.push(c._id);
      //             });
      //             console.log(idArray);
      //             netWorking.post(
      //               netWorking.messageChatGet,
      //               {
      //                 chatsId: idArray,
      //               },
      //               (result) => {
      //                 console.log(result);
      //                 this.setState(
      //                   {
      //                     messagesChat: result.data.response.sort((a, b) => {
      //                       return (
      //                         moment(a.created).toDate() -
      //                         moment(b.created).toDate()
      //                       );
      //                     }),
      //                     loadingReports: false,
      //                   },
      //                   () => {}
      //                 );
      //               }
      //             );
      //           }
      //         );
      //       }
      //     );
      //   });
    };

    render() {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <Component
            loading={this.state.loading}
            authUser={null}
            adminsOfaDistrict={this.state.adminsOfaDistrict}
            loadingDistrict={this.state.loadingDistrict}
            superAdmin={this.state.superAdmin}
            loadingUser={this.state.loadingUser}
            currentDistrict={this.state.district}
            reports={this.state.reports}
            teams={this.state.teams}
            trackers={this.state.trackers}
            activeDates={this.state.activeDates}
            workers={this.state.workers}
            guests={this.state.guests}
            statistics={this.state.statistics}
            statisticsOld={this.state.statisticsOld}
            reportTypes={this.state.reportTypes}
            updateDistrict={this.updateDistrict}
            users={this.state.users}
            fetchUsers={this.fetchUsers}
            fetchDistrict={this.fetchDistrict}
            fetchData={this.fetchData}
            listOfDistricts={this.state.listOfDistricts}
            fetchAllDistricts={this.fetchAllDistricts}
            setDistrict={this.setDistrict}
            superData={this.state.superData}
            handleDeletedDistrict={this.handleDeletedDistrict}
            actualPageProfile={this.state.actualPageProfile}
            updatePageProfile={this.updatePageProfile}
            fetchSettings={this.fetchSettings}
            trackersYesterday={this.state.trackersYesterday}
            socket={this.state.socket}
            kanbanKeys={this.state.kanbanKeys}
            changeSelectedTeam={this.changeSelectedTeam}
            selectedTeam={this.state.selectedTeam}
            messagesChat={this.state.messagesChat}
            chats={this.state.chats}
            countStatistics={this.state.countStatistics}
            filtersSelected={this.state.filtersSelected}
            changeFilter={this.changeFilter}
            showTime={this.state.showTime}
            changeShowTime={this.changeShowTime}
          />
        </AuthUserContext.Provider>
      );
    }
  };

export default withAuthentication;
