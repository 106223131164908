import React, { useEffect, useState } from "react";
import { createDocument, updateDocument } from "../../firebase/index";
import { isEmpty } from "lodash";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
// import BigButton from "../components/Big-button-component";
import TextAreaFieldComponent from "../components/text-area-field-component";
import ActionButtons from "../components/Action-buttons";
import HeaderDialog from "../components/Header-dialog";
import {
  NotificationApp,
  NotificationType,
} from "../../server/notification/protos/notification_pb";
import { Report } from "../../server/report/protos/report_pb";
import { licenseI } from "../../redux/licenses/licensesReducer";
import { Team } from "../../server/team/protos/team_pb";
import { useTranslation } from "react-i18next";
import {
  grpcCreateNotification,
  grpcUpdateNotification,
} from "../../redux/notifications/notificationsAction";

interface CommentaryDialogProps {
  commentary: NotificationApp.AsObject;
  typeCommentary: any;
  markerReport: Report.AsObject;
  currentDistrict: licenseI | undefined;
  getNotifications: any;
  team: Team.AsObject | undefined;
  assignedWorkers: any;
  onCloseRequest: any;
}

export const CommentaryDialog: React.FunctionComponent<CommentaryDialogProps> =
  ({
    commentary,
    typeCommentary,
    markerReport,
    currentDistrict,
    getNotifications,
    team,
    assignedWorkers,
    onCloseRequest,
  }: CommentaryDialogProps) => {
    let defaultValue: any;

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [state, setState] = useState({
      comment: "",
      commentValid: false,
    });

    useEffect(() => {
      if (commentary) {
        setState((prevState) => ({
          ...prevState,
          comment: commentary.body,
        }));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleForm = (name: any, event: any) => {
      const value = event.target.value;
      event.preventDefault();
      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };

    const checkValidFormToSubmit = () => {
      if (!isEmpty(state.comment) && currentDistrict) {
        //   this.setState({ commentValid: true });
        // console.log(data);
        if (!commentary) {
          let array: any = [];
          array.push(currentDistrict._id || "");
          if (team && team.bossList) array.push(...team.bossList);
          if (assignedWorkers) array.push(...assignedWorkers);
          dispatch(
            grpcCreateNotification(
              {
                cityid: currentDistrict._id,
                notificationimage: !currentDistrict.imagePath
                  ? "https://miciudad.s3-us-west-1.amazonaws.com/profile-image.png"
                  : currentDistrict.imagePath,
                notificationtype: NotificationType.CUSTOM,
                reportid: markerReport.reportid,
                reportimage: markerReport.imageurl,
                title: currentDistrict.name,
                reporttypeid: markerReport.reporttypeid,
                userid: currentDistrict._id,
                sentby: currentDistrict._id,
                body: state.comment,
                reporttitle: markerReport.description,
              },
              () => {
                onCloseRequest();
              }
            )
          );
          // createDocument(
          //   "notifications",
          //   {
          //     newState: markerReport.reportstatus,
          //     notificationType:
          //       typeCommentary === 1
          //         ? "CommentaryNotification"
          //         : "messageNotification",
          //     notificationImage: !currentDistrict.imagePath
          //       ? "https://miciudad.s3-us-west-1.amazonaws.com/profile-image.png"
          //       : currentDistrict.imagePath,
          //     districtId: currentDistrict._id,
          //     report: markerReport.reportid,
          //     reportType: markerReport.reporttypeid,
          //     reportTitle: markerReport.description,
          //     reportImage: markerReport.imageurl,
          //     title: currentDistrict.name,
          //     body: state.comment,
          //     created: moment().toISOString(),
          //     userIds: typeCommentary === 1 ? markerReport.reportedby : array,
          //     viewed: false,
          //   },
          //   () => {

          //     // this.props.getNotifications(this.props.markerReport);
          //   }
          // );
        } else {
          dispatch(
            grpcUpdateNotification(
              { id: commentary.id, body: state.comment },
              () => {
                onCloseRequest();
              }
            )
          );
          // updateDocument(
          //   "notifications/comment",
          //   commentary.id,
          //   {
          //     body: state.comment,
          //     notificationType: "CommentaryNotification",
          //   },
          //   () => {
          //     onCloseRequest();
          //     // this.props.getNotifications(this.props.markerReport);
          //   }
          // );
        }
      }
    };

    return (
      <div className="content-dialog big-dialog-x10">
        <HeaderDialog
          onCloseRequest={onCloseRequest}
          title={t("name.commentary.add")}
          description={t("name.commentary.add.description")}
          noBold1={defaultValue}
          noBold2={defaultValue}
          boldText={defaultValue}
        ></HeaderDialog>
        <div className="content-options">
          <div className="content-options-body">
            <div className="options-body-item-big text-area-box">
              <div className="selector-box full-width-select">
                <TextAreaFieldComponent
                  //   placeholder={this.props.worker.email}
                  value={state.comment}
                  onChange={(event: any) => {
                    handleForm("comment", event);
                  }}
                  invalid={state.commentValid}
                  message={t("name.commentary.invalid")}
                ></TextAreaFieldComponent>
              </div>
            </div>
            <ActionButtons
              small={true}
              cancelClick={onCloseRequest}
              doneClick={checkValidFormToSubmit}
              clickable={!isEmpty(state.comment)}
              doneText={t("submit.send")}
              cancelText={t("submit.cancel")}
            ></ActionButtons>
          </div>
        </div>
      </div>
    );
  };

export default CommentaryDialog;
