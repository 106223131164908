import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
// import withAuthorization from "../session/withAuthorization";
import { Redirect } from "react-router";
import LoginView from "../views/login-page/components/loginView";
import ForgotView from "../views/login-page/components/forgotView";
import PasswordConfirmationView from "../views/login-page/components/passwordConfirmationView";
import Action from "../views/login-page/components/action";
import PublicTicketPage from "../views/cashier-page/public-ticket";
import { getLanguageS } from "../firebase/auth";

interface RoutesWithoutAuthProps {}

export const RoutesWithoutAuth: React.FunctionComponent<RoutesWithoutAuthProps> =
  ({}: RoutesWithoutAuthProps) => {
    useEffect(() => {
      // if (getLanguageS() !== undefined) this.props.changeLanguage(getLanguageS());
    }, []);

    return (
      <Switch>
        <Route exact path="/" render={(props) => <LoginView />} />
        <Route exact path="/forgot" render={(props) => <ForgotView />} />
        <Route exact path="/action" render={(props) => <Action />} />
        <Route exact path="/ticket" render={(props) => <PublicTicketPage />} />
        <Redirect to="/" />
      </Switch>
    );
  };

// const authCondition = (authUser) => authUser;
// export default withAuthorization(authCondition)(Routes);

export default RoutesWithoutAuth;
