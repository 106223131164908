//type of Users
export const superAdmin = "superAdmin";
export const worker = "worker";
export const adminServices = "adminServices";
export const adminSupervisors = "adminSupervisors";
export const adminCashier = "adminCashier";
export const adminMayor = "adminMayor";



//report State
export const pending = "espera";
export const inProgress = "proceso";
export const done = "solucionado";

export const REPORT_PROGRESS_ASSIGNED = "assigned";
export const REPORT_PROGRESS_PROGRESS = "inProgress";
export const REPORT_PROGRESS_COMPLETED = "completed";

//assign reports actions
export const ASSIGN_TO_TEAM = 1;
export const REMOVE_FROM_TEAM = 2;
export const ASSIGN_TO_OTHER_TEAM = 3;

//report State of work
export const assigned = "assigned";
export const inProgressWork = "inProgress";
export const completed = "completed";

export const limpieza = "Limpieza";
export const riego = "Riego";
export const poda = "Poda";
export const pintura = "Pintura";
export const parques = "Parques Panteones";
export const alumbrado = "Alumbrado Público";
export const basura = "Recogida Basura";
export const desazolve = "Desazolve";
export const escombros = "Retiro de Escombros";

export const GoogleAPIKEY = "AIzaSyCvjW-TQJYgn7BgaDpsz7_KK1a1EdwDJk4";

export const colors = [
  "#F14860",
  "#FFA129",
  "#FDE00B",
  "#7B33FF",
  "#F14CD3",
  "#BB87FF",
  "#828282",
  "#212121",
  "#38F0F5",
  "#48B3FB",
  "#00A09D",
  "#38EEAC",
  "#ACFA00",
  "#BBBB56",
  "#A7651F",
  "#B1906C",
];

// var colors = [
//   "rgba(233, 30, 99, 0.95)",
//   "rgba(156, 39, 176, 0.9)",
//   "rgba(103, 58, 183, 0.85)",
//   "rgba(63, 81, 181, 0.8)",
//   "rgba(33, 150, 243, 0.75)",
//   "rgba(3, 169, 244, 0.7)",
//   "rgba(0, 188, 212, 0.7)",
//   "rgba(0, 150, 136, 0.75)",
//   "rgba(76, 175, 80, 0.8)",
//   "rgba(139, 195, 74, 0.85)",
//   "rgba(205, 220, 57, 0.9)",
//   "rgba(255, 235, 59, 0.95)",
//   "rgba(255, 193, 7, 1)",
//   "rgba(244, 67, 54, 1)",
// ];

export const marks = {
  0: "00:00",
  // 1: "00:30",
  2: "01:00",
  // 3: "01:30",
  4: "02:00",
  // 5: "02:30",
  6: "03:00",
  // 7: "03:30",
  8: "04:00",
  // 9: "04:30",
  10: "05:00",
  // 11: "05:30",
  12: "06:00",
  // 13: "06:30",
  14: "07:00",
  // 15: "07:30",
  16: "08:00",
  // 17: "08:30",
  18: "09:00",
  // 19: "09:30",
  20: "10:00",
  // 21: "10:30",
  22: "11:00",
  // 23: "11:30",
  24: "12:00",
  // 25: "12:30",
  26: "01:00",
  // 27: "01:30",
  28: "02:00",
  // 29: "02:30",
  30: "03:00",
  // 31: "03:30",
  32: "04:00",
  // 33: "04:30",
  34: "05:00",
  // 35: "05:30",
  36: "06:00",
  // 37: "06:30",
  38: "07:00",
  // 39: "07:30",
  40: "08:00",
  // 41: "08:30",
  42: "09:00",
  //43 : "09:30",
  44: "10:00",
  // 45: "10:30",
  46: "11:00",
  //47 : "11:30",
  48: "00:00",
};

export const marksTotal = {
  0: "00:00",
  1: "00:30",
  2: "01:00",
  3: "01:30",
  4: "02:00",
  5: "02:30",
  6: "03:00",
  7: "03:30",
  8: "04:00",
  9: "04:30",
  10: "05:00",
  11: "05:30",
  12: "06:00",
  13: "06:30",
  14: "07:00",
  15: "07:30",
  16: "08:00",
  17: "08:30",
  18: "09:00",
  19: "09:30",
  20: "10:00",
  21: "10:30",
  22: "11:00",
  23: "11:30",
  24: "12:00",
  25: "12:30",
  26: "01:00",
  27: "01:30",
  28: "02:00",
  29: "02:30",
  30: "03:00",
  31: "03:30",
  32: "04:00",
  33: "04:30",
  34: "05:00",
  35: "05:30",
  36: "06:00",
  37: "06:30",
  38: "07:00",
  39: "07:30",
  40: "08:00",
  41: "08:30",
  42: "09:00",
  43: "09:30",
  44: "10:00",
  45: "10:30",
  46: "11:00",
  47: "11:30",
  48: "00:00",
};

export const marks2 = {
  0: "00:00",
  // 1: "00:30",
  1: "01:00",
  // 3: "01:30",
  2: "02:00",
  // 5: "02:30",
  3: "03:00",
  // 7: "03:30",
  4: "04:00",
  // 9: "04:30",
  5: "05:00",
  // 11: "05:30",
  6: "06:00",
  // 13: "06:30",
  7: "07:00",
  // 15: "07:30",
  8: "08:00",
  // 17: "08:30",
  9: "09:00",
  // 19: "09:30",
  10: "10:00",
  // 21: "10:30",
  11: "11:00",
  // 23: "11:30",
  12: "12:00",
  // 25: "12:30",
  13: "13:00",
  // 27: "01:30",
  14: "14:00",
  // 29: "02:30",
  15: "15:00",
  // 31: "03:30",
  16: "16:00",
  // 33: "04:30",
  17: "17:00",
  // 35: "05:30",
  18: "18:00",
  // 37: "06:30",
  19: "19:00",
  // 39: "07:30",
  20: "20:00",
  // 41: "08:30",
  21: "21:00",
  //43 : "09:30",
  22: "22:00",
  // 45: "10:30",
  23: "23:00",
  //47 : "11:30",
  24: "24:00",
};

export const marksTotal2 = {
  0: "00:00 AM",
  // 1: "00:30",
  1: "01:00 AM",
  // 3: "01:30",
  2: "02:00 AM",
  // 5: "02:30",
  3: "03:00 AM",
  // 7: "03:30",
  4: "04:00 AM",
  // 9: "04:30",
  5: "05:00 AM",
  // 11: "05:30",
  6: "06:00 AM",
  // 13: "06:30",
  7: "07:00 AM",
  // 15: "07:30",
  8: "08:00 AM",
  // 17: "08:30",
  9: "09:00 AM",
  // 19: "09:30",
  10: "10:00 AM",
  // 21: "10:30",
  11: "11:00 AM",
  // 23: "11:30",
  12: "12:00 PM",
  // 25: "12:30",
  13: "13:00 PM",
  // 27: "01:30",
  14: "14:00 PM",
  // 29: "02:30",
  15: "15:00 PM",
  // 31: "03:30",
  16: "16:00 PM",
  // 33: "04:30",
  17: "17:00 PM",
  // 35: "05:30",
  18: "18:00 PM",
  // 37: "06:30",
  19: "19:00 PM",
  // 39: "07:30",
  20: "20:00 PM",
  // 41: "08:30",
  21: "21:00 PM",
  //43 : "09:30",
  22: "22:00 PM",
  // 45: "10:30",
  23: "23:00 PM",
  //47 : "11:30",
  24: "24:00 PM",
};

export const getTime = (value) => {
  switch (value) {
    case 0:
      return "00:00";
    case 1:
      return "00:30";
    case 2:
      return "01:00";
    case 3:
      return "01:30";
    case 4:
      return "02:00";
    case 5:
      return "02:30";
    case 6:
      return "03:00";
    case 7:
      return "03:30";
    case 8:
      return "04:00";
    case 9:
      return "04:30";
    case 10:
      return "05:00";
    case 11:
      return "05:30";
    case 12:
      return "06:00";
    case 13:
      return "06:30";
    case 14:
      return "07:00";
    case 15:
      return "07:30";
    case 16:
      return "08:00";
    case 17:
      return "08:30";
    case 18:
      return "09:00";
    case 19:
      return "09:30";
    case 20:
      return "10:00";
    case 21:
      return "10:30";
    case 22:
      return "11:00";
    case 23:
      return "11:30";
    case 24:
      return "12:00";
    case 25:
      return "12:30";
    case 26:
      return "13:00";
    case 27:
      return "13:30";
    case 28:
      return "14:00";
    case 29:
      return "14:30";
    case 30:
      return "15:00";
    case 31:
      return "15:30";
    case 32:
      return "16:00";
    case 33:
      return "16:30";
    case 34:
      return "17:00";
    case 35:
      return "17:30";
    case 36:
      return "18:00";
    case 37:
      return "18:30";
    case 38:
      return "19:00";
    case 39:
      return "19:30";
    case 40:
      return "20:00";
    case 41:
      return "20:30";
    case 42:
      return "21:00";
    case 43:
      return "21:30";
    case 44:
      return "22:00";
    case 45:
      return "22:30";
    case 46:
      return "23:00";
    case 47:
      return "23:30";
    default:
      return "00:00";
  }
};

export const getTime2 = (value) => {
  switch (value) {
    case 0:
      return "00:00";
    // case 1:
    //   return "00:30";
    case 1:
      return "01:00";
    // case 3:
    //   return "01:30";
    case 2:
      return "02:00";
    // case 5:
    //   return "02:30";
    case 3:
      return "03:00";
    // case 7:
    //   return "03:30";
    case 4:
      return "04:00";
    // case 9:
    //   return "04:30";
    case 5:
      return "05:00";
    // case 6:
    //   return "05:30";
    case 6:
      return "06:00";
    // case 13:
    // return "06:30";
    case 7:
      return "07:00";
    // case 15:
    // return "07:30";
    case 8:
      return "08:00";
    // case 17:
    // return "08:30";
    case 9:
      return "09:00";
    // case 19:
    // return "09:30";
    case 10:
      return "10:00";
    // case 21:
    // return "10:30";
    case 11:
      return "11:00";
    // case 23:
    // return "11:30";
    case 12:
      return "12:00";
    // case 25:
    // return "12:30";
    case 13:
      return "13:00";
    // case 27:
    // return "13:30";
    case 14:
      return "14:00";
    // case 29:
    // return "14:30";
    case 15:
      return "15:00";
    // case 31:
    // return "15:30";
    case 16:
      return "16:00";
    // case 33:
    // return "16:30";
    case 17:
      return "17:00";
    // case 35:
    // return "17:30";
    case 18:
      return "18:00";
    // case 37:
    // return "18:30";
    case 19:
      return "19:00";
    // case 39:
    // return "19:30";
    case 20:
      return "20:00";
    // case 41:
    // return "20:30";
    case 21:
      return "21:00";
    // case 22:
    // return "21:30";
    case 22:
      return "22:00";
    // case 45:
    // return "22:30";
    case 23:
      return "23:00";
    // case 47:
    // return "23:30";
    default:
      return "00:00";
  }
};

// animatedMoveLat = (t, current, moveto, fun) => {
//   var lat = current;
//   var deltaLat = moveto - current / 100;
//   var delay = 10 * t;

//   for (var i = 0; i < 100; i++) {
//     lat += deltaLat;
//     return lat;
//   }
// };

// animatedMoveLng = (t, current, moveto, fun) => {
//   var lng = current;
//   var deltaLng = moveto - current / 100;
//   var delay = 10 * t;

//   for (var i = 0; i < 100; i++) {
//     lng += deltaLng;
//     return lng;
//   }
// };

// animatedMove = (t, current, moveto, fun) => {
//   var lat = current.lat;
//   var lng = current.lng;

//   var deltalat = (moveto.lat - current.lat) / 100;
//   var deltalng = (moveto.lng - current.lng) / 100;

//   var delay = 10 * t;
//   for (var i = 0; i < 100; i++) {
//     var lat = current.lat;
//     var lng = current.lng;
//     lat += deltalat;
//     lng += deltalng;
//     fun({ lat: lat, lng: lng });
//   }
// };
