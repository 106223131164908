import React, { useState } from "react";
import { Button, Form, Input } from "antd";
import Logo from "../../../assets/img/logo.png";
import { Eye, ClosedEye } from "../../../assets/svg/index";
import SimpleModal from "../../../utils/dialogs/simple-dialog";
import LoadingDialog from "../../dialogs/loading-dialog";
import BDDialog from "../../dialogs/bdDialog";
import axios from "axios";
import { authAWS, netWorking } from "../../../firebase";
import { isEmpty } from "lodash";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { grpcLogin } from "../../../redux/user/userAction";
import { LoginResponse } from "../../../server/user/protos/auth_pb";
import { CheckAuthentication } from "../../../session/CheckAuthentication";
import { grpcFetchLicenses } from "../../../redux/licenses/licensesActions";
import { FetchType } from "../../../server/license/protos/license_pb";
import { validEmailRegex } from "../../../utils/utils";

const FormItem = Form.Item;

interface LoginViewProps {}

export const LoginView: React.FunctionComponent<LoginViewProps> =
  ({}: LoginViewProps) => {
    const location = useLocation();
    const redirect = useHistory();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [state, setState] = useState({
      eyeActive: false,
      password: "",
      email: "",
      terms: false,
      errorTerms: false,
      isLoading: false,
      blockedUserDialog: false,
      deletedUserDialog: false,
      failUserMessage: "",
      failLoginMessage: "",
    });

    const doLogin = (event) => {
      event.preventDefault();
      const goHome = () => {
        redirect.push("/");
      };
      const wrong = () => {
        wrongLogin();
      };
      const wrongTerms = () => {
        wrongTerms2();
      };
      if (
        !validEmailRegex.test(state.email.trim().toLowerCase()) ||
        isEmpty(state.password.trim())
      ) {
        wrong();
        return;
      }
      if (!state.terms) {
        wrongTerms();
        return;
      } else {
        dispatch(
          grpcLogin(
            {
              email: state.email.trim().toLowerCase(),
              password: state.password.trim(),
            },
            (response: LoginResponse.AsObject) => {
              console.log("login, response", response);
              if (!response.success) {
                wrong();
              } else {
                // dispatch(
                //   grpcFetchLicenses({
                //     query: user?.licenceId || "",
                //     point: {
                //       lat: defaultValue,
                //       lng: defaultValue,
                //     },
                //     type: FetchType.BY_ID,
                //   })
                // );
                authAWS.setSession(
                  {
                    accessToken: response.accessToken,
                    refreshToken: response.refreshToken,
                  },
                  CheckAuthentication
                );
              }
            },
            (err: Error) => {
              wrong();
            }
          )
        );
        // axios
        //   .post(
        //     netWorking.URL + "auth/login",
        //     {
        //       username: state.email.trim().toLowerCase(),
        //       password: state.password.trim(),
        //     },
        //     {
        //       headers: {
        //         "Access-Control-Allow-Origin": "*",
        //         crossdomain: true,
        //       },
        //     }
        //   )
        //   .then(function (data) {
        //     if (data.data.response.response !== undefined) {
        //       const result = data.data.response.response;
        //       if (result.success) {
        //         const authResult = {
        //           accessToken: data.data.response.accessToken,
        //           refreshToken: data.data.response.refreshToken,
        //           expiresIn: data.data.response.expiresIn,
        //           email: result.data.email,
        //           name: result.data.name,
        //           lastName: result.data.lastName,
        //           id: result.data._id,
        //           typeOfUser: result.typeOfUser,
        //           districtId: result.data.districtId,
        //         };
        //         authAWS.setSession(authResult, goHome);
        //       } else {
        //         wrong();
        //         return;
        //       }
        //     } else {
        //       wrong();
        //       return;
        //     }
        //   })
        //   .catch(function (error) {
        //     console.log(error);
        //     wrong();
        //   });
      }
    };

    const showBlockedDialog = () => {
      setState((prev) => ({
        ...prev,
        blockedUserDialog: !state.blockedUserDialog,
        isLoading: false,
      }));
    };

    const showDeletedDialog = () => {
      setState((prev) => ({
        ...prev,
        deletedUserDialog: !state.deletedUserDialog,
        isLoading: false,
      }));
    };

    const tooManyTries = () => {
      setState((prev) => ({
        ...prev,
        failLoginMessage: t("error.message.too.many.try"),
        loginStatus: "error",
      }));
    };

    const wrongUser = () => {
      setState((prev) => ({
        ...prev,
        failUserMessage: t("error.message.user.dont.exist"),
        validUser: "error",
      }));
    };

    const wrongTerms2 = () => {
      setState((prev) => ({
        ...prev,
        errorTerms: true,
        isLoading: false,
      }));
    };

    const wrongLogin = () => {
      setState((prev) => ({
        ...prev,
        failLoginMessage: "error.message.incorrect.password",
        loginStatus: "error",
        isLoading: false,
      }));
    };

    const wrongLoginAccount = () => {
      setState((prev) => ({
        ...prev,
        failLoginMessage: t("error.message.incorrect.account"),
        validUser: "error",
      }));
    };

    const handleLoginForm = (name, event) => {
      const value = event.target.value;
      event.preventDefault();
      setState((prev) => ({
        ...prev,
        [name]: value,
        loginStatus: "",
        validUser: "",
        failLoginMessage: "",
        failUserMessage: "",
        errorTerms: false,
      }));
    };

    return (
      <Form onSubmit={doLogin} className="login-box">
        <div className="login-logo">
          <img src={Logo} alt="" />
          Giooby
        </div>
        <div className="data-box">
          <div className="data-box-title">
            <div className="title">{t("login.welcome")}</div>
            <div className="sub-title">{t("login.start.session")}</div>
          </div>
          <div className="inputs-box">
            <div className="email-box">
              <div>{t("name.email")}</div>
              <FormItem hasFeedback help={state.failUserMessage}>
                <Input
                  placeholder=""
                  onChange={(event) => handleLoginForm("email", event)}
                  className="email-login"
                />
              </FormItem>
            </div>
            <div className="password-box">
              <div>
                <div>{t("name.password")}</div>
                <FormItem hasFeedback help={t(state.failLoginMessage)}>
                  <Input
                    suffix={
                      state.eyeActive ? (
                        <Eye
                          className="eye-deactive password-eye"
                          onClick={() => {
                            setState((prev) => ({
                              ...prev,
                              eyeActive: !state.eyeActive,
                            }));
                          }}
                        ></Eye>
                      ) : (
                        <ClosedEye
                          className="eye-deactive password-eye-closed"
                          onClick={() => {
                            setState((prev) => ({
                              ...prev,
                              eyeActive: !state.eyeActive,
                            }));
                          }}
                        ></ClosedEye>
                      )
                    }
                    placeholder=""
                    className="password-login"
                    onChange={(event) => handleLoginForm("password", event)}
                    type={state.eyeActive ? "" : "password"}
                  />
                </FormItem>
              </div>
            </div>
          </div>
          <div
            className={
              state.errorTerms
                ? "agreements-box checkbox-error"
                : "agreements-box"
            }
          >
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="customCheck1"
                onChange={(event) => {
                  setState((prev) => ({
                    ...prev,
                    terms: !state.terms,
                    errorTerms: false,
                  }));
                }}
              ></input>
              <label
                className="custom-control-label"
                htmlFor="customCheck1"
              ></label>
              {t("terms.agree")}
              <a
                target="_blank"
                href={
                  "https://agreements.giooby.com/" +
                  t("language") +
                  "/terms_and_conditions"
                }
              >
                {t("terms")}
              </a>
            </div>
          </div>
          <div className="forgot-box">
            <div>
              <span
                onClick={() => {
                  redirect.push("/forgot");
                }}
              >
                {t("name.password.forgot")}{" "}
              </span>
            </div>
          </div>
          <div className="btn-box">
            <FormItem>
              <Button
                type="primary"
                htmlType="submit"
                className="btn-done"
                onClick={() =>
                  setState((prev) => ({
                    ...prev,
                    isLoading: true,
                  }))
                }
                // loading={this.props.isLoading}
              >
                {t("name.sign.in")}
              </Button>
            </FormItem>
          </div>
        </div>
        <div className="copyright">
          &copy; {t("copyright.footer")}{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://giooby.com/"
          >
            Giooby.com
          </a>
        </div>
        {state.isLoading && (
          <SimpleModal>
            <LoadingDialog></LoadingDialog>
          </SimpleModal>
        )}
        {state.blockedUserDialog && (
          <SimpleModal>
            <BDDialog
              Title={t("name.blocked.account")}
              Description={t("notification.blocked.city")}
              onCloseRequest={showBlockedDialog}
            ></BDDialog>
          </SimpleModal>
        )}
        {state.deletedUserDialog && (
          <SimpleModal>
            <BDDialog
              Title={t("name.deleted.account")}
              Description={t("notification.deleted.city")}
              onCloseRequest={showDeletedDialog}
            ></BDDialog>
          </SimpleModal>
        )}
      </Form>
    );
  };

export default LoginView;
