import React from "react";

import { CloseIcon } from "../../assets/svg/index.js";

interface HeaderDialogProps {
  onCloseRequest: any;
  description?: any;
  title: any;
  profile?: any;
  children?: any;
  noBold1?: any;
  noBold2?: any;
  boldText?: any;
}

export const HeaderDialog: React.FunctionComponent<HeaderDialogProps> = ({
  onCloseRequest,
  description,
  title,
  profile,
  children,
  noBold1,
  noBold2,
  boldText,
}: any) => {
  return (
    <div className="content-header">
      <div className="header-data">
        <div className="header-content">
          <div className="title-dialog">{title}</div>
          {description || description === "" ? (
            <div className="description">
              {description}
              {noBold1} <b>{boldText}</b>
              {noBold2}{" "}
            </div>
          ) : (
            <div></div>
          )}
          {profile && children}
        </div>
        <div className="close-buttom" onClick={onCloseRequest}>
          <CloseIcon></CloseIcon>
        </div>
      </div>
    </div>
  );
};

export default HeaderDialog;
