import React from "react";
import "./style.scss";
// import { css } from "@emotion/core";
import FeatherIcon from "feather-icons-react";
// First way to import
// Another way to import. This is recommended to reduce bundle size

class NotificationDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      time: 0,
    };
  }
  componentDidMount() {
    this.intervalID = setInterval(() => this.tick(), 1000);
  }
  componentWillUnmount() {
    clearInterval(this.intervalID);
  }
  tick() {
    this.setState(
      {
        time: this.state.time + 1,
      },
      () => {
        if (this.state.time === 5) this.props.clearMessage();
      }
    );
  }

  render() {
    return (
      <div className="position-notification">
        <div
          className={` ${
            this.props.type === 1 ? "success" : "error"
          } notification`}
          onClick={() => this.props.clearMessage()}
        >
          {this.props.type === 1 ? (
            <FeatherIcon icon="check" size="28" />
          ) : (
            <FeatherIcon icon="x" size="28" />
          )}
          <div>
            {this.props.type === 1
              ? this.props.successMessage
              : this.props.errorMessage}
          </div>
        </div>
      </div>
    );
  }
}

export default NotificationDialog;
