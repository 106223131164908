import React from "react";
import "./style.scss";

class ImgCommentDialog extends React.Component {
  render() {
    return (
      <div className="sweet-img-dialog">
        <div
          className="dissmiser-box"
          onClick={this.props.onCloseRequest}
        ></div>
        <div
          id="carouselIndicators"
          className="carousel slide"
          data-ride="carousel"
        >
          <div className="carousel-inner">
            <div className={"carousel-item active"}>
              <div className="title-carousel"></div>
              <img src={this.props.image} className="d-block w-100" alt="..." />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ImgCommentDialog;
