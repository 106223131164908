import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootStore } from "../../../redux/Store";
import { useTranslation } from "react-i18next";

import { Boss, Options } from "../../../assets/svg/index";
import { Team } from "../../../server/team/protos/team_pb";
import { User } from "../../../server/user/protos/user_pb";

interface WorkerTileProps {
  action: any;
  item: User.AsObject;
  handleToggleModalEditWorker: any;
}

export const WorkerTile: React.FunctionComponent<WorkerTileProps> = ({
  action,
  item,
  handleToggleModalEditWorker,
}: WorkerTileProps) => {
  const selectedTeam: Team.AsObject = useSelector(
    (globalState: RootStore) => globalState.teams.selectedTeam
  );

  const { t } = useTranslation();

  const [state, setState] = useState({
    showDialog: false,
  });

  const showDialog = () => {
    setState((prevState) => ({
      ...prevState,
      showDialog: !state.showDialog,
    }));
  };

  return (
    <div className="team-tile">
      <div className="select-options-box">
        {selectedTeam.bossList &&
        selectedTeam.bossList.indexOf(item.id) !== -1 ? (
          <div className="boss-box">
            <Boss className="boss"></Boss>
            {t("name.boss")}
          </div>
        ) : (
          <div></div>
        )}
        <Options className="option-button" onClick={showDialog}></Options>
      </div>
      <div className="team-tile-child">
        <div className="content-team-member">
          <div className="img-team-member">
            <img
              src={
                item.imagePath
                  ? item.imagePath
                  : "https://miciudad.s3-us-west-1.amazonaws.com/profile.png"
              }
              alt=""
            />
          </div>
          <div className="main-data">
            <div className="name-team-member">
              {item.name + " " + item.lastName}
            </div>
            <div className="status-team-member">{t("name.active")}</div>
          </div>
          <div className="email-team-member">{item.email}</div>
          <div className="phone-team-member">
            {item.phoneNumber && item.phoneNumber}
          </div>
        </div>
        {state.showDialog && (
          <div className="hidden-wall" onClick={showDialog}></div>
        )}
        {state.showDialog && (
          <div className="options-box">
            <div className="clear-item" onClick={showDialog}></div>
            <div className="menu-item menu-item-top">
              {t("name.send.message")}
            </div>
            <div
              className="menu-item"
              onClick={() => {
                showDialog();
                handleToggleModalEditWorker();
              }}
            >
              {t("name.edit.member")}
            </div>
            <div
              className="menu-item"
              onClick={() => {
                showDialog();
                action(0, item, 0);
              }}
            >
              {t("name.remove.of.team")}
            </div>
            {selectedTeam.bossList &&
            selectedTeam.bossList.indexOf(item.id) !== -1 ? (
              <div
                className="menu-item menu-item-bottom"
                onClick={() => {
                  showDialog();
                  action(1, item, 0);
                }}
              >
                {t("name.remove.of.boss")}
              </div>
            ) : (
              <div
                className="menu-item menu-item-bottom"
                onClick={() => {
                  showDialog();
                  action(1, item, 1);
                }}
              >
                {t("name.add.to.boss")}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
